import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import { IncidencesPopoverComponent } from "../incidences-popover/incidences-popover.component";
import { PopoverController } from "@ionic/angular";
import { IncidencesService } from "../../../services/src/lib/endpoint/incidences/incidences.service";
import {Router} from "@angular/router";
import { WebsocketService } from '../../../../libs/services/src/lib/endpoint/web-socket/websocket.service';
import { JsonPipe } from '@angular/common';
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material';
import {IncidenceModel} from "../../../services/src/models/endpoints/Incidence";
import Incidence = IncidenceModel.Incidence;
import ResponseSearch = IncidenceModel.ResponseSearch;


@Component({
  selector: 'button-incidences',
  templateUrl: './incidences-button.component.html',
  styleUrls: ['./incidences-button.component.scss']
})

export class IncidencesButtonComponent implements OnInit, OnDestroy {

  private subscription;
  private SOCKECT_ID_RECEIVE:string="INCIDENCES";
  incidencesUnattendedQuantity : number = 0;
  private incidencesEmitter : EventEmitter<Incidence[]> = new EventEmitter();
  private incidences : Incidence[] = [] ;
  private isSubscriptionLoaded : boolean = false;

  constructor(
    private router: Router,
    private popoverController: PopoverController,
    public incidencesService: IncidencesService,
    private websocketService : WebsocketService,
  ) { }

  ngOnInit() {
    this.incidencesService.getIncidencesIconList(
      (response : ResponseSearch) => {
        this.incidencesUnattendedQuantity = response.data.count_search;
        this.incidences = response.data.incidences;
      });
    this.loadSubscription();
  }

  private loadSubscription() {
    if (!this.isSubscriptionLoaded){
      this.subscription = this.websocketService.getEmitData().subscribe((_in?:{receive:{sockect_id_receive:string, socket_data:any}})=>{
        try {
          const id = _in.receive.sockect_id_receive;
          const data = _in.receive.socket_data;
          if(id === this.SOCKECT_ID_RECEIVE && data){
            this.incidencesUnattendedQuantity = data.count_search;
            this.incidences = data.incidences;
            this.incidencesEmitter.emit(data.incidences);
          }
        } catch (error) {

        }
      }, () => {}, () => {
        if (this.subscription) {
          this.subscription.unsubscribe();
          this.subscription = null;
          this.isSubscriptionLoaded = false;
        }
      });
      this.isSubscriptionLoaded = true;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.isSubscriptionLoaded = false;
  }

  async showIncidences(ev: any) {
    if (this.incidencesUnattendedQuantity > 0) {
      const popover = await this.popoverController.create({
        component: IncidencesPopoverComponent,
        componentProps : {incidences : this.incidences, incidentsEmitter : this.incidencesEmitter},
        event: ev,
        cssClass: 'popover-incidences'
      });

      popover.onDidDismiss().then((data) => {
        if (data && data.data && data.data.showMore) {
          this.router.navigate(['incidences']);
        }
      });

      return await popover.present();
    }
  }

}

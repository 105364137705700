import {Component, OnInit} from '@angular/core';
import {Router } from '@angular/router';
import * as toolbarProvider from "../../../services/src/providers/toolbar/toolbar.provider";


@Component({
  selector: 'app-locate',
  templateUrl: './locate.component.html',
  styleUrls: ['./locate.component.scss']
})

export class LocateComponent implements OnInit {

  constructor(
  )
   {}

  ngOnInit() {

  }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-picking-manual',
  templateUrl: './picking-manual.component.html',
  styleUrls: ['./picking-manual.component.scss']
})

export class PickingManualComponent implements OnInit {

  constructor() {}

  ngOnInit() {

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatListModule } from '@angular/material/list';
import {MatIconModule, MatSelectModule, MatSortModule, MatTableModule} from '@angular/material'
import { MatCheckboxModule } from '@angular/material/checkbox';
import {FormsModule} from "@angular/forms";
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatExpansionModule} from '@angular/material/expansion';
import { RefundsComponent } from './refunds.component';
import {RouterModule, Routes} from "@angular/router";
import {RefundsOkComponent} from "./refunds-ok/refunds-ok.component";
import {RefundsKoComponent} from "./refunds-ko/refunds-ko.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonModule} from "@angular/material/button";
import {RulesModule} from "../marketplaces/rules/rules.module";
import {CategorizeProductsModule} from "../marketplaces/catalog/modals/categorize-products/categorize-products.module";
import {LogisticsOperatorsModule} from "../marketplaces/logistics-operators/logistics-operators.module";
import {MatInputModule} from "@angular/material/input";
import {PaginatorComponentModule} from "../components/paginator/paginator.component.module";

const routes: Routes = [
  {
    path: '',
    component: RefundsComponent
  },
  {
    path: 'refunds-ok',
    component: RefundsOkComponent,
    data: {
      name: 'Reembolsos OK'
    }
  },
  {
    path: 'refunds-ko',
    component: RefundsKoComponent,
    data: {
      name: 'Reembolsos KO'
    }
  },
];

@NgModule({
  declarations: [
    RefundsComponent,
    RefundsOkComponent,
    RefundsKoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    RouterModule.forChild(routes),
    MatExpansionModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatSortModule,
    RulesModule,
    CategorizeProductsModule,
    LogisticsOperatorsModule,
    MatListModule,
    MatPaginatorModule,
    FormsModule,
    PaginatorComponentModule,
    MatIconModule
  ],
  entryComponents: [RefundsComponent]
})
export class RefundsModule { }

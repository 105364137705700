import {Component, Input, OnInit} from '@angular/core';
import { ListDetailHistoryComponent } from '../list-detail.component';

@Component({
  selector: 'title-detail-history',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleDetailHistoryComponent implements OnInit {

  @Input() filters: any;
  @Input() filterOptions: any;

  constructor(
    private listComponent: ListDetailHistoryComponent
  ) {}

  ngOnInit() {}

  applyFilters(event, column: string){
    this.listComponent.applyFilters(event, column);
  }

  orderBy(column: string){
    this.listComponent.orderBy(column);
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'suite-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {

  @Input() products: Array<any>;
  @Input() bulk;

  constructor(private modalController : ModalController) {
  }

  ngOnInit() {
  }

  close() {
    this.modalController.dismiss();
  }

}

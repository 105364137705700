import {Injectable} from '@angular/core';
import {RequestsProvider} from "../../../providers/requests/requests.provider";
import {environment} from "@suite/services";
import {HttpRequestModel} from "../../../models/endpoints/HttpRequest";
import Response = HttpRequestModel.Response;
import {RegularizationModel} from "../../../models/endpoints/Regularization";
import Regularization = RegularizationModel.Regularization;
import {from, Observable} from "rxjs";
import { HttpClient } from '@angular/common/http';
import {map, switchMap} from "rxjs/operators";
import SizeRegularizationResponse = RegularizationModel.SizeRegularizationResponse;
import SizeRegularizationParameters = RegularizationModel.SizeRegularizationParameters;
import SizeRegularizationNotifyParameters = RegularizationModel.SizeRegularizationNotifyParameters;

@Injectable({
  providedIn: 'root'
})
export class RegularizationService {

  private postLoadRegularizationsUrl = environment.apiBase+'/regularizations';
  private postSaveUrl = environment.apiBase+'/regularizations/save';
  private postProductRegularizedUrl = environment.apiBase+'/regularizations/product-regularized';
  private postProductNotFoundUrl = environment.apiBase+'/regularizations/product-not-found';
  private getLoadUrl = environment.apiBase+'/regularizations/';
  private getStartRouteUrl = environment.apiBase+'/regularizations/start-route/';
  private getRegularizationFinishedUrl = environment.apiBase+'/regularizations/finish/';
  private getAvailableModelsUrl = environment.apiBase+'/products/models/available-regularization';
  private getAvailableModelsFiltersUrl = environment.apiBase+'/products/models/available-regularization/filters';
  private postSizeRegularizationUrl = environment.apiBase+'/regularizations/size-regularization';
  private postRevertSizeRegularizationUrl = environment.apiBase+'/regularizations/revert-size-regularization';
  private postSizeRegularizationNotifyUrl = environment.apiBase+'/regularizations/size-regularization/notify';

  constructor(
    private requestsProvider: RequestsProvider,
    private http: HttpClient,

  ) {}

  postLoadRegularizations(parameters: {page: number, size: number}): Promise<Response> {
    return this.requestsProvider.post(this.postLoadRegularizationsUrl, parameters);
  }

  getLoad(parameters: {id: number}): Promise<Response> {
    return this.requestsProvider.get(this.getLoadUrl+parameters.id);
  }

  getAvailableModels(): Promise<Response> {
    return this.requestsProvider.get(this.getAvailableModelsUrl);
  }

  postSave(parameters: Regularization): Promise<Response> {
    return this.requestsProvider.post(this.postSaveUrl, parameters);
  }

  postProductNotFound(parameters: {regProcessId: number}): Promise<Response> {
    return this.requestsProvider.post(this.postProductNotFoundUrl, parameters);
  }

  getStartRoute(parameters: {id: number}): Promise<Response> {
    return this.requestsProvider.get(this.getStartRouteUrl+parameters.id);
  }

  postProductRegularized(parameters: {regularizationId: number, productReference: string}): Promise<Response> {
    return this.requestsProvider.post(this.postProductRegularizedUrl, parameters);
  }

  getRegularizationFinished(parameters: {id: number}): Promise<Response> {
    return this.requestsProvider.get(this.getRegularizationFinishedUrl+parameters.id);
  }

  getAvailableModelsFilters(): Promise<Response> {
    return this.requestsProvider.get(this.getAvailableModelsFiltersUrl);
  }

  postSearchAvailableModels(params): Observable<any> {
    return this.http.post<any>(this.getAvailableModelsUrl, params).pipe(map(response => {
      return response.data;
    }));
  }

  postSizeRegularization(params: SizeRegularizationParameters): Promise<SizeRegularizationResponse> {
    return this.requestsProvider.post(this.postSizeRegularizationUrl, params);
  }

  postRevertSizeRegularization(params: SizeRegularizationParameters): Promise<SizeRegularizationResponse> {
    return this.requestsProvider.post(this.postRevertSizeRegularizationUrl, params);
  }

  postSizeRegularizationNotify(params: SizeRegularizationNotifyParameters): Promise<SizeRegularizationResponse> {
    return this.requestsProvider.post(this.postSizeRegularizationNotifyUrl, params);
  }

}

import {Component, ViewChild} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {IntermediaryService, OplTransportsService} from '../../../services/src';
import {MatTabsModule, MatTabChangeEvent} from '@angular/material/tabs';
import {DefectiveRegistryService} from '../../../services/src/lib/endpoint/defective-registry/defective-registry.service';
import {FormBuilder} from '@angular/forms';
import {ExpeditionCollectedService} from '../../../services/src/lib/endpoint/expedition-collected/expedition-collected.service';
import {PackageCollectedComponent} from './package-collected/package-collected.component';
import {TransportsModel} from "../../../services/src/models/endpoints/Expeditions/Transports";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ExpeditionsProcessedModel} from "../../../services/src/models/endpoints/Expeditions/Processed";

@Component({
  selector: 'suite-expedition-collected',
  templateUrl: './expedition-collected.component.html',
  styleUrls: ['./expedition-collected.component.scss'],
  animations: [
    trigger('disappear', [
      state('flyIn', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 1 }),
        animate('0.5s ease-in-out')
      ]),
      transition(':leave', [
        animate('0.5s ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ExpeditionCollectedComponent {

  @ViewChild('#tabGroup') private tabGroup: MatTabsModule;
  @ViewChild('#selected') private tabSelected: PackageCollectedComponent;

  indexTab;
  indexToIdTransport = [];
  sendEvent: boolean = false;
  dataTransport: TransportsModel.TransportWithQuantity[];
  buttonSend: boolean;

  public existsWarning: boolean = false;
  public showWarning: boolean = true;
  public warningMessageText: string = null;

  constructor(
    private defectiveRegistryService: DefectiveRegistryService,
    private formBuilder: FormBuilder,
    private intermediaryService: IntermediaryService,
    private modalController: ModalController,
    private intermediary: IntermediaryService,
    private expeditionCollectedService: ExpeditionCollectedService,
    private oplTransportsService: OplTransportsService
  ) {}

  ngOnInit() {
    this.getTransports();
  }

  public async refresh() {
    this.expeditionCollectedService.setEmitTabId(this.getTransportIdForTab());
  }

  public async send() {
    this.sendEvent == true ? this.sendEvent = false : this.sendEvent = true;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.existsWarning = false;
    this.warningMessageText = null;
    this.indexTab = tabChangeEvent.index;
    tabChangeEvent.tab.ngOnDestroy();

    this.oplTransportsService
      .getCheckTransportHasRelabel(this.getTransportIdForTab())
      .subscribe(res => {
        if (res.length > 0) {
          this.existsWarning = true;
          this.showWarning = true;

          this.warningMessageText = "Hay";
          let countTotalOrders = 0;
          if (res.length == 1) {
            countTotalOrders = res[0].count;
            this.warningMessageText = this.warningMessageText.concat(this.getWarningTextForWarehouse(res[0]));
          } else {
            for (let i in res) {
              if (parseInt(i) != 0 && parseInt(i) != (res.length - 1)) {
                this.warningMessageText = this.warningMessageText.concat(",");
              } else if (parseInt(i) == (res.length - 1)) {
                this.warningMessageText = this.warningMessageText.concat(" y");
              }

              const transportRelabel = res[i];
              countTotalOrders += transportRelabel.count;
              this.warningMessageText = this.warningMessageText.concat(this.getWarningTextForWarehouse(transportRelabel));
            }
          }

          if (countTotalOrders > 1) {
            this.warningMessageText = this.warningMessageText.concat(` previamente contratados con este transportista que están pendientes de retiquetado.`);
          } else {
            this.warningMessageText = this.warningMessageText.concat(` previamente contratado con este transportista que está pendiente de retiquetado.`);
          }
        } else {
          this.existsWarning = false;
        }
      }, err => this.existsWarning = false);
  }

  async getTransports() {
    this.oplTransportsService.getOperatorsPendingPickUp().subscribe(result => {
      this.dataTransport = result;
      this.assignIdTransportToIndex();
    }, (err) => console.log(err));
  }

  assignIdTransportToIndex() {
    for (const i in this.dataTransport) {
      const transport = this.dataTransport[i];
      this.indexToIdTransport.push({
        index: i,
        idTransport: transport.id,
        referenceTransport: transport.reference
      })
    }
  }

  buttonState($event) {
    this.buttonSend = $event;
  }

  public async showPopoverInfo() {
    this.showWarning = !this.showWarning;
  }

  public async closePopoverInfo() {
    this.showWarning = false;
  }

  private getWarningTextForWarehouse(transportRelabel: ExpeditionsProcessedModel.CheckTransportHasRelabeled): string {
    if (typeof transportRelabel == "undefined" || !transportRelabel) return "";
    return ` ${transportRelabel.count} pedido${transportRelabel.count > 1 ? 's' : ''} de ${transportRelabel.warehouse}`;
  }

  private getTransportIdForTab(): number {
    const transport = this.indexToIdTransport.find(element => element.index == this.indexTab);
    return transport ? transport.idTransport : 0;
  }
}

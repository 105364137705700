import {Component, OnInit} from '@angular/core';
import {IntermediaryService} from "@suite/services";
import {Router} from '@angular/router';
import {PositionsToast} from "../../../services/src/models/positionsToast.type";
import {PickingService} from "../../../services/src/lib/endpoint/picking/picking.service";
import {WorkwavesService} from "../../../services/src/lib/endpoint/workwaves/workwaves.service";
import {WorkwaveModel} from "../../../services/src/models/endpoints/Workwaves";
import {TimesToastType} from "../../../services/src/models/timesToastType";
import {ShoesPickingModel} from "../../../services/src/models/endpoints/ShoesPicking";
import {CarriersService} from "../../../services/src/lib/endpoint/carriers/carriers.service";
import {ShoesPickingService} from "../../../services/src/lib/endpoint/shoes-picking/shoes-picking.service";
import {PickingProvider} from "../../../services/src/providers/picking/picking.provider";


@Component({
  selector: 'suite-create-picking-orders-petitions',
  templateUrl: './create-picking-orders-petitions.component.html',
  styleUrls: ['./create-picking-orders-petitions.component.scss']
})
export class CreatePickingOrdersPetitionsComponent implements OnInit {

  public TYPE_EXECUTION_ID = 1;

  public onlineCheck: boolean;
  public storePetitionsCheck: boolean;
  public residenceCheck: boolean;
  public storePickupCheck: boolean;
  public multipleCheck: boolean;
  public simpleCheck: boolean;

  public pickingTypeFirstGroupValid: boolean;
  public pickingTypeSecondGroupValid: boolean;
  public pickingTypeThirdGroupValid: boolean;

  public showTeamAssignations: boolean;

  public numberOfArticles: number;
  public validNumberOfArticles: boolean;
  public teamAssignations: any[];

  public createPickingButtonIsActive: boolean;
  public currentUser;

  public loadSpinner: boolean;
  public listOfRequest;

  constructor(
    private intermediaryService: IntermediaryService,
    public router: Router,
    private pickingService: PickingService,
    private workwaveService: WorkwavesService,
    private carriersService: CarriersService,
    private shoesPickingService: ShoesPickingService,
    private pickingProvider: PickingProvider
  ) {}

  async ngOnInit() {

    this.onlineCheck = false;
    this.storePetitionsCheck = false;
    this.residenceCheck = false;
    this.storePickupCheck = false;
    this.multipleCheck = false;
    this.simpleCheck = false;

    this.pickingTypeFirstGroupValid = true;
    this.pickingTypeSecondGroupValid = true;
    this.pickingTypeThirdGroupValid = true;

    this.showTeamAssignations = true;

    this.numberOfArticles = null;
    this.validNumberOfArticles = true;
    this.teamAssignations = [];

    this.createPickingButtonIsActive = false;

    this.loadSpinner = false;
    this.listOfRequest = [];

    let sessionUser = JSON.parse(localStorage.getItem("user"));
    this.currentUser = {
      userId: sessionUser.id,
      user: sessionUser.name
    };

    this.refreshTeamAssignations();
  }

  async ionViewWillEnter() {
  }

  public createPicking() {
    this.checkValidNumberOfArticles();

    if (this.numberOfArticles && this.validNumberOfArticles && (this.onlineCheck || this.storePetitionsCheck) && (this.residenceCheck || this.storePickupCheck) && (this.multipleCheck || this.simpleCheck)) {

      this.loadSpinner = true;

      this.workwaveService.postAssignUserToLimitedOnlineStoreRequests({
        requestIds: this.listOfRequest.filter(r => r.request).map(r => r.request.id),
        deliveryRequestIds: this.listOfRequest.filter(r => r.deliveryRequest).map(r => r.deliveryRequest.id),
        userIds: [this.currentUser.userId],
        qtyMaxItems: this.numberOfArticles
      }).subscribe((response) => {
        if (response.code === 201) {
          const requestIds = response.data.requestIds;
          const deliveryRequestIds = response.data.deliveryRequestIds;

          this.workwaveService.postConfirmMatchLineRequestOnlineStore({
            type: this.TYPE_EXECUTION_ID,
            requestIds: requestIds,
            deliveryRequestIds: deliveryRequestIds,
            userIds: [this.currentUser.userId],
            qtyMaxItems: this.numberOfArticles
          })
            .then((res: any) => {
              if (res.code === 201) {
                if (res.data.pickings.length) {
                  this.intermediaryService.presentToastSuccess('Tarea de picking generada correctamente', TimesToastType.DURATION_SUCCESS_TOAST_3750);
                  this.enterPicking(res.data.pickings[0]);
                } else {
                  this.loadSpinner = false;
                  this.intermediaryService.presentToastError('[ERR1305] Ha ocurrido un error al generar la ola de trabajo. No se generó el picking.', 4000);
                }
              } else {
                this.loadSpinner = false;
                let errorMessage = "[ERR1304] Ha ocurrido un error al generar el picking.";
                if (res && res.errors) {
                  errorMessage = res.errors;
                }
                console.error('[ERR1304] Error::Subscribe:workwavesService::postConfirmMatchLineRequest::', res);
                this.intermediaryService.presentToastError(errorMessage, PositionsToast.BOTTOM, TimesToastType.DURATION_ERROR_TOAST_LONG);
              }
            }, (error) => {
              this.loadSpinner = false;
              this.intermediaryService.presentToastError('[ERR1303] Ha ocurrido un error al generar el picking.', 3000);
            })
            .catch((error) => {
              this.loadSpinner = false;
              this.intermediaryService.presentToastError('[ERR1302] Ha ocurrido un error al generar el picking.', 3000);
            });
        } else {
          this.loadSpinner = false;
          this.intermediaryService.presentToastError('[ERR1301] Ha ocurrido un error al generar el picking.', 3000);
        }
      }, error => {
        this.loadSpinner = false;
        let errorMessage = "[ERR1306] Ha ocurrido un error al generar el picking.";
        if (error && error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        console.error('[ERR1306] Error::Subscribe:workwaveService::postAssignUserToMatchLineOnlineStoreRequest::', error);
        this.intermediaryService.presentToastError(errorMessage, PositionsToast.BOTTOM, TimesToastType.DURATION_ERROR_TOAST_LONG);
      });

    }

  }

  public enterPicking(firstPicking) {
      this.carriersService
        .getUpdatePackingStatusInPicking(firstPicking.id)
        .subscribe((res) => {
          console.debug('Test::carriersService::getUpdatePackingStatusInPicking::res', res);
        }, (error) => {
          console.debug('Test::carriersService::getUpdatePackingStatusInPicking::error', error);
        });

      this.shoesPickingService
        .getPendingListByPicking(firstPicking.id)
        .subscribe((res: ShoesPickingModel.ResponseListProductsByPicking) => {
          this.loadSpinner = false;
          let listProducts: ShoesPickingModel.ShoesPicking[] = res.data.list;

          this.pickingProvider.pickingId = firstPicking.id;
          this.pickingProvider.listProducts = listProducts;
          this.pickingProvider.quantityTotal = res.data.counts.total;
          this.pickingProvider.quantityPending = res.data.counts.pending;
          this.pickingProvider.quantityScanned = res.data.counts.scanned;
          this.pickingProvider.typePacking = firstPicking.packingType;
          this.pickingProvider.typePicking = firstPicking.typePicking.id;
          this.pickingProvider.packingReference = firstPicking.packingRef;
          this.router.navigate(['picking/manual']);
          this.resetData();
        }, (error) => {
          this.loadSpinner = false;
        });
  }

  public resetData() {
    this.ngOnInit();
  }

  public refreshNumberArticles() {
    this.pickingTypeFirstGroupValid = (this.onlineCheck || this.storePetitionsCheck);
    this.pickingTypeSecondGroupValid = (this.residenceCheck || this.storePickupCheck);
    this.pickingTypeThirdGroupValid = (this.multipleCheck || this.simpleCheck);

    if (this.pickingTypeFirstGroupValid && this.pickingTypeSecondGroupValid && this.pickingTypeThirdGroupValid) {
      this.loadSpinner = true;

      let params = {
        preparationLinesTypes: [],
        shippingModes: [],
        numberRequestType: []
      };

      if (this.onlineCheck) {
        params.preparationLinesTypes.push(30);
      }

      if (this.storePetitionsCheck) {
        params.preparationLinesTypes.push(20);
      }

      if (this.residenceCheck) {
        params.shippingModes.push(1);
      }

      if (this.storePickupCheck) {
        params.shippingModes.push(3);
      }

      if (this.multipleCheck) {
        params.numberRequestType.push("multiple");
      }

      if (this.simpleCheck) {
        params.numberRequestType.push("simple");
      }

      this.workwaveService.getSendOperationsCount(params).subscribe((sendOperationsCount) => {
        if (sendOperationsCount && sendOperationsCount.data) {
          this.numberOfArticles = sendOperationsCount.data.total;
          this.listOfRequest = sendOperationsCount.data.listPicking;
        } else {
          this.numberOfArticles = 0;
          this.listOfRequest = [];
        }
        this.checkValidNumberOfArticles();
        this.refreshTeamAssignations();
        this.loadSpinner = false;
      }, error => {
        console.error(error)
				this.numberOfArticles = 0;
				this.listOfRequest = [];
        this.loadSpinner = false
        this.intermediaryService.presentToastError(error.error.errors, 'bottom', 3200)
      });

    } else {
      let errorMessage = 'Es necesario seleccionar uno de los dos o los dos tipos de los siguientes grupos: ';
      if (!this.pickingTypeFirstGroupValid) {
        errorMessage += 'Online/Peticiones tienda'
      }
      if (!this.pickingTypeSecondGroupValid) {
        if (!this.pickingTypeFirstGroupValid) {
          errorMessage += ', ';
        }
        errorMessage += 'Domicilio/Recogida tienda'
      }

      if (!this.pickingTypeThirdGroupValid) {
        if (!this.pickingTypeFirstGroupValid || !this.pickingTypeSecondGroupValid) {
          errorMessage += ', ';
        }
        errorMessage += 'Múltiples/Simples'
      }
      this.intermediaryService.presentToastError(errorMessage, PositionsToast.BOTTOM, 4000);
    }
  }

  public pickingTypeSelect(group) {
    this.refreshTeamAssignations();
    this.numberOfArticles = null;
    this.validNumberOfArticles = true;
    setTimeout(() => {
      switch (group) {
        case 1:
          if (!this.pickingTypeFirstGroupValid) {
            this.pickingTypeFirstGroupValid = (this.onlineCheck || this.storePetitionsCheck);
          }
          break;
        case 2:
          if (!this.pickingTypeSecondGroupValid) {
            this.pickingTypeSecondGroupValid = (this.residenceCheck || this.storePickupCheck);
          }
          break;
        case 3:
          if (!this.pickingTypeThirdGroupValid) {
            this.pickingTypeThirdGroupValid = (this.multipleCheck || this.simpleCheck);
          }
          break;
      }
    });
  }

  public async refreshTeamAssignations() {

    this.teamAssignations = [];
    let team = await this.pickingService.getPendingPickingByUser();
    if (team.data && team.data.length) {
      let currentUserPickings = team.data.find(user => user.userId === this.currentUser.userId);
      if (currentUserPickings) {
        team.data = team.data.filter(item => item.userId !== currentUserPickings.userId);
        if (this.numberOfArticles && this.validNumberOfArticles) {
          currentUserPickings.pickings = String(parseInt(currentUserPickings.pickings, 10) + this.numberOfArticles);
        }
        team.data.unshift(currentUserPickings);
      } else {
        this.currentUser.pickings = (this.numberOfArticles && this.validNumberOfArticles) ? this.numberOfArticles.toString() : "0";
        team.data.unshift(this.currentUser);
      }
      for (let teamItem of team.data) {
        this.teamAssignations.push(teamItem);
      }
    } else {
      this.currentUser.pickings = (this.numberOfArticles && this.validNumberOfArticles) ? this.numberOfArticles.toString() : "0";
      this.teamAssignations.push(this.currentUser);
    }
  }

  public createPickingButtonActivation(): boolean {
    return (this.numberOfArticles && this.validNumberOfArticles && (this.onlineCheck || this.storePetitionsCheck) && (this.residenceCheck || this.storePickupCheck) && (this.multipleCheck || this.simpleCheck) && this.listOfRequest.length);
  }

  public checkValidNumberOfArticles() {
    this.validNumberOfArticles = (this.numberOfArticles && this.numberOfArticles % 1 === 0 && this.numberOfArticles > 0);
  }

  public checkInvalidNumberMessage() {
    if (!this.validNumberOfArticles) {
      this.intermediaryService.presentToastError("Número de artículos inválido", PositionsToast.BOTTOM, 4000);
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
  MatTableModule,
  MatCheckboxModule,
  MatGridListModule,
  MatRadioModule, MatExpansionModule
} from '@angular/material';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule, Routes} from "@angular/router";
import {CreatePickingOrdersPetitionsComponent} from "./create-picking-orders-petitions.component";

const routes: Routes = [
  {
    path: '',
    component: CreatePickingOrdersPetitionsComponent
  }
];

@NgModule({
  declarations: [CreatePickingOrdersPetitionsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    IonicModule,
    MatTableModule,
    MatCheckboxModule,
    MatGridListModule,
    MatExpansionModule,
    MatRadioModule,
    MatTooltipModule,
    FormsModule
  ]
})
export class CreatePickingOrdersPetitionsModule {}

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import {AuthenticationService, environment} from '@suite/services';

import { UserModel, RolModel } from '@suite/services';
import { PATH } from '../../../../../../../config/base';
import { concat, BehaviorSubject } from 'rxjs';
import FilterOptionsResponse = UserModel.UserFilterOptionsResponse;
import SearchParameters = UserModel.SearchParameters;
import SearchResponse = UserModel.SearchResponse;
import {RequestsProvider} from "../../../../../../services/src/providers/requests/requests.provider";

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  private getFilterOptionsUrl:string = environment.apiBase+"/gestion-permissions/users/filters";
  constructor(private http: HttpClient, private auth: AuthenticationService, private requestsProvider: RequestsProvider) {}

  async getIndex(
    apiEndpoint: string
  ): Promise<Observable<HttpResponse<UserModel.ResponseIndex>>> {
    const PATH_GET_INDEX = PATH(apiEndpoint, 'Index');
    const currentToken = await this.auth.getCurrentToken();
    const headers = new HttpHeaders({ Authorization: currentToken });
    return this.http.get<UserModel.ResponseIndex>(PATH_GET_INDEX, {
      headers: headers,
      observe: 'response'
    });
  }

  async postStore(
    user: UserModel.User | RolModel.Rol,
    apiEndpoint: string
  ): Promise<Observable<HttpResponse<UserModel.ResponseStore>>> {
    const PATH_POST_STORE = PATH(apiEndpoint, 'Store');
    const currentToken = await this.auth.getCurrentToken();
    const headers = new HttpHeaders({ Authorization: currentToken });
    return this.http.post<UserModel.ResponseStore>(PATH_POST_STORE, user, {
      headers: headers,
      observe: 'response'
    });
  }

  async getShow(
    userId: string | number,
    apiEndpoint: string
  ): Promise<Observable<HttpResponse<UserModel.ResponseShow>>> {
    const PATH_GET_SHOW = PATH(apiEndpoint, 'Show').slice(0, -1);
    const currentToken = await this.auth.getCurrentToken();
    const headers = new HttpHeaders({ Authorization: currentToken });
    return this.http.get<UserModel.ResponseShow>(`${PATH_GET_SHOW}${userId}`, {
      headers: headers,
      observe: 'response'
    });
  }

  async putUpdate(
    user: UserModel.User | RolModel.Rol,
    apiEndpoint: string
  ): Promise<Observable<HttpResponse<UserModel.ResponseUpdate>>> {
    const PATH_PUT_UPDATE: string = PATH(apiEndpoint, 'Update').slice(0, -1);
    const currentToken = await this.auth.getCurrentToken();
    const headers = new HttpHeaders({ Authorization: currentToken });
    return this.http.put<UserModel.ResponseUpdate>(
      `${PATH_PUT_UPDATE}${user.id}`,
      user,
      {
        headers: headers,
        observe: 'response'
      }
    );
  }

  async deleteDestroy(
    users: UserModel.User[],
    apiEndpoint: string
  ): Promise<Observable<HttpResponse<UserModel.ResponseDestroy>>[]> {
    const PATH_DEL_DESTROY: string = PATH(apiEndpoint, 'Destroy').slice(0, -1);
    const currentToken = await this.auth.getCurrentToken();
    const headers = new HttpHeaders({ Authorization: currentToken });

    return users.map(user => {
      return concat(
        this.http.delete<UserModel.ResponseDestroy>(
          `${PATH_DEL_DESTROY}${user.id}`,
          {
            headers: headers,
            observe: 'response'
          }
        )
      );
    });
  }

  async getIndexWithFilters(apiEndpoint: string, params: SearchParameters): Promise<SearchResponse> {
    const PATH_GET_INDEX = PATH(apiEndpoint, 'Index');
    return this.requestsProvider.post(PATH_GET_INDEX+"/filters", params);
  }

  async getUsersIndexWithFilters(apiEndpoint: string, params: SearchParameters): Promise<SearchResponse> {
    const PATH_GET_INDEX = PATH(apiEndpoint, 'Index');
    return this.requestsProvider.post(PATH_GET_INDEX+"/filtered", params);
  }

  async getGroupsIndexWithFilters(apiEndpoint: string, params: SearchParameters): Promise<SearchResponse> {
    const PATH_GET_INDEX = PATH(apiEndpoint, 'Index');
    return this.requestsProvider.post(PATH_GET_INDEX+"/filters",params);
  }

  async getFilterOptions(): Promise<FilterOptionsResponse> {
    return this.requestsProvider.get(this.getFilterOptionsUrl);
  }
}

import { Component, OnInit } from '@angular/core';
import { BarcodeScannerService } from 'libs/services/src/lib/barcode-scanner/barcode-scanner.service';

@Component({
  selector: 'suite-release-reservations',
  templateUrl: './release-reservations.component.html',
  styleUrls: ['./release-reservations.component.scss']
})
export class ReleaseReservationsComponent implements OnInit {

  constructor(private barcodeScannerService: BarcodeScannerService) { }

  async scanBarcode(action) {
    var scanResult = await (await this.barcodeScannerService.useScanner(action));
  }

  ngOnInit() {
  }

}

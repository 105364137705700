import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {environment, ExpeditionService, IntermediaryService} from "@suite/services";
import {PositionsToast} from "../../../../services/src/models/positionsToast.type";
import {TimesToastType} from "../../../../services/src/models/timesToastType";
import {ScannerManualComponent} from "../../components/scanner-manual/scanner-manual.component";
import {ToolbarProvider} from "../../../../services/src/providers/toolbar/toolbar.provider";
import {AudioProvider} from "../../../../services/src/providers/audio-provider/audio-provider.provider";

// Scandit required
declare let ScanditMatrixSimple;
const HEADER_BACKGROUND: string = '#222428';
const HEADER_COLOR: string = '#FFFFFF';

@Component({
  selector: 'suite-incidence-code-scan',
  templateUrl: './incidence-code-scan.component.html',
  styleUrls: ['./incidence-code-scan.component.scss']
})
export class IncidenceCodeScanComponent implements OnInit {

  @ViewChild(ScannerManualComponent) scannerManual: ScannerManualComponent;

  private expeditionId: number = null;
  public deliveryRequestExternalId: string = null;
  public url: any;
  public info: string = '';
  public msg: string = '';

  private lastCodeScanned: string = 'start';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private intermediaryService: IntermediaryService,
    private expeditionService: ExpeditionService,
    private toolbarProvider: ToolbarProvider,
    private audioProvider: AudioProvider
  ) {}

  ngOnInit() {
    if(this.activatedRoute && this.activatedRoute.snapshot && this.activatedRoute.snapshot.params && this.activatedRoute.snapshot.params.id) {
      this.expeditionId = isNaN(this.activatedRoute.snapshot.params.id) ? null : parseInt(this.activatedRoute.snapshot.params.id);
      this.deliveryRequestExternalId = this.activatedRoute.snapshot.params.code;
      this.url = this.activatedRoute.snapshot.url.map(value => value.path);
      if(this.url[0]=='scan'){
        this.info = 'Escanee el código de incidencia descargado previamente para comprobar el paquete.';
        this.msg = 'CÓDIGO DE INCIDENCIA';

        if(this.activatedRoute.snapshot.paramMap.get('camera')){
          this.throwScandit();
        }else{
          setTimeout(() => this.scannerManual.focusToInput(), 1000);
        }
      }else{
        if(this.url[0]=='change'){
          this.info = 'Escanee cualquiera de los códigos de bultos de la expedición.';
          this.msg = 'CÓDIGO DE BULTO';
        }
        setTimeout(() => this.scannerManual.focusToInput(), 1000);
      }
    }
    this.toolbarProvider.currentPage.next('Generar etiquetas de envio');
  }

  public async codeScanned(data: string) {
    await this.intermediaryService.presentLoadingNew('Comprobando código...');
    if(this.url[0]=='scan') {
      this.expeditionService
        .postCheckIncidenceForExpedition(this.expeditionId, {incidenceCode: data})
        .subscribe(async (res) => {
          if (res.code == 200 && res.data) {
            await this.intermediaryService.dismissLoadingNew();
            this.audioProvider.playDefaultOk();
            await this.intermediaryService.presentToastSuccess('¡Código validado!', TimesToastType.DURATION_SUCCESS_TOAST_2000, PositionsToast.BOTTOM);
            this.scannerManual.setValue(null);
            await this.router.navigate([`/order-preparation/id/${this.expeditionId}`, {incidenceResolved: true}]);
          } else {
            await this.intermediaryService.dismissLoadingNew();
            const errorMessage = "Ha ocurrido un error al intentar comprobar el código de la incidencia.";
            this.audioProvider.playDefaultError();
            await this.intermediaryService.presentToastError(errorMessage, PositionsToast.BOTTOM);
            this.scannerManual.setValue(null);
            this.scannerManual.focusToInput();
          }
        }, async (error) => {
          await this.intermediaryService.dismissLoadingNew();
          let errorMessage = "Ha ocurrido un error al intentar comprobar el código de la incidencia.";
          if (error && error.error && error.error.errors) {
            errorMessage = error.error.errors;
          }
          this.audioProvider.playDefaultError();
          await this.intermediaryService.presentToastError(errorMessage, PositionsToast.BOTTOM);
          this.scannerManual.setValue(null);
          this.scannerManual.focusToInput();
        })
    }else{
      this.expeditionService
        .postCheckPackageForExpedition(this.expeditionId, {packageCode: data})
        .subscribe(async (res) => {
          if (res.code == 200 && res.data) {
            await this.intermediaryService.dismissLoadingNew();
            this.audioProvider.playDefaultOk();
            await this.intermediaryService.presentToastSuccess('¡Código validado!', TimesToastType.DURATION_SUCCESS_TOAST_2000, PositionsToast.BOTTOM);
            this.scannerManual.setValue(null);
            await this.router.navigate([`/order-preparation/id/${this.expeditionId}`, {incidenceResolved: true}]);
          } else {
            await this.intermediaryService.dismissLoadingNew();
            const errorMessage = "Ha ocurrido un error al intentar comprobar el código de la incidencia.";
            this.audioProvider.playDefaultError();
            await this.intermediaryService.presentToastError(errorMessage, PositionsToast.BOTTOM);
            this.scannerManual.setValue(null);
            this.scannerManual.focusToInput();
          }
        }, async (error) => {
          await this.intermediaryService.dismissLoadingNew();
          let errorMessage = "Ha ocurrido un error al intentar comprobar el código de la incidencia.";
          if (error && error.error && error.error.errors) {
            errorMessage = error.error.errors;
          }
          this.audioProvider.playDefaultError();
          await this.intermediaryService.presentToastError(errorMessage, PositionsToast.BOTTOM);
          this.scannerManual.setValue(null);
          this.scannerManual.focusToInput();
        })
    }
  }

  public throwScandit() {
    this.lastCodeScanned = '';
    ScanditMatrixSimple.init(async (response) => {
      if(response && response.result && response.actionIonic) {
        this.throwScanditEvent(response.actionIonic);
      } else if (response && response.barcode && response.barcode.data) {
        if(response.barcode.data != this.lastCodeScanned){
          this.lastCodeScanned = response.barcode.data;
          const codeScanned = response.barcode.data;
          ScanditMatrixSimple.finish();

          setTimeout(async () => {
            if (codeScanned.trim() != "") {
              this.androidCodeScanned(codeScanned);
            } else {
              this.scannerManual.setValue(null);
              this.audioProvider.playDefaultError();
              await this.intermediaryService.presentToastError('El código de paquete escaneado no es válido para la operación que se espera realizar.', PositionsToast.BOTTOM);
              this.scannerManual.focusToInput();
            }
          },100);
        }
      } else if (response.action == 'request') {
        let responseData = null;

        if (response.data) {
          responseData = JSON.parse(response.data);
        }

        if (responseData) {
          switch (response.requestType) {
            case "postCheckIncidenceForExpedition":
            case "postCheckPackageForExpedition":
              if (response.code === 200 && responseData.data) {
                await this.intermediaryService.dismissLoadingNew();
                this.audioProvider.playDefaultOk();
                await this.intermediaryService.presentToastSuccess('¡Código validado!', TimesToastType.DURATION_SUCCESS_TOAST_2000, PositionsToast.BOTTOM);
                this.scannerManual.setValue(null);
                await this.router.navigate([`/order-preparation/id/${this.expeditionId}`, {incidenceResolved: true}]);
              } else {
                await this.intermediaryService.dismissLoadingNew();
                const errorMessage = "Ha ocurrido un error al intentar comprobar el código de la incidencia.";
                this.audioProvider.playDefaultError();
                await this.intermediaryService.presentToastError(errorMessage, PositionsToast.BOTTOM);
                this.scannerManual.setValue(null);
                this.scannerManual.focusToInput();
              }
              break;
          }
        }
      }
    }, 'Escanear Incidencia', HEADER_BACKGROUND, HEADER_COLOR);
  }

  private throwScanditEvent(event: string) {
    switch (event) {
      case 'lastCodeScannedStart':
        this.lastCodeScanned = '';
        break;
      case 'hideText':
        ScanditMatrixSimple.showText(false);
        break;
    }
  }

  private async androidCodeScanned(data: string) {
    await this.intermediaryService.presentLoadingNew('Comprobando código...');
    if(this.url[0]=='scan') {
      ScanditMatrixSimple.request("POST", environment.apiBase+"/opl-expedition/check/incidence/code/" + this.expeditionId, {incidenceCode: data}, localStorage.getItem("access_token"), "postCheckIncidenceForExpedition");
    }else{
      ScanditMatrixSimple.request("POST", environment.apiBase+"/opl-expedition/check/package/code/" + this.expeditionId, {packageCode: data}, localStorage.getItem("access_token"), "postCheckPackageForExpedition");
    }
  }
}

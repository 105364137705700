import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from "@angular/common";
import {
  GroupWarehousePickingModel,
  GroupWarehousePickingService, IntermediaryService,
  UserTimeModel,
  UserTimeService
} from '@suite/services';
import { PickingParametrizationProvider } from "../../../../services/src/providers/picking-parametrization/picking-parametrization.provider";
import { WorkwavesService } from "../../../../services/src/lib/endpoint/workwaves/workwaves.service";
import { WorkwaveModel } from "../../../../services/src/models/endpoints/Workwaves";
import { AlertController, Events, LoadingController } from "@ionic/angular";
import { TableTypesOSComponent } from "../table-types/table-types.component";
import { TableRequestsOrdersOSComponent } from "../table-requests-orders/table-requests-orders.component";
import { TableEmployeesOSComponent } from "../table-employees/table-employees.component";
import { Observable } from 'rxjs';
import {Router} from "@angular/router";
import { TimesToastType } from '../../../../services/src/models/timesToastType';
import {TextParserService} from "../../../../services/src/lib/text-parser/text-parser.service";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'list-workwave-template-online-store',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateY(0)' })),
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.17s ease-in-out')
      ]),
      transition(':leave', [
        animate('0.17s ease-in-out', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class ListWorkwaveTemplateRebuildOSComponent implements OnInit {

  private GROUPS_WAREHOUSES_LOADED = "groups-warehouses-loaded-os";
  private EMPLOYEES_LOADED = "employees-loaded-os";
  private REQUEST_ORDERS_LOADED = "request-orders-loaded-os";
  private TEAM_ASSIGNATIONS_LOADED = "team-assignations-loaded-os";
  private DRAW_CONSOLIDATED_MATCHES = "draw-consolidated-matches-os";
  private TYPE_EXECUTION_ID = 1;

  @Input() typeWorkwave: number;
  @ViewChild(TableTypesOSComponent) tableTypes: TableTypesOSComponent;
  @ViewChild(TableRequestsOrdersOSComponent) tableRequests: TableRequestsOrdersOSComponent;
  @ViewChild(TableEmployeesOSComponent) tableEmployees: TableEmployeesOSComponent;

  template: any;
  disableEdition: boolean = false;

  listTypesToUpdate: Array<number> = new Array<number>();
  listGroupsWarehousesToUpdate: Array<GroupWarehousePickingModel.GroupWarehousesSelected> = new Array<GroupWarehousePickingModel.GroupWarehousesSelected>();
  listEmployeesToUpdate: Array<number> = new Array<number>();
  listRequestOrdersToUpdate: Array<number> = new Array<number>();
  listDeliveryRequestOrdersToUpdate: Array<number> = new Array<number>();
  private listWarehousesThresholdAndSelectedQty: any = {};
  private checkRequestsSelectedIsOverThreshold: boolean = false;
  private ObservablePendings: Array<any> = new Array();

  private loading: HTMLIonLoadingElement = null;
  enlarged = false;
  responseQuantities: WorkwaveModel.AssignationsByRequests[];

  fieldsToLoadTeamAssignations: any = {};

  public showCreateButton: boolean = false;

  constructor(
    private location: Location,
    private events: Events,
    private router: Router,
    private intermediaryService: IntermediaryService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private groupWarehousePickingService: GroupWarehousePickingService,
    private userTimeService: UserTimeService,
    private workwavesService: WorkwavesService,
    private pickingParametrizationProvider: PickingParametrizationProvider,
    private textParserService: TextParserService
  ) {}

  ngOnInit() {
    this.pickingParametrizationProvider.resetLoadings();

    this.fieldsToLoadTeamAssignations = {};
    this.tableRequests.loadingListRequestOrdersOnlineStore = 0;
    this.template = {
      name: 'Nueva Ola de trabajo',
      id: null
    };
    this.loadDefaultWorkWaveData();
    this.typeChanged([20, 30]);
  }

  ngOnDestroy() {
    try {
      for (let observable of this.ObservablePendings) {
        observable.unsubscribe();
      }
    } catch (error) {
      console.error('error -> ', error);
    }
  }

  private loadDefaultWorkWaveData() {
    this.pickingParametrizationProvider.loadingListEmployees++;
    this.loadEmployees();
    this.pickingParametrizationProvider.loadingListRequestOrdersOnlineStore++;
    this.tableRequests.loadingListRequestOrdersOnlineStore++;
    this.loadRequestOrders();
    this.pickingParametrizationProvider.loadingListTeamAssignations++;
    this.loadTeamAssignations();
  }

  private loadEmployees() {
    let obs = this.userTimeService.getListUsersRegister();
    this.ObservablePendings.push(obs.subscribe((res: UserTimeModel.ListUsersRegisterTimeActiveInactive) => {
      this.pickingParametrizationProvider.listEmployees = res;
      this.events.publish(this.EMPLOYEES_LOADED);
      this.pickingParametrizationProvider.loadingListEmployees--;
    }, (error) => {
      console.error('Error::Subscribe:userTimeService::getListUsersRegister::', error);
      this.pickingParametrizationProvider.listEmployees = { usersActive: [], usersInactive: [] };
      this.events.publish(this.EMPLOYEES_LOADED);
      this.pickingParametrizationProvider.loadingListEmployees--;
    }));
  }

  private loadRequestOrders() {
    this.pickingParametrizationProvider.loadingListTeamAssignations++;
    if (this.listTypesToUpdate.length > 0) {
      let obs = this.workwavesService.postMatchLineRequestOnlineStore({ preparationLinesTypes: this.listTypesToUpdate });
      this.ObservablePendings.push(obs.subscribe((res: WorkwaveModel.ResponseMatchLineRequestOnlineStore) => {
        console.log('Test::Subscribe');
        if (res.code === 201) {
          this.pickingParametrizationProvider.listRequestOrdersOnlineStore = res.data;
          this.events.publish(this.REQUEST_ORDERS_LOADED);
          this.pickingParametrizationProvider.loadingListRequestOrdersOnlineStore--;
          this.tableRequests.loadingListRequestOrdersOnlineStore--;
          this.pickingParametrizationProvider.loadingListTeamAssignations--;
        } else {
          console.error('Error::Subscribe:workwavesService::postMatchLineRequest::', res);
          this.pickingParametrizationProvider.listRequestOrdersOnlineStore = [];
          this.events.publish(this.REQUEST_ORDERS_LOADED);
          this.pickingParametrizationProvider.loadingListRequestOrdersOnlineStore--;
          this.tableRequests.loadingListRequestOrdersOnlineStore--;
          this.pickingParametrizationProvider.loadingListTeamAssignations--;
        }
      }, (error) => {
        console.error('Error::Subscribe:workwavesService::postMatchLineRequest::', error);
        this.pickingParametrizationProvider.listRequestOrdersOnlineStore = [];
        this.events.publish(this.REQUEST_ORDERS_LOADED);
        this.pickingParametrizationProvider.loadingListRequestOrdersOnlineStore--;
        this.tableRequests.loadingListRequestOrdersOnlineStore--;
        this.pickingParametrizationProvider.loadingListTeamAssignations--;
        if(error.error.code === 405) {
          this.intermediaryService.presentToastError(error.error.errors, 'bottom', 4000)
        }
      }));
    } else {
      this.pickingParametrizationProvider.listRequestOrdersOnlineStore = [];
      this.events.publish(this.REQUEST_ORDERS_LOADED);
      this.pickingParametrizationProvider.loadingListRequestOrdersOnlineStore--;
      this.tableRequests.loadingListRequestOrdersOnlineStore--;
      this.pickingParametrizationProvider.loadingListTeamAssignations--;
    }
  }

  private loadTeamAssignations() {
    if (this.listEmployeesToUpdate.length > 0 && (this.listRequestOrdersToUpdate.length > 0 || this.listDeliveryRequestOrdersToUpdate.length > 0)) {
      let obs = this.workwavesService
        .postAssignUserToMatchLineOnlineStoreRequest({
          requestIds: this.listRequestOrdersToUpdate,
          deliveryRequestIds: this.listDeliveryRequestOrdersToUpdate,
          userIds: this.listEmployeesToUpdate
        });
      this.ObservablePendings.push(obs.subscribe((res: WorkwaveModel.ResponseAssignUserToMatchLineRequestOnlineStore) => {
        if (res.code === 201) {
          this.showCreateButton = true;

          let resData = res.data;
          this.pickingParametrizationProvider.listTeamAssignations = resData.assignations;
          this.events.publish(this.TEAM_ASSIGNATIONS_LOADED);
          if (resData.quantities) {
            this.events.publish(this.DRAW_CONSOLIDATED_MATCHES, resData.quantities);
            this.responseQuantities = resData.quantities;
          }
          this.pickingParametrizationProvider.loadingListTeamAssignations--;
        } else {
          this.showCreateButton = false;

          console.error('Error::Subscribe:workwavesService::postAssignUserToMatchLineRequest::', res);
          this.pickingParametrizationProvider.listTeamAssignations = new Array<WorkwaveModel.TeamAssignations>();
          this.events.publish(this.TEAM_ASSIGNATIONS_LOADED);
          this.pickingParametrizationProvider.loadingListTeamAssignations--;
        }
      }, (error) => {
        this.showCreateButton = false;

        let errorMessage = "[ERR1308] Ha ocurrido un error al intentar generar las asignaciones de usuarios a los pickings.";
        if (error && error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        console.error('[ERR1308] Error::Subscribe:workwavesService::postAssignUserToMatchLineRequest::', error);
        this.intermediaryService.presentWarning(this.textParserService.formatTextToWarningAlertMessage(errorMessage), null);

        this.pickingParametrizationProvider.listTeamAssignations = new Array<WorkwaveModel.TeamAssignations>();
        this.events.publish(this.TEAM_ASSIGNATIONS_LOADED);
        this.pickingParametrizationProvider.loadingListTeamAssignations--;
      }));
    } else {
      this.pickingParametrizationProvider.listTeamAssignations = new Array<WorkwaveModel.TeamAssignations>();
      this.events.publish(this.TEAM_ASSIGNATIONS_LOADED);
      this.pickingParametrizationProvider.loadingListTeamAssignations--;
    }
  }

  saveWorkWave() {
    if (this.listEmployeesToUpdate.length < 1) {
      this.intermediaryService.presentToastError('Seleccione almenos un usuario para generar las tareas de picking.', TimesToastType.DURATION_ERROR_TOAST);
    } else if (this.listRequestOrdersToUpdate.length < 1 && this.listDeliveryRequestOrdersToUpdate.length < 1) {
      this.intermediaryService.presentToastError('Seleccione almenos una operación de envío para generar las tareas de picking.', TimesToastType.DURATION_ERROR_TOAST);
    } else {
      this.presentAlertConfirmPickings();
    }
  }

  goPreviousPage() {
    this.router.navigate(['workwaves-scheduled'], { replaceUrl: true });
  }

  //region Response from table components
  typeChanged(data) {
    this.showCreateButton = false;

    this.listTypesToUpdate = data;
    this.pickingParametrizationProvider.loadingListRequestOrdersOnlineStore++;
    this.tableRequests.loadingListRequestOrdersOnlineStore++;
    this.loadRequestOrders();
  }

  employeeChanged(data) {
    this.showCreateButton = false;

    this.listEmployeesToUpdate = data.user;
    this.listRequestOrdersToUpdate = data.table.listSelected;
    this.listDeliveryRequestOrdersToUpdate = data.table.listSelectedDelivery;
    this.pickingParametrizationProvider.loadingListTeamAssignations++;
    this.loadTeamAssignations();
  }

  requestOrderChanged(data) {
    if (data.store === true && data.type === true) {
      this.typeChanged(data.data.typesShippingOrders);
    }
  }

  loadTeamAssignationsEmitted(data) {
    if (typeof data.user != 'undefined') {
      this.fieldsToLoadTeamAssignations.user = data.user;
    }
    if (typeof data.table != 'undefined') {
      this.fieldsToLoadTeamAssignations.table = data.table;
    }
    if (typeof data.data != 'undefined') {
      this.fieldsToLoadTeamAssignations.data = data.data;
    }
    if (typeof this.fieldsToLoadTeamAssignations.data == 'undefined') {
      this.fieldsToLoadTeamAssignations.data = {};
    }

    if (this.fieldsToLoadTeamAssignations.user === true && this.fieldsToLoadTeamAssignations.table === true) {
      this.fieldsToLoadTeamAssignations.data.user = this.tableEmployees.getSelectedEmployees();
      this.employeeChanged(this.fieldsToLoadTeamAssignations.data);
    }
  }
  //endregion

  enlarge() {
    if (this.enlarged) {
      let top = document.getElementsByClassName('stores-employees')[0] as HTMLElement;
      top.style.height = '25vh';
      this.enlarged = !this.enlarged;
    } else {
      let top = document.getElementsByClassName('stores-employees')[0] as HTMLElement;
      top.style.height = 'calc(100vh - 52px - 56px - 8px)';
      this.enlarged = !this.enlarged;
    }
  }

  private generateWorkWave() {
    this.workwavesService
      .postConfirmMatchLineRequestOnlineStore({
        type: this.TYPE_EXECUTION_ID,
        requestIds: this.listRequestOrdersToUpdate,
        deliveryRequestIds: this.listDeliveryRequestOrdersToUpdate,
        userIds: this.listEmployeesToUpdate
      })
      .then((res: WorkwaveModel.ResponseConfirmMatchLineRequestOnlineStore) => {
        if (res.code === 201) {
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }
          this.intermediaryService.presentToastSuccess('Tareas de picking generadas correctamente', TimesToastType.DURATION_SUCCESS_TOAST_3750);
          this.goPreviousPage();
        } else {
          let errorMessage = "[ERR1307] Ha ocurrido un error al intentar generar la ola de trabajo con los pickings indicados..";
          if (res && res.errors) {
            errorMessage = res.errors;
          }
          console.error('[ERR1307] Error::Subscribe:workwavesService::postConfirmMatchLineRequest::', res);
          this.intermediaryService.presentWarning(this.textParserService.formatTextToWarningAlertMessage(errorMessage), null);

          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }
        }
      }, (error) => {
        console.error('Error::Subscribe:workwavesService::postConfirmMatchLineRequest::', error);
        if (this.loading) {
          this.loading.dismiss();
          this.loading = null;
        }
      })
      .catch((error) => {
        console.error('Error::Subscribe:workwavesService::postConfirmMatchLineRequest::', error);
        if (this.loading) {
          this.loading.dismiss();
          this.loading = null;
        }
      });
  }

  async presentAlertWarningOverThreshold(listWarehousesOverThreshold: Array<string>) {
    let msg = '';
    if (listWarehousesOverThreshold.length === 1) {
      msg = `Se ha superado el umbral máximo de envío a la tienda <b>${listWarehousesOverThreshold[0]}</b>. Ajuste las órdenes seleccionadas al máximo de la tienda.`
    } else {
      let warehousesOverThreshold = listWarehousesOverThreshold.join(', ');
      msg = `Se ha superado el umbral máximo de envío a las tienda <b>${warehousesOverThreshold}</b>. Ajuste las órdenes seleccionadas al máximo de cada tienda.`
    }

    const alert = await this.alertController.create({
      header: '¡Umbral máximo superado!',
      message: msg,
      buttons: ['Cerrar']
    });

    await alert.present();
  }

  async presentAlertConfirmPickings() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      message: 'Se generará y ejecutará la ola de trabajo con las especificaciones indicadas. ¿Continuar con la creación?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.showLoading('Lanzando ola de trabajo...').then(() => this.generateWorkWave());
          }
        }
      ]
    });

    await alert.present();
  }

  async showLoading(message: string) {
    this.loading = await this.loadingController.create({
      message: message,
      translucent: true,
    });
    return await this.loading.present();
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'suite-workwave-template-online-store',
  templateUrl: './workwave-template-online-store.component.html',
  styleUrls: ['./workwave-template-online-store.component.scss']
})
export class WorkwaveTemplateOnlineStoreComponent implements OnInit {

  constructor() {}

  ngOnInit() {

  }

}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgZone } from '@angular/core';
import { app, environment } from '../../../../services/src/environments/environment';
import { AuthenticationService, Oauth2Service, TariffService, WarehouseModel, GlobalVariableService } from '@suite/services';
import { Router } from '@angular/router';
import { ScanditService } from "../../../../services/src/lib/scandit/scandit.service";
import { ReceptionScanditService } from "../../../../services/src/lib/scandit/reception/reception.service";
import { PrintTagsScanditService } from "../../../../services/src/lib/scandit/print-tags/print-tags.service";
import { Events, MenuController, PopoverController } from "@ionic/angular";
import { SealScanditService } from "../../../../services/src/lib/scandit/seal/seal.service";
import { ProductInfoScanditService } from "../../../../services/src/lib/scandit/product-info/product-info.service";
import { ToolbarProvider } from "../../../../services/src/providers/toolbar/toolbar.provider";
import { LoginComponent } from '../../login/login.page';
import { AuditMultipleScanditService } from "../../../../services/src/lib/scandit/audit-multiple/audit-multiple.service";
import { AlertPopoverComponent } from "../alert-popover/alert-popover.component";
import { WarehouseReceptionAlertService } from "../../../../services/src/lib/endpoint/warehouse-reception-alert/warehouse-reception-alert.service";
import Warehouse = WarehouseModel.Warehouse;
import { LocalStorageProvider } from "../../../../services/src/providers/local-storage/local-storage.provider";
import { PickingStoreService } from "../../../../services/src/lib/endpoint/picking-store/picking-store.service";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material';
import { MarketplacesService } from "../../../../services/src/lib/endpoint/marketplaces/marketplaces.service";
import { PositioningManualProvider } from "../../../../services/src/providers/positioning-manual/positioning-manual.provider";
import { SizeRegularizationService } from "../../../../services/src/lib/scandit/size-regularization/size-regularization.service";
import { OutScanIncidenceService } from "../../../../services/src/lib/endpoint/out-scan-incidence/out-scan-incidence.service";
import { OutScansIncidencesComponent } from "../../out-scans-incidences/out-scans-incidences.component";
import { InScansIncidenceService } from 'libs/services/src/lib/endpoint/in-scans-incidence/in-scans-incidence.service';
import { InScansIncidencesComponent } from '../../in-scans-incidences/in-scans-incidences.component';
import { BarcodeScannerService } from '../../../../services/src/lib/barcode-scanner/barcode-scanner.service';
/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 700,
  hideDelay: 3000,
  touchendHideDelay: 3000,
};

type MenuItemList = (MenuSectionGroupItem | MenuSectionItem)[];

interface MenuSectionGroupItem {
  title: string,
  open: boolean,
  type: 'wrapper',
  children: (MenuSectionGroupItem | MenuSectionItem)[],
  thirdLevel?: boolean
  tooltip?: string,
  amount?: number,
  id?: string
}

interface MenuSectionItem {
  title: string,
  id?: string,
  url: string,
  icon: string,
  notification?: boolean
  children?: (MenuSectionGroupItem | MenuSectionItem)[];
  header?: boolean
  tooltip?: string,
  amount?: number
}

interface GlobalVariable {
  createdAt: string,
  id: number,
  type: GlobalVariablesType,
  typeObject: {
    id: number,
    name: string,
    workwaves: false,
    type: string,
    tooltip: string
  },
  typeVariable: number,
  updatedAt: string,
  value: string
}

enum GlobalVariablesType {
  CONSOLIDATED_MINIMUM_ALLOCATION_THRESHOLD = 1,
  OT_MINIMUM_ALLOCATION_THRESHOLD = 2,
  USERS_WHO_GENERATE_REPLACEMENTS = 3,
  SECONDS_COUNTDOWN_TO_CONFIRM_EMPTY_WAY = 4,
  SECONDS_TO_NOTIFY_TO_AVELON_PREDISTRIBUTION = 5,
  LIMIT_STORES_SALES_FOR_ALGORITHM = 6,
  LIMIT_STORES_DAY_SALES_FOR_ALGORITHM = 7,
  REJECT_REASON_REQUESTS_WO_STOCK = 8,
  DAYS_TO_CHECK_PRODUCT_MOVEMENTS = 9,
  HOURS_TO_CHECK_DUPLICATE_OUTPUT_SCAN_AVELON = 10,
  MINUTES_FOR_RESERVE_EXPIRATION = 11,
  REJECT_REASON_DELIVERY_AND_REQUESTS_STORES = 12,
  SECONDS_OF_INACTIVITY_TO_REQUEST_NEW_LOCATION = 13,
  SORTER_ENABLED = 14
}

@Component({
  selector: 'suite-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
  ],
})
export class MenuComponent implements OnInit {
  project_selector: any;
  @Input() set alloweds(allowed) {
    this.filterPages(allowed || { logout: true });
  }
  isActived: boolean;
  isNewTariff: boolean = false;
  versionUpdate: any;
  private app = app;

  iconsDirection = 'start';
  displaySmallSidebar = false;
  currentRoute: string = "";

  pickingTasksStoresAmount: number = 0;
  reservedExpiredAmount: number = 0;

  sgaPages: MenuItemList = [
    /*    {
          title: 'Registro horario',
          id: 'time-registration',
          url: '/user-time',
          icon: 'time'
        },*/
    {
      title: 'Logística',
      open: false,
      type: 'wrapper',
      icon: 'filing',
      children: [
        {
          title: 'Productos',
          id: 'products-sga',
          url: '/products',
          icon: 'basket'
        },
        /*{
          title:'Etiquetas',
          id:'labels',
          url:'/labels',
          icon:'basket'
        },*/
        {
          title: 'Mapa Almacén',
          id: 'warehouses-map-sga',
          url: '/warehouse/manage',
          icon: 'apps'
        },
        {
          title: 'Control Embalajes',
          id: 'packing-control-sga',
          url: '/audits',
          icon: 'list-box'
        },
        {
          title: 'Embalajes',
          id: 'packing-sga',
          url: '/jails/menu',
          icon: 'grid'
        },
        {
          title: 'Incidencias',
          id: 'incidences',
          url: '/incidences',
          icon: 'notifications'
        },
        {
          title: 'Incidencias Escaneos Salida',
          id: 'out-scans-incidences',
          url: '/out-scans-incidences',
          icon: 'notifications',
        },
        {
          title: 'Incidencias Escaneos Recepción',
          id: 'in-scans-incidences',
          url: '/in-scans-incidences',
          icon: 'notifications',
        }
      ]
    },
    {
      title: 'Olas de trabajo',
      open: false,
      type: 'wrapper',
      icon: 'hammer',
      children: [
        {
          title: 'Picking directo/consolidado',
          id: 'workwaves-direct-consolidated',
          url: '/workwave-template-rebuild',
          icon: 'add-circle'
        },
        {
          title: 'Peticiones online/tienda',
          id: 'workwaves-online-store',
          url: '/workwave/online-store',
          icon: 'add-circle-outline'
        },
        {
          title: 'Listado',
          id: 'workwaves-scheduled',
          url: '/workwaves-scheduled',
          icon: 'code'
        },
        {
          title: 'Historial',
          id: 'workwaves-history',
          url: '/workwaves-history',
          icon: 'code-download'
        },
        {
          title: 'Pickings en curso',
          id: 'pickings-execution',
          url: '/workwaves-scheduled/pickings',
          icon: 'code-working'
        }
      ]
    },
    {
      title: 'Gestión de usuarios',
      open: false,
      type: 'wrapper',
      icon: 'contacts',
      children: [
        {
          title: 'Gestión de usuarios',
          url: '/users/menu',
          id: 'user-management',
          icon: 'people'
        },
        {
          title: 'Asignación de roles',
          id: 'role-assignment',
          url: '/role-assignment',
          icon: 'key'
        },
        {
          title: 'Roles',
          id: 'roles',
          url: '/roles/menu',
          icon: 'person'
        },
      ]
    },
    {
      title: 'Control de exposición',
      open: false,
      type: 'wrapper',
      icon: 'cart',
      children: [
        {
          title: 'Productos sin imprimir',
          id: 'new-unprinted-products-sga',
          url: '/list-new-products',
          icon: 'basket',
          tooltip: 'Listado de nuevos productos recibidos que aún no han sido impresos'
        },
        {
          title: 'Ocultar alertas de recepción',
          id: 'reception-hide-alert-sga',
          url: '/reception-hide-alert',
          icon: 'alert',
          tooltip: 'Listado de qué almacenes pueden ocultar las alertas de nuevas recepciones'
        }
      ]
    },
    {
      title: 'Defectuosos',
      open: false,
      type: 'wrapper',
      icon: 'paper',
      children: [
        {
          title: 'Registro',
          id: 'defectives-registry-sga',
          url: '/defective-registry',
          icon: 'list-box',
          tooltip: 'Listado de registro de defectusosos'
        },
        {
          title: 'Histórico',
          id: 'defectives-historic-sga',
          url: '/defective-historic',
          icon: 'filing',
          tooltip: 'Listado de registro histórico de defectuosos'
        },
        {
          title: 'Parametrización',
          id: 'defectives-parameterization',
          url: '/damaged-shoes',
          icon: 'options',
          tooltip: 'Parametrización de estados'
        },
        {
          title: 'Tipos de daños',
          id: 'defectives-types',
          url: '/defective-management',
          icon: 'flag',
          tooltip: 'Tipos de daños'
        },
        {
          title: 'Zonas',
          id: 'defectives-zones',
          url: '/defective-zones',
          icon: 'flag',
          tooltip: 'Zonas'
        },
      ]
    },
    {
      title: 'Devoluciones fábrica',
      open: false,
      type: 'wrapper',
      icon: 'return-left',
      children: [
        {
          title: 'Seguimiento de Devoluciones',
          id: 'returns-tracking-list',
          url: '/return-tracking-list',
          icon: 'list',
          tooltip: 'Seguimiento de devoluciones'
        },
        {
          title: 'Condiciones proveedores',
          id: 'returns-supplier-conditions',
          url: '/supplier-conditions',
          icon: 'list-box',
          tooltip: 'Listado de condiciones de proveedores'
        },
        {
          title: 'Tipos de devoluciones',
          id: 'returns-types',
          url: '/return-types',
          icon: 'list-box',
          tooltip: 'Listado de tipos de devoluciones'
        },
        {
          title: 'Listado Históricos',
          id: 'returns-historic',
          url: '/returns-historic',
          icon: 'list-box',
          tooltip: 'Listado de históricos de devoluciones'
        }
      ]
    },
    {
      title: 'Picking tiendas',
      open: false,
      type: 'wrapper',
      icon: 'cart',
      children: [
        {
          title: 'Calendario',
          id: 'picking-store-calendar-sga',
          url: '/calendar-sga',
          icon: 'md-calendar'
        }
      ]
    },
    {
      title: 'Configuración',
      open: false,
      type: 'wrapper',
      icon: 'options',
      children: [
        {
          title: 'Variables globales',
          id: 'global-variables',
          url: '/global-variables',
          icon: 'cog'
        },
        {
          title: 'Recepcion Final',
          id: 'reception-final',
          url: '/reception-final',
          icon: 'pricetags'
        },
        {
          title: 'Almacenes',
          id: 'warehouses',
          url: '/warehouses',
          icon: 'filing'
        },
        {
          title: 'Grupos de almacenes',
          id: 'warehouses-group',
          url: '/groups/menu',
          icon: 'person'
        },
        {
          title: 'Asignar grupos de almacenes',
          id: 'group-to-warehouse',
          url: '/group-to-warehouse',
          icon: 'people'
        },
        {
          title: 'Grupos de tiendas para picking',
          id: 'group-warehouse-picking',
          url: '/group-warehouse-picking',
          icon: 'people'
        },
        {
          title: 'Agencias de transporte',
          id: 'online-orders-transporters',
          url: '/transports',
          icon: 'train'
        },
        {
          title: 'Aplicaciones instaladas',
          id: 'installed-app-versions',
          url: '/installed',
          icon: 'phone-portrait'
        },
        {
          title: 'Edificios',
          id: 'building',
          url: '/building',
          icon: 'business'
        }
      ]
    },
    {
      title: 'Tarifas',
      id: 'tariff-sga',
      url: '/tariff',
      icon: 'logo-usd'
    },
    {
      title: 'Sorter',
      open: false,
      type: 'wrapper',
      icon: 'apps',
      children: [
        {
          title: 'Plantillas',
          id: 'sorter-templates-sga',
          url: '/sorter/templates',
          icon: 'document'
        },
        {
          title: 'Estantería anexa',
          id: 'sorter-racks',
          url: '/sorter/racks',
          icon: 'grid'
        },
        {
          title: 'Selección de plantilla',
          id: 'sorter-template-selection',
          url: '/sorter/template/selection',
          icon: 'checkbox-outline'
        },
        {
          title: 'Vaciado de calles',
          id: 'sorter-ways-emptying',
          url: '/sorter/ways/emptying',
          icon: 'square-outline'
        }
      ]
    },
    {
      title: 'Recepción de fábrica',
      open: false,
      type: 'wrapper',
      icon: 'archive',
      children: [
        {
          title: 'Proceso recepción',
          id: 'factory-reception-process',
          url: '/receptions-avelon',
          icon: 'pricetags'
        },
        {
          title: 'Recepción sin pedido',
          id: 'factory-reception-process-without-order',
          url: '/receptions-avelon/free',
          icon: 'pricetags'
        },
        {
          title: 'Predistribuciones',
          id: 'factory-reception-predistributions',
          url: '/predistributions',
          icon: 'options'
        },
        {
          title: 'Stock Reservado',
          id: 'factory-reception-reserved-stock',
          url: '/receptions',
          icon: 'hand'
        },
        {
          title: 'Pendiente notificar Avelon',
          id: 'factory-reception-pending-notification-avelon',
          url: '/pr-ta-se-av',
          icon: 'cloud-upload'
        },
        {
          title: 'Estados de expediciones',
          id: 'factory-reception-management-states-expedition',
          url: '/state-expedition-avelon/menu',
          icon: 'cog'
        },
        {
          title: 'Temporadas habilitadas',
          id: 'factory-reception-management-seasons-enabled',
          url: '/seasons-enabled',
          icon: 'cog'
        },
        {
          title: 'Habilitar campos comerciales',
          id: 'factory-reception-management-commercial-fields',
          url: '/commercial-fields',
          icon: 'cog'
        },
        {
          title: 'Marcas habilitadas recepción sin pedido',
          id: 'factory-reception-management-brands-enabled-reception',
          url: '/brands-enabled-reception',
          icon: 'cog'
        },
        {
          title: 'Incidencias',
          id: 'incidences-reception',
          url: '/incidences-reception',
          icon: 'notifications'
        },
        {
          title: 'Incidencias Avelon',
          id: 'factory-reception-avelon-reception-incidences',
          url: '/avelon-incidences-reception',
          icon: 'notifications'
        }
      ]
    },
    {
      title: 'Regiones',
      id: 'regions',
      url: '/regions',
      icon: 'map'
    },
    {
      title: 'Drop Files',
      id: 'drop-file',
      url: '/drop-files',
      icon: 'folder'
    },
    {
      title: 'Regiones',
      id: 'regions',
      url: '/regions',
      icon: 'map'
    },
    {
      title: 'Marketplaces',
      open: false,
      type: 'wrapper',
      icon: 'cart',
      children: [
        {
          title: 'Catálogos Marketplaces',
          id: 'catalogs-marketplaces',
          url: '/marketplaces/catalogs-marketplaces',
          icon: 'apps'
        }
      ]
    },
    {
      title: 'Pedidos Online',
      open: false,
      type: 'wrapper',
      icon: 'build',
      children: [
        {
          title: 'Incidencias',
          id: 'online-orders-incidences-sga',
          url: '/opl-expedition-incidences',
          icon: 'notifications',
          tooltip: 'Lista de incidencias'
        },
        {
          title: 'Expediciones por recoger',
          id: 'online-orders-delivery-to-be-picked-up',
          url: '/expedition-collected',
          icon: 'cog',
          tooltip: 'Lista de expediciones por recoger'
        },
        {
          title: 'Histórico de Envíos',
          id: 'online-orders-shipping-history',
          url: '/transport-orders',
          icon: 'car',
          tooltip: 'Ordenes'
        },
        {
          title: 'Histórico pedidos cancelados',
          id: 'online-orders-cancelled-history',
          url: '/expedition-cancelled',
          icon: 'remove-circle-outline',
          tooltip: 'Lista de expediciones canceladas'
        },
        {
          title: 'Logística Interna',
          id: 'online-orders-logistic-internal',
          url: '/package-history',
          icon: 'cube'
        },
        {
          title: 'Generar expedición',
          id: 'online-orders-manual-shipping',
          url: '/expedition-manual',
          icon: 'add',
          tooltip: 'Genere expediciones manualmente'
        },
        {
          title: 'Contratación Seur',
          id: 'online-orders-hiring-seur',
          url: '/hiring-seur',
          icon: 'copy',
          tooltip: 'Modifique datos de Seur'
        },
        {
          title: 'Contratación Correos Express',
          id: 'online-orders-hiring-correosexpress',
          url: '/hiring-correosexpress',
          icon: 'copy',
          tooltip: 'Modifique datos de Correos Express'
        },
        {
          title: 'Contratación DHL',
          id: 'online-orders-hiring-dhl',
          url: '/hiring-dhl',
          icon: 'copy',
          tooltip: 'Modifique datos de DHL'
        },
        {
          title: 'Cambio de Dirección',
          id: 'online-orders-address-change',
          url: '/orders/online/change-address',
          icon: 'repeat',
          tooltip: 'Cambie, actualice o ajuste la dirección de un pedido antes de su procesado y envío'
        }
      ]
    },
    {
      title: 'Clientes',
      open: false,
      type: 'wrapper',
      icon: 'people',
      children: [
        {
          title: 'Administración de clientes',
          id: 'customers',
          url: '/customers',
          icon: 'people',
          tooltip: 'Lista de clientes'
        },
      ]
    },
    {
      title: 'Reembolsos',
      open: false,
      type: 'wrapper',
      icon: 'cash',
      children: [
        {
          title: 'Reembolsos OK',
          id: 'refunds',
          url: '/refunds/refunds-ok',
          icon: 'checkmark-circle'
        },
        {
          title: 'Reembolsos KO',
          id: 'refunds',
          url: '/refunds/refunds-ko',
          icon: 'alert'
        },
      ]
    },
  ];

  alPages: MenuItemList = [
    /*    {
          title: 'Registro horario',
          id: 'time-registration',
          url: '/user-time',
          icon: 'time',
          tooltip: 'Registrar hora de entrada y salida'
        },*/
    {
      title: 'Productos',
      open: false,
      type: 'wrapper',
      icon: 'basket',
      children: [
        {
          title: 'Productos',
          id: 'products-al',
          url: '/products',
          icon: 'basket',
          tooltip: 'Listado de productos'
        },
        {
          title: 'Localizar',
          icon: 'search',
          url: '/locate',
          id: 'locate',
          tooltip: 'Localizar productos en embalajes'
        },
        //TODO
        {
          title: 'Consulta',
          id: 'products-info',
          url: 'products/info',
          icon: 'information-circle',
          tooltip: 'Consulta Stock de artículos mediante el escáner'
        },
        {
          title: 'Productos recibidos',
          id: 'print-products-received',
          url: '/print/product/received',
          icon: 'archive',
          tooltip: 'Listado de todos los productos recibidos'
        },
        {
          title: 'Productos solicitados',
          id: 'received-products-requested',
          url: '/requested-products',
          icon: 'archive',
          tooltip: 'Listado de todos los productos solicitados que se han recibido'
        },
        {
          title: 'Productos no aptos online',
          id: 'unfit-online-products',
          url: '/unfit-online-products',
          icon: 'archive',
          tooltip: 'Listado de productos no aptos online'
        },
        {
          title: 'Reetiquetado productos',
          id: 'print-product',
          url: 'print/product/relabel',
          icon: 'barcode',
          tooltip: 'Imprimir nuevas etiquetas de productos con cámara'
        },
        {
          title: 'Reetiquetado productos manual',
          id: 'print-product-manual',
          url: '/print/product/relabel',
          icon: 'barcode',
          tooltip: 'Imprimir nuevas etiquetas de productos introduciendo el código manualmente'
        }
      ]
    },
    {
      id: 'regularization',
      title: 'Regularizaciones',
      icon: 'barcode',
      url: '/regularization'
    },
    {
      id: 'regularization',
      title: 'Regularización talla',
      icon: 'barcode',
      url: '/size-regularization'
    },
    {
      title: 'Pedidos online',
      icon: 'basket',
      type: 'wrapper',
      open: false,
      children: [
        {
          title: 'Generar etiquetas de envio',
          id: 'online-orders-generate-labels',
          url: '/order-preparation',
          icon: 'basket',
          tooltip: 'Imprimir etiquetas de contenedores'
        },
        {
          title: 'Incidencias',
          id: 'online-orders-incidences-al',
          url: '/list-alerts',
          icon: 'notifications',
          tooltip: 'listado de incidencias'
        },
        {
          title: 'Pedidos Recibidos',
          id: 'online-orders-package-received',
          url: '/package-received',
          icon: 'cube',
          tooltip: 'Lista de bultos recibidos por Logística Interna'
        },
        {
          title: 'Expediciones por recoger',
          id: 'online-orders-delivery-to-be-picked-up',
          url: '/expedition-collected',
          icon: 'cog',
          tooltip: 'Lista de expediciones por recoger'
        },
        {
          title: 'Histórico de envíos',
          id: 'online-orders-shipping-history',
          url: '/transport-orders',
          icon: 'car',
          tooltip: 'Ordenes'
        },
        {
          title: 'Histórico pedidos cancelados',
          id: 'online-orders-cancelled-history',
          url: '/expedition-cancelled',
          icon: 'remove-circle-outline',
          tooltip: 'Lista de expediciones canceladas'
        }
      ]
    },
    {
      title: 'Tarifas',
      id: 'tarifas',
      open: false,
      type: 'wrapper',
      icon: 'logo-usd',
      notification: this.isNewTariff,
      children: [
        {
          title: 'Tarifas',
          id: 'tariff-al',
          url: '/tariff',
          icon: 'logo-usd',
          notification: this.isNewTariff,
          tooltip: 'Listado de tarifas habilitadas'
        },
        {
          title: 'Código exposición',
          id: 'print-price-tag',
          url: 'print/tag/price',
          icon: 'pricetags',
          tooltip: 'Imprimir etiquetas de exposición escaneando los productos con cámara'
        },
        {
          title: 'Código exposición manual',
          id: 'print-price-tag-manual',
          url: '/print-tag/manual/price',
          icon: 'pricetags',
          tooltip: 'Impresión de códigos de exposición indicando manualmente el código de los productos y el motivo si fuese necesario'
        },
        {
          title: 'Nuevos Productos',
          id: 'new-products',
          url: '/new-products',
          icon: 'basket',
          tooltip: 'Listado de nuevos productos recibidos que aún no han sido impresos'
        }
      ]
    },
    {
      title: 'Logística',
      open: false,
      type: 'wrapper',
      icon: 'send',
      children: [
        {
          title: 'Ubicar/escanear con cámara',
          id: 'positioning',
          icon: 'locate',
          url: 'positioning',
          tooltip: 'Escanear artículos mediante cámara para ubicar'
        },
        {
          title: 'Ubicar/escanear con láser',
          icon: 'locate',
          url: '/positioning/manual',
          id: 'positioning-manual',
          tooltip: 'Escanear artículos mediante láser para ubicar'
        },
        {
          title: 'Creación Picking Pedidos/Peticiones',
          id: 'create-picking-orders-petitions',
          url: '/createPickingOrdersPetitions',
          icon: 'add',
          tooltip: 'Crear pickings de peticiones y pedidos'
        },
        {
          title: 'Traspasos',
          id: 'picking-task-store',
          icon: 'qr-scanner',
          url: '/picking-tasks',
          tooltip: 'Traspasos pendientes de realizar'
        },
        {
          title: 'Listado de peticiones',
          id: 'picking-tasks-stores',
          icon: 'qr-scanner',
          url: '/picking-tasks-stores',
          tooltip: 'Listado de peticiones pendientes de realizar',
          amount: this.pickingTasksStoresAmount
        },
        {
          title: 'Asociar pares a embalajes',
          id: 'picking-scan-packing',
          icon: 'qr-scanner',
          url: '/picking-scan-packing',
          tooltip: 'Asociar pares procesados para traspasos a embalajes y precintarlos'
        },
        {
          title: 'Liberar Reservas Expiradas',
          id: 'free-expired-reserves',
          icon: 'ios-link',
          url: '/free-expired-reserves',
          tooltip: 'Liberar reservas expiradas mediante escaneo con cámara',
          amount: this.reservedExpiredAmount
        },
        {
          title: 'Ubicar defectuosos',
          id: 'defectives-positioning',
          icon: 'warning',
          url: 'defective-positioning',
          tooltip: 'Escanear artículos defectuosos mediante cámara para ubicar'
        },
        {
          title: 'Tareas de Picking',
          id: 'picking-task',
          icon: 'qr-scanner',
          url: '/picking-tasks',
          tooltip: 'Tareas de picking asignadas'
        },
        {
          title: 'Verificación de artículos',
          icon: 'checkmark-circle-outline',
          url: '/picking/online-store/verify',
          id: 'verification-products-online',
          tooltip: 'Sección para verificar los artículos aptos online'
        },
        {
          title: 'Mapa Almacén',
          id: 'warehouses-map-al',
          url: '/warehouse/manage',
          icon: 'apps',
          tooltip: ''
        },
        {
          title: 'Recepcionar embalaje',
          id: 'reception-products-packing',
          url: 'reception',
          icon: 'archive',
          tooltip: 'Recepcionar embalaje completo escaneando la etiqueta con cámara'
        },
        {
          title: 'Recepcionar par a par',
          id: 'reception-products-peer-to-peer',
          url: 'reception-pair-by-pair',
          icon: 'square-outline',
          tooltip: 'Recepcionar par a par los artículos recibidos escanéandolos con la cámara'
        },
        {
          title: 'Embalajes',
          id: 'packing-al',
          url: '/jails/menu',
          icon: 'grid',
          tooltip: 'Listado de embalajes'
        },
        {
          title: 'Reetiquetado embalajes',
          id: 'print-packing',
          url: '/print/packing',
          icon: 'grid',
          tooltip: 'Imprimir nuevas etiquetas de embalajes'
        },
        {
          title: 'Precintar embalaje con cámara',
          id: 'packing-seal',
          url: 'packing/seal',
          icon: 'paper-plane',
          tooltip: 'Precintar embalaje para enviar desde el escáner de la cámara'
        },
        {
          title: 'Precintar embalaje con láser',
          id: 'packing-seal-manual',
          url: '/packing/seal/manual',
          icon: 'paper-plane',
          tooltip: 'Precintar embalaje para enviar desde el láser'
        },
        {
          title: 'Traspaso contenido embalaje',
          id: 'packing-transfer',
          url: '/packing/transfer',
          icon: 'redo',
          tooltip: 'Traspasar toda la mercancía de un embalaje a otro'
        },
        {
          title: 'Recepción de embalaje vacío',
          id: 'reception-empty-packing',
          url: '/packing/carrierEmptyPacking',
          icon: 'exit',
          tooltip: 'Recibir embalajes que han sido enviados vacíos'
        },
        {
          title: 'Envío de embalaje vacío',
          id: 'send-empty-packing',
          url: '/sendEmptyPacking',
          icon: 'send',
          tooltip: 'Enviar embalajes vacíos a otro destino'
        }
      ]
    },
    {
      id: 'returns-pending',
      title: 'Devoluciones Pendientes',
      icon: 'return-left',
      url: '/return-pending-list'
    },
    {
      title: 'Picking y Ventilación',
      open: false,
      type: 'wrapper',
      icon: 'grid',
      children: [
        {
          title: 'Ventilación de traspasos',
          id: 'ventilation-transfer',
          url: '/ventilation/transfer',
          icon: 'swap',
          tooltip: 'Escanear embalaje para obtener información sobre el traspaso y seleccionar la opción a realizar'
        },
        {
          title: 'Ventilación sin Sorter',
          id: 'ventilation-no-sorter',
          url: '/ventilation-no-sorter',
          icon: 'aperture',
          tooltip: 'Realizar ventilación asignando a embalajes'
        }
      ]
    },
    {
      title: 'Sorter',
      open: false,
      type: 'wrapper',
      icon: 'apps',
      children: [
        {
          title: 'Entrada',
          id: 'sorter-input',
          url: '/sorter/input',
          icon: 'log-in',
          tooltip: 'Escanear artículos con entrada en sorter'
        },
        {
          title: 'Salida',
          id: 'sorter-output',
          url: '/sorter/output',
          icon: 'log-out',
          tooltip: 'Vaciado de calles'
        }
      ]
    },
    {
      title: 'Control de embalajes',
      open: false,
      type: 'wrapper',
      icon: 'ribbon',
      children: [
        {
          title: 'Lista Control Embalajes',
          id: 'packing-control-audit-al',
          url: '/audits',
          icon: 'list-box',
          tooltip: 'Listado de controles de embalajes realizados'
        },
        {
          title: 'Revisiones Pendientes',
          id: 'packing-control-audit-rv',
          url: '/audits/pending-revisions',
          icon: 'list-box',
          tooltip: 'Listado de revisiones de embalajes pendientes de realizar'
        },
        {
          title: 'Escaneo total (láser)',
          id: 'packing-control-add-audits',
          url: '/audits/add',
          icon: 'qr-scanner',
          tooltip: 'Auditoría de embalaje mediante láser escaeando todos los productos'
        },
        {
          title: 'Escaneo aleatorio (cámara)',
          id: 'packing-control-audit-scan',
          url: 'audits/scan',
          icon: 'aperture',
          tooltip: 'Auditoría mediante escáner de la cámara sin necesidad de escaner todos los productos'
        }
      ]
    },
    {
      title: 'Recepción de fábrica',
      open: false,
      type: 'wrapper',
      icon: 'archive',
      children: [
        {
          title: 'Recepción de mercancía',
          id: 'factory-reception-process',
          url: '/expeditions/pending/app',
          icon: 'pricetags',
          tooltip: 'Realiza la recepción de mercancía recibida de fábrica mediante una expedición o proveedor.'
        },
        {
          title: 'Recepción sin pedido',
          id: 'factory-reception-process-without-order',
          url: '/expeditions/pending/app/free',
          icon: 'pricetags',
          tooltip: 'Realiza la recepción de mercancía recibida de fábrica mediante una expedición o proveedor sin necesidad de que esta mercancía esté asignada a un pedido.'
        }
      ]
    },
    {
      id: 'defectives-al',
      title: 'Defectuosos',
      icon: 'warning',
      url: '/defect-handler'
    },
    {
      title: 'Configuración',
      open: false,
      type: 'wrapper',
      icon: 'build',
      children: [
        {
          title: 'Impresora de etiqueta producto',
          id: 'config-printer',
          url: '/settings',
          icon: 'print',
          tooltip: 'Mac de la impresora'
        },
        {
          title: 'Servicio impresión etiquetas pedidos',
          id: 'config-equipment-ip-code',
          url: '/settings-print-service',
          icon: 'desktop',
          tooltip: 'IP equipo de impresión'
        }
      ]
    },

    //TESTER MENU ITEMS

    // {
    //   id: 'defectives-al',
    //   title: 'BR',
    //   icon: 'warning',
    //   url: '/br'
    // },
    // {
    //   id: 'defectives-positioning',
    //   title: 'UBICAR DEFECTUOSOS',
    //   icon: 'warning',
    //   url: '/locate-defective',
    //   tooltip: 'Escanear artículos defectuosos mediante cámara para ubicar'
    // },
    // {
    //   id: 'defectives-al',
    //   title: 'RELEASE RESERVATIONS',
    //   icon: 'warning',
    //   url: '/release-reservations'
    // },
    // {
    //   id: 'defectives-al',
    //   title: 'SIZE REGULATION',
    //   icon: 'warning',
    //   url: '/size-regularization'
    // },
    // {
    //   id: 'defectives-al',
    //   title: 'RECEPTION PAIR BY PAIR',
    //   icon: 'warning',
    //   url: '/reception-pair-by-pair'
    // },
    // {
    //   id: 'defectives-al',
    //   title: 'PICKING STORES',
    //   icon: 'warning',
    //   url: '/picking-stores'
    // },
    // {
    //   id: 'print-price-tag',
    //   title: 'PRINT TAGS',
    //   icon: 'warning',
    //   url: '/print-tags',
    //   tooltip: 'Imprimir etiquetas de exposición escaneando los productos con cámara'
    // },

  ];
  private menuPages = {
    sga: this.sgaPages,
    al: this.alPages
  }

  private intervalOutScanIncidences = null;
  private intervalInScanIncidences = null;

  menuPagesFiltered: MenuItemList = [];
  @Output() menuTitle = new EventEmitter();

  constructor(
    private loginService: Oauth2Service,
    private router: Router,
    private authenticationService: AuthenticationService,
    private scanditService: ScanditService,
    private receptionScanditService: ReceptionScanditService,
    private printTagsScanditService: PrintTagsScanditService,
    private sealScanditService: SealScanditService,
    private productInfoScanditService: ProductInfoScanditService,
    private auditMultipleScanditService: AuditMultipleScanditService,
    private menuController: MenuController,
    private toolbarProvider: ToolbarProvider,
    private tariffService: TariffService,
    private popoverController: PopoverController,
    private warehouseReceptionAlertService: WarehouseReceptionAlertService,
    private localStorageProvider: LocalStorageProvider,
    private zona: NgZone,
    private events: Events,
    private pickingStoreService: PickingStoreService,
    private positioningManualProvider: PositioningManualProvider,
    private sizeRegularizationService: SizeRegularizationService,
    private marketplacesService: MarketplacesService,
    private outScanIncidenceService: OutScanIncidenceService,
    private globalVariableService: GlobalVariableService,
    private inScansIncidenceService: InScansIncidenceService,
    private barcodeScannerService: BarcodeScannerService,
  ) {

    this.loginService.availableVersion.subscribe(res => {
      this.versionUpdate = res;
    })
    this.events.subscribe('exit-picking-tasks-stores-scanner', async () => {
      await this.getPickingTasksStoresAmount();
    });
  }

  returnTitle(item: MenuSectionItem) {
    this.currentRoute = item.title
    this.toolbarProvider.currentPage.next(item.title);
    this.toolbarProvider.optionsActions.next([]);
    this.menuTitle.emit(item.title);
  }

  setTitle(title) {
    this.toolbarProvider.currentPage.next(title);
  }

  loadUpdate() {
    window.open(this.loginService.downloadAppLink, '_blank')
  }

  /**
   * Select the links that be shown depends of dictionary paramethers
   */
  filterPages(dictionary) {
    dictionary = JSON.parse(JSON.stringify(dictionary));
    if (app.name == 'al') {
      this.newTariffs();
      this.getPickingTasksStoresAmount();
      this.getReservesExpiredAmount();
      this.zona.run(() => {
        setInterval(() => {
          this.newTariffs();
          this.getPickingTasksStoresAmount();
          this.getReservesExpiredAmount();
        }, 5 * 60 * 1000);
      });
    }
    let logoutItem = ({
      title: 'Cerrar sesión',
      id: 'logout',
      url: '/logout',
      icon: 'log-out'
    });
    if (!this.alPages.find(item => (<any>item).id == "logout"))
      this.alPages.push(logoutItem);
    else
      this.alPages.forEach((item, i) => {
        if ((<any>item).id == "logout")
          this.alPages[i] = logoutItem;
      });
    if (!this.sgaPages.find(item => (<any>item).id == "logout"))
      this.sgaPages.push(logoutItem);
    else
      this.sgaPages.forEach((item, i) => {
        if ((<any>item).id == "logout")
          this.sgaPages[i] = logoutItem;
      });
    this.project_selector = app.name;
    if (!app || !app.name) {
      return false;
    }
    /**obtain the routes for the current application */
    let auxPages = this.menuPages[this.app.name];
    this.menuPagesFiltered = [];
    if (!auxPages) {
      return false;
    }
    /**iterate over all pages of the application */
    auxPages.forEach((page: any) => {
      /**to save the childrens of the actual page */
      let auxChildren = [];
      /**if the page is a wrapper then iterate over his childrens to get the alloweds */
      if (page.type == "wrapper") {
        page.children.forEach(children => {
          /**if the childen is allowed then add if */
          if (dictionary[children.id]) {
            auxChildren.push(children);
          }
        });
        /**if the page is a wrapper and have childrens then add it */
        let auxPage = JSON.parse(JSON.stringify(page));
        auxPage.children = auxChildren;
        /** */
        if (auxChildren.length) {
          this.menuPagesFiltered.push(auxPage);
        }
        /**if not is a wrapper then is a normal category the check if plus easy */
      } else {
        if (dictionary[page.id]) {
          this.menuPagesFiltered.push(page);
        }
      }
    });

    //this.currentRoute = this.menuPagesFiltered[0].children[0].title;
    if (this.userHasMarketplacesPermission(dictionary) && app.name != 'al') {
      this.marketplacesService.addMarketplacesOptions(this.menuPagesFiltered);
    }

    if (this.intervalOutScanIncidences) {
      clearInterval(this.intervalOutScanIncidences);
      this.intervalOutScanIncidences = null;
    }

    if (this.intervalInScanIncidences) {
      clearInterval(this.intervalInScanIncidences);
      this.intervalInScanIncidences = null;
    }

    if (dictionary['out-scans-incidences'] && app.name == 'sga') {
      this.checkForOutScansIncidences();
      this.zona.run(() => {
        this.intervalOutScanIncidences = setInterval(() => {
          this.checkForOutScansIncidences();
        }, 5 * 60 * 1000);
      });
    }

    if (dictionary['in-scans-incidences'] && app.name == 'sga') {
      this.checkForInScansIncidences();
      this.zona.run(() => {
        this.intervalInScanIncidences = setInterval(() => {
          this.checkForInScansIncidences();
        }, 5 * 60 * 1000);
      });
    }

    this.globalVariableService.getAll().subscribe((data: GlobalVariable[]) => {
      const sorterStatusString: string = data.find(variable => variable.type == GlobalVariablesType.SORTER_ENABLED).value;
      if (sorterStatusString == 'false') {
        for (let index in this.menuPagesFiltered) {
          if (this.menuPagesFiltered[index].title == 'Sorter') {
            this.menuPagesFiltered.splice(parseInt(index), 1);
          }
        }
      }
    }, error => {
      console.error(error);
    });
  }

  private userHasMarketplacesPermission(permissions): boolean {
    return permissions['catalogs-marketplaces'];
  }

  tapOption(p) {
    this.positioningManualProvider.isActived.subscribe((actived) => {
      this.isActived = actived;
    });
    if (this.isActived == true) {
      this.currentRoute = 'Ubicar/escanear con láser';
      this.router.navigate(['/positioning/manual']);
      this.toolbarProvider.currentPage.next("Ubicar/escanear con láser");
      this.menuTitle.emit('Ubicar/escanear con láser');
    } else {
      this.currentRoute = p.title;
      this.toolbarProvider.currentPage.next(p.title);
      this.toolbarProvider.optionsActions.next([]);
      this.menuTitle.emit(p.title);
      if (p.url === 'logout') {
        this.authenticationService.getCurrentToken().then(accessToken => {
          this.loginService
            .get_logout(accessToken)
            .subscribe((data) => {
              this.authenticationService.logout().then(success => {
                this.router.navigateByUrl('/login')
              });
            });
        });
      } else if (p.url === 'positioning') {
        this.scanditService.positioning();
      } else if (p.url === 'reception') {
        this.receptionScanditService.reception(1);
      } else if (p.url == 'reception/empty-carrier') {
        this.checkAlertsAndRedirect();
      } else if (p.url === 'reception-pair-by-pair') {
        this.checkAlertsAndRedirect();
      } else if (p.url === 'audits/scan') {
        this.auditMultipleScanditService.init();
      } else if (p.url == 'size-regularization') {
        this.sizeRegularizationService.init();
      }
    }
  }

  async checkAlertsAndRedirect(plugin?: string) {
    const currentWarehouse: Warehouse = await this.authenticationService.getStoreCurrentUser();
    if (currentWarehouse) {
      this.warehouseReceptionAlertService.check({ warehouseId: currentWarehouse.id }).then(async response => {
        if (response.code == 200 && typeof response.data == 'boolean') {
          if (response.data) {
            await this.localStorageProvider.set('hideAlerts', false);
            const popover = await this.popoverController.create({
              component: AlertPopoverComponent
            });
            popover.onDidDismiss().then(async response => {
              if (typeof response.data == 'boolean' && response.data) {
                await this.localStorageProvider.set('hideAlerts', true);
              } else {
                await this.localStorageProvider.set('hideAlerts', false);
              }
              if (plugin == 'barcodescanner') {
                this.barcodeScannerService.useScanner('receptionPairByPair');
              } else {
                this.receptionScanditService.reception(2);
              }
            });
            await popover.present();
          } else {
            await this.localStorageProvider.set('hideAlerts', false);
            if (plugin == 'barcodescanner') {
              this.barcodeScannerService.useScanner('receptionPairByPair');
            } else {
              this.receptionScanditService.reception(2);
            }
          }
        } else {
          console.error(response);
        }
      }, error => {
        console.error(error);
      });
    } else {
      console.error('Current warehouse not found.');
    }
  }

  tapOptionSubitem(p) {
    this.positioningManualProvider.isActived.subscribe((actived) => {
      this.isActived = actived;
    });
    if (this.isActived == true) {
      this.currentRoute = 'Ubicar/escanear con láser';
      this.router.navigate(['/positioning/manual']);
      this.toolbarProvider.currentPage.next("Ubicar/escanear con láser");
      this.menuTitle.emit('Ubicar/escanear con láser');
    } else {
      this.menuController.close();
      if (p.url === 'print/tag/ref') {
        this.printTagsScanditService.printTagsReferences();
      } else if (p.url === 'print/tag/price') {
        this.printTagsScanditService.printTagsPrices();
      } else if (p.url === 'packing/seal') {
        this.sealScanditService.seal();
      } else if (p.url === 'reception') {
        this.receptionScanditService.reception(1);
      } else if (p.url == 'reception/empty-carrier') {
        this.checkAlertsAndRedirect();
      } else if (p.url === 'reception-pair-by-pair') {
        this.checkAlertsAndRedirect();
      } else if (p.url == 'print/product/relabel') {
        this.printTagsScanditService.printRelabelProducts();
      } else if (p.url == 'products/info') {
        this.productInfoScanditService.init();
      } else if (p.url == 'br') {
        this.barcodeScannerService.useScanner('extendedProductInfo');
      } else if (p.url === 'locate-defective') {
        this.barcodeScannerService.useScanner('locateDefective');
      } else if (p.url === 'positioning') {
        this.scanditService.positioning();
      } else if (p.url === 'defective-positioning') {
        //TODO LOOK FOR THIS CODE
        this.scanditService.defectivePositioning();
      } else if (p.url === 'audits/scan') {
        this.auditMultipleScanditService.init();
      } else {
        this.returnTitle(p);
      }
      if (p.id === 'workwaves-direct-consolidated') {
        this.router.navigate([p.url], { queryParams: { type: 1 } })
      }
    }
  }

  tapOptionSubSubitem(menuItem) {
    this.positioningManualProvider.isActived.subscribe((actived) => {
      this.isActived = actived;
    });
    if (this.isActived == true) {
      this.currentRoute = 'Ubicar/escanear con láser';
      this.router.navigate(['/positioning/manual']);
      this.toolbarProvider.currentPage.next("Ubicar/escanear con láser");
      this.menuTitle.emit('Ubicar/escanear con láser');
    } else {
      this.menuController.close();
      this.menuTitle.emit(menuItem.title);
    }

  }

  openSubMenuItem(menuItem) {
    menuItem.open = !menuItem.open;
  }

  toggleSidebar() {
    this.displaySmallSidebar = !this.displaySmallSidebar;
    this.displaySmallSidebar === true
      ? (this.iconsDirection = 'end')
      : (this.iconsDirection = 'start');

    for (let page of <MenuSectionGroupItem[]>(this.menuPagesFiltered)) {
      if (page.children && page.children.length > 0) {
        page.open = false;
      }
    }
  }

  ngOnInit() {
  }

  /**
   * Listen changes in form to resend the request for search
   */
  async newTariffs() {
    const currentWarehouse: Warehouse = await this.authenticationService.getStoreCurrentUser();
    if (currentWarehouse) {
      this.tariffService
        .getNewTariff()
        .then(tariff => {
          if (tariff.code == 200) {
            let newTariff = tariff.data;
            /**save the data and format the dates */
            this.alPages.forEach((item, i) => {
              if ((<any>item).id == "tarifas") {
                (<any>item).notification = newTariff;
                (<any>item).children.forEach((child, j) => {
                  if ((<any>child).id == "tariff-al") {
                    (<any>child).notification = newTariff;
                  }
                });
              }
            });
          } else {
            console.error('Error to try check if exists new tariffs', tariff);
          }
        }, (error) => {
          console.error('Error to try check if exists new tariffs', error);
        })
    }
  }

  checkIfChildrenHasNewTariffs(element): boolean {
    return !!element.children.find(c => c.notification)
  }

  async getPickingTasksStoresAmount() {
    const currentWarehouse: Warehouse = await this.authenticationService.getStoreCurrentUser();
    if (currentWarehouse) {
      this.pickingStoreService.getLineRequestsStoreOnlineAmount().then(response => {
        if (response.code == 200) {
          for (let page of this.alPages) {
            if (page.children) {
              for (let child of page.children) {
                if (child && child.id == 'picking-tasks-stores' && (child.amount || child.amount == 0)) {
                  child.amount = response.data;
                  return;
                }
              }
            }
          }
        } else {
          console.error(response);
        }
      }, console.error).catch(console.error);
    }
  }

  async getReservesExpiredAmount() {
    const currentWarehouse: Warehouse = await this.authenticationService.getStoreCurrentUser();
    if (currentWarehouse) {
      this.pickingStoreService.getReservesExpiredAmount().then(response => {
        if (response.code == 200) {
          for (let page of this.alPages) {
            if (page.children) {
              for (let child of page.children) {
                if (child && child.id == 'free-expired-reserves' && (child.amount || child.amount == 0)) {
                  child.amount = response.data;
                  return;
                }
              }
            }
          }
        } else {
          console.error(response);
        }
      }, console.error).catch(console.error);
    }
  }

  checkIfChildrenNotification(element): boolean {
    return !!element.children.find(c => c.notification || (c.amount && c.amount > 0));
  }

  async checkForOutScansIncidences() {
    this.outScanIncidenceService.getCheckForOutScanIncidences().then(response => {
      if (response.code == 200) {
        OutScansIncidencesComponent.alert = !!response.data;
        this.setOutScanIncidencesNotification();
      } else {
        console.error('Error to try check if exists out scans incidences', response);
      }
    }, (error) => {
      console.error('Error to try check if exists out scans incidences', error);
    });
  }

  async checkForInScansIncidences() {
    try {
      const response = await this.inScansIncidenceService.checkForInScansIncidences()
      if (response.code == 200) {
        InScansIncidencesComponent.alert = !!response.data;
        this.setInScansIncidencesNotification();
      } else {
        console.error('Error to try check if exists out scans incidences', response);
      }
    } catch (error) {
      console.error('Error to try check if exists out scans incidences', error);
    }
  }

  setOutScanIncidencesNotification() {
    this.sgaPages.forEach((item, i) => {
      if ((<any>item).title == 'Logística' && (<any>item).type == 'wrapper') {
        (<any>item).children.forEach((child, j) => {
          if ((<any>child).id == "out-scans-incidences") {
            (<any>child).notification = OutScansIncidencesComponent.alert;
          }
        });
      }
    });
  }

  setInScansIncidencesNotification() {
    this.sgaPages.forEach((item, i) => {
      if ((<any>item).title == 'Logística' && (<any>item).type == 'wrapper') {
        (<any>item).children.forEach((child, j) => {
          if ((<any>child).id == "in-scans-incidences") {
            (<any>child).notification = InScansIncidencesComponent.alert;
          }
        });
      }
    });
  }
}

import { HttpRequestModel } from 'libs/services/src/models/endpoints/HttpRequest';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  ExpeditionModel
} from "../../../models/endpoints/Expedition";
import ResponseCheckPackageForExpedition = ExpeditionModel.ResponseCheckPackageForExpedition;
import ResponseCheckProductsForExpedition = ExpeditionModel.ResponseCheckProductsForExpedition;

@Injectable({
  providedIn: 'root'
})
export class ExpeditionService {

  private baseUrl: string;
  private getExpeditionInfoURL: string;
  private getOrderStatusURL: string;
  private getListOrdersNoProcessedURL: string;
  private postCheckIncidenceForExpeditionUrl: string;
  private postCheckPackageForExpeditionUrl: string;
  private getExpeditionByFirstProductUrl: string;
  private getExpeditionByIdUrl: string;
  private postExpeditionReadedProductsUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBase;
    this.getExpeditionInfoURL = `${this.baseUrl}/opl-expedition/detail`;
    this.getOrderStatusURL = `${this.baseUrl}/opl-expedition/detail`;
    this.getListOrdersNoProcessedURL = `${this.baseUrl}/opl-expedition/orders-no-processed`;
    this.postCheckIncidenceForExpeditionUrl = `${this.baseUrl}/opl-expedition/check/incidence/code/`;
    this.postCheckPackageForExpeditionUrl = `${this.baseUrl}/opl-expedition/check/package/code/`;
    this.getExpeditionByFirstProductUrl = `${this.baseUrl}/opl-expedition/sync-expedition/`;
    this.getExpeditionByIdUrl = `${this.baseUrl}/opl-expedition/`;
    this.postExpeditionReadedProductsUrl = `${this.baseUrl}/opl-expedition/check-expedition-products`;
  }

  getExpeditionInfo(id){
    return this.http.get<HttpRequestModel.Response>(this.getExpeditionInfoURL+'/'+id).pipe(
      map(resp => resp.data)
    )
  }

  getOrderStatus(body: any): Observable<any> {
    return this.http.post<HttpRequestModel.Response>(this.getOrderStatusURL, body).pipe(
      map(resp => resp.data)
    )
  }

  getListOrdersNoProcessed(): Observable<any> {
    return this.http.get<HttpRequestModel.Response>(this.getListOrdersNoProcessedURL).pipe(
      map(resp => resp.data)
    )
  }

  postCheckIncidenceForExpedition(expeditionId: number, params: ExpeditionModel.ParamsCheckIncidenceForExpedition): Observable<HttpRequestModel.Response> {
    return this.http.post<ExpeditionModel.ResponseCheckIncidenceForExpedition>(this.postCheckIncidenceForExpeditionUrl + expeditionId, params);
  }

  postCheckPackageForExpedition(expeditionId: number, params: ExpeditionModel.ParamsCheckPackageForExpedition): Observable<HttpRequestModel.Response> {
    return this.http.post<ExpeditionModel.ResponseCheckPackageForExpedition>(this.postCheckPackageForExpeditionUrl + expeditionId, params);
  }

  getExpeditionByFirstProduct(productBarcode: string): Observable<ResponseCheckPackageForExpedition> {
    return this.http.get(this.getExpeditionByFirstProductUrl + productBarcode).pipe(map((response:any) => {
      return response;
    }));
  }

  getExpeditionByDeliveryRequestId(deliveryRequestId) {
    return this.http.get(this.getExpeditionByFirstProductUrl + deliveryRequestId).pipe(map((response:any) => {
      return response;
    }));
  }

  getExpeditionById(id: number): Observable<ResponseCheckPackageForExpedition> {
    return this.http.get(this.getExpeditionByIdUrl + id).pipe(map((response:any) => {
      return response;
    }));
  }

  postExpeditionReadedProducts(readedProducts): Observable<ResponseCheckProductsForExpedition> {
    return this.http.post<HttpRequestModel.Response>(this.postExpeditionReadedProductsUrl, readedProducts).pipe(map((response: any) => {
        return response;
    }));
  }
}

import {Injectable} from "@angular/core";
import {AlertController, Platform} from "@ionic/angular";

export interface AlertOptions {
  header?: string;
  subHeader?: string;
  message?: string;
  cssClass?: string;
  buttons?: (AlertButton | string)[];
  backdropDismiss?: boolean;
  translucent?: boolean;
  animated?: boolean;
  mode?: 'ios' | 'md';
  keyboardClose?: boolean;
  id?: string;
}

export interface AlertButton {
  text: string;
  role?: string;
  cssClass?: string | string[];
  handler?: (value: any) => boolean | void | {
    [key: string]: any;
  };
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private static readonly LARGE_STYLE: string = "large-alert";

  constructor(private platform: Platform, private alertCtrl: AlertController) {
  }

  async createAlert(options: AlertOptions) {
    const legacyOptions = await this.convertOptions(options);
    return await this.alertCtrl.create(legacyOptions);
  }

  private async convertOptions(options: AlertOptions) {
    let legacyOptions = {};
    legacyOptions['cssClass'] = [];
    legacyOptions['mode'] = 'md';

    if (!this.platform.is("mobile")) {
      legacyOptions['cssClass'].push(AlertService.LARGE_STYLE);
    }

    const fields: Array<string> = Object.keys(options);
    for (let field of fields) {
      if (field === "mode") {

        legacyOptions[field] = options[field];

      } else if (field === "cssClass") {

        legacyOptions[field].push(options[field]);

      } else {
        legacyOptions[field] = options[field];
      }
    }
    return legacyOptions;
  }
}

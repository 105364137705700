import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpRequestModel } from '../../../models/endpoints/HttpRequest';
import { map } from 'rxjs/operators';
import { OplTransportsModel } from '../../../models/endpoints/opl-transports.model'
import {Observable} from "rxjs";
import {ExpeditionsProcessedModel} from "../../../models/endpoints/Expeditions/Processed";

@Injectable({
  providedIn: 'root'
})
export class OplTransportsService {

  private filtersUrl: string;
  private filtersTransportUrl: string;
  private orderExpeditionUrl: string
  private postLoadOrdersWithManifestsAndPackagesUrl: string;
  private postLoadOrderManifestWithPackagesUrl: string;
  private printOrderUrl: string;
  private dowloadPdf: string;
  private printOrderTransportUrl: string
  private getManifestInfoUrl: string;
  private getOperatorsPendingPickUpUrl: string = environment.apiBase + "/opl-expedition/expedition/transport/pending/pickup";
  private getOperatorsCancelledUrl: string = environment.apiBase + "/opl-expedition/expedition/transport/cancelled";
  private getOperatorsWithManifestsUrl: string = environment.apiBase + "/opl-expedition/expedition/transport/manifests";
  private getCheckTransportHasRelabelUrl: string = environment.apiBase + "/service-external/transports/check/relabel/";

  constructor(private http: HttpClient) {
    this.filtersUrl = `${environment.apiBase}/opl-expedition/order-expedition/get-filters`;
    this.filtersTransportUrl = `${environment.apiBase}/opl-expedition/order-expedition/get-transports-filters`;
    this.orderExpeditionUrl = `${environment.apiBase}/opl-expedition/order-expedition/filter`;
    this.printOrderUrl = `${environment.apiBase}/opl-expedition/order-expedition/print`
    this.dowloadPdf = `${environment.downloadFiles}/api/opl-expedition/order-expedition/transport/download/pdf`
    this.printOrderTransportUrl = `${environment.apiBase}/opl-expedition/order/print`
    this.getManifestInfoUrl = `${environment.apiBase}/opl-expedition/manifest/order/`;
    this.postLoadOrdersWithManifestsAndPackagesUrl = `${environment.apiBase}/opl-expedition/order/manifests/filter`;
    this.postLoadOrderManifestWithPackagesUrl = `${environment.apiBase}/opl-expedition/order/manifest/packages`;

  }

  getFilters(){
    return this.http.get<HttpRequestModel.Response>(this.filtersUrl).pipe(map(res => res.data));
  }
  getTransports() {
    return this.http.get<HttpRequestModel.Response>(this.filtersTransportUrl).pipe(map(res => res.data));
  }

  getList(body: OplTransportsModel.OrderExpeditionFilterRequest) {
    return this.http.post<HttpRequestModel.Response>(this.orderExpeditionUrl,body).pipe(map(res => res.data));
  }

  postLoadOrdersWithManifestsAndPackages(body: OplTransportsModel.OrderExpeditionFilterRequest): Observable<OplTransportsModel.ResponseLoadOrdersWithManifestsAndPackages> {
    return this.http.post<HttpRequestModel.Response>(this.postLoadOrdersWithManifestsAndPackagesUrl,body).pipe(map(res => res.data));
  }

  postLoadOrderManifestWithPackages(body) {
    return this.http.post<HttpRequestModel.Response>(this.postLoadOrderManifestWithPackagesUrl,body).pipe(map(res => res.data));
  }

  print(id: number) {
    const body = {
      "expeditionOrderId": id
    }
    return this.http.post<HttpRequestModel.Response>(this.printOrderUrl, body).pipe(map(res => res.data));
  }

  downloadPdf(path?:string) {
    const body = {
      path: path || '/pdf/documento1.pdf'
    }
    let headers: HttpHeaders = new HttpHeaders({ Accept: 'application/pdf' });
    return this.http.post(this.dowloadPdf, body,{headers, responseType:'blob'})
  }

  downloadPdfTransortOrders(expeditionOrderId: number) {
    const body = {
      expeditionOrderId
    }
    let headers: HttpHeaders = new HttpHeaders({ Accept: 'application/pdf' });
    return this.http.post(this.printOrderTransportUrl, body, { headers, responseType: 'blob' })
  }

  getManifestInfo(expeditionOrderId: number) {
    const url = `${this.getManifestInfoUrl}${expeditionOrderId}`;
    return this.http.get<HttpRequestModel.Response>(url).pipe(map(res => res.data));
  }

  public getOperatorsPendingPickUp(): Observable<ExpeditionsProcessedModel.TransportsWithPendingPickUp[]> {
    return this.http.get<HttpRequestModel.Response>(this.getOperatorsPendingPickUpUrl).pipe(map(resp => resp.data));
  }

  public getOperatorsCancelled(): Observable<ExpeditionsProcessedModel.TransportsWithCancelled[]> {
    return this.http.get<HttpRequestModel.Response>(this.getOperatorsCancelledUrl).pipe(map(resp => resp.data));
  }

  public getOperatorsWithManifests(): Observable<ExpeditionsProcessedModel.TransportsWithManifests[]> {
    return this.http.get<HttpRequestModel.Response>(this.getOperatorsWithManifestsUrl).pipe(map(resp => resp.data));
  }

  public getCheckTransportHasRelabel(transportId: number): Observable<ExpeditionsProcessedModel.CheckTransportHasRelabeled[]> {
    return this.http.get<HttpRequestModel.Response>(`${this.getCheckTransportHasRelabelUrl}${transportId}`).pipe(map(resp => resp.data));
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-positioning-manual-online',
  templateUrl: './positioning-manual-online.component.html',
  styleUrls: ['./positioning-manual-online.component.scss']
})

export class PositioningManualOnlineComponent implements OnInit {

  constructor() {}

  ngOnInit() {

  }

}

export namespace ActionTypeModel {

  export enum ActionsTypes {
    INTERNAL_WAREHOUSE_POSITIONING_OUTPUT = 1,
    POSITIONING_INTERNAL_WAREHOUSE_ENTRY = 2,
    POSITIONING_NEW_PRODUCT = 3,
    POSITIONING_NEW_ROLLBACK_PRODUCT = 4,
    POSITIONING_ANOTHER_WAREHOUSE = 5,
    POSITIONING_ANOTHER_ROLLBACK = 6,
    POSITIONING_NEW_RECORD = 7,
    PIKING = 8,
    ROLLBACK_PINKING = 9,
    POSITIONING_INTERNAL_WAREHOUSE_ROLLBACK = 10,
    PIKING_CONSOLIDATED = 11,
    ROLLBACK_PIKING_CONSOLIDATED = 12,
    PRODUCT_PICKING_NOT_FOUND = 13,
    PIKING_STORE = 14,
    RELABEL_PRODUCT = 15,
    TRANSFER_FULL_CARRIER_PACKINGS = 16,
    TRANSFER_SELECTIVE_CARRIER_PACKINGS = 17,
    SORTER_OUTPUT = 18,
    SORTER_INPUT = 19,
    SORTER_RACK_INPUT = 20,
    REMOVE_PRODUCT_FROM_PACKING = 21,
    RECEPTION_SORTER = 22,
    RECEPTION_WAREHOUSE = 23,
    PICKING_ONLINE = 24,
    POSITIONING_DEFECTIVE = 25,
    OPL_INTERN_PROCESS = 26,
    RETURN_PICKING = 27,
    ASSOCIATED_TO_ORDER = 28,
    RETURN_PICKED_UP = 29,
    PREPARED_RETURN = 30,
    LOCATION_CANCELLED = 31,
    PRODUCT_REGULARIZED = 32,
    UNASSOCIATED_PICKING_PRODUCT = 33,
    SIZE_REGULARIZATION = 34
  }
}

import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {ModalController} from "@ionic/angular";
import {MarketplacesService} from "../../../../services/src/lib/endpoint/marketplaces/marketplaces.service";
import {RefundsService} from "../../../../services/src/lib/endpoint/refunds/refunds.service";
import {IntermediaryService} from "@suite/services";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'suite-refunds-ok',
  templateUrl: './refunds-ok.component.html',
  styleUrls: ['./refunds-ok.component.scss']
})
export class RefundsOkComponent implements OnInit {

  displayedColumns: string[] = ['orderReference', 'returnItemIdentifier', 'returnSizeIndex', 'amount', 'paymentGateway', 'customerName'];
  dataSource;
  private refunds;
  private refundsData = [];
  private selection;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private modalController: ModalController,
              private renderer: Renderer2,
              private marketplacesService: MarketplacesService,
              private refundsService: RefundsService,
              private intermediaryService: IntermediaryService,) {
  }

  ngOnInit() {
    this.tableDataLoad();
  }

  tableDataLoad(){
    this.refundsService.getRefundsOk().subscribe(count => {
      this.refunds = count;
      this.refundsData = [];

      for(let i = 0; i < this.refunds.length; i++){
        this.refundsData.push({
          orderReference: this.refunds[i].orderReference,
          returnItemIdentifier: this.refunds[i].returnLines,
          returnSizeIndex: this.refunds[i].returnLines,
          amount: this.refunds[i].amount,
          paymentGateway: this.refunds[i].paymentGateway,
          customerName: this.refunds[i].customerName
        });
      }

      this.dataSource = new MatTableDataSource(this.refundsData);
      this.dataSource.paginator = this.paginator;
      this.selection = new SelectionModel(true, []);
    });
  }

  public getOrderReference(list): string {
    return list;
  }
  public getReturnItemIdentifier(list): string {
    let items;
    for(let i = 0; i < list.length; i++){
      items = i > 0 ? items + '<br/>' + list[i].returnItemIdentifier : list[i].returnItemIdentifier;
    }
    return items;
  }
  public getReturnSizeIndex(list): string {
    let items;
    for(let i = 0; i < list.length; i++){
      items = i > 0 ? items + '<br/>' + list[i].returnSizeIndex : list[i].returnSizeIndex;
    }
    return items;
  }
  public getAmount(list): string {
    return list;
  }
  public getPaymentGateway(list): string {
    return list;
  }
  public getCustomerName(list): string {
    return list;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ReleaseReservationsComponent } from './release-reservations.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

//CA_RELEASE RESERVATIONS
const routes: Routes = [
  {
    path: '',
    component: ReleaseReservationsComponent
  },
];

@NgModule({
  declarations: [ReleaseReservationsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    IonicModule,
    FormsModule,
  ]
})
export class ReleaseReservationsModule { }

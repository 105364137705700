import { Component, OnInit, Input } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'suite-selectable-list',
  templateUrl: './selectable-list.component.html',
  styleUrls: ['./selectable-list.component.scss']
})
export class SelectableListComponent implements OnInit {

  @Input() origins: {id: number, value: string}[] = [];
  @Input() currentOrigin: {id: number, value: string} = null;
  @Input() carrier: string = '';

  chosenOriginId: number = null;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this.chosenOriginId = this.currentOrigin.id;
  }

  submit() {
    this.modalController.dismiss(this.chosenOriginId);
  }

  close() {
    this.modalController.dismiss();
  }

}

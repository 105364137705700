import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { IntermediaryService } from '../../../../services/src/lib/endpoint/intermediary/intermediary.service';
import {UtilsComponent} from "../../components/utils/utils.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HiringSeurService} from "../../../../services/src/lib/endpoint/hiring-seur/hiring-seur.service";

@Component({
  selector: 'suite-update-hiring-seur',
  templateUrl: './update-hiring-seur.component.html',
  styleUrls: ['./update-hiring-seur.component.scss']
})
export class UpdateHiringSeurComponent implements OnInit {
  title = 'Editar Contratación de Seur';
  redirectTo = '/update-hiring-seur';
  elementHiring;
  referenceWarehouseSelected;
  nameWarehouseSelected;
  userSeurSelected;
  passSeurSelected;
  dniSelected;
  citSelected;
  cccSelected;
  cccDPDSelected;
  ccnSelected;
  franquiciaSelected;

  @ViewChild(UtilsComponent) utils: UtilsComponent;
  updateForm: FormGroup = this.formBuilder.group({
    reference: [''],
    name: [''],
    userSeur: [''],
    passSeur: [''],
    dni: [''],
    cit: ['', Validators.pattern('^[0-9]*$')],
    ccc: ['', Validators.pattern('^[0-9]*$')],
    cccDPD: ['', Validators.pattern('^[0-9]*$')],
    ccn: ['', Validators.pattern('^[0-9]*$')],
    franquicia: ['', Validators.pattern('^[0-9]*$')],
  });

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private intermediaryService: IntermediaryService,
    private formBuilder: FormBuilder,
    private hiringSeurService: HiringSeurService,
  ) {}

  ngOnInit() {
    this.elementHiring = this.navParams.get('element');
    this.referenceWarehouseSelected = this.elementHiring.warehouseReference;
    this.nameWarehouseSelected = this.elementHiring.nameWarehouse;
    this.userSeurSelected = this.elementHiring.userSeur;
    this.passSeurSelected = this.elementHiring.passSeur;
    this.dniSelected = this.elementHiring.dni;
    this.citSelected = this.elementHiring.cit;
    this.cccSelected = this.elementHiring.ccc;
    this.cccDPDSelected = this.elementHiring.cccDPD;
    this.ccnSelected = this.elementHiring.ccn;
    this.franquiciaSelected = this.elementHiring.franquicia;
  }

  updateWarehouseSeur(){
    let dataBody = {
      "id": this.elementHiring.idWarehouseSeur,
      "warehouseReference": this.elementHiring.warehouseReference,
      "seur_sws_user": this.userSeurSelected,
      "seur_sws_pass": this.passSeurSelected,
      "seur_sws_dni": this.dniSelected,
      "seur_sws_cit": this.citSelected,
      "seur_sws_ccc": this.cccSelected,
      "seur_sws_ccc_DPD": this.cccDPDSelected,
      "seur_sws_ccn": this.ccnSelected,
      "seur_sws_franquicia": this.franquiciaSelected,
    };

    if(dataBody.seur_sws_user == "" && dataBody.seur_sws_pass == "" && dataBody.seur_sws_dni == "" &&
      dataBody.seur_sws_cit == "" && dataBody.seur_sws_ccc == "" && dataBody.seur_sws_ccc_DPD == "" && dataBody.seur_sws_ccn == "" && dataBody.seur_sws_franquicia == ""){
      this.intermediaryService.presentConfirm("¿Está seguro que quiere eliminar los datos?",()=>{
        this.hiringSeurService.deleteWarehouseSeur(dataBody.id).subscribe(() => {
          this.intermediaryService.presentToastSuccess("Datos eliminados con éxito");
          this.modalController.dismiss(null);
        }, error => {
          this.intermediaryService.presentToastError("Error al eliminar los datos");
          this.modalController.dismiss(null);
        });
      });
    }else{
      this.hiringSeurService.updateWarehouseSeur(dataBody).subscribe(() => {
        this.intermediaryService.presentToastSuccess("Datos guardados con éxito");
        this.modalController.dismiss(null);
      }, error => {
        this.intermediaryService.presentToastError("Error al guardar los datos");
        this.modalController.dismiss(null);
      });
    }
  }

  close():void{
    this.modalController.dismiss();
  }

  cleanInputs(){
    this.userSeurSelected = '';
    this.passSeurSelected = '';
    this.dniSelected = '';
    this.citSelected = '';
    this.cccSelected = '';
    this.cccDPDSelected = '';
    this.ccnSelected = '';
    this.franquiciaSelected = '';
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {ListWorkwavesHistoryComponent} from "../list.component";


@Component({
  selector: 'title-list-workwaves-history',
  templateUrl: './list-title.component.html',
  styleUrls: ['./list-title.component.scss']
})
export class TitleListWorkwavesHistoryComponent implements OnInit {

  @Input() filters: any;
  @Input() filterOptions: any;

  constructor(
    private listComponent: ListWorkwavesHistoryComponent
  ) {}

  ngOnInit() {}

  applyFilters(event, column: string){
    this.listComponent.applyFilters(event, column);
  }

  orderBy(column: string){
    this.listComponent.orderBy(column);
  }

}

import {Component, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {MatOptionSelectionChange, MatTableDataSource} from '@angular/material';
import * as XLSX from 'xlsx';
import {ModalController} from '@ionic/angular';
import {MarketplacesService} from 'libs/services/src/lib/endpoint/marketplaces/marketplaces.service';
import {forkJoin} from "rxjs";
import {IntermediaryService} from '@suite/services';
import {ModalSizes} from './modal-dialog/modal-dialog.component'
@Component({
  selector: 'suite-catalog-marketplaces',
  templateUrl: './catalog-marketplaces.component.html',
  styleUrls: ['./catalog-marketplaces.component.scss']
})

export class CatalogMarketplacesComponent implements OnInit, OnDestroy {

  @ViewChild('catalogTable') table: ElementRef;

  catalogTableData : MatTableDataSource<any> = new MatTableDataSource([]);
  catalogAllTableData: MatTableDataSource<any> = new MatTableDataSource([]);
  catalogTableHeader = ['select', 'ref', 'model', 'brand'];

  actionSelected;
  innerStatus = [
    {id: 'invalid', name: 'Invalido', selected: false},
    {id: 'valid', name: 'Valido', selected: false },
    {id: 'validWithIssues', name: 'Valido con errores', selected: false},
    {id: 'uploaded', name:'Subido', selected: false},
    {id: 'rejected', name: 'Rechazado', selected: false},
    {id: 'failed', name: 'Error', selected: false}
  ];
  sendStatus = [
    {id: 'unknown', name: 'Desconocido', selected: false},
    {id: 'uploaded', name: 'Subido', selected: false},
    {id: 'uploadedStock0', name: 'Subido - stock 0', selected: false},
    {id: 'uploadedWithErrors', name: 'Subido con errores', selected: false},
  ];
  externalStatus = [
    {id: 'unknown', name: 'Desconocido', selected: false},
    {id: 'synced', name: 'Sincronizado', selected: false},
    {id: 'hasIssues', name: 'Con errores', selected: false},
  ];

  synchronizedFilter = null;
  innerStatusFilter = [];
  sendStatusFilter = [];
  externalStatusFilter = [];
  marketplaces = [];
  marketSelected = this.marketplaces;
  searchReference;
  searchModel;
  searchBrand;
  refresher;
  count;
  total;
  page = 1;
  pageCount;
  marketFilter;
  marketPlacesColumn : any[] = [];
  selectedProducts = []
  
  private isFreshStart: boolean = true;
  private mdwMarketIdsMaps : Map<string, any> = new Map<string, any>();
  private jsonAllProducts = [];

  constructor(
    private marketplacesService: MarketplacesService,
    private intermediaryService : IntermediaryService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.marketFilter = '';
    this.getFiltersData();
    this.getProductsData();
    this.startInterval();
  }

  ngOnDestroy() {
    this.stopInterval();
  }

  getProductsData() {
    this.marketplacesService.searchProductCatalog(this.buildFilter()).subscribe(response => {
      this.count = response.count;
      this.total = response.total;
      this.pageCount = response.pageCount;
      this.showProductsInfo(response.data)
    });
  }

  generateExcelReport(){
    let bodyReport = {
      "task": "productSearch",
      "args": {
        "fileName": "Reporte sin variaciones",
      }
    }
    let fileName = bodyReport.args.fileName;
    let fileNameLength = fileName.length;

    if(fileNameLength > 30){
      this.intermediaryService.presentToastError("El nombre del archivo no puede ser mayor a 30 caracteres", 8000);
    }else{
      this.generateReportsMarketplaces(bodyReport);
    }
  }

  generateExcelVariationsReport(){
    let bodyReport = {
      "task": "productVariationSearch",
      "args": {
        "fileName": "Reporte con variaciones",
      }
    }

    let fileName = bodyReport.args.fileName;
    let fileNameLength = fileName.length;

    if(fileNameLength > 30){
      this.intermediaryService.presentToastError("El nombre del archivo no puede ser mayor a 30 caracteres", 8000);
    }else{
      this.generateReportsMarketplaces(bodyReport);
    }
  }

  async generateReportsMarketplaces(bodyReport) {
    this.intermediaryService.presentLoading("Generando informe, por favor espere...");
    await this.downloadFilereport(bodyReport);
    this.intermediaryService.dismissLoading();
    this.intermediaryService.presentToastSuccess("Informe generado, descárgelo desde la pantalla 'Informes'", 8000);
  }

  async downloadFilereport(bodyReport){
    let body = await this.buildFilterReport(bodyReport);
    this.marketplacesService.postReportsMarketplaces(body).subscribe(response => {
    let dataReport = response;
    });
  }
    
  async openModal(product): Promise<void> {
    let modal = await this.modalController.create({
      component: ModalSizes,
      componentProps: {
        mode: 'create',
        product: product
      },
    });
    modal.present();
  }

  close(data) {
    this.modalController.dismiss(data);
  }

  async requestAllProducts(total, i){
    const body = this.buildFilter({limit : 200, page : i})
    const promise = new Promise(async (resolve, reject) => {
      await this.marketplacesService.getAllProductCatalogMarketplaces(body).subscribe(async respons => {
        this.jsonAllProducts.push(...respons.data as []);

        if(respons.total == total){
          resolve({
            jsonExport: this.jsonAllProducts,
            response: respons
          });
        }
      });
    });
    return await promise;
  }

  private showProductsInfo(products){
    const productInfoList = [];
    products.forEach((product) => {
      let productInfo = {
        reference : product.reference,
        model : product.model,
        brand : product.brand,
        status : {},
        statusDescription : {},
        stock : {},
        productsMarkets : product.productsMarkets,
        isSelected : this.selectedProducts.map(p => p.reference).includes(product.reference)
      }
      this.mdwMarketIdsMaps.forEach((value : any)=>{
        productInfo.stock[value.mdwId] = 0;
      })
      
      for (let productMarket of product.productsMarkets) {
        productInfo.status[productMarket.id] = productMarket.status;
        productInfo.statusDescription[productMarket.id] = this.getStatusDescription(productMarket);
      }

      productInfoList.push(productInfo);
    });

    this.catalogTableData = new MatTableDataSource(productInfoList);
  }

  updateObject(property, value, productInfo){
      productInfo[property] = value
  }
  selectProductRow(product) {
    if (product.isSelected){
      this.selectedProducts.push(product);
    } else {
      const index = this.selectedProducts.map(p => p.reference).indexOf(product.reference);
      this.selectedProducts.splice(index, 1);
    }
  }

  private buildFilter(options ?: {limit: number, page :number }) : any {
    return {
      limit : options ? options.limit : 15,
      page : options ? options.page : this.page,
      orderBy : 'reference',
      filter : this.getQueryFilters()
    }
  }

  private async buildFilterReport(options) {
    let filters = await this.getQueryFiltersReports()
    options.args.filter = filters;
    return options;
  }

  async changeSelectedStatus (event) { 
    switch (event.detail.value) {
      case 'Sincronizado':
        this.synchronizedFilter = true;
        this.searchProducts();
        break;

      case 'No sincronizado':
        this.synchronizedFilter = false;
        this.searchProducts();
        break;
      
      default: 
        this.synchronizedFilter = null;
        this.searchProducts();
        break;
    }
  }

  searchProducts() {
    this.page = 1;
    this.refreshProductList();
  }

  getStatusDescription(data){
    let state = '';
    switch (data.status) {
      case "validAndSynced":
        state = 'Sincronizado';
        break;
      case "syncedStock0":
        state = 'Sincronizado - stock 0';
        break;
      case "invalid":
        state = 'Error mapeo';
        break;
      case "syncFailed":
        state = 'Error sincro';
        break;

    }
    const statusDescription = data.pmResponse && data.pmResponse.validationDetails && !!data.pmResponse.validationDetails[0] ? ` (${data.pmResponse.validationDetails[0]})` : '';
    return `${state}${statusDescription}`;
}

  startInterval() {
    this.refresher = setInterval(() => {
      this.marketplaces = [];
      this.getFiltersData();
      this.getProductsData();
    }, 60000);
  }

  stopInterval() {
    if (this.refresher) {
      clearInterval(this.refresher);
    }
  }

  refreshProductList() {
    this.stopInterval();
    this.getProductsData();
    this.startInterval();
  }

  async getFiltersData() {
    let markets = [];

    await forkJoin([
      this.marketplacesService.getMarketplaces(),
      this.marketplacesService.getFeatures()
    ]).subscribe(results => {

      if (results[0] && results[0].length) {
        results[0].forEach(market => {
          if(market.status !== "disabled"){
            markets.push({id: market.id, name: market.name, mdwId : market.externalId});
            this.mdwMarketIdsMaps.set(market.name, {id : market.id, mdwId : market.externalId});
            if(!this.isFreshStart && !this.hasMarketColumn(market)){
              this.marketPlacesColumn.push({ name : market.name, id : market.id, statusFilterId : -1});
            }
          }
        });
      }
      markets.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));

      this.marketplaces = this.marketplaces.concat(markets);
      if (this.isFreshStart){
        this.isFreshStart = false;
        this.marketplaces.forEach(market=>{
          this.marketSelected.push(market.id);
          this.marketPlacesColumn.push({ name : market.name, id : market.id, statusFilterId : -1});
          this.catalogTableHeader.push(market.name)
        });
      }
    });
  }

  private hasMarketColumn(newMarket) : boolean{
    for(let market of this.marketPlacesColumn){
      if (market.id === newMarket.id){
        return true;
      }
    }
    return false;
  }

  getMdwIdByMarketname(marketName : string){
    return this.mdwMarketIdsMaps.get(marketName).mdwId;
  }

  getMdwIdMarketByMarketname(marketName : string){
    return this.mdwMarketIdsMaps.get(marketName).id;
  }

  private getQueryFilters() : any{
    let filters = { pm:{statusSync:[], marketIds: []}};
    this.addFilter(filters, "reference", this.searchReference)
    this.addFilter(filters, "model", this.searchModel)
    this.addFilter(filters, "brand", this.searchBrand)

    if(this.synchronizedFilter != null){filters['synchronized'] = this.synchronizedFilter}
    if(this.innerStatusFilter != null && this.innerStatusFilter.length != 0  ){filters.pm['syncStatuses'] = this.innerStatusFilter}
    if(this.sendStatusFilter != null && this.sendStatusFilter.length !=  0){filters.pm['marketStatuses'] = this.sendStatusFilter}
    if(this.externalStatusFilter != null && this.externalStatusFilter.length != 0){filters.pm['readStatuses'] = this.externalStatusFilter}
    
    this.marketPlacesColumn.forEach(market=>{
      if(this.marketSelected.includes(market.id)){
        filters.pm.marketIds.push(market.id);
      }
    });
    return filters;
  }
  private getQueryFiltersReports() : any{
    let filters = { pm:{marketIds: []}};

    if(this.innerStatusFilter != null && this.innerStatusFilter.length != 0  ){filters.pm['syncStatuses'] = this.innerStatusFilter}
    if(this.sendStatusFilter != null && this.sendStatusFilter.length !=  0){filters.pm['marketStatuses'] = this.sendStatusFilter}
    if(this.externalStatusFilter != null && this.externalStatusFilter.length != 0){filters.pm['readStatuses'] = this.externalStatusFilter}
    
    this.marketPlacesColumn.forEach(market=>{
      if(this.marketSelected.includes(market.id)){
        filters.pm.marketIds.push(market.id);
      }
    });
    return filters;
  }

  private addFilter(filters : any, field : string, value){
    if(!!value){
      filters[field] = value
    }
  }

  updateSelectedMarkets(event: MatOptionSelectionChange, market) {
    if(event.isUserInput){
      if (event.source.selected){
        this.marketSelected.push(market.id);
      } else {
        const index = this.marketSelected.indexOf(market.id);
        this.marketSelected.splice(index, 1);
      }
      this.setMarketVisibility(market);
      this.searchProducts();
    }
  }

  updateSelectedStatus(event, status, filter) {
    if(event.checked == true && status.id){
      switch (filter) {
        case 'innerStatusFilter':
          this.innerStatus.forEach(e => {
            if(e.id == status.id){
              e.selected = true;
            }
          });
          this[filter].push(status.id);
        break;

        case 'sendStatusFilter':
          this.sendStatus.forEach(e => {
            if(e.id == status.id){
              e.selected = true;
            }
          });
          this[filter].push(status.id);
        break;

        case 'externalStatusFilter':
          this.externalStatus.forEach(e => {
            if(e.id == status.id){
              e.selected = true;
            }
          });
          this[filter].push(status.id);
        break;
      }
    }
    if(event.checked == false && status.id){
      switch (filter) {
        case 'innerStatusFilter':
          this.innerStatus.forEach(e => {
            if(e.id == status.id){
              e.selected = false;
            }
          });
        const index = this[filter].indexOf(status.id);
        this[filter].splice(index, 1);
        break;
        case 'sendStatusFilter':  
          this.sendStatus.forEach(e => {
            if(e.id == status.id){
              e.selected = false;
            }
          });
        const indexSend = this[filter].indexOf(status.id);
        this[filter].splice(indexSend, 1);
        break;
        case 'externalStatusFilter':
          this.externalStatus.forEach(e => {
            if(e.id == status.id){
              e.selected = false;
            }
          });
        const indexExternal = this[filter].indexOf(status.id);
        this[filter].splice(indexExternal, 1);
        break;
      }
    }
      
    this.searchProducts();
  }

  private setMarketVisibility(market){
    if (this.marketSelected.includes(market.id) && !this.catalogTableHeader.includes(market.name)){
      this.catalogTableHeader.push(market.name);
    } else if(!this.marketSelected.includes(market.id) && this.catalogTableHeader.includes(market.name)) {
      const index = this.catalogTableHeader.indexOf(market.name, 0);
      this.catalogTableHeader.splice(index, 1);
    }
  }

  nextPage() {
    this.page++;
    this.page = Math.min(this.page, this.pageCount)
    this.refreshProductList();
  }

  previousPage() {
    this.page--;
    this.page = Math.max(1, this.page)
    this.refreshProductList();
  }

  resetFilters() {  
    this.marketPlacesColumn.forEach(marketInfo=>{
      marketInfo.statusFilterId = -1;
    })
    this.searchModel = "";
    this.searchBrand = "";
    this.marketSelected = this.marketPlacesColumn.map(market=>market.id);
    this.searchReference = "";
    this.synchronizedFilter = null;
    this.innerStatusFilter = [];
    this.sendStatusFilter = [];
    this.externalStatusFilter = [];
    this.catalogTableHeader.splice(4); 
    this.marketPlacesColumn.forEach(market=>{
      this.catalogTableHeader.push(market.name);
    });
    this.innerStatus.forEach(status=>{
        status.selected = false;
    });
    this.sendStatus.forEach(status=>{
      status.selected = false;
    }); 
   this.searchProducts();
  }
}
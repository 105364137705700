import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {PackageCancelledModel} from "../../../../services/src/models/endpoints/Expeditions/PackageCancelled";
import IPackageCancelledInfo = PackageCancelledModel.IPackageCancelledInfo;
import PackageInfoStatus = PackageCancelledModel.PackageInfoStatus;
import IPackageCancelledLineInfo = PackageCancelledModel.IPackageCancelledLineInfo;

@Component({
  selector: 'suite-packages-warning-cancelled',
  templateUrl: './packages-warning-cancelled.component.html',
  styleUrls: ['./packages-warning-cancelled.component.scss']
})
export class PackagesWarningCancelledComponent implements OnInit {

  packages: IPackageCancelledInfo[];
  callbackOk: () => void;
  callbackCancel: () => void;

  constructor(
    private modalController: ModalController
  ) {
  }

  displayedColumns: string[] = [
    'deliveryRequestExternalId',
    'container',
    'tracking',
    'status',
    'lines'
  ];

  ngOnInit() {
  }

  async dismiss() {
    this.callbackCancel();
    this.modalController.dismiss();
    this.callbackCancel();
  }

  async generateManifest() {
    // this.callbackCancel();
    await this.callbackOk();
    this.modalController.dismiss();
  }

  getStatusName(status: PackageInfoStatus) {
    switch (status) {
      case PackageCancelledModel.PackageInfoStatus.CANCELLED_COMPLETELY:
        return 'Cancelado completo';
      case PackageCancelledModel.PackageInfoStatus.RETURNED_COMPLETELY:
        return 'Devuelto completo';
      case PackageCancelledModel.PackageInfoStatus.CANCELLED_PARTIALLY:
        return 'Cancelado parcialmente';
      case PackageCancelledModel.PackageInfoStatus.RETURNED_PARTIALLY:
        return 'Devuelto parcialmente';
      default:
        return '';
    }
  }

  getLinesFormat(lines: IPackageCancelledLineInfo[]) {
    if(!(lines && lines.length > 0)){
      return '-';
    }
    return lines.map(l=>`${l.modelReference}-${l.sizeNumber}`).join(', ');
  }
}

import { Component, OnInit } from '@angular/core';
import { ListProductsCarrierService } from '../../../../services/src/lib/endpoint/list-products-carrier/list-products-carrier.service';
import { CarrierService, IntermediaryService } from '@suite/services';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { FiltersAuditProvider } from '../../../../services/src/providers/filters-audit/filters-audit.provider';
import { FiltersListComponent } from './filters-list/filters-list.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AudioProvider } from '../../../../services/src/providers/audio-provider/audio-provider.provider';

@Component({
  selector: 'suite-list-products-carrier',
  templateUrl: './list-products-carrier.component.html',
  styleUrls: ['./list-products-carrier.component.scss'],
})
export class ListProductsCarrierComponent implements OnInit {

  destinyPacking: string = null;
  packingProducts: any[] = [];
  carrierReference = '';
  returnPacking: boolean;
  returnPicking: string;
  carrierDestiny = '';
  packingInventorys = [];
  form: FormGroup = this.formBuilder.group({
    products: [],
    warehouses: [],

    orderby: this.formBuilder.group({
      type: '1',
      order: "asc"
    })
  });

  private process: string;
  private readonly isSorterProcess: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private listProductsCarrierService : ListProductsCarrierService,
    private intermediaryService: IntermediaryService,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private filtersAuditProvider: FiltersAuditProvider,
    private navParams: NavParams,
    private route: Router,
    private carrierService: CarrierService,
    private audioProvider: AudioProvider,
  ) {
    this.carrierReference = this.navParams.get('carrierReference');
    this.carrierDestiny = this.navParams.get('carrierDestiny');
    this.packingInventorys = this.navParams.get('packingInventorys');
    this.process = this.navParams.get('process');
    this.isSorterProcess = !!this.navParams.get('isSorterProcess');
    this.returnPacking = !!this.navParams.get('returnPacking');
    this.returnPicking = this.navParams.get('returnPicking');
  }

  async ngOnInit() {
    this.filtersAuditProvider.sort = null;
    this.filtersAuditProvider.filter = 0;
    if(!this.packingInventorys || this.packingInventorys.length==0) {
      await this.getProducts();
    }else{
      this.packingProducts = this.packingInventorys;
      this.destinyPacking = this.carrierDestiny;
    }
  }

  private async getProducts(data = null) {
    await this.intermediaryService.presentLoading();
    await this.listProductsCarrierService.getProducts(this.carrierReference, data).subscribe(async (res: any) => {

      if (res.data.results && res.data.results.length > 0) {
        this.packingProducts = res.data.results[0].packingInventorys;
        this.destinyPacking = (res.data.results[0].cwd && res.data.results[0].cwd.warehouse) ? res.data.results[0].cwd.warehouse.reference : null;
      } else {
        this.packingProducts = [];
      }

      await this.intermediaryService.dismissLoading();
    }, async (err) => {
      await this.intermediaryService.presentToastError(err.error.errors);
    })
  }

  async filterProducts() {
    const popover = await this.popoverController.create({
      cssClass: 'popover-filter',
      component: FiltersListComponent,
      componentProps: { form: this.form, carrierReference: this.carrierReference },
      event
    });

    popover.onDidDismiss().then(async (res) => {
      if (res.data) {
        const arrayProducts = [];
        const arrayWarehouses = [];

        Object.keys(res.data.items.products).map(function(key){
          arrayProducts.push(res.data.items.products[key].value);
          return arrayProducts;
        });

        res.data.items.products = arrayProducts;

        Object.keys(res.data.items.warehouses).map(function(key){
          arrayWarehouses.push(res.data.items.warehouses[key].value);
          return arrayWarehouses;
        });

        res.data.items.warehouses = arrayWarehouses;

        await this.getProducts(res.data.items);
        this.form = res.data.form;
      }
    });

    await popover.present();
  }

  async close(con = false) {
     await this.modalController.dismiss();
  }

  async btnContinue(con = false) {
    if(con){
      await this.modalController.dismiss(this.carrierReference);
    }else{
      await this.modalController.dismiss();
    }
  }

  async btnCarrierEmpty(emptyPackingInReturn: boolean) {
    await this.intermediaryService.presentLoadingNew('Vaciando embalaje...');

    const callbackOk = (res) => {
      if (res.code === 200) {
        this.audioProvider.playDefaultOk();
        this.intermediaryService.dismissLoadingNew();
        this.intermediaryService.presentToastSuccess(`El embalaje ${this.carrierReference} se ha vaciado corectamente`);
        this.modalController.dismiss(this.carrierReference);
      } else {
        this.audioProvider.playDefaultError();
        this.intermediaryService.dismissLoadingNew();
        this.intermediaryService.presentToastError(res.errors);
      }
    };
    const callbackError = (error) => {
      this.audioProvider.playDefaultError();
      this.intermediaryService.dismissLoadingNew();
      this.intermediaryService.presentToastError(error.message);
    };

    /*
    * IF the modal was invoked from a sorter process
    *   will request the empty-packing endpoint created in sorter service
    * ELSE will requests the endpoint created in MGA
    * */
    if (this.isSorterProcess) {
      this.carrierService
        .postPackingEmptySorter(this.carrierReference, this.process, emptyPackingInReturn)
        .then(callbackOk)
        .catch(callbackError);
    } else {
      this.carrierService
        .postPackingEmpty(this.carrierReference, this.process, emptyPackingInReturn)
        .then(callbackOk)
        .catch(callbackError);
    }
  }

  async btnPosition(ruta:string) {
    await this.route.navigateByUrl(ruta);
    await this.modalController.dismiss(ruta,'navigate');
  }

  async btnCancel() {
    await this.modalController.dismiss();
  }

  async btnReturnEmpty() {
    await this.modalController.dismiss('emptyPacking');
  }

}

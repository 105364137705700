import {AfterViewInit, Component, OnInit, ViewChild, OnDestroy} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {RegularizationModel} from "../../../services/src/models/endpoints/Regularization";
import Regularization = RegularizationModel.Regularization;
import {RegularizationService} from "../../../services/src/lib/endpoint/regularization/regularization.service";
import {DateTimeParserService} from "../../../services/src/lib/date-time-parser/date-time-parser.service";
import getRegularizationResponse = RegularizationModel.getRegularizationResponse;
import {Events, ModalController,IonInfiniteScroll} from "@ionic/angular";
import getAvailableModelsResponse = RegularizationModel.getAvailableModelsResponse;
import postSaveRegularizationResponse = RegularizationModel.postSaveRegularizationResponse;
import { ToolbarProvider } from '../../../services/src/providers/toolbar/toolbar.provider';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { IntermediaryService } from '@suite/services';
import { validators } from '../utils/validators';
import { TagsInputOption } from '../components/tags-input/models/tags-input-option.model';
import {PaginatorComponent} from "../components/paginator/paginator.component";
import {AppFiltersModel} from '../../../services/src/models/endpoints/AppFilters';
import {PositionsToast} from '../../../services/src/models/positionsToast.type';
import {TimesToastType} from '../../../services/src/models/timesToastType';

@Component({
  selector: 'suite-regularization-form',
  templateUrl: './regularization-form.component.html',
  styleUrls: ['./regularization-form.component.scss']
})
export class RegularizationFormComponent implements OnInit, OnDestroy {

  regularizationId: number;
  regularization: Regularization = {
    id: null,
    createdAt: new Date().toISOString(),
    finishedAt: null,
    regModels: [],
    status: 1
  };

  //////////------------------///////////////////////

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  limit: number = undefined;
  page: number = 0;

  public listItems: {id: number, reference: string}[] = [];
  public searchText: string = null;

  private originaListItems = [];
  paginatedListItems: {id: number, reference: string}[] = [];

  private LIMIT_PAGINATION: number = 30;

  selectedForm: FormGroup = this.formBuilder.group({
    selector: false
  },{
    validators: validators.haveItems("toSelect")
  });

  formFilters: FormGroup = this.formBuilder.group({
    models: [],
    families: [],
    lifestyles: [],
    seasons:[],
    brands:[],
    pagination: this.formBuilder.group({
      page: 1,
      limit: 20
    }),
    orderby: this.formBuilder.group({
      type: '',
      order: "asc"
    })
  });

  models: Array<TagsInputOption> = [];
  sizes: Array<TagsInputOption> = [];
  families: Array<TagsInputOption> = [];
  lifestyles: Array<TagsInputOption> = [];
  seasons: Array<TagsInputOption> = [];
  brands: Array<TagsInputOption> = [];
  ordertypes: Array<TagsInputOption> = [];

  showFilters: boolean = false;
  ngInit: boolean;
  pauseListenFormChange = false;
  requestTimeout: any = null;
  loadedData = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalController: ModalController,
    private events: Events,
    private toolbarProvider: ToolbarProvider,
    private formBuilder: FormBuilder,
    private dateTimeParserService: DateTimeParserService,
    private intermediaryService: IntermediaryService,
    private regularizationService: RegularizationService,
  ) {}

  async ionViewWillEnter() {
    this.toolbarProvider.optionsActions.next([
      {
        icon: 'funnel',
        label: 'Filtros',
        action: () => this.showFilters = !this.showFilters
      }
    ]);

    console.log('Iniciandoo')
    this.regularization = {
      id: null,
      createdAt: new Date().toISOString(),
      finishedAt: null,
      regModels: [],
      status: 1
    };

    /////---////////
    this.limit = this.paginator.finalPagerValues[0];

    await this.initForm();
    await this.getFilters();
    this.listenChangesPaginator();
    this.selectedForm = this.formBuilder.group({
      selector: false
    }, {
      validators: validators.haveItems("toSelect")
    });
  }

  async ngOnInit() { }

  async ngOnDestroy() {
    await this.toolbarProvider.optionsActions.next([]);
    await this.events.publish('exit-regularization');    
  }

  load(){
    this.regularizationService.getLoad({ id: this.regularizationId }).then((response: getRegularizationResponse) => {
      if(response.code == 200) {
        this.regularization = response.data;
        if(this.startAvailable()) {
          this.router.navigate(['/regularization-route', { id: this.regularization.id }])
        }
      }else{
        console.error(response);
      }
    }, console.error).catch(console.error);
  }

  getStatusText(status: number){
    switch(status){
      case 1:
        return 'Creada';
      case 2:
        return 'En proceso';
      case 3:
        return 'Finalizada';
      default:
        return 'Desconocido';
    }
  }

  saveDisabled(): boolean {

    return this.selectedForm['controls'] && this.selectedForm['controls']['toSelect'] && this.selectedForm['controls']['toSelect'].value.filter(rm => rm == true).length == 0;
  }

  addDisabled(): boolean {
    return this.regularization.status != 1;
  }

  startAvailable(){
    return this.regularization.regProcesses && this.regularization.regProcesses.filter(m => m.regularized == false).length > 0;
  }

  async save() {
    this.selectedForm['controls']['toSelect'].value.forEach((element, index) => {
      if (element) {
        const selectedModel: any = {};
        selectedModel.model = this.paginatedListItems[index];
        this.regularization.regModels.push(selectedModel);
      }
    });

    this.regularizationService.postSave(this.regularization).then(async (response: postSaveRegularizationResponse) => {
      if (response.code == 200) {
        this.regularizationId = response.data;
        this.load();
      } else {
        console.error(response);
        await this.intermediaryService.presentToastError(response.errors, PositionsToast.BOTTOM, TimesToastType.DURATION_ERROR_TOAST);
        await this.applyFilters();
      }
    }, console.error).catch(console.error);
  }

  /////////-----////////////
  async getModelsAvailableForRegularization(): Promise<{id: number, reference: string}[]> {
    let models: {id: number, reference: string}[] = [];
    await this.regularizationService.getAvailableModels().then((response: getAvailableModelsResponse) => {
      if(response.code == 200){
        models = response.data;
      }else{
        console.error(response);
      }
    }, console.error).catch(console.error);
    return models;
  }

  public async applyFilters() {
    await this.intermediaryService.presentLoading('Cargando productos...');
    if (this.pauseListenFormChange) return;
      this.formFilters.value.pagination.page = 1;
      this.searchModels(this.getFormValueCopy());
  }

  async clearFilters() {
    this.formFilters = this.formBuilder.group({
      models: [],
      families: [],
      lifestyles: [],
      seasons: [],
      brands: [],
      pagination: this.formBuilder.group({
        page: this.page || 1,
        limit: this.limit || this.paginator.finalPagerValues[0]
      }),
      orderby: this.formBuilder.group({
        type: '',
        order: "asc"
      })
    });

    await this.initForm();
    await this.getFilters();
  }

  private getFilters(): void {
    this.regularizationService
      .getAvailableModelsFilters()
      .then((res: AppFiltersModel.ResponseRegularizationModelsRequested) => {

        this.models = res.data.models;
        this.lifestyles = res.data.lifestyles;
        this.families = res.data.families;

        this.seasons = res.data.seasons;
        this.brands = res.data.brands;

        this.ordertypes = res.data.ordertypes;
        this.applyFilters();
      });
  }

  private initSelectedForm() {
    if (!this.selectedForm['controls']['toSelect'] || this.selectedForm['controls']['toSelect'].value.length != this.paginatedListItems.length) {
      this.selectedForm.removeControl("toSelect");
      this.selectedForm.addControl("toSelect", this.formBuilder.array(this.paginatedListItems.map(x => new FormControl(false))));
    }
  }

  private searchModels(form?) {
    this.regularizationService.postSearchAvailableModels(form)
      .subscribe(async (res: any) => {
        this.paginatedListItems = res.result;
        this.initSelectedForm();
        this.showFilters = false;
        let paginator = res.pagination;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage > 0 ? paginator.lastPage : 1;
        this.loadedData = true;
        await this.intermediaryService.dismissLoading();
      }, async (error) => {
        console.error('Error::Subscribe::postSearchAvailableModels -> ', error);
        this.loadedData = true;
        await this.intermediaryService.dismissLoading();
      });
  }

  private listenChangesPaginator(): void {
    let previousPageSize = this.limit;

    this.paginator.page.subscribe(async page => {
      await this.intermediaryService.presentLoading('Cargando productos...');

      let flag = previousPageSize == page.pageSize;
      previousPageSize = page.pageSize;
      this.limit = page.pageSize;
      this.page = flag ? page.pageIndex : 1;
      this.formFilters.value.pagination.page = this.page;
      this.formFilters.value.pagination.limit = this.limit;

      this.searchModels(this.getFormValueCopy());
    });
  }

  initForm() {
    this.formFilters.patchValue({
      models: [],
      families: [],
      lifestyles: [],
      seasons: [],
      brands: [],
      pagination: this.formBuilder.group({
        page: 1,
        limit: this.limit
      }),
      orderby: this.formBuilder.group({
        type: '',
        order: "asc"
      })
    })
  }

  private clone(toClone: any) {
    return JSON.parse(JSON.stringify(toClone));
  }

  private getFormValueCopy() {
    return JSON.parse(JSON.stringify(this.formFilters.value || {}));
  }

  checkAll(event){
    this.selectedForm.removeControl("toSelect");
    this.selectedForm.addControl("toSelect", this.formBuilder.array(this.paginatedListItems.map(x => new FormControl(event.detail.checked))));
  }

}

import {Component, OnInit} from '@angular/core';
import {PickingProvider} from "../../../services/src/providers/picking/picking.provider";
import {PickingScanditService} from "../../../services/src/lib/scandit/picking/picking.service";
import {PickingStoreModel} from "../../../services/src/models/endpoints/PickingStore";
import {PickingStoreService} from "../../../services/src/lib/endpoint/picking-store/picking-store.service";
import {IntermediaryService} from "@suite/services";
import {Router} from "@angular/router";
import ResponseCheckExternalWarehouse = PickingStoreModel.ResponseCheckExternalWarehouse;
import {AlertController} from "@ionic/angular";

@Component({
  selector: 'app-picking-scan-packing',
  templateUrl: './picking-scan-packing.component.html',
  styleUrls: ['./picking-scan-packing.component.scss']
})
export class PickingScanPackingComponent implements OnInit {

  message = 'No hay ningún producto procesado para asociar a embalajes.';

  constructor(
    private pickingProvider: PickingProvider,
    private pickingStoreService: PickingStoreService,
    private pickingScanditService: PickingScanditService,
    private intermediaryService: IntermediaryService,
    private router: Router,
    private alertController: AlertController
  ) {}

  async ngOnInit() {
  }

  async ionViewWillEnter() {
    await this.load();
  }

  private async load() {
    this.message = 'Cargando...';
    await this.intermediaryService.presentLoadingNew();
    this.pickingStoreService.getCheckExternalWarehouse().then(async (response: ResponseCheckExternalWarehouse) => {
      if (response.code == 200) {
        if (response.data.externalWarehouseOnly) {
          if (response.data.externalRequests.length > 0) {
            this.finishLoad('external');
          } else {
            await this.intermediaryService.dismissLoadingNew();
            this.message = 'No hay ningún producto procesado para asociar a embalajes.';
          }
        }else{
          this.finishLoad([]);
        }
      } else {
        console.error(response);
      }
    }, console.error).catch(console.error);
  }

  private finishLoad(subgroup?){
    let filtersToGetProducts: PickingStoreModel.ParamsFiltered = {
      orderbys: [],
      sizes: [],
      colors: [],
      models: [],
      brands: [],
      toAssociate: true,
      external: subgroup && subgroup == 'external',
      store: subgroup && subgroup == 'store'
    };

    this.pickingProvider.subgroup = subgroup;

    if ((<any>window).cordova) {
      this.pickingStoreService.postLineRequestsProccesed(filtersToGetProducts).then(async (res: PickingStoreModel.ResponseLineRequestsFiltered) => {
        await this.intermediaryService.dismissLoadingNew();
        if (res.code == 200 || res.code == 201) {
          this.pickingProvider.listProductsToStorePickings = [];
          this.pickingProvider.listProductsProcessedToStorePickings = res.data.processed;
          if(res.data && res.data.orderPackages && res.data.orderPackages.length > 0){
            for(let pack of res.data.orderPackages){
              this.pickingProvider.listProductsProcessedToStorePickings.push(<any> pack);
            }
          }
          this.pickingProvider.listFiltersPicking = res.data.filters;
          if (this.pickingProvider.listProductsProcessedToStorePickings && this.pickingProvider.listProductsProcessedToStorePickings.length > 0) {
            await this.pickingScanditService.picking(true, 'Asociar pares a embalajes');
            this.router.navigate(['/welcome']);
          } else {
            this.message = 'No hay ningún producto procesado para asociar a embalajes.';
          }
        }
      });
    } else {
      console.error('Error Not Cordova');
    }
  }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {PaginatorComponent} from "../components/paginator/paginator.component";
import {HiringDhlModel} from "../../../services/src/models/endpoints/HiringDhl";
import Filters = HiringDhlModel.Filters;
import FilterOptions = HiringDhlModel.FilterOptions;
import FilterOptionsResponse = HiringDhlModel.FilterOptionsResponse;
import Order = HiringDhlModel.Order;
import Pagination = HiringDhlModel.Pagination;
import SearchParameters = HiringDhlModel.SearchParameters;
import {NavigationEnd, Router} from "@angular/router";
import {HiringDhlService} from "../../../services/src/lib/endpoint/hiring-dhl/hiring-dhl.service";
import { IntermediaryService } from '../../../services/src/lib/endpoint/intermediary/intermediary.service';
import {DateTimeParserService} from "../../../services/src/lib/date-time-parser/date-time-parser.service";
import {MatTableDataSource} from "@angular/material/table";
import {ModalController} from "@ionic/angular";
import {UpdateHiringDhlComponent} from "./update-hiring-dhl/update-hiring-dhl.component";

@Component({
  selector: 'suite-hiring-dhl',
  templateUrl: './hiring-dhl.component.html',
  styleUrls: ['./hiring-dhl.component.scss']
})
export class HiringDhlComponent implements OnInit {

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  dataSource;
  public displayedColumns: string[] = ['warehouseReference', 'nameWarehouse', 'userId', 'keyDhl', 'accountId'];

  dataTable = [];
  hiring;
  filters: Filters = {
    warehouseReference: [],
    nameWarehouse: [],
    userId: [],
    keyDhl: [],
    accountId: []
  };

  filterOptions: FilterOptions  = {
    warehouseReference: [],
    nameWarehouse: [],
    userId: [],
    keyDhl: [],
    accountId: []
  };
  order: Order = {
    field: 'warehouseReference',
    direction: 'ASC'
  };
  pagination: Pagination = {
    limit: undefined,
    page: 1
  };

  constructor(
    public router: Router,
    private hiringDhlService: HiringDhlService,
    private intermediaryService: IntermediaryService,
    private dateTimeParserService: DateTimeParserService,
    private modalCtrl: ModalController,
  ) {
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd && val && val.url == '/hiring-dhl'){
        if(typeof this.hiring !== 'undefined'){
          this.reset();
        }
      }
    });
  }

  ngOnInit() {
    this.loadFilters();
    this.dataSource = new MatTableDataSource();
    this.paginator.page.subscribe(paginator => {
      if (this.pagination.limit != paginator.pageSize) {
        this.pagination.page = 1;
      } else {
        this.pagination.page = paginator.pageIndex;
      }
      this.pagination.limit = paginator.pageSize;
      this.loadHiring();
    });
  }

  ngAfterViewInit(){
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadHiring();
  }

  loadFilters(){
    this.hiringDhlService.getFilterOptions().then((response: FilterOptionsResponse) => {
      this.hiringDhlService.getFilterOptionsDhl().then((responseDhl: FilterOptionsResponse) => {
        if(response.code == 200){
          this.filterOptions = {
            warehouseReference: response.data.warehouseReference.map((data, index) => {
              const warehouseReference1 = JSON.stringify(data);
              const warehouseReference = JSON.parse(warehouseReference1);
              return {
                id: warehouseReference.id,
                value: warehouseReference.warehouseReference,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            nameWarehouse: response.data.nameWarehouse.map((data, index) => {
              const nameWarehouse1 = JSON.stringify(data);
              const nameWarehouse = JSON.parse(nameWarehouse1);
              return {
                id: nameWarehouse.id,
                value: nameWarehouse.nameWarehouse,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            userId: responseDhl.data.userId.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            keyDhl: responseDhl.data.keyDhl.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            accountId: responseDhl.data.accountId.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            })
          };
        }else{
          console.error(response);
        }
      }).catch(console.error);
    }).catch(console.error);
  }

  async loadHiring(){
    const parameters: SearchParameters = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };

    this.dataTable = [];
    await this.hiringDhlService.getAllWarehouses(parameters).subscribe(async data => {
      let dataWarehouses = data.result;
      await this.hiringDhlService.getAllWarehousesDhl(parameters).subscribe(async data => {
        let dataDhl = data.result;

        if(this.filters.userId.length > 0 || this.filters.keyDhl.length > 0 || this.filters.accountId.length > 0){
          dataWarehouses.forEach(async (dataWarehouse) => {
            dataDhl.forEach(async (dataS) => {
              if (parseInt(dataWarehouse.reference) == dataS.warehouseReference) {
                this.dataTable.push({
                  warehouseReference: parseInt(dataWarehouse.reference),
                  nameWarehouse: dataWarehouse.name,
                  idWarehouse: dataS.id,
                  userId: dataS.userId,
                  keyDhl: dataS.keyDhl,
                  accountId: dataS.accountId
                });
              }
            });
          });
        }else{
          dataWarehouses.forEach(async (dataWarehouse) => {
            this.dataTable.push({
              warehouseReference: parseInt(dataWarehouse.reference),
              nameWarehouse: dataWarehouse.name,
              userId: null,
              keyDhl: null,
              accountId: null
            });
            dataDhl.forEach(async (dataS) => {
              if(parseInt(dataWarehouse.reference) == dataS.warehouseReference){
                for(let i = 0; i < this.dataTable.length; i++){
                  if(parseInt(dataWarehouse.reference) == this.dataTable[i].warehouseReference && dataS.warehouseReference == this.dataTable[i].warehouseReference){
                    this.dataTable.splice(i, 1, {
                      warehouseReference: parseInt(dataWarehouse.reference),
                      nameWarehouse: dataWarehouse.name,
                      idWarehouse: dataS.id,
                      userId: dataS.userId,
                      keyDhl: dataS.keyDhl,
                      accountId: dataS.accountId
                    });
                  }
                }
              }
            });
          });
        }

        if(this.order.field == 'userId' || this.order.field == 'keyDhl' || this.order.field == 'accountId'){
          this.dataSource = new MatTableDataSource(this.orderItemsDhl(this.dataTable, this.order.field, this.order.direction));
        }else{
          this.dataSource = new MatTableDataSource(this.dataTable);
        }
      });
      const paginator = data.pagination;
      this.paginator.cantSelect = paginator.limit;
      this.paginator.length = paginator.totalResults;
      this.paginator.pageIndex = paginator.selectPage;
      this.paginator.lastPage = paginator.lastPage;
    });
  }

  async openModalWarehouse(event, element) {
    event.stopPropagation();
    event.preventDefault();
    let modal = (await this.modalCtrl.create({
      component: UpdateHiringDhlComponent,
      componentProps: {
        element
      }
    }));
    modal.onDidDismiss().then((data) => {
      this.loadHiring();
    });
    modal.present();
  }

  reset(){
    this.filters = {
      warehouseReference: [],
      nameWarehouse: [],
      userId: [],
      keyDhl: [],
      accountId: []
    };
    this.order = {
      field: 'warehouseReference',
      direction: 'ASC'
    };
    this.pagination = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 1;
    this.loadFilters();
    this.loadHiring();
  }

  orderBy(column: string){
    if(this.order.field == column){
      this.order.direction == 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.loadHiring();
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        if(column == 'warehouseReference'){
          values.push(item.id);
        }else{
          values.push(item.value);
        }
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.pagination.page = 1;
    this.loadHiring();
  }

  orderItemsDhl(items, field, direction){
    let data;
    switch(field){
      case 'userId':
        data = data = items.sort(function (a, b) {
          if (a.userId > b.userId) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.userId < b.userId) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
      case 'keyDhl':
        data = items.sort(function (a, b) {
          if (a.keyDhl > b.keyDhl) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.keyDhl < b.keyDhl) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
      case 'accountId':
        data = items.sort(function (a, b) {
          if (a.accountId > b.accountId) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.accountId < b.accountId) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
    }
    return data;
  }

}

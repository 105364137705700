import { Injectable } from '@angular/core';
import {AuthenticationService, environment} from "@suite/services";
import {HiringDhlModel} from "../../../models/endpoints/HiringDhl";
import SearchParameters = HiringDhlModel.SearchParameters;
import FilterOptionsResponse = HiringDhlModel.FilterOptionsResponse;
import {RequestsProvider} from "../../../providers/requests/requests.provider";
import {from, Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HiringDhlService {

  private apiMga = environment.apiBase;
  private apiDhl = environment.apiDhl;

  private getAllWarehousesUrl = this.apiMga + '/warehouses/getAllWarehouses';
  private getWarehouseDhlUrl = this.apiDhl + '/warehouse/';
  private updateWarehouseDhlUrl = this.apiDhl + '/warehouse/';
  private deleteWarehouseDhlUrl = this.apiDhl + '/warehouse/';
  private getFilterOptionsUrl = this.apiMga + '/warehouses/filter-options';
  private getFilterOptionsUrlDhl = this.apiDhl + '/warehouse/filter-options';

  constructor(
    private requestsProvider: RequestsProvider,
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getAllWarehouses(params: SearchParameters):Observable<any> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
      return this.http.post<any>(this.getAllWarehousesUrl, params, {headers}).pipe(map(response=>{
        return response.data;
      }));
    }));
  }

  getAllWarehousesDhl(params: SearchParameters){
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
      return this.http.post<any>(this.getWarehouseDhlUrl, params, {headers}).pipe(map(response=>{
        return response.data;
      }));
    }));
  }

  updateWarehouseDhl(data){
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
      return this.http.put<any>(this.updateWarehouseDhlUrl + data.warehouseReference, data, {headers}).pipe(map(response=>{
        return response.data;
      }));
    }));
  }

  deleteWarehouseDhl(id:number){
    return this.http.delete<any>(this.deleteWarehouseDhlUrl + id)
      .pipe(map(response => {
        return response;
      }));
  }

  getFilterOptions(): Promise<FilterOptionsResponse> {
    return this.requestsProvider.get(this.getFilterOptionsUrl);
  }

  getFilterOptionsDhl(): Promise<FilterOptionsResponse> {
    return this.requestsProvider.get(this.getFilterOptionsUrlDhl);
  }
}

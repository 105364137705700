import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ReceptionPairByPairComponent } from './reception-pair-by-pair.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: '',
    component: ReceptionPairByPairComponent
  },
];

@NgModule({
  declarations: [ReceptionPairByPairComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    IonicModule,
    FormsModule,
  ]
})
export class ReceptionPairByPairModule { }

import { Component, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {ModalController, NavParams} from '@ionic/angular';
import {MatTableDataSource} from '@angular/material';
import {MarketplacesService} from 'libs/services/src/lib/endpoint/marketplaces/marketplaces.service';
import { trigger, state, style, transition, animate } from "@angular/animations";

export interface PeriodicElement {
  size: string;
  reference: number;
  color: string;
  brand: string;
  name: string;
  updatedStatus: string;
  date: string;
  internalStatus: string;
  marketStatus: string;
}

export interface Element {
  reference: number;
  color: string;
  brand: string;
  name: string;
  updatedStatus: string;
  date: string;
  internalStatus: string;
  marketStatus: string;
}

@Component({
  selector: "app-modal-dialog",
  templateUrl: "./modal-dialog.component.html",
  styleUrls: ["./modal-dialog.component.scss"],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
}) 

export class ModalSizes{
  
  @ViewChild('catalog') catalog: ElementRef;
    displayedColumns: string[] = ['size','color','innerStatus','readStatus','marketStatus', 'expand'];
    displayedColumnsVariationDetails: string[] = ['size','observations'];
    catalogTableData : MatTableDataSource<any> = new MatTableDataSource([]);
    expandedElement: any | null;
    catalogTableDataVariationsDetails;
  
    innerStatus= [
      {id: 'invalid', name: 'Invalido'},
      {id: 'valid', name: 'Valido'},
      {id: 'uploaded', name:'Subido'},
      {id: 'missing', name: 'Faltante'},
      {id: null, name: 'Sin estado'}
    ];
    
    marketStatus = [
     {id: 'unknown', name: 'Desconocido'},
     {id: 'uploaded', name: 'Subido'},
     {id: 'uploadedMissing', name: 'uploadedMissing'},
     {id: null, name: 'Sin estado'},
   ];

    readStatus = [
      {id: 'missing', name: 'No encontrado'},
      {id: 'synced', name: 'Sincronizado'},
      {id: 'mismatch', name: 'Diferente precio/stock'},
      {id: 'hasIssues', name: 'Con errores'},
      {id: null, name: 'Sin estado'},
    ];

    statusSync = [
      {id: 'invalid', name: 'Invalido', selected: false},
      {id: 'valid', name: 'Valido', selected: false },
      {id: 'validWithIssues', name: 'Valido con errores', selected: false},
      {id: 'uploaded', name:'Subido', selected: false},
      {id: 'rejected', name: 'Rechazado', selected: false},
      {id: 'failed', name: 'Error', selected: false}
    ];

    statusMarket = [
      {id: 'unknown', name: 'Desconocido', selected: false},
      {id: 'uploaded', name: 'Subido', selected: false},
      {id: 'uploadedStock0', name: 'Subido - stock 0', selected: false},
      {id: 'uploadedWithErrors', name: 'Subido con errores', selected: false},
    ];

    statusRead = [
      {id: 'unknown', name: 'Desconocido', selected: false},
      {id: 'synced', name: 'Sincronizado', selected: false},
      {id: 'hasIssues', name: 'Con errores', selected: false},
    ];

    product;
    productCreatedAtDate;
    productUpdatedAtDate;
    productUploadedAtDate;
    observations;
    description = "";
    marketplaces = [];
    marketPlacesColumn : any[] = [];
    marketSelected = this.marketplaces;
    searchReference;
    refresher;

    constructor(
    public dialogo: MatDialog,
    private modalController: ModalController,
    private marketplacesService: MarketplacesService,
    private navParams: NavParams

    ) {
      this.product = this.navParams.get("product");
      }
    
ngOnInit() {
  this.getProductsData();
}

close(data) {
  this.modalController.dismiss(data);
}

getProductsData() {
  this.marketplacesService.searchProductWithVariationsCatalog(this.buildFilter()).subscribe(response => {
    this.showProductsInfo(response.data)
  });
}

getStatus(productMarketStatus, typeOfStatus){
  if(productMarketStatus != null && productMarketStatus != '' && productMarketStatus != undefined){ 
  return this[typeOfStatus].find(element => element.id === productMarketStatus).name;
  }else{
    return "Sin datos";
  }
}

getValidationDetails(variation){
  let validationDetails = '';
  variation.validationDetails.forEach(readDetail => {
    if(readDetail.length > 0){
      validationDetails = validationDetails+" "+readDetail;
    }
  });
  return validationDetails;
}

private showProductsInfo(products){
  const productInfoList = [];
  products.forEach((product) => {
    let productInfo = {
      reference : product.reference,
      model : product.model,
      brand : product.brand,
      color : product.color,
      status : {},
      statusActive: {},
      statusDescription : {},
      productsMarkets : product.productsMarkets,
    }
  
    for (let productMarket of product.productsMarkets) {
      productInfo.status[productMarket.id] = productMarket.status;
    }

    this.description = productInfo.reference + ' - ' + productInfo.model + ' - ' + productInfo.brand + ' - ' + productInfo.color;
    productInfo.productsMarkets[0].variations.forEach(async variation => {
      variation.color = productInfo.color;
      variation.updatedAt = await this.formatDate(new Date(variation.updatedAt));
    });
    productInfoList.push(productInfo.productsMarkets[0].variations);
  });

  this.catalogTableData = new MatTableDataSource(productInfoList[0]);
  this.catalogTableDataVariationsDetails = this.catalogTableData;
}

async formatDate(date) {
  return (
    [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join('/') +
    ' ' +
    [
      this.padTo2Digits(date.getHours()),
      this.padTo2Digits(date.getMinutes()),
      this.padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

private buildFilter(options ?: {limit: number, page :number }) : any {
  return {
    limit : options ? options.limit : 15,
    orderBy : 'reference',
    filter : this.getQueryFilters()
  }
}

private getQueryFilters() : any{
  let filters = {pm:{marketIds:[]}};
  if(this.product!=null){
    this.addFilter(filters,"reference", this.product.reference)};
    filters.pm.marketIds.push(this.product.productsMarkets[0].marketId);
    return filters;
  }
  
private addFilter(filters : any, field : string, value){
  if(!!value){
    filters[field] = value
  }
}
}
import { Component, OnInit, ViewChild, Input, Output, OnChanges, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment'
import {IonSlides, ModalController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {formatDate} from '@angular/common';
import {IntermediaryService, IncidentsService, environment, UploadFilesService} from '../../../services/src';
import {DropFilesService} from '../../../services/src/lib/endpoint/drop-files/drop-files.service';
import {SignatureComponent} from '../signature/signature.component';
import {DropFilesComponent} from '../drop-files/drop-files.component';
import {PhotoModalComponent} from './components/photo-modal/photo-modal.component';
import {SignaturePad} from 'angular2-signaturepad/signature-pad';
import {Platform} from '@ionic/angular';
import {FileTransfer, FileUploadOptions, FileTransferObject, FileUploadResult} from '@ionic-native/file-transfer/ngx';
import {ReviewImagesComponent} from './components/review-images/review-images.component';
import {ProductModel, ProductsService} from '@suite/services';
import {AlertController} from "@ionic/angular";
import {PositionsToast} from '../../../services/src/models/positionsToast.type';
import {ToolbarProvider} from "../../../services/src/providers/toolbar/toolbar.provider";
import {Subscription} from 'rxjs';
import {ItemReferencesProvider} from "../../../services/src/providers/item-references/item-references.provider";
import {PrintTicketService} from '../../../services/src/lib/print-ticket/print-ticket.service';
import {DefectiveRegistryModel} from "../../../services/src/models/endpoints/DefectiveRegistry";
import DefectiveRegistry = DefectiveRegistryModel.DefectiveRegistry;
import {IncidenceModel} from "../../../services/src/models/endpoints/Incidence";
//import { ReviewImagesComponent } from './components/review-images/review-images.component';
import {SelectScrollbarComponent} from './components/select-scrollbar/select-scrollbar.component';
import {PopoverController} from "@ionic/angular";
import {SelectScrollbarProvider} from "../../../services/src/providers/select-scrollbar/select-scrollbar.provider";

declare let ScanditMatrixSimple;

const BACKGROUND_COLOR_ERROR: string = '#e8413e';
const BACKGROUND_COLOR_INFO: string = '#15789e';
const TEXT_COLOR: string = '#FFFFFF';
const HEADER_BACKGROUND: string = '#222428';
const HEADER_COLOR: string = '#FFFFFF';

@Component({
  selector: 'suite-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.scss']
})
export class IncidentsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  select1: boolean = false;
  select2: boolean = false;
  allOptions = [];
  allDefectType = [];
  ticketEmit: boolean;
  passHistory: boolean;
  requirePhoto: boolean = false;
  requireContact: boolean = false;
  requireOk: boolean;
  checkHistory: boolean;
  txtName = "";
  txtInfo = "";
  name;
  info;
  managementId;
  defectParentId;
  defectChildId;
  defectZoneChildId;
  defectZoneParentId;
  slideOpts = {
    speed: 400
  };
  date: string;
  dateNow = moment().format("YYYY-MM-DD");
  dateDetection;
  incidenceForm: FormGroup;
  defectContacto: FormGroup;
  readed: boolean
  barcode: string = ''
  defects: any = [];
  defectsSubtypes: any = [];
  defectChildsOfParent: any = [];
  zones: any = [];
  zonesChilds: any = [];
  defectZonesChildsOfParent: any = [];
  statusManagament: any;
  public barcodeRoute = null;
  public types: any;
  public differentState: boolean = true;
  private typeIdBC: number;
  showKeyboard: boolean

  private varTrying;
  apiURL: string = environment.uploadFiles + '?type=defects'
  imgData: string;
  img: any;
  photos: Array<any> = []
  signatures: any = null
  signaturesSubscription: Subscription;
  photoList: boolean = false;
  signatureList: boolean = false;
  dateOnFront = new Date();
  state: boolean;
  color: string;
  private lastCodeScanned = '';
  private timeMillisToResetScannedCode = 2000;
  lastPopoverType: string;
  defectStatus: any;
  defectTypeP: any;
  defectTypeC: any;
  defectZoneP: any;
  defectZoneC: any;
  isSaving: boolean = false;
  lastCode;

  constructor(
    private fb: FormBuilder,
    private fbContact: FormBuilder,
    private incidentsService: IncidentsService,
    private intermediary: IntermediaryService,
    private modalController: ModalController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private plt: Platform,
    private camera: Camera,
    private transfer: FileTransfer,
    private uploadService: UploadFilesService,
    private productsService: ProductsService,
    private intermediaryService: IntermediaryService,
    private alertController: AlertController,
    private toolbarProvider: ToolbarProvider,
    private dropService: DropFilesService,
    private itemReferencesProvider: ItemReferencesProvider,
    private printTicketService: PrintTicketService,
    private popoverController: PopoverController,
    private selectScrollbarProvider: SelectScrollbarProvider
  ) {


  }

  ngOnInit() {

    this.signatures = null;
    this.toolbarProvider.currentPage.next("Registro defectuoso");
    this.toolbarProvider.optionsActions.next([]);
    this.photos = [];

    this.signaturesSubscription = this.uploadService.signatureEventAsign().subscribe(resp => {
      if (resp) {
        this.intermediary.presentLoading()
        if (this.signatures) {

          this.uploadService.deleteFile(this.signatures.id).subscribe(
            resp => {
              this.intermediary.presentToastSuccess('Imagen anterior eliminada exitosamente')
            },
            err => {
              this.intermediary.dismissLoading()
            },
            () => {
              this.intermediary.dismissLoading()
            }
          )
        }
      }
      this.signatures = resp

      if (this.signatures) {
        this.signatures.pathMedium = `${environment.apiBasePhoto}${this.signatures.pathMedium}`
        this.signatures.pathIcon = `${environment.apiBasePhoto}${this.signatures.pathIcon}`
      }
      console.log(this.signatures);

      if (!this.signatureList) {
        this.openSignatureList()
      }
    })
    this.initForm();
    this.readed = false;
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state != undefined) {
      this.readed = true;
      this.barcodeRoute = navigation.extras.state['reference'];
    }
    this.initDinamicFields();
    if(this.activatedRoute.snapshot.paramMap.get('camera')){
      this.scanNewCode();
    }
  }

  ngOnDestroy() {


    console.log('OnDestroy');
    console.log(this.photos);
    console.log(this.signatures);

    // if (this.signatures) {
    //   this.uploadService.deleteFile(this.signatures.id).subscribe(resp => {
    //     this.signatures = null
    //     this.uploadService.setSignature(null)
    //   })
    // }

    // if (this.photos.length > 0) {
    //   this.photos.forEach(elem => {
    //     this.uploadService.deleteFile(elem.id).subscribe(resp => { })
    //   })
    // }
    //this.signatures = null
    //this.uploadService.setSignature(null)
    //this.photos = []
    this.clearVariables();
    this.signaturesSubscription.unsubscribe();
  }

  defectType(defecType) {
    this.allDefectType = defecType ? defecType.classifications : [];
  }

  initForm() {
    this.date = moment().format("YYYY-MM-DD");
    let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    this.incidenceForm = this.fb.group({
      productId: 1,
      productReference: '',
      dateDetection: [moment().format("YYYY-MM-DD")],
      observations: '',
      numberObservations: 1,
      factoryReturn: [false],
      statusManagementDefectId: [0],
      defectTypeChildId: [0],
      defectTypeParentId: [0],
      defectZoneChildId: [0],
      defectZoneParentId: [0],
      signFileId: [0],
      gestionState: 0,
      contact: this.fb.group({
        name: '',
        info: ''
      })

    })
    this.clearVariables();
  }

  async initDinamicFields() {
    this.incidentsService.getDefectTypesChild().subscribe(resp => {
      this.defectsSubtypes = resp;
    })
    this.incidentsService.getDefectTypesParent().subscribe(resp => {
      this.defects = resp;
    })
    this.incidentsService.getDefectZonesChild().subscribe(resp => {
      this.zonesChilds = resp;
    })
    this.incidentsService.getDefectZonesParent().subscribe(resp => {
      this.zones = resp;
    })
    this.incidentsService.getStatusManagamentDefectAl().subscribe(resp => {
      this.statusManagament = resp
      this.types = resp.statuses;
      this.defectType(resp);
    })
    this.loadFromDBValues();
  }

  async loadFromDBValues() {
    console.log("Date now", this.dateNow)
    if (this.barcodeRoute) {
      let body = {
        "productId": this.barcodeRoute,
        "productReference": ""
      }
      await this.incidentsService.getOneIncidentProductById(body).subscribe(resp => {

        resp = resp.data;
        // console.log('result', resp);
        // console.log('contact', contact);

        this.statusManagament = {
          'classifications': resp.statusManagementDefect
        }

        // console.log("resp status ", resp.statusManagementDefect);

        this.statusManagament["classifications"] = resp.statusManagementDefect;

        this.varTrying = resp.statusManagementDefect.id;


        // this.incidenceForm.patchValue({ gestionChange: resp.statusManagementDefect.id })
        this.incidenceForm.patchValue({
          id: resp.id,
          productId: resp.product.id,
          productReference: resp.product.reference,
          barcode: resp.product.reference,
          registerDate: Date.now(),
          observations: resp.observations,
          gestionState: resp.statusManagementDefect.id,
          photo: resp.photo,
          validation: resp.validation,
          isHistory: resp.isHistory,
          statusManagementDefectId: resp.statusManagementDefect.id,
          defectTypeChildId: resp.defectTypeChild.id,
          defectTypeParentId: resp.defectTypeParent.id,
          defectZoneChildId: resp.defectZoneChild.id,
          defectZoneParentId: resp.defectZoneParent.id,
          dateDetection: moment().format(),
          // photosFileIds: [ [{ "id": 1 }]],
          // signFileId: [1],
          // contact: this.fb.group({
          //   name: contact.name,
          //   email: contact.email,
          //   phone: contact.phone
          // })
        });
        this.typeIdBC = resp.statusManagementDefect.id;
        this.onChange(resp.defectTypeChild.id);

        let sendtoGestionChange = {
          "detail": {
            "value": resp.statusManagementDefect.id
          }
        }
        this.gestionChange(sendtoGestionChange);
      }, error => {
        console.log("here is error ", error);
      });

    }

  }

  newValue(e) {
    if (this.itemReferencesProvider.checkCodeValue(e) === this.itemReferencesProvider.codeValue.PRODUCT) {
      this.barcode = e;
      if (this.barcode && this.barcode.length > 0) {
        this.incidenceForm.patchValue({
          productReference: this.barcode
        })
        this.getSizeListByReference(e, null, null);
      }
    } else {
      this.intermediary.presentToastError(`Código de producto [${e}] inválido`, PositionsToast.BOTTOM);
    }

  }

  scanNewCode() {
    this.lastCodeScanned = '';
    ScanditMatrixSimple.init((response) => {
      if (response && response.result && response.actionIonic) {
        this.executeAction(response.actionIonic, response.params);
      } else if (response && response.barcode && response.barcode.data) {
        if (response.barcode != this.lastCodeScanned) {
          this.lastCodeScanned = response.barcode;
          ScanditMatrixSimple.setTimeout("lastCodeScannedStart", this.timeMillisToResetScannedCode, "");
          let code = response.barcode.data;
          if (this.itemReferencesProvider.checkCodeValue(code) === this.itemReferencesProvider.codeValue.PRODUCT) {
            this.lastCode = code;
            this.incidenceForm.patchValue({
              productReference: code
            });
            ScanditMatrixSimple.request("POST", environment.apiBase + '/defects/registry/verifyProduct', { reference: code }, localStorage.getItem("access_token"), "getSizeListByReference");

          } else {
            ScanditMatrixSimple.setText(`Código de producto [${code}] inválido`, BACKGROUND_COLOR_ERROR, TEXT_COLOR, 16);
            ScanditMatrixSimple.showText(true);
            this.hideTextMessage(4000)
          }
        }

      } else if (response.action === 'request') {
        let responseData = null;

        if (response.data) {
          responseData = JSON.parse(response.data);
        }

        switch (response.requestType) {
          case "getSizeListByReference":
            let error;
            if (responseData.data !== undefined) {
              ScanditMatrixSimple.setText('El producto solicitado ya se encuentra registrado', BACKGROUND_COLOR_ERROR, TEXT_COLOR, 16);
              ScanditMatrixSimple.showText(true);
              this.hideTextMessage(4000);
            } else {
              ScanditMatrixSimple.request("GET", environment.apiBase + '/products/info/' + this.lastCode, {}, localStorage.getItem("access_token"), "getInfo");
            }
            break;

          case "getInfo":
            if (response.code === 200) {
              this.readed = true;
              ScanditMatrixSimple.finish();
              setTimeout(() => {
                if (document.getElementById('input-ta')) {
                  document.getElementById('input-ta').focus();
                }
              }, 100);
            } else if (response.code === 0) {
              ScanditMatrixSimple.setText('Ha ocurrido un problema al intentar conectarse con el servidor. Revise su conexión y pruebe de nuevo a realizar la operación.', BACKGROUND_COLOR_ERROR, TEXT_COLOR, 16);
              ScanditMatrixSimple.showText(true);
              this.hideTextMessage(4000);
            } else {
              ScanditMatrixSimple.setText('No se ha podido consultar la información del producto escaneado.', BACKGROUND_COLOR_ERROR, TEXT_COLOR, 16);
              ScanditMatrixSimple.showText(true);
              this.hideTextMessage(4000);
            }
            break;
        }
      }
    }, 'Registrar defectuoso', HEADER_BACKGROUND, HEADER_COLOR);
  }

  print(defective, status) {
    this.printTicketService.printTicket(defective);
  }

  validate() {

    let regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    let validation = true;
    let msg;
    let This = this;
    console.log(this.txtName.length);

    if (this.txtName.length < 4) {
      console.log("name false");
      msg = "Nombre debe tener minimo 4 digítos...";
      validation = false;
    }
    /*    if (!regex.test(this.txtEmail)) {
          console.log("email validation true");
          msg = "Email invalido...";
          validation = false;
          console.log("email false");
        }*/

    if (msg == undefined) {

    } else {
      if (msg.length > 0) {
        This.intermediary.presentToastError(msg);
      }
    }

    return validation;
  }

  onKeyName(event) {
    this.txtName = event.target.value;
  }

  onKeyInfo(event) {
    this.txtInfo = event.target.value;
  }


  send() {
    if (this.requirePhoto) {
      if (this.photos.length > 0) {
        let photos = []
        this.photos.forEach(elem => {
          photos.push({id: elem.id});
        });
        this.incidenceForm.addControl('photosFileIds', new FormControl(photos))
      } else {

        this.intermediary.presentToastError("Debe Agregar Algunas Fotos");
        return;
      }

    }

    if (this.requireOk) {
      console.log('signFileId');
      if (this.signatures) {
        this.incidenceForm.patchValue({
          signFileId: this.signatures.id,
        });
      } else {
        this.intermediary.presentToastError("Debe Agregar la Firma");
        return;
      }

    }


    this.incidenceForm.patchValue({
      statusManagementDefectId: this.managementId,
      defectTypeParentId: this.defectParentId,
      defectTypeChildId: this.defectChildId,
      defectZoneChildId: this.defectZoneChildId,
      defectZoneParentId: this.defectZoneParentId,
    })
    if (this.requireContact == true) {
      if (this.validate()) {
        this.incidenceForm.value.contact.info = this.txtInfo + "";
        let object = this.incidenceForm.value;
        this.sendToIncidents(object);
      }
    } else {
      let object = this.incidenceForm.value;
      delete object.contact;
      this.sendToIncidents(object);
    }
  }

  isFormComplete() {
    if (!this.defectParentId || !this.defectZoneParentId) {
      return false;
    }
    if (this.requirePhoto && this.photos.length == 0) {
      return false;
    }
    if (this.requireOk && !this.signatures) {
      return false;
    }
    if (this.requireContact && this.txtName.length < 4 && this.txtInfo.length < 1) {
      return false;
    }
    return true;
  }

  async enviaryarn() {
    let photos = []
    this.photos.forEach(elem => {
      photos.push({id: elem.id});
    });
    this.incidenceForm.patchValue({
      statusManagementDefectId: this.managementId,
      defectTypeParentId: this.defectParentId,
      defectTypeChildId: this.defectChildId ? this.defectChildId : 0,
      defectZoneChildId: this.defectZoneChildId,
      defectZoneParentId: this.defectZoneParentId,
      photosFileIds: photos,
      signFileId: this.signatures.id,
      // contact:{
      //   name: this.txtName,
      //   email: this.txtEmail,
      //   phone: this.txtTel,
      // },
    });
    //let defective = ;
    // console.log("hello world", this.incidenceForm);
    let This = this;
    await This.intermediary.presentLoading('Enviando...')
    if (this.ticketEmit == true) {
      //this.printTicketService.printTicket(this.incidenceForm, this.incidenceForm.statuManagementDefectId);
    }

    if (this.incidenceForm.value.observations == null) {
      this.incidenceForm.patchValue({
        observations: "None",
      })
    }

    if (this.incidenceForm.value.observations != null) {
      this.incidenceForm.patchValue({
        contact: {
          phone: this.incidenceForm.value.contact.phone + ""
        },
      })
    }

    let object = this.incidenceForm.value;
    if (!this.requireContact) {
      delete object.contact;
    }
    // setTimeout(async () => {
    //   await this.intermediary.dismissLoading()
    // }, 3000)
    This.incidentsService.addRegistry(object).subscribe(
      resp => {
        this.readed = false
        this.incidenceForm.patchValue({
          // productId: 1,
          productReference: '',
          dateDetection: this.dateNow,
          observations: '',
          factoryReturn: false,
          statusManagementDefectId: 0,
          defectTypeChildId: 0,
          defectTypeParentId: 0,
          defectZoneChildId: 0,
          defectZoneParentId: 0,
          gestionState: 0,
          photosFileIds: 0,
          signFileId: 0,
          contact: {
            name: this.txtName,
            info: this.txtInfo,
          }
        })
        This.intermediary.dismissLoading()
        This.intermediary.presentToastSuccess('El defecto fue enviado exitosamente')
        this.router.navigateByUrl('/defect-handler');
      },
      e => {
        // console.log(e);
        This.intermediary.dismissLoading()
        This.intermediary.presentToastError(e.error)
        // console.log("e,", e);
      }
    );

  }


  async sendToIncidents(object) {
    await this.intermediary.presentLoadingNew('Enviando...');
    this.isSaving = true;
    this.incidenceForm.disable();

    let This = this;
    This.incidentsService
      .addRegistry(object)
      .subscribe(resp => {
        if (this.ticketEmit == true) {
          this.printTicketService.printTicket(resp.result);
        }
        this.readed = false;
        this.clearVariables();

        This.intermediary.dismissLoadingNew();
        this.isSaving = false;
        this.incidenceForm.enable();

        This.intermediary.presentToastSuccess('El defecto fue enviado exitosamente');
        this.router.navigateByUrl('/defect-handler');
      }, e => {
        This.intermediary.dismissLoadingNew();
        this.isSaving = false;
        this.incidenceForm.enable();

        if (e.status === 503) {
          This.intermediary.presentToastError("Revise su conexión");
        } else {
          This.intermediary.presentToastError(e.error.errors);
        }
      });
  }

  gestionChange(e) {
    let id = e.id;
    let res;
    if (this.barcodeRoute == null || this.barcodeRoute == undefined) {
      res = this.statusManagament['classifications'].find(x => x.id == id);

    } else {
      res = this.statusManagament.classifications != undefined ? this.statusManagament.classifications : this.statusManagament['classifications'].find(x => x.id == id);

    }

    if (res != undefined) {
      // console.log("res", res);

      if (res instanceof Array) {
        res = res.find(x => x.id == id);
      }
      console.log(res.requirePhoto);
      this.ticketEmit = res.ticketEmit;
      this.passHistory = res.passHistory;
      this.requirePhoto = res.requirePhoto;
      this.requireContact = res.requireContact;
      this.requireOk = res.requireOk;
      this.managementId = res.id;

    } else {
      this.ticketEmit = false;
      this.passHistory = false;
      this.requirePhoto = false;
      this.requireContact = false;
      this.requireOk = false;
    }

    this.select1 = true;


  }

  defectChange(e) {
    this.select2 = true;
    this.defectParentId = e && e.id > 0 ? e.id : 0;
    this.defectChildsOfParent = e && e.defectTypeChild ? e.defectTypeChild : [];
    this.defectChildId = 0;
  }

  defectParentChange(e) {
    this.select2 = true;
    this.defectChildId = e && e.id ? e.id : 0;
  }

  defectZoneChange(e) {
    this.select2 = true;
    this.defectZoneParentId = e && e.id ? e.id : 0;
    this.defectZonesChildsOfParent = e && e.defectZoneChild ? e.defectZoneChild : [];
    this.defectZoneChildId = 0;
  }

  defectZoneChangeParent(e) {
    this.select2 = true;
    this.defectZoneChildId = e && e.id ? e.id : 0;
  }

  ngAfterViewInit() {
    // console.log(this.signaturePad);
    //   this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    //   this.signaturePad.set('canvasWidth', this.plt.width())
    //   this.signaturePad.set('canvasHeight', 300)
    //   this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  ngOnChanges() {
  }


  async signModal() {
    const modal = await this.modalController.create({
      component: SignatureComponent,
    });
    await modal.present();

  }


  takePhoto() {
    const options: CameraOptions = {
      quality: 15,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
      correctOrientation: true
    }
    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      console.log(imageData);
      this.imgData = imageData
      this.uploadImage()

    }, (err) => {
      // Handle error
    });
  }

  async searchPhoto() {
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      correctOrientation: true
    }
    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      console.log(imageData);
      this.imgData = imageData
      this.uploadImage()

    }, (err) => {
      // Handle error
    });
  }

  async uploadImage() {
    if (!this.imgData || this.imgData.length == 0) {
      this.intermediaryService.presentToastError('Debe seleccionar una imagen o tomar una foto')
      return
    }
    this.intermediaryService.presentLoading()
    // Destination URL
    let url = this.apiURL;
    // File for Upload
    var targetPath = this.imgData;
    const imgDataSplit = this.imgData.split('/')
    let name = imgDataSplit[imgDataSplit.length - 1]
    if (name.split('?').length > 1) {
      name = name.split('?')[0]
    }
    var options: FileUploadOptions = {
      fileKey: 'file',
      chunkedMode: false,
      mimeType: 'image/png',
      fileName: name
      // params: { 'desc': desc }
    };
    const fileTransfer: FileTransferObject = this.transfer.create();
    // Use the FileTransfer to upload the image
    fileTransfer.upload(targetPath, url, options)
      .then((result: FileUploadResult) => {
        this.intermediaryService.dismissLoading()
        const response: any = JSON.parse(result.response)
        console.log('response: ', response);
        console.log(response.data);

        response.data.pathMedium = `${environment.apiBasePhoto}${response.data.pathMedium}`
        response.data.pathIcon = `${environment.apiBasePhoto}${response.data.pathIcon}`
        this.img = response.data;
        this.photos.push(this.img);
        console.log('subido');
        if (!this.photoList) {
          this.openPhotoList();
        }
        this.intermediaryService.presentToastSuccess('la imagen cargada correctamente')
      })
      .catch(
        e => {
          console.log(e);

          this.intermediaryService.dismissLoading()
          const error = JSON.parse(e.body)

          this.intermediaryService.presentToastError(error.errors)
        }
      );

  }

  openPhotoList() {
    this.photoList = !this.photoList
  }

  openSignatureList() {
    this.signatureList = !this.signatureList;
  }

  deleteImage(item, index, arr) {
    this.intermediary.presentLoading()
    this.uploadService.deleteFile(item.id).subscribe(
      resp => {
        this.intermediary.presentToastSuccess('Archivo borrado exitosamente')
        arr.splice(index, 1);
      },
      err => {
        this.intermediary.presentToastError('Ocurrio un error al borrar el archivo')
        this.intermediary.dismissLoading()
      },
      () => {
        this.intermediary.dismissLoading()
      }
    )
  }

  deleteSignature(item) {
    this.intermediary.presentLoading()
    this.uploadService.deleteFile(item.id).subscribe(
      resp => {
        this.intermediary.presentToastSuccess('Archivo borrado exitosamente')
        this.clearVariables(1);
        this.uploadService.setSignature(null)
      },
      err => {
        this.intermediary.presentToastError('Ocurrio un error al borrar el archivo')
        this.intermediary.dismissLoading()
      },
      () => {
        this.intermediary.dismissLoading()
      }
    )
  }

  async onOpenReviewModal(item) {
    const modal = await this.modalController.create({
      component: ReviewImagesComponent,
      componentProps: {imgSrc: item.pathMedium}
    });

    await modal.present();

  }


  onChange(value) {
    if (value != this.typeIdBC) {
      this.differentState = false;
    } else {
      this.differentState = true;
    }

  }


  private getSizeListByReference(dataWrote: string, successCallback: () => any, errorCallback: (msg) => any) {
    const body = {
      reference: dataWrote
    };
    this.productsService.verifyProduct(body).then(async (res) => {
      if (res.data !== undefined) {
        if (errorCallback) {
          errorCallback('El producto solicitado ya se encuentra registrado');
        } else {
          this.intermediaryService.presentToastError('El producto solicitado ya se encuentra registrado', PositionsToast.BOTTOM).then(() => {
            this.readed = false
          });
        }
      } else {
        this.productsService.getInfo(dataWrote).then(async (res: ProductModel.ResponseInfo) => {
          if (res.code === 200) {
            this.readed = true;
            if (successCallback) {
              successCallback();
            }
          } else if (res.code === 0) {
            if (errorCallback) {
              errorCallback('Ha ocurrido un problema al intentar conectarse con el servidor. Revise su conexión y pruebe de nuevo a realizar la operación.');
            } else {
              this.intermediaryService.presentToastError('Ha ocurrido un problema al intentar conectarse con el servidor. Revise su conexión y pruebe de nuevo a realizar la operación.', PositionsToast.BOTTOM).then(() => {
                this.readed = false
              });
            }
          } else {
            if (errorCallback) {
              errorCallback('No se ha podido consultar la información del producto escaneado.');
            } else {
              this.intermediaryService.presentToastError('No se ha podido consultar la información del producto escaneado.', PositionsToast.BOTTOM).then(() => {
                this.readed = false
              });
            }
          }
        }, (error) => {
          console.error('Error::Subscribe::GetInfo -> ', error);
          if (errorCallback) {
            errorCallback('No se ha podido consultar la información del producto escaneado.');
          } else {
            this.intermediaryService.presentToastError('No se ha podido consultar la información del producto escaneado.', PositionsToast.BOTTOM).then(() => {
              this.readed = false
            });
          }
        })
          .catch((error) => {
            console.error('Error::Subscribe::GetInfo -> ', error);
            if (errorCallback) {
              errorCallback('No se ha podido consultar la información del producto escaneado.');
            } else {
              this.intermediaryService.presentToastError('No se ha podido consultar la información del producto escaneado.', PositionsToast.BOTTOM).then(() => {
                this.readed = false
              });
            }
          });
      }
    }, error => {
      const msg = error && error.error && error.error.errors ? error.error.errors : `Ha ocurrido un error al consultar la información del producto [${dataWrote}]`
      console.error('Error::Subscribe::GetInfo -> ', error);
      if (errorCallback) {
        errorCallback(msg);
      } else {
        this.intermediaryService.presentToastError(msg, PositionsToast.BOTTOM).then(() => {
          this.readed = false
        });
      }
    });

  }


  private async presentAlertInput(modelId: number, sizeId: number) {
    const alert = await this.alertController.create({
      header: 'Ubicación del producto',
      message: 'Introduce la referencia de la ubicación o el embalaje en que está el producto. Si no está en ninguno de estos sitios continúa sin rellenar este campo.',
      inputs: [{
        name: 'reference',
        type: 'text',
        placeholder: 'Referencia '
      }],
      backdropDismiss: false,
      buttons: [
        {
          text: 'Continuar',
          handler: (data) => {
            let reference = data.reference.trim();

          }
        }
      ]
    });

    await alert.present();
  }


  clearVariables(type?: number) {
    if (!type) {
      this.incidenceForm.patchValue({
        productReference: '',
        dateDetection: moment().format("YYYY-MM-DD"),
        observations: '',
        factoryReturn: false,
        statusManagementDefectId: 0,
        defectTypeChildId: 0,
        defectTypeParentId: 0,
        defectZoneChildId: 0,
        defectZoneParentId: 0,
        photosFileIds: [],
        signFileId: 0,
        contact: {
          name: '',
          info: ''
        }
      });
      this.signatures = null;
      this.uploadService.setSignature(null);
      this.photos = [];
      this.photoList = false
      this.signatureList = false;
      this.ticketEmit = false;
      this.passHistory = false;
      this.requirePhoto = false;
      this.requireContact = false;
      this.requireOk = false;
    } else if (type == 1) {
      this.uploadService.setSignature(null);
      this.signatures = null;
      this.signatureList = false;
    } else if (type == 2) {
      this.photos = [];
      this.photoList = false
      this.requirePhoto = false;
    }
  }

  onActiveKeyboard() {

  }


  private hideTextMessage(delay: number) {
    ScanditMatrixSimple.setTimeout("hideText", delay, "");
  }

  private executeAction(action: string, paramsString: string) {
    let params = [];
    try {
      params = JSON.parse(paramsString);
    } catch (e) {

    }

    switch (action) {
      case 'lastCodeScannedStart':
        this.lastCodeScanned = '';
        break;
      case 'hideText':
        ScanditMatrixSimple.showText(false);
        break;
    }
  }

  clickSelectPopover(ev: any, popoverType: string) {
    ev.stopPropagation();
    ev.preventDefault();
    this.lastPopoverType = popoverType;

    switch (popoverType) {
      case 'status':
        this.openSelectPopover(ev, null, this.allDefectType);
        break;
      case 'typeP':
        this.openSelectPopover(ev, null, this.defects);
        break;
      case 'typeC':
        this.openSelectPopover(ev, null, this.defectChildsOfParent);
        break;
      case 'zoneP':
        this.openSelectPopover(ev, null, this.zones);
        break;
      case 'zoneC':
        this.openSelectPopover(ev, null, this.defectZonesChildsOfParent);
        break;
    }

  }

  public async openSelectPopover(ev: any, typedValue, allOptions) {
    this.selectScrollbarProvider.allOptions = allOptions;
    this.allOptions = allOptions;

    const popover = await this.popoverController.create({
      cssClass: 'select-scrollbar',
      component: SelectScrollbarComponent,
      componentProps: {typedValue, allOptions}
    });

    popover.onDidDismiss().then((data) => {
      switch (this.lastPopoverType) {
        case 'status':
          this.selectChangeStatus(data);
          break;
        case 'typeP':
          this.selectChangeTypeP(data);
          break;
        case 'typeC':
          this.selectChangeTypeC(data);
          break;
        case 'zoneP':
          this.selectChangeZoneP(data);
          break;
        case 'zoneC':
          this.selectChangeZoneC(data);
          break;
      }
    });
    await popover.present();
  }

  selectChangeStatus(defectStatus) {
    this.defectStatus = defectStatus.data;
    this.gestionChange(this.defectStatus);
  }

  selectChangeTypeP(defectTypeP) {
    this.defectTypeP = defectTypeP.data;
    this.defectChange(this.defectTypeP);
  }

  selectChangeTypeC(defectTypeC) {
    this.defectTypeC = defectTypeC.data;
    this.defectParentChange(this.defectTypeC);
  }

  selectChangeZoneP(defectZoneP) {
    this.defectZoneP = defectZoneP.data;
    this.defectZoneChange(this.defectZoneP);
  }

  selectChangeZoneC(defectZoneC) {
    this.defectZoneC = defectZoneC.data;
    this.defectZoneChangeParent(this.defectZoneC);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiringDhlComponent } from './hiring-dhl.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {RouterModule, Routes} from "@angular/router";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {FabExtendedModule} from "../components/button/fab/fab-extended/fab-extended.module";
import {PaginatorComponentModule} from "../components/paginator/paginator.component.module";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatTableModule} from "@angular/material/table";
import { UpdateHiringDhlComponent } from './update-hiring-dhl/update-hiring-dhl.component';
import { FilterButtonModule } from '../components/filter-button/filter-button.module';

const routes: Routes = [
  {
    path: '',
    component: HiringDhlComponent
  }
];

@NgModule({
  declarations: [HiringDhlComponent, UpdateHiringDhlComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FabExtendedModule,
    PaginatorComponentModule,
    FilterButtonModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatTableModule,
  ],
  entryComponents: [
    UpdateHiringDhlComponent
  ]
})
export class HiringDhlModule { }

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams} from "@ionic/angular";
import {AvelonIncidencesReception} from "../../../../../services/src/models/endpoints/AvelonIncidencesReception";

@Component({
  selector: 'suite-in-scan-incidences-show-json',
  templateUrl: './avelon-incidences-reception-show-json.component.html',
  styleUrls: ['./avelon-incidences-reception-show-json.component.scss']
})

export class AvelonIncidencesReceptionShowJsonComponent implements OnInit {

  incidence: AvelonIncidencesReception;
  errors: any[];

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
  ) {
    this.errors = [];
  }

  ngOnInit() {
    this.incidence = this.navParams.get("incidence");
    let result = this.incidence.response.indexOf("{");
    let json = this.incidence.response.substring(result);
    this.incidence.retries.forEach(retry => {
      this.errors.push({response: JSON.stringify(JSON.parse(retry.error), null, 4), createdAt: retry.createdAt});
    });
    this.errors.push({response: JSON.stringify(JSON.parse(json), null, 4), createdAt: this.incidence.createdAt});
  }

  close() {
    this.modalController.dismiss();
  }

  downloadRequestJSON(error) {
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(error.response);
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", `${this.incidence.id}-${error.createdAt}.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InScansIncidencesComponent } from './in-scans-incidences.component';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MatCheckboxModule, MatTableModule, MatTooltipModule } from '@angular/material';
import { FilterButtonModule } from '../components/filter-button/filter-button.module';
import { PaginatorComponentModule } from '../components/paginator/paginator.component.module';
import { InScansIncidencesShowJsonModule } from './modals/in-scans-incidences-show-json/in-scans-incidences-show-json.module';

const route: Routes = [{
  path: '',
  component: InScansIncidencesComponent
}]

@NgModule({
  declarations: [InScansIncidencesComponent],
  imports: [
    CommonModule,
    IonicModule,
    MatTableModule,
    FilterButtonModule,
    PaginatorComponentModule,
    MatCheckboxModule,
    RouterModule.forChild(route),
    InScansIncidencesShowJsonModule,
    MatTooltipModule
  ],
  entryComponents: [InScansIncidencesComponent]
})
export class InScansIncidencesModule { }

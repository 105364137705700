import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MatDatepickerModule, MatDialogModule, MatNativeDateModule, MatTableModule } from '@angular/material';

import { AppComponent } from './app.component';
import { ModalSizes } from 'libs/modules/src/marketplaces/catalog-marketplaces/modal-dialog/modal-dialog.component';
import { AppRoutingModule } from './app-routing.module';

import { IonicStorageModule } from '@ionic/storage';

import { ServicesModule } from '@suite/services';
import { PipesModule } from "../../../../libs/pipes/src";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpInterceptorService } from './interceptor/http-interceptor.service';
import { ErrordialogComponent } from './interceptor/errordialog-component/errordialog.component';
import {IncidencesButtonModule, ScannerConfigurationModule} from "@suite/common-modules";
import { AddTokenToRequestInterceptor } from '@suite/services';
import { BreadcrumbModule,MenuModule} from '@suite/common-modules';

import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import localeEs from '@angular/common/locales/es';
import {registerLocaleData} from "@angular/common";
import {MondayStartingDateAdapterService} from "../../../../libs/services/src/lib/monday-starting-date-adapter/monday-starting-date-adapter.service";
import { NgxMaskModule } from 'ngx-mask';
import { VirtualKeyboardComponent } from '../../../../libs/modules/src/components/virtual-keyboard/virtual-keyboard.component';
import { VirtualKeyboardModule } from '../../../../libs/modules/src/components/virtual-keyboard/virtual-keyboard.module';
import { DropFilesModule } from '../../../../libs/modules/src/drop-files/drop-files.module';
import { ModalReviewModule } from '../../../../libs/modules/src/components/modal-defective/ModalReview/modal-review.module';

import {SignatureModule} from '../../../../libs/modules/src/signature/signature.module';
import { ReviewImagesModule } from '../../../../libs/modules/src/incidents/components/review-images/review-images.module';
import { RegistryDetailsModule } from 'libs/modules/src/components/modal-defective/registry-details/registry-details.module';
import { WebsocketService } from '../../../../libs/services/src/lib/endpoint/web-socket/websocket.service';
import {AfterPackingReceptionComponent} from "../../../../libs/modules/src/components/after-packing-reception/after-packing-reception.component";

import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';

registerLocaleData(localeEs);
@NgModule({
  declarations: [AppComponent, ErrordialogComponent, AfterPackingReceptionComponent, ModalSizes],
  entryComponents: [ErrordialogComponent, VirtualKeyboardComponent, AfterPackingReceptionComponent, ModalSizes],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    ServicesModule,
    PipesModule,
    BrowserAnimationsModule,
    MatDialogModule,
    ScannerConfigurationModule,
    IncidencesButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BreadcrumbModule,
    MenuModule,
    NgxMaskModule.forRoot(),
    VirtualKeyboardModule,
    DropFilesModule,
    ModalReviewModule,
    SignatureModule,
    ReviewImagesModule, 
    RegistryDetailsModule,
    MatTableModule,
    MatCardModule,
    MatIconModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    MatDatepickerModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: DateAdapter, useClass: MondayStartingDateAdapterService },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddTokenToRequestInterceptor,
      multi: true
    },
    WebsocketService
   /* {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, AfterViewInit, Input,Output,EventEmitter, SimpleChange } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ModalController, NavController } from '@ionic/angular';
import {environment, IntermediaryService, OplTransportsService} from '../../../services/src';
import { SelectionModel } from '@angular/cdk/collections';
import { TagsInputOption } from '../components/tags-input/models/tags-input-option.model';
import { DefectiveRegistryService } from '../../../services/src/lib/endpoint/defective-registry/defective-registry.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { FiltersModel } from '../../../services/src/models/endpoints/filters';
import { ExpeditionCollectedService } from '../../../services/src/lib/endpoint/expedition-collected/expedition-collected.service';
import { ActivatedRoute } from '@angular/router';
import {DateTimeParserService} from "../../../services/src/lib/date-time-parser/date-time-parser.service";
import {ToolbarProvider} from "../../../services/src/providers/toolbar/toolbar.provider";
import {PackagesComponent} from "../transport-package-collected/packages/packages.component";
import {validators} from "../utils/validators";
import {PrinterServiceModel} from "../../../services/src/models/endpoints/PrinterService";
import {PositionsToast} from "../../../services/src/models/positionsToast.type";
import {TimesToastType} from "../../../services/src/models/timesToastType";
import {DownloaderService} from "../../../services/src/lib/downloader/downloader.service";
import {PrinterServiceService} from "../../../services/src/lib/endpoint/printer-service/printer-service.service";
import {ExpeditionManifestsModel} from "../../../services/src/models/endpoints/Expeditions/Manifests";
import {Downloader, DownloadRequest, NotificationVisibility} from "@ionic-native/downloader/ngx";
import {config} from "../../../services/src/config/config";
import { app } from '../../../services/src/environments/environment';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ExpeditionsProcessedModel} from "../../../services/src/models/endpoints/Expeditions/Processed";
import {PackageCancelledModel} from "../../../services/src/models/endpoints/Expeditions/PackageCancelled";
import IPackageCancelledInfo = PackageCancelledModel.IPackageCancelledInfo;
import ResponseDataPackageCancelledInfo = PackageCancelledModel.ResponseDataPackageCancelledInfo;
import {PackagesWarningCancelledAlComponent} from "./packages-warning-cancelled-al/packages-warning-cancelled-al.component";

@Component({
  selector: 'suite-transport-package-collected',
  templateUrl: './transport-package-collected.component.html',
  styleUrls: ['./transport-package-collected.component.scss'],
  animations: [
    trigger('disappear', [
      state('flyIn', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 1 }),
        animate('0.5s ease-in-out')
      ]),
      transition(':leave', [
        animate('0.5s ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class TransportPackageCollectedComponent implements OnInit, AfterViewInit {

  constructor(
    private defectiveRegistryService: DefectiveRegistryService,
    private formBuilder: FormBuilder,
    private intermediaryService: IntermediaryService,
    private modalController: ModalController,
    private intermediary: IntermediaryService,
    private expeditionCollectedService: ExpeditionCollectedService,
    private navCtrl: NavController,
    private activateRoute: ActivatedRoute,
    private oplTransportsService: OplTransportsService,
    private dateTimeParserService: DateTimeParserService,
    private toolbarProvider: ToolbarProvider,
    private downloaderService: DownloaderService,
    private printerServiceService: PrinterServiceService,
    private downloader: Downloader
  ) {}

  @Input() id: any;
  @Input() sendEvent:boolean;
  @Output()buttonState = new EventEmitter<boolean>();

  /**Filters */
  uniquecodes: Array<TagsInputOption> = [];
  containers: Array<TagsInputOption> =[];
  shops: Array<TagsInputOption> = [];
  orders: Array<TagsInputOption> = [];
  dates: Array<TagsInputOption> = [];
  groups:Array<TagsInputOption> = [];

  displayedColumns: string[] = ['select', 'orders', 'uniquecodes', 'containers', 'dates', 'products'];
  dataSource;
  packages;
  button:boolean = false;
  allSelected:boolean = false;
  selection = new SelectionModel<any>(true, []);
  columns=['uniquecodes','shops', 'containers','orders','dates'];
  public showFiltersMobileVersion: boolean = false;

  toDelete: FormGroup = this.formBuilder.group({
    jails: this.formBuilder.array([])
  });

  form: FormGroup = this.formBuilder.group({
    id: [[]],
    uniquecodes:[[]],
    containers:[[]],
    shops:[[]],
    orders:[[]],
    dates:[[]],
    groups:[[]],
    idTransport: new FormControl(''),
    pagination: this.formBuilder.group({
      page: 1,
      limit: 500
    }),
    orderby: this.formBuilder.group({
      type: 5,
      order: "desc"
    }),
    isAl: true,
  });
  length: any;

  selectedForm: FormGroup = this.formBuilder.group({
    selector: false
  },{
    validators: validators.haveItems("toSelect")
  });

  public existsWarning: boolean = false;
  public showWarning: boolean = true;
  public warningMessageText: string = null;

  ngOnInit() {
    let transportId = parseInt(this.activateRoute.snapshot.paramMap.get('id'));
    this.id = transportId;
    this.initForm();
    this.getFilters(this.id);
    this.form.value.idTransport = this.id;

    this.checkTransportHasPendingRelabel(this.id);
  }

  async ngAfterViewInit() {
    this.form.value.pagination.limit = 500;
    this.getList(this.form.value);
    this.listenChanges();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }){
    let change: SimpleChange = changes['sendEvent'];
      if(this.stateUpdate() == true){
        let packages = this.selectedForm.value.toSelect
          .map((selected, i) => {
            return selected ? this.packages[i] : false;
          })
          .filter(packages => packages);
        this.update(packages);
    }
  }

  openFiltersMobile() {
    this.showFiltersMobileVersion = !this.showFiltersMobileVersion;
  }

  ionViewWillEnter() {
    this.toolbarProvider.optionsActions.next([
      {
        icon: 'funnel',
        label: 'Filtros',
        action: () => this.showFiltersMobileVersion = !this.showFiltersMobileVersion
      }
    ]);
  }

  private getFormValueCopy() {
    return JSON.parse(JSON.stringify(this.form.value || {}));
  }

  sanitize(object){
    /**mejorable */
    object = JSON.parse(JSON.stringify(object));
    if(!object.orderby.type){
      delete object.orderby.type;
    }else{
      object.orderby.type = parseInt(object.orderby.type);
    }
    if(!object.orderby.order)
      delete object.orderby.order;
    Object.keys(object).forEach(key=>{
      if(object[key] instanceof Array){
        if(object[key][0] instanceof Array){
          object[key] = object[key][0];
        } else {
          for(let i = 0;i<object[key].length;i++) {
            if(object[key][i] === null || object[key][i] === "") {
              object[key].splice(i,1);
            }
          }
        }
      }
      if (object[key] === null || object[key] === "") {
        delete object[key];
      }
    });
    return object;
  }

  applyFilters() {
    this.openFiltersMobile();
    this.form.value.pagination.page = 1;
    this.form.value.pagination.limit = 500;
    this.form.value.idTransport = this.id;
    this.getList(this.sanitize(this.getFormValueCopy()));
  }

  clearFilters() {
    this.form = this.formBuilder.group({
      id: [[]],
      uniquecodes:[[]],
      containers:[[]],
      shops:[[]],
      orders:[[]],
      dates:[[]],
      groups: [[]],
      idTransport: this.id,
      pagination: this.formBuilder.group({
        page: 1,
        limit: 500
      }),
      orderby: this.formBuilder.group({
        type: 5,
        order: "desc"
      }),
      isAl: true,
    });
  }

  initForm() {
    this.form.patchValue({
      id: [],
      uniquecodes:[],
      containers:[],
      shops:[],
      orders:[],
      dates:[],
      isAl: true,
    })
  }

  listenChanges() {
    let previousPageSize = this.form.value.pagination.limit;
  }

  getFilters(id) {
    this.expeditionCollectedService.getFiltersPackage(id, false).subscribe((entities) => {
      this.uniquecodes = this.updateFilterSource(entities.uniquecodes, 'uniquecodes');
      this.containers = this.updateFilterExpedition(entities.containers,'containers');
      this.shops = this.updateFilterSource(entities.shops,'shops');
      this.orders = this.updateFilterSource(entities.orders,'orders');
      this.dates = this.updateFilterSource(entities.dates,'dates');
      this.groups = this.updateFilterSource(entities.groups,'groups');
    },(error)=>{
      console.log(error);
    })
  }

  private updateFilterSource(dataEntity: FiltersModel.Default[], entityName: string) {
    let resultEntity;

    let dataValue = this.form.get(entityName).value;

    resultEntity = dataEntity ? dataEntity.map(entity => {
        if (entity.name == "uniquecode") {
          entity.name = 'Etiqueta de envío';
        } else if (entity.name == "container") {
          entity.name = 'ID Contenedor';
        } else if (entity.name == "order") {
          entity.name = 'ID Pedido';
        } else if (entity.name == "date_preparation") {
          entity.name = 'Fecha preparación';
        }
        entity.id = <number>(<unknown>entity.id);
        entity.name = entity.name;
        entity.value = entity.name;
        entity.checked = true;
        entity.hide = false;
        return entity;
    }) : [];

    resultEntity.splice(2,1);

    if (dataValue && dataValue.length) {
      this.form.get(entityName).patchValue(dataValue, { emitEvent: false });
    }

    return resultEntity;
  }

  private updateFilterExpedition(dataEntity: FiltersModel.Default[], entityName: string) {
    let resultEntity;

    let dataValue = this.form.get(entityName).value;

    resultEntity = dataEntity ? dataEntity.map(entity => {
      entity.id = <number>(<unknown>entity.id);
      entity.name = entity.id+"";
      entity.value = entity.id+"";
      entity.checked = true;
      entity.hide = false;
      return entity;
    }) : [];

    if (dataValue && dataValue.length) {
      this.form.get(entityName).patchValue(dataValue, { emitEvent: false });
    }

    return resultEntity;
  }

  async getList(form) {
    await this.intermediaryService.presentLoadingNew("Cargando paquetes recogidos..");
    await this.expeditionCollectedService.getPackages(form).subscribe(async (resp: any) => {
      await this.intermediaryService.dismissLoadingNew();
      if (resp.results) {
        this.dataSource = new MatTableDataSource<any>(resp.results);
        this.packages = resp.results;
        const paginator = resp.pagination;

        this.initSelectedForm();
      }
    },
      async err => {
        await this.intermediaryService.dismissLoadingNew()
      },
      async () => {
        await this.intermediaryService.dismissLoadingNew()
      })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  selectAll(event): void {
    let value = event.checked;
    this.allSelected = value;
    this.selectedForm['controls'].toSelect['controls'].forEach(control => {
      control.setValue(value);
    });
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  async update(packages) {

    await this.intermediaryService.presentLoadingNew();

    const expeditionPackageIds: number[] = packages.map(s => s.package.id);
    await this.expeditionCollectedService.postCheckOrderNotCancelled({expeditionPackageIds: expeditionPackageIds})
      .then(async response => {
        let responseData: ResponseDataPackageCancelledInfo;
        if(response && response.data) responseData = response.data;
        if (response.code == 200 && responseData && responseData.packages.length > 0 ) {
          await this.intermediaryService.dismissLoadingNew();
          await this.presentModalWarningCancelledOrReturned(responseData.packages, async () => {
            await this.downloadManifest(packages);
          }, async () => {
            await this.refresh()
          });
        } else {
          await this.downloadManifest(packages);
        }
      }, async (error) => {
        console.error(error);
        await this.intermediaryService.dismissLoadingNew();
      })
      .catch(async (error) => {
        console.error(error);
        await this.intermediaryService.dismissLoadingNew();
      });
  }

  async presentModalWarningCancelledOrReturned(packages: IPackageCancelledInfo[], callbackOk: () => void, callbackCancel: () => void) {
    const modal = await this.modalController.create({
      component: PackagesWarningCancelledAlComponent,
      componentProps: {packages, callbackOk, callbackCancel}
    });
    await modal.present();
  }

  async downloadManifest(packages){
    const dataPackages: any = packages.map(obj => {
      return {
        warehouse: obj.warehouse.id,
        expedition: obj.expedition,
        transport: obj.transport.id,
        package: obj.package.id
      };
    });
    await this.printerServiceService.getPrinterServiceAddress();
    this.expeditionCollectedService.updatePackage(dataPackages).subscribe(data => {
        this.selection.clear();
        this.ngOnInit();
        this.intermediaryService.presentToastSuccess('Se han marcado como enviados los paquetes seleccionados.');

        this.oplTransportsService.getManifestInfo(data.order.id).subscribe((manifestDoc: ExpeditionManifestsModel.ResponseDownloadAL) => {
          const bodyPrintBase64: PrinterServiceModel.ParamsPrintBase64 = {
            documentBase64: manifestDoc.document,
            documentType: manifestDoc.type
          };

          this.printManifest(bodyPrintBase64, true, false);

          const urlDownload = environment.downloadPdf + '/' + manifestDoc.externalPath;
          const fileName = this.downloaderService.getExpeditionManifestDownloadedFileName(manifestDoc.extension);
          this.downloadUrl(urlDownload, fileName);

        }, (error) => {
          let errorMessage = 'Ha ocurrido un error al intentar imprimir el documento de manifiesto generado.';
          if (error && error.error && error.error.errors) {
            errorMessage = error.error.errors;
          }
          this.intermediaryService.presentToastError(errorMessage);
        }, () => {
          this.intermediaryService.dismissLoadingNew();
        });

      }, error => {
        let errorMessage = 'Ha ocurrido un error al intentar marcar como enviados los paquetes seleccionados.';
        if (error && error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }

        this.selection.clear();
        this.intermediaryService.presentToastError(errorMessage);
        this.intermediaryService.dismissLoadingNew();
      },
      () => {
        this.refresh();
      });
  }

  printManifest(bodyPrintBase64, firstTime, printError) {
    let printTwice = (app.name === 'al');
    this.printerServiceService.postPrintBase64(bodyPrintBase64).subscribe(res => {
      console.debug('PrinterService::Item printed correctly: ', typeof res != 'string' ? JSON.stringify(res) : res);
      console.debug('PrinterService::Item printed correctly: ', typeof res != 'string' ? JSON.stringify(res) : res);

      if (printTwice && firstTime) {
        this.printManifest(bodyPrintBase64, false, false);
      }

    }, error => {
      if (!printError) {
        console.error('PrinterService::ERROR to print item: ', JSON.stringify(error));
        this.intermediaryService.presentToastError("Ha ocurrido un error al intentar conectar con el servicio de impresión. Compruebe que la dirección configurada en 'Configuración > Servicio de impresión' es la correcta.", PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST_LONG);
      }
      if (printTwice && firstTime) {
        this.printManifest(bodyPrintBase64, false, true);
      }
    });
  }

  refresh() {
    this.getList(this.form.value);
    this.checkTransportHasPendingRelabel(this.id);
  }

  private initSelectedForm() {
    this.allSelected = false;
    this.selectedForm.removeControl("toSelect");
    this.selectedForm.addControl("toSelect", this.formBuilder.array(this.packages.map(element => new FormControl(false))));
  }

  stateUpdate() {
    let packages = this.selectedForm.value.toSelect
      .map((selected, i) => {
        return selected ? this.packages[i] : false;
      })
      .filter(packages => packages);
    if (packages.length > 0) {
      this.buttonState.emit(true);
      return true
    }else{
      this.buttonState.emit(false);
    }
    return false;
  }

  public async send(){
    if(this.stateUpdate() == true){
      let packages = this.selectedForm.value.toSelect
        .map((selected, i) => {
          return selected ? this.packages[i] : false;
        })
        .filter(packages => packages);
      this.update(packages);
      this.initSelectedForm();
    }
  }

  async presentModal(packages) {

    const modal = await this.modalController.create({
      component: PackagesComponent,
      componentProps: { packages }
    });

    await modal.present();

  }

  //region Download of documents
  private async downloadUrl(urlDownload, fileName){
    let request = this.configNotificationDownload(urlDownload,fileName);
    console.log("DOWNLOAD::request", request);

    await this.downloader.download(request).then((location: string) =>{
      console.log("DOWNLOAD::SUCCESS location", location);
      this.intermediaryService.dismissLoadingNew();
      this.intermediaryService.presentToastSuccess("¡Descarga finalizada!");
    }).catch((error: any) => {
      console.log("DOWNLOAD::ERROR", error);
      this.intermediaryService.dismissLoadingNew();
      this.intermediaryService.presentToastError("¡Ha ocurrido un error al descargar el archivo!");
    });
  }

  private configNotificationDownload(url,name): DownloadRequest {
    const downloadsDirectory = `${config.downloads.directoryBase}/${config.downloads.directoryAL}/${config.downloads.directoryManifests}`;
    return {
      uri: url,
      title: 'Documento de manifiesto',
      description: '',
      mimeType: '',
      visibleInDownloadsUi: true,
      notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
      destinationInExternalPublicDir: {
        dirType: downloadsDirectory,
        subPath: name
      }
    }
  }
  //endregion

  // region Check expedition to relabel for transport-operator
  private checkTransportHasPendingRelabel(transportId: number) {
    if (typeof transportId == "undefined" || transportId == null) return;

    this.existsWarning = false;
    this.warningMessageText = null;

    this.oplTransportsService
      .getCheckTransportHasRelabel(transportId)
      .subscribe(res => {
        if (res.length > 0) {
          this.existsWarning = true;
          this.showWarning = true;

          this.warningMessageText = "Hay";
          let countTotalOrders = 0;
          if (res.length == 1) {
            countTotalOrders = res[0].count;
            this.warningMessageText = this.warningMessageText.concat(this.getWarningTextForWarehouse(res[0]));
          } else {
            for (let i in res) {
              if (parseInt(i) != 0 && parseInt(i) != (res.length - 1)) {
                this.warningMessageText = this.warningMessageText.concat(",");
              } else if (parseInt(i) == (res.length - 1)) {
                this.warningMessageText = this.warningMessageText.concat(" y");
              }

              const transportRelabel = res[i];
              countTotalOrders += transportRelabel.count;
              this.warningMessageText = this.warningMessageText.concat(this.getWarningTextForWarehouse(transportRelabel));
            }
          }

          if (countTotalOrders > 1) {
            this.warningMessageText = this.warningMessageText.concat(` previamente contratados con este transportista que están pendientes de retiquetado.`);
          } else {
            this.warningMessageText = this.warningMessageText.concat(` previamente contratado con este transportista que está pendiente de retiquetado.`);
          }
        } else {
          this.existsWarning = false;
        }
      }, err => this.existsWarning = false);
  }

  private getWarningTextForWarehouse(transportRelabel: ExpeditionsProcessedModel.CheckTransportHasRelabeled): string {
    if (typeof transportRelabel == "undefined" || !transportRelabel) return "";
    return ` ${transportRelabel.count} pedido${transportRelabel.count > 1 ? 's' : ''} de ${transportRelabel.warehouse}`;
  }

  public async showPopoverInfo() {
    this.showWarning = !this.showWarning;
  }

  public async closePopoverInfo() {
    this.showWarning = false;
  }
  //endregion
}

import {environment} from './../../../environments/environment'
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  private getPackageByReferenceUrl = `${environment.apiBase}/opl-expedition/op-orders/package/`

  constructor(
    private http: HttpClient
  ) { }

  getByReference(reference: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.getPackageByReferenceUrl}${reference}`).subscribe(response => {
        resolve(response)
      }, reject)
    })
  }
}

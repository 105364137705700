import { Injectable } from '@angular/core';
import {AuthenticationService, environment} from "@suite/services";
import {HiringSeurModel} from "../../../models/endpoints/HiringSeur";
import SearchParameters = HiringSeurModel.SearchParameters;
import FilterOptionsResponse = HiringSeurModel.FilterOptionsResponse;
import {RequestsProvider} from "../../../providers/requests/requests.provider";
import {from, Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HiringSeurService {

  private apiMga = environment.apiBase;
  private apiSeur = environment.apiSeur;

  private getAllWarehousesUrl = this.apiMga + '/warehouses/getAllWarehouses';
  private getWarehouseSeurUrl = this.apiSeur + '/seur-warehouse/';
  private updateWarehouseSeurUrl = this.apiSeur + '/seur-warehouse/';
  private deleteWarehouseSeurUrl = this.apiSeur + '/seur-warehouse/';
  private getFilterOptionsUrl = this.apiMga + '/warehouses/filter-options';
  private getFilterOptionsUrlSeur = this.apiSeur + '/seur-warehouse/filter-options';

  constructor(
    private requestsProvider: RequestsProvider,
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getAllWarehouses(params: SearchParameters):Observable<any> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
      return this.http.post<any>(this.getAllWarehousesUrl, params, {headers}).pipe(map(response=>{
        return response.data;
      }));
    }));
  }

  getAllWarehousesSeur(params: SearchParameters){
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
      return this.http.post<any>(this.getWarehouseSeurUrl, params, {headers}).pipe(map(response=>{
        return response.data;
      }));
    }));
  }

  updateWarehouseSeur(data){
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
      return this.http.put<any>(this.updateWarehouseSeurUrl + data.warehouseReference, data, {headers}).pipe(map(response=>{
        return response.data;
      }));
    }));
  }

  deleteWarehouseSeur(id:number){
    return this.http.delete<any>(this.deleteWarehouseSeurUrl + id)
      .pipe(map(response => {
        return response;
      }));
  }

  getFilterOptions(): Promise<FilterOptionsResponse> {
    return this.requestsProvider.get(this.getFilterOptionsUrl);
  }

  getFilterOptionsSeur(): Promise<FilterOptionsResponse> {
    return this.requestsProvider.get(this.getFilterOptionsUrlSeur);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class MarketplacesService {

  private apiBase = environment.apiRule;
  private apiMiddleware = environment.apiMiddleware;
  private apiCrm = environment.apiCrm;
  private getMapDataRulesUrl = this.apiBase + "/api/MapDataRules";
  private postMapDataRulesUrl = this.apiBase + "/api/MapDataRules/";
  private updateMapDataRulesUrl = this.apiBase + "/api/MapDataRules/";
  private deleteMapDataRulesUrl = this.apiBase + "/api/MapDataRules/";
  private getMapEntitiesUrl = this.apiBase + "/api/EnumMetadata/get/mapentity";
  private getRulesFilterTypesUrl = this.apiBase + "/api/EnumMetadata/get/rulefiltertype";
  private getRulesFilterUrl = this.apiBase + "/api/RuleFilter";
  private postRulesFilterUrl = this.apiBase + "/api/RuleFilter";
  private updateRulesFilterUrl = this.apiBase + "/api/RuleFilter/{{id}}";
  private getRulesFilterByTypeUrl = this.apiBase + "/api/RuleFilter/{{type}}";
  private getMarketsUrl = this.apiMiddleware + "/api/v1/market";
  private getRulesConfigurationsUrl = this.apiBase + "/rc/bymarket/";
  private postRulesConfigurationsUrl = this.apiBase + "/api/RuleConfiguration";
  private updateRulesConfigurationsUrl = this.apiBase + "/api/RuleConfiguration/";
  private getRulesConfigurationsByIdUrl = this.apiBase + "/api/RuleConfiguration/{{id}}";
  private getBrandsRuleFilters = this.apiBase + "/api/RuleFilter/5";
  private getColorsRuleFilters = this.apiBase + "/api/RuleFilter/3";
  private getFeaturesRuleFilters = this.apiBase + "/api/RuleFilter/2";
  private getSizesRuleFilters = this.apiBase + "/api/RuleFilter/4";
  private getSeasonsRuleFilters = this.apiBase + "/api/RuleFilter/30";
  private postProductCategoryUrl = this.apiBase + "/api/ProductCategory/";
  private getProductCategoryUrl = this.apiBase + "/api/ProductCategory/";
  private searchCatalogUrl = this.apiMiddleware + "/product/search";
  private postReportsMarketsUrl = this.apiMiddleware + "/oddjob/launch";
  private getReportMarketplacesUrl = this.apiMiddleware + "/oddjob/";
  private getDownloadReportUrl = this.apiMiddleware + "/file-store/{id}/stream-body";
  private getAllReportsUrl = this.apiMiddleware + "/oddjob";
  private searchCatalogVariationsUrl = this.apiMiddleware + "/product/search-with-variations";
  private getCategories = this.apiBase + "/api/ProductCategory";
  private getMarketProductPropertyUrl = this.apiMiddleware + "/market/product-property";
  private getMarketSizesUrl = this.apiMiddleware + "/market/product-property/sizes/by-market/";
  private getMarketBrandsUrl = this.apiMiddleware + "/market/product-property/brands/by-market/";
  private getMarketColorsUrl = this.apiMiddleware + "/market/product-property/colors/by-market/";
  private getMarketFeaturesUrl = this.apiMiddleware + "/market/product-property/features/by-market/";
  private getMarketSeasonsUrl = this.apiMiddleware + "/market/product-property/seasons/by-market/";
  private forceProductUpdateUrl = this.apiMiddleware + "/product/force-update-valid";
  private updateDataUrl = this.apiMiddleware + "/product/remap-rule-filter";
  private updateCategoriesMinPricesUrl = this.apiMiddleware + "/product/remap-for-market";
  private getMarketplacesUrl = this.apiMiddleware + "/market";
  private getPlatformOrdersUrl = this.apiCrm + '/orders/platform/';
  private postUpdateTrackingOrderUrl = this.apiCrm + '/orders/{{id}}/expedition-tracking-exchange';
  private patchOrderLineUrl = this.apiCrm + '/orders/lines/{{id}}';
  private getZalandoSizeMapUrl = this.apiMiddleware + "/zalando-size-map";
  private postZalandoSizeMapUpdateUrl = this.apiMiddleware + "/zalando-size-map/update-size/";
  private postZalandoSizeMapUpdateWithApiUrl = this.apiMiddleware + "/zalando-size-map/update-with-api";
  private deleteRuleUrl = this.apiBase + "/api/RuleConfiguration/";

  private markets = [];

  constructor(
    private http: HttpClient
  ) {
  }

  addMarketplacesOptions(menuPages){
    this.markets = [];
    const index = this.getPageIndexByTitle(menuPages, 'Marketplaces');
    this.getMarketplaces().subscribe(markets =>{
      if(markets){
        markets.forEach(market =>{
          this.markets.push(market);
          if(market.status !== 'disabled'){
            menuPages[index].children.push({
              title: market.name,
              open: false,
              type: 'wrapper',
              id: market.name,
              icon: 'add-circle-outline',
              thirdLevel: true,
              children: [
                {
                  title: 'Mapeos',
                  id: market.name + '-mapping',
                  url: '/marketplaces/mapping/' + market.id,
                  icon: 'code'
                },{
                  title: 'Reglas',
                  id: market.name + '-rules',
                  url: '/marketplaces/rules/' + market.id,
                  icon: 'code-working'
                },
                {
                  title: 'Catálogo',
                  id: market.name + '-catalog',
                  url: '/marketplaces/catalog/' + market.id,
                  icon: 'apps'
                }
              ]
            });

            if(market.configurationData.catalog.refunds){
              menuPages[index].children.slice(-1)[0].children.push({
                title: 'Reembolsos',
                id: market.name + '-refunds',
                url: '/marketplaces/refunds/' + market.id,
                icon: 'checkmark-circle'
              });
            }
          }
        });
      }
      menuPages[index].children.push(
        {
          title: 'Prioridad de Tienda',
          id: 'store-priority',
          url: '/marketplaces/store-priority',
          icon: 'ios-albums'
        },
        {
          title: 'Parametrización Logística',
          id: 'logistics-operators',
          url: '/marketplaces/logistics-operators',
          icon: 'cube'
        },
        {
          title: 'Destinos e impuestos',
          id: 'parameterization-iva',
          url: '/marketplaces/parameterization-iva',
          icon: 'information-circle'
        },
        {
          title: 'Informes',
          id: 'reports',
          url: '/marketplaces/reports',
          icon: 'ios-paper'
        }
      );
    });
  }

  private getPageIndexByTitle(menuPages, title : string) : number{
    let index = 0;
    for(const page of menuPages){
      if(page.title.includes(title)){
        break;
      }
      index++;
    }
    return index;
  }

  getCachedMarketById(marketId) : any{
     return this.markets.find(market=> market.id == marketId)
  }

  getMapEntities(): Observable<any> {
    return this.http.get<any>(this.getMapEntitiesUrl, {}).pipe(
      map(response => {
        return response;
      }));
  }

  getMapDataRules(): Observable<any> {
    return this.http.get<any>(this.getMapDataRulesUrl , {}).pipe(map(response => {
      return response;
    }));
  }

  postReportsMarketplaces(report): Observable<any> {
    return this.http.post<any>(this.postReportsMarketsUrl, report, {}).pipe(map(response => {
      return response;
    }));
  }
  
  getDownloadReportsMarketplacesFile(fileId): Observable<any> {
    return this.http.get<any>(this.getDownloadReportUrl.replace('{id}', fileId), {responseType: 'blob' as 'json'}).pipe(map(response => {
      return response;
    }));
  }

  getReportsMarketplaces(sort?): Observable<any> {
    if(sort){
      return this.http.get<any>(this.getAllReportsUrl + sort, {}).pipe(map(response => {
        return response;
      }));
    }
    return this.http.get<any>(this.getAllReportsUrl, {}).pipe(map(response => {
      return response;
    }));
  }

  postMapDataRules(data): Observable<any> {
    return this.http.post<any>(this.postMapDataRulesUrl, data, {}).pipe(map(response => {
      return response;
    }));
  }

  updateMapDataRules(mapDataId: number, data): Observable<any> {
    return this.http.put<any>(this.updateMapDataRulesUrl + mapDataId.toString(), data, {}).pipe(map(response => {
      return response;
    }));
  }

  deleteMapDataRules(mapDataId: number): Observable<any> {
    return this.http.delete<any>(this.deleteMapDataRulesUrl + mapDataId.toString(), {}).pipe(map(response => {
      return response;
    }));
  }

  getRulesFilterTypes(): Observable<any> {
    return this.http.get<any>(this.getRulesFilterTypesUrl, {}).pipe(map(response => {
      return response;
    }));
  }

  getBrands(): Observable<any> {
    return this.http.get<any>(this.getBrandsRuleFilters, {}).pipe(map(response => {
      return response;
    }));
  }

  getSizes(): Observable<any> {
    return this.http.get<any>(this.getSizesRuleFilters, {}).pipe(map(response => {
      return response;
    }));
  }

  getSeasons(): Observable<any> {
    return this.http.get<any>(this.getSeasonsRuleFilters, {}).pipe(map(response => {
      return response;
    }));
  }

  getFeatures(): Observable<any> {
    return this.http.get<any>(this.getFeaturesRuleFilters, {}).pipe(map(response => {
      return response;
    }));
  }

  getColors(): Observable<any> {
    return this.http.get<any>(this.getColorsRuleFilters, {}).pipe(map(response => {
      return response;
    }));
  }

  postRulesFilter(data): Observable<any> {
    return this.http.post<any>(this.postRulesFilterUrl, data, {}).pipe(map(response => {
      return response;
    }));
  }

  updateRulesFilter(ruleFilterId: number, data): Observable<any> {
    return this.http.put<any>(this.updateRulesFilterUrl.replace('{{id}}', ruleFilterId.toString()), data, {}).pipe(map(response => {
      return response;
    }));
  }

  getRulesFilterByType(type): Observable<any> {
    return this.http.get<any>(this.getRulesFilterByTypeUrl.replace('{{type}}', type.toString()), {}).pipe(map(response => {
      return response;
    }));
  }

  getMarkets(): Observable<any> {
    return this.http.get<any>(this.getMarketsUrl, {}).pipe(map(response => {
      return response;
    }));
  }

  getRulesConfigurations(market): Observable<any> {
    return this.http.get<any>(this.getRulesConfigurationsUrl + market, {}).pipe(map(response => {
      return response;
    }));
  }

  /*
  getRulesConfigurations(): Observable<any> {
    return this.http.get<any>(this.getRulesConfigurationsUrl, {}).pipe(map(response => {
      return response;
    }));
  }

  getRulesConfigurationsByMarket(marketId: number): Observable<any> {
    return this.http.get<any>(this.getRulesConfigurationsByMarketUrl.replace('{{id}}', marketId.toString()), {}).pipe(map(response => {
      return response;
    }));
  }
  */

  getRulesConfigurationsById(ruleConfigurationId: number): Observable<any> {
    return this.http.get<any>(this.getRulesConfigurationsByIdUrl.replace('{{id}}', ruleConfigurationId.toString()), {}).pipe(map(response => {
      return response;
    }));
  }

  postRulesConfigurations(data): Observable<any> {
    return this.http.post<any>(this.postRulesConfigurationsUrl, data, {}).pipe(map(response => {
      return response;
    }));
  }

  updateRulesConfigurations(ruleConfigurationId, data): Observable<any> {
    return this.http.put<any>(this.updateRulesConfigurationsUrl + ruleConfigurationId, data, {}).pipe(map(response => {
      return response;
    }));
  }

  getMarketCategories(): Observable<any> {
    return this.http.get<any>(this.getCategories, {}).pipe(map(response => {
      return response;
    }));
  }

  /*
  getMarketCategories(marketId: number): Observable<any> {
    return this.http.get<any>(this.getCategoriesUrl.replace('{{id}}', marketId.toString()), {}).pipe(map(response => {
      return response;
    }));
  }
  */

  postProductCategory(data): Observable<any> {
    return this.http.post<any>(this.postProductCategoryUrl, data, {}).pipe(map(response => {
      return response;
    }));
  }

  forceProductsUpdate(): Observable<any> {
    return this.http.post<any>(this.forceProductUpdateUrl, {}, {}).pipe(map(response => {
      return response;
    }));
  }

  update_data(dataToUpdate): Observable<any> {
    return this.http.post<any>(this.updateDataUrl, dataToUpdate).pipe(map(response => {
      return response;
    }));
  }

  updateCategoriesMinPrices(marketReId):Observable<any> {
    return this.http.post<any>(this.updateCategoriesMinPricesUrl, marketReId).pipe(map(response => {
      return response;
    }));
  }

  searchProductCatalog(filters): Observable<any> {
    return this.http.post<any>(this.searchCatalogUrl, filters).pipe(map(response => {
      return response;
    }));
  }
  searchProductWithVariationsCatalog(filters): Observable<any> {
    return this.http.post<any>(this.searchCatalogVariationsUrl, filters).pipe(map(response => {
      return response;
    }));
  }

  getAllProductCatalog(filters): Observable<any> {
    return this.http.post<any>(this.searchCatalogUrl, filters).pipe(map(response => {
      return response;
    }));
  }

  getAllProductCatalogMarketplaces(filters): Observable<any> {
    return this.http.post<any>(this.searchCatalogUrl, filters).pipe(map(response => {
      return response;
    }));
  }

  getMarketSizes(market): Observable<any> {
    return this.http.get<any>(this.getMarketSizesUrl + market, {}).pipe(map(response => {
      return response;
    }));
  }

  getMarketBrands(market): Observable<any> {
    return this.http.get<any>(this.getMarketBrandsUrl + market, {}).pipe(map(response => {
      return response;
    }));
  }

  getMarketColors(market): Observable<any> {
    return this.http.get<any>(this.getMarketColorsUrl + market, {}).pipe(map(response => {
      return response;
    }));
  }

  getMarketFeatures(market): Observable<any> {
    return this.http.get<any>(this.getMarketFeaturesUrl + market, {}).pipe(map(response => {
      return response;
    }));
  }

  getMarketSeasons(market): Observable<any> {
    return this.http.get<any>(this.getMarketSeasonsUrl + market, {}).pipe(map(response => {
      return response;
    }));
  }

  getMarketProductProperty(query = ""): Observable<any> {
    return this.http.get<any>(this.getMarketProductPropertyUrl + query, {}).pipe(map(response => {
      return response;
    }));
  }

  getZalandoSizeMap(query = ""): Observable<any> {
    return this.http.get<any>(this.getZalandoSizeMapUrl + query, {}).pipe(map(response => {
      return response;
    }));
  }

  postUpdateZalandoSizeMap(sizeDomainId, body): Observable<any> {
    return this.http.post<any>(this.postZalandoSizeMapUpdateUrl + sizeDomainId, body).pipe(map(response => {
      return response;
    }));
  }

  postUpdateZalandoSizeMapWithApi(): Observable<any> {
    return this.http.post<any>(this.postZalandoSizeMapUpdateWithApiUrl, {}).pipe(map(response => {
      return response;
    }));
  }

  getMarketplaces(): Observable<any> {
    return this.http.get<any>(this.getMarketplacesUrl, {}).pipe(map(response => {
      return response;
    }));
  }

  deleteRule(ruleId : string) : Observable<any>{
    return this.http.delete<any>(this.deleteRuleUrl + ruleId, {}).pipe(map(response => {
      return response;
    }));
  }

  postSearchPlatformOrders(body){
      return this.http.post<any>(this.getPlatformOrdersUrl, body).pipe(map(response=>{
        return response.data;
      }));
  }

  postUpdateTrackingOrder(orderId, body){
    return this.http.post<any>(this.postUpdateTrackingOrderUrl.replace('{{id}}', orderId.toString()), body).pipe(map(response=>{
      return response.data;
    }));
  }

  patchOrderLine(orderLineId, body){
    return this.http.patch<any>(this.patchOrderLineUrl.replace('{{id}}', orderLineId.toString()), body).pipe(map(response=>{
      return response.data;
    }));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectableListComponent } from './selectable-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {SingleSelectListModule} from "../../components/single-select-list/single-select-list.module";
import {MatFormFieldModule, MatSelectModule} from "@angular/material";

@NgModule({
  declarations: [SelectableListComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    CommonModule,
    SingleSelectListModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  exports:[SelectableListComponent]
})
export class SelectableListModule { }

import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {MarketplacesService} from 'libs/services/src/lib/endpoint/marketplaces/marketplaces.service';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

export interface PeriodicElement {
  type: string;
  id: number;
  date: string;
  description: string;
  download: string;
}

@Component({
  selector: 'report-marketplaces',
  styleUrls: ['reports-marketplaces.component.scss'],
  templateUrl: 'reports-marketplaces.component.html',
})

export class ReportMarketplacesComponent implements OnInit{
  displayedColumns: string[] = ['fileId','type','date','description','download'];
  dataSource : MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild('dataSort') dataSort = new MatSort();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  orderType: string;
  typeOrder: string;
  products: any;
  resultsLength = 0;
  numPages: number;

  constructor(
    private marketplacesService: MarketplacesService,
  ) {    
      this.dataSource.sort = this.dataSort;  
    }

  ngOnInit(): void {
    this.getReports();
    this.paginator._intl.itemsPerPageLabel = 'Items por página';
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator._intl.firstPageLabel = 'Primera página';
    this.paginator._intl.lastPageLabel = 'Última página';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };
  }
 
  downloadReportsMarketplaces(fileId) {
    this.marketplacesService.getDownloadReportsMarketplacesFile(fileId).subscribe(
      (response) => {
        let blob = new Blob([response], { type: 'application/zip' });            
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "informe.zip";
        link.click();
      },
      (error) => {
        throw error;
      }
    );
  }

  getReports() {
    this.marketplacesService.getReportsMarketplaces("?sort=updatedAt%2CDESC&limit=15").subscribe(
      (response) => {
        let data = response.data.filter((item) => item.artifacts.length > 0);
        this.dataSource = data;
        this.resultsLength = response.total;
        this.numPages = response.pageCount;
        this.paginator.pageIndex = 0;
      },
      (error) => {
        throw error;
      }
    );
  }

  changePage(event) {
    if(event.pageIndex < this.numPages) {
      if(this.typeOrder && this.orderType) {
        let sort = "?sort=artifacts." + this.orderType + "%2C" + this.typeOrder.toUpperCase() + '&limit=15&page=' + (event.pageIndex + 1);
        if(this.orderType == "date" && this.typeOrder != "") {
          sort = "?sort=updatedAt" + "%2C" + this.typeOrder.toUpperCase() + '&limit=15&page=' + (event.pageIndex + 1);
        }
        this.marketplacesService.getReportsMarketplaces(sort).subscribe(
          (response) => {
            let data = response.data.filter((item) => item.artifacts.length > 0);
            this.dataSource = data;
          },
          (error) => {
            throw error;
          }
        );
      }
      else {
        this.marketplacesService.getReportsMarketplaces("?sort=updatedAt%2CDESC&page=" + (event.pageIndex + 1) + '&limit=15').subscribe(
          (response) => {
            let data = response.data.filter((item) => item.artifacts.length > 0);
            this.dataSource = data;
          },
          (error) => {
            throw error;
          }
        );
      }
  }
  }

  sortData(event: Sort) {
    this.orderType = event.active;
    this.typeOrder = event.direction;
    let sort = "?sort=artifacts." + this.orderType + "%2C" + this.typeOrder.toUpperCase() + '&limit=15';
    
    if(this.orderType == "date" && this.typeOrder != "") {
      sort = "?sort=updatedAt" + "%2C" + this.typeOrder.toUpperCase() + '&limit=15';
    }

    if(this.typeOrder == "") {
      sort = "?sort=updatedAt%2CDESC&limit=15";
    }

    this.marketplacesService.getReportsMarketplaces(sort).subscribe(
      (response) => {
        let data = response.data.filter((item) => item.artifacts.length > 0);
        this.dataSource = data;
        this.paginator.pageIndex = 0;
      },
      (error) => {
        throw error;
      }
    );
  }
}

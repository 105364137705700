import {Component, OnInit} from '@angular/core';
import {AuthenticationService, environment, IntermediaryService, TypesService} from '@suite/services';
import {ProductsService, InventoryModel} from '@suite/services';
import {PrinterService} from "../../../../../services/src/lib/printer/printer.service";
import {PrintModel} from "../../../../../services/src/models/endpoints/Print";
import {WarehouseService} from "../../../../../services/src/lib/endpoint/warehouse/warehouse.service";
import {AlertController, LoadingController, ModalController, NavParams} from "@ionic/angular";
import {InventoryService} from "../../../../../services/src/lib/endpoint/inventory/inventory.service";
import {from, Observable, of} from "rxjs";
import * as moment from 'moment';
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {DateTimeParserService} from "../../../../../services/src/lib/date-time-parser/date-time-parser.service";
import {PermissionsService} from '../../../../../services/src/lib/endpoint/permissions/permissions.service';
import {TimesToastType} from '../../../../../services/src/models/timesToastType';
import {PositionsToast} from "../../../../../services/src/models/positionsToast.type";
import {ActionTypeModel} from "../../../../../services/src/models/endpoints/ActionType";
import {catchError, switchMap} from "rxjs/operators";
import * as Filesave from 'file-saver';

export interface Result {
  statusCode: Number,
  statusMessage: String,
  statusDescription: String,
  result: Object,
  data: String,
  message: String,
  code: String
}

@Component({
  selector: 'suite-generate-excel',
  templateUrl: './generate-excel.component.html',
  styleUrls: ['./generate-excel.component.scss'],
})

export class GenerateExcelComponent implements OnInit {

  public creationTime: string;
  public csvExists: boolean;
  private getCsvDateTimeUrl = `${environment.apiBase}/inventory/get-csv-all-products-created-time`;

  constructor(
    private modalController: ModalController,
    private intermediaryService: IntermediaryService,
    private http: HttpClient,
    private auth: AuthenticationService,
    private inventoryServices: InventoryService
  ) {
    this.csvExists = false;
    this.creationTime = '2022-08-22T10:05:21.417Z';
  }

  ngOnInit(): void {
    this.getCsvDateTime();
  }

  public async getCsvDateTime() {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.get<Result>(this.getCsvDateTimeUrl, { headers });
    })).subscribe(result => {
      this.creationTime = result.data.toString();
      this.csvExists = true;
    });
  }

  close() {
    this.modalController.dismiss();
  }

  downloadCSV() {
    console.log('downloading CSV');
    this.intermediaryService.presentLoading('Descargando Archivo CSV');
    this.inventoryServices.getCSV().pipe(
      catchError(error => of(error)),
      // map(file => file.error.text)
    ).subscribe((data) => {
      console.log(data);
      // check if there is a file to download
      if (data.statusCode === 200) {

      }
      const blob = new Blob([data], {type: 'text/csv'});
      Filesave.saveAs(blob, `AllProdcuts.csv`);
      this.intermediaryService.dismissLoading();
      this.intermediaryService.presentToastSuccess('Archivo descargado')
    }, error => console.log(error));
  }

  generateNewCSV() {
    this.intermediaryService.presentLoading('Solicitando creación de nuevo archivo CSV');
    this.inventoryServices.generateCSV().pipe(
      catchError(error => of(error)),
      // map(file => file.error.text)
    ).subscribe((data) => {
      this.intermediaryService.dismissLoading();
      if (data.statusCode === 200) {
        this.intermediaryService.presentToastSuccess('El archivo se está generando')
      } else {
        this.intermediaryService.presentToastError('Error al generar el archivo')
      }
    }, error => console.log(error));
  }
}

import { Component, OnInit } from '@angular/core';
import { BarcodeScannerService } from 'libs/services/src/lib/barcode-scanner/barcode-scanner.service';
@Component({
  selector: 'suite-size-regularization',
  templateUrl: './size-regularization.component.html',
  styleUrls: ['./size-regularization.component.scss']
})
export class SizeRegularizationComponent implements OnInit {

  constructor(private barcodeScannerService: BarcodeScannerService) { }

  async scanBarcode(action) {
    var scanResult = await (await this.barcodeScannerService.useScanner(action));
    //console.log('REFERENCE ID-->', scanResult);
    //this.getExtraProductInfo(scanResult);
  }

  ngOnInit() {
  }

}

export enum TableEmitter {
  BtnAdd = 1,
  BtnExcell = 2,
  BtnSend = 3,
  BtnRefresh = 4,
  BtnDelete = 5,
  Checkbox = 6,
  Filters = 7,
  OpenRow = 8,
  Pagination = 9,
  Sorter = 10,
  iconEdit = 11,
}

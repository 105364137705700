import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MarketplacesMgaService} from "../../../../../services/src/lib/endpoint/marketplaces-mga/marketplaces-mga.service";
import { IntermediaryService } from '../../../../../services/src/lib/endpoint/intermediary/intermediary.service';

@Component({
  selector: 'suite-update-parameterization-iva',
  templateUrl: './update-parameterization-iva.component.html',
  styleUrls: ['./update-parameterization-iva.component.scss']
})
export class UpdateParameterizationIvaComponent implements OnInit {

  title = 'Editar regla';
  redirectTo = '/update-parameterization-iva';
  elementRules;
  id;
  country;
  isoCode;
  iva;
  nameShippingCost;
  activateButton;

  updateForm: FormGroup = this.formBuilder.group({
    country: [''],
    isoCode: [''],
    iva: ['', [Validators.required, Validators.pattern('^[0-9]+([.][0-9]+)?$')]],
    nameShippingCost: ['']
  });

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private navParams: NavParams,
    private marketplacesMgaService: MarketplacesMgaService,
    private intermediaryService: IntermediaryService,
  ) {}

  ngOnInit() {
    this.elementRules = this.navParams.get('element');
    this.id = this.elementRules.id;
    this.country = this.elementRules.country;
    this.isoCode = this.elementRules.isoCode;
    this.iva = this.elementRules.iva;
    this.nameShippingCost = this.elementRules.nameShippingCost;
  }

  updateRules(){
    let dataBody = {
      "id": this.id,
      "percentage": this.iva
    };

    this.marketplacesMgaService.updateParameterizationIva(dataBody).subscribe(data => {
      this.intermediaryService.presentToastSuccess("Regla guardada con éxito");
      this.modalController.dismiss(null);
    }, error => {
      this.intermediaryService.presentToastError("Error al guardar la regla");
      this.modalController.dismiss(null);
    });
  }

  close():void{
    this.modalController.dismiss();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatTooltipModule } from '@angular/material';
import { AvelonIncidencesReceptionShowRequestJsonComponent } from "./avelon-incidences-reception-show-request-json.component";



@NgModule({
  declarations: [AvelonIncidencesReceptionShowRequestJsonComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    MatTooltipModule
  ],
  entryComponents: [
    AvelonIncidencesReceptionShowRequestJsonComponent
  ]
})
export class AvelonIncidencesReceptionShowRequestJsonModule {}

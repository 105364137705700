import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { PickingParametrizationProvider } from "../../../../services/src/providers/picking-parametrization/picking-parametrization.provider";
import { Events } from "@ionic/angular";
import { WorkwaveModel } from "../../../../services/src/models/endpoints/Workwaves";
import AssignationsByRequests = WorkwaveModel.AssignationsByRequests;

@Component({
  selector: 'table-team-assignation',
  templateUrl: './table-team-assignation.component.html',
  styleUrls: ['./table-team-assignation.component.scss']
})
export class TableTeamAssignationOSComponent implements OnInit {

  @Input() responseQuantities: WorkwaveModel.AssignationsByRequests[];
  @Output() loadTeamAssignations = new EventEmitter();

  private TEAM_ASSIGNATIONS_LOADED = "team-assignations-loaded-os";

  listTeamAssignations: Array<WorkwaveModel.TeamAssignations> = new Array<WorkwaveModel.TeamAssignations>();

  private maxQuantityAssignations: number = 0;
  maxSizeForCols: number = 12;
  maxSizeForNameCol: number = 2;
  private columnsMultiple: number = 10;

  tooltipValue: string = null;
  enlarged = false;

  constructor(
    public events: Events,
    public pickingParametrizationProvider: PickingParametrizationProvider
  ) {}

  ngOnInit() {
    this.events.subscribe(this.TEAM_ASSIGNATIONS_LOADED, () => {
      this.listTeamAssignations = this.pickingParametrizationProvider.listTeamAssignations;

      this.maxQuantityAssignations = 0;

      if (this.listTeamAssignations.length > 0) {

        for (let teamAssignation of this.listTeamAssignations) {
          let tempMaxCount = 0;
          for (let assignation of teamAssignation.pickingShoes) {
            tempMaxCount += parseInt(assignation.quantityShoes);
          }
          if (tempMaxCount > this.maxQuantityAssignations) {
            this.maxQuantityAssignations = tempMaxCount;
          }
        }

        this.maxQuantityAssignations *= this.columnsMultiple;

        this.maxSizeForNameCol = this.maxQuantityAssignations * 0.2;
        this.maxSizeForCols = this.maxQuantityAssignations + this.maxSizeForNameCol;
      } else {
        this.maxSizeForCols = 12;
        this.maxSizeForNameCol = 2;
      }
    }
    );
  }

  ngOnDestroy() {
    this.events.unsubscribe(this.TEAM_ASSIGNATIONS_LOADED);
  }

  private teamAssignationsLoaded() {
    this.listTeamAssignations = this.pickingParametrizationProvider.listTeamAssignations;

    for (let teamAssignation of this.listTeamAssignations) {
      let tempMaxCount = 0;
      for (let assignation of teamAssignation.pickingShoes) {
        tempMaxCount += parseInt(assignation.quantityShoes);
      }
      if (tempMaxCount > this.maxQuantityAssignations) {
        this.maxQuantityAssignations = tempMaxCount;
      }
    }

    this.maxSizeForNameCol = this.maxQuantityAssignations * 0.2;
    this.maxSizeForCols = this.maxQuantityAssignations + this.maxSizeForNameCol;
  }

  stringToInt(value: string): number {
    return parseInt(value) * this.columnsMultiple;
  }

  userSelected() {
    if (!this.userAssignationsAreLoading()) {
      this.loadTeamAssignations.emit({ user: true, table: true });
    }
  }

  showConsolidatedBreakdown(assignation) {

    this.tooltipValue = '';
    if (assignation.quantityOnlineShoes > 0) this.tooltipValue += `ONLINE -> ${assignation.quantityOnlineShoes} \n `;
    if (assignation.quantityShoes > 0) this.tooltipValue += `PETICIONES TIENDA -> ${assignation.quantityShoes - (assignation.quantityOnlineShoes | 0)} \n `;
    
  }

  isChecked(operation: HTMLElement) {
    return operation.children[0].children[0].children[0].children[0].children[0].getAttribute('aria-checked') == 'true';
  }

  getLaunchPairs(operation: HTMLElement) {
    return parseInt(operation.children[0].children[7].children[0].children[0].innerHTML);
  }

  getDestiny(operation: HTMLElement) {
    return operation.children[0].children[4].children[0].children[0].innerHTML;
  }

  isAssigned(operation: HTMLElement, requestReferences){
    return requestReferences.includes(parseInt(operation.children[0].children[1].children[0].children[0].innerHTML));
  }

  enlarge() {
    if (this.enlarged) {
      let top = document.getElementsByClassName('stores-employees')[0] as HTMLElement;
      let middle = document.getElementsByClassName('requests-orders')[0] as HTMLElement;
      let bottom = document.getElementsByClassName('team-assignation')[0] as HTMLElement;
      let empty = document.getElementsByClassName('empty-list')[0] as HTMLElement;
      document.getElementById('top').style.display = 'block';
      top.style.display = 'block';
      middle.style.display = 'block';
      bottom.style.height = 'calc(45vh - 52px - 56px - 18px - 18px - 8px)';
      this.enlarged = !this.enlarged;
    } else {
      let top = document.getElementsByClassName('stores-employees')[0] as HTMLElement;
      let middle = document.getElementsByClassName('requests-orders')[0] as HTMLElement;
      let bottom = document.getElementsByClassName('team-assignation')[0] as HTMLElement;
      let empty = document.getElementsByClassName('empty-list')[0] as HTMLElement;
      document.getElementById('top').style.display = 'none';
      top.style.display = 'none';
      middle.style.display = 'none';
      bottom.style.height = 'calc(100vh - 52px - 56px)';
      this.enlarged = !this.enlarged;
    }
  }

  userAssignationsAreLoading() : boolean {
    return this.pickingParametrizationProvider.loadingListTeamAssignations && this.pickingParametrizationProvider.loadingListTeamAssignations > 0;
  }

}

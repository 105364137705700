import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ExpeditionManualService} from "../../../../../services/src/lib/endpoint/expedition-manual/expedition-manual.service";
import {IntermediaryService} from "@suite/services";
import {LoadingButtonComponent} from "../../../components/button/loading-button/loading-button.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ExpeditionAddressesModel} from "../../../../../services/src/models/endpoints/Expeditions/Adresses";
import {TimesToastType} from "../../../../../services/src/models/timesToastType";

@Component({
  selector: 'suite-expedition-manual',
  templateUrl: './change-address.component.html',
  styleUrls: ['./change-address.component.scss'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateY(0)' })),
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.17s ease-in-out')
      ]),
      transition(':leave', [
        animate('0.17s ease-in-out', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class ChangeAddressComponent implements OnInit {

  @ViewChild('lbLoadDestiny') lbLoadDestiny: LoadingButtonComponent;

  public formOrderId: FormGroup;
  public form: FormGroup;

  public markets: any[] = [];
  public warehouses: any[] = [];
  public provinces: any[] = [];
  public countries: any[] = [];
  public rules: any[] = [];

  public name: string = null;
  public surname: string = null;
  public phone: string = null;
  public email: string = null;
  public address1: string = null;
  public address2: string = null;
  public postcode: string = null;
  public province = {
    createdAt: null,
    id: -1,
    name: null,
    postalCodes: [],
    updatedAt: null
  };
  public country = null;
  public city: string = null;
  public company: string = null;

  public orderCanChangeAddress: boolean = true;

  public showAdvice: boolean = false;
  public advice: string = null;

  public countryHasProvinces: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private expeditionManualService: ExpeditionManualService,
    private intermediaryService: IntermediaryService
  ) {}

  ngOnInit() {
    this.initForm();
    this.getCountries();
    this.getProvinces();
  }

  public isFormEmpty() {
    return !(this.formOrderId.value.orderId || this.name || this.surname || this.phone || this.email || this.address1 || this.address2 || this.postcode || this.province || this.country || this.city || this.company);
  }

  public resetFields() {
    this.loadOrderDestinyInForm();
    this.showAdvice = false;
    this.advice = null;
    this.orderCanChangeAddress = true;
    this.initForm();
  }

  public async createExpedition() {
    if (this.formOrderId.invalid) {
      this.intermediaryService.presentToastError('Indique el ID del pedido al que actualizarle la dirección.');
      return;
    }

    if (!this.orderCanChangeAddress) {
      this.intermediaryService.presentToastError('No es posible cambiar la dirección del pedido indicado porque las expediciones generadas para él ya han sido procesadas.');
      return;
    }

    if (this.form.invalid) {
      this.intermediaryService.presentToastError('Complete los campos marcados como obligatorios (*) en el formulario y compruebe que estén correctos para continuar.');
      return;
    }

    await this.intermediaryService.presentLoadingNew('Guardando la nueva dirección para el pedido...');
    const body: ExpeditionAddressesModel.ParamsUpdateExternalOrderAddress = {
      externalOrderId: this.formOrderId.value.orderId,
      address: {
        name: this.name,
        surname: this.surname,
        phone: this.phone,
        email: this.email,
        city: this.city,
        companyName: this.company,
        address1: this.address1,
        address2: this.address2,
        country: this.country.isoCode,
        province: this.province && this.province.name != 'Provincia' ? this.province.name : null,
        postcode: this.postcode
      }
    };
    this.expeditionManualService
      .putUpdateExternalOrderAddress(body)
      .subscribe(() => {
        this.intermediaryService.dismissLoadingNew();
        this.intermediaryService.presentToastSuccess(`Registrada la nueva dirección para el pedido ${this.formOrderId.value.orderId}.`, TimesToastType.DURATION_SUCCESS_TOAST_2750);
        this.resetFields();
      }, (e) => {
        this.intermediaryService.dismissLoadingNew();
        if (e && e.error && e.error.message == 'NotFoundExpeditionsException') {
          this.intermediaryService.presentToastSuccess(`Registrada la nueva dirección para el pedido ${this.formOrderId.value.orderId}.`, TimesToastType.DURATION_SUCCESS_TOAST_2750);
          this.resetFields();
        } else {
          let errorMessage = 'Ha ocurrido un error al intentar guardar la nueva dirección indicada.';
          if (e && e.error && e.error.errors) errorMessage = e.error.errors;
          this.intermediaryService.presentToastError(errorMessage);
        }
      });
  }

  public preloadDestiny() {
    if (this.formOrderId.invalid) {
      this.intermediaryService.presentToastError('Indique el ID del pedido del que precargar la dirección.');
      return;
    }

    this.lbLoadDestiny.isLoading = true;
    this.expeditionManualService
      .postOrderAddress({ externalOrderId: this.formOrderId.value.orderId })
      .subscribe(res => {
        this.advice = res.errorMessage || null;
        this.showAdvice = !!this.advice;
        this.orderCanChangeAddress = res.canChangeAddress;
        this.enableFormFields(this.orderCanChangeAddress);
        if (this.orderCanChangeAddress) this.loadOrderDestinyInForm(res.address);
      }, (e) => {
        if (e && e.error && e.error.code == 404 && e.error.message == 'NotFoundEntityException') {
          this.showAdvice = true;
          this.advice = `El pedido ${this.formOrderId.value.orderId} no tiene direcciones registradas o no ha sido embalado todavía.\nRellene todos los campos indicados si quiere pre-cargar esa dirección cuando se procese el pedido.`;
        } else {
          this.advice = null;
          this.showAdvice = false;
        }
        this.loadOrderDestinyInForm();
        this.orderCanChangeAddress = true;
        this.lbLoadDestiny.isLoading = false;
      }, () => this.lbLoadDestiny.isLoading = false);
  }

  private initForm() {
    this.formOrderId = this.formBuilder.group({
      orderId: new FormControl('', Validators.required)
    });
    this.form = this.formBuilder.group({
      name: new FormControl('', [ Validators.required, Validators.maxLength(25) ]),
      surname: new FormControl('', [ Validators.required, Validators.maxLength(25) ]),
      phone: new FormControl('', [ Validators.maxLength(12) ]),
      email: new FormControl('', [Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
      address1: new FormControl('', [ Validators.required, Validators.maxLength(30) ]),
      address2: new FormControl('', [ Validators.maxLength(30) ]),
      city: new FormControl('', [ Validators.required, Validators.maxLength(30) ]),
      company: new FormControl('', [ Validators.maxLength(50) ]),
      province: new FormControl('' ),
      country: new FormControl('', Validators.required ),
      postcode: new FormControl('', [ Validators.required, Validators.maxLength(100) ])
    });
  }

  private enableFormFields(enable: boolean) {
    if (enable) {
      this.form.controls['name'].enable();
      this.form.controls['surname'].enable();
      this.form.controls['phone'].enable();
      this.form.controls['email'].enable();
      this.form.controls['address1'].enable();
      this.form.controls['address2'].enable();
      this.form.controls['city'].enable();
      this.form.controls['company'].enable();
      this.form.controls['province'].enable();
      this.form.controls['country'].enable();
      this.form.controls['postcode'].enable();
    } else {
      this.form.controls['name'].disable();
      this.form.controls['surname'].disable();
      this.form.controls['phone'].disable();
      this.form.controls['email'].disable();
      this.form.controls['address1'].disable();
      this.form.controls['address2'].disable();
      this.form.controls['city'].disable();
      this.form.controls['company'].disable();
      this.form.controls['province'].disable();
      this.form.controls['country'].disable();
      this.form.controls['postcode'].disable();
    }
  }

  private loadOrderDestinyInForm(orderAddress?: ExpeditionAddressesModel.Address) {
    this.name = orderAddress ? orderAddress.name : null;
    this.surname = orderAddress ? orderAddress.surname : null;
    this.phone = orderAddress ? orderAddress.phone : null;
    this.email = orderAddress ? orderAddress.email : null;
    this.address1 = orderAddress ? orderAddress.address1 : null;
    this.address2 = orderAddress ? orderAddress.address2 : null;
    this.postcode = orderAddress ? orderAddress.postcode : null;

    // No need call countryChanged() because it's called automatically from ngModel in HTML
    this.country = orderAddress ? this.countries.find(c => c.isoCode.toUpperCase() == orderAddress.country.toUpperCase()) : null;

    // If exists address for expedition and the province is in provinces list, save this province
    if (orderAddress && orderAddress.province) {
      const province = this.provinces.find(p => p.name.toUpperCase() == orderAddress.province.toUpperCase());
      if (province) {
        this.province = province;
      } else {
        this.province = {
          createdAt: null,
          id: -1,
          name: orderAddress.province,
          postalCodes: [],
          updatedAt: null
        };
      }
    } else {
      this.province = {
        createdAt: null,
        id: -1,
        name: null,
        postalCodes: [],
        updatedAt: null
      };
    }

    this.city = orderAddress ? orderAddress.city : null;
    this.company = orderAddress ? orderAddress.companyName : null;
  }

  private getCountries(){
    this.expeditionManualService.getCountries().subscribe(data => {
      this.countries = data;
    });
  }
  private getProvinces(){
    this.expeditionManualService.getProvinces().subscribe(data => {
      this.provinces = data;
      this.provinces.unshift({
        createdAt: null,
        id: -2,
        name: "Provincia",
        postalCodes: [],
        updatedAt: null
      })
    });
  }

  public countryChanged() {
    if (this.country.isoCode == 'ES') {
      this.countryHasProvinces = true;
      if (!this.province || this.province.id == -1) {
        this.province = this.provinces[0];
      }
    } else {
      this.countryHasProvinces = false;
      if (!this.province || (this.province.id == -1 && !this.province.name) || this.province.id != -1) {
        this.province = {
          createdAt: null,
          id: -1,
          name: null,
          postalCodes: [],
          updatedAt: null
        };
      }
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvelonIncidencesReceptionComponent } from './avelon-incidences-reception.component';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MatCheckboxModule, MatTableModule, MatTooltipModule } from '@angular/material';
import { FilterButtonModule } from '../components/filter-button/filter-button.module';
import { PaginatorComponentModule } from '../components/paginator/paginator.component.module';
import { AvelonIncidencesReceptionShowJsonModule } from './modals/avelon-incidences-reception-show-json/avelon-incidences-reception-show-json.module';
import {
  AvelonIncidencesReceptionShowRequestJsonComponent
} from "./modals/avelon-incidences-reception-show-request-json/avelon-incidences-reception-show-request-json.component";
import {
  AvelonIncidencesReceptionShowRequestJsonModule
} from "./modals/avelon-incidences-reception-show-request-json/avelon-incidences-reception-show-request-json.module";

const route: Routes = [{
  path: '',
  component: AvelonIncidencesReceptionComponent
}]

@NgModule({
  declarations: [AvelonIncidencesReceptionComponent],
  imports: [
    CommonModule,
    IonicModule,
    MatTableModule,
    FilterButtonModule,
    PaginatorComponentModule,
    MatCheckboxModule,
    RouterModule.forChild(route),
    AvelonIncidencesReceptionShowRequestJsonModule,
    AvelonIncidencesReceptionShowJsonModule,
    MatTooltipModule
  ],
  entryComponents: [AvelonIncidencesReceptionComponent]
})
export class AvelonIncidencesReceptionModule { }

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ExpeditionManualService} from "../../../services/src/lib/endpoint/expedition-manual/expedition-manual.service";
import {IntermediaryService} from "@suite/services";

@Component({
  selector: 'suite-expedition-manual',
  templateUrl: './expedition-manual.component.html',
  styleUrls: ['./expedition-manual.component.scss']
})
export class ExpeditionManualComponent implements OnInit {

  public form: FormGroup;

  public markets: any[] = [];
  public warehouses: any[] = [];
  public logisticsOperators: any[] = [];
  public provinces: any[] = [];
  public countries: any[] = [];
  public rules: any[] = [];

  private regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  private warehouseDestiny = null;
  public address: string = null;
  public postcode: string = null;
  public province = null;
  public country = null;

  private readonly IS_DISABLED = true;

  constructor(
    private formBuilder: FormBuilder,
    private expeditionManualService: ExpeditionManualService,
    private intermediaryService: IntermediaryService
  ) {}

  ngOnInit() {
    this.initForm();

    this.form.valueChanges.subscribe(res => {
      if (res && res.warehouseDestiny && !this.warehouseDestiny) {
        this.warehouseDestiny = res.warehouseDestiny;
        this.address = this.warehouseDestiny.address1.trim() + (this.warehouseDestiny.address2.trim() ? ', ' + this.warehouseDestiny.address2.trim() : '');
        this.postcode = this.warehouseDestiny.postcode;
        this.province = this.provinces.find(p => p.name.toLowerCase() == this.warehouseDestiny.city.toLowerCase());
        this.country = this.countries.find(c => c.isoCode.toLowerCase() == this.warehouseDestiny.country.toLowerCase());
      }
    });

    this.getWarehouses();
    this.getTransports();
    this.getMarkets();
    this.getCountries();
    this.getProvinces();
    this.getRules();
  }

  public resetFields() {
    this.warehouseDestiny = null;
    this.address = null;
    this.postcode = null;
    this.province = null;
    this.country = null;
    this.initForm();
  }

  public createExpedition() {
    if (this.IS_DISABLED) {
      this.intermediaryService.presentToastError('La funcionalidad de generar expediciones manualmente todavía no está disponible.');
      return;
    }

    if (this.form.invalid) {
      this.intermediaryService.presentToastError('Complete el formulario.');
      return;
    }

    const recipient: {
      name: string,
      address: string,
      country: string,
      city: string,
      zipCode: string,
      phone: string,
      email: string,
      contactName: string,
      warehouseDestinityId?: number
    } = {
      name: this.form.value.name,
      address: this.form.value.direction,
      country: this.form.value.country.isoCode.toUpperCase(),
      city: this.form.value.province.name,
      zipCode: this.form.value.postalcode,
      phone: this.form.value.phone,
      email: this.form.value.email,
      contactName: this.form.value.name
    };

    if (this.form.value.warehouseDestiny && this.form.value.warehouseDestiny.id) {
      recipient.warehouseDestinityId = this.form.value.warehouseDestiny.id;
    }

    const body = {
      marketId: this.form.value.market.id,
      operator: this.form.value.operator.name.toUpperCase(),
      warehouseReference: this.form.value.warehouseOrigin.warehouseReference,
      referenceExpedition: this.form.value.referenceExpedition,
      sender: {
        warehouseOriginId: this.form.value.warehouseOrigin.id,
        name: this.form.value.warehouseOrigin.name,
        address: this.form.value.warehouseOrigin.address1 + (this.form.value.warehouseOrigin.address2 ? ', ' + this.form.value.warehouseOrigin.address2 : ''),
        country: this.form.value.warehouseOrigin.country.toUpperCase(),
        city: this.form.value.warehouseOrigin.city,
        zipCode: this.form.value.warehouseOrigin.postcode,
        phone: this.form.value.warehouseOrigin.phone
      },
      recipient,
      packages: {
        packagesNum: this.form.value.packages,
        packageReference: this.form.value.packagesReference.split(',').map(i => i.trim()),
        kilos: this.form.value.packagesWeight
      }
    };
  }

  private initForm() {
    this.form = this.formBuilder.group({
      market: new FormControl('', Validators.required ),
      warehouseOrigin: new FormControl('', Validators.required ),
      warehouseDestiny: new FormControl(''),
      operator: new FormControl(''),
      name: new FormControl('', [ Validators.required, Validators.maxLength(25) ]),
      phone: new FormControl('', [ Validators.required, Validators.maxLength(12) ]),
      email: new FormControl('', [ Validators.required, Validators.pattern(this.regexEmail) ]),
      direction: new FormControl('', [ Validators.required, Validators.maxLength(30) ]),
      province: new FormControl('', Validators.required ),
      country: new FormControl('', Validators.required ),
      postalcode: new FormControl('', [ Validators.required, Validators.maxLength(5) ]),
      referenceExpedition: new FormControl('', Validators.required ),
      packages: new FormControl('', [ Validators.required ]),
      packagesReference: new FormControl('', Validators.required ),
      packagesWeight: new FormControl('', [ Validators.required ])
    });
  }

  private getWarehouses(){
    this.expeditionManualService.getWarehouse().subscribe(data => {
      this.warehouses = data;
    });
  }
  private getTransports(){
    this.expeditionManualService.getLogisticsOperators().subscribe(data => {
      this.logisticsOperators = data;
    });
  }
  private getMarkets(){
    this.expeditionManualService.getMarkets().subscribe(data => {
      this.markets = data;
    });
  }
  private getCountries(){
    this.expeditionManualService.getCountries().subscribe(data => {
      this.countries = data;
    });
  }
  private getProvinces(){
    this.expeditionManualService.getProvinces().subscribe(data => {
      this.provinces = data;
    });
  }
  private getRules(){
    this.expeditionManualService.getRules().subscribe(data => {
      this.rules = data;
    });
  }
}

import {Component, OnInit, ViewChild,} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {SupplierConditionModel} from "../../../services/src/models/endpoints/SupplierCondition";
import {NavigationEnd, Router} from "@angular/router";
import {PaginatorComponent} from "../components/paginator/paginator.component";
import {OutScanIncidenceService} from "../../../services/src/lib/endpoint/out-scan-incidence/out-scan-incidence.service";
import {IntermediaryService} from "@suite/services";
import {AlertController, ModalController} from "@ionic/angular";
import {OutScanIncidencesShowJsonComponent} from "./modals/out-scan-incidences-show-json/out-scan-incidences-show-json.component";

@Component({
  selector: 'suite-out-scans-incidences',
  templateUrl: './out-scans-incidences.component.html',
  styleUrls: ['./out-scans-incidences.component.scss']
})
export class OutScansIncidencesComponent implements OnInit {

  public static alert: boolean = false;

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  public dataSource = null;
  public displayedColumns: string[] = ['date', 'origin', 'destiny', 'packing', 'request', 'error', 'solve'];

  outScansIncidences;
  filters = {
    dates: [],
    origins: [],
    destinies: [],
    packaging: []
  };
  filterOptions = {
    dates: [],
    origins: [],
    destinies: [],
    packaging: []
  };
  order = {
    field: 'date',
    direction: 'DESC'
  };
  pagination = {
    limit: undefined,
    page: 1
  };
  incidenceToSolve = null;
  currentUser;

  constructor(
    private router: Router,
    private outScanIncidenceService: OutScanIncidenceService,
    private alertController: AlertController,
    private modalController: ModalController,
    private intermediaryService: IntermediaryService
  ) {
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd && val && val.url === '/out-scans-incidences'){
        if(typeof this.outScansIncidences !== 'undefined'){
          this.reset();
        }
      }
    });
  }

  ngOnInit() {
    let sessionUser = JSON.parse(localStorage.getItem("user"));
    this.currentUser = sessionUser.id;

    this.loadFilters();
    this.paginator.page.subscribe(paginator => {
      if (this.pagination.limit !== paginator.pageSize) {
        this.pagination.page = 1;
      } else {
        this.pagination.page = paginator.pageIndex;
      }
      this.pagination.limit = paginator.pageSize;
      this.loadOutScansIncidences();
    });
  }

  ngAfterViewInit(){
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadOutScansIncidences();
  }

  loadFilters(){
    this.outScanIncidenceService.getOutScanIncidenceFilterOptions().then((response) => {
      if(response.code === 200){
        this.filterOptions = {
          dates: response.data.dates.map(data => {
            let day = data.slice(8, 10);
            let month = data.slice(5, 7);
            let year = data.slice(0, 4);
            let hours = data.slice(11, 13);
            let min = data.slice(14, 16);
            let secs = data.slice(17, 19);
            let newDate = day + '/' + month + '/' + year + ' ' + hours + ':' + min + ':' + secs;
            return {
              id: data,
              value: newDate,
              checked: false,
              hide: false
            }
          }),
          origins: response.data.origins.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
          destinies: response.data.destinies.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
          packaging: response.data.packaging.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          })
        }
      }else{
        console.error(response);
      }
    }).catch(console.error);
  }

  loadOutScansIncidences(){
    const parameters = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };
    this.outScanIncidenceService.postSearchOutScanIncidence(parameters).then((response) => {
      if(response.code === 200){

        OutScansIncidencesComponent.alert = !!response.data.result.length;

        for (let incidenceItem of response.data.result) {
          let day = incidenceItem.date.slice(8, 10);
          let month = incidenceItem.date.slice(5, 7);
          let year = incidenceItem.date.slice(0, 4);
          let hours = incidenceItem.date.slice(11, 13);
          let min = incidenceItem.date.slice(14, 16);
          let secs = incidenceItem.date.slice(17, 19);
          incidenceItem.date = day + '/' + month + '/' + year + ' ' + hours + ':' + min + ':' + secs;
        }
        this.dataSource = new MatTableDataSource<SupplierConditionModel.SupplierCondition>(response.data.result);
        this.outScansIncidences = response.data.result;
        const paginator = response.data.pagination;
        this.paginator.cantSelect = paginator.limit;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;
      }else{
        console.error(response);
      }
    }).catch(console.error);
  }

  orderBy(column: string){
    if(this.order.field === column){
      this.order.direction === 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.loadOutScansIncidences();
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        values.push(item.id);
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.pagination.page = 1;
    this.loadOutScansIncidences();
  }

  reset(){
    this.filters = {
      dates: [],
      origins: [],
      destinies: [],
      packaging: []
    };
    this.order = {
      field: 'date',
      direction: 'DESC'
    };
    this.pagination = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 1;
    this.loadFilters();
    this.loadOutScansIncidences();
  }

  async showJSON(incidence, type) {
    let dataToShow = null;
    let modal = null;
    switch (type) {
      case 'request':
        if (incidence.request) {
          try {
            dataToShow = JSON.stringify(JSON.parse(incidence.request), null, 4);
          } catch(e) {
            dataToShow = incidence.request;
          }
        } else {
          dataToShow = '';
        }
        modal = await this.modalController.create({
          component: OutScanIncidencesShowJsonComponent,
          componentProps: {
            incidence,
            type,
            dataToShow,
            currentUser: this.currentUser
          }
        });
        await modal.present();
        let requestData = <any>await modal.onDidDismiss();
        if (requestData && requestData.data.reset) {
          this.reset();
        }
        break;
      case 'error':
        if (incidence.error) {
          try {
            dataToShow = JSON.stringify(JSON.parse(incidence.error), null, 4);
          } catch(e) {
            dataToShow = incidence.error;
          }
          modal = await this.modalController.create({
            component: OutScanIncidencesShowJsonComponent,
            componentProps: {
              incidence,
              type,
              dataToShow,
              currentUser: this.currentUser
            }
          });
          await modal.present();
          let errorData = <any>await modal.onDidDismiss();
          if (errorData && errorData.data.reset) {
            this.reset();
          }
        }
        break;
      default:
        break;
    }
  }

  async manualIncidenceSolvingAlert(incidence) {
    this.incidenceToSolve = incidence;
    const alert = await this.alertController.create({
      header: 'Resolver manualmente',
      message: '<span>Se marcará como resuelta la incidencia de origen <b>' + incidence.origin + '</b>, destino <b>' + incidence.destiny + '</b> y embalaje <b>' +  incidence.packing + '</b>.<br/><br/>¿Está seguro de querer continuar?</span>',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.incidenceToSolve = null;
          }
        }, {
          text: 'Sí',
          handler: async () => {
            this.markAsSolved();
          }
        }
      ]
    });
    await alert.present();
  }

  markAsSolved() {
    let params = {
      sendRequest: false,
      incidence: this.formatIncidenceToSolve(),
      userId: this.currentUser
    };

    this.outScanIncidenceService.postSolveOutScanIncidence(params).then((response) => {
      if(response.code === 200){
        this.intermediaryService.presentToastSuccess('Incidencia marcada como resuelta.');
        this.reset();
        this.incidenceToSolve = null;
      } else{
        console.error(response);
        this.intermediaryService.presentToastError('Ha ocurrido un error. La incidencia no se ha podido marcar como resuelta.');
        this.incidenceToSolve = null;
      }
    }).catch((error) => {
      console.error(error);
      this.intermediaryService.presentToastError('Ha ocurrido un error. La incidencia no se ha podido marcar como resuelta.');
      this.incidenceToSolve = null;
    });

  }

  formatIncidenceToSolve() {
    let request = JSON.parse(this.incidenceToSolve.request);

    return {
      id: this.incidenceToSolve.id,
      type: this.incidenceToSolve.type,
      notificationId: this.incidenceToSolve.notificationId,
      packing: this.incidenceToSolve.packing,
      request
    };
  }

}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {AuthenticationService} from "@suite/services";
import {HttpRequestModel} from "../../../models/endpoints/HttpRequest";
import {RequestsProvider} from "../../../providers/requests/requests.provider";
import { from, Observable } from "rxjs";
import { PackingInventoryModel } from 'libs/services/src/models/endpoints/PackingInventory';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PackingInventoryService {

  private getCarrierOfProductUrl = environment.apiBase+"/packing/inventories/{{reference}}/carrier";
  private getProductsByPackingIdUrl = environment.apiBase+"/packing/reference/products/search";
  private getProductsFiltersByPackingIdUrl = environment.apiBase+"/packing/reference/products/filters";
  private getexcell = environment.apiBase+'/packing/reference/products/export-to-excel';

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private requestsProvider: RequestsProvider
  ) {}

  getCarrierOfProduct(reference: string) : Promise<HttpRequestModel.Response> {
    let url = this.getCarrierOfProductUrl.replace('{{reference}}', reference);
    return this.requestsProvider.get(url);
  }

  getFilters(packingId:number): Observable<PackingInventoryModel.ResponseSearchFilters> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.post<PackingInventoryModel.ResponseSearchFilters>(this.getProductsFiltersByPackingIdUrl, {packingId},{ headers });
    }));
  }

  searchProductsByPackingId(parameters): Observable<PackingInventoryModel.ResponseSearchFilters> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.post<PackingInventoryModel.ResponseSearchFilters>(this.getProductsByPackingIdUrl, parameters, { headers });
    }));
  }

  getFileExcell(parameters) {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      // let headers:HttpHeaders = new HttpHeaders({Authorization:token});

      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.post(this.getexcell, parameters, { headers, responseType: 'blob' });
    }));
  }

}

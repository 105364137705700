import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {ModalController} from "@ionic/angular";
import {MarketplacesService} from "../../../../services/src/lib/endpoint/marketplaces/marketplaces.service";
import {RefundsService} from "../../../../services/src/lib/endpoint/refunds/refunds.service";
import {IntermediaryService} from "@suite/services";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'suite-refunds-ok',
  templateUrl: './refunds-ko.component.html',
  styleUrls: ['./refunds-ko.component.scss']
})
export class RefundsKoComponent implements OnInit {

  displayedColumns: string[] = ['select', 'orderReference', 'returnItemIdentifier', 'returnSizeIndex', 'amount', 'paymentGateway', 'customerName'];
  dataSource;
  private refunds;
  private refundsData = [];
  private selection;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private modalController: ModalController,
              private renderer: Renderer2,
              private marketplacesService: MarketplacesService,
              private refundsService: RefundsService,
              private intermediaryService: IntermediaryService,) {
  }

  ngOnInit() {
    this.tableDataLoad();
  }

  tableDataLoad(){
    this.refundsService.getRefundsKo().subscribe(count => {
      this.refunds = count;
      this.refundsData = [];

      for(let i = 0; i < this.refunds.length; i++){
        this.refundsData.push({
          order: this.refunds[i].order,
          idPaymentRefund: this.refunds[i].idPaymentRefund,
          orderPaymentsId: this.refunds[i].orderPaymentsId,
          orderReference: this.refunds[i].orderReference,
          returnItemIdentifier: this.refunds[i].returnLines,
          returnSizeIndex: this.refunds[i].returnLines,
          amount: this.refunds[i].amount,
          paymentGateway: this.refunds[i].paymentGateway,
          customerName: this.refunds[i].customerName
        });
      }

      this.dataSource = new MatTableDataSource(this.refundsData);
      this.dataSource.paginator = this.paginator;
      this.selection = new SelectionModel(true, []);
    });
  }

  isAllSelected() {
    const numSelected = this.selection['selected'].length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  confirmDeletion():void{
    this.intermediaryService.presentConfirm("¿Está seguro que ha reembolsado manualmente los pedidos seleccionados?",()=>{
      this.deleteRefundsKo();
    });
  }

  async deleteRefundsKo(){
    let refunds = this.selection['selected'];
    let refundsBody;
    if(refunds.length > 0) {
      for (let i = 0; i < refunds.length; i++) {
        refundsBody = {
          id: refunds[i].idPaymentRefund,
          refundState: 'OK',
          orderPaymentsId: refunds[i].orderPaymentsId,
          charge: refunds[i].amount,
          paymentGateway: refunds[i].paymentGateway,
          orderId: refunds[i].order
        };
        await this.refundsService.updateRefunds(refundsBody).subscribe(count => {
          let refundsData = count;
          this.tableDataLoad();
          this.intermediaryService.presentToastSuccess("Reembolsos actualizados con éxito");
          this.modalController.dismiss(null);
        }, error => {
          this.intermediaryService.presentToastError("Error al actualizar los reembolsos");
          this.modalController.dismiss(null);
        });
      }
    }
  }

  public getOrderReference(list): string {
    return list;
  }
  public getReturnItemIdentifier(list): string {
    let items;
    for(let i = 0; i < list.length; i++){
      items = i > 0 ? items + '<br/>' + list[i].returnItemIdentifier : list[i].returnItemIdentifier;
    }
    return items;
  }
  public getReturnSizeIndex(list): string {
    let items;
    for(let i = 0; i < list.length; i++){
      items = i > 0 ? items + '<br/>' + list[i].returnSizeIndex : list[i].returnSizeIndex;
    }
    return items;
  }
  public getAmount(list): string {
    return list;
  }
  public getPaymentGateway(list): string {
    return list;
  }
  public getCustomerName(list): string {
    return list;
  }

}

import { Component, OnInit } from '@angular/core';
import {PickingProvider} from "../../../../services/src/providers/picking/picking.provider";
import {ModalController, NavParams} from "@ionic/angular";
import { IntermediaryService } from '@suite/services';
import {ShoesPickingService} from "../../../../services/src/lib/endpoint/shoes-picking/shoes-picking.service";

@Component({
  selector: 'list-destinies',
  templateUrl: './modal-destinies.component.html',
  styleUrls: ['./modal-destinies.component.scss']
})
export class ListDestiniesComponent implements OnInit {

  public title = 'Destinos';
  private picking;
  destinies;

  constructor(
    private modalController: ModalController,
    private pickingProvider: PickingProvider,
    private intermediaryService: IntermediaryService,
    private shoesPickingService: ShoesPickingService,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    this.picking = this.navParams.get('picking');
    this.destinies = this.picking.destinyWarehouse;
  }

  goToList() {
    this.modalController.dismiss();
  }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {CarrierModel} from "../../../../services/src/models/endpoints/Carrier";
import {ActionSheetController, LoadingController} from "@ionic/angular";
import {Router} from "@angular/router";
import {PrintTagsScanditService} from "../../../../services/src/lib/scandit/print-tags/print-tags.service";
import {CarriersService} from "../../../../services/src/lib/endpoint/carriers/carriers.service";
import {
  AuthenticationService,
  IntermediaryService,
  PriceModel,
  TypeModel,
  TypesService,
  WarehouseModel
} from '@suite/services';
import {PrinterService} from "../../../../services/src/lib/printer/printer.service";
import { PositionsToast } from '../../../../services/src/models/positionsToast.type';
import {ToolbarProvider} from "../../../../services/src/providers/toolbar/toolbar.provider";
import {PaginatorComponent} from "../../components/paginator/paginator.component";
import {TagsInputOption} from "../../components/tags-input/models/tags-input-option.model";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PrintRelabelPackingModule } from "../../print-relabel-packing/print-relabel-packing.module";

@Component({
  selector: 'list-packing-relabel',
  templateUrl: './list-packing.component.html',
  styleUrls: ['./list-packing.component.scss']
})
export class ListPackingRelabelTemplateComponent implements OnInit {

  public listCarriers: CarrierModel.Carrier[];
  public isLoadingData: boolean = true;
  private loading: any;

  private isStoreUser: boolean = false;
  private storeUserObj: WarehouseModel.Warehouse = null;
  pagerValues = [20, 50, 100];

  pauseListenFormChange = false;
  requestTimeout;

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  private carriersTypes: TypeModel.Type[] = [];
  filtersOpen: boolean;

  /**Filters */
  reference: Array<TagsInputOption> = [];
  status: Array<TagsInputOption> = [];
  groups: Array<TagsInputOption> = [];

  form: FormGroup = this.formBuilder.group({
    reference: [],
    status: [],
    warehouseId: 0,
    pagination: this.formBuilder.group({
      page: 1,
      limit: undefined
    }),
    orderby: this.formBuilder.group({
      type: 1,
      order: "desc"
    })
  });

  constructor(
    private router: Router,
    private actionSheetController: ActionSheetController,
    private intermediaryService: IntermediaryService,
    private loadingController: LoadingController,
    private authenticationService: AuthenticationService,
    private carriersService: CarriersService,
    private printTagsScanditService: PrintTagsScanditService,
    private printerService: PrinterService,
    private typesService: TypesService,
    private toolbarProvider: ToolbarProvider,
    private formBuilder: FormBuilder,
  ) {}

  async ngOnInit() {
    this.filtersOpen = false;
    this.initForm();

    this.isStoreUser = await this.authenticationService.isStoreUser();
    if (this.isStoreUser) {
      this.storeUserObj = await this.authenticationService.getStoreCurrentUser();
    }

    this.carriersTypes = this.typesService.listPacking;
    this.loadToolbarActions();
    this.listenChanges();
/*    this.loadCarriers();*/
  }

  ngAfterViewInit() {
    this.form.value.pagination.limit = this.paginator.finalPagerValues[0];
    this.listenChanges();
  }

  private loadToolbarActions() {
    let actionsToolbar = [];

    actionsToolbar = [
      {
        icon: 'funnel',
        label: 'Filtros',
        action: () => this.filtersOpen = !this.filtersOpen
      }];

    this.toolbarProvider.optionsActions.next(actionsToolbar);
  }


  initForm() {
    this.form.patchValue({
      reference: [],
      status: [],
      warehouseId: 0,
      orderby: this.formBuilder.group({
        type: 1,
        order: "desc"
      })
    });
    this.reference = [];
    this.status = [];
    this.getFilters();
  }

  private async loadCarriers() {
    this.isLoadingData = true;
    if (this.storeUserObj) {
      this.form.patchValue({
        warehouseId: this.storeUserObj.id
      });
    }

    this.carriersService
      .postListByWarehouse(this.form.value)
      .subscribe((res: any) => {
        this.isLoadingData = false;
        if (res.code === 200) {
            this.listCarriers = res.data.results;
            const paginator = res.data.pagination;
            this.groups = res.data.ordertypes;
            this.paginator.length = paginator.totalResults;
            this.paginator.pageIndex = paginator.selectPage;
            this.paginator.lastPage = paginator.lastPage;
        } else if (res.code !== 204) {
          console.error('Error::Subscribe::GetCarrierOfProduct::', res);
          let warehouseTypeName = 'de la tienda';
          if (!this.isStoreUser) {
            warehouseTypeName = 'del almacén';
          }
          this.intermediaryService.presentToastError(`Ha ocurrido un error al intentar consultar los recipientes ${warehouseTypeName}.`, PositionsToast.BOTTOM).then(() => {
            setTimeout(() => {
              document.getElementById('input-ta').focus();
            },500);
          });
        }
      }, (error) => {
        this.isLoadingData = false;

        console.error('Error::Subscribe::GetCarrierOfProduct::', error);

        if (error.error.code !== 204) {
          let warehouseTypeName = 'de la tienda';
          if (!this.isStoreUser) {
            warehouseTypeName = 'del almacén';
          }

          this.intermediaryService.presentToastError(`Ha ocurrido un error al intentar consultar los recipientes ${warehouseTypeName}.`, PositionsToast.BOTTOM).then(() => {
            setTimeout(() => {
              document.getElementById('input-ta').focus();
            },500);
          });
        }
      });
  }

  getFilters(): void {

    if (this.storeUserObj) {
      this.form.patchValue({
        warehouseId: this.storeUserObj.id
      });
    }
    this.carriersService.getFilters(this.form.value).subscribe((filters) => {
      this.reference = filters.reference;
      this.status = filters.status;
      this.status.forEach( item => {
        item.name = this.getStatus(parseInt(item.name));
      });
      this.applyFilters();
    }, (err) => {
      console.log(err);
    });
  }

  getStatus(request: number): string{
      switch (request) {
        case 1:
          return 'Vacío';
        case 2:
          return 'Cargando';
        case 3:
          return 'Enviado';
        case 4:
          return 'Precintado';
        case 5:
          return 'Enviar sin productos';
        default:
          return 'Vacío';
      }
  }

  listenChanges() {
    let previousPageSize = this.form.value.pagination.limit;
    /**detect changes in the paginator */
    this.paginator.page.subscribe(page => {
      /**true if only change the number of results */
      let flag = previousPageSize === page.pageSize;
      previousPageSize = page.pageSize;
      this.form.value.pagination = {
        limit: page.pageSize,
        page: flag ? page.pageIndex : 1
      };
      this.loadCarriers()
    });
  }

  applyFilters() {
    if (this.pauseListenFormChange) return;
    clearTimeout(this.requestTimeout);
    this.requestTimeout = setTimeout(async () => {
      this.form.value.pagination.page = 1;
      this.form.value.pagination.limit = this.paginator.cantSelect;
      await this.loadCarriers();
    }, 100);
  }

  clearFilters() {
    this.initForm();
  }

  public async relabelPacking() {
    let actionSheet = await this.actionSheetController.create({
      header: 'Contenido del recipiente',
      buttons: [
        {
          text: 'Recipiente con contenido',
          icon: 'square',
          handler: () => {
            this.actionSheetSelectTypeScan();
          }
        }, {
          text: 'Recipiente vacío',
          icon: 'square-outline',
          handler: () => {
            // Create new packing and print label
            this.actionSheetSelectCarrierType();
          }
        }
      ]
    });

    await actionSheet.present();
  }

  // ActionSheet with methods to scan product and print their carrier
  public async actionSheetSelectTypeScan() {
    let actionSheet = await this.actionSheetController.create({
      header: 'Tipo de comprobación',
      buttons: [
        {
          text: 'Escaneo de producto',
          icon: 'qr-scanner',
          handler: () => {
            // Scan product inside packing and get jail where it is to print label
            this.printTagsScanditService.printTagsPackings();
          }
        },
        {
          text: 'Introducción manual de producto',
          icon: 'create',
          handler: () => {
            // Set manually product reference to get carrier and print
            this.router.navigate(['print/packing/manual']);
          }
        }
      ]
    });

    await actionSheet.present();
  }

  // ActionSheet with type of carries to generate
  public async actionSheetSelectCarrierType() {
    let buttons = [];

    for (let carrierType of this.carriersTypes) {
      buttons.push({
        text: carrierType.name,
        icon: 'cube',
        handler: () => {
          this.showLoading('Generando recipiente y mandando a imprimir...');
          this.generateNewCarrier(carrierType);
        }
      });
    }

    let actionSheet = await this.actionSheetController.create({
      header: 'Tipo de recipiente',
      buttons: buttons
    });

    await actionSheet.present();
  }

  private generateNewCarrier(carrierType: TypeModel.Type) {
    this.carriersService
      .postGenerate({
        packingType: carrierType.id
      })
      .subscribe((res: CarrierModel.ResponseGenerate) => {
        this.loading.dismiss();
        if (res.code === 201) {
          this.printerService.print({text: [res.data.reference], type: 0});
        } else {
          console.error('Error::Subscribe::GetCarrierOfProduct::', res);
          let msgErrorByCarrierType = 'un nuevo recipiente';
          if (carrierType.id === 1) {
            msgErrorByCarrierType = 'una nueva jaula';
          } else if (carrierType.id === 2) {
            msgErrorByCarrierType = 'un nuevo pallet';
          }
          this.intermediaryService.presentToastError(`Ha ocurrido un error al intentar generar ${msgErrorByCarrierType}.`, PositionsToast.BOTTOM).then(() => {
            setTimeout(() => {
              document.getElementById('input-ta').focus();
            },500);
          });
        }
      }, (error) => {
        this.loading.dismiss();

        console.error('Error::Subscribe::GetCarrierOfProduct::', error);
        let msgErrorByCarrierType = 'un nuevo recipiente';
        if (carrierType.id === 1) {
          msgErrorByCarrierType = 'una nueva jaula';
        } else if (carrierType.id === 2) {
          msgErrorByCarrierType = 'un nuevo pallet';
        }
        this.intermediaryService.presentToastError(`Ha ocurrido un error al intentar generar ${msgErrorByCarrierType}.`, PositionsToast.BOTTOM).then(() => {
          setTimeout(() => {
            document.getElementById('input-ta').focus();
          },500);
        });
      });
  }

  private async showLoading(message: string) {
    this.loading = await this.loadingController.create({
      message: message,
      translucent: true,
    });
    return await this.loading.present();
  }

}

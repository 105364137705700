import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {LabelModel} from "../../../models/endpoints/Label";
import {HttpRequestModel} from "../../../models/endpoints/HttpRequest";
import {RequestsProvider} from "../../../providers/requests/requests.provider";
import {ExpeditionModel} from "../../../models/endpoints/Expedition";
import IExpeditionShippingPackageCode = ExpeditionModel.IExpeditionShippingPackageCode;


@Injectable({
  providedIn: 'root'
})
export class LabelsService {

  private _shippingPackageCodes :IExpeditionShippingPackageCode[] = [];

  /**urls of the service */
  private getIndexUrl = environment.apiBase+"/labels";
  private getStatusLabelsUrl = environment.apiBase+"/opl-expedition/";
  private postCheckShippingPackageCodesUrl = environment.apiBase+"/opl-expedition/tracking-package/check-codes";
  private postExpeditionListAlertsUrl = environment.apiBase+"/opl-expedition/alerts";
  private getExpeditionUnlockedPendingScanUrl = environment.apiBase+"/opl-expedition/check-expedition-pending-scan";
  private getPrintLabelByIdsUrl = environment.apiBase+"/opl-expedition/container/";
  private getTransportStatusUrl = environment.apiBase+"/opl-expedition/generate-tag/";
  private postServicePrintPackUrl = environment.apiBase+"/opl-expedition/service-print-pack/";
  private getExpeditionExtraDocsUrl = environment.apiBase+"/opl-expedition/print/extra-doc/";
  private filtersUrl = environment.apiBase+"/opl-expedition/alerts/filters";

  constructor(private http:HttpClient, private requestsProvider: RequestsProvider) { }

  /**
   * Get all labels from server
   * @returns observable with array of labels
   * @todo model of data, but first need know the model
   */
  getIndex():Observable<any>{
    return this.http.get(this.getIndexUrl).pipe(map((response:any)=>{
      return response.data
    }));
  }
  getIndexLabels():Observable<any>{
    return this.http.get(this.getStatusLabelsUrl).pipe(map((response:any)=>{
      return response.data
    }));
  }
  getFilters() {
    const body = {
      ids: [],
      dates: [],
      barcodes: [],
    };
    return this.http.post<HttpRequestModel.Response>(this.filtersUrl, body).pipe(
      map(resp => resp.data)
    )
  }
  getExpeditionByBarcode(body):Observable<any>{
    return this.http.post(this.getPrintLabelByIdsUrl,body).pipe(map((response:any)=>{
      return response.data
    }));
  }

  getTransportStatus(body):Observable<any>{
    return this.http.post(this.getTransportStatusUrl,body).pipe(map((response:any)=>{
      return response.data
    }));
  }

  getListAlertsExpedition(body: any):Observable<any>{
    return this.http.post(this.postExpeditionListAlertsUrl, body).pipe(map((response:any)=>{
      return response.data
    }));
  }

  postCheckShippingPackageCodes(body):Observable<any>{
    return this.http.post(this.postCheckShippingPackageCodesUrl,body).pipe(map((response:any)=>{
      return response.data;
    }));
  }

  postServicePrintPack(body):Observable<LabelModel.IExpeditionDocToPrint[]>{
    return this.http.post(this.postServicePrintPackUrl, body).pipe(map((response:any)=>{
      return response.data
    }));
  }

  getExpeditionUnlockedPendingScan(): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.get(this.getExpeditionUnlockedPendingScanUrl);
  }

  getExpeditionExtraDocs(expeditionId: number): Observable<LabelModel.IExpeditionDocToPrint[]> {
    const url = this.getExpeditionExtraDocsUrl + expeditionId;
    return this.http.get(url).pipe(map((response: any) => {
      return response.data
    }));
  }

  get shippingPackageCodes(): ExpeditionModel.IExpeditionShippingPackageCode[] {
    return this._shippingPackageCodes;
  }

  set shippingPackageCodes(value: ExpeditionModel.IExpeditionShippingPackageCode[]) {
    this._shippingPackageCodes = value;
  }

  isAllShippingPackageCodesChecked(): boolean {
    return !this.shippingPackageCodes.find(c => !c.checked);
  }

  checkUniqueCodeInShippingPackageCodes(uniqueCode: string): boolean {
    const found = this.shippingPackageCodes.find(c => c.uniqueCode == uniqueCode);
    if (found) {
      found.checked = true;
      return true;
    }
    return false;
  }

  resetCheckedShippingPackageCodes(): void {
    this.shippingPackageCodes = this.shippingPackageCodes.map((c) => {
      c.checked = false;
      return c;
    });
  }

  getNumShippingPackageCodesChecked(): number {
    return this.shippingPackageCodes ? this.shippingPackageCodes.filter(s => s.checked).length : 0;
  }

  getNumShippingPackageCodes(): number {
    return this.shippingPackageCodes ? this.shippingPackageCodes.length : 0;
  }
}

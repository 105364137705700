import { IonicModule } from '@ionic/angular';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import {MatTableModule} from '@angular/material';
import { TransportManifestComponent } from './transport-manifest.component';
import {TagsInputModule} from "../components/tags-input/tags-input.module";
import {PaginatorComponentModule} from "../components/paginator/paginator.component.module";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IncidenceCodeScanComponent} from "./incidence-code-scan/incidence-code-scan.component";
import {IncidenceCodeScanModule} from "./incidence-code-scan/incidence-code-scan.module";

const routes: Routes = [
  {
    path: '',
    component: TransportManifestComponent
  }
  ,{
    path: 'change/:id/:code',
    component: IncidenceCodeScanComponent
  },
  {
    path: 'scan/:id/:code',
    component: IncidenceCodeScanComponent
  }
];

@NgModule({
  declarations: [TransportManifestComponent],
  entryComponents: [TransportManifestComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forChild(routes),
    MatListModule,
    MatTableModule,
    PaginatorComponentModule,
    TagsInputModule,
    FormsModule,
    ReactiveFormsModule,
    IncidenceCodeScanModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TransportManifestModule { }

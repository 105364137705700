import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { IntermediaryService } from '../../../services/src/lib/endpoint/intermediary/intermediary.service';
import { OplTransportsService } from '../../../services/src/lib/endpoint/opl-transports/opl-transports.service';
import { OplTransportsModel } from '../../../services/src/models/endpoints/opl-transports.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TagsInputOption } from '../components/tags-input/models/tags-input-option.model';
import {ToolbarProvider} from "../../../services/src/providers/toolbar/toolbar.provider";
import {FiltersModel} from "../../../services/src/models/endpoints/filters";
import {PackagesComponent} from "../transport-order-package/packages/packages.component";
import {ExpeditionManifestsModel} from "../../../services/src/models/endpoints/Expeditions/Manifests";
import {PrinterServiceModel} from "../../../services/src/models/endpoints/PrinterService";
import {PositionsToast} from "../../../services/src/models/positionsToast.type";
import {TimesToastType} from "../../../services/src/models/timesToastType";
import {environment} from "@suite/services";
import {PrinterServiceService} from "../../../services/src/lib/endpoint/printer-service/printer-service.service";
import {DownloaderService} from "../../../services/src/lib/downloader/downloader.service";
import {Downloader, DownloadRequest, NotificationVisibility} from "@ionic-native/downloader/ngx";
import {config} from "../../../services/src/config/config";
import { PaginatorComponent } from '../components/paginator/paginator.component';

@Component({
  selector: 'suite-transport-order-package',
  templateUrl: './transport-order-package.component.html',
  styleUrls: ['./transport-order-package.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TransportOrderPackageComponent implements OnInit {

  @Input('id') id: number;
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  filters: OplTransportsModel.OrderExpeditionFilters;

  displayedColumns: string[] = [
    'id',
    'date',
    'print',
    'package',
  ];
  pagerValues = [20, 80, 100];
  pauseListenFormChange: boolean;
  selection = new SelectionModel<any>(true, []);
  form: FormGroup = this.formBuilder.group({
    expeditions: [[]],
    orders: [[]],
    dates: [[]],
    warehouses: [[]],
    transports: [[]],
    groups:[[]],
    orderby: this.formBuilder.group({
      type: 1,
      order: "desc"
    }),
    pagination: this.formBuilder.group({
      page: 1,
      limit: 20
    }),
    isAl: true
  });

  public showFiltersMobileVersion: boolean = false;

  columns: any;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  expandedElement: any;
  isFilteringWarehouse: number = 0;
  lastUsedFilter: string;

  orders: Array<TagsInputOption> = [];
  warehouses: Array<TagsInputOption> = [];
  dates: Array<TagsInputOption> = [];
  groups: Array<TagsInputOption> = [];
  listManifests: OplTransportsModel.ResultsLoadOrdersWithManifestsAndPackage[] = [];
  listPackages: any = [];

  constructor(
    private intermediaryService: IntermediaryService,
    private oplTransportsService: OplTransportsService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private activateRoute: ActivatedRoute,
    private toolbarProvider: ToolbarProvider,
    private printerServiceService: PrinterServiceService,
    private downloaderService: DownloaderService,
    private downloader: Downloader
  ) {}

  ngOnInit() {
    const transportId: number = parseInt(this.activateRoute.snapshot.paramMap.get('id'));
    this.initForm();
    this.form.patchValue({
      transports: [transportId]
    });
    this.getFilters();
  }

  async ngAfterViewInit() {
    this.form.value.pagination.limit = 20;
    this.getList(this.form.value);
    this.listenChanges();
  }

  listenChanges(): void {
    let previousPageSize = this.form.value.pagination.limit;
    /**detect changes in the paginator */
    this.paginator.page.subscribe(page => {
      /**true if only change the number of results */
      let flag = previousPageSize == page.pageSize;
      previousPageSize = page.pageSize;
      this.form.value.pagination.limit = page.pageSize;
      this.form.value.pagination.page = flag?page.pageIndex:1;
      this.getList(this.form.value);
    });
  }

  initForm() {
    this.form.patchValue({
      expeditions: [],
      orders: [],
      dates: [],
      warehouses: [],
      transports: [],
      isAl: true,
    })
  }

  openFiltersMobile() {
    this.showFiltersMobileVersion = !this.showFiltersMobileVersion;
  }

  ionViewWillEnter() {
    this.toolbarProvider.optionsActions.next([
      {
        icon: 'funnel',
        label: 'Filtros',
        action: () => this.showFiltersMobileVersion = !this.showFiltersMobileVersion
      }
    ]);
  }

  getFilters() {
    this.oplTransportsService.getFilters().subscribe((entities: any) => {
      this.warehouses = this.updateFilterSource(entities.warehouses,'warehouses');
      this.orders = this.updateFilterSource(entities.orders,'orders');
      this.dates = this.updateFilterSource(entities.dates,'dates');
      this.groups = this.updateFilterSource(entities.orderTypes,'groups');
    });
  }

  private updateFilterSource(dataEntity: FiltersModel.Default[], entityName: string) {
    let resultEntity;

    this.pauseListenFormChange = true;
    let dataValue = this.form.get(entityName).value;

    resultEntity = dataEntity ? dataEntity.map(entity => {
      if(entity.name == "Order"){
        entity.name = 'Nº Manifiesto';
      }else if(entity.name == "Date"){
        entity.name = 'Fecha';
      }
      entity.id = <number>(<unknown>entity.id);
      entity.name = entity.name.toString();
      entity.value = entity.name;
      entity.checked = true;
      entity.hide = false;
      return entity;
    }) : [];

    if (dataValue && dataValue.length) {
      this.form.get(entityName).patchValue(dataValue, { emitEvent: false });
    }

    setTimeout(() => { this.pauseListenFormChange = false; }, 0);

    return resultEntity;
  }

  private getFormValueCopy() {
    return JSON.parse(JSON.stringify(this.form.value || {}));
  }

  sanitize(object){
    /**mejorable */
    object = JSON.parse(JSON.stringify(object));
    if(!object.orderby.type){
      delete object.orderby.type;
    }else{
      object.orderby.type = parseInt(object.orderby.type);
    }
    if(!object.orderby.order)
      delete object.orderby.order;
    Object.keys(object).forEach(key=>{
      if(object[key] instanceof Array){
        if(object[key][0] instanceof Array){
          object[key] = object[key][0];
        } else {
          for(let i = 0;i<object[key].length;i++) {
            if(object[key][i] === null || object[key][i] === "") {
              object[key].splice(i,1);
            }
          }
        }
      }
      if (object[key] === null || object[key] === "") {
        delete object[key];
      }
    });
    return object;
  }

  applyFilters() {
    this.openFiltersMobile();
    this.form.value.pagination.page = 1;
    this.form.value.pagination.limit = 20;
    this.getList(this.sanitize(this.getFormValueCopy()));
  }

  async getList(body: OplTransportsModel.OrderExpeditionFilterRequest) {
    await this.intermediaryService.presentLoadingNew();
    this.oplTransportsService
      .postLoadOrdersWithManifestsAndPackages(body)
      .subscribe(resp => {
        this.listManifests = resp.results;

        let paginator = resp.pagination;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage > 0 ? paginator.lastPage : 1;
      }, () => {
        this.intermediaryService.presentToastError('Ocurrio un error al cargar el listado');
        this.intermediaryService.dismissLoadingNew();
      }, () => {
        this.intermediaryService.dismissLoadingNew();
      });
  }

  clearFilters(transportId: number) {
    this.form = this.formBuilder.group({
      expeditions: [[]],
      orders: [[]],
      dates: [[]],
      warehouses: [[]],
      transports: [[]],
      groups:[[]],
      orderby: this.formBuilder.group({
        type: 1,
        order: "desc"
      }),
      pagination: this.formBuilder.group({
        page: 1,
        limit: 20
      }),
      isAl: true
    });
    this.form.patchValue({
      transports: [transportId]
    })
  }

  formatDate(data: string): string {
    const date = new Date(data);
    const day = date.getDate()
    let month: any = date.getMonth() + 1
    if (month < 10) {
      month = `0${month}`
    }
    const year = date.getFullYear()
    const formatedDate = `${day}-${month}-${year}`
    return formatedDate
  }

  async print(id) {
    await this.intermediaryService.presentLoadingNew();
    await this.printerServiceService.getPrinterServiceAddress();
    this.oplTransportsService.getManifestInfo(id).subscribe((manifestDoc: ExpeditionManifestsModel.ResponseDownloadAL) => {
      const bodyPrintBase64: PrinterServiceModel.ParamsPrintBase64 = {
        documentBase64: manifestDoc.document,
        documentType: manifestDoc.type
      };
      this.printerServiceService.postPrintBase64(bodyPrintBase64).subscribe(res => {
        console.debug('PrinterService::Item printed correctly: ', typeof res != 'string' ? JSON.stringify(res) : res);
      }, error => {
        console.error('PrinterService::ERROR to print item: ', JSON.stringify(error));
        this.intermediaryService.presentToastError("Ha ocurrido un error al intentar conectar con el servicio de impresión. Compruebe que la dirección configurada en 'Configuración > Servicio de impresión' es la correcta.", PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST_LONG);
      });

      const urlDownload = environment.downloadPdf + '/' + manifestDoc.externalPath;
      const fileName = this.downloaderService.getExpeditionManifestDownloadedFileName(manifestDoc.extension);
      this.downloadUrl(urlDownload, fileName);
      this.intermediaryService.dismissLoadingNew();
    }, (error) => {
      let errorMessage = 'Ha ocurrido un error al intentar imprimir el documento de manifiesto.';
      if (error && error.error && error.error.errors) {
        errorMessage = error.error.errors;
      }
      this.intermediaryService.dismissLoadingNew();
      this.intermediaryService.presentToastError(errorMessage);
    });
  }

  refresh(transportId: number) {
    this.clearFilters(transportId);
    this.form.patchValue({
      transports: [transportId]
    });
    this.getList(this.form.value)
  }

  async presentModal(packages) {
    let expeditionIds = [];
    packages.forEach(p => {
      expeditionIds.push(p.expeditionId);
    });
    let body = { expeditionIds: expeditionIds };

    await this.intermediaryService.presentLoadingNew();
    this.oplTransportsService
      .postLoadOrderManifestWithPackages(body)
      .subscribe(async resp => {
        this.listPackages = resp;

        let itemPackages = [];
        packages.forEach(p => {
          this.listPackages.forEach(item => {
            if(p.containerId == item.containerId){
              itemPackages.push(item);
            }
          });
        });

        packages = itemPackages;
        const modal = await this.modalController.create({
          component: PackagesComponent,
          componentProps: { packages }
        });

        await modal.present();
      }, () => {
        this.intermediaryService.presentToastError('Ocurrio un error al cargar el listado');
        this.intermediaryService.dismissLoadingNew();
      }, () => {
        this.intermediaryService.dismissLoadingNew();
      });
  }

  //region Download of documents
  private async downloadUrl(urlDownload, fileName){
    let request = this.configNotificationDownload(urlDownload,fileName);
    console.log("DOWNLOAD::request", request);

    await this.downloader.download(request).then((location: string) =>{
      console.log("DOWNLOAD::SUCCESS location", location);
      this.intermediaryService.dismissLoading();
      this.intermediaryService.presentToastSuccess("¡Descarga finalizada!");
    }).catch((error: any) => {
      console.log("DOWNLOAD::ERROR", error);
      this.intermediaryService.dismissLoading();
      this.intermediaryService.presentToastError("¡Ha ocurrido un error al descargar el archivo!");
    });
  }

  private configNotificationDownload(url,name): DownloadRequest {
    const downloadsDirectory = `${config.downloads.directoryBase}/${config.downloads.directoryAL}/${config.downloads.directoryManifests}`;
    return {
      uri: url,
      title: 'Documento de manifiesto',
      description: '',
      mimeType: '',
      visibleInDownloadsUi: true,
      notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
      destinationInExternalPublicDir: {
        dirType: downloadsDirectory,
        subPath: name
      }
    }
  }
  //endregion
}

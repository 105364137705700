import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ModalController, NavParams} from "@ionic/angular";
import { IntermediaryService } from '../../../../services/src/lib/endpoint/intermediary/intermediary.service';
import {HiringDhlService} from "../../../../services/src/lib/endpoint/hiring-dhl/hiring-dhl.service";
import { UtilsComponent } from '../../components/utils/utils.component';

@Component({
  selector: 'suite-update-hiring-dhl',
  templateUrl: './update-hiring-dhl.component.html',
  styleUrls: ['./update-hiring-dhl.component.scss']
})
export class UpdateHiringDhlComponent implements OnInit {
  title = 'Editar Contratación de DHL';
  redirectTo = '/update-hiring-dhl';
  elementHiring;
  referenceWarehouseSelected;
  nameWarehouseSelected;
  userIdSelected;
  keyDhlSelected;
  accountIdSelected;
  dataFlag = false;

  @ViewChild(UtilsComponent) utils: UtilsComponent;
  updateForm: FormGroup = this.formBuilder.group({
    reference: [''],
    name: [''],
    userId: [''],
    keyDhl: [''],
    accountId: ['']
  });

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private intermediaryService: IntermediaryService,
    private formBuilder: FormBuilder,
    private hiringDhlService: HiringDhlService,
  ) {}

  ngOnInit() {
    this.elementHiring = this.navParams.get('element');
    this.referenceWarehouseSelected = this.elementHiring.warehouseReference;
    this.nameWarehouseSelected = this.elementHiring.nameWarehouse;
    this.userIdSelected = this.elementHiring.userId;
    this.keyDhlSelected = this.elementHiring.keyDhl;
    this.accountIdSelected = this.elementHiring.accountId;
    // if the fields to be edited are set to null, they cannot be deleted from the database
    if(this.elementHiring.userId == null && this.elementHiring.keyDhl == null && this.elementHiring.accountId == null){
      this.dataFlag = true;
    }
  }

  updateWarehouseDhl(){
    let dataBody = {
      "id": this.elementHiring.idWarehouse,
      "warehouseReference": this.elementHiring.warehouseReference,
      "userId": this.userIdSelected,
      "keyDhl": this.keyDhlSelected,
      "accountId": this.accountIdSelected
    };

    if(dataBody.userId == "" && dataBody.keyDhl == "" && dataBody.accountId == "" && !this.dataFlag){
      this.intermediaryService.presentConfirm("¿Está seguro que quiere eliminar los datos?",()=>{
        this.hiringDhlService.deleteWarehouseDhl(dataBody.id).subscribe(() => {
          this.intermediaryService.presentToastSuccess("Datos eliminados con éxito");
          this.modalController.dismiss(null);
        }, error => {
          this.intermediaryService.presentToastError("Error al eliminar los datos");
          this.modalController.dismiss(null);
        });
      });
    }else if(dataBody.userId == "" || dataBody.keyDhl == "" || dataBody.accountId == "" ||
      dataBody.userId == null || dataBody.keyDhl == null || dataBody.accountId == null){
      this.intermediaryService.presentToastError("Debe rellenar todos los campos");
    }else{
      this.hiringDhlService.updateWarehouseDhl(dataBody).subscribe(() => {
        this.intermediaryService.presentToastSuccess("Datos guardados con éxito");
        this.modalController.dismiss(null);
      }, error => {
        this.intermediaryService.presentToastError("Error al guardar los datos");
        this.modalController.dismiss(null);
      });
    }
  }

  close():void{
    this.modalController.dismiss();
  }

  cleanInputs(){
    this.userIdSelected = '';
    this.keyDhlSelected = '';
    this.accountIdSelected = '';
  }

}

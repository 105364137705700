import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  environment,
  IncidentsService,
  IntermediaryService,
  InventoryService,
  ProductsService,
  TypesService,
  UploadFilesService,
  WarehouseService
} from '@suite/services';
import { PrinterService } from "../../../../../services/src/lib/printer/printer.service";
import { AlertController, LoadingController, ModalController, NavParams, PopoverController } from "@ionic/angular";
import { DefectiveRegistryService } from '../../../../../services/src/lib/endpoint/defective-registry/defective-registry.service';
import { DamagedModel } from '../../../../../services/src/models/endpoints/Damaged';
import { SelectScrollbarComponent } from "../../../defect-handler/details-register/select-scrollbar/select-scrollbar.component";
import { SelectScrollbarProvider } from "../../../../../services/src/providers/select-scrollbar/select-scrollbar.provider";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DefectiveManagementService } from "../../../../../services/src/lib/endpoint/defective-management/defective-management.service";
import { DefectiveZonesService } from "../../../../../services/src/lib/endpoint/defective-zones/defective-zones.service";
import { SignatureComponent } from "../../../signature/signature.component";
import { DropFilesComponent } from "../../../drop-files/drop-files.component";
import { ReviewImagesComponent } from "../../../incidents/components/review-images/review-images.component";
import { DropFilesService } from "../../../../../services/src/lib/endpoint/drop-files/drop-files.service";
import * as moment from 'moment';
import { PrintTicketService } from "../../../../../services/src/lib/print-ticket/print-ticket.service";
import { formatDate } from "@angular/common";
import Status = DamagedModel.Status;
import Classifications = DamagedModel.Classifications;

@Component({
  selector: 'suite-registry-details',
  templateUrl: './registry-details.component.html',
  styleUrls: ['./registry-details.component.scss']
})
export class RegistryDetailsComponent implements OnInit, OnDestroy {
  id: number;
  isHistory: boolean;
  section = 'information';
  title = 'Ubicación ';
  productId: string;
  registry: any = {};
  registryHistorical;
  showChangeState = false;
  date: any;
  container = null;
  warehouseId: number;
  listProducts: any[] = [];
  loading = null;

  actionTypes = {};
  listWarehouses: any[] = [];
  listHalls: any[] = [];
  listHallsOriginal: any = {};
  listRows: any[] = [];
  listRowsOriginal: any = {};
  listColumns: any[] = [];
  listColumnsOriginal: any = {};
  listReferences: any = {};
  warehouseSelected: number;
  columnSelected: number;
  dates: any[] = [];

  statusList: Status[] = [];
  defectTypes: any[] = [];
  zones: any[] = [];
  requireContact: boolean;
  incidenceForm: FormGroup;
  defectTypeP: any;
  defectTypeC: any;
  defectZoneP: any;
  defectZoneC: any;
  defectChildId;
  defectParentId;
  defects: any = [];
  defectChildsOfParent: any = [];
  defectZonesChildsOfParent: any = [];
  defectZoneChildId;
  defectZoneParentId;
  passHistory: boolean;
  requirePhoto: boolean;
  requireOk: boolean;
  defectStatus: any;
  managementId;

  signatureList: boolean = false;
  signature: any = null;

  displayPhotoList: boolean = false;
  photoList: boolean = false;
  photos: Array<any> = [];

  private baseUrlPhoto = environment.apiBasePhoto;
  private ticketEmit: boolean;
  private txtName: string = ""
  private txtInfo: string = "";
  private selectGestionState: boolean = false;
  private form: FormGroup = this.formBuilder.group({
    product: [],
    model: [],
    size: [],
    color: [],
    brand: [],
    dateDetection: [],
    statusManagementDefect: [],
    defectTypeParent: [],
    defectTypeChild: [],
    warehouse: [],
    pagination: this.formBuilder.group({
      page: 1,
      limit: 10
    }),
    orderby: this.formBuilder.group({
      type: 1,
      order: "asc"
    })
  });


  constructor(
    private typeService: TypesService,
    private defectiveRegistryService: DefectiveRegistryService,
    private modalController: ModalController,
    private navParams: NavParams,
    private printerService: PrinterService,
    private warehouseService: WarehouseService,
    private alertController: AlertController,
    private inventoryService: InventoryService,
    private loadingController: LoadingController,
    private incidentsService: IncidentsService,
    private productsService: ProductsService,
    private popoverController: PopoverController,
    private selectScrollbarProvider: SelectScrollbarProvider,
    private formBuilder: FormBuilder,
    private defectiveManagementService: DefectiveManagementService,
    private defectiveZonesService: DefectiveZonesService,
    private intermediary: IntermediaryService,
    private uploadService: UploadFilesService,
    private dropFilesService: DropFilesService,
    private printTicketService: PrintTicketService
  ) {
    this.id = this.navParams.get("id");
    this.isHistory = this.navParams.get("history");
    this.productId = this.navParams.get("productId");
    this.showChangeState = this.navParams.get("showChangeState");
  }

  async ngOnInit() {
    this.container = this.navParams.data.container;
    this.warehouseId = this.navParams.data.warehouseId;
    this.listWarehouses = this.warehouseService.listWarehouses;
    this.listHallsOriginal = this.warehouseService.listHalls;
    this.listRowsOriginal = this.warehouseService.listRows;
    this.listColumnsOriginal = this.warehouseService.listColumns;
    this.listReferences = this.warehouseService.listReferences;
    this.warehouseSelected = null;

    this.initializeForms();

    this.getRegistryDetail();
    this.getRegistryHistorical();
    this.getActionTypes();
    this.getSelectorOptions();

    this.getPhotoList();
    this.initializeSignaturesSubscription();
  }

  private initializeForms() {
    this.form.patchValue({
      product: [],
      model: [],
      size: [],
      color: [],
      brand: [],
      dateDetection: [],
      statusManagementDefect: [],
      defectTypeParent: [],
      defectTypeChild: [],
      warehouse: [],
      orderby: this.formBuilder.group({
        type: 1,
        order: "asc"
      })
    });

    this.incidenceForm = this.formBuilder.group({
      id: [0],
      productId: [0],
      productReference: [""],
      dateDetection: [""],
      observations: [""],
      factoryReturn: [false],
      statusManagementDefectId: [0],
      defectTypeParentId: [0],
      defectTypeChildId: [0],
      defectZoneParentId: [0],
      defectZoneChildId: [0],
      signFileId: [0],
      contact: this.formBuilder.group({
        name: [''],
        info: [''],
      })

    })
  }

  getRegistryDetail(): void {
    this.defectiveRegistryService.getDataDefect({ id: this.id, isHistory: this.isHistory }).subscribe(lastHistorical => {
      this.loadRegistry(lastHistorical.data);
    });
  }

  private loadRegistry(data: any) {
    this.registry = data;
    this.loadDefectInfo();
    this.loadFormValues();
    this.loadRequiredOptions(this.registry.statusManagementDefect);
  }

  private loadDefectInfo() {
    if (this.registry.defectTypeParent) {
      this.defectTypeP = this.registry.defectTypeParent;
      this.defectParentId = this.registry.defectTypeParent.id;
      this.defectChildId = this.registry.defectTypeChild && this.registry.defectTypeChild.id ? this.registry.defectTypeChild.id : 0;
      this.defectTypeC = this.registry.defectTypeChild ? this.registry.defectTypeChild : null;
      this.defectiveManagementService.getShow(this.defectParentId).subscribe(data => {
        this.defectChildsOfParent = data.defectTypeChild;
      });
    }
    if (this.registry.defectZoneParent) {
      this.defectZoneP = this.registry.defectZoneParent;
      this.defectZoneParentId = this.registry.defectZoneParent.id;
      this.defectZoneChildId = this.registry.defectZoneChild && this.registry.defectZoneChild.id ? this.registry.defectZoneChild.id : 0;
      this.defectZoneC = this.registry.defectZoneChild ? this.registry.defectZoneChild : null;
      this.defectiveZonesService.getShow(this.defectZoneParentId).subscribe(data => {
        this.defectZonesChildsOfParent = data.defectZoneChild;
      });
    }
   
    this.date = formatDate(this.registry.dateDetection, 'dd/MM/yyyy', 'es');
    this.date = moment().format("YYYY-MM-DD");
  }

  private loadFormValues() {
    this.incidenceForm = this.formBuilder.group({
      id: [this.registry.id],
      productId: [this.registry.product.id],
      productReference: [this.registry.product.reference],
      dateDetection: [this.date],
      observations: [this.registry.observations],
      factoryReturn: [false],
      statusManagementDefectId: this.registry && this.registry && this.registry.statusManagementDefect ? [this.registry.statusManagementDefect.id] : [0],
      defectTypeParentId: this.registry && this.registry.defectTypeParent ? [this.registry.defectTypeParent.id] : [0],
      defectTypeChildId: this.registry && this.registry.defectTypeChild ? [this.registry.defectTypeChild.id] : [0],
      defectZoneParentId: this.registry && this.registry.defectZoneParent ? [this.registry.defectZoneParent.id] : [0],
      defectZoneChildId: this.registry && this.registry.defectZoneChild ? [this.registry.defectZoneChild.id] : [0],
      signFileId: [0],
      contact: this.formBuilder.group({
        name: this.registry && this.registry.contact && this.registry.contact.name ? [this.registry.contact.name] : [''],
        info: this.registry && this.registry.contact && this.registry.contact.info ? [this.registry.contact.info] : [''],
      })
    });
    this.txtName = this.registry && this.registry.contact && this.registry.contact.name ? this.registry.contact.name : "";
    this.txtInfo = this.registry && this.registry.contact && this.registry.contact.info ? this.registry.contact.info : "";
  }

  private loadRequiredOptions(data: Classifications) {
    this.defectStatus = data;
    if (data !== undefined) {
      this.ticketEmit = data.ticketEmit;
      this.passHistory = data.passHistory;
      this.requirePhoto = data.requirePhoto
      this.requireContact = data.requireContact;
      this.requireOk = data.requireOk;
      this.managementId = data.id;
    } else {
      this.ticketEmit = false;
      this.passHistory = false;
      this.requirePhoto = false;
      this.requireContact = false;
      this.requireOk = false;
    }
  }

  getRegistryHistorical(): void {
    this.defectiveRegistryService.getHistorical({ id: this.id, isHistory: this.isHistory }).subscribe(historical => {
      this.registryHistorical = historical;
    });
  }

  getActionTypes(): void {
    this.typeService.getTypeActions().subscribe(ActionTypes => {
      ActionTypes.forEach(actionType => {
        this.actionTypes[actionType.id] = actionType.name
      })
    })
  }

  private getSelectorOptions() {
    this.getStatusList();
    this.getDefectTypes();
    this.getDefectZones();
  }

  private getStatusList() {
    this.incidentsService.getStatusManagamentDefect().subscribe(resp => {
      this.statusList = resp ? resp.classifications : [];
    })
  }

  private getDefectTypes(): void {
    this.incidentsService.getDefectTypesParent().subscribe(resp => {
      this.defectTypes = resp;
    })
  }

  private getDefectZones(): void {
    this.incidentsService.getDefectZonesParent().subscribe(resp => {
      this.zones = resp;
    })
  }

  private initializeSignaturesSubscription() {
    this.uploadService.signatureEventAsign().subscribe(resp => {

      if (resp) {
        this.intermediary.presentLoading()
        if (this.signature) {

          this.uploadService.deleteFile(this.signature.id).subscribe(
            () => {
              this.intermediary.presentToastSuccess('Imagen anterior eliminada exitosamente')
            },
            err => {
              this.intermediary.dismissLoading()
            },
            () => {
              this.intermediary.dismissLoading()
            }
          )
        }
      }
      this.signature = resp;
      if (this.signature && !this.signature.pathIcon.includes(environment.apiBasePhoto)) {
        this.signature.pathMedium = `${environment.apiBasePhoto}${this.signature.pathMedium}`;
        this.signature.pathIcon = `${environment.apiBasePhoto}${this.signature.pathIcon}`;
      }
      if (!this.signatureList) {
        this.openSignatureList()
      }
    });
  }

  private getPhotoList() {
    this.dropFilesService.getImage().subscribe(resp => {
      if (resp) {
        this.photos.push(resp)
        this.displayPhotoList = true;
        this.photoList = true;
      }
    });
  }

  getRequireStatus(defectStatus, statusName: string) {
    if (defectStatus && statusName) {
      switch (statusName) {
        case 'contact':
          return defectStatus.requireContact;
        case 'history':
          return defectStatus.passHistory;
        case 'photo':
          return defectStatus.requirePhoto;
        case 'signature':
          return defectStatus.requireOk;
        case 'ticket':
          return defectStatus.ticketEmit;
        case 'orders':
          return defectStatus.allowOrders;
      }
    }
  }

  async close() {
    if (this.isStatusUpdated()) {
      this.presentModelConfirmClose()
    } else {
      this.exit()
    }
  }

  async presentModelConfirmClose() {
    let buttonsAlert: any = [{
      text: "Salir sin guardar",
      handler: async () => {
        this.exit()
      }
    }, {
      text: "No",
      handler: () => {

      }
    }];

    const alert = await this.alertController.create({
      header: "Salir sin guardar",
      message: "¿Está seguro de que desea salir de la operación sin guardar los cambios?",
      buttons: buttonsAlert
    });
    alert.onDidDismiss().then((data) => {
      if (!data) {

      }
    });
    return await alert.present();
  }

  async exit() {
    if (!this.isHistory) {
      this.defectiveRegistryService.setRefreshList(true);
    }
    await this.modalController.dismiss();
  }

  openDefectStatusSelector() {
    this.createSelectPopover(this.statusList, (selectedStatus) => {
      if (selectedStatus && selectedStatus.data) {
        this.defectStatus = selectedStatus.data;
        this.loadRequiredOptions(this.defectStatus);
        this.selectGestionState = true;
      }
    });
  }

  openDefectTypesSelector() {
    this.createSelectPopover(this.defectTypes, (defectType) => {
      if (defectType && defectType.data) {
        this.defectTypeP = defectType.data;
        this.defectTypeC = null;
        this.defectParentId = this.defectTypeP.id;
        this.defectChildsOfParent = this.defectTypeP.defectTypeChild ? this.defectTypeP.defectTypeChild : [];
        this.defectChildId = 0;
      }
    });
  }

  openSubDefectTypesSelector() {
    this.createSelectPopover(this.defectChildsOfParent, (subDefectType) => {
      if (subDefectType && subDefectType.data) {
        this.defectTypeC = subDefectType.data;
        this.defectChildId = this.defectTypeC.id;
      }
    });
  }

  openZonesSelector() {
    this.createSelectPopover(this.zones, (zone) => {
      if (zone && zone.data) {
        this.defectZoneP = zone.data;
        this.defectZoneParentId = this.defectZoneP.id;
        this.defectZonesChildsOfParent = this.defectZoneP.defectZoneChild ? this.defectZoneP.defectZoneChild : [];
        this.defectZoneChildId = 0;
      }
    });
  }

  openSubZonesSelector() {
    this.createSelectPopover(this.defectZonesChildsOfParent, (subZone) => {
      if (subZone && subZone.data) {
        this.defectZoneC = subZone.data;
        this.defectZoneChildId = this.defectZoneC.id;
      }
    });
  }

  private async createSelectPopover(optionsList: any[], handler) {
    // inyectar este puntero mediante un servicio es peligroso
    this.selectScrollbarProvider.allOptions = optionsList;
    let popover = await this.popoverController.create({
      cssClass: 'select-scrollbar',
      component: SelectScrollbarComponent,
    });

    popover.onDidDismiss().then(handler);

    await popover.present();
  }

  isStatusUpdated(): boolean {
    return (this.registry.statusManagementDefect && this.defectStatus && this.registry.statusManagementDefect.id != this.defectStatus.id) || this.registry.noVerified;
  }

  onKeyName(event) {
    this.txtName = event.target.value;
  }

  onKeyInfo(event) {
    this.txtInfo = event.target.value;
  }

  async searchPhoto() {
    const modal = await this.modalController.create({
      component: DropFilesComponent,
      componentProps: { sectionOrigin: 'defects' }
    });
    await modal.present();
  }

  openPhotoList() {
    this.photoList = !this.photoList;
  }

  showPhotoList() {
    this.displayPhotoList = !this.displayPhotoList;
  }

  deleteImage(item, index, arr) {
    this.intermediary.presentLoading();
    this.uploadService.deleteFile(item.id).subscribe(
      resp => {
        this.intermediary.presentToastSuccess('Archivo borrado exitosamente')
        arr.splice(index, 1);
        if (this.photos.length === 0) {
          this.openPhotoList()
        }
      },
      err => {
        this.intermediary.presentToastError('Ocurrio un error al borrar el archivo')
        this.intermediary.dismissLoading()
      },
      () => {
        this.intermediary.dismissLoading()
      }
    )
  }

  async signModal() {
    const modal = await this.modalController.create({
      component: SignatureComponent,
    });

    await modal.present();
  }

  openSignatureList() {
    this.signatureList = !this.signatureList

  }

  async onOpenReviewImage(item) {
    const modal = await this.modalController.create({
      component: ReviewImagesComponent,
      componentProps: { imgSrc: item }
    });

    await modal.present();

  }

  deleteSignature(item) {
    this.intermediary.presentLoading()
    this.uploadService.deleteFile(item.id).subscribe(
      resp => {
        this.intermediary.presentToastSuccess('Archivo borrado exitosamente')
        this.uploadService.setSignature(null);
        this.signature = null;
        this.signatureList = false;
      },
      err => {
        this.intermediary.presentToastError('Ocurrio un error al borrar el archivo')
        this.intermediary.dismissLoading()
      },
      () => {
        this.intermediary.dismissLoading()
      }
    )
  }

  isFormComplete() {
    if ((!this.selectGestionState && !this.registry.noVerified)) {
      return false;
    }
    if (this.requirePhoto && this.photos.length == 0) {
      return false;
    }
    if (this.requireOk && !this.signature) {
      return false;
    }
    if (this.requireContact && this.txtName.length < 4 && this.txtInfo.length < 1) {
      return false;
    }
    if (!(this.defectParentId > 0 && this.defectZoneParentId > 0)){
      return false;
    }
    return this.isStatusUpdated();
  }

  updateStatus() {
    this.incidenceForm.patchValue({
      defectTypeChildId: this.defectChildId,
      defectTypeParentId: this.defectParentId,
      defectZoneChildId: this.defectZoneChildId,
      defectZoneParentId: this.defectZoneParentId,
    });

    if (this.requirePhoto) {
      if (this.photos.length > 0) {
        let photos = [];
        this.photos.forEach(elem => {
          if (elem && elem.file) {
            photos.push({ id: elem.file.id });
          }
        });
        this.incidenceForm.addControl('photosFileIds', new FormControl(photos));
        this.incidenceForm.patchValue({
          photosFileIds: photos
        });
      } else {

        this.intermediary.presentToastError("Debe Agregar Algunas Fotos");
        return;
      }

    }

    if (this.requireOk) {
      if (this.signature) {
        this.incidenceForm.patchValue({
          signFileId: this.signature.id,
        });
      } else {
        this.intermediary.presentToastError("Debe Agregar la Firma");
        return;
      }

    }

    this.incidenceForm.patchValue({
      statusManagementDefectId: this.managementId,
    })
    if (this.requireContact == true) {
      if (this.validate()) {
        this.incidenceForm.value.contact.info = this.txtInfo + "";
        let object = this.incidenceForm.value;
        // Set To Verified
        object['noVerified'] = false;
        this.sendToIncidents(object);
      }
    } else {
      let object = this.incidenceForm.value;
      delete object.contact
      // Set To Verified
      object['noVerified'] = false;
      this.sendToIncidents(object);
    }
  }

  validate() {

    let regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    let validation = true;
    let msg;

    if (this.txtName.length < 4) {
      console.log("name false");
      msg = "Nombre debe tener minimo 4 digítos...";
      validation = false;
    }

    if (msg == undefined) {

    } else {
      if (msg.length > 0) {
        this.intermediary.presentToastError(msg);
      }
    }

    return validation;
  }

  async sendToIncidents(object) {

    this.incidentsService.addRegistry(object).subscribe(
      resp => {

        this.resetVariables();
        this.intermediary.dismissLoading()
        this.intermediary.presentToastSuccess('El defecto fue enviado exitosamente');
        this.exit();
        if (!this.isHistory) {
          this.defectiveRegistryService.getListDefectAfterUpdate(this.form.value);
        }

      },
      e => {
        console.log(e);

        this.intermediary.dismissLoading()
        this.intermediary.presentToastError(e.error.message)
      }
    );
  }

  print(defective) {
    this.incidentsService.getData(defective).subscribe(
      resp => {
        if (resp.data.statusManagementDefect.ticketEmit == true) {
          this.printTicketService.printTicket(resp.data);
        }
      });
  }

  ngOnDestroy(): void {
    this.resetVariables();
  }

  resetVariables() {
    this.incidenceForm.patchValue({
      id: this.registry.id,
      productReference: this.registry.product.id,
      dateDetection: moment().format("YYYY-MM-DD"),
      observations: '',
      factoryReturn: false,
      statusManagementDefectId: 0,
      defectTypeParentId: 0,
      defectTypeChildId: 0,
      defectZoneParentId: 0,
      defectZoneChildId: 0,
      photosFileIds: [],
      signFileId: 0,
      contact: {
        name: '',
        info: ''
      }
    });
    this.signature = null;
    this.uploadService.setSignature(null);
    this.dropFilesService.setImage(null);
    this.photos = [];
    this.photoList = false
    this.signatureList = false;
    this.ticketEmit = false;
    this.passHistory = false;
    this.requirePhoto = false;
    this.requireContact = false;
    this.requireOk = false;
    this.txtInfo = "";
    this.txtName = "";
  }

}

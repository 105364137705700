import { Component, OnInit } from '@angular/core';
import { IntermediaryService } from '@suite/services';
import {AlertController, ModalController, NavParams} from "@ionic/angular";
import {OutScanIncidenceService} from "../../../../../services/src/lib/endpoint/out-scan-incidence/out-scan-incidence.service";

@Component({
  selector: 'suite-out-scan-incidences-show-json',
  templateUrl: './out-scan-incidences-show-json.component.html',
  styleUrls: ['./out-scan-incidences-show-json.component.scss']
})
export class OutScanIncidencesShowJsonComponent implements OnInit {

  incidence;
  type;
  windowTitle;
  currentUser;
  showJSONValue = '';

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private navParams: NavParams,
    private outScanIncidenceService: OutScanIncidenceService,
    private intermediaryService: IntermediaryService
  ) {
  }

  ngOnInit() {
    this.incidence = this.navParams.get("incidence");
    this.type = this.navParams.get("type");
    this.showJSONValue = this.navParams.get("dataToShow");
    this.currentUser = this.navParams.get("currentUser");
    this.windowTitle = (this.type == 'request') ? 'Petición' : 'Error';
  }

  close() {
    this.modalController.dismiss({reset: false});
  }

  downloadRequestJSON() {
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(this.showJSONValue);
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", this.type + '-' + this.incidence.date + "-origin-" + this.incidence.origin + "-destiny-" + this.incidence.destiny + "-packing-" + this.incidence.packing + ".json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  async resendPetition() {
    let jsonWorks;
    try {
      jsonWorks = JSON.parse(this.showJSONValue);
    } catch (e) {
      jsonWorks = null;
      this.intermediaryService.presentToastError('Error de formato en la petición. Asegúrese de que el formato del JSON es correcto.');
    }

    if (jsonWorks) {
      const alert = await this.alertController.create({
        header: 'Reenviar petición',
        message: '<span>Se reenviará al servicio Avelon la petición de la incidencia de origen <b>' + this.incidence.origin + '</b>, destino <b>' + this.incidence.destiny + '</b> y embalaje <b>' +  this.incidence.packing + '</b>.<br/><br/>¿Está seguro de querer continuar?</span>',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
            }
          }, {
            text: 'Sí',
            handler: async () => {
              this.sendRequest();
            }
          }
        ]
      });
      await alert.present();
    }
  }

  sendRequest() {

    let params = {
      sendRequest: true,
      incidence: this.formatIncidenceToSolve(),
      userId: this.currentUser
    };

    this.outScanIncidenceService.postSolveOutScanIncidence(params).then((response) => {
      if (response.code === 200) {
        if (response.data) {
          this.intermediaryService.presentToastSuccess('El reenvio de la petición ha resuelto la incidencia.');
        } else {
          this.intermediaryService.presentToastError('El servicio de Avelon ha devuelto un error con la petición realizada.');
        }
        this.modalController.dismiss({reset: true});
      } else {
        console.error(response);
        this.intermediaryService.presentToastError('Ha ocurrido un error. No se ha podido reenviar la petición.');
        this.modalController.dismiss({reset: false});
      }
    }).catch((error) => {
      console.error(error);
      this.intermediaryService.presentToastError('Ha ocurrido un error. No se ha podido reenviar la petición.');
      this.modalController.dismiss({reset: false});
    });
  }

  formatIncidenceToSolve() {
    let request;

    try {
      request = JSON.parse(this.showJSONValue);
    } catch(e) {
      request = this.incidence.request;
    }

    return {
      id: this.incidence.id,
      type: this.incidence.type,
      notificationId: this.incidence.notificationId,
      packing: this.incidence.packing,
      request
    };
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextParserService {

  constructor() {}

  public formatTextToWarningAlertMessage(originalMessage: string): string {
    return originalMessage.replace("\n", "<br/>");
  }

  public formatTextToToastMessage(originalMessage: string): string {
    return originalMessage.replace("<br/>", "\n");
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { IonicModule } from '@ionic/angular';
import { RegularizationRouteComponent } from './regularization-route.component';
import {LoadingMessageModule} from "../components/loading-message/loading-message.module";
import {FormsModule} from "@angular/forms";

const routes: Routes = [{
    path: '',
    component: RegularizationRouteComponent
}];

@NgModule({
  declarations: [RegularizationRouteComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forChild(routes),
    LoadingMessageModule,
    FormsModule
  ]
})

export class RegularizationRouteModule {}

export const environment = {
  production: true,
  printerRequired: true,
  apiBasePhoto: '/outerapi/photo', //'http://195.201.31.197:8080'
  apiBase: '/outerapi/api', //'http://195.201.31.197:8080/api'
  apiSeur: '/outerapi/apiSeur', //'http://195.201.31.197:3053/api'
  apiCorreosExpress: '/outerapi/api',
  apiDhl: '/outerapi/apiDhl',
  apiCrm: '/outerapi/apiCrm', //'http://195.201.31.197:3057/api'
  apiSorter: '/outerapi/api-sorter', //'http://195.201.31.197:8080/api'
  apiLogisticOperator: '/outerapi/api', //'http://195.201.31.197:8080/api'
  apiRule: '/outerapi/apiRule', //'http://195.201.31.197:5000'
  apiMiddleware: '/outerapi/apiMid', //'http://195.201.31.197:3049'
  urlBase: '/outerapi/urlBase', //'http://195.201.31.197:8080'
  uploadFiles: '/outerapi/uploadFiles', //'http://195.201.31.197:8080/api/upload-files'
  downloadFiles: '/outerapi/downloadFiles', //'http://195.201.31.197:8080'
  downloadPdf: '/outerapi/downloadPdf', //'http://195.201.31.197:8080'
  urlDownloadApp: 'https://drive.google.com/file/d/1skbZFeqWvoUkYZToPoZfHLWW4XTrUuWG/view?usp=sharing',
  ocrModelLearningSystem: 'https://localhost:3000/classes-create',
  apiMicroSocket: '/outerapi/ws/api',
  sga: {
    client_id: "moodular-client-sga",
    client_secret: "c44ab9a5-72d6-4ce8-b81a-d101a5713a1a"
  },
  al: {
    client_id: "moodular-client-al",
    client_secret: "7936444d-6e82-4349-a23d-cdd61d80ba3b"
  }
};
/**this is a dirty method need be change for replace or environments in build time */
export let app: { name: "sga" | "al" } = {
  name: 'sga'
};

export const arrayCategories = [2,6,3,9,10];

export let group = [
    {
      id:1,
      name: 'Sección',
    },
    {
      id: 2,
      name: 'Familia',
    },
    {
      id: 6,
      name: 'Tacón',
    },
    {
      id: 3,
      name: 'Descripción',
    },
    {
      id: 9,
      name: 'Mat. Interior',
    },
    {
      id: 10,
      name: 'Mat. Exterior',
    },
    {
      id: 12,
      name: 'Comercial',
    },
    {
      id: 15,
      name: 'Marca',
    },
    {
      id: 16,
      name: 'Color',
    },
    {
      id: 17,
      name: 'Talla',
    },
    {
      id: 18,
      name: 'Precio',
    },
    {
      id: 19,
      name: 'Añadir referencias',
    },
    {
      id: 20,
      name: 'Excluír referencias',
    }
];

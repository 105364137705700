import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuditsModel } from "../../../../../services/src/models/endpoints/Audits";
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavParams, ModalController } from '@ionic/angular';
import { FilterButtonComponent } from '../../../components/filter-button/filter-button.component';
import { TagsInputOption } from '../../../components/tags-input/models/tags-input-option.model';
import { FiltersModel } from '../../../../../services/src/models/endpoints/filters';
import * as _ from 'lodash';
import { PaginatorComponent } from '../../../components/paginator/paginator.component';


const ELEMENT_DATA: AuditsModel.AuditPackingProduct[] = [];

@Component({
  selector: 'suite-products-by-audit',
  templateUrl: './products-by-audit.component.html',
  styleUrls: ['./products-by-audit.component.scss']
})
export class ProductsByAuditComponent implements OnInit {

  displayedColumns: string[] = ['reference', 'productReferences', 'status'];
  dataSource: MatTableDataSource<AuditsModel.AuditPackingProduct> = new MatTableDataSource(ELEMENT_DATA);
  entities;
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  @ViewChild(MatSort) sort: MatSort;

  isFilteringReference: number = 0;
  isFilteringProductReference: number = 0;
  isFilteringStatus: number = 0;
  lastUsedFilter: string;

  packing;
  products;
  allProducts;
  @ViewChild('filterButtonReference') filterButtonReference: FilterButtonComponent;
  @ViewChild('filterButtonProductReference') filterButtonProductReference: FilterButtonComponent;

  reference: Array<TagsInputOption> = [];
  productReferences: Array<TagsInputOption> = [];
  status: Array<TagsInputOption> = [];
  // form: FormGroup;
  pagerValues;

  form: FormGroup = this.formBuilder.group({
    reference: [],
    productReferences: [],
    status: [],
    pagination: this.formBuilder.group({
      page: 1,
      limit: 0
    }),
    orderby: this.formBuilder.group({
      type: 1,
      order: "asc"
    })
  });
  pauseListenFormChange: boolean;
  orderType;
  typeOrder;
  constructor(
    private formBuilder: FormBuilder,
    public navParams: NavParams,
    private modalController: ModalController,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: AuditsModel.AuditPacking
  ) {
    this.products = this.navParams.get('sorterAuditPackingProducts');
    this.allProducts = Object.assign([], this.products);
    this.packing = this.navParams.get('packing');
    //this.dataSource = new MatTableDataSource(this.products);
    this.dataSource.sort = this.sort;
  }

  close(): void {
    this.modalController.dismiss()
  }

  ngOnInit() {
    this.initEntity();
    this.initForm();
    this.getFilters();
    this.getList(this.form);
    this.listenChanges();

  }

  initForm() {
    this.pagerValues = this.paginator.fixedSGApagerValues;

    this.form = this.formBuilder.group({
      reference: [],
      productReferences: [],
      status: [],
      pagination: this.formBuilder.group({
        page: 1,
        limit: this.pagerValues[0]
      }),
      orderby: this.formBuilder.group({
        type: 1,
        order: "asc"
      })
    })
  }

  initEntity() {
    this.entities = {
      reference: [],
      productReferences: [],
      status: [],
      orderby: this.formBuilder.group({
        type: 1,
        order: "asc"
      })
    }
  }

  getFilters() {
    this.products.forEach(x => {
      let statusName = this.getProductStatusText(x);
      if (!this.entities.status.find(i => i.id == statusName)) {
        this.entities.status.push({ id: statusName, value: statusName, name: statusName, checked: false, hide: false })
      };

      if (!this.entities.reference.find(i => i.id == x.product.model.reference)) {
        this.entities.reference.push({ id: x.product.model.reference, value: x.product.model.reference, name: x.product.model.reference, checked: false, hide: false })
      };

      if (!this.entities.productReferences.find(i => i.id == x.product.reference)) {
        this.entities.productReferences.push({ id: x.product.reference, value: x.product.reference, name: x.product.reference, checked: false, hide: false })
      };
    });

    this.reference = this.updateFilterSource(this.entities.reference, 'reference');
    this.productReferences = this.updateFilterSource(this.entities.productReferences, 'productReferences');
    this.status = this.updateFilterSource(this.entities.status, 'status');
  }

  private updateFilterSource(dataEntity: FiltersModel.Default[], entityName: string) {
    let resultEntity;

    this.pauseListenFormChange = true;
    let dataValue = this.form.get(entityName).value;
    resultEntity = dataEntity.map(entity => {
      entity.id = <number>(<unknown>entity.id);
      if (entity.name.toString() == 'false') {
        entity.name = 'No';
        entity.value = 'No';
      } else {
        if (entity.name.toString() == 'true') {
          entity.name = 'Sí';
          entity.value = 'Sí';
        } else {
          entity.name = entity.name;
          entity.value = entity.name;
        }
      }
      entity.checked = true;
      entity.hide = false;
      return entity;
    });

    if (dataValue && dataValue.length) {
      this.form.get(entityName).patchValue(dataValue, { emitEvent: false });
    }

    setTimeout(() => { this.pauseListenFormChange = false; }, 0);

    return resultEntity;
  }

  applyFilter(event: any) {
    const filteredValue = event.target.value;
    this.dataSource.filter = filteredValue.trim().toLowerCase();
  }

  getProductStatusText(product: AuditsModel.AuditPackingProduct): string {
    if (product.incidence) {
      return 'Con incidencia';
    } else if (product.rightAudit) {
      return 'Validado';
    } else {
      return '-';
    }
  }

  async getList(form: FormGroup) {
    let referenceFiltered = form.value.reference || [];
    let productReferenceFiltered = form.value.productReferences || [];
    let statusFiltered = form.value.status || [];
    this.products = this.allProducts.filter(x => {
      let reference = true;
      let status = true;
      let productReference = true;
      if (referenceFiltered.length) {
        reference = referenceFiltered.indexOf(x.product.model.reference) !== -1;
      }

      if (statusFiltered.length) {
        status = statusFiltered.indexOf(this.getProductStatusText(x)) !== -1;
      }

      if (productReferenceFiltered.length) {
        productReference = productReferenceFiltered.indexOf(x.product.reference) !== -1;
      }

      return reference && status && productReference
    });

    let page_number = this.form.value.pagination.page;
    let page_size = this.form.value.pagination.limit;
    const paginatorLength = this.products.length;
    this.products = this.products.slice((page_number - 1) * page_size, page_number * page_size)

    if(this.orderType && this.typeOrder){
      this.products = _.orderBy(this.products, [this.orderType], [this.typeOrder]);
    }

    this.dataSource = new MatTableDataSource(this.products);
    this.paginator.length = paginatorLength;
    this.paginator.pageIndex = page_number;
    this.paginator.lastPage = Math.ceil(this.paginator.length / page_size);
  }

  applyFilters(filtersResult, filterType) {
    const filters = filtersResult.filters;
    switch (filterType) {
      case 'reference':
        let referenceFiltered: string[] = [];
        for (let reference of filters) {

          if (reference.checked) referenceFiltered.push(reference.id);
        }

        if (referenceFiltered.length >= this.reference.length) {
          this.form.value.reference = [];
          this.isFilteringReference = this.reference.length;
        } else {
          if (referenceFiltered.length > 0) {
            this.form.value.reference = referenceFiltered;
            // debugger
            // this.products = this.allProducts.filter(x => referenceFiltered.indexOf(x.product.model.reference) !== -1);
            // this.dataSource = new MatTableDataSource(this.products);
            this.isFilteringReference = referenceFiltered.length;
          } else {
            this.form.value.reference = [];
            this.isFilteringReference = this.reference.length;
          }
        }
        break;
      case 'productReferences':
        let productReferenceFiltered: string[] = [];
        for (let productReference of filters) {

          if (productReference.checked) productReferenceFiltered.push(productReference.id);
        }

        if (productReferenceFiltered.length >= this.productReferences.length) {
          this.form.value.productReferences = [];
          this.isFilteringProductReference = this.productReferences.length;
        } else {
          if (productReferenceFiltered.length > 0) {
            this.form.value.productReferences = productReferenceFiltered;
            this.isFilteringProductReference = productReferenceFiltered.length;
          } else {
            this.form.value.productReferences = [];
            this.isFilteringProductReference = this.productReferences.length;
          }
        }
        break;
      case 'status':
        let statusFiltered: string[] = [];
        for (let status of filters) {

          if (status.checked) statusFiltered.push(status.id);
        }

        if (statusFiltered.length >= this.status.length) {
          this.form.value.status = [];
          this.isFilteringStatus = this.status.length;
        } else {
          if (statusFiltered.length > 0) {
            this.form.value.status = statusFiltered;
            this.isFilteringStatus = statusFiltered.length;
          } else {
            this.form.value.status = [];
            this.isFilteringStatus = this.status.length;
          }
        }
        break;

    }

    this.lastUsedFilter = filterType;
    this.getList(this.form);
  }

  listenChanges() {
    let previousPageSize = this.form.value.pagination.limit;
    /**detect changes in the paginator */
    this.paginator.page.subscribe(page => {
      /**true if only change the number of results */
      let flag = previousPageSize === page.pageSize;
      previousPageSize = page.pageSize;
      this.form.value.pagination = {
        limit: page.pageSize,
        page: flag ? page.pageIndex : 1
      };
      this.getList(this.form)
    });

  }

  async sortData(event: Sort) {
    switch (event.active) {
      case 'reference':
        this.orderType = 'product.model.reference';
        this.typeOrder = event.direction !== '' ? event.direction : 'asc';
        break;
      case 'productReferences':
        this.orderType = 'product.reference'
        this.typeOrder = event.direction !== '' ? event.direction : 'asc';
        break;
      default:
        this.orderType = undefined
        this.typeOrder = undefined;
        break;
      }
    this.getList(this.form);
    
  }

}

import {Injectable} from '@angular/core';
import {RegularizationService} from "../../endpoint/regularization/regularization.service";
import {PrinterService} from "../../printer/printer.service";
import {RegularizationModel} from "../../../models/endpoints/Regularization";
import SizeRegularizationParameters = RegularizationModel.SizeRegularizationParameters;

declare let ScanditMatrixSimple;

@Injectable({
  providedIn: 'root'
})
export class SizeRegularizationService {

  constructor(
    private regularizationSrv: RegularizationService,
    private printerService:PrinterService
  ) {}

  async init() {
    ScanditMatrixSimple.initSizeRegularization(async (params: SizeRegularizationParameters) => {
      this.regularizationSrv.postSizeRegularization(params).then(response => {
        if (response.code === 200) {
          this.printerService.printTagBarcodeWithoutSubscribe([response.data.reference], 1,()=>{
            let body = {
              model : response.data.model,
              oldSize : response.data.oldSize,
              newSize : response.data.newSize
            };
            this.regularizationSrv.postSizeRegularizationNotify(body).then(res => {
              if (res.code === 200) {
          ScanditMatrixSimple.resetSizeRegularization();
              }else{
                ScanditMatrixSimple.showSizeRegularizationError(res.errors);
              }
            }, error => {
              ScanditMatrixSimple.showSizeRegularizationError(error);
            });
          }, ()=>{
            ScanditMatrixSimple.showSizeRegularizationError('Error de conexión con la impresora.');
            let parameters = {
              oldReference : params.oldReference,
              newSize : params.newSize,
              newReference : response.data.reference
            };
            this.regularizationSrv.postRevertSizeRegularization(parameters).then(result => {
              if (result.code != 200) {
                ScanditMatrixSimple.showSizeRegularizationError(result.errors);
              }
            });
          });
        } else {
          ScanditMatrixSimple.showSizeRegularizationError(response.errors);
        }
      },error => {
        ScanditMatrixSimple.showSizeRegularizationError(error.errors);
      }).catch(error => {
        ScanditMatrixSimple.showSizeRegularizationError(error.errors);
      });
    });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { MatTableDataSource } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { IntermediaryService } from '@suite/services';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { RackModel } from '../../../../services/src/models/endpoints/rack.model';
import { RackService } from '../../../../services/src/lib/endpoint/rack/rack.service';
import { StoreUpdateComponent } from './store-update/store-update.component';
import {PrinterService} from "../../../../services/src/lib/printer/printer.service";
import FilterOptionsResponse = RackModel.FilterOptionsResponse;
import Pagination = RackModel.Pagination;
import Filters = RackModel.Filters;
import FilterOptions = RackModel.FilterOptions;
import Order = RackModel.Order;
import Rack = RackModel.Rack;
import SearchParameters = RackModel.SearchParameters;
import SearchResponse = RackModel.SearchResponse;
import {PaginatorComponent} from "../../components/paginator/paginator.component";

@Component({
  selector: 'suite-racks',
  templateUrl: './racks.component.html',
  styleUrls: ['./racks.component.scss'],
})
export class RacksComponent implements OnInit {
  public title = 'Estantes';
  dataSource: MatTableDataSource<RackModel.Rack>;
  displayedColumns: string[] = ['select', 'name', 'reference', 'warehouse'];
  selection = new SelectionModel<RackModel.Rack>(true, []);
  showDeleteButton = false;

  pagerValues = [50, 100, 500];

  private page: number = 0;
  private limit: number = this.pagerValues[0];
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  filters: Filters = {
    names: [],
    references: [],
    warehouses: []
  };
  filterOptions: FilterOptions = {
    names: [],
    references: [],
    warehouses: []
  };
  order: Order = {
    field: 'date',
    direction: 'DESC'
  };
  pagination: Pagination = {
    limit: this.pagerValues[0],
    page: 1,
    selectPage: 1,
    lastPage: 1
  };

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private intermediaryService: IntermediaryService,
    private rackService: RackService,
    private alertController: AlertController,
    private printerService:PrinterService
  ) { }

  async ngOnInit() {
    await this.loadFilters();
  }

  ngAfterViewInit() {
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.listenChanges();
    this.getRacks();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource ? this.dataSource.data.length : 0;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));

    this.isAllSelected()
      ? (this.showDeleteButton = true)
      : (this.showDeleteButton = false);
  }

  checkboxLabel(row?: RackModel.Rack): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  checkSelection(row?: RackModel.Rack) {
    this.selection.toggle(row);
    this.showDeleteButton = this.selection.selected.length > 0;
  }

  private async getRacks() {
    await this.intermediaryService.presentLoading();
    const parameters: SearchParameters = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };
    await this.rackService.getIndexWithFilters(parameters).then((data: SearchResponse) => {
      if (data.code == 200) {
        this.dataSource = new MatTableDataSource(data.data.result);
        this.paginator.length = data && data.data.count ? data.data.count : 0;
        this.paginator.pageIndex = data && data.data.pagination ? data.data.pagination.selectPage : 1;
        this.paginator.lastPage = data && data.data.pagination ? data.data.pagination.lastPage : 1;
      }
      this.intermediaryService.dismissLoading();
    });
  }


  loadFilters(){
    this.rackService.getFilterOptions().then((response: FilterOptionsResponse) => {
      if(response.code == 200){
        this.filterOptions = response.data;
      }else{
        console.error(response);
      }
    }).catch(console.error);
  }

  listenChanges(): void {
    let previousPageSize = this.pagination.limit;

    this.intermediaryService.dismissLoading();
    /**detect changes in the paginator */
    this.paginator.page.subscribe(page => {
      /**true if only change the number of results */
      let flag = previousPageSize == page.pageSize;
      previousPageSize = page.pageSize;
      this.pagination.limit = page.pageSize;
      this.pagination.page = flag ? page.pageIndex : 1;
      this.getRacks();
    });
  }

  async confirmDelete() {
    if (this.selection.selected.length > 0) {
      await this.presentDeleteAlert();
    }
  }

  async printRacks() {
    if (this.selection.selected.length > 0) {
      let listReferences: Array<string> = this.selection.selected.map(rack => rack.sorterRack_reference);

      if (listReferences && listReferences.length > 0) {
        this.printReferencesList(listReferences);
      }
    }
  }

  private async printReferencesList(listReferences: Array<string>) {
    if ((<any>window).cordova) {
      this.printerService.print({ text: listReferences, type: 0 });
    } else {
      return await this.printerService.printBarcodesOnBrowser(listReferences);
    }
  }

  async goToDialog() {
    const modal = (await this.modalCtrl.create({
      component: StoreUpdateComponent,
      showBackdrop: true,
      keyboardClose: false,
      backdropDismiss: false,
    }));
    modal.onDidDismiss().then(async () => {
      this.selection.clear();
      await this.getRacks();
      this.showDeleteButton = false;
    });
    modal.present();
  }

  async goToUpdate(row?: RackModel.Rack) {
    this.rackService.populateForm(row);
    await this.goToDialog();
  }

  async presentDeleteAlert() {
    const variant = this.selection.selected.length === 1 ? 'Almacen' : 'Almacenes';
    const variantDelete = this.selection.selected.length === 1 ? 'eliminado' : 'eliminados';
    const header = `Eliminar ${variant}`;
    const message = `¿Seguro que desea eliminar <strong>${this.selection.selected.length}</strong> ${variant}?`;
    const successMsg = `${variant} ${this.selection.selected.map(value => value.name)} ${variantDelete}`;

    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Aceptar',
          handler: async () => {
            await this.intermediaryService.presentLoading();
            await this.deleteElements(successMsg);
          }
        }
      ]
    });

    await alert.present();
  }

  private async deleteElements(successMsg: string) {
    await this.intermediaryService.presentLoading();
    let observable = new Observable(observer=>observer.next());
    console.log('this.selection.selected',this.selection.selected)
    this.selection.selected.forEach(rack => {
      if(rack) {
        observable = observable.pipe(switchMap(resonse =>{
          return this.rackService.delete(rack.sorterRack_id)
        }))
      }
    });

    observable.subscribe(async () => {
      await this.resetTool(successMsg);
    }, async (errorResponse: HttpErrorResponse) => {
      console.log(errorResponse);
      await this.resetTool(errorResponse.message);
    });
  }

  private async resetTool(msg: string) {
    await this.intermediaryService.presentToastSuccess(msg);
    this.selection.clear();
    await this.intermediaryService.dismissLoading();
    await this.getRacks();
    this.showDeleteButton = false;
  }


  orderBy(column: string){
    if(this.order.field == column){
      this.order.direction == 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.getRacks();
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        values.push(item.id);
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.pagination.page = 1;
    this.getRacks();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatListModule } from '@angular/material';
import { MatTooltipModule } from "@angular/material";
import { ManageIncidenceComponent } from './manage-incidence.component';
import {MatTabsModule} from '@angular/material/tabs';
import {
  MatCheckboxModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSortModule,
  MatTableModule
} from '@angular/material';
import { NgxFileDropModule } from  'ngx-file-drop' ;
import {MatDividerModule} from "@angular/material/divider";
import {FormInputErrorModule} from "../form-input-error/form-input-error.module";

@NgModule({
  exports: [ManageIncidenceComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    FormsModule,
    MatCheckboxModule,
    MatRippleModule,
    MatSortModule,
    NgxFileDropModule,
    MatTabsModule,
    MatDividerModule,
    FormInputErrorModule
  ],
  declarations: [ManageIncidenceComponent]
})

export class ManageIncidenceModule { }

import { IonicModule } from '@ionic/angular';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {ColumnValuePipe, TableFiltersComponent} from './table-filters.component';
import { MatListModule } from '@angular/material';
import { MatCheckboxModule, MatSortModule, MatTableModule,MatRippleModule, MatPaginatorModule } from '@angular/material';
import { FilterButtonModule } from "../../components/filter-button/filter-button.module";
import { PaginatorComponentModule } from '../../components/paginator/paginator.component.module';
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonToggleModule} from "@angular/material/button-toggle";

@NgModule({
  declarations: [TableFiltersComponent, ColumnValuePipe],
  imports: [
    CommonModule,
    IonicModule,
    MatListModule,
    MatCheckboxModule,
    MatSortModule,
    MatTableModule,
    FilterButtonModule,
    MatRippleModule,
    MatPaginatorModule,
    PaginatorComponentModule,
    MatMenuModule,
    MatButtonToggleModule
  ],
  exports:[TableFiltersComponent],
  providers: [DatePipe]
})
export class TableFiltersModule { }

import {Component, OnInit} from '@angular/core';
import {IntermediaryService} from '@suite/services';
import {Router} from "@angular/router";
import {OplTransportsService} from "../../../services/src/lib/endpoint/opl-transports/opl-transports.service";
import {TransportsModel} from "../../../services/src/models/endpoints/Expeditions/Transports";

@Component({
  selector: 'transports-select',
  templateUrl: './transports-select.component.html',
  styleUrls: ['./transports-select.component.scss'],
})
export class TransportsSelectComponent implements OnInit {

  transports: TransportsModel.TransportWithQuantity[] = [];
  isLoadingTransports: boolean = false;
  private typePage: number = TypePage.PENDING_PICKUP;

  constructor(
    private oplTransportsService: OplTransportsService,
    private intermediaryService: IntermediaryService,
    public router: Router
  ) {}

  ngOnInit() {
    if (this.router.url == '/expedition-collected') {
      this.typePage = TypePage.PENDING_PICKUP;
    } else if (this.router.url == '/expedition-cancelled') {
      this.typePage = TypePage.CANCELLED;
    } else {
      this.typePage = TypePage.MANIFESTS;
    }
  }

  ngAfterViewInit() {
    this.getList();
  }

  private async getList() {
    this.isLoadingTransports = true;

    let observable;
    if (this.typePage == TypePage.PENDING_PICKUP) {
      observable = this.oplTransportsService.getOperatorsPendingPickUp();
    } else if (this.typePage == TypePage.CANCELLED) {
      observable = this.oplTransportsService.getOperatorsCancelled();
    } else {
      observable = this.oplTransportsService.getOperatorsWithManifests();
    }

    observable.subscribe((res: TransportsModel.TransportWithQuantity[]) => this.transports = res,
      (error) => { this.isLoadingTransports = false; this.intermediaryService.presentToastError('Ha ocurrido un error al intentar cargar los transportistas.') },
      () => this.isLoadingTransports = false);
  }

  public refresh() {
    this.getList();
  }

  public async openItemsList(transportId: number) {
    let route;
    if (this.typePage == TypePage.PENDING_PICKUP) {
      route = '/transport-package-collected';
    } else if (this.typePage == TypePage.CANCELLED) {
      route = '/transport-package-cancelled';
    } else {
      route = '/transport-order-package';
    }
    await this.router.navigate([route, { id: transportId }])
  }

  public getQuantityWithUnities(quantity: number) {
    if (!quantity) {
      quantity = 0;
    }

    let unities;
    if (this.typePage == TypePage.PENDING_PICKUP) {
      unities = 'pedido';
    } else if (this.typePage == TypePage.CANCELLED) {
      unities = 'pedido';
      if (quantity > 1 || quantity == 0) {
        unities = unities + 's';
      }
      unities = unities + ' cancelado';
    } else {
      unities = 'manifiesto';
    }
    if (quantity > 1 || quantity == 0) {
      unities = unities + 's';
    }

    return `${quantity} ${unities}`;
  }
}

enum TypePage {
  PENDING_PICKUP = 1,
  MANIFESTS = 2,
  CANCELLED = 3
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { IonicModule } from '@ionic/angular';
import { RegularizationFormComponent } from './regularization-form.component';
import {MatDividerModule, MatExpansionModule, MatGridListModule, MatPaginatorModule, MatIconModule} from "@angular/material";
import { PaginatorComponentModule } from '../components/paginator/paginator.component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {TagsInputModule} from "../components/tags-input/tags-input.module";
import { RegularizationProductTemplateComponent } from './filter-items-list/list-items/list-items.component';

const routes: Routes = [{
    path: '',
    component: RegularizationFormComponent
}];

@NgModule({
  declarations: [RegularizationFormComponent,RegularizationProductTemplateComponent],
  entryComponents: [RegularizationFormComponent,RegularizationProductTemplateComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forChild(routes),
    MatDividerModule,
    FormsModule,
    ScrollingModule,
    PaginatorComponentModule,
    MatExpansionModule,
    MatGridListModule,
    MatPaginatorModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    TagsInputModule,
  ]
})

export class RegularizationFormModule {}

import { Component, OnInit, ViewChild } from '@angular/core';
import { from, Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService, environment, InventoryModel, IntermediaryService, ProductModel } from '@suite/services';
import { AlertController, Events } from "@ionic/angular";
import { switchMap } from "rxjs/operators";
import {LoadingMessageComponent} from "../components/loading-message/loading-message.component";
import {ShoesPickingModel} from "../../../services/src/models/endpoints/ShoesPicking";
import {ItemReferencesProvider} from "../../../services/src/providers/item-references/item-references.provider";
import {AudioProvider} from "../../../services/src/providers/audio-provider/audio-provider.provider";
import {KeyboardService} from "../../../services/src/lib/keyboard/keyboard.service";
import { environment as al_environment } from "../../../../apps/al/src/environments/environment";
import {TimesToastType} from "../../../services/src/models/timesToastType";
import {PositionsToast} from "../../../services/src/models/positionsToast.type";
import {ActivatedRoute, Router} from "@angular/router";
import {RegularizationService} from "../../../services/src/lib/endpoint/regularization/regularization.service";
import {RegularizationModel} from "../../../services/src/models/endpoints/Regularization";
import getStartRegularizationRouteResponse = RegularizationModel.getStartRegularizationRouteResponse;
import RegularizationProcess = RegularizationModel.RegularizationProcess;
import {PrinterService} from "../../../services/src/lib/printer/printer.service";
import Product = ProductModel.Product;
import postRegularizeProductResponse = RegularizationModel.postRegularizeProductResponse;

@Component({
  selector: 'suite-regularization-route',
  templateUrl: './regularization-route.component.html',
  styleUrls: ['./regularization-route.component.scss']
})
export class RegularizationRouteComponent implements OnInit {

  @ViewChild(LoadingMessageComponent) loadingMessageComponent: LoadingMessageComponent;

  regularizationId: number;
  progress: {
    finished: number,
    total: number
  };
  listProducts: RegularizationProcess[];
  nexProduct: RegularizationProcess;
  dataToWrite: string = 'PRODUCTO';
  inputData: string = null;
  lastCodeScanned: string = 'start';
  scanContainerToNotFound: string = null;

  private postCheckContainerProductUrl = environment.apiBase + "/inventory/check-container";
  private timeoutStarted = null;
  private readonly timeMillisToResetScannedCode: number = 1000;

  public isScannerBlocked: boolean = false;

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private events: Events,
    private alertController: AlertController,
    private itemReferencesProvider: ItemReferencesProvider,
    private intermediaryService: IntermediaryService,
    private audioProvider: AudioProvider,
    private keyboardService: KeyboardService,
    private route: ActivatedRoute,
    private regularizationService: RegularizationService,
    private printerService: PrinterService,
    private router: Router
  ) {
    this.timeMillisToResetScannedCode = al_environment.time_millis_reset_scanned_code;
    this.focusToInput();
  }

  async ngOnInit() {
    this.regularizationId = parseInt(this.route.snapshot.paramMap.get('id'));
    if (this.regularizationId) {
      this.startRoute();
    } else {
      await this.intermediaryService.presentToastError('Ha ocurrido un error al cargar la regularización.');
    }
  }

  startRoute(){
    this.regularizationService.getStartRoute({id: this.regularizationId}).then((response: getStartRegularizationRouteResponse) => {
      if(response.code == 200){
        this.listProducts = response.data;
        this.nexProduct = this.listProducts.filter(p=>!p.regularized)[0];
        this.progress = {
          finished: this.listProducts.filter(p=>p.regularized).length,
          total: this.listProducts.length
        };
      }else{
        console.error(response);
      }
    }, console.error).catch(console.error);
  }

  async keyUpInput(event) {
    let dataWritten = (this.inputData || '').trim();
    if (event.keyCode === 13 && !this.isScannerBlocked) {
      this.isScannerBlocked = true;
      document.getElementById('input-ta').blur();

      if (dataWritten === this.lastCodeScanned) {
        this.inputData = null;
        this.isScannerBlocked = false;
        this.focusToInput();
        return;
      }
      this.lastCodeScanned = dataWritten;

      if (this.timeoutStarted) {
        clearTimeout(this.timeoutStarted);
      }
      this.timeoutStarted = setTimeout(() => this.lastCodeScanned = 'start', this.timeMillisToResetScannedCode);

      this.inputData = null;
      switch(this.dataToWrite){
        case 'PRODUCTO':
          if(this.itemReferencesProvider.checkCodeValue(dataWritten) === this.itemReferencesProvider.codeValue.PRODUCT){
            this.loadingMessageComponent.show(true, 'Procesando '+dataWritten);
            this.regularizationService.postProductRegularized({regularizationId: this.regularizationId, productReference: dataWritten}).then(async (response: postRegularizeProductResponse) => {
              this.loadingMessageComponent.show(false);
              if (response.code == 200) {
                const newProduct: Product = response.data;
                this.printerService.printTagBarcodeUsingProductWatingResponse(newProduct)
                  .subscribe(async (result) => {
                    console.log('Result Impresora', result);
                    if (result && typeof result !== "boolean") {
                      result.subscribe(r => { });
                    }
                    // this.printerService.printTagBarcodeUsingProduct(newProduct);
                    await this.intermediaryService.presentToastSuccess('Coloque el producto ' + newProduct.reference + ' en la ubicación ' + this.nexProduct.inventory.container.reference, 5000, 'bottom');
                    for (let i = 0; i < this.listProducts.length; i++) {
                      if (this.listProducts[i].id == this.nexProduct.id) {
                        this.listProducts[i].regularized = true;
                        break;
                      }
                    }
                    this.nexProduct = this.listProducts.filter(p => !p.regularized)[0];
                    this.progress.finished = this.listProducts.filter(p => p.regularized).length;
                    if (this.progress.finished == this.progress.total) {
                      setTimeout(async () => {
                        this.regularizationService.getRegularizationFinished({ id: this.regularizationId }).then(async (response) => {
                          if (response.code == 200) {
                            await this.intermediaryService.presentToastSuccess('El recorrido se ha finalizado con éxito.', 2000, 'bottom');
                            await this.router.navigate(['/regularization']);
                            this.events.publish('exit-regularization');
                          } else {
                            console.error(response);
                          }
                        }, console.error).catch(console.error);
                      }, 5000);
                    } else {
                      this.inputData = null;
                      this.isScannerBlocked = false;
                    }
                  }, (error) => {
                    console.error('An error succeed to try print products received. \nError:', error);
                  });
                
              } else {
                this.isScannerBlocked = false;
                await this.processFinishError({
                  focusInput: {
                    playSound: true
                  },
                  toast: {
                    position: PositionsToast.BOTTOM,
                    message: response.errors
                  }
                });
                if (response.errors.includes('finalizo')) {
                  await this.router.navigate(['/regularization']);
                  this.events.publish('exit-regularization');
                }  
              }
            }, console.error).catch((e)=>{
              this.loadingMessageComponent.show(false);
              console.error(e)}
              );
          }else{
            this.isScannerBlocked = false;
            await this.processFinishError({
              focusInput: {
                playSound: true
              },
              toast: {
                position: PositionsToast.BOTTOM,
                message: 'El código escaneado no corresponde a un producto.'
              }
            });
          }
          break;
        case 'UBICACIÓN':
          if (this.itemReferencesProvider.checkCodeValue(dataWritten) === this.itemReferencesProvider.codeValue.CONTAINER || this.itemReferencesProvider.checkCodeValue(dataWritten) === this.itemReferencesProvider.codeValue.CONTAINER_OLD) {
            this.loadingMessageComponent.show(true, 'Comprobando '+dataWritten);
            this.postCheckContainerProduct(dataWritten, this.nexProduct.inventory.id).subscribe(async res => {
              this.loadingMessageComponent.show(false);
              if (res.code === 200) {
                this.regularizationService.postProductNotFound({regProcessId: this.nexProduct.id}).then(async response => {
                  if (response.code === 200 || response.code === 201) {
                    this.scanContainerToNotFound = null;
                    this.dataToWrite = 'PRODUCTO';
                    this.isScannerBlocked = false;
                    await this.processFinishOk({
                      focusInput: {
                        playSound: true
                      },
                      toast: {
                        position: PositionsToast.BOTTOM,
                        message: 'El producto ha sido reportado como no encontrado',
                        duration: TimesToastType.DURATION_SUCCESS_TOAST_1500
                      }
                    });
                    for(let i = 0; i < this.listProducts.length; i++){
                      if(this.listProducts[i].id == this.nexProduct.id){
                        this.listProducts[i].regularized = true;
                        break;
                      }
                    }
                    this.nexProduct = this.listProducts.filter(p=>!p.regularized)[0];
                    this.progress.finished = this.listProducts.filter(p=>p.regularized).length;
                    if(this.progress.finished == this.progress.total){
                      this.regularizationService.getRegularizationFinished({id: this.regularizationId}).then(async (response) => {
                        if (response.code == 200) {
                          await this.intermediaryService.presentToastSuccess('La regularización se ha finalizado con éxito.');
                          await this.router.navigate(['/regularization']);
                          this.events.publish('exit-regularization');
                        } else {
                          console.error(response);
                        }
                      }, console.error).catch(console.error);
                    }else{
                      this.inputData = null;
                      this.isScannerBlocked = false;
                    }
                  } else {
                    this.isScannerBlocked = false;
                    await this.processFinishError({
                      focusInput: {
                        playSound: true
                      },
                      toast: {
                        position: PositionsToast.BOTTOM,
                        message: 'Ha ocurrido un error al intentar reportar el producto como no encontrado.'
                      }
                    });
                  }
                }, async () => {
                  this.isScannerBlocked = false;
                  await this.processFinishError({
                    focusInput: {
                      playSound: true
                    },
                    toast: {
                      position: PositionsToast.BOTTOM,
                      message: 'El código escaneado no corresponde a la ubicación del producto.'
                    }
                  });
                });
              } else {
                this.isScannerBlocked = false;
                await this.processFinishError({
                  focusInput: {
                    playSound: true
                  },
                  toast: {
                    position: PositionsToast.BOTTOM,
                    message: 'El código escaneado no corresponde a la ubicación del producto'
                  }
                });
              }
            }, async () => {
              this.loadingMessageComponent.show(false);
              this.isScannerBlocked = false;
              await this.processFinishError({
                focusInput: {
                  playSound: true
                },
                toast: {
                  position: PositionsToast.BOTTOM,
                  message: 'El código escaneado no corresponde a la ubicación del producto.'
                }
              });
            });
          } else {
            this.isScannerBlocked = false;
            await this.processFinishError({
              focusInput: {
                playSound: true
              },
              toast: {
                position: PositionsToast.BOTTOM,
                message: 'El código escaneado no corresponde a una ubicación.'
              }
            });
          }
          break;
      }
    } else {
      if (event.keyCode === 13 && this.isScannerBlocked) {
        this.inputData = null;
        this.focusToInput();
      }
    }
  }

  private postCheckContainerProduct(containerReference: string, inventoryId: number): Observable<InventoryModel.ResponseCheckContainer> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      let params: InventoryModel.ParamsCheckContainer = { inventoryId, containerReference };

      return this.http.post<InventoryModel.ResponseCheckContainer>(this.postCheckContainerProductUrl, params, { headers });
    }));
  }

  oldReference(inventory: ShoesPickingModel.Inventory) {
    let alphabet = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
    return 'P' + inventory.rack.hall.toString().padStart(2, '0')
      + alphabet[inventory.container.row - 1]
      + inventory.container.column.toString().padStart(2, '0');
  }

  async productNotFound() {
    const alertWarning = await this.alertController.create({
      header: 'Atención',
      message: '¿Está seguro de querer reportar como no encontrado el producto <b>' + this.nexProduct.productShoesUnit.model.reference + '</b> en la ubicación <b>' + this.nexProduct.inventory.container.reference + '</b>?<br/>(tendrá que escanear la ubicación para confirmar el reporte).',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancelar',
          handler: async () => {
            this.isScannerBlocked = false;
            await this.processFinishError({
              focusInput: {
                playSound: true
              }
            });

            this.scanContainerToNotFound = null;
            this.dataToWrite = "PRODUCTO";
          }
        },
        {
          text: 'Reportar',
          handler: async () => {
            this.isScannerBlocked = false;
            await this.processFinishOk({
              focusInput: {
                playSound: true
              }
            });

            this.scanContainerToNotFound = "Escanea la ubicación que estás revisando para comprobar que sea la correcta. Escanea el producto si lo encuentra.";
            this.dataToWrite = "UBICACIÓN";
          }
        }]
    });

    return await alertWarning.present();
  }

  private focusToInput(playSound: boolean = false, typeSound: 'ok' | 'error' = 'ok') {
    setTimeout(() => {
      document.getElementById('input-ta').focus();
      if (playSound) {
        if (typeSound == 'ok') {
          this.audioProvider.playDefaultOk();
        } else {
          this.audioProvider.playDefaultError();
        }
      }
    }, 500);
  }

  public onFocus(event) {
    if (event && event.target && event.target.id) {
      this.keyboardService.setInputFocused(event.target.id);
    }
  }

  private async processFinishOk(options: { toast?: { message: string, duration: number, position: string }, focusInput?: { playSound?: boolean }, playSound?: boolean } = null) {
    this.loadingMessageComponent.show(false);

    if (options.toast != null) {
      await this.intermediaryService.presentToastPrimary(options.toast.message, options.toast.duration, options.toast.position);
    }

    if (options.focusInput != null) {
      if (options.focusInput.playSound) {
        this.focusToInput(true, 'ok');
      } else {
        this.focusToInput();
      }
    }

    if (options.playSound != null) {
      this.audioProvider.playDefaultOk();
    }
  }

  private async processFinishError(options: { toast?: { message: string, duration?: number, position: string }, focusInput?: { playSound?: boolean }, playSound?: boolean } = null) {
    this.loadingMessageComponent.show(false);

    if (options.toast != null) {
      await this.intermediaryService.presentToastError(options.toast.message, options.toast.position, options.toast.duration || TimesToastType.DURATION_ERROR_TOAST);
    }

    if (options.focusInput != null) {
      if (options.focusInput.playSound) {
        this.focusToInput(true, 'error');
      } else {
        this.focusToInput();
      }
    }

    if (options.playSound != null) {
      this.audioProvider.playDefaultOk();
    }
  }

  async stopRegularization() {
    const alertWarning = await this.alertController.create({
      header: 'Atención',
      message: '¿Está seguro de querer Finalizar la regularización.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancelar',
          handler: async () => {
            this.isScannerBlocked = false;
            await this.processFinishError({
              focusInput: {
                playSound: true
              }
            });

            this.dataToWrite = "PRODUCTO";
          }
        },
        {
          text: 'Finalizar',
          handler: async () => {
            this.isScannerBlocked = false;
            await this.processFinishOk({
              focusInput: {
                playSound: true
              }
            });

            this.regularizationService.getRegularizationFinished({id: this.regularizationId}).then(async (response) => {
              if (response.code == 200) {
                await this.intermediaryService.presentToastSuccess('El recorrido se ha finalizado con éxito.', 2000, 'bottom');
                await this.router.navigate(['/regularization']);
                this.events.publish('exit-regularization');
              } else {
                console.error(response);
              }
            }, console.error).catch(console.error);
          }
        }]
    });

    return await alertWarning.present();
  }

}

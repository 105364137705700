import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-print-relabel-packing-manual',
  templateUrl: './print-relabel-packing-manual.component.html',
  styleUrls: ['./print-relabel-packing-manual.component.scss']
})

export class PrintRelabelPackingManualComponent implements OnInit {

  constructor() {}

  ngOnInit() {

  }

}

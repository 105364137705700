import { Component, OnInit } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import {app, IntermediaryService} from '../../../../../services/src/';
import {environment, UploadFilesService} from "@suite/services";
import {ModalReviewReturnComponent} from "../../../components/modal-return/ModalReviewReturn/modal-review-return.component";
import {Downloader, DownloadRequest, NotificationVisibility} from "@ionic-native/downloader/ngx";
import {config} from "../../../../../services/src/config/config";
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'suite-view-files',
  templateUrl: './view-files.component.html',
  styleUrls: ['./view-files.component.scss']
})

export class ViewFilesComponent implements OnInit{
  private baseUrlPhoto = environment.apiBasePhoto;
  type: string;
  images: any[];
  files;
  private isSGA;
  constructor(
    private modalController: ModalController,
    private intermediary: IntermediaryService,
    private uploadService: UploadFilesService,
    private downloader: Downloader,
    private platform: Platform
  ) {
  }

  ngOnInit() {
    setTimeout(() => this.files = this.images, 500);
    this.isSGA = (app.name == 'sga');
  }

  deleteImage(item, index, arr) {
    this.intermediary.presentLoading()
    this.uploadService.deleteFile(item.id).subscribe(
      resp => {
        this.intermediary.presentToastSuccess('Archivo borrado exitosamente')
        arr.splice(index, 1);
        //this.signature = false;
      },
      err => {
        this.intermediary.presentToastError('Ocurrio un error al borrar el archivo')
        this.intermediary.dismissLoading()
      },
      () => {
        this.intermediary.dismissLoading()
      }
    )
  }

  async openReviewImage(item) {
    const modal = await this.modalController.create({
      component: ModalReviewReturnComponent,
      componentProps: {
        data: item
      }
    });
    await modal.present();
  }

  public close(){
    this.modalController.dismiss();
  }

  isImage(extension) {
    return (extension == '.png' || extension == '.jpg' || extension == '.jpeg');
  }

  async downloadFile(file) {
    const urlDownload = environment.downloadPdf + file.pathOriginal;
    const fileName = this.getFileName(file.fileName);
    if (this.platform.is('android') && (<any>window).cordova) {
      await this.downloadUrl(urlDownload, fileName);
    } else {
      FileSaver.saveAs(urlDownload, fileName);
    }
  }

  public getFileName(fileName: string) {
    const fileNameFound = fileName.match(/^file-\d*-(.*)/);
    if (fileNameFound && fileNameFound.length > 1) {
      return fileNameFound[1];
    }

    return fileName;
  }

  private async downloadUrl(urlDownload, fileName){
    let request = this.configNotificationDownload(urlDownload,fileName);
    await this.downloader.download(request).then(async (location: string) =>{
      await this.intermediary.dismissLoading();
      await this.intermediary.presentToastSuccess("¡Descarga finalizada!");
    }).catch(async (error: any) => {
      await this.intermediary.dismissLoading();
      await this.intermediary.presentToastError("¡Ha ocurrido un error al descargar el archivo!");
    });
  }

  private configNotificationDownload(url,name): DownloadRequest {
    const downloadsDirectory = `${config.downloads.directoryBase}/${config.downloads.directoryAL}/${config.downloads.directoryReturns}`;
    return {
      uri: url,
      title: name,
      description: '',
      mimeType: '',
      visibleInDownloadsUi: true,
      notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
      destinationInExternalPublicDir: {
        dirType: downloadsDirectory,
        subPath: name
      }
    }
  }
}

export enum InternOrderPackageStatus {
    PENDING_COLLECTED = 1,
    COLLECTED = 2,
    SORTER_IN = 3,
    SORTER_OUT = 4,
    JAIL_IN = 5,
    JAIL_OUT = 6,
    SORTER_RACK_IN = 7,
    RECEIVED = 8,
    WAREHOUSE_OUTPUT = 9
  }

import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ReturnModel} from "../../../../../services/src/models/endpoints/Return";
import {MatPaginator, MatTableDataSource} from "@angular/material";
import AvailableProductsGrouped = ReturnModel.AvailableProductsGrouped;
import AssignedProductsGrouped = ReturnModel.AssignedProductsGrouped;
import Order = ReturnModel.Order;
import FilterOption = ReturnModel.FilterOption;
import Pagination = ReturnModel.Pagination;
import Brand = BrandModel.Brand;
import Return = ReturnModel.Return;
import {BrandModel} from "../../../../../services/src/models/endpoints/Brand";
import {validators} from "../../../utils/validators";
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import {IntermediaryService} from "@suite/services";
import {ReturnService} from "../../../../../services/src/lib/endpoint/return/return.service";
import {DefectiveManagementService} from "../../../../../services/src/lib/endpoint/defective-management/defective-management.service";

@Component({
  selector: 'suite-data',
  templateUrl: './available-items-grouped.component.html',
  styleUrls: ['./available-items-grouped.component.scss']
})
export class AvailableItemsGroupedComponent implements OnInit {

  @Input() listAssignedProductsGrouped: AssignedProductsGrouped[] = [];
  @Input() listUnitiesGrouped: AvailableProductsGrouped[] = [];
  @Input() assigned: boolean = false;
  @Input() showUnities: boolean = false;
  @Input() isDefect: boolean = false;
  @Input() return: Return;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  defects: any;
  dataSource: any;
  displayedColumns: string[] = ['check', 'provider', 'brand', 'units'];
  order: Order = {
    field: 'provider',
    direction: 'DESC'
  };
  filterOptions: {
    providers: FilterOption[],
    brands: FilterOption[],
    units: FilterOption[]
  } = {
    providers: [],
    brands: [],
    units: []
  };
  filters: {
    providers: string[],
    brands: string[],
    units: number[]
  } = {
    providers: [],
    brands: [],
    units: []
  };
  pagerValues = [20, 50, 100];
  pagination: Pagination = {
    limit: this.pagerValues[0],
    page: 1
  };
  data: Available[];
  selectedForm: FormGroup = this.formBuilder.group({}, {
    validators: validators.haveItems("toSelect")
  });

  itemsIdSelected: Array<any> = [];

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private defectiveManagementService: DefectiveManagementService,
    private intermediaryService: IntermediaryService,
    private returnService: ReturnService
  ) {}

  ngOnInit() {
      if(this.isDefect){
        this.defectiveManagementService.getIndex().subscribe(
          (resp:any) => {
            this.defects = resp;
          },
          async err => {
            await this.intermediaryService.dismissLoading()
          },
          async () => {
            await this.intermediaryService.dismissLoading()
          }
        )
      }
      this.paginator.pageSizeOptions = this.pagerValues;
      this.paginator.page.subscribe(paginator => {
        this.pagination.limit = paginator.pageSize;
        this.pagination.page = paginator.pageIndex+1;
        this.reloadAvailableUnits();
      });
      this.data = [];
      if(this.listUnitiesGrouped) {
        for (let provider of this.listUnitiesGrouped as AvailableProductsGrouped[]) {
          if (!this.filterOptions.providers.map(x => x.value).includes(provider.name)) {
            this.filterOptions.providers.push({
              id: provider.name,
              value: provider.name,
              checked: false,
              hide: false
            });
          }
          for (let brand of provider.brands) {
            this.data.push({
              providerId: provider.id,
              provider: provider.name,
              brandId: brand.id,
              brand: brand.name,
              units: brand.unities
            });
            if (!this.filterOptions.brands.map(x => x.value).includes(brand.name)) {
              this.filterOptions.brands.push({
                id: brand.name,
                value: brand.name,
                checked: false,
                hide: false
              });
            }
            if (!this.filterOptions.units.map(x => x.value).includes(brand.unities)) {
              this.filterOptions.units.push({
                id: brand.unities,
                value: brand.unities,
                checked: false,
                hide: false
              });
            }
          }
        }
      }else{
        this.dataSource = null;
        this.data = [];
      }
      this.paginator.length = this.data.length;
      this.reloadAvailableUnits();

  }

  reloadAvailableUnits(){
    //clone data
    let filteredOrderedData: Available[] = this.data.slice();
    //apply filters
    if(this.filters.providers.length > 0){
      let deleteCount: number = 0;
      let newData = filteredOrderedData.slice();
      for(let index = 0; index < filteredOrderedData.length; index++){
        if(!this.filters.providers.includes(filteredOrderedData[index].provider)){
          newData.splice(index-deleteCount, 1);
          deleteCount++;
        }
      }
      filteredOrderedData = newData;
    }
    if(this.filters.brands.length > 0){
      let deleteCount: number = 0;
      let newData = filteredOrderedData.slice();
      for(let index = 0; index < filteredOrderedData.length; index++){
        if(!this.filters.brands.includes(filteredOrderedData[index].brand)){
          newData.splice(index-deleteCount, 1);
          deleteCount++;
        }
      }
      filteredOrderedData = newData;
    }
    if(this.filters.units.length > 0){
      let deleteCount: number = 0;
      let newData = filteredOrderedData.slice();
      for(let index = 0; index < filteredOrderedData.length; index++){
        if(!this.filters.units.includes(filteredOrderedData[index].units)){
          newData.splice(index-deleteCount, 1);
          deleteCount++;
        }
      }
      filteredOrderedData = newData;
    }
    //order data
    switch(this.order.field){
      case 'provider':
        filteredOrderedData.sort((a, b) => {return this.order.direction == 'DESC' ? a.provider.localeCompare(b.provider) : b.provider.localeCompare(a.provider)});
        break;
      case 'brand':
        filteredOrderedData.sort((a, b) => {return this.order.direction == 'DESC' ? a.brand.localeCompare(b.brand) : b.brand.localeCompare(a.brand)});
        break;
      case 'units':
        filteredOrderedData.sort((a, b) => {return this.order.direction == 'DESC' ? a.units-b.units : b.units-a.units});
    }
    this.initSelectForm();
    //apply pagination and finish
    this.dataSource = new MatTableDataSource<Available>(filteredOrderedData.slice((this.pagination.page-1)*this.pagination.limit, this.pagination.page*this.pagination.limit));
  }

  orderBy(column: string){
    if(this.order.field == column){
      this.order.direction == 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.reloadAvailableUnits();
  }

  filterByDefect(event){
    let defectId = event.detail.value;
    this.loadOfAvailabilities(defectId);
    this.reloadAvailableUnits();
  }

  private loadOfAvailabilities(defectTypeId) {
    if (this.return) {
      if (this.return.type && this.return.warehouse) {
        this.intermediaryService.presentLoading();
        this.returnService
          .postGetAvailableProductsGrouped({
            warehouse: this.return.warehouse.id,
            defective: this.return.type.defective,
            defectType: defectTypeId ? defectTypeId : null
          })
          .subscribe(res => {
            if (res.code == 200 && res.data.available_products) {
              this.listUnitiesGrouped = res.data.available_products;
              this.ngOnInit();
            } else {
              this.listUnitiesGrouped = null;
            }
            this.intermediaryService.dismissLoading();
          }, error => this.listUnitiesGrouped = null);
      } else {
        this.listUnitiesGrouped = null;
      }
    }
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        values.push(item.id);
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.reloadAvailableUnits();
  }

  async close() {
    await this.modalController.dismiss();
  }

  async select() {
    await this.modalController.dismiss(this.itemsIdSelected);
  }

  /**
   * init selectForm controls
   */
  initSelectForm(): void {
    this.selectedForm.removeControl("toSelect");
    this.selectedForm.addControl("toSelect", this.formBuilder.array(this.data.map(item => new FormControl(false))));
  }

  prevent(e): void {
    e.preventDefault();
    e.stopPropagation();
  }

  itemSelected(item) {
    const index = this.itemsIdSelected.indexOf(item, 0);
    if (index > -1) {
      this.itemsIdSelected.splice(index, 1);
    } else {
      this.itemsIdSelected.push(item);
    }

  }

  async selectRow(item) {
    const index = this.itemsIdSelected.indexOf(item, 0);
    if (index > -1) {
      this.itemsIdSelected.splice(index, 1);
    } else {
      this.itemsIdSelected.push(item);
    }
    await this.modalController.dismiss(this.itemsIdSelected);
  }

}

interface Available {
  providerId: number,
  provider: string,
  brandId: number,
  brand: string,
  units: number
}

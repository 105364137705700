import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from "@angular/animations";
import {RolModel, UserModel, IntermediaryService, TariffModel} from "@suite/services";
import {forkJoin, Observable} from "rxjs";
import { HttpResponse } from "@angular/common/http";
import { ModalController } from "@ionic/angular";
import { CrudService } from '../../../common/ui/crud/src/lib/service/crud.service';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { validators } from '../utils/validators';
import { Router } from '@angular/router';
import { UpdateComponent } from './modals/update/update.component';
import { StoreComponent } from './modals/store/store.component';
import { SorterTemplateService } from '../../../services/src/lib/endpoint/sorter-template/sorter-template.service';
import { TemplateSorterModel } from '../../../services/src/models/endpoints/TemplateSorter';
import { switchMap } from 'rxjs/operators';
import {SorterProvider} from "../../../services/src/providers/sorter/sorter.provider";
import FilterOptionsResponse = TemplateSorterModel.FilterOptionsResponse;
import Pagination = TemplateSorterModel.Pagination;
import Filters = TemplateSorterModel.Filters;
import FilterOptions = TemplateSorterModel.FilterOptions;
import Order = TemplateSorterModel.Order;
import Template = TemplateSorterModel.Template;
import SearchParameters = TemplateSorterModel.SearchParameters;
import SearchResponse = TemplateSorterModel.SearchResponse;
import {PaginatorComponent} from "../components/paginator/paginator.component";
import {SorterModule} from "./sorter.module";

@Component({
  selector: 'suite-sorter',
  templateUrl: './sorter.component.html',
  styleUrls: ['./sorter.component.scss'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateY(0)' })),
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.17s ease-in-out')
      ]),
      transition(':leave', [
        animate('0.17s ease-in-out', style({ transform: 'translateY(100%)' }))
      ])
    ]),
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', visibility: 'hidden', padding: '0' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class SorterComponent implements OnInit {

  displayedColumns = ['delete', 'nombre', 'zona', 'carriles', 'dropdown'];
  //dataSource = new ExampleDataSource();
  displayedColumnsWareHouse: any = ['check', 'name'];
  selectedForm: FormGroup;
  selectedFormActive: FormGroup;
  items: FormArray;
  toDeleteIds: number[] = [];
  pagerValues = [50, 100, 500];

  private page: number = 0;
  private limit: number = this.pagerValues[0];
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  filters: Filters = {
    nombres: [],
    zonas: [],
    carriles: []
  };
  filterOptions: FilterOptions = {
    nombres: [],
    zonas: [],
    carriles: []
  };
  order: Order = {
    field: 'date',
    direction: 'DESC'
  };
  pagination: Pagination = {
    limit: undefined,
    page: 1,
    selectPage: 1,
    lastPage: 1,
    totalResults: 0
  };

  private TEMPLATE_DELETION_SUCCESS = 0;
  private ACTIVE_TEMPLATE_DELETION_ERROR = 1;
  private TEMPLATE_WITH_PRODUCTS_DELETION_ERROR = 2;

  constructor(
    private crudService: CrudService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalController: ModalController,
    private sorterTemplateService: SorterTemplateService,
    private intermediaryService: IntermediaryService,
    private sorterProvider: SorterProvider
  ) {
    this.selectedForm = this.formBuilder.group(
      {
        selector: false,
        selects: this.formBuilder.array([this.createSelect()]),
        global: false
      },
      {
        validators: validators.haveItems('toSelect')
      }
    );

    this.selectedFormActive = this.formBuilder.group(
      {
        selector: false,
        selects: this.formBuilder.array([this.createSelect()])
      },
      {
        validators: validators.haveItems('toSelectActive')
      }
    );
  }


  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  expandedElement: any;
  showExpasion: boolean = false;
  warehouses: any = [];
  templates: TemplateSorterModel.Template[] = [];

  ngOnInit() {
    this.sorterProvider.templateToEditSelected = null;
    this.crudService
      .getIndex('Warehouses')
      .then(
        (
          data: Observable<
            HttpResponse<UserModel.ResponseIndex | RolModel.ResponseIndex>
          >
        ) => {
          data.subscribe(
            (
              res: HttpResponse<
                UserModel.ResponseIndex | RolModel.ResponseIndex
              >
            ) => {
              this.warehouses = res.body.data;
              this.initSelect(this.warehouses);
            },
            (err) => {
              console.log(err)
            }, () => {

            }
          );
        }
      );
    this.loadFilters();
  }

  ngAfterViewInit() {
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.listenChanges();
    this.getTemplates();
  }

  clickShowExpasion(row: TemplateSorterModel.Template) {
    event.stopPropagation();
    this.sorterProvider.templateToEditSelected = row;
    this.router.navigate([`/sorter/templates/${row.id}/${row.equalParts}`]);
  }

  selectAll(event): void {
    let value = event.detail.checked;
    const controlArray = <FormArray>this.selectedForm.get('toSelect');
    controlArray.controls.forEach((control, i) => {
      control.setValue(value);
    });
    if(value){
      this.templates.forEach(template => {
        this.toDeleteIds.push(template.id);
      });
    }else{
      this.toDeleteIds = [];
    }
  }

  initSelect(items) {
    this.selectedForm.removeControl('toSelect');
    this.selectedForm.addControl('toSelect', this.formBuilder.array(items.map(item => new FormControl(Boolean(false)))));
  }

  selectAllActive(event): void {
    let value = event.detail.checked;
    const controlArray = <FormArray>this.selectedFormActive.get('toSelectActive');
    controlArray.controls.forEach((control, i) => {
      control.setValue(value);
    });
  }

  initSelectActive(items: TemplateSorterModel.Template[]) {
    this.selectedFormActive.removeControl('toSelectActive');
    this.selectedFormActive.addControl('toSelectActive', this.formBuilder.array(
      items.map(item => new FormControl(Boolean(item.active))))
    );
    this.intermediaryService.dismissLoading();
  }

  createSelect(): FormControl {
    return new FormControl(Boolean(false));
  }

  loadFilters(){
    this.sorterTemplateService.getFilterOptions().then((response: FilterOptionsResponse) => {
      if(response.code == 200){
        this.filterOptions = response.data;
      }else{
        console.error(response);
      }
    }).catch(console.error);
  }

  getTemplates() {
    this.intermediaryService.presentLoading();
    const parameters: SearchParameters = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };
    this.sorterTemplateService.getIndexWithFilters(parameters).then((data: SearchResponse) => {
      if(data.code == 200) {
        this.templates = data.data.result;
        const paginator = data.data.pagination;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;
      }
      this.initSelectActive(this.templates);
    });
  }

  listenChanges(): void {
    let previousPageSize = this.pagination.limit;
    this.intermediaryService.dismissLoading();
    /**detect changes in the paginator */
    this.paginator.page.subscribe(page => {
      /**true if only change the number of results */
      let flag = previousPageSize == page.pageSize;
      previousPageSize = page.pageSize;
      this.pagination.limit = page.pageSize;
      this.pagination.page = flag ? page.pageIndex : 1;
      this.getTemplates();
    });
  }

  async update(row): Promise<void> {
    let modal = (await this.modalController.create({
      component: UpdateComponent,
      componentProps: {
        templateId: row.id
      }
    }));
    modal.onDidDismiss().then((response) => {
      this.toDeleteIds = [];
      this.initSelect(this.warehouses);
      this.initSelectActive(this.templates);
      this.selectedForm.get('global').setValue(false);
      if (response.data) {
        this.getTemplates();
      }
    });
    modal.present();
  }

  async store(): Promise<void> {
    let modal = (await this.modalController.create({
      component: StoreComponent
    }));
    modal.onDidDismiss().then((response) => {
      this.toDeleteIds = [];
      this.initSelect(this.warehouses);
      this.initSelectActive(this.templates);
      this.selectedForm.get('global').setValue(false);
      if (response.data) {
        this.getTemplates();
      }
    });
    modal.present();
  }

  active(element, check) {
    event.stopPropagation();
    let payload = element;
    payload = {
      active: check.value,
      ...payload
    }
    this.intermediaryService.presentLoading();
    this.sorterTemplateService.updateTemplateSorter(payload, payload.id).subscribe((data) => {
    }, (err) => {
      console.log(err)
    }, () => {
      this.getTemplates();
      this.intermediaryService.dismissLoading();
    });
  }

  toDeleteTemplate(index) {
    event.stopPropagation();
    let repeat = false;
    let idToDelete = this.templates[index].id;
    this.toDeleteIds.forEach(id => {
      if (id === idToDelete) {
        repeat = true;
      }
    });

    if (!repeat) {
      this.toDeleteIds.push(idToDelete);
    }
  }

  delete() {

    if (this.toDeleteIds.length) {

      const requests = [];
      this.toDeleteIds.forEach(id => {
        requests.push(this.sorterTemplateService.deleteTemplateSorter(id));
      });

      this.intermediaryService.presentLoading();

      forkJoin(requests).subscribe((responses) => {
        const successDeletions = [];
        const activeErrors = [];
        const productsErrors = [];

        for (const response of responses) {
          switch (response.data.result) {

            case this.TEMPLATE_DELETION_SUCCESS:
              successDeletions.push(response.data);
              break;

            case this.ACTIVE_TEMPLATE_DELETION_ERROR:
              activeErrors.push(response.data);
              break;

            case this.TEMPLATE_WITH_PRODUCTS_DELETION_ERROR:
              productsErrors.push(response.data);
              break;

          }
        }

        if (successDeletions.length == requests.length) {
          this.toDeleteIds = [];
          const controlArray = <FormArray>this.selectedForm.get('toSelect');
          controlArray.controls.forEach((control, i) => {
            control.setValue(false);
          });
          this.getTemplates();
          if (successDeletions.length == 1) {
            this.intermediaryService.presentToastSuccess("La plantilla " + successDeletions[0].name + " ha sido eliminada con éxito.");
          } else {
            this.intermediaryService.presentToastSuccess("Todas las plantillas seleccionadas han sido eliminadas con éxito.");
          }
        } else {
          let errorsMsg = '';

          if (successDeletions.length) {
            if (successDeletions.length == 1) {
              errorsMsg += 'Se ha eliminado con éxito la plantilla ' + successDeletions[0].name;
            } else {
              errorsMsg += 'Se han eliminado con éxitos las plantillas ';
              for (let i = 0; i < successDeletions.length; i++) {
                if (i == successDeletions.length - 1) {
                  errorsMsg += ' y ' + successDeletions[i].name;
                } else if (i == 0) {
                  errorsMsg += successDeletions[i].name;
                } else {
                  errorsMsg += ', ' + successDeletions[i].name;
                }
              }
            }
            errorsMsg += ', pero se han producido los siguientes errores: \n';
          } else {
            errorsMsg += 'No se ha podido eliminar ninguna plantilla debido a los siguientes errores: \n';
          }

          if (activeErrors.length) {
            if (activeErrors.length == 1) {
              errorsMsg += ' - La plantilla ' + activeErrors[0].name + ' aún está siendo usada por el Sorter. Es necesario cambiar la plantilla en uso.';
            } else {
              errorsMsg += ' - Las plantillas ';
              for (let i = 0; i < activeErrors.length; i++) {
                if (i == activeErrors.length - 1) {
                  errorsMsg += ' y ' + activeErrors[i].name;
                } else if (i == 0) {
                  errorsMsg += activeErrors[i].name;
                } else {
                  errorsMsg += ', ' + activeErrors[i].name;
                }
              }
              errorsMsg += ' aún están siendo usadas por el Sorter. Es necesario cambiar las plantillas en uso.';
            }
          }

          if (productsErrors.length) {
            errorsMsg += '\n';
            if (productsErrors.length == 1) {
              errorsMsg += ' - La plantilla ' + productsErrors[0].name + ' aún está asociada a productos. Es necesario que no haya productos asociados a dicha plantilla.';
            } else {
              errorsMsg += ' - Las plantillas ';
              for (let i = 0; i < productsErrors.length; i++) {
                if (i == productsErrors.length - 1) {
                  errorsMsg += ' y ' + productsErrors[i].name;
                } else if (i == 0) {
                  errorsMsg += productsErrors[i].name;
                } else {
                  errorsMsg += ', ' + productsErrors[i].name;
                }
              }
              errorsMsg += ' aún están asociadas a productos. Es necesario que no haya productos asociados a dichas plantillas.';
            }
          }
          errorsMsg += '\n\nUna vez solucionados los errores indicados, por favor inténtelo de nuevo.';
          if (successDeletions.length) {
            this.toDeleteIds = [];
            const controlArray = <FormArray>this.selectedForm.get('toSelect');
            controlArray.controls.forEach((control, i) => {
              control.setValue(false);
            });
            this.getTemplates();
            this.intermediaryService.presentToastWarning(errorsMsg, 8000, "top");
          } else {
            this.toDeleteIds = [];
            this.getTemplates();
            this.intermediaryService.presentToastError(errorsMsg, "top", 8000);
            const controlArray = <FormArray>this.selectedForm.get('toSelect');
            controlArray.controls.forEach((control, i) => {
              control.setValue(false);
            });
          }
        }

        this.intermediaryService.dismissLoading();

      }, (error) => {
        this.toDeleteIds = [];
        this.intermediaryService.dismissLoading();
        this.getTemplates();
        this.intermediaryService.presentToastError("No se ha podido eliminar alguna o todas las plantillas seleccionadas debido a un error inesperado: " + error.errors);
        const controlArray = <FormArray>this.selectedForm.get('toSelect');
        controlArray.controls.forEach((control, i) => {
          control.setValue(false);
        });
      });

    }
  }

  orderBy(column: string){
    if(this.order.field == column){
      this.order.direction == 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.getTemplates();
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        values.push(item.id);
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.pagination.page = 1;
    this.getTemplates();
  }

}

// export class ExampleDataSource extends DataSource<any> {
//   /** Connect function called by the table to retrieve one stream containing the data to render. */
//   data = [
//     { icon: '', Ntemplate: 1, zona: 2, dropdown: false, nombre: 'template1', carriles: 20 },
//     { icon: '', Ntemplate: 2, zona: 2, dropdown: false, nombre: 'template2', carriles: 23 },
//     { icon: '', Ntemplate: 3, zona: 2, dropdown: false, nombre: 'template3', carriles: 26 },
//   ];
//   connect(): Observable<Element[]> {
//     const rows = [];
//     this.data.forEach(element => rows.push(element, { detailRow: true, element }));
//     console.log(rows);
//     return of(rows);
//   }

//   disconnect() { }
// }

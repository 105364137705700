import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from './store/store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule
  ]
})
export class ModalsModule { }

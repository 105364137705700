import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from "@ionic/angular";
import { FilterItemsListComponent } from "../filter-items-list/filter-items-list.component";
import {
  environment,
  IntermediaryService,
  ReceptionAvelonModel,
  ReceptionsAvelonService
} from "@suite/services";
import { ReceptionAvelonProvider } from "../../../../../services/src/providers/reception-avelon/reception-avelon.provider";
import { LoadingMessageComponent } from "../../../components/loading-message/loading-message.component";
import { ToolbarProvider } from "../../../../../services/src/providers/toolbar/toolbar.provider";
import { PrinterService } from "../../../../../services/src/lib/printer/printer.service";
import { ModalModelImagesComponent } from "../modal-model-images/modal-model-images.component";
import { PositionsToast } from "../../../../../services/src/models/positionsToast.type";
import {ActivatedRoute, Router} from "@angular/router";
import { LocalStorageProvider } from "../../../../../services/src/providers/local-storage/local-storage.provider";
import {ScreenResult} from "../../../receptions-avelon/enums/screen_result.enum";
import {ModalDestinyReceptionComponent} from "../../modals/modal-model-images/destiny-reception.component";
import {TypeModelVisualization} from "../../../receptions-avelon/enums/model_visualization.enum";
import {TypeColorVisualization} from "../../../receptions-avelon/enums/color_visualization.enum";
import Data = ReceptionAvelonModel.Data;

@Component({
  selector: 'suite-manual-reception',
  templateUrl: './manual-reception.component.html',
  styleUrls: ['./manual-reception.component.scss']
})
export class ManualReceptionComponent implements OnInit, OnDestroy {

  @ViewChild(LoadingMessageComponent) loadingMessageComponent: LoadingMessageComponent;

  public brandSelected: ReceptionAvelonModel.Data = null;
  public modelSelected: ReceptionAvelonModel.Data = null;
  public modelIdSelected: number = null;
  public colorSelected: ReceptionAvelonModel.Data = null;
  public eanCode: string = null;

  expeditionLines: {
    id: number,
    state: number,
    brandId: number,
    modelId: number,
    colorId: number
  }[];
  public listBrands: any[] = [];
  public listModels: any[] = [];
  public listColors: any[] = [];
  public listBrandsAll: any[] = [];
  public listModelsAll: any[] = [];
  public listColorsAll: any[] = [];
  public listSizes: ReceptionAvelonModel.LoadSizesList[] = [];

  public resultsList: any[] = [];

  lastPrint;
  isReceptionWithoutOrder: boolean = false;

  public TypesModel = TypeModelVisualization;
  public TypesColor = TypeColorVisualization;
  public typeModelVisualization = TypeModelVisualization.MODEL_NAME;
  public typeColorVisualization = TypeColorVisualization.COLOR_NAME;
  public lastTypeModelVisualization = TypeModelVisualization.MODEL_NAME;
  public lastTypeColorVisualization = TypeColorVisualization.COLOR_NAME;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private receptionsAvelonService: ReceptionsAvelonService,
    private printerService: PrinterService,
    private intermediaryService: IntermediaryService,
    private receptionAvelonProvider: ReceptionAvelonProvider,
    private toolbarProvider: ToolbarProvider,
    private router: Router,
    private localStorageProvider: LocalStorageProvider
  ) { }

  async ngOnInit() {
    const tmpTypeModelVisualization = await this.localStorageProvider.get('typeModelVisualization');
    if (tmpTypeModelVisualization) {
      this.typeModelVisualization = parseInt(tmpTypeModelVisualization.toString());
      this.lastTypeModelVisualization = this.typeModelVisualization;
    }

    const tmpTypeColorVisualization = await this.localStorageProvider.get('typeColorVisualization');
    if (tmpTypeColorVisualization) {
      this.typeColorVisualization = parseInt(tmpTypeColorVisualization.toString());
      this.lastTypeColorVisualization = this.typeColorVisualization;
    }

    if (this.activatedRoute.snapshot && this.activatedRoute.snapshot.routeConfig && this.activatedRoute.snapshot.routeConfig.path) {
      this.isReceptionWithoutOrder = /^free\//.test(this.activatedRoute.snapshot.routeConfig.path);
    }
    this.loadReceptions();
    this.eanCode = this.activatedRoute.snapshot.paramMap.get("ean");
    this.toolbarProvider.showBackArrow.next(true);
    this.toolbarProvider.currentPage.next('Recepción manual');
    this.toolbarProvider.optionsActions.next([
      {
        label: 'recargar',
        icon: 'refresh',
        action: () => this.resetData()
      }
    ]);

    if (!this.eanCode) {
      try {
        const lastPrint = await this.localStorageProvider.get('lastPrint');
        if (lastPrint) {
          const lastPrintParsed = JSON.parse(String(lastPrint));
          if (lastPrintParsed) {
            this.lastPrint = lastPrintParsed;
            this.brandSelected = this.lastPrint.brand;
            this.modelSelected = this.lastPrint.model;
            this.modelIdSelected = this.modelSelected.id;
            this.colorSelected = this.lastPrint.color;
            this.listSizes = this.lastPrint.sizes;
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  ngOnDestroy() {
    this.toolbarProvider.showBackArrow.next(false);
    this.toolbarProvider.optionsActions.next([]);
  }

  resetSizes() {
    for (let size of this.listSizes) {
      size.quantity = 0;
    }
  }

  private resetData(dataToConcat = null) {
    this.brandSelected = null;
    this.modelSelected = null;
    this.modelIdSelected = null;
    this.colorSelected = null;
    this.listBrands = [];
    this.listModels = [];
    this.listColors = [];
    this.listSizes = [];
    this.resultsList = [];
    this.loadReceptions(dataToConcat);
  }

  private loadReceptions(dataToConcat = null) {
    this.intermediaryService.presentLoading();
    this.receptionsAvelonService
      .getReceptions(this.receptionAvelonProvider.expeditionData.providerId, this.typeModelVisualization, this.typeColorVisualization)
      .subscribe((res) => {
        if (res) {
          this.expeditionLines = res.lines;
          this.listBrands = res.brands;
          this.listModels = res.models;
          this.listColors = res.colors;
          this.listBrandsAll = res.brands;
          this.listModelsAll = res.models;
          this.listColorsAll = res.colors;
          if (dataToConcat) {
            for (let brand of dataToConcat.brands) {
              if (!this.listBrands.find(b => b.id == brand.id)) {
                this.listBrands.push(brand);
              }
            }
            for (let color of dataToConcat.colors) {
              const colorInList = this.listColors.find(c => c.id == color.id);
              if (!!colorInList) {
                for (let model of color.belongsModels) {
                  if (!colorInList.belongsModels.find(m => m == model)) {
                    colorInList.belongsModels.push(model);
                  }
                }
              } else {
                this.listColors.push(color);
              }
            }
            for (let model of dataToConcat.models) {
              const modelInList = this.listModels.find(m => m.name == model.name);
              if (!!modelInList) {
                for (let modelId of model.available_ids) {
                  if (!modelInList.available_ids.find(i => i == modelId)) {
                    modelInList.available_ids.push(modelId);
                  }
                }
                for (let photo in model.photos_models) {
                  if (!modelInList.photos_models) {
                    modelInList.photos_models = {};
                  }
                  if (!modelInList.photos_models[photo]) {
                    modelInList.photos_models[photo] = model.photos_models[photo];
                  }
                }
              } else {
                this.listModels.push(model);
              }
            }
          }
        }
      }, (error)=>{
        this.intermediaryService.dismissLoading();
        if (error && error.error && error.error.code == 400 && error.error.message == 'Error') {
          let message: string = 'Ha ocurrido un error al cargar los datos de las recepciones.';
          if(error.error.errors){
            message = error.error.errors;
          }
          this.intermediaryService.presentToastError(message, PositionsToast.BOTTOM);
        } else {
          let message: string = 'No ha sido posible conectarse con el servidor.';
          if(error && error.status){
            message = '['+error.status+'] '+message;
          }
          this.intermediaryService.presentToastError(message, PositionsToast.BOTTOM);
        }
      }, async () => {
        await this.intermediaryService.dismissLoading();
        if (this.modelSelected && this.colorSelected) {
          await this.checkDuplicates();
        }
      });
  }

  public async listItems(itemToList: number) {
    let listItemsForFilter = [];
    let filterType = '';

    switch (itemToList) {
      case 1:
        filterType = 'Marcas';
        listItemsForFilter = this.getAllBrandsByMoldelAndColorSelected(this.modelSelected, this.colorSelected);
        break;
      case 2:
        filterType = this.typeModelVisualization == this.TypesModel.MODEL_NAME ? 'Modelos' : (this.typeModelVisualization == this.TypesModel.MODEL_REFERENCE ? 'Referencias' : 'Detalles-Artículos');
        listItemsForFilter = this.getAllModelsByBrandsAndColorsSelected(this.brandSelected, this.colorSelected);
        break;
      case 3:
        filterType = this.typeColorVisualization == this.TypesColor.COLOR_NAME ? 'Colores' : 'Detalles-Colores';
        listItemsForFilter = this.getAllColorsByBrandAndModelSelected(this.brandSelected, this.modelSelected);
        break;
    }

    const modal = await this.modalController.create({
      component: FilterItemsListComponent,
      componentProps: {
        filterListType: filterType,
        listItems: listItemsForFilter
      }
    });

    modal.onDidDismiss().then((data) => {
      if (data && data.data) {
        if (data.data.filterListType == 'Marcas') {
          this.brandSelected = data.data.itemSelected;
          this.listModels = this.getAllModelsByBrandsAndColorsSelected(this.brandSelected, this.colorSelected);
          this.listColors = this.getAllColorsByBrandAndModelSelected(this.brandSelected, this.modelSelected);
          this.getModelAndColorColors(this.brandSelected.id);
        } else if (data.data.filterListType == 'Modelos' || data.data.filterListType == 'Referencias' || data.data.filterListType == 'Detalles-Artículos') {
          this.modelSelected = data.data.itemSelected;
          this.listBrands = this.getAllBrandsByMoldelAndColorSelected(this.modelSelected, this.colorSelected);
          this.listColors = this.getAllColorsByBrandAndModelSelected(this.brandSelected, this.modelSelected);
          this.getColorColors(this.modelSelected.id);
        } else if (data.data.filterListType == 'Colores' || data.data.filterListType == 'Detalles-Colores') {
          this.colorSelected = data.data.itemSelected;
          this.listBrands = this.getAllBrandsByMoldelAndColorSelected(this.modelSelected, this.colorSelected);
          this.listModels = this.getAllModelsByBrandsAndColorsSelected(this.brandSelected, this.colorSelected);
        }
        this.updateFilterLists(data.data.itemSelected, data.data.filterListType);
      }
    });

    modal.present();
  }

  getModelAndColorColors(brandId: number) {
    let greenModels: number[] = [];
    let orangeModels: number[] = [];
    let greenColors: number[] = [];
    let orangeColors: number[] = [];
    for (let line of this.expeditionLines) {
      if (!brandId || line.brandId == brandId) {
        if (line.state == 2) {
          if (!greenModels.includes(line.modelId)) {
            greenModels.push(line.modelId);
          }
          if (!greenColors.includes(line.colorId)) {
            greenColors.push(line.colorId);
          }
        } else {
          if (!orangeModels.includes(line.modelId)) {
            orangeModels.push(line.modelId);
          }
          if (!orangeColors.includes(line.colorId)) {
            orangeColors.push(line.colorId);
          }
        }
      }
    }
    orangeModels = orangeModels.filter(model => { return !greenModels.includes(model) });
    orangeColors = orangeColors.filter(color => { return !greenColors.includes(color) });
    for (let model of this.listModels) {
      if (greenModels.includes(model.id)) {
        model.color = 'green';
      } else {
        if (orangeModels.includes(model.id)) {
          model.color = 'orange';
        } else {
          model.color = 'red';
        }
      }
    }
    for (let color of this.listColors) {
      if (greenColors.includes(color.id)) {
        color.color = 'green';
      } else {
        if (orangeColors.includes(color.id)) {
          color.color = 'orange';
        } else {
          color.color = 'red';
        }
      }
    }
  }

  getColorColors(modelId: number) {
    let greenColors: number[] = [];
    let orangeColors: number[] = [];
    for (let line of this.expeditionLines) {
      if (!modelId || line.modelId == modelId) {
        if (line.state == 2 && !greenColors.includes(line.colorId)) {
          greenColors.push(line.colorId);
        } else {
          if (line.state != 2 && !orangeColors.includes(line.colorId)) {
            orangeColors.push(line.colorId);
          }
        }
      }
    }
    orangeColors = orangeColors.filter(color => { return !greenColors.includes(color) });
    for (let color of this.listColors) {
      if (greenColors.includes(color.id)) {
        color.color = 'green';
      } else {
        if (orangeColors.includes(color.id)) {
          color.color = 'orange';
        } else {
          color.color = 'red';
        }
      }
    }
  }

  private updateFilterLists(filterUsed, typeFilter: string) {
    let listModels = [];
    let listBrands = [];
    let listSizes = [];
    let listColors = [];

    if (filterUsed.belongsModels) {
      filterUsed.belongsModels.forEach(modelId => {
        const modelsFilter = this.listModels.filter(model => !!model.available_ids.find(id => id == modelId));
        modelsFilter.forEach(m => {
          const modelFind = listModels.find(elem => elem.id == m.id);
          if (modelFind === undefined) {
            listModels.push(m)
          }
        });

        /***************************brands******************************/
        const brandsFilter = this.listBrands.filter(elem => {
          if (elem.belongsModels.find(elem => elem === modelId)) {
            return elem
          }
        });
        brandsFilter.forEach(elem => {
          if (listBrands.find(data => data.id === elem.id) === undefined) {
            listBrands.push(elem)
          }
        });

        /*****************************color****************************/
        const colorFilter = this.listColors.filter(elem => {
          if (elem.belongsModels.find(elem => elem === modelId)) {
            return elem
          }
        });
        colorFilter.forEach(elem => {
          if (listColors.find(data => data.id === elem.id) === undefined) {
            listColors.push(elem)
          }
        })
      })
    } else {
      const modelsFilter = this.listModels.filter(model => model.id === filterUsed.id);
      modelsFilter.forEach(m => {
        const modelFind = listModels.find(elem => elem.id == m.id);
        if (modelFind === undefined) {
          listModels.push(m)
        }
      });

      /***************************brands******************************/
      const brandsFilter = this.listBrands.filter(elem => !!elem.belongsModels.find(model => !!filterUsed.available_ids.find(id => id == model)));
      brandsFilter.forEach(elem => {
        if (listBrands.find(data => data.id === elem.id) === undefined) {
          listBrands.push(elem)
        }
      });

      /*****************************color****************************/
      const colorFilter = this.listColors.filter(elem => !!elem.belongsModels.find(model => !!filterUsed.available_ids.find(id => id == model)));
      colorFilter.forEach(elem => {
        if (listColors.find(data => data.id === elem.id) === undefined) {
          listColors.push(elem)
        }
      })
    }

    if (listModels.length > 0) {
      this.listModels = listModels;
      if (!this.modelSelected && this.listModels.length == 1) {
        this.modelSelected = this.listModels[0];
        this.modelIdSelected = this.modelSelected.id;
      }

      if (this.colorSelected && this.modelSelected) {
        this.modelIdSelected = this.modelSelected.available_ids.find(id => !!this.colorSelected.belongsModels.find(model => model == id));
      }
    }
    if (listBrands.length > 0) {
      this.listBrands = listBrands;
      if (!this.brandSelected && this.listBrands.length == 1) {
        this.brandSelected = this.listBrands[0];
      }
    }
    if (listColors.length > 0) {
      this.listColors = listColors;
      if (!this.colorSelected && this.listColors.length == 1) {
        this.colorSelected = this.listColors[0];
        this.modelIdSelected = this.modelSelected.available_ids.find(id => !!this.colorSelected.belongsModels.find(model => model == id));
      }
    }

    if (this.modelSelected && this.colorSelected) {
      this.checkDuplicates();
    }
  }

  async checkDuplicates() {
    if (this.typeModelVisualization == TypeModelVisualization.MODEL_REFERENCE) {
      this.loadSizes();
    } else {
      const availableIds: number[] = this.modelSelected.available_ids.filter(id => id != this.modelSelected.id);
      let duplicatedModelIds: number[] = [];
      if (this.modelSelected.color == 'red') {
        const brandModelIds: number[] = this.brandSelected.belongsModels;
        const colorModelIds: number[] = this.colorSelected.belongsModels;
        duplicatedModelIds = availableIds.filter(id => brandModelIds.includes(id) && colorModelIds.includes(id));
      } else {
        const lineModelIds: number[] = this.expeditionLines.map(line => {
          if (line.brandId == this.brandSelected.id && line.colorId == this.colorSelected.id) {
            return line.modelId;
          }
        });
        duplicatedModelIds = availableIds.filter(id => lineModelIds.includes(id));
      }
      duplicatedModelIds = duplicatedModelIds.filter(id => id != this.modelIdSelected);
      if (duplicatedModelIds.length > 0) {
        duplicatedModelIds.push(this.modelIdSelected);
        const modelsWithReferences: {id: number, reference: string}[] = (await this.receptionsAvelonService.postGetModelsReferences(duplicatedModelIds)).data;
        await this.openShowSelectableList(modelsWithReferences);
      } else {
        this.loadSizes();
      }
    }
  }

  public async openShowSelectableList(modelsWithReferences: {id: number, reference: string}[]) {
    const listItemsForFilter = modelsWithReferences.map(m => {
      return {id: m.id, name: m.reference, color: null}
    });

    const modal = await this.modalController.create({
      component: FilterItemsListComponent,
      componentProps: {
        filterListType: 'Seleccione un artículo',
        listItems: listItemsForFilter
      }
    });

    modal.onDidDismiss().then((result) => {
      console.log('result:',result);
      if (result && result.data) {
        this.modelIdSelected = result.data.itemSelected.id;
        this.loadSizes();
      }else{
        this.resetData();
      }
    });

    await this.intermediaryService.presentWarning('La combinación seleccionada ha encontrado los siguientes artículos diferentes. Por favor, seleccione el artículo que está recepcionando.', await modal.present());
  }

  private getAllModelsByBrandsAndColorsSelected(brandSelected, colorSelected){
    let availableModels = [];
    if(brandSelected && brandSelected.belongsModels && brandSelected.belongsModels.length > 0){
      availableModels = brandSelected.belongsModels;
    }
    if(colorSelected && colorSelected.belongsModels && colorSelected.belongsModels.length > 0){
      if(availableModels.length > 0){
        availableModels = availableModels.filter((id) => colorSelected.belongsModels.includes(id));
      } else {
        availableModels = colorSelected.belongsModels;
      }
    }
    return this.getAllModelsAvailableModels(availableModels);
  }

  private getAllColorsByBrandAndModelSelected(brandSelected, modelSelected){
    let availableModels = [];
    if(brandSelected && brandSelected.belongsModels && brandSelected.belongsModels.length > 0){
      availableModels = brandSelected.belongsModels;
    }
    if(modelSelected && modelSelected.available_ids && modelSelected.available_ids.length > 0){
      if(availableModels.length > 0){
        availableModels = availableModels.filter((id) => modelSelected.available_ids.includes(id));
      } else {
        availableModels = modelSelected.available_ids
      }
    }
    return this.getAllColorsByAvailableModels(availableModels);
  }

  private getAllBrandsByMoldelAndColorSelected(modelSelected, colorSelected){
    let availableModels = [];
    if(modelSelected && modelSelected.available_ids && modelSelected.available_ids.length > 0){
      availableModels = modelSelected.available_ids;
    }
    if(colorSelected && colorSelected.belongsModels && colorSelected.belongsModels.length > 0){
      if(availableModels.length > 0){
        availableModels = availableModels.filter((id) => colorSelected.belongsModels.includes(id));
      } else {
        availableModels = colorSelected.belongsModels;
      }
    }
    return this.getAllBrandsByAvailableModels(availableModels);
  }

  private getAllModelsAvailableModels(availableModels : number[]){
    if(availableModels && availableModels.length > 0){
      let listModels = [];
      availableModels.forEach(modelId => {
        const modelsFilter = this.listModelsAll.filter(model => !!model.available_ids.find(id => id == modelId));
        modelsFilter.forEach(m => {
          const modelFind = listModels.find(elem => elem.id == m.id);
          if (modelFind === undefined) {
            listModels.push(m)
          }
        });
      })
      return listModels;
    } else {
      return this.listModelsAll;
    }
  }

  private getAllColorsByAvailableModels(availableModels : number[]){
    if(availableModels && availableModels.length > 0){
      let listColors = [];
      availableModels.forEach(modelId => {
        const colorsFilter = this.listColorsAll.filter(color => !!color.belongsModels.find(id => id == modelId));
        colorsFilter.forEach(m => {
          const colorFind = listColors.find(elem => elem.id == m.id);
          if (colorFind === undefined) {
            listColors.push(m)
          }
        });
      })
      return listColors;
    } else {
      return this.listColorsAll;
    }
  }

  private getAllBrandsByAvailableModels(availableModels : number[]){
    if(availableModels && availableModels.length > 0){
      let listBrands = [];
      availableModels.forEach(modelId => {
        const brandsFilter = this.listBrandsAll.filter(brand => !!brand.belongsModels.find(id => id == modelId));
        brandsFilter.forEach(m => {
          const brandFind = listBrands.find(elem => elem.id == m.id);
          if (brandFind === undefined) {
            listBrands.push(m)
          }
        });
      })
      return listBrands;
    } else {
      return this.listBrandsAll;
    }
  }

  public async showImages(ev) {
    const photoForModel = this.getPhotoUrl(this.modelIdSelected);
    if (photoForModel) {
      const popover = await this.popoverController.create({
        component: ModalModelImagesComponent,
        event: ev,
        cssClass: 'popover-images',
        mode: 'ios',
        componentProps: {
          image: photoForModel
        }
      });

      return await popover.present();
    }
  }

  public printCodes() {
    if (this.eanCode) {
      this.checksEanAndPrint(this.eanCode);
    } else {
      this.checksNotifyReceptionAndPrint();
    }
  }

  public getPhotoUrl(modelId): string | boolean {
    if (modelId && this.modelSelected.photos_models && this.modelSelected.photos_models[modelId]) {
      return environment.urlBase + this.modelSelected.photos_models[modelId];
    }

    return false;
  }

  private loadSizes() {
    this.receptionsAvelonService
      .postLoadSizesList({ modelId: this.modelIdSelected, colorId: this.colorSelected.id })
      .subscribe((res: ReceptionAvelonModel.ResponseLoadSizesList) => {
        if (res.code == 200) {
          this.listSizes = res.data;
        } else {
          this.intermediaryService.presentToastError('Ha ocurrido un error al intentar cargar las tallas correspondientes.', PositionsToast.BOTTOM);
        }
      }, (error) => {
        this.intermediaryService.presentToastError('Ha ocurrido un error al intentar cargar las tallas correspondientes.', PositionsToast.BOTTOM);
      });
  }

  public removeEanCode() {
    this.eanCode = null;
  }

  public sizeSelectedInSelector(item: ReceptionAvelonModel.LoadSizesList) {
    for (let size of this.listSizes) {
      if (size.id != item.id) {
        size.quantity = 0;
      }
    }
  }

  private checksEanAndPrint(eanCode: string) {
    if (this.checkOnlyOneSizeAndOneQuantity()) {
      this.checksNotifyReceptionAndPrint(eanCode);
    }
  }

  private checksNotifyReceptionAndPrint(eanToAssociate: string = null) {
    const sizesToPrint = this.listSizes.filter(s => {
      return s.quantity > 0;
    });

    if (sizesToPrint.length > 0) {
      this.loadingMessageComponent.show(true, 'Imprimiendo códigos');

      const deliveryNote = this.receptionAvelonProvider.deliveryNote;
      const sizesMapped = sizesToPrint.map(s => {
        const sizeMapped: any = {
          providerId: this.receptionAvelonProvider.expeditionData.providerId,
          expedition: this.receptionAvelonProvider.expeditionData.reference,
          brandId: this.brandSelected.id,
          colorId: this.colorSelected.id,
          sizeId: s.id,
          modelId: this.modelIdSelected,
          quantity: s.quantity
        };
        if (eanToAssociate) {
          sizeMapped.ean = eanToAssociate;
        }
        return sizeMapped;
      });

      const paramsRequest = [];
      for (let size of sizesMapped) {
        for (let q = 0; q < size.quantity; q++) {
          paramsRequest.push(size);
        }
      }

      let params: ReceptionAvelonModel.ParamsToPrint = {
        to_print: paramsRequest,
        model_field: this.typeModelVisualization == this.TypesModel.MODEL_NAME ? 'name' : (this.typeModelVisualization == this.TypesModel.MODEL_REFERENCE ? 'reference' : 'supplierReference')
      };
      if (deliveryNote) {
        params.delivery_note = deliveryNote;
      }

      const subscribeResponseOk = async (res) => {
        this.loadingMessageComponent.show(false);
        const referencesToPrint = res.resultToPrint.map(r => r.reference);
        if (referencesToPrint && referencesToPrint.length > 0) {
          const markAsPrinted = async () => {
            const parameters = {
              resultToPrint: res.resultToPrint
            };
            this.receptionsAvelonService.markAsPrinted(parameters).then(response => {
              if(!response || !response.code || response.code != 200){
                console.error('Error al tratar de marcar como impreso alguno de los productos:', response);
              }
            }, response => {
              console.error('Error al tratar de marcar como impreso alguno de los productos:', response);
            }).catch(error => {
              console.error('Error al tratar de marcar como impreso alguno de los productos:', error);
            });
          };
          this.printerService.printTagBarcode(referencesToPrint, 1, markAsPrinted, ()=>{
            this.receptionsAvelonService
              .postCreateIncidenceForNotPrints({references: referencesToPrint})
              .subscribe(
                () => console.log('- Incidences for received products not printed generated! -'),
                (error) => console.error('- Error to generate incidences for received products not printed >> ', error)
              )
          })
            .subscribe(async (resPrint) => {
              console.log('Print reference of reception successful');
              if (typeof resPrint == 'boolean') {
                console.log(resPrint);
              } else {
                resPrint.subscribe((resPrintTwo) => {
                  console.log('Print reference of reception successful two', resPrintTwo);
                })
              }
              let lastPrint = {
                brand: this.brandSelected,
                model: this.modelSelected,
                color: this.colorSelected,
                sizes: this.listSizes.map(s => {s.quantity = 0; return s;})
              };
              await this.localStorageProvider.set('lastPrint', JSON.stringify(lastPrint));
            }, (error) => {
              console.error('Some error success to print reference of reception', error);
              this.receptionsAvelonService
                .postCreateIncidenceForNotPrints({references: referencesToPrint})
                .subscribe(
                  () => console.log('- Incidences for received products not printed generated! -'),
                  (error) => console.error('- Error to generate incidences for received products not printed >> ', error)
                );
            });

          const someProductToSorter = !!res.resultToPrint.find(r => r.type == ScreenResult.SORTER_VENTILATION);
          let typeDestinyReception = someProductToSorter ? ScreenResult.SORTER_VENTILATION : ScreenResult.WAREHOUSE_LOCATION;
          const modalDestiny = await this.modalController.create({
            component: ModalDestinyReceptionComponent,
            componentProps: { typeDestinyReception: typeDestinyReception }
          });
          modalDestiny.onDidDismiss().then(_ => {
            const routeSections = ['receptions-avelon', 'app'];
            if (this.isReceptionWithoutOrder) {
              routeSections.push('free');
            }

            this.router.navigate(routeSections)
          });
          modalDestiny.present();
        }
        if (res.productsWithError && res.productsWithError.length > 0) {
          let errorMessage = `No se han podido generar e imprimir alguna de las etiquetas necesarias (${res.productsWithError.length}). <br/>Se detalla la incidencia a continuación: <ul>`;
          for (let error of res.productsWithError) {
            errorMessage += `<li>${error.reason}</li>`
          }
          errorMessage += '</ul>';
          this.intermediaryService.presentWarning(errorMessage, null);
          this.intermediaryService.presentToastError('Ha ocurrido un error inesperado al intentar imprimir algunas de las etiquetas necesarias.', PositionsToast.BOTTOM);
        }
      };
      const subscribeResponseError = (error) => {
        this.loadingMessageComponent.show(false);
        this.intermediaryService.presentToastError('Ha ocurrido un error al intentar imprimir las etiquetas necesarias.', PositionsToast.BOTTOM);
      };

      if (this.isReceptionWithoutOrder) {
        this.receptionsAvelonService.makeReceptionFree(params).subscribe(subscribeResponseOk, subscribeResponseError);
      } else {
        this.receptionsAvelonService.printReceptionLabel(params).subscribe(subscribeResponseOk, subscribeResponseError);
      }
    } else {
      this.intermediaryService.presentWarning('Indique qué talla(s) desea imprimir y qué cantidad de etiquetas quiere imprimir por cada talla.', null);
    }
  }

  //region Selection of data visualization for model and color
  public async changeModelValue() {
    this.lastTypeModelVisualization = this.typeModelVisualization;
    await this.localStorageProvider.set('typeModelVisualization', this.typeModelVisualization);
    this.resetData();
  }

  public async changeColorValue() {
    this.lastTypeColorVisualization = this.typeColorVisualization;
    await this.localStorageProvider.set('typeColorVisualization', this.typeColorVisualization);
    this.resetData();
  }
  //endregion

  // check that user only has selected one size and one unity for that size to associate to EAN code
  private checkOnlyOneSizeAndOneQuantity(): boolean {
    const sizesToPrint = this.listSizes.filter(s => {
      return s.quantity > 0;
    });
    if (!sizesToPrint || sizesToPrint.length < 1) {
      this.intermediaryService.presentWarning('Seleccione 1 Ud. de una talla para asociar al EAN escaneado.', null);
      return false;
    } else if (sizesToPrint.length > 1) {
      this.intermediaryService.presentWarning('Seleccione solo una talla para asociar al EAN escaneado. Asegúrese de que solo ha indicado 1 Ud. de la talla que desea asociar al código.', null);
      return false;
    } else if (sizesToPrint[0].quantity > 1) {
      this.intermediaryService.presentWarning('Solo puede asociar a este EAN 1 Ud. de la talla indicada.', null);
      return false;
    }

    return true;
  }
}

import { Component, OnInit,Input, Output,EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AlertController } from "@ionic/angular";
import { PrinterService } from "../../../../services/src/lib/printer/printer.service";
import {ItemReferencesProvider} from "../../../../services/src/providers/item-references/item-references.provider";
import {
  AuthenticationService, environment,
  IntermediaryService,
  PriceService,
  ProductsService,
  WarehouseModel
} from '@suite/services';
import { environment as al_environment } from "../../../../../apps/al/src/environments/environment";
import { AudioProvider } from "../../../../services/src/providers/audio-provider/audio-provider.provider";
import { KeyboardService } from "../../../../services/src/lib/keyboard/keyboard.service";
import { PositionsToast } from '../../../../services/src/models/positionsToast.type';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LabelsService } from '@suite/services';
import { ActivatedRoute, Params } from '@angular/router';
declare let ScanditMatrixSimple;
const BACKGROUND_COLOR_ERROR: string = '#e8413e';
const BACKGROUND_COLOR_INFO: string = '#15789e';
const TEXT_COLOR: string = '#FFFFFF';
const HEADER_BACKGROUND: string = '#222428';
const HEADER_COLOR: string = '#FFFFFF';

@Component({
  selector: 'suite-input-codes',
  templateUrl: './input-codes.component.html',
  styleUrls: ['./input-codes.component.scss']
})
export class InputCodesComponent implements OnInit {
  numAllSann:number =0;
  dataToWrite: string = 'ETIQUETA DE ENVÍO';
  inputProduct: string = null;
  lastCodeScanned: string = 'start';
  PrintError:boolean = false;
  alertId=0;
  private lastProductReferenceScanned: string = 'start';
  numScanner:number = 0;
  private isStoreUser: boolean = false;
  private storeUserObj: WarehouseModel.Warehouse = null;

  public typeTagsBoolean: boolean = false;

  private timeoutStarted = null;
  private readonly timeMillisToResetScannedCode: number = 1000;
  @Input() expeditionId: number;
  @Input() camera: boolean;
  @Output() state = new EventEmitter<{code: string, valid: boolean, finished: boolean }>();

  private isInternal:boolean = false;
  private orderId:number;
  private lastReferenceChecked: string = '';

  constructor(
    private intermediaryService: IntermediaryService,
    private alertController: AlertController,
    private printerService: PrinterService,
    private priceService: PriceService,
    private productsService: ProductsService,
    private authService: AuthenticationService,
    private itemReferencesProvider: ItemReferencesProvider,
    private audioProvider: AudioProvider,
    private keyboardService: KeyboardService,
    private router: Router,
    private location: Location,
    private labelService: LabelsService,
    private routeParams: ActivatedRoute,
		private detectorRef: ChangeDetectorRef
  ) {
    this.timeMillisToResetScannedCode = al_environment.time_millis_reset_scanned_code;
    this.focusToInput();
  }

  async ngOnInit() {

    if(this.labelService.getNumShippingPackageCodes() > 0){
      this.dataToWrite = `ETIQUETA DE ENVÍO ${(this.labelService.getNumShippingPackageCodesChecked() + 1)} DE ${this.labelService.getNumShippingPackageCodes()}`;
    }

    if(this.routeParams.snapshot.params.isInternal != undefined){
      this.isInternal = this.routeParams.snapshot.params.isInternal;
    }

    if(this.routeParams.snapshot.params.id != undefined){
      this.expeditionId = this.routeParams.snapshot.params.id;
    }

    if(this.routeParams.snapshot.params.orderId != undefined){
      this.orderId = this.routeParams.snapshot.params.orderId;
    }

    this.isStoreUser = await this.authService.isStoreUser();
    if (this.isStoreUser) {
      this.storeUserObj = await this.authService.getStoreCurrentUser();
    }

    if(this.camera){
      this.scanNewCode();
    }
  }

  private focusToInput() {
    setTimeout(() => {
      document.getElementById('input-ta').focus();
    }, 800);
  }

  async keyUpInput(event) {
    let dataWrote = (this.inputProduct || "").trim();
    if (event.keyCode === 13 && dataWrote) {
      if (dataWrote === this.lastCodeScanned) {
        this.inputProduct = null;
        this.focusToInput();
        return;
      }
      this.lastCodeScanned = dataWrote;
      this.lastProductReferenceScanned = dataWrote;

      if (this.timeoutStarted) {
        clearTimeout(this.timeoutStarted);
      }
      this.timeoutStarted = setTimeout(() => this.lastCodeScanned = 'start', this.timeMillisToResetScannedCode);
      this.inputProduct = null;
      if(dataWrote.trim() != ""){
        await this.checkBarcodeShipping(dataWrote);
      } else {
        this.audioProvider.playDefaultError();
        this.intermediaryService.presentToastError('El código de paquete escaneado no es válido para la operación que se espera realizar.', PositionsToast.BOTTOM).then(() => {
          this.focusInputTa();
        });
        this.focusToInput();
      }
    }
  }

  private focusInputTa() {
    setTimeout(() => {
      document.getElementById('input-ta').focus();
    }, 500);
  }

  public onFocus(event) {
    if (event && event.target && event.target.id) {
      this.keyboardService.setInputFocused(event.target.id);
    }
  }

  public async checkBarcodeShipping(barcode) {
    if (this.labelService.getNumShippingPackageCodes() > 0) {
      await this.checkShippingLabel(barcode, false);
    } else {
      this.router.navigate(['/order-preparation']);
    }
  }

  async checkShippingLabel(reference_: string, processWithScandit: boolean) {
    this.lastReferenceChecked = reference_;
    const checked = this.labelService.checkUniqueCodeInShippingPackageCodes(reference_);
    if(!checked){
      this.state.emit({code: reference_, valid: false, finished: false});
      return;
    }
    if (this.labelService.isAllShippingPackageCodesChecked()) {
      let body = {
        uniqueCodes: this.labelService.shippingPackageCodes.filter(spc=> spc.checked).map(spc => spc.uniqueCode),
        expeditionId: this.expeditionId,
        isInternal: this.isInternal,
        orderId: this.orderId
      }
      if(processWithScandit){
        ScanditMatrixSimple.showLoadingDialog('');
      } else {
        await this.intermediaryService.presentLoading();
      }
      if(!processWithScandit){
        this.labelService.postCheckShippingPackageCodes(body).subscribe(async result => {
          await this.intermediaryService.dismissLoading();
          this.state.emit({code: reference_, valid: true, finished: true});
        }, error => {
          console.log(error);
          this.intermediaryService.dismissLoading();
          this.state.emit({code: reference_, valid: false, finished: true});
        });
      } else {
        ScanditMatrixSimple.request("POST", environment.apiBase+"/opl-expedition/tracking-package/check-codes", body, localStorage.getItem("access_token"), "postCheckShippingPackageCodes");
      }
    } else {
      this.state.emit({code: reference_, valid: true, finished: false});
      if(processWithScandit){
        ScanditMatrixSimple.finish();
      }
    }
  }

  return(){
    this.router.navigate(['/order-preparation']);
  }

  async scanNewCode(){
    this.lastCodeScanned = '';
    ScanditMatrixSimple.init(async (response) => {
      if(response && response.result && response.actionIonic){
        this.executeAction(response.actionIonic, response.params);
      } else if (response && response.barcode && response.barcode.data) {
        if(response.barcode.data != this.lastCodeScanned){
          this.lastCodeScanned = response.barcode.data;
          let code = response.barcode.data;
          this.inputProduct = null;
          await this.checkShippingLabel(code, true);
        }

      } else if (response.action == 'request') {
        let responseData = null;

        if (response.data) {
          responseData = JSON.parse(response.data);
        }

        switch (response.requestType) {
          case "postCheckShippingPackageCodes":
            ScanditMatrixSimple.hideLoadingDialog();
            ScanditMatrixSimple.finish();
            if (response.code == 200 || response.code == 201) {
              this.state.emit({code: this.lastReferenceChecked, valid: true, finished: true});
            } else {
              this.state.emit({code: this.lastReferenceChecked, valid: false, finished: true});
            }
            break;
        }
      }
			this.detectorRef.detectChanges();
    }, 'Escanear Bulto', HEADER_BACKGROUND, HEADER_COLOR);
  }

  private executeAction(action: string, paramsString: string){
    let params = [];
    try{
      params = JSON.parse(paramsString);
    } catch (e) {

    }

    switch (action){
      case 'lastCodeScannedStart':
        this.lastCodeScanned = '';
        break;
      case 'hideText':
        ScanditMatrixSimple.showText(false);
        break;
    }
  }

  private hideTextMessage(delay: number){
    ScanditMatrixSimple.setTimeout("hideText", delay, "");
  }

  private androidPrintLabels(barcode){
    if(this.numScanner > 0){
      this.androidCheckShippingLabel(barcode);
    }else{
      this.router.navigate(['/order-preparation']);
    }

  }

  async androidCheckShippingLabel(reference_){
    let body = {
      uniqueCode: reference_,
      expeditionId: this.expeditionId,
      isInternal: this.isInternal,
      orderId: this.orderId
    }
    this.intermediaryService.presentLoading();
    ScanditMatrixSimple.request("POST", environment.apiBase+"/opl-expedition/tracking-package/code", body, localStorage.getItem("access_token"), "postPrintLabels");
  }

}

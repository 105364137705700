import {Component, Input, OnInit} from '@angular/core';
import {ListPickingRebuildComponent} from "../list-picking-rebuild.component";

@Component({
  selector: 'title-picking',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitlePickingComponent implements OnInit {

  @Input() filters: any;
  @Input() filterOptions: any;

  constructor(
    private listComponent: ListPickingRebuildComponent
  ) {}

  ngOnInit() {}

  applyFilters(event, column: string){
    this.listComponent.applyFilters(event, column);
  }

  orderBy(column: string){
    this.listComponent.orderBy(column);
  }

}

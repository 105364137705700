import { Injectable } from '@angular/core';
import {from, Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {PickingModel} from "../../../models/endpoints/Picking";
import {map, switchMap} from "rxjs/operators";
import {AuthenticationService} from "@suite/services";
import SearchParameters = PickingModel.SearchParameters;
import SearchResponse = PickingModel.SearchResponse;
import FilterOptionsResponse = PickingModel.FilterOptionsResponse;
import Pagination = PickingModel.Pagination;
import Filters = PickingModel.Filters;
import FilterOptions = PickingModel.FilterOptions;
import Order = PickingModel.Order;
import Picking = PickingModel.Picking;
import {RequestsProvider} from "../../../providers/requests/requests.provider";
import SearchResponseRebuild = PickingModel.SearchResponseRebuild;
import {HttpRequestModel} from "../../../models/endpoints/HttpRequest";

@Injectable({
  providedIn: 'root'
})
export class PickingService {

  /**Urls for the picking service */
  private getShowUrl = environment.apiBase+"/processes/picking-main/order/{{id}}";
  private getListByUserUrl = environment.apiBase+"/processes/picking-main/order/{{id}}/user";
  private putUpdateUrl = environment.apiBase+"/processes/picking-main";
  private putUpdateByPickingsUrl = environment.apiBase+"/processes/picking-main/pickings";
  private postVerifyPackingUrl = environment.apiBase+"/processes/picking-main/packing";
  private postResetPickingsUrl = environment.apiBase+"/processes/picking-main/reset-pickings";
  private getListPendingPickingByWorkWaveUrl = environment.apiBase + "/processes/picking-main/order/pending/";
  private getListAllPendingPickingUrl = environment.apiBase + "/processes/picking-main/order/pending";
  private getFilterOptionsUrl: string = environment.apiBase+'/processes/picking-main/filter-options';
  private getFilterOptionsWorkWaveUrl: string = environment.apiBase+'/processes/picking-main/filter-options/';
  private getListPickingByHistoryWorkWaveUrl = environment.apiBase + "/processes/picking-main/history/order/";
  private getFilterOptionsHistoryWorkWaveUrl: string = environment.apiBase+'/processes/picking-main/filter-options/history/';
  private getPendingPickingByUserUrl = environment.apiBase+"/processes/picking-main/order/pending-by-user";

  private _pickingAssignments: PickingModel.Picking[] = [];

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private requestsProvider: RequestsProvider
  ) {}

  getShow(workwaveId: number) : Observable<PickingModel.ResponseShow> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.get<PickingModel.ResponseShow>(this.getShowUrl.replace('{{id}}', workwaveId.toString()), { headers });
    }));
  }

  getListByUser(userId: number) : Observable<PickingModel.ResponseShow> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.get<PickingModel.ResponseShow>(this.getListByUserUrl.replace('{{id}}', userId.toString()), { headers });
    }));
  }

  putUpdate(workwaveId: number, pickings: Array<PickingModel.Picking>) : Observable<PickingModel.ResponseUpdate> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });

      let pickingsUpdate: PickingModel.PickingUpdate[] = pickings.map((picking: PickingModel.Picking) => {
        return {
          userId: picking.user.id,
          pikingId: picking.id
        }
      });

      let paramsPickingUpdate = {
        workwaveId: workwaveId,
        pikings: pickingsUpdate
      };

      return this.http.put<PickingModel.ResponseUpdate>(this.putUpdateUrl, paramsPickingUpdate, { headers });
    }));
  }

  putUpdateByPickings(pickings: Array<PickingModel.Picking>) : Observable<PickingModel.ResponseUpdate> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });

      let pickingsUpdate: PickingModel.PickingUpdate[] = pickings.map((picking: PickingModel.Picking) => {
        return {
          userId: picking.user.id,
          pikingId: picking.id
        }
      });

      let paramsPickingUpdate = {
        pikings: pickingsUpdate
      };

      return this.http.put<PickingModel.ResponseUpdate>(this.putUpdateByPickingsUrl, paramsPickingUpdate, { headers });
    }));
  }

  postVerifyPacking(packing) : Observable<PickingModel.ResponseUpdate> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.post<PickingModel.ResponseUpdate>(this.postVerifyPackingUrl, packing, { headers });
    }));
  }

  async getListPendingPickingByWorkwave(idWorkWave: number, params: SearchParameters): Promise<SearchResponseRebuild> {
    return this.requestsProvider.post(this.getListPendingPickingByWorkWaveUrl + idWorkWave, params);
  }

/*  getListPendingPickingByWorkwave(idWorkWave: number, params: SearchParameters): Observable<Array<PickingModel.PendingPickings>> {
    return this.http.get<PickingModel.ResponseListPendingPickings>(this.getListPendingPickingByWorkWaveUrl + idWorkWave, params).pipe(map(response => {
      return response.data;
    }));
  }*/

  async getListAllPendingPicking(params: SearchParameters): Promise<SearchResponseRebuild> {
    return this.requestsProvider.post(this.getListAllPendingPickingUrl, params);
  }
/*  getListAllPendingPicking(params: SearchParameters): Observable<Array<PickingModel.PendingPickings>> {
    return this.http.get<PickingModel.ResponseListPendingPickings>(this.getListAllPendingPickingUrl, params).pipe(map(response => {
      return response.data;
    }));
  }*/

  async getFilterOptions(): Promise<FilterOptionsResponse> {
    return this.requestsProvider.get(this.getFilterOptionsUrl);
  }

  async postResetPickings(parameters: {pickingIds: number[]}): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postResetPickingsUrl, parameters);
  }

  async getFilterOptionsByWorkwave(idWorkWave: number): Promise<FilterOptionsResponse> {
    return this.requestsProvider.get(this.getFilterOptionsWorkWaveUrl + idWorkWave);
  }

  get pickingAssignments(): PickingModel.Picking[] {
    return this._pickingAssignments;
  }

  set pickingAssignments(value: PickingModel.Picking[]) {
    this._pickingAssignments = value;
  }

  async getListPickingByHistoryWorkwave(idWorkWave: number, params: SearchParameters): Promise<SearchResponseRebuild> {
    return this.requestsProvider.post(this.getListPickingByHistoryWorkWaveUrl + idWorkWave, params);
  }

  async getFilterOptionsByHistoryWorkwave(idWorkWave: number): Promise<FilterOptionsResponse> {
    return this.requestsProvider.get(this.getFilterOptionsHistoryWorkWaveUrl + idWorkWave);
  }

  async getPendingPickingByUser() {
    return this.requestsProvider.get(this.getPendingPickingByUserUrl);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {  map } from 'rxjs/operators';

import { Observable } from 'rxjs';
import {HttpRequestModel} from "../../../models/endpoints/HttpRequest";
import {RequestsProvider} from "../../../providers/requests/requests.provider";
import {AuditsModel} from "../../../models/endpoints/Audits";

@Injectable({
  providedIn: 'root'
})
export class AuditsService {

  private postCreateAuditUrl = environment.apiBase + '/sorter/audit';
  private postCheckProductInPackingUrl = environment.apiBase + '/sorter/audit/packing/product';
  private filtersUrl = environment.apiBase+ '/sorter/audit/actives/filters';

  constructor(
    private http: HttpClient,
    private requestsProvider: RequestsProvider
  ) { }

  getAll(body?: any):Observable<any>{
    return this.http.post(environment.apiBase+ '/sorter/audit/actives',  body).pipe();
  }

  getFilters() {
    const body = {
      packings: [],
    };
    return this.http.post<HttpRequestModel.Response>(this.filtersUrl, body).pipe(
      map(resp => resp.data)
    )
  }

  getAllPendintPacking():Observable<any>{
    return this.http.get(environment.apiBase+ '/sorter/audit/packings/pending').pipe();
  }

  create(data : any):Observable<any>{
    return this.http.post(environment.apiBase+ '/sorter/audit',data).pipe();
  }

  getProducts(data : any):Observable<any>{
    return this.http.post(environment.apiBase+ '/sorter/audit/packing/products',data).pipe();
  }

  addProduct(data : any):Observable<any>{
    return this.http.post(environment.apiBase+ '/sorter/audit/audit-packing-product',data).pipe();
  }

  getById(id:any):Observable<any>{
    return this.http.get(environment.apiBase+ '/sorter/audit/'+id).pipe();
  }

  postCreateAudit(params: AuditsModel.ParamsCreateAudit) : Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postCreateAuditUrl, params);
  }

  postCheckProductInPacking(params: AuditsModel.ParamsCheckProductInPacking) : Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postCheckProductInPackingUrl, params);
  }

  getAllFilters():Observable<any>{
    return this.http.get<HttpRequestModel.Response>(environment.apiBase+ '/sorter/audit/actives/filters').pipe(
      map(resp => resp.data)
    )
  }

  getAllFiltered(body: AuditsModel.IndexRequest): Observable<AuditsModel.DataSource> {
    return this.http.post<HttpRequestModel.Response>(environment.apiBase+ '/sorter/audit/filters/actives', body).pipe(
      map(resp => resp.data)
    )
  }

}

import {Injectable} from '@angular/core';
import {ScanditProvider} from "../../../providers/scandit/scandit.provider";
import {ScanditModel} from "../../../models/scandit/Scandit";
import {CarriersService} from "../../endpoint/carriers/carriers.service";
import {CarrierModel} from "../../../models/endpoints/Carrier";
import {environment as al_environment} from "../../../../../../apps/al/src/environments/environment";
import {ItemReferencesProvider} from "../../../providers/item-references/item-references.provider";
import {environment} from "@suite/services";

declare let Scandit;
declare let GScandit;
declare let ScanditMatrixSimple;

@Injectable({
  providedIn: 'root'
})
export class SealScanditService {

  private timeoutHideText;

  private readonly timeMillisToResetScannedCode: number = 1000;

  constructor(
    private carriersService: CarriersService,
    private scanditProvider: ScanditProvider,
    private itemReferencesProvider: ItemReferencesProvider
) {
    this.timeMillisToResetScannedCode = al_environment.time_millis_reset_scanned_code;
  }

  async seal() {
    let lastCodeScanned: string = 'start';
    let codeScanned: string = null;
    let timeoutStarted = null;

    ScanditMatrixSimple.init((response: ScanditModel.ResponseSimple) => {
      if (response && response.result) {
        if (response.barcode && response.barcode.data != lastCodeScanned) {
          codeScanned = response.barcode.data;
          lastCodeScanned = codeScanned;

          if (timeoutStarted) {
            clearTimeout(timeoutStarted);
          }
          timeoutStarted = setTimeout(() => lastCodeScanned = 'start', this.timeMillisToResetScannedCode);

          if (this.itemReferencesProvider.checkCodeValue(codeScanned) == this.itemReferencesProvider.codeValue.PACKING) {
            // Seal the packing
            ScanditMatrixSimple.showLoadingDialog('Precintando embalaje...');
            ScanditMatrixSimple.request("POST", environment.apiBase + "/packing/seal", { reference: codeScanned }, localStorage.getItem("access_token"), "postSeal");
          } else {
            ScanditMatrixSimple.setText(
              'El código escaneado no es válido para la operación que se espera realizar.',
              this.scanditProvider.colorsMessage.error.color,
              this.scanditProvider.colorText.color,
              16);
            this.hideTextMessage(1500);
          }
        } else {
          if (response.action == 'matrix_simple') {
            ScanditMatrixSimple.showFixedTextBottom(true, 'Escanea el recipiente a precintar.');
          } else if (response.action == 'request') {
            let responseData = null;

            if (response.data) {
              responseData = JSON.parse(response.data);
            }

            switch (response.requestType) {
              case "postSeal":
                ScanditMatrixSimple.hideLoadingDialog();
                if (response.code == 200) {
                  let msgOk = 'El recipiente';
                  if (responseData.data.packingType == 1) {
                    msgOk = 'La jaula';
                  } else if (responseData.data.packingType == 2) {
                    msgOk = 'El pallet';
                  }
                  msgOk += ' se ha precintado correctamente.';
                  ScanditMatrixSimple.setText(
                    msgOk,
                    this.scanditProvider.colorsMessage.success.color,
                    this.scanditProvider.colorText.color,
                    16);
                  this.hideTextMessage(1500);
                } else {
                  let errorMsg = responseData && responseData.error && responseData.error.errors ? responseData.error.errors : 'Ha ocurrido un error al intentar precintar el recipiente.';
                  ScanditMatrixSimple.setText(
                    errorMsg,
                    this.scanditProvider.colorsMessage.error.color,
                    this.scanditProvider.colorText.color,
                    16);
                  this.hideTextMessage(1500);
                }
                break;
            }
          }
        }
      }
    }, 'Precintar', this.scanditProvider.colorsHeader.background.color, this.scanditProvider.colorsHeader.color.color);
  }

  private hideTextMessage(delay: number) {
    if (this.timeoutHideText) {
      clearTimeout(this.timeoutHideText);
    }
    this.timeoutHideText = setTimeout(() => {
      ScanditMatrixSimple.showText(false);
    }, delay);
  }

}



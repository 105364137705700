import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-picking-return',
  templateUrl: './picking-return.component.html',
  styleUrls: ['./picking-return.component.scss']
})
export class PickingReturnComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}

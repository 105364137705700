import {Component, OnInit, ChangeDetectorRef, ViewChild, Input} from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalController, NavParams } from "@ionic/angular";
import { WarehousesService, WarehouseGroupService, WarehouseGroupModel, BuildingModel, BuildingService, GroupWarehousePickingService, GroupWarehousePickingModel, IntermediaryService } from '@suite/services';
import { UtilsComponent } from '../../components/utils/utils.component';


@Component({
  selector: 'suite-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {
  @ViewChild(UtilsComponent) utils: UtilsComponent;
  updateForm: FormGroup = this.formBuilder.group({
    id: ['', [Validators.required]],
    name: [''],
    description: [''],
    reference: [''],
    is_store: [false, []],
    groupId: '',
    groupsWarehousePicking: [''],
    prefix_container: '',
    hasBuilding: [false, []],
    buildingId: [''],
    is_main: [false, []],
    is_defective: [false, []],
    has_racks: [false, []],
    sales_replacement: [false, []],
    receptionAsMain: [false, []],
    moveWithStockCorrections: [false, []],
    noForceLocationMainWarehouse:[false, []],
    halls: '',
    rows: '',
    columns: '',
    TypePackingId: [''],
    thresholdShippingStore: [''],
    is_outlet: false
  });
  groupWarehousesPicking: Array<GroupWarehousePickingModel.GroupWarehousePicking> = [];
  private warehouseId;
  packingTypes: Array<any> = [];
  private currentHasRacks;
  buildings: Array<BuildingModel.Building> = [];
  groups: Array<WarehouseGroupModel.WarehouseGroup> = [];

  /**wrapper for common ionic component methods like loading */
  @ViewChild(UtilsComponent) utilsComponent: UtilsComponent;
  @Input() editWarehousePermission : boolean;
  title : string;
  constructor(private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private warehousesService: WarehousesService,
    private warehouseGroupService: WarehouseGroupService,
    private cd: ChangeDetectorRef,
    private navParams: NavParams,
    private buildingService: BuildingService,
    private intermediaryService: IntermediaryService,
    private groupWarehousePickingService: GroupWarehousePickingService
  ) {
    this.warehouseId = this.navParams.data.id;
    this.getWarehouse(this.warehouseId);
  }

  /**
  * Assign and unassign validators depends of value of another validators
  */
  changeValidatorsAndValues(): void {
    let values = this.updateForm.value;
    /**Listen for changes on is_store control */
    this.updateForm.get("is_store").valueChanges.subscribe((isStore) => {
      let store = this.updateForm.get("groupId")
      store.clearValidators();
      store.setValue("");
      this.cd.detectChanges();
    });



    /**Listen for changes on hasBuilding control */
    this.updateForm.get("hasBuilding").valueChanges.subscribe((hasBuilding) => {
      let buildingId = this.updateForm.get("buildingId")
      buildingId.clearValidators();
      buildingId.setValue("");
      buildingId.setValidators(hasBuilding ? [Validators.required] : [])
      this.cd.detectChanges();
    });

    /**Listen for changes in has_racks control */
    this.updateForm.get("has_racks").valueChanges.subscribe((hasRacks) => {
      let hallways = this.updateForm.get("halls");
      let rows = this.updateForm.get("rows")
      let columns = this.updateForm.get("columns")
      let prefix = this.updateForm.get("prefix_container")
      let aux = [hallways, rows, columns].forEach(control => {
        control.clearValidators();
        control.setValue("");
        control.setValidators(control ? [Validators.required] : []);
      });
      if(prefix){
        prefix.clearValidators();
        if(hasRacks) {
          prefix.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(4)]);
        }
      }
      this.cd.detectChanges();
    });
  }


  /**
   * Get the packing types
   */
  getPackingTypes() {
    this.warehousesService.getTypePacking().subscribe((packingTypes) => {
      this.packingTypes = packingTypes;
    })
  }

  /**
   * get the warehouse to edit
   * @param id the id of warehouse
   */
  getWarehouse(id: number): void {
    this.intermediaryService.presentLoading();
    this.warehousesService.getShow(id).subscribe(warehouse => {
      /**the models in backend differs then the model is useless */
      this.currentHasRacks = warehouse.has_racks;
      let warehouseToPatch: any = warehouse;
      if (warehouse.group)
        warehouseToPatch.groupId = warehouseToPatch.group.id;
      if (warehouseToPatch.packingType)
        warehouseToPatch.TypePackingId = warehouseToPatch.packingType;
      warehouseToPatch.groupsWarehousePicking = warehouseToPatch.groupsWarehousePicking.map(group => {
        return group.id
      });
      this.updateForm.patchValue(warehouseToPatch);
    }, (err) => {
    }, () => {
      this.intermediaryService.dismissLoading();
    });
  }

  getGroupWarehousePicking(): void {
    this.groupWarehousePickingService.getIndex().subscribe(groups => {
      this.groupWarehousesPicking = groups;
    });
  }


  /**
   * Event triggered when user press a key in a field
   * @param event event triggered
   */
  onlyUpperLetters(event) {
    let key = event.key
    return /[a-zA-Z]/.test(key);
  }

  /**
 * Get all registereds buildings
 */
  getBuildings(): void {
    this.buildingService.getIndex().subscribe(buildings => {
      this.buildings = buildings
    });
  }
  onlyNumbers(event) {
    let key = event.key
    return /[0-9]/.test(key);
  }
  /**
  * delete empty values
  */
  sanitize(object: any): Object {
    object = JSON.parse(JSON.stringify(object));
    object.prefix_container = object && object.prefix_container ? object.prefix_container.toUpperCase() : null;
    if (object.thresholdShippingStore)
      object.thresholdShippingStore = parseInt(object.thresholdShippingStore)
    Object.keys(object).forEach(key => {
      let value = object[key];
      if (value === "" || value === null)
        delete object[key];
    });
    delete object.reference;
    return object;
  }

  /**
  * get wharehousesgroups to show in the select
  */
  getWharehousesGroup(): void {
    this.warehouseGroupService.getIndex().subscribe(warehousesGroups => {
      this.groups = warehousesGroups;

    });
  }

  /**
  * Save the new warehouse
  */
  submit() {
    this.intermediaryService.presentLoading();
    this.warehousesService.put(this.sanitize(this.updateForm.value)).subscribe(data => {
      this.intermediaryService.dismissLoading();
      this.utils.presentAlert("Éxito", "Almacén editado con éxito");
      this.close();
    }, (err) => {
      if(err.status === 405) {
        this.intermediaryService.presentToastError(`ERROR ACTUALIZANDO ALMACÉN: ${err.error.errors}`, 'bottom', 4500)
      }
      this.intermediaryService.dismissLoading();
    });
  }

  ngOnInit() {
    this.title = this.editWarehousePermission ? 'Actualizar almacén' : 'Información del almacén'
    this.getWharehousesGroup();
    this.getBuildings();
    this.getPackingTypes();
    this.changeValidatorsAndValues();
    this.getGroupWarehousePicking();
  }

  /**close the current instance of the modal */
  close(): void {
    this.modalCtrl.dismiss();
  }
}

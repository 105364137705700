import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule, Routes } from "@angular/router";
import { ExpeditionManualComponent } from './expedition-manual.component';
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {FabExtendedModule} from "../components/button/fab/fab-extended/fab-extended.module";

const routes: Routes = [
  {
    path: '',
    component: ExpeditionManualComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FabExtendedModule
  ],
  declarations: [ExpeditionManualComponent]
})

export class ExpeditionManualModule { }

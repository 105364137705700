import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'labels-manual',
  templateUrl: './labels-manual.component.html',
  styleUrls: ['./labels-manual.component.scss'],
})
export class LabelsManualComponent implements OnInit {
 

  constructor(
    
  ) { }

  ngOnInit() {
  
  }

  
}

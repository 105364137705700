import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'suite-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {
  packages:Array<any>
  constructor(
    private modalController: ModalController
  ) { }
  displayedColumns: string[] = [
    'deliveryRequestLineId',
    'product',
    'size',
    'brand',
    'status'
  ];
  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss()
  }

  getStatusName(status){
    return parseInt(status) == 2 ? 'Cancelado' : parseInt(status) == 3 ? 'Devuelto' : '';
  }
}

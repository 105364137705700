import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {MarketplacesService} from '../../../../../services/src/lib/endpoint/marketplaces/marketplaces.service';
import {MarketplacesMgaService} from '../../../../../services/src/lib/endpoint/marketplaces-mga/marketplaces-mga.service';
import {IntermediaryService} from "@suite/services";

@Component({
  selector: 'suite-new-rule',
  templateUrl: './update-operator-rule.component.html',
  styleUrls: ['./update-operator-rule.component.scss']
})
export class UpdateOperatorRuleComponent implements OnInit {

  provinces;
  countries;
  markets;
  logisticsOperators;
  warehousesOrigins;
  warehousesDestinies;
  rules;
  dataBody;
  isChecked = {'isChecked': false};
  marketsCheck;
  provincesCheck;
  countriesCheck;
  warehousesOriginsCheck;
  warehousesDestiniesCheck;
  operatorSelected;
  selectMarkets = false;
  selectProvinces = false;
  selectCountries = false;
  selectWarehousesOrigins = false;
  selectWarehousesDestinies = false;
  activateButton = {'markets': true, 'warehousesOrigins': true, 'warehousesDestinies': true, 'provinces': true, 'countries': true, 'logisticOperator': true};
  elementRule;
  selectedOperator;
  isIndeterminateOrigin:boolean;
  masterCheckOrigin:boolean;
  isIndeterminateDestinity:boolean;
  masterCheckDestinity:boolean;
  textInstructions;
  textareaInstructions;

  constructor(private modalController: ModalController,
              private renderer: Renderer2,
              private marketplacesService: MarketplacesService,
              private marketplacesMgaService: MarketplacesMgaService,
              private intermediaryService: IntermediaryService,
              private navParams: NavParams,) {
  }

  ngOnInit() {
    this.provinces = '';
    this.countries = '';
    this.markets = '';
    this.rules = '';
    this.warehousesOrigins = '';
    this.warehousesDestinies = '';
    this.dataBody = '';
    this.marketsCheck = [];
    this.provincesCheck = [];
    this.countriesCheck = [];
    this.warehousesOriginsCheck = [];
    this.warehousesDestiniesCheck = [];
    this.operatorSelected = '';
    this.elementRule = this.navParams.get('element');

    this.marketplacesMgaService.getProvinces().subscribe(count => {
      this.provinces = count;
      this.checkAlreadySeleted(this.provinces, this.elementRule.provincia);
      this.initSelectedChecked(this.provinces, 'province');
      if(this.provinces.length > 5){
        document.getElementById('mat-expantion-provinces').style.maxHeight = '280px';
        document.getElementById('mat-expantion-provinces').style.overflowY = 'scroll';
        document.getElementById('mat-expantion-provinces').style.marginRight = '-25px';
        document.getElementById('ion-list-provinces').style.paddingRight = '25px';
      }
    });

    this.marketplacesMgaService.getCountries().subscribe(count => {
      this.countries = count;
      this.checkAlreadySeleted(this.countries, this.elementRule.pais);
      this.initSelectedChecked(this.countries, 'country');
      if(this.countries.length > 5){
        document.getElementById('mat-expantion-countries').style.maxHeight = '280px';
        document.getElementById('mat-expantion-countries').style.overflowY = 'scroll';
        document.getElementById('mat-expantion-countries').style.marginRight = '-25px';
        document.getElementById('ion-list-countries').style.paddingRight = '25px';
      }
    });

    this.marketplacesMgaService.getMarkets().subscribe(count => {
      this.markets = count;
      this.checkAlreadySeleted(this.markets, this.elementRule.marketplace);
      this.initSelectedChecked(this.markets, 'market');
      if(this.markets.length > 5){
        document.getElementById('mat-expantion-markets').style.maxHeight = '280px';
        document.getElementById('mat-expantion-markets').style.overflowY = 'scroll';
        document.getElementById('mat-expantion-markets').style.marginRight = '-25px';
        document.getElementById('ion-list-markets').style.paddingRight = '25px';
      }
    });

    this.marketplacesMgaService.getWarehouse().subscribe(count => {
      this.warehousesOrigins = count;
      this.checkAlreadySeleted(this.warehousesOrigins, this.elementRule.warehouseOrigin);
      this.initSelectedChecked(this.warehousesOrigins, 'warehousesOrigins');
      if(this.warehousesOrigins.length > 5){
        document.getElementById('mat-expantion-warehouses-origins').style.maxHeight = '280px';
        document.getElementById('mat-expantion-warehouses-origins').style.overflowY = 'scroll';
        document.getElementById('mat-expantion-warehouses-origins').style.marginRight = '-25px';
        document.getElementById('ion-list-warehouses-origins').style.paddingRight = '25px';
      }
    });

    this.marketplacesMgaService.getWarehouse().subscribe(count => {
      this.warehousesDestinies = count;
      this.checkAlreadySeleted(this.warehousesDestinies, this.elementRule.warehouseDestiny);
      this.initSelectedChecked(this.warehousesDestinies, 'warehousesDestinies');
      if(this.warehousesDestinies.length > 5){
        document.getElementById('mat-expantion-warehouses-destinies').style.maxHeight = '280px';
        document.getElementById('mat-expantion-warehouses-destinies').style.overflowY = 'scroll';
        document.getElementById('mat-expantion-warehouses-destinies').style.marginRight = '-25px';
        document.getElementById('ion-list-warehouses-destinies').style.paddingRight = '25px';
      }
    });

    this.marketplacesMgaService.getLogisticsOperators().subscribe(count => {
      this.logisticsOperators = count;
      this.selectAlreadySeleted(this.logisticsOperators, this.elementRule.operadorLogistico);
      this.operatorsSelected(this.logisticsOperators);
    });

    this.textInstructions = this.elementRule.instruction.length > 0 ? this.elementRule.instruction : [{ id: undefined, name: undefined, textarea: undefined}];
    this.textareaInstructions = this.elementRule.instruction.length > 0 ? this.elementRule.instruction[0].textarea.toUpperCase() : undefined;
  }

  private checkAlreadySeleted(completeList, currentList) {
    for (let element of currentList) {
      const elementInCompleteList = completeList.find(e => e.id == element.id);
      if (elementInCompleteList) {
        elementInCompleteList.isChecked = true;
      }
    }
  }

  private selectAlreadySeleted(completeList, currentList) {
    for (let element of currentList) {
      const elementInCompleteList = completeList.find(e => e.id == element.id);
      if (elementInCompleteList) {
        this.selectedOperator = element.name;
      }
    }
  }

  removeItem(arr, item){
    let index;
    for(let i = 0; i < arr.length; i++){
      if(arr[i].id == item.id){
        index = i;
        break;
      }
    }
    arr.splice(index, 1);
  }

  initSelectedChecked(elements, element){
    switch(element) {
      case 'market':
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].isChecked === true) {
            this.marketsCheck.push({id: elements[i].id});
          }
        }
        break;
      case 'warehousesOrigins':
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].isChecked === true) {
            this.warehousesOriginsCheck.push({id: elements[i].id});
          }
        }
        break;
      case 'warehousesDestinies':
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].isChecked === true) {
            this.warehousesDestiniesCheck.push({id: elements[i].id});
          }
        }
        break;
      case 'province':
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].isChecked === true) {
            this.provincesCheck.push({id: elements[i].id});
          }
        }
        break;
      case 'country':
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].isChecked === true) {
            this.countriesCheck.push({id: elements[i].id});
          }
        }
        break;
      case 'logistic-operator':
        this.operatorSelected = this.elementRule.operadorLogistico.id;
        this.activateButton.logisticOperator = true;
        break;
    }
  }

  marketsSelected(market){
    if(market.isChecked === true){
      this.marketsCheck.push({id: market.id});
    }else{
      this.removeItem(this.marketsCheck, market);
    }
  }

  warehousesOriginsSelected(warehouse){
    if(warehouse.isChecked === true){
      this.warehousesOriginsCheck.push({id: warehouse.id});
    }else{
      this.removeItem(this.warehousesOriginsCheck, warehouse);
    }
  }

  warehousesDestiniesSelected(warehouse){
    if(warehouse.isChecked === true){
      this.warehousesDestiniesCheck.push({id: warehouse.id});
    }else{
      this.removeItem(this.warehousesDestiniesCheck, warehouse);
    }
  }

  provincesSelected(province){
    if(province.isChecked === true){
      this.provincesCheck.push({id: province.id});
    }else{
      this.removeItem(this.provincesCheck, province);
    }
  }

  countriesSelected(country){
    if(country.isChecked === true){
      this.countriesCheck.push({id: country.id});
    }else{
      this.removeItem(this.countriesCheck, country);
    }
  }

  operatorsSelected(operator){
    for(let i = 0; i < this.logisticsOperators.length; i++){
      if(operator === this.logisticsOperators[i].name){
        this.operatorSelected = this.logisticsOperators[i].id;
        this.activateButton.logisticOperator = true;
      }
    }
  }

  updateRules(){
    // if the rule had an instruction and now the textarea is empty ==> the instruction is removed
    if(this.elementRule.instruction.length > 0 && this.textareaInstructions == ''){
      this.marketplacesMgaService.deleteInstructions(this.textInstructions[0].id).subscribe(instructionsData => {
        this.dataBody = {
          "id": this.elementRule.idRule,
          "logisticOperator": this.operatorSelected,
          "markets": this.marketsCheck,
          "warehousesOrigins": this.warehousesOriginsCheck,
          "warehousesDestinies": this.warehousesDestiniesCheck,
          "provinces": this.provincesCheck,
          "countries": this.countriesCheck,
          "instructions": instructionsData
        };
        this.marketplacesMgaService.updateRules(this.dataBody).subscribe(count => {
          this.rules = count;
          this.intermediaryService.presentToastSuccess("Regla guardada con éxito");
          this.modalController.dismiss(null);
        }, error => {
          this.intermediaryService.presentToastError("Error al guardar la regla");
          this.modalController.dismiss(null);
        });
      }, error => {
        this.intermediaryService.presentToastError("Error al guardar la regla");
        this.modalController.dismiss(null);
      });
    }else{
      // if the rule it already had an instruction and now the textarea contains text ==> the rule and instruction is updated
      if(this.elementRule.instruction.length > 0 && this.textareaInstructions != undefined){
        let bodyInstructions = {
          "instruction": this.textInstructions ?
            { id: this.textInstructions[0].id, instruction: this.textareaInstructions.toUpperCase().replace(/\n/g, '\n').toString() }
            : null
        };
        this.marketplacesMgaService.updateInstructions(bodyInstructions.instruction).subscribe(instructionsData => {
          this.dataBody = {
            "id": this.elementRule.idRule,
            "logisticOperator": this.operatorSelected,
            "markets": this.marketsCheck,
            "warehousesOrigins": this.warehousesOriginsCheck,
            "warehousesDestinies": this.warehousesDestiniesCheck,
            "provinces": this.provincesCheck,
            "countries": this.countriesCheck,
            "instructions": [instructionsData]
          };
          this.marketplacesMgaService.updateRules(this.dataBody).subscribe(count => {
            this.rules = count;
            this.intermediaryService.presentToastSuccess("Regla guardada con éxito");
            this.modalController.dismiss(null);
          }, error => {
            this.marketplacesMgaService.deleteInstructions(instructionsData.id).subscribe(instructionsData => {});
            this.intermediaryService.presentToastError("Error al guardar la regla");
            this.modalController.dismiss(null);
          });
        }, error => {
          this.intermediaryService.presentToastError("Error al guardar la regla");
          this.modalController.dismiss(null);
        });
      // if the rule did not have an instruction and the textarea has text ==> a new instruction is created
      }else if(this.elementRule.instruction.length == 0 && this.textareaInstructions != undefined){
        let bodyInstructions = {
          "instruction": this.textInstructions ? this.textareaInstructions.toUpperCase().replace(/\n/g, '\n').toString() : null
        };
        this.marketplacesMgaService.createInstructions(bodyInstructions).subscribe(instructionsData => {
          this.dataBody = {
            "id": this.elementRule.idRule,
            "logisticOperator": this.operatorSelected,
            "markets": this.marketsCheck,
            "warehousesOrigins": this.warehousesOriginsCheck,
            "warehousesDestinies": this.warehousesDestiniesCheck,
            "provinces": this.provincesCheck,
            "countries": this.countriesCheck,
            "instructions": instructionsData
          };
          this.marketplacesMgaService.updateRules(this.dataBody).subscribe(count => {
            this.rules = count;
            this.intermediaryService.presentToastSuccess("Regla guardada con éxito");
            this.modalController.dismiss(null);
          }, error => {
            this.marketplacesMgaService.deleteInstructions(instructionsData.id).subscribe(instructionsData => {});
            this.intermediaryService.presentToastError("Error al guardar la regla");
            this.modalController.dismiss(null);
          });
        }, error => {
          this.intermediaryService.presentToastError("Error al guardar la regla");
          this.modalController.dismiss(null);
        });
      // if not, the rule is updated without the instruction
      }else{
        this.dataBody = {
          "id": this.elementRule.idRule,
          "logisticOperator": this.operatorSelected,
          "markets": this.marketsCheck,
          "warehousesOrigins": this.warehousesOriginsCheck,
          "warehousesDestinies": this.warehousesDestiniesCheck,
          "provinces": this.provincesCheck,
          "countries": this.countriesCheck,
          "instructions": []
        };
        this.marketplacesMgaService.updateRules(this.dataBody).subscribe(count => {
          this.rules = count;
          this.intermediaryService.presentToastSuccess("Regla guardada con éxito");
          this.modalController.dismiss(null);
        }, error => {
          this.intermediaryService.presentToastError("Error al guardar la regla");
          this.modalController.dismiss(null);
        });
      }
    }
  }

  openSelects(select){
    if(select === 'markets'){
      this.selectMarkets = true;
      this.selectWarehousesOrigins = false;
      this.selectWarehousesDestinies = false;
      this.selectProvinces = false;
      this.selectCountries = false;
    }
    if(select === 'warehousesOrigins'){
      this.selectWarehousesOrigins = true;
      this.selectWarehousesDestinies = false;
      this.selectMarkets = false;
      this.selectProvinces = false;
      this.selectCountries = false;
    }
    if(select === 'warehousesDestinies'){
      this.selectWarehousesDestinies = true;
      this.selectWarehousesOrigins = false;
      this.selectMarkets = false;
      this.selectProvinces = false;
      this.selectCountries = false;
    }
    if(select === 'provinces'){
      this.selectProvinces = true;
      this.selectMarkets = false;
      this.selectWarehousesOrigins = false;
      this.selectWarehousesDestinies = false;
      this.selectCountries = false;
    }
    if(select === 'countries'){
      this.selectCountries = true;
      this.selectProvinces = false;
      this.selectMarkets = false;
      this.selectWarehousesOrigins = false;
      this.selectWarehousesDestinies = false;
    }
    if(select === 'logistic-operator'){
      this.selectCountries = false;
      this.selectProvinces = false;
      this.selectMarkets = false;
      this.selectWarehousesOrigins = false;
    }
  }

  close(data) {
    this.modalController.dismiss(data);
  }

  checkMasterOrigin() {
    setTimeout(()=>{
      this.warehousesOrigins.forEach(obj => {
        obj.isChecked = this.masterCheckOrigin;
      });
    });
  }

  checkEventOrigin() {
    const totalItems = this.warehousesOrigins.length;
    let checked = 0;
    this.warehousesOrigins.map(obj => {
      if (obj.isChecked) checked++;
    });
    if (checked > 0 && checked < totalItems) {
      this.isIndeterminateOrigin = true;
      this.masterCheckOrigin = false;
    } else if (checked == totalItems) {
      this.masterCheckOrigin = true;
      this.isIndeterminateOrigin = false;
      this.warehousesOriginsCheck = [];
      this.warehousesOrigins.map(obj => {
        if (obj.isChecked){
          this.warehousesOriginsCheck.push(obj);
        }
      });
    } else {
      this.isIndeterminateOrigin = false;
      this.masterCheckOrigin = false;
      this.warehousesOriginsCheck = [];
    }
  }

  checkMasterDestinity() {
    setTimeout(()=>{
      this.warehousesDestinies.forEach(obj => {
        obj.isChecked = this.masterCheckDestinity;
      });
    });
  }

  checkEventDestinity() {
    const totalItems = this.warehousesDestinies.length;
    let checked = 0;
    this.warehousesDestinies.map(obj => {
      if (obj.isChecked) checked++;
    });
    if (checked > 0 && checked < totalItems) {
      this.isIndeterminateDestinity = true;
      this.masterCheckDestinity = false;
    } else if (checked == totalItems) {
      this.masterCheckDestinity = true;
      this.isIndeterminateDestinity = false;
      this.warehousesDestiniesCheck = [];
      this.warehousesDestinies.map(obj => {
        if (obj.isChecked){
          this.warehousesDestiniesCheck.push(obj);
        }
      });
    } else {
      this.isIndeterminateDestinity = false;
      this.masterCheckDestinity = false;
      this.warehousesDestiniesCheck = [];
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as io from 'socket.io-client';
import { AuthenticationService } from '../authentication/authentication.service';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from "rxjs";
import { type } from "./enums/typeData";
type Socket = SocketIOClient.Socket;

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket : Socket;
  private socketUrl : string;
  private socketPath : string;
  static emitData = new BehaviorSubject({});
  private emitData$ = WebsocketService.emitData.asObservable();

  constructor(
    private authenticationService : AuthenticationService,
    private http:HttpClient
  ) {}

  handshake = () =>{
    this.authenticationService.getCurrentToken()
    .then(async (authentication) => {
      return (await this.socket).emit('identity', authentication);
    });
  };

  errorHandler = (reason? : string) => {
    const message = `Error: [${this.socketUrl}]: ${reason}`;
    this.notificationHandler(message);
  };

  processDiscoveredSocket = (data : any) => {
    this.socketUrl = data && data.data && data.data.url ? data.data.url : '';
    this.socketPath = data && data.data && data.data.path ? data.data.path : '';
    this.init(this.socketUrl, this.socketPath);
  };

  getSocket() : Socket {
    return this.socket;
  }

  isConnected() : boolean {
    return !!this.socket;
  }

  disconnect() : void {
    this.socket.disconnect();
    delete this.socket;
  }

  async init(socketUrl : string = 'ws://localhost/ ', path?: string){
    if(path){
      this.socket = io(socketUrl, {
        path: path
      });
    } else {
      this.socket = io(socketUrl);
    }
    this.socket.on('connect', this.handshake);
    this.socket.on('notification', this.notificationHandler);
    this.socket.on('connect_error', this.errorHandler );
    this.socket.on('disconnect', this.errorHandler);
  }

  /**
   * [openConnection Initialice new connection with a SocketIO Server]
   * @param  requestUrl URL to discover Web Socket
  **/
  openConnection(requestUrl : string = 'http://localhost'){
    return this.http.get(requestUrl).subscribe(this.processDiscoveredSocket);
  }

  notificationHandler(data : string | Object, acknowledge? : Function){
    if(acknowledge){
      acknowledge(this.getSocket().id );
    }
    let dtIn:any;

    switch (typeof data) {
      case 'object':{
        dtIn = {
          'type': type.OBJECT,
          'receive': JSON.parse(JSON.stringify(data)).data
        };
        break;
      }
      case 'string':{
        dtIn = {
          'type': type.STRING,
          'receive': data
        };
        break;
      }
      case 'boolean':{
        dtIn = {
          'type': type.BOOLEAN,
          'receive': data
        };
        break;
      }
      case 'number':{
        dtIn = {
          'type': type.NUMBER,
          'receive': data
        };
        break;
      }
      default:
        break;
    }
    if(typeof data === 'object'){
      WebsocketService.setEmitData(JSON.parse(JSON.stringify(dtIn)));
    }
  }

  static setEmitData(data: any){
    WebsocketService.emitData.next(data);
  }

  getEmitData(){
    return this.emitData$;
  }

  getSubscribeReceiveData(){
    return this.emitData$;
  }

  startConnection(){
    this.openConnection(environment.apiMicroSocket+"/discover_websocket");
  }

}

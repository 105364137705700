import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {RouterModule, Routes} from "@angular/router";
import {PaginatorComponentModule} from '../components/paginator/paginator.component.module';
import {FilterButtonModule} from "../components/filter-button/filter-button.module";
import {MatTooltipModule} from "@angular/material";
import {ExpeditionIncidencesComponent} from './expedition-incidences.component';
import {MatRippleModule, MatSortModule, MatTableModule} from '@angular/material';
import {MatButtonModule} from "@angular/material/button";
import {ManageIncidenceModule} from "./manage-incidence/manage-incidence.module";
import {ManageIncidenceComponent} from "./manage-incidence/manage-incidence.component";

const routes: Routes = [
  {
    path: '',
    component: ExpeditionIncidencesComponent
  }
];

@NgModule({
  entryComponents: [ManageIncidenceComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    MatTableModule,
    RouterModule.forChild(routes),
    PaginatorComponentModule,
    FilterButtonModule,
    MatTooltipModule,
    FormsModule,
    MatRippleModule,
    MatSortModule,
    MatButtonModule,
    ManageIncidenceModule
  ],
  declarations: [ExpeditionIncidencesComponent]
})

export class ExpeditionIncidencesModule {}

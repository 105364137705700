import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment as al_environment } from "../../../../../../apps/al/src/environments/environment";
import { SorterProvider } from "../../../../../services/src/providers/sorter/sorter.provider";
import {ItemReferencesProvider} from "../../../../../services/src/providers/item-references/item-references.provider";
import { GlobalVariableService, GlobalVariableModel, IntermediaryService, CarrierService } from "@suite/services";
import { OutputSorterModel } from "../../../../../services/src/models/endpoints/OutputSorter";
import { ProductSorterModel } from "../../../../../services/src/models/endpoints/ProductSorter";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { ExecutionSorterModel } from "../../../../../services/src/models/endpoints/ExecutionSorter";
import { HttpRequestModel } from "../../../../../services/src/models/endpoints/HttpRequest";
import { SorterExecutionService } from "../../../../../services/src/lib/endpoint/sorter-execution/sorter-execution.service";
import { Location } from "@angular/common";
import { SorterOutputService } from "../../../../../services/src/lib/endpoint/sorter-output/sorter-output.service";
import { SorterOutputModel } from "../../../../../services/src/models/endpoints/SorterOutput";
import { AudioProvider } from "../../../../../services/src/providers/audio-provider/audio-provider.provider";
import { Router, ActivatedRoute } from '@angular/router';
import { WaySorterModel } from 'libs/services/src/models/endpoints/WaySorter';
import {KeyboardService} from "../../../../../services/src/lib/keyboard/keyboard.service";
import {Events} from "@ionic/angular";
import { ListasProductosComponent } from 'libs/modules/src/picking-manual/lista/listas-productos/listas-productos.component';
import { ListProductsCarrierComponent } from '../../../components/list-products-carrier/list-products-carrier.component';
import {ToolbarProvider} from "../../../../../services/src/providers/toolbar/toolbar.provider";
import {ReturnService} from "../../../../../services/src/lib/endpoint/return/return.service";
import {ReturnModel} from "../../../../../services/src/models/endpoints/Return";
import ReturnPackingsResponse = ReturnModel.ReturnPackingsResponse;
import EmptyPackingResponse = ReturnModel.EmptyPackingResponse;
@Component({
  selector: 'sorter-output-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerOutputSorterComponent implements OnInit, OnDestroy {

  messageGuide: string = '';
  inputValue: string = null;
  lastCodeScanned: string = 'start';
  processStarted: boolean = false;
  isFirstProductScanned: boolean = false;
  wrongCodeScanned: boolean = false;
  lastProductScannedChecking: ProductSorterModel.ProductSorter = null;
  packingIsFull: boolean = false;
  stopButtonPressed: boolean = false;
  hideLeftButtonFooter: boolean = true;
  hideRightButtonFooter: boolean = true;
  hideInput: boolean = false;
  lastProductScanned: boolean = false;
  outputWithIncidencesClear: boolean = false;
  isWaitingSorterFeedback = false;

  ULTIMA_JAULA:string;
  lastJailPackingInventories: any[];
  lastWarehouse = null;
  lastWarehouseReference = null;
  ultimaReferenza:string;
  alerta:boolean;

  listVariables: Array<GlobalVariableModel.GlobalVariable> = new Array<GlobalVariableModel.GlobalVariable>();
  private listTypesFromDb: Array<{ id: number, name: string, workwave: boolean, type: string, tooltip: string }> = [];
  private listVariablesFromDb: Array<GlobalVariableModel.GlobalVariable> = new Array<GlobalVariableModel.GlobalVariable>();
  private countLoadOfVariables: number = 0;
  private readonly SECONDS_COUNTDOWN_TO_CONFIRM_EMPTY_WAY: number = 4;

  private timeoutStarted = null;
  private readonly timeMillisToResetScannedCode: number = 1000;

  leftButtonText: string = 'JAULA LLENA.';
  rightButtonText: string = 'CALLE VACÍA';
  leftButtonDanger: boolean = true;

  infoSorterOperation: OutputSorterModel.OutputSorter = null;
  public waySelectedToEmptying: WaySorterModel.WaySorter = null;

  private checkByWrongCode: boolean = true;

  private launchIncidenceBeep: boolean = false;
  private intervalIncidenceBeep = null;

  private wayIsEmpty: boolean = false;
  private handlerStopOutput :boolean = false;
  private backButtonOverride: any = null;

  private lastWarehouseScan: ProductSorterModel.DestinyWarehouseSorter
  private previousWarehouseScan: ProductSorterModel.DestinyWarehouseSorter

  constructor(
    private location: Location,
    private router: Router,
    private activate: ActivatedRoute,
    private alertController: AlertController,
    private intermediaryService: IntermediaryService,
    private sorterExecutionService: SorterExecutionService,
    public sorterProvider: SorterProvider,
    private platform: Platform,
    private sorterOutputService: SorterOutputService,
    private itemReferencesProvider: ItemReferencesProvider,
    private audioProvider: AudioProvider,
    private toolbarProvider: ToolbarProvider,
    private keyboardService: KeyboardService,
    public  events: Events,
    private modalCtrl: ModalController,
    private returnService: ReturnService,
    private carrierService: CarrierService,
    private globalVariableService: GlobalVariableService,
  ) {
    this.timeMillisToResetScannedCode = al_environment.time_millis_reset_scanned_code;
    setTimeout(() => {
      document.getElementById('input').focus();
    }, 800);
    this.events.subscribe('load_of_variables', () => {
      this.countLoadOfVariables++;
      if (this.countLoadOfVariables === 2) {
        this.generateVariablesList();
        this.countLoadOfVariables = 0;
      }
    });
  }

  ngOnInit() {
    this.addStopButton();
    this.infoSorterOperation = this.sorterProvider.infoSorterOutputOperation;
    this.getTypes();
    this.getGlobalVariables();

    this.backButtonOverride = this.platform.backButton.subscribeWithPriority(999999, async () => {
      if(!this.handlerStopOutput){
        if(this.wrongCodeScanned){
          this.handlerStopOutput = true;
          await this.presentModalCancelOutputWithIncidence();
        } else {
          if(this.isFirstProductScanned && !this.packingIsFull) {
            this.handlerStopOutput = true;
            await this.presentModalCancelOutput();
          }else{
            this.handlerStopOutput = false;
            this.router.navigate(['sorter/output']);
          }
        }
      } else {
        const alert = await this.alertController.create({
          header: "Información",
          message: this.packingIsFull ? "Por favor realice las acciones indicadas para finalizar el proceso." : "Tiene una acción de parada del proceso de salida del sorter en proceso, por favor realice las acciones indicadas para finalizar el proceso.",
          buttons: [{
            text: "Aceptar",
            handler: async ()=>{

            }
          }]
        });
        await alert.present();
      }
    });
  }

  ngOnDestroy() {
    this.backButtonOverride.unsubscribe();
    this.checkByWrongCode = false;
    this.launchIncidenceBeep = false;
    this.wayIsEmpty = true;
    this.events.publish('sorter:refresh', this.wayIsEmpty);
    this.toolbarProvider.optionsActions.next([]);
  }

  addStopButton() {
    this.handlerStopOutput = false;
    const buttons = [
      {
        icon: 'hand',
        label: 'Detener proceso',
        action: async () => {
          if(!this.handlerStopOutput){
            if(this.wrongCodeScanned){
              this.handlerStopOutput = true;
              await this.presentModalCancelOutputWithIncidence();
            } else {
              if(this.isFirstProductScanned && !this.packingIsFull) {
                this.handlerStopOutput = true;
                await this.presentModalCancelOutput();
              }else{
                this.handlerStopOutput = false;
                this.router.navigate(['sorter/output']);
              }
            }
          } else {
            const alert = await this.alertController.create({
              header: "Información",
              message: this.packingIsFull ? "Por favor realice las acciones indicadas para finalizar el proceso." : "Tiene una acción de parada del proceso de salida del sorter en proceso, por favor realice las acciones indicadas para finalizar el proceso.",
              buttons: [{
                text: "Aceptar",
                handler: async ()=>{

                }
              }]
            });
            await alert.present();
          }
        }
      }];
    this.toolbarProvider.optionsActions.next(buttons);
  }

  focusToInput() {
    setTimeout(() => {
      if (!this.isWaitingSorterFeedback && document.getElementById('input')) {
        document.getElementById('input').focus()
      }
    }, 500);
  }


  private async modalList(jaula: string, products: any, destiny: string, returnPacking?: boolean) {
    let modal = await this.modalCtrl.create({
      component: ListProductsCarrierComponent,
      componentProps: {
        carrierReference:jaula,
        packingInventorys:products,
        carrierDestiny:destiny,
        process: 'sorter-emptying',
        returnPacking: returnPacking,
        isSorterProcess: true
      }
    });

    modal.onDidDismiss().then((data) => {
      console.log(data);
      if(data.data === undefined && data.role === undefined){
        this.focusToInput();
        return;
      }

      if(data.data && data.role === undefined){
        if(this.itemReferencesProvider.checkCodeValue(data.data) === this.itemReferencesProvider.codeValue.PACKING){
          // console.log('passo di qui ',this.lastCodeScanned);

          this.focusToInput();
          this.inputValue = data.data;
          this.ultimaReferenza = data.data;
          this.lastCodeScanned = 'start';
          // this.processInitiated = false;
          this.keyUpInput(KeyboardEvent['KeyCode'] = 13,true);
          return;
        }else if(data.role === 'navigate'){
          this.focusToInput();
        }
      }

      if(data.data == 'positioning/manual' && data.role == 'navigate'){
        this.router.navigate(['sorter/output']).then(()=>{
          this.toolbarProvider.currentPage.next('Ubicar/escanear con láser');
          this.toolbarProvider.optionsActions.next([]);
          this.router.navigate(['positioning/manual']);
        });
      }

    })
    modal.present();
  }

  async keyUpInput(event?,test = false) {
    this.handlerStopOutput = false;
    let dataWrote = (this.inputValue || "").trim();

    this.ultimaReferenza = dataWrote;

    if (event.keyCode === 13 || test && dataWrote) {
      if (dataWrote === this.lastCodeScanned) {
        this.inputValue = null;
        this.focusToInput();
        return;
      }
      this.lastCodeScanned = dataWrote;

      if (this.timeoutStarted) {
        clearTimeout(this.timeoutStarted);
      }
      this.timeoutStarted = setTimeout(() => this.lastCodeScanned = 'start', this.timeMillisToResetScannedCode);

      this.inputValue = null;

      if (this.itemReferencesProvider.checkCodeValue(dataWrote) === this.itemReferencesProvider.codeValue.PACKING) {
        if (this.processStarted && this.infoSorterOperation.packingReference) {

          if(this.itemReferencesProvider.checkSpecificCodeValue(dataWrote,this.itemReferencesProvider.codeValue.PACKAGE)){
            //is package
            this.scannPackage(dataWrote);
          }else{
            //code invalid
            this.audioProvider.playDefaultError();
            await this.intermediaryService.presentToastError('Código de producto erróneo.');
            this.focusToInput();
          }
        } else {
          this.sorterOutputService
            .getSingleCarrier(dataWrote)
            .subscribe(data => {
            if (!!data && !!data.data) {
              this.lastJailPackingInventories = data.data.packingInventorys;
              this.sorterOutputService.getReturnsOfPacking(data.data.reference).subscribe(async (response: ReturnPackingsResponse) => {
                if (response.code == 200) {
                  if (response.data && response.data.returnIds && response.data.returnIds.length > 0) {
                    await this.modalList(data.data.reference, response.data.returnProducts, null, true);
                  } else {
                    if (!!data.data.packingInventorys && data.data.packingInventorys.length > 0 && !test) {
                      const refWarehouseDestiny = data.data.carrierWarehousesDestiny && data.data.carrierWarehousesDestiny[0] ? data.data.carrierWarehousesDestiny[0].warehouseReference : null;
                      this.modalList(dataWrote, data.data.packingInventorys, refWarehouseDestiny);
                    } else {
                      this.assignPackingToProcess(dataWrote);
                    }
                  }
                } else {
                  console.error(response);
                }
              }, error => console.error(error));
            }
          })

        }
      } else if (this.itemReferencesProvider.checkCodeValue(dataWrote) === this.itemReferencesProvider.codeValue.PRODUCT) {
        if (this.processStarted && this.infoSorterOperation.packingReference) {
          this.outputProductFromSorter(dataWrote);
        } else if (this.packingIsFull) {
          this.audioProvider.playDefaultError();
          await this.intermediaryService.presentToastError('Escanea el nuevo embalaje a utilizar antes de continuar con los productos.');
          this.focusToInput();
        } else {
          this.audioProvider.playDefaultError();
          await this.intermediaryService.presentToastError('Escanea el embalaje a utilizar antes de comenzar con los productos.');
          this.focusToInput();
        }
      } else if(this.itemReferencesProvider.checkSpecificCodeValue(dataWrote,this.itemReferencesProvider.codeValue.PACKAGE)){
        //is package
        this.scannPackage(dataWrote);
      } else {
        if (!this.processStarted) {
          this.audioProvider.playDefaultError();
          await this.intermediaryService.presentToastError('Escanea un embalaje para comenzar las operaciones.');
          this.focusToInput();
        }
      }
    }
  }

  async scannPackage(dataWrote){
    if (this.processStarted && this.infoSorterOperation.packingReference) {
      this.outputProductFromSorter(dataWrote);
    } else if (this.packingIsFull) {
      this.audioProvider.playDefaultError();
      await this.intermediaryService.presentToastError('Escanea el nuevo embalaje a utilizar antes de continuar con los productos.');
      this.focusToInput();
    } else {
      this.audioProvider.playDefaultError();
      await this.intermediaryService.presentToastError('Escanea el embalaje a utilizar antes de comenzar con los productos.');
      this.focusToInput();
    }
  }


  generateVariablesList() {
    this.intermediaryService.dismissLoading();
    if (this.listVariablesFromDb.length !== this.listTypesFromDb.length) {
      this.listVariables = this.listVariablesFromDb;
      for (let iType in this.listTypesFromDb) {
        let type = this.listTypesFromDb[iType];
        let isTypeCreated: boolean = false;
        for (let iVariable in this.listVariablesFromDb) {
          let variable = this.listVariablesFromDb[iVariable];
          if (variable.type === type.id) {
            isTypeCreated = true;
            break;
          }
        }
        if (!isTypeCreated) {
          this.listVariables.push({ type: type.id, value: null, tooltip: null });
        }
      }
    } else {
      this.listVariables = this.listVariablesFromDb;
    }
    this.listVariables.sort((a, b) => {
      return a.type < b.type ? -1 : 1;
    })
  }

  getGlobalVariables() {
    this.globalVariableService
      .getAll()
      .subscribe((globalVariables) => {
        console.log(globalVariables);
        this.listVariablesFromDb = globalVariables;
        this.events.publish('load_of_variables');
      });
  }

  getTypes() {
    this.globalVariableService
      .getTypes()
      .subscribe((types) => {
        this.listTypesFromDb = types;
        this.events.publish('load_of_variables');
      });
  }

  async emptyWay(finish?: boolean) {
    let showModalWithCount = async (finish?: boolean) => {
      let globalVar = 5;
      let textCountdown = 'Revisa para confirmar que la calle está completamente vacía.<br/>';
      let globalFound = this.listVariables.find( global => {
        return global.type === this.SECONDS_COUNTDOWN_TO_CONFIRM_EMPTY_WAY;

      });
      if(globalFound && globalFound.value){

        globalVar = parseInt(globalFound.value);
      }
      let countdown = globalVar;
      let enableSetWayAsEmpty = false;

      let buttonCancel = {
        text: 'Cancelar',
        handler: () => this.unlockCurrentWay()
      };

      let buttonOk = {
        text: 'Confirmar',
        handler: () => this.jaulaLlena(finish)
        // handler: () => this.setWayAsEmpty()

      };

      let alertEmptyPacking = await this.alertController.create({
        header: '¿Está vacía?',
        message: textCountdown + '<h2>' + countdown + 's</h2>',
        backdropDismiss: false,
        buttons: [
          buttonCancel
        ]
      });

      await alertEmptyPacking.present();

      let intervalChangeCountdown = setInterval(() => {
        countdown--;
        if (countdown === -1) {
          clearInterval(intervalChangeCountdown);
          alertEmptyPacking.message = textCountdown + '<b>Ya puedes pulsar confirmar.</b>';
          alertEmptyPacking.buttons = [buttonCancel, buttonOk];
          enableSetWayAsEmpty = true;
        } else {
          alertEmptyPacking.message = textCountdown + '<h2>' + countdown + 's</h2>';
        }
      }, 1000);
    };

    await this.intermediaryService.presentLoading('Bloqueando procesos en calle...');

    this.sorterOutputService
      .postBlockSorterWay({
        block: true,
        wayId: this.infoSorterOperation.wayId.toString()
      })
      .then(async (res: SorterOutputModel.ResponseBlockSorterWay) => {
        if (res.code === 200) {
          await this.intermediaryService.dismissLoading();
          showModalWithCount(finish);
        } else {
          let errorMessage = 'Ha ocurrido un error al intentar bloquear los procesos en la calle actual.';
          if (res.errors) {
            errorMessage = res.errors;
          }
          await this.intermediaryService.presentToastError(errorMessage);
          await this.intermediaryService.dismissLoading();
          this.focusToInput();
        }
      }, async (error) => {
        let errorMessage = 'Ha ocurrido un error al intentar bloquear los procesos en la calle actual.';
        if (error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.presentToastError(errorMessage);
        await this.intermediaryService.dismissLoading();
        this.focusToInput();
      })
      .catch(async (error) => {
        let errorMessage = 'Ha ocurrido un error al intentar bloquear los procesos en la calle actual.';
        if (error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.presentToastError(errorMessage);
        await this.intermediaryService.dismissLoading();
        this.focusToInput();
      });
  }

  async packingFull() {
    let callback = () => {
      if (this.wrongCodeScanned) {
        this.setPackingAsFull();
      } else {
        this.packingIsFull = true;
        this.hideLeftButtonFooter = true;
        this.hideRightButtonFooter = false;
        this.hideInput = false;
        this.handlerStopOutput = true;
        this.messageGuide = 'Escanea el primer artículo de la calle para indicar el embalaje como lleno.';
        this.focusToInput();
      }
    };
    await this.intermediaryService.presentConfirm(`¿Quiere marcar el embalaje ${this.infoSorterOperation.packingReference} como lleno?`, callback, null, 'Sí', 'No');
  }

  finishOutputWithIncidences() {
    this.wrongCodeScanned = false;

    this.hideLeftButtonFooter = false;
    this.hideRightButtonFooter = false;

    this.isFirstProductScanned = false;
    this.messageGuide = '';
    this.focusToInput();

    this.checkByWrongCode = true;
    this.checkWayWithIncidence();

    this.outputWithIncidencesClear = false;
    this.launchIncidenceBeep = false;
  }

  /**
   * @description Alert cuando terminan las calles por warehoise
   */
  private async nuevaAlert(){
    this.alerta = false;

    let alert = await this.alertController.create({
      message: `No hay más calles a vaciar para la tienda ${this.previousWarehouseScan.reference}.<br/>Deberá escanear un nuevo embalaje para continuar el vaciado de otras calles con otro destino.`,
      buttons: ['Continuar']
    });

    await alert.present();
  }

  private setPreviousWarehouseReference(infoSorterOperation: OutputSorterModel.OutputSorter) {
    if(!this.lastWarehouseScan) {
      this.lastWarehouseScan = infoSorterOperation.destinyWarehouse
    }
    if(this.lastWarehouseScan && this.lastWarehouseScan.id !== infoSorterOperation.destinyWarehouse.id) {
      this.previousWarehouseScan = this.lastWarehouseScan
      this.lastWarehouseScan = infoSorterOperation.destinyWarehouse
    }
  }

  private async assignPackingToProcess(packingReference: string) {
    this.setPreviousWarehouseReference(this.infoSorterOperation)
    this.ULTIMA_JAULA = packingReference;
    this.lastWarehouse = null;
    this.lastWarehouseReference = null;

    await this.intermediaryService.presentLoading('Asignando embalaje al proceso...');

    this.sorterOutputService
      .postAssignPackingToWay({
        packingReference,
        wayId: this.infoSorterOperation.wayId.toString()
      })
      .then(async (res: SorterOutputModel.ResponseAssignPackingToWay) => {

        if (res.code === 200) {
          this.ULTIMA_JAULA = res && res.data && res.data.packing ? res.data.packing.reference : null;
          if(res.data && res.data.way && res.data.way['warehouse']){
            this.lastWarehouse = res.data.way['warehouse'];
            this.lastWarehouseReference = this.lastWarehouse && this.lastWarehouse['name'] ? this.lastWarehouse['name'] : null;
          }

          // If output process is not started yet (first packing scanned) start here to check if current way have incidences
          if (!this.processStarted) {
            this.checkWayWithIncidence();
          }
          this.messageGuide = '';
          this.processStarted = true;
          this.packingIsFull = false;
          this.lastProductScanned = false;
          this.infoSorterOperation.packingReference = packingReference;
          this.isFirstProductScanned = false;
          await this.intermediaryService.dismissLoading();
          this.audioProvider.playDefaultOk();
          await this.intermediaryService.presentToastSuccess(`Iniciando proceso con el embalaje ${packingReference}.`);
          this.focusToInput();
        } else {
          this.ULTIMA_JAULA = null;
          if(res.code === 405 && this.alerta) {
            this.nuevaAlert()
          }
          this.audioProvider.playDefaultError();
          let errorMessage = 'Ha ocurrido un error al intentar asignar el embalaje escaneado al proceso.';
          if (res.errors) {
            errorMessage = res.errors;
          }
          await this.intermediaryService.presentToastError(errorMessage);
          await this.intermediaryService.dismissLoading();
          this.focusToInput();
        }
      }, async (error) => {
        this.ULTIMA_JAULA = null;
        this.audioProvider.playDefaultError();
        let errorMessage = 'Ha ocurrido un error al intentar asignar el embalaje escaneado al proceso.';
        if (error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.presentToastError(errorMessage);
        await this.intermediaryService.dismissLoading();
        this.focusToInput();
      })
      .catch(async (error) => {
        this.ULTIMA_JAULA = null;

        this.audioProvider.playDefaultError();
        let errorMessage = 'Ha ocurrido un error al intentar asignar el embalaje escaneado al proceso.';
        if (error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.presentToastError(errorMessage);
        await this.intermediaryService.dismissLoading();
        this.focusToInput();
      });
  }

  private async outputProductFromSorter(productReference: string) {
    console.log(productReference);
    await this.intermediaryService.presentLoading('Comprobando producto escaneado...', async () => {
      await this.sorterOutputService.postScanProductPutInPackings({
        productReference,
        packingReference: this.infoSorterOperation.packingReference,
        wayId: this.infoSorterOperation.wayId,
        fullPacking: this.packingIsFull,
        incidenceProcess: this.wrongCodeScanned,
        stopButtonPressed: this.stopButtonPressed
      }).subscribe(async res => {
        await this.intermediaryService.dismissLoading();
        if (res.processStopped != undefined && !this.wrongCodeScanned) {
          this.audioProvider.playDefaultOk();
          this.intermediaryService.presentToastSuccess('Proceso de salida finalizado.', 2000);
          this.stopExecutionOutput(this.stopButtonPressed);
        } else {
          if (this.wrongCodeScanned) {

            let resData = res;
            this.messageGuide = '';
            if (!resData.productInSorter) {
              this.lastProductScannedChecking = null;
              this.audioProvider.playDefaultError();
              this.intermediaryService.presentToastError(`¡El producto ${productReference} no debería de estar en el sorter!`);
              this.focusToInput();
            } else {
              this.infoPackageOrProduct(resData);
              this.hideLeftButtonFooter = false;
              this.hideRightButtonFooter = false;
              if (this.lastProductScannedChecking.destinyWarehouse.id !== this.infoSorterOperation.destinyWarehouse.id) {
                this.audioProvider.playDefaultError();
                this.intermediaryService.presentToastError(`¡El producto ${productReference} tiene asignado un destino diferente al de la calle actual!`);
                if (resData.wayWithIncidences) {
                  this.focusToInput();
                } else {
                  this.outputWithIncidencesClear = true;
                  this.hideLeftButtonFooter = true;
                }
              } else {
                this.audioProvider.playDefaultOk();
                this.intermediaryService.presentToastSuccess(`Producto ${productReference} comprobado y válido. Puede añadirlo al embalaje.`, 2000);
                if (resData.wayWithIncidences) {
                  this.focusToInput();
                } else {
                  this.outputWithIncidencesClear = true;
                  this.hideLeftButtonFooter = true;
                }
              }
            }
          } else {
            this.audioProvider.playDefaultOk();
            this.intermediaryService.presentToastSuccess(`Producto ${productReference} comprobado y válido.`, 2000);
            if (this.packingIsFull) {

              this.lastProductScanned = true;
              this.setPackingAsFull();
            } else if (this.stopButtonPressed) {
              this.router.navigate(['sorter/output']);
            } else {

              this.hideLeftButtonFooter = false;
              this.hideRightButtonFooter = false;
              this.hideInput = true;

              this.isFirstProductScanned = true;
              this.messageGuide = '';
              this.focusToInput();
            }
          }
        }

      }, async (error) => {
        this.audioProvider.playDefaultError();
        let errorMessage = 'Ha ocurrido un error al intentar comprobar el producto escaneado.';
        if (error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.presentToastError(errorMessage);
        await this.intermediaryService.dismissLoading();
        this.focusToInput();

      });
    });

  }

  infoPackageOrProduct(resData){
    if(resData.product.reference != undefined){
      this.lastProductScannedChecking = {
        reference: resData.product.reference,
        destinyWarehouse: {
          id: resData.warehouse.id,
          name: resData.warehouse.name,
          reference: resData.warehouse.reference
        },
        model: {
          reference: resData.product.model ? resData.product.model.reference : ''
        },
        size: {
          name: resData && resData.product && resData.product.size ? resData.product.size.name : ''
        }
      };
    }else{
      this.lastProductScannedChecking = {
        reference: resData.product.uniqueCode,
        destinyWarehouse: {
          id: resData.warehouse.id,
          name: resData.warehouse.name,
          reference: resData.warehouse.reference
        },
        model: {
          reference: ''
        },

      };
    }
  }



  private async setPackingAsFull() {

    await this.intermediaryService.presentLoading('Registrado embalaje como lleno...');

    this.sorterOutputService
      .postPackingFull({
        packingReference: this.infoSorterOperation.packingReference,
        wayId: this.infoSorterOperation.wayId.toString()
      })
      .then(async (res: SorterOutputModel.ResponsePackingFull) => {
        if (res.code === 200) {
          this.audioProvider.playDefaultOk();
          if (this.wrongCodeScanned) {
            this.lastProductScannedChecking = null;
          }
          await this.intermediaryService.presentToastSuccess('Embalaje registrado como lleno en el sistema. Escanea un nuevo embalaje para continuar con el proceso.');
          await this.intermediaryService.dismissLoading();
          this.hideLeftButtonFooter = true;
          this.hideRightButtonFooter = true;
          this.infoSorterOperation.packingReference = null;
          this.messageGuide = 'Escanea un embalaje nuevo para continuar';
          this.packingIsFull = true;
          this.focusToInput();
        } else {
          this.audioProvider.playDefaultError();
          let errorMessage = 'Ha ocurrido un error al intentar registrar el embalaje como lleno.';
          if (res.errors) {
            errorMessage = res.errors;
          }
          await this.intermediaryService.presentToastError(errorMessage);
          await this.intermediaryService.dismissLoading();
          this.focusToInput();
        }
      }, async (error) => {
        this.audioProvider.playDefaultError();
        let errorMessage = 'Ha ocurrido un error al intentar registrar el embalaje como lleno.';
        if (error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.presentToastError(errorMessage);
        await this.intermediaryService.dismissLoading();
        this.focusToInput();
      })
      .catch(async (error) => {
        this.audioProvider.playDefaultError();
        let errorMessage = 'Ha ocurrido un error al intentar registrar el embalaje como lleno.';
        if (error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.presentToastError(errorMessage);
        await this.intermediaryService.dismissLoading();
        this.focusToInput();
      });
  }

  private async jaulaLlena(finish?: boolean){
    this.alerta = false;
    this.hideInput = false;
    if(this.infoSorterOperation && this.infoSorterOperation.packingReference){
      let alert = await this.alertController.create({
        header:`¿El embalaje ${this.infoSorterOperation.packingReference} está lleno? `,
        backdropDismiss: false,
        buttons:[
          {
            text:'SI',
            handler:()=>{
              this.outputWithIncidencesClear = false;
              let productReference = this.ultimaReferenza;
              this.sorterOutputService
                .postPackingFull({
                  packingReference: this.infoSorterOperation.packingReference,
                  wayId: this.infoSorterOperation.wayId.toString()
                }).then( res=>{
                if(res.code === 200){
                  this.audioProvider.playDefaultOk();
                  this.ULTIMA_JAULA = null;
                  this.setWayAsEmpty(finish);
                }
              });
            }},
          {
            text:`No`,
            handler:()=>{
              this.alerta = true;
              this.outputWithIncidencesClear = false;
              this.setWayAsEmpty(finish);
            }
          }
        ]
      })
      await alert.present();
    } else {
      this.alerta = true;
      this.outputWithIncidencesClear = false;
      this.setWayAsEmpty(finish);
    }
  }

  private async setWayAsEmpty(finish?: boolean, sealPacking?:boolean, packingReferences?:any[]) {
    await this.intermediaryService.presentLoading('Marcando la calle como vacía');
    setTimeout(() => {
      this.sorterOutputService
        .postEmptyWay({
          wayId: this.infoSorterOperation.wayId.toString()
        })
        .then(async (res: SorterOutputModel.ResponseEmptyWay) => {
          console.log(res);

          if (res.code === 200) {
            if(sealPacking && packingReferences){
              this.sorterOutputService.postSealList(packingReferences).subscribe(async () => {
                  this.packingIsFull = false;
              });
            }
            this.launchIncidenceBeep = false;
            this.audioProvider.playDefaultOk();
            this.sorterProvider.colorActiveForUser = null;
            await this.intermediaryService.dismissLoading();
            this.hideLeftButtonFooter = true;
            this.hideRightButtonFooter = true;
            this.lastProductScannedChecking = null;
            await this.intermediaryService.presentToastSuccess(`Registrada la calle como vacía.`);

            /*
            * If is a sorter emptying process for a way set as 'manual emptying'
            *   will finish the output process and not search a new way
            * */
            if (this.infoSorterOperation && this.infoSorterOperation.manualEmpty) {
              finish = true;
            }

            this.stopExecutionOutput(finish);
          } else {
            this.audioProvider.playDefaultError();
            let errorMessage = 'Ha ocurrido un error al intentar marcar como vacía la calle.';
            if (res.errors) {
              errorMessage = res.errors;
            }
            await this.intermediaryService.presentToastError(errorMessage);
            await this.intermediaryService.dismissLoading();
            this.focusToInput();
          }
        }, async (error) => {
          this.audioProvider.playDefaultError();
          let errorMessage = 'Ha ocurrido un error al intentar marcar como vacía la calle.';
          if (error.error && error.error.errors) {
            errorMessage = error.error.errors;
          }
          await this.intermediaryService.presentToastError(errorMessage);
          await this.intermediaryService.dismissLoading();
          this.focusToInput();
        })
        .catch(async (error) => {
          this.audioProvider.playDefaultError();
          let errorMessage = 'Ha ocurrido un error al intentar marcar como vacía la calle.';
          if (error.error && error.error.errors) {
            errorMessage = error.error.errors;
          }
          await this.intermediaryService.presentToastError(errorMessage);
          await this.intermediaryService.dismissLoading();
          this.focusToInput();
        });
    }, 2 * 1000);
  }

  private async unlockCurrentWay() {
    await this.intermediaryService.presentLoading('Desblloqueando procesos en calle...');

    this.sorterOutputService
      .postBlockSorterWay({
        wayId: this.infoSorterOperation.wayId.toString(),
        block: false
      })
      .then(async (res: SorterOutputModel.ResponseBlockSorterWay) => {
        if (res.code === 200) {
          await this.intermediaryService.presentToastSuccess('Calle desbloqueada para continuar con su uso.');
          await this.intermediaryService.dismissLoading();
          this.focusToInput();
        } else {
          let errorMessage = 'Ha ocurrido un error al intentar desbloquear los procesos en la calle actual.';
          if (res.errors) {
            errorMessage = res.errors;
          }
          await this.intermediaryService.presentToastError(errorMessage);
          await this.intermediaryService.dismissLoading();
          this.focusToInput();
        }
      }, async (error) => {
        let errorMessage = 'Ha ocurrido un error al intentar desbloquear los procesos en la calle actual.';
        if (error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.presentToastError(errorMessage);
        await this.intermediaryService.dismissLoading();
        this.focusToInput();
      })
      .catch(async (error) => {
        let errorMessage = 'Ha ocurrido un error al intentar desbloquear los procesos en la calle actual.';
        if (error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.presentToastError(errorMessage);
        await this.intermediaryService.dismissLoading();
        this.focusToInput();
      })
  }

  private checkWayWithIncidence() {
    let someCounter = 0;
    let checkWayWithIncidenceLocal = () => {
      someCounter++;
      let wayId = this.infoSorterOperation.wayId;

      this.sorterOutputService
        .postGetIncidenceWay({
          way: wayId
        })
        .then(async (res: SorterOutputModel.ResponseGetIncidenceWay) => {
          if (res.code === 201 && res.data) {
            this.wrongCodeDetected();
            this.focusToInput();
          } else if (this.checkByWrongCode) {
            setTimeout(() => checkWayWithIncidenceLocal(), 1000);
          }
        }, (error) => {
          console.error('Error::onrejected::sorterOutputService::postGetIncidenceWay', error)
        })
        .catch((error) => {
          console.error('Error::catch::sorterOutputService::postGetIncidenceWay', error)
        });
    };

    checkWayWithIncidenceLocal();
  }

  private async wrongCodeDetected() {
    this.launchIncidenceBeep = true;
    this.launchIncidenceSound();
    this.wrongCodeScanned = true;
    this.leftButtonDanger = false;
    this.checkByWrongCode = false;
    if (this.hideInput) this.hideInput = false;
  }

  private launchIncidenceSound() {
    if (!this.intervalIncidenceBeep) {
      this.intervalIncidenceBeep = setInterval(() => {
        if (!this.launchIncidenceBeep) {
          clearInterval(this.intervalIncidenceBeep);
          this.intervalIncidenceBeep = null;
        }
        this.audioProvider.play('incidenceBeep');
      }, 3 * 1000);
    }
  }

  private stopExecutionOutput(finish?: boolean, forceWithIncidence?:boolean) {
    this.sorterExecutionService
      .postStopExecuteColor(forceWithIncidence, !finish)
      .subscribe(async (res: ExecutionSorterModel.StopExecuteColor) => {

        if(!finish){
          let paramsRequest: ExecutionSorterModel.ParamsExecuteColor = {
            color: this.sorterProvider.colorSelected.id,
            type: 2
          };
          if (paramsRequest) {
            this.sorterExecutionService.postExecuteColor(paramsRequest).subscribe(data => {

              let idWayToWork = null;
              if (this.waySelectedToEmptying) {
                idWayToWork = this.waySelectedToEmptying.id;
              }

              let lastWarehouse;
              if (this.sorterProvider.infoSorterOutputOperation) {
                lastWarehouse = this.sorterProvider.infoSorterOutputOperation.destinyWarehouse.reference;
              }

              this.sorterOutputService.getNewProcessWay(idWayToWork, lastWarehouse)
                .then(async (res2: SorterOutputModel.ResponseNewProcessWay) => {

                  if (res2.code === 201) {
                    this.inputValue = null;
                    this.processStarted = null;
                    this.isFirstProductScanned = false;
                    this.wrongCodeScanned = false;
                    this.packingIsFull = false;

                    let id = this.sorterProvider.id_wareHouse;

                    let newProcessWay = res2.data;
                    this.sorterProvider.infoSorterOutputOperation = {
                      destinyWarehouse: {
                        id: newProcessWay.warehouse.id,
                        name: newProcessWay.warehouse.name,
                        reference: newProcessWay.warehouse.reference
                      },
                      wayId: newProcessWay.way.zoneWay.ways.id,
                      manualEmpty: newProcessWay.way.manual
                    };
                    this.infoSorterOperation = this.sorterProvider.infoSorterOutputOperation;

                    if(this.ULTIMA_JAULA || this.ULTIMA_JAULA !== null){
                      this.assignPackingToProcess(this.ULTIMA_JAULA);
                    }
                    this.focusToInput();
                  } else {
                    let errorMessage = 'Ha ocurrido un error al intentar obtener la nueva calle donde trabajar.';
                    if (res2.errors) {
                      errorMessage = res2.errors;
                    }
                    await this.intermediaryService.presentToastError(errorMessage);
                    this.location.back();
                  }

                }).catch(error => { this.location.back() })
            })
          }
        }

        await this.intermediaryService.dismissLoading();
        this.sorterProvider.colorActiveForUser = null;
        this.checkByWrongCode = true;
        if(finish){
            this.sorterProvider.processActiveForUser = null;
            this.router.navigate(['sorter/output']);
        }
      }, async (error: HttpRequestModel.Error) => {
        await this.intermediaryService.dismissLoading();
        let errorMessage = 'Ha ocurrido un error al intentar finalizar el proceso.';
        if (error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.presentToastError(errorMessage);
        this.focusToInput();
      });
  }

  public onFocus(event){
    if(event && event.target && event.target.id){
      this.keyboardService.setInputFocused(event.target.id);
    }
  }

  private async presentModalCancelOutput() {
    const previousMessage = this.messageGuide;
    let buttonsAlert: any = [
      {
        text: "No",
        handler: async ()=>{
          this.handlerStopOutput = false;
          await this.emptyWay(true);
        }
      },
      {
      text: "Sí",
      handler: ()=>{
        this.stopButtonPressed = true;
        this.hideLeftButtonFooter = true;
        this.hideRightButtonFooter = true;
        this.hideInput = false;
        this.messageGuide = '';
        this.focusToInput();
      }
    }];

    const alert = await this.alertController.create({
      header: "Detener Salida Sorter",
      message: "¿Quedan artículos en la calle?",
      backdropDismiss: false,
      buttons: buttonsAlert
    });
    alert.onDidDismiss().then((data) => {
      if(!data){
        this.stopButtonPressed = false;
        this.hideLeftButtonFooter = false;
        this.hideRightButtonFooter = false;
        this.messageGuide = previousMessage;
        this.focusToInput();
      }
    });
    return await alert.present();
  }

  private async presentModalCancelOutputWithIncidence() {
    const previousMessage = this.messageGuide;
    let buttonsAlert: any = [{
      text: "No",
      handler: async ()=>{
        await this.emptyWay(true);
      }
      },
      {
        text: "Sí",
        handler: ()=>{
          this.stopExecutionOutput(true, true);
        }
      }];

    const alert = await this.alertController.create({
      header: "Detener Salida Sorter con incidencias",
      message: "¿Quedan artículos en la calle?",
      buttons: buttonsAlert,
      backdropDismiss: false
    });
    alert.onDidDismiss().then((data) => {
      if(!data){
        this.hideLeftButtonFooter = false;
        this.hideRightButtonFooter = false;
        this.messageGuide = previousMessage;
        this.focusToInput();
      }
    });
    return await alert.present();
  }
}

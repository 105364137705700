import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PickingModel} from "../../../../../services/src/models/endpoints/Picking";
import {PickingProvider} from "../../../../../services/src/providers/picking/picking.provider";
import * as moment from 'moment';
import {ModalController} from "@ionic/angular";
import {ListProductsComponent} from "../modal-products/modal-products.component";
import {ListDestiniesComponent} from "../modal-destinies/modal-destinies.component";
import {ShoesPickingModel} from "../../../../../services/src/models/endpoints/ShoesPicking";
import {ShoesPickingService} from "../../../../../services/src/lib/endpoint/shoes-picking/shoes-picking.service";
import { IntermediaryService } from '@suite/services';

@Component({
  selector: 'detail-history',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailHistoryComponent implements OnInit {

 
  @Input() picking: PickingModel.PendingPickingsSelected;

  public STATUS_PICKING_INITIATED: number = 2;

  constructor(
    private modalController: ModalController,
    private intermediaryService: IntermediaryService,
    private shoesPickingService: ShoesPickingService,
    public pickingProvider: PickingProvider
  ) {}

  ngOnInit() {}

  dateCreatedParsed() : string {
    moment.locale('es');
    return moment(this.picking.createdAt).format('ddd, DD/MM/YYYY');
  }

  timeCreatedParsed() : string {
    moment.locale('es');
    return moment(this.picking.createdAt).format('LT');
  }

  showProducts() {
    this.createModalProducts();
  }

  showDestinies(picking) {
    this.createModalDestinies(picking);
  }

  private async createModalProducts() {
    let modal = await this.modalController.create({
      component: ListProductsComponent,
      cssClass: 'picking-product-list',
      componentProps: {id: this.picking.id}
    });

    return await modal.present();
  }

  private async createModalDestinies(picking) {
    let modal = await this.modalController.create({
      component: ListDestiniesComponent,
      cssClass: 'picking-product-list',
      componentProps: {picking: picking}
    });

    return await modal.present();
  }
}
import { Component, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {IntermediaryService, OplTransportsService} from '../../../services/src';
import { MatTabsModule, MatTabChangeEvent} from '@angular/material/tabs';
import { DefectiveRegistryService } from '../../../services/src/lib/endpoint/defective-registry/defective-registry.service';
import { FormBuilder } from '@angular/forms';
import { ExpeditionCollectedService } from '../../../services/src/lib/endpoint/expedition-collected/expedition-collected.service';
import { PackageCancelledComponent } from './package-cancelled/package-cancelled.component';
import {ExpeditionsProcessedModel} from "../../../services/src/models/endpoints/Expeditions/Processed";

@Component({
  selector: 'suite-expedition-cancelled',
  templateUrl: './expedition-cancelled.component.html',
  styleUrls: ['./expedition-cancelled.component.scss']
})

export class ExpeditionCancelledComponent {
  indexTab;
  indexToIdTranport=[];
  sendEvent:boolean=false;
  dataTransport: ExpeditionsProcessedModel.TransportsWithCancelled[];
  buttonSend:boolean;
  @ViewChild('#tabGroup') private tabGroup: MatTabsModule;
  @ViewChild('#selected') private tabSelected: PackageCancelledComponent;

  constructor(
    private defectiveRegistryService: DefectiveRegistryService,
    private formBuilder: FormBuilder,
    private intermediaryService: IntermediaryService,
    private modalController: ModalController,
    private intermediary: IntermediaryService,
    private expeditionCollectedService: ExpeditionCollectedService,
    private oplTransportsService: OplTransportsService
  ){}

  ngOnInit(){
    this.getTranports();
  }

 public async refresh(){
    let tab= this.indexToIdTranport.find(element => element.index == this.indexTab);
   this.expeditionCollectedService.setEmitTabId(tab.idTransport);
  }

 public async send(){
    this.sendEvent == true ? this.sendEvent = false:this.sendEvent = true;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.indexTab = tabChangeEvent.index;
    tabChangeEvent.tab.ngOnDestroy();
  }

  async getTranports(){
    this.oplTransportsService.getOperatorsCancelled().subscribe(res => {
      this.dataTransport = res;
      this.assignIdTransportToIndex();
    },
    async (err) => {
      console.log(err);
    });
  }

  assignIdTransportToIndex(){
    for (const i in this.dataTransport) {
      const transport = this.dataTransport[i];
      this.indexToIdTranport.push({
        index: i,
        idTransport: transport.id,
        referenceTransport: transport.reference
      })
    }
  }

  buttonState($event){
    this.buttonSend = $event;
  }
}

import {Injectable} from '@angular/core';
import {AuthenticationService, UserRequestData} from '../../../../services/src/lib/endpoint/authentication/authentication.service';
import {Router, ActivatedRoute} from '@angular/router';
import {empty} from 'rxjs/internal/Observer';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable, from, BehaviorSubject} from 'rxjs';
import {catchError, map, switchMap, finalize, filter, take} from 'rxjs/operators';
import {Oauth2Service} from '../endpoint/oauth2/oauth2.service';
import {IntermediaryService} from '../endpoint/intermediary/intermediary.service';
import {Location} from "@angular/common";
import { TimesToastType } from '../../models/timesToastType';

@Injectable()
export class AddTokenToRequestInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private intermediaryService: IntermediaryService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private oauth2Service: Oauth2Service
  ) { }

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private isToastVisible: boolean = false;

  private listRoutesWithCustomErrorConnectionManagement: string[] = [
    '/print/product/relabel',
    '/print-tag/manual/price'
  ];
  private listUrisWithCustomErrorConnectionManagement: string[] = [
    '/api/print',
    '/market'
  ];
  private listUrisWithCustom401Management: string[] = [
    '/api/processes/positioner-main'
  ];

  addTokenToRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authenticationService.getCurrentTokenAndUserId()).pipe(switchMap((data:UserRequestData) => {
      const {token,userId} = data;
      if (!token && !(request.url.includes("/api/oauth2/"))) {
        this.authenticationService.logout();
        return Observable.create(empty);
      } else if (token) {
        return next.handle(!(request.url.includes("/api/oauth2/")) ? request.clone({
          setHeaders: {
            Authorization: token,
            UserId:userId.toString()
          }
        }) : request)
          /**Catch http response error to detect if it is 401 or 403(authentication) */
          .pipe(catchError((err, caught) => {
            switch (err.status) {
              case 403:
                return this.handle401Error(request, next);
              case 401:
                if (this.isUriInListUris401Managed(request.url)) {

                }else if (!request.url.includes("/api/oauth2/")) {
                  return this.handle401Error(request, next);
                }else{
                  this.authenticationService.logout();
                  return new Observable(observer => observer.error(err));
                }
              case 400:
                if (request.url.includes('token')) {
                  return new Observable(observer => {
                    observer.error();
                  }).pipe(map((error) => {
                    this.authenticationService.logout();
                    return error;
                  }));
                }
                break;
              case 500:
                if (this.authenticationService.isAuthenticated) {
                  this.authenticationService.logout();
                  if (!this.isToastVisible) {
                    this.intermediaryService.presentToastError('Ha ocurrido un error al conectar con el servidor.');
                    this.isToastVisible = true;
                    setTimeout(() => {
                      this.isToastVisible = false;
                    }, TimesToastType.DURATION_ERROR_TOAST);
                  }
                }
                break;
              case 0:
                // check if the page is assigned to exclude that will manage itself the connection error
                let customErrorManagement = !!this.listRoutesWithCustomErrorConnectionManagement.find(url => url === this.router.url);
                if (!customErrorManagement) {
                  customErrorManagement = this.isUriInListUrisManaged(request.url);
                }
                /*
                * NO CONNECTION TO API
                * status = 0
                * statusText = 'Unknown Error'
                * */
                if (!customErrorManagement && err.statusText == 'Unknown Error' && this.authenticationService.isAuthenticated()) {
                  this.authenticationService.logout();
                  if (!this.isToastVisible) {
                    this.intermediaryService.presentToastError('Ha ocurrido un error al intentar conectarse con el servidor. Compruebe que su conexión a la red es estable para continuar.');

                    this.isToastVisible = true;
                    setTimeout(() => {
                      this.isToastVisible = false;
                    }, TimesToastType.DURATION_ERROR_TOAST);
                  }
                }
                break;
            }
            return new Observable(observer => observer.error(err));
          }));
      } else {
        this.intermediaryService.dismissLoading();
        return next.handle(request);
      }
    }));
  }

  /**
   * Handle the http 401 error(authentication) to request a new refresh token
   * @param request
   * @param next
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    /**the token is not current refreshing */
    if (!this.isRefreshingToken) {
      /**activate the flag: the token is refreshing */
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      /**first we obtain the refresh token */
      return from(this.authenticationService.getCurrentRefreshToken())
        /**then with that token call the refresh token endpoint */
        .pipe(switchMap(token => {
          return this.oauth2Service.refreshToken(token)
            /**save the new token  in storage*/
            .pipe(switchMap(response => {
              return from(this.authenticationService.login(response.data.access_token, response.data.user.id, response.data.accessPermitionsDictionary, response.data.refresh_token));
            }))
        })).pipe(
          switchMap((user) => {
            this.tokenSubject.next("ok");
            return this.addTokenToRequest(request, next);
          }),
          catchError(err => {
            this.isRefreshingToken = false;
            this.intermediaryService.presentWarning("Su sesión ha expirado", () => { });
            this.authenticationService.logout();
            return new Observable(observer => observer.error(err));
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
        );
    } else {
      /**if the token is refreshing add the new request to subject */
      return this.tokenSubject
        .pipe(filter(token => token !== null),
          take(1),
          switchMap(token => {
            return this.addTokenToRequest(request, next);
          }));
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.addTokenToRequest(request, next);
  }

  private isUriInListUrisManaged(uriDenied: string): boolean {
    return !!this.listUrisWithCustomErrorConnectionManagement.find(uri => {
      const uriAsRegex = new RegExp(`(${uri})$`);
      return uriAsRegex.test(uriDenied);
    });
  }

  private isUriInListUris401Managed(uriDenied: string): boolean {
    return !!this.listUrisWithCustom401Management.find(uri => {
      const uriAsRegex = new RegExp(`(${uri})$`);
      return uriAsRegex.test(uriDenied);
    });
  }
}

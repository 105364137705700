import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ExpeditionIncidencesService} from "../../../services/src/lib/endpoint/expedition-incidences/expedition-incidences.service";
import {ExpeditionIncidenceModel} from "../../../services/src/models/endpoints/ExpeditionIncidence";
import ResponseGetAllIncidences = ExpeditionIncidenceModel.GetAllIncidences;
import {IntermediaryService} from "../../../services/src/lib/endpoint/intermediary/intermediary.service";
import {TimesToastType} from "../../../services/src/models/timesToastType";
import {ModalController} from "@ionic/angular";
import {OplExpeditionsService} from "../../../services/src/lib/endpoint/opl-expeditions/opl-expeditions.service";
import {ManageIncidenceComponent} from "./manage-incidence/manage-incidence.component";
import {PaginatorComponent} from "../components/paginator/paginator.component";
import {MatSort, Sort} from "@angular/material/sort";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TagsInputOption} from "../components/tags-input/models/tags-input-option.model";
import {FilterButtonComponent} from "../components/filter-button/filter-button.component";

@Component({
  selector: 'expedition-incidences',
  templateUrl: './expedition-incidences.component.html',
  styleUrls: ['./expedition-incidences.component.scss']
})
export class ExpeditionIncidencesComponent implements OnInit {

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @ViewChild(MatSort) mSort: MatSort;

  @ViewChild('fbBrands') fbBrands: FilterButtonComponent;
  @ViewChild('fbTypes') fbTypes: FilterButtonComponent;
  @ViewChild('fbDeliveryRequestExternalIds') fbdeliveryRequestExternalIds: FilterButtonComponent;
  @ViewChild('fbIncidenceCodes') fbIncidenceCodes: FilterButtonComponent;
  @ViewChild('fbWarehouses') fbWarehouses: FilterButtonComponent;
  @ViewChild('fbTransports') fbTransports: FilterButtonComponent;
  @ViewChild('fbDates') fbDates: FilterButtonComponent;

  public displayedColumns: string[] = ['typeEntity', 'code', 'incidenceCode', 'warehouse', 'transport', 'package', 'date', 'actions'];
  public dataSource = new MatTableDataSource<ResponseGetAllIncidences>();

  public TYPES = {EXPEDITION_LOCKED: 1, INCIDENCE: 2};

  public mSortRest = false;
  public isFilteringTypes: number;
  public isFilteringDeliveryRequestExternalIds: number;
  public isFilteringIncidenceCodes: number;
  public isFilteringWarehouses: number;
  public isFilteringTransports: number;
  public isFilteringDates: number;

  public filterOptions: {
    types: TagsInputOption[],
    deliveryRequestExternalIds: TagsInputOption[],
    incidenceCodes: TagsInputOption[],
    warehouses: TagsInputOption[],
    transports: TagsInputOption[],
    dates: TagsInputOption[]
  } = {
    types: [],
    deliveryRequestExternalIds: [],
    incidenceCodes: [],
    warehouses: [],
    transports: [],
    dates: []
  };
  public filters: {
    types: TagsInputOption[],
    deliveryRequestExternalIds: TagsInputOption[],
    incidenceCodes: TagsInputOption[],
    warehouses: TagsInputOption[],
    transports: TagsInputOption[],
    dates: TagsInputOption[],
    sort: { field: string, direction: string },
    pagination: {limit: number, page: number}
  } = {
    types: [],
    deliveryRequestExternalIds: [],
    incidenceCodes: [],
    warehouses: [],
    transports: [],
    dates: [],
    sort: { field: 'date', direction: 'DESC' },
    pagination: { limit: undefined, page: 1}
  };

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private expeditionIncidencesService: ExpeditionIncidencesService,
    private oplExpeditionsService: OplExpeditionsService,
    private intermediaryService: IntermediaryService
  ) {}

  ngOnInit() {
    this.loadFilters();
  }

  async ngAfterViewInit() {
    await this.intermediaryService.presentLoadingNew('Cargando incidencias...');
    this.filters.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadIncidences();
    this.listenChanges();
  }

  private listenChanges() {
    let previousPageSize = this.filters.pagination.limit;
    this.paginator.page.subscribe(page => {
      let flag = previousPageSize === page.pageSize;
      previousPageSize = page.pageSize;
      this.filters.pagination = {
        limit: page.pageSize,
        page: flag ? page.pageIndex : 1
      };
      this.loadIncidences()
    });

    this.mSort.sortChange.subscribe((sort: Sort) => {
      if (this.mSortRest) {
        this.mSortRest = false;
      } else {
        let sortSelection = {
          field: 'id',
          direction: 'DESC'
        };
        if (sort.direction != '') {
          sortSelection = {
            field: sort.active,
            direction: sort.direction.toUpperCase()
          };
        }

        this.filters.sort = sortSelection;
        this.loadIncidences();
      }
    });
  }

  public applyFilters(event, column: string) {
    const values = [];

    for(let item of event.filters){
      if(item.checked){
        values.push(item.value);
      }
    }

    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.filters.pagination.page = 1;
    this.loadIncidences();
  }

  private loadFilters() {
    this.expeditionIncidencesService
      .getGetIncidencesFilters()
      .subscribe(res => {
        this.filterOptions.types = res.types;
        this.filterOptions.deliveryRequestExternalIds = res.deliveryRequestExternalIds;
        this.filterOptions.incidenceCodes = res.incidenceCodes;
        this.filterOptions.warehouses = res.warehouses;
        this.filterOptions.transports = res.transports;
        this.filterOptions.dates = res.dates;

        this.isFilteringDates = this.filters.dates.length;
        this.isFilteringTransports = this.filters.transports.length;
        this.isFilteringTypes = this.filters.types.length;
        this.isFilteringDeliveryRequestExternalIds = this.filters.deliveryRequestExternalIds.length;
        this.isFilteringIncidenceCodes = this.filters.incidenceCodes.length;
        this.isFilteringWarehouses = this.filters.warehouses.length;
      }, (error) => { })
  }

  private loadIncidences() {
    let params: any = {};
    if (this.filters.types && this.filters.types.length > 0) {
      params.types = this.filters.types;
    } else {
      delete params.types;
    }
    if (this.filters.deliveryRequestExternalIds && this.filters.deliveryRequestExternalIds.length > 0) {
      params.deliveryRequestExternalIds = this.filters.deliveryRequestExternalIds;
    } else {
      delete params.deliveryRequestExternalIds;
    }
    if (this.filters.incidenceCodes && this.filters.incidenceCodes.length > 0) {
      params.incidenceCodes = this.filters.incidenceCodes;
    } else {
      delete params.incidenceCodes;
    }
    if (this.filters.warehouses && this.filters.warehouses.length > 0) {
      params.warehouses = this.filters.warehouses;
    } else {
      delete params.warehouses;
    }
    if (this.filters.transports && this.filters.transports.length > 0) {
      params.transports = this.filters.transports;
    } else {
      delete params.transports;
    }
    if (this.filters.dates && this.filters.dates.length > 0) {
      params.dates = this.filters.dates;
    } else {
      delete params.dates;
    }
    params.sort = this.filters.sort;
    params.pagination = this.filters.pagination;

    this.expeditionIncidencesService
      .postGetAllIncidences(params)
      .subscribe(async (res) => {
        this.dataSource = new MatTableDataSource<ResponseGetAllIncidences>(res.result);

        const paginator = res.pagination;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;
        await this.intermediaryService.dismissLoadingNew();
      }, async (error) => {
        let errorMessage = 'Ha ocurrido un error al cargar las incidencias.';
        if (error && error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.presentToastError(errorMessage);
        await this.intermediaryService.dismissLoadingNew();
      })
  }

  public async refresh() {
    await this.intermediaryService.presentLoadingNew('Cargando incidencias...');
    this.resetFilters();
    this.loadFilters();
    this.loadIncidences();
  }

  private resetFilters() {
    this.filters = {
      types: [],
      deliveryRequestExternalIds: [],
      incidenceCodes: [],
      warehouses: [],
      transports: [],
      dates: [],
      sort: {field: 'date', direction: 'DESC'},
      pagination: {limit: this.paginator.finalPagerValues[0], page: 1}
    };

    this.filterOptions.transports = this.filterOptions.transports.map(t => {t.checked = false; return t;});
    this.filterOptions.types = this.filterOptions.types.map(t => {t.checked = false; return t;});
    this.filterOptions.warehouses = this.filterOptions.warehouses.map(t => {t.checked = false; return t;});
    this.filterOptions.incidenceCodes = this.filterOptions.incidenceCodes.map(t => {t.checked = false; return t;});
    this.filterOptions.dates = this.filterOptions.dates.map(t => {t.checked = false; return t;});
    this.filterOptions.deliveryRequestExternalIds = this.filterOptions.deliveryRequestExternalIds.map(t => {t.checked = false; return t;});

    this.mSortRest = false;
  }

  public async expeditionIncidenceAction(incidence: ResponseGetAllIncidences) {
    if (incidence.typeEntity.id == this.TYPES.EXPEDITION_LOCKED) {
      const callbackOk = () => this.unlockExpedition(incidence.expeditionId);
      await this.intermediaryService.presentConfirm('¿Está seguro que desea desloquear?', callbackOk)
    } else if (incidence.typeEntity.id == this.TYPES.INCIDENCE) {
      await this.manageIncidence(incidence);
    }
  }

  private async unlockExpedition(expeditionId: number) {
    await this.intermediaryService.presentLoadingNew();
    this.oplExpeditionsService
      .unlockExpedition({expeditionId})
      .subscribe(async (resp) => {
        await this.intermediaryService.dismissLoadingNew();
        await this.intermediaryService.presentToastSuccess('¡Expedición desbloqueada con exito!', TimesToastType.DURATION_SUCCESS_TOAST_2750);

        await this.intermediaryService.presentLoadingNew('Cargando incidencias...');
        this.loadIncidences();
      }, async (error) => {
        let errorMessage = 'Error al desbloquear la expedición o existe otra en proceso';
        if (error && error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        await this.intermediaryService.dismissLoadingNew();
        await this.intermediaryService.presentToastError(errorMessage);

        await this.intermediaryService.presentLoadingNew('Cargando incidencias...');
        this.loadIncidences();
      })
  }

  private async manageIncidence(incidence: ResponseGetAllIncidences) {
    let modal = this.modalController.create({
      component: ManageIncidenceComponent,
      componentProps: {
        id: incidence.expeditionId,
        deliveryRequestExternalId: incidence.deliveryRequestExternalId
      }
    });
    (await modal).onDidDismiss().then((d) => {
      if (d && d.data && d.data.update) {
        this.loadFilters();
        this.loadIncidences();
      }
    });
    (await modal).present();
  }
}

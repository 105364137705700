import { Component, OnInit, ViewChild  } from '@angular/core';
import {Location} from "@angular/common";
import {PickingModel} from "../../../../services/src/models/endpoints/Picking";
import {WorkwavesService} from "../../../../services/src/lib/endpoint/workwaves/workwaves.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PickingService} from "../../../../services/src/lib/endpoint/picking/picking.service";
import {AlertController, LoadingController } from "@ionic/angular";
import { IntermediaryService, UserTimeModel, UserTimeService } from '@suite/services';
import { TimesToastType } from '../../../../services/src/models/timesToastType';
import {MatPaginator} from "@angular/material";
import FilterOptionsResponse = PickingModel.FilterOptionsResponse;
import Pagination = PickingModel.Pagination;
import Filters = PickingModel.Filters;
import FilterOptions = PickingModel.FilterOptions;
import Order = PickingModel.Order;
import Picking = PickingModel.Picking;
import SearchParameters = PickingModel.SearchParameters;
import SearchResponse = PickingModel.SearchResponse;
import {PaginatorComponent} from "../../components/paginator/paginator.component";
import SearchResponseRebuild = PickingModel.SearchResponseRebuild;


@Component({
  selector: 'list-detail-history',
  templateUrl: './list-detail.component.html',
  styleUrls: ['./list-detail.component.scss']
})
export class ListDetailHistoryComponent implements OnInit {

  public STATUS_PICKING_INITIATED: number = 2;

  idWorkwave: number = null;

  public listPickings: Array<PickingModel.HistoryPickings> = new Array<PickingModel.HistoryPickings>();
  //public listPickings: Array<any> = new Array<any>();
  public isLoadingPickings: boolean = false;
  loading: HTMLIonLoadingElement = null;

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  filters: Filters = {
    ids: [],
    dates: [],
    origins: [],
    destinies: [],
    users: [],
    status: [],
  };
  filterOptions: FilterOptions = {
    ids: [],
    dates: [],
    origins: [],
    destinies: [],
    users: [],
    status: [],
  };
  order: Order = {
    field: 'date',
    direction: 'DESC'
  };
  pagination: Pagination = {
    limit: undefined,
    page: 1
  };

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private intermediaryService: IntermediaryService,
    private workwavesService: WorkwavesService,
    private pickingService: PickingService,
    private userTimeService: UserTimeService
  ) {}

  ngOnInit() {
    this.idWorkwave = this.activatedRoute.snapshot.paramMap.get('id') ? parseInt(this.activatedRoute.snapshot.paramMap.get('id')) : null;
   
    this.loadFilters();

    this.paginator.page.subscribe(paginator => {
      this.pagination.limit = paginator.pageSize;
      this.pagination.page = paginator.pageIndex;
      this.loadPickingsList();
      this.intermediaryService.dismissLoading();
    });
  }

  ngAfterViewInit(){
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadPickingsList();
  }

  public loadPickingsList() {
    this.isLoadingPickings = true;

    let subscribeResponseListPickings = ((response: SearchResponseRebuild | any) => {
      if(response.code == 200) {
        if(response.data.result){
          this.listPickings = response.data.result;
          this.paginator.length = response.data.pagination.totalResults;
          this.paginator.lastPage = response.data.pagination.lastPage;
          this.paginator.pageIndex = response.data.pagination.selectPage;
        } else {
          this.listPickings = response.data;
          this.paginator.length = response.data.length;
          this.paginator.lastPage = 1;
          this.paginator.pageIndex = 1;
        }
        this.isLoadingPickings = false;
      }
    });
    let subscribeErrorListPickings = (error) => {
      if(error.status === 404){
        this.router.navigateByUrl('workwaves-history');
        this.isLoadingPickings = false;
        return;
      }else{
        console.error('Error::Subscribe:pickingService::getListAllPendingPicking::', error);
        this.listPickings = new Array<PickingModel.HistoryPickings>();
        this.isLoadingPickings = false;
      }
    };

    const parameters: SearchParameters = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };

    if (this.idWorkwave) {
      this.pickingService
        .getListPickingByHistoryWorkwave(this.idWorkwave, parameters)
        .then(subscribeResponseListPickings, subscribeErrorListPickings);
    } 

  }

  loadFilters(){
    if (this.idWorkwave) {
      this.pickingService.getFilterOptionsByHistoryWorkwave(this.idWorkwave).then((response: FilterOptionsResponse) => {
        if(response.code == 200){
          this.filterOptions = response.data;
        }else{
          console.error(response);
        }
      }).catch(console.error);
    }
  }

  orderBy(column: string){
    if(this.order.field == column){
      this.order.direction == 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.loadPickingsList();
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        values.push(item.id);
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.loadPickingsList();
  }

  goPreviousPage() {
    this.location.back();
  }  

  async showLoading(message: string) {
    this.loading = await this.loadingController.create({
      message: message,
      translucent: true,
    });
    return await this.loading.present();
  }

  reset(){
    this.filters = {
      ids: [],
      dates: [],
      origins: [],
      destinies: [],
      users: [],
      status: [],
    };
    this.order = {
      field: 'releaseDate',
      direction: 'DESC'
    };
    this.pagination = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 0;
    this.loadFilters();
    this.loadPickingsList();
  }

}



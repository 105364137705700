import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { Location } from "@angular/common";
import {
  GroupWarehousePickingModel,
  GroupWarehousePickingService, IntermediaryService,
  UserTimeModel,
  UserTimeService
} from '@suite/services';
import { PickingParametrizationProvider } from "../../../../services/src/providers/picking-parametrization/picking-parametrization.provider";
import { WorkwavesService } from "../../../../services/src/lib/endpoint/workwaves/workwaves.service";
import { WorkwaveModel } from "../../../../services/src/models/endpoints/Workwaves";
import {AlertController, Events, LoadingController, NavController} from "@ionic/angular";
import { Observable, Subject } from 'rxjs';
import {Router} from "@angular/router";
import {TableEmployeesComponent} from "../table-employees/table-employees.component";
import {TableRequestsOrdersComponent} from "../table-requests-orders/table-requests-orders.component";
import {TimesToastType} from "../../../../services/src/models/timesToastType";
import {TextParserService} from "../../../../services/src/lib/text-parser/text-parser.service";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'list-workwave-template-rebuild',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateY(0)' })),
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.17s ease-in-out')
      ]),
      transition(':leave', [
        animate('0.17s ease-in-out', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class ListWorkwaveTemplateRebuildComponent implements OnInit {

  private GROUPS_WAREHOUSES_LOADED = "groups-warehouses-loaded";
  private EMPLOYEES_LOADED = "employees-loaded";
  private REQUEST_ORDERS_LOADED = "request-orders-loaded";
  private TEAM_ASSIGNATIONS_LOADED = "team-assignations-loaded";
  private DRAW_CONSOLIDATED_MATCHES = "draw-consolidated-matches";
  private TYPE_EXECUTION_ID = 1;
  private BLOCK_BUTTONS = 'block_button';
  private ENABLED_BUTTONS = 'enabled_button';
  private BLOCK_BUTTONS_TEAM = 'block_button_team';
  private ENABLED_BUTTONS_TEAM = 'enabled_button_team';

  @Input() templateToEdit: any;
  @Input() typeWorkwave: number;
  @ViewChild(TableEmployeesComponent) tableEmployees: TableEmployeesComponent;
  @ViewChild(TableRequestsOrdersComponent) tableRequestsOrders: TableRequestsOrdersComponent;

  template: any;
  public userReques: any = [];
  disableEdition: boolean = false;

  listTypesToUpdate: Array<number> = new Array<number>();
  listGroupsWarehousesToUpdate: Array<GroupWarehousePickingModel.GroupWarehousesSelected> = new Array<GroupWarehousePickingModel.GroupWarehousesSelected>();
  listEmployeesToUpdate: Array<number> = new Array<number>();
  listRequestOrdersToUpdate: Array<number> = new Array<number>();
  private listWarehousesThresholdAndSelectedQty: any = {};
  private checkRequestsSelectedIsOverThreshold: boolean = false;

  private loading: HTMLIonLoadingElement = null;
  private ObservablePendings: Array<any> = new Array();
  private noCancelLinesWoStock = false
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  enlarged = false;
  responseQuantities: WorkwaveModel.AssignationsByRequests[];

  public showCreateButton: boolean = false;


  constructor(
    private location: Location,
    private events: Events,
    private router: Router,
    private intermediaryService: IntermediaryService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private groupWarehousePickingService: GroupWarehousePickingService,
    private userTimeService: UserTimeService,
    private workwavesService: WorkwavesService,
    private pickingParametrizationProvider: PickingParametrizationProvider,
    private navController: NavController,
    private textParserService: TextParserService
  ) {

    this.workwavesService.requestUser.subscribe(res => {
      if (res.user === true && res.table === true) {
        this.employeeChanged(res.data);
      }
    });

    this.workwavesService.orderAssignment.subscribe(res => {
      if (res.store === true && res.type === true) {
        this.groupWarehousesChanged(res.data);
      }
    })
  }

  ngOnInit() {
    this.pickingParametrizationProvider.resetLoadings();

    if (this.templateToEdit) {
      if (this.templateToEdit.type === 1) {
        this.disableEdition = true;
      }
      this.template = {
        name: this.templateToEdit.name || 'Ola de trabajo // ' + this.templateToEdit.id,
        id: this.templateToEdit.id
      };
    } else {
      this.template = {
        name: 'Nueva Ola de trabajo',
        id: null
      };
    }

    this.loadDefaultWorkWaveData()
  }


  ngOnDestroy() {
    this.ObservablePendings.map(obs => {
      try {
        <Observable<any>>obs.unsubscribe();
      } catch (error) {
      }
    })
  }

  private loadDefaultWorkWaveData() {
    this.pickingParametrizationProvider.loadingListGroupsWarehouses++;
    this.loadGroupsWarehouses();
    this.pickingParametrizationProvider.loadingListEmployees++;
    this.loadEmployees();
    this.pickingParametrizationProvider.loadingListRequestOrders++;
    this.loadRequestOrders();
    this.pickingParametrizationProvider.loadingListTeamAssignations++;
    this.loadTeamAssignations();
  }

  private loadGroupsWarehouses() {
    let obs = this.groupWarehousePickingService.getIndex();
    this.ObservablePendings.push(obs);
    obs.subscribe((res: Array<GroupWarehousePickingModel.GroupWarehousePicking>) => {
      this.pickingParametrizationProvider.listGroupsWarehouses = res.filter(groupWarehouse => groupWarehouse.warehouses.length > 0);
      this.events.publish(this.GROUPS_WAREHOUSES_LOADED);
      this.pickingParametrizationProvider.loadingListGroupsWarehouses--;
    }, (error) => {
      console.error('Error::Subscribe:groupWarehousePickingService::getIndex::', error);
      this.pickingParametrizationProvider.listGroupsWarehouses = new Array<GroupWarehousePickingModel.GroupWarehousePicking>();
      this.events.publish(this.GROUPS_WAREHOUSES_LOADED);
      this.pickingParametrizationProvider.loadingListGroupsWarehouses--;
    });
  }

  private loadEmployees() {
    let obs = this.userTimeService.getListUsersRegister();
    this.ObservablePendings.push(obs);
    obs.subscribe((res: UserTimeModel.ListUsersRegisterTimeActiveInactive) => {
      this.pickingParametrizationProvider.listEmployees = res;
      this.events.publish(this.EMPLOYEES_LOADED);
      this.pickingParametrizationProvider.loadingListEmployees--;
    }, (error) => {
      console.error('Error::Subscribe:userTimeService::getListUsersRegister::', error);
      this.pickingParametrizationProvider.listEmployees = { usersActive: [], usersInactive: [] };
      this.events.publish(this.EMPLOYEES_LOADED);
      this.pickingParametrizationProvider.loadingListEmployees--;
    });
  }

  private loadRequestOrders() {
    this.pickingParametrizationProvider.loadingListTeamAssignations++;
    if (this.listTypesToUpdate.length > 0 && this.listGroupsWarehousesToUpdate.length > 0) {
      let obs = this.workwavesService.postMatchLineRequest({
        groupsWarehousePicking: this.listGroupsWarehousesToUpdate,
        typesShippingOrders: this.listTypesToUpdate
      });
      this.ObservablePendings.push(obs);
      obs.subscribe((res: Array<WorkwaveModel.MatchLineRequest>) => {
        this.pickingParametrizationProvider.listRequestOrders = res;
        this.events.publish(this.REQUEST_ORDERS_LOADED);
        this.pickingParametrizationProvider.loadingListRequestOrders--;
        this.pickingParametrizationProvider.loadingListRequestOrders--;
        this.pickingParametrizationProvider.loadingListTeamAssignations--;
        this.events.publish(this.ENABLED_BUTTONS);
        this.events.publish(this.ENABLED_BUTTONS_TEAM);
      }, (error) => {
        console.error('Error::Subscribe:workwavesService::postMatchLineRequest::', error);
        this.pickingParametrizationProvider.listRequestOrders = new Array<WorkwaveModel.MatchLineRequest>();
        this.events.publish(this.REQUEST_ORDERS_LOADED);
        this.pickingParametrizationProvider.loadingListRequestOrders--;
        this.pickingParametrizationProvider.loadingListRequestOrders--;
        this.pickingParametrizationProvider.loadingListTeamAssignations--;
        this.events.publish(this.ENABLED_BUTTONS);
        this.events.publish(this.ENABLED_BUTTONS_TEAM);
      });
    } else {
      this.pickingParametrizationProvider.listRequestOrders = new Array<WorkwaveModel.MatchLineRequest>();
      this.events.publish(this.REQUEST_ORDERS_LOADED);
      this.pickingParametrizationProvider.loadingListRequestOrders--;
      this.pickingParametrizationProvider.loadingListRequestOrders--;
      this.pickingParametrizationProvider.loadingListTeamAssignations--;
      this.events.publish(this.ENABLED_BUTTONS);
      this.events.publish(this.ENABLED_BUTTONS_TEAM);
    }
  }

  private loadTeamAssignations() {
    if (this.listEmployeesToUpdate.length > 0 && this.listRequestOrdersToUpdate.length > 0) {
      let obs0 = this.workwavesService
        .postMatchLineRequest({
          groupsWarehousePicking: this.listGroupsWarehousesToUpdate,
          typesShippingOrders: this.listTypesToUpdate
        });
      this.ObservablePendings.push(obs0);
      let obs = this.workwavesService
        .postAssignUserToMatchLineRequest({
          requestIds: this.listRequestOrdersToUpdate,
          userIds: this.listEmployeesToUpdate,
          groupsWarehousePicking: this.listGroupsWarehousesToUpdate,
          typesShippingOrders: this.listTypesToUpdate
        })
      this.ObservablePendings.push(obs);
      obs.subscribe((res: WorkwaveModel.UsersAndAssignationsQuantities) => {
        this.showCreateButton = true;

        this.pickingParametrizationProvider.listTeamAssignations = res.assignations;
        this.events.publish(this.TEAM_ASSIGNATIONS_LOADED);
        if (res.quantities) {
          this.events.publish(this.DRAW_CONSOLIDATED_MATCHES, res.quantities);
          this.responseQuantities = res.quantities;
        }
        this.pickingParametrizationProvider.loadingListTeamAssignations--;
      }, (error) => {
        this.showCreateButton = false;

        let errorMessage = "[ERR1201] Ha ocurrido un error al intentar generar las asignaciones de usuarios a los pickings.";
        if (error && error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }
        console.error('[ERR1201] Error::Subscribe:workwavesService::postAssignUserToMatchLineRequest::', error);
        this.intermediaryService.presentWarning(this.textParserService.formatTextToWarningAlertMessage(errorMessage), null);

        this.pickingParametrizationProvider.listTeamAssignations = new Array<WorkwaveModel.TeamAssignations>();
        this.events.publish(this.TEAM_ASSIGNATIONS_LOADED);
        this.pickingParametrizationProvider.loadingListTeamAssignations--;
      }, () => {
        this.events.publish(this.ENABLED_BUTTONS);
        this.events.publish(this.ENABLED_BUTTONS_TEAM);
      });
    } else {
      this.pickingParametrizationProvider.listTeamAssignations = new Array<WorkwaveModel.TeamAssignations>();
      this.events.publish(this.TEAM_ASSIGNATIONS_LOADED);
      this.pickingParametrizationProvider.loadingListTeamAssignations--;
      this.events.publish(this.ENABLED_BUTTONS);
      this.events.publish(this.ENABLED_BUTTONS_TEAM);
    }
  }

  saveWorkWave() {
    if (this.listEmployeesToUpdate.length < 1) {
      this.intermediaryService.presentToastError("Seleccione almenos un usuario para generar las tareas de picking.");
    } else if (this.listRequestOrdersToUpdate.length < 1) {
      this.intermediaryService.presentToastError("Seleccione almenos una operación de envío para generar las tareas de picking.");
    } else {
      let listWarehousesOverThreshold = [];

      for (let iWarehouse in this.listWarehousesThresholdAndSelectedQty) {
        let warehouseThreshold = this.listWarehousesThresholdAndSelectedQty[iWarehouse];
        if (typeof warehouseThreshold.max !== 'undefined' && warehouseThreshold.max != null && warehouseThreshold.max > 0 && warehouseThreshold.selected > warehouseThreshold.max) {
          listWarehousesOverThreshold.push(warehouseThreshold.warehouse);
        }
      }

      if (this.checkRequestsSelectedIsOverThreshold && listWarehousesOverThreshold.length > 0) {
        this.presentAlertWarningOverThreshold(listWarehousesOverThreshold);
      } else {
        this.presentAlertConfirmPickings();
      }
    }
  }

  goPreviousPage() {
    this.navController.navigateRoot('/workwaves-scheduled', { replaceUrl: true })
  }

  typeChanged(data) {
    this.listTypesToUpdate = data.fields;
    if (data.inPageCreation) {
      this.updateListOfRequestOrders();
    }
    // this.pickingParametrizationProvider.loadingListRequestOrders++;
    // this.loadRequestOrders();
  }

  groupWarehousesChanged(data) {
    this.showCreateButton = false;

    this.listGroupsWarehousesToUpdate = new Array<GroupWarehousePickingModel.GroupWarehousesSelected>(data.fields);
    if (data.inPageCreation) {
      this.updateListOfRequestOrders();
    }
  }

  employeeChanged(data) {
    this.showCreateButton = false;

    this.listEmployeesToUpdate = data.fields;
    if (data.inPageCreation) {
      this.updateListOfUserAssignations();
    }
  }

  requestOrderChanged(data) {
    this.listWarehousesThresholdAndSelectedQty = data.fields.listThreshold;
    this.listRequestOrdersToUpdate = data.fields.listSelected;
    if (data.inPageCreation) {
      this.updateListOfUserAssignations();
    }
  }

  updateListOfRequestOrders() {
    this.showCreateButton = false;

    this.pickingParametrizationProvider.loadingListRequestOrders++;
    this.loadRequestOrders();
  }

  updateListOfUserAssignations() {
    this.showCreateButton = false;

    this.listEmployeesToUpdate = this.tableEmployees.getSelectedEmployees();
    this.listRequestOrdersToUpdate = this.tableRequestsOrders.getSelectedRequests();
    this.pickingParametrizationProvider.loadingListTeamAssignations++;
    this.loadTeamAssignations();
  }

  enlarge() {
    if (this.enlarged) {
      let top = document.getElementsByClassName('stores-employees')[0] as HTMLElement;
      top.style.height = '25vh';
      this.enlarged = !this.enlarged;
    } else {
      let top = document.getElementsByClassName('stores-employees')[0] as HTMLElement;
      top.style.height = 'calc(100vh - 52px - 56px - 8px)';
      this.enlarged = !this.enlarged;
    }
  }

  private generateWorkWave() {
    let obs = this.workwavesService
      .postConfirmMatchLineRequest({
        type: this.TYPE_EXECUTION_ID,
        requestIds: this.listRequestOrdersToUpdate,
        userIds: this.listEmployeesToUpdate,
        groupsWarehousePicking: this.listGroupsWarehousesToUpdate,
        noCancelLinesWoStock: this.noCancelLinesWoStock
      });
    this.ObservablePendings.push(obs);
    obs.subscribe((res: WorkwaveModel.DataConfirmMatchLineRequest) => {
      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }

      let toastMessage = "La ola de trabajo y sus picking se han generadas correctamente.";
      let toastTime = TimesToastType.DURATION_SUCCESS_TOAST_2000;
      if (res && res.extra_info && res.extra_info.message) {
        toastMessage += `\n${res.extra_info.message}`;
        toastTime = TimesToastType.DURATION_SUCCESS_TOAST_4550;
      }
      this.intermediaryService.presentToastSuccess(toastMessage, toastTime);

      this.goPreviousPage();
    }, (error) => {
      let errorMessage = "[ERR1202] Ha ocurrido un error al intentar generar la ola de trabajo con los pickings indicados.";
      if (error && error.error && error.error.errors) {
        errorMessage = error.error.errors;
      }
      console.error('[ERR1202] Error::Subscribe:workwavesService::postConfirmMatchLineRequest::', error);
      this.intermediaryService.presentWarning(this.textParserService.formatTextToWarningAlertMessage(errorMessage), null);

      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }
    });
  }

  async presentAlertWarningOverThreshold(listWarehousesOverThreshold: Array<string>) {
    let msg = '';
    if (listWarehousesOverThreshold.length === 1) {
      msg = `Se ha superado el umbral máximo de envío a la tienda <b>${listWarehousesOverThreshold[0]}</b>. Ajuste las órdenes seleccionadas al máximo de la tienda.`
    } else {
      let warehousesOverThreshold = listWarehousesOverThreshold.join(', ');
      msg = `Se ha superado el umbral máximo de envío a las tienda <b>${warehousesOverThreshold}</b>. Ajuste las órdenes seleccionadas al máximo de cada tienda.`
    }

    const alert = await this.alertController.create({
      header: '¡Umbral máximo superado!',
      message: msg,
      buttons: ['Cerrar']
    });

    await alert.present();
  }

  async presentAlertConfirmPickings() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      message: 'Se generará y ejecutará la ola de trabajo con las especificaciones indicadas. ¿Continuar con la creación?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.showLoading('Lanzando ola de trabajo...').then(() => this.generateWorkWave());
          }
        }
      ]
    });

    await alert.present();
  }

  noCancelLines(event: boolean) {
    this.noCancelLinesWoStock = event
  }

  async showLoading(message: string) {
    this.loading = await this.loadingController.create({
      message: message,
      translucent: true,
    });
    return await this.loading.present();
  }
}

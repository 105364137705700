import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
  MatTableModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatListModule,
  MatDatepickerModule, MatInputModule, MatTooltipModule, MatIconModule, MatSortModule
} from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import { JailRoutingModule } from './jail-routing.module';
import { JailComponent } from './jail.component';
import { StoreComponent } from './store/store.component';
import { UpdateComponent } from './update/update.component';
import { CommonUiCrudModule } from '@suite/common/ui/crud';
import { DataModule } from './data/data.module';
import { BreadcrumbModule } from '../../../modules/src/components/breadcrumb/breadcrumb.module';
import { SendComponent } from './send/send.component';
import { MatFormFieldModule } from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { SendPackingComponent } from './send-packing/send-packing.component';
import { ShowDestinationsComponent } from './show-destionations/show-destinations.component';
import { SendJailComponent } from './send-jail/send-jail.component';
import { AddDestinyComponent } from './add-destiny/add-destiny.component';
import { MultipleDestinationsComponent } from './multiple-destinations/multiple-destinations.component';
import { HistoryModalComponent } from './history-modal/history-modal.component';
import { HistoryWarehouseComponent } from './history-warehouse/history-warehouse.component';
import { HistoryWarehouseNMComponent } from './history-warehouse-no-modal/history-warehouse-no-modal.component';
import { DatePipe } from '@angular/common';
import {ProductRelocationModule} from "../products/modals/product-relocation/product-relocation.module";
import {PaginatorComponentModule} from "../components/paginator/paginator.component.module";
import {FilterButtonModule} from "../components/filter-button/filter-button.module";
import {ProductDetailsModule} from "../products/modals/product-details/product-details.module";
import {TagsInputModule} from "../components/tags-input/tags-input.module";
import {ProductsComponent} from "../products/products.component";
import { ListProductsModalComponent } from './list-products-modal/list-products-modal.component';
import {SelectableListModule} from "./selectable-list/selectable-list.module";
import {SelectableListComponent} from "./selectable-list/selectable-list.component";

@NgModule({
  declarations: [
    JailComponent,
    StoreComponent,
    UpdateComponent,
    SendComponent,
    SendPackingComponent,
    ShowDestinationsComponent,
    SendJailComponent,
    HistoryModalComponent,
    HistoryWarehouseComponent,
    HistoryWarehouseNMComponent,
    MultipleDestinationsComponent,
    AddDestinyComponent,
    ListProductsModalComponent
  ],

  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    MatTableModule,
    MatCheckboxModule,
    JailRoutingModule,
    CommonUiCrudModule,
    CdkTableModule,
    DataModule,
    SelectableListModule,
    MatPaginatorModule,
    MatListModule,
    FormsModule,
    BreadcrumbModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatSortModule,
    ReactiveFormsModule,
    IonicModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    BreadcrumbModule,
    TagsInputModule,
    PaginatorComponentModule,
    FilterButtonModule,
    MatTooltipModule
  ],
  entryComponents: [
    StoreComponent,
    SendComponent,
    SendPackingComponent,
    ShowDestinationsComponent,
    SendJailComponent,
    HistoryModalComponent,
    MultipleDestinationsComponent,
    ListProductsModalComponent,
    SelectableListComponent
  ],
  providers: [
    DatePipe,
    AddDestinyComponent,
    MultipleDestinationsComponent,
    HistoryModalComponent,
    ListProductsModalComponent
  ]
})
export class JailModule { }

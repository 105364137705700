import {Injectable} from '@angular/core';
import {RequestsProvider} from "../../../providers/requests/requests.provider";
import {environment} from "@suite/services";

@Injectable({
  providedIn: 'root'
})
export class OutScanIncidenceService {

  private postSearchOutScanIncidenceUrl = environment.apiBase+'/out-scans-incidences/search';
  private getOutScanIncidenceFilterOptionsUrl = environment.apiBase+'/out-scans-incidences/filter-options';
  private postSolveOutScanIncidenceUrl = environment.apiBase+'/out-scans-incidences/solve';
  private getCheckForOutScanIncidencesUrl = environment.apiBase+'/out-scans-incidences/check-count'

  constructor(
    private requestsProvider: RequestsProvider
  ) {}

  public postSearchOutScanIncidence(params) {
    return this.requestsProvider.post(this.postSearchOutScanIncidenceUrl, params);
  }

  public getOutScanIncidenceFilterOptions() {
    return this.requestsProvider.get(this.getOutScanIncidenceFilterOptionsUrl);
  }

  public postSolveOutScanIncidence(params) {
    return this.requestsProvider.post(this.postSolveOutScanIncidenceUrl, params);
  }

  public getCheckForOutScanIncidences() {
    return this.requestsProvider.get(this.getCheckForOutScanIncidencesUrl);
  }
}

import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PositioningManualProvider {

  private _isActived: Subject<boolean> = new Subject();
  get isActived(): Subject<boolean> {
    return this._isActived;
  }

}

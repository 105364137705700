import { Component, OnInit, ViewChild } from '@angular/core';
import {WorkwavesService} from "../../../../services/src/lib/endpoint/workwaves/workwaves.service";
import {Observable} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {WorkwaveModel} from "../../../../services/src/models/endpoints/Workwaves";
import {Event as NavigationEvent, NavigationExtras, NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {AlertController} from "@ionic/angular";
import { IntermediaryService } from '@suite/services';
import {MatPaginator} from "@angular/material";
import FilterOptionsResponse = WorkwaveModel.FilterOptionsResponse;
import Pagination = WorkwaveModel.Pagination;
import Filters = WorkwaveModel.Filters;
import FilterOptions = WorkwaveModel.FilterOptions;
import Order = WorkwaveModel.Order;
import Workwave = WorkwaveModel.Workwave;
import SearchParameters = WorkwaveModel.SearchParameters;
import SearchResponse = WorkwaveModel.SearchResponse;
import {DateTimeParserService} from "../../../../services/src/lib/date-time-parser/date-time-parser.service";
import {SupplierConditionModel} from "../../../../services/src/models/endpoints/SupplierCondition";
import {MatTableDataSource} from "@angular/material/table";
import {WorkwavesScheduleModule} from "../../workwaves-schedule/workwaves-schedule.module";
import {PaginatorComponent} from "../../components/paginator/paginator.component";

@Component({
  selector: 'list-workwaves-schedule',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListWorkwavesScheduleComponent implements OnInit {

  public workWaves: any[];
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  public displayedColumns: string[] = ['warehouse', 'type', 'user', 'date', 'show'];

  filters: Filters = {
    warehouses: [],
    types: [],
    users: [],
    dates: [],
  };
  filterOptions: FilterOptions = {
    warehouses: [],
    types: [],
    users: [],
    dates: [],
  };
  order: Order = {
    field: 'date',
    direction: 'DESC'
  };
  pagination: Pagination = {
    limit: undefined,
    page: 1
  };

  showTooltip: boolean = false;

  constructor(
    private alertController: AlertController,
    private workwavesService: WorkwavesService,
    private router: Router,
    private intermediaryService: IntermediaryService,
    private dateTimeParserService: DateTimeParserService
  )
  {
    this.router.events
      .pipe(
        filter((event: NavigationEvent) => {
          return (event instanceof NavigationStart);
        })
      )
      .subscribe((event: NavigationStart) => {
          if (event.url == '/workwaves-scheduled') {
            this.loadWorkWaves();
          }
        }
      );
  }

  ngOnInit() {
    this.loadFilters();
    this.paginator.page.subscribe(paginator => {
      let flag = false;
      if(paginator.pageSize != this.pagination.limit){
        flag = true;
      }
      this.pagination.limit = paginator.pageSize;
      this.pagination.page = flag ? 1 : paginator.pageIndex;
      this.loadWorkWaves();
      this.intermediaryService.dismissLoading();
    });
  }

  ngAfterViewInit(){
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadWorkWaves();
  }

  loadFilters(){
    this.workwavesService.getFilterOptions().then((response: FilterOptionsResponse) => {
      if(response.code == 200){
        this.filterOptions = response.data;
      }else{
        console.error(response.message);
      }
    }).catch(console.error);
  }

  loadWorkWaves() {
    const parameters: SearchParameters = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };
    this.workwavesService.getListScheduled(parameters).then((response: SearchResponse) => {
      if(response.code == 200){
        this.workWaves = response.data.result;
        let paginator: any = response.data.pagination;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;
      }
      this.intermediaryService.dismissLoading();
    });
  }

  orderBy(column: string){
    if(this.order.field == column){
      this.order.direction == 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.loadWorkWaves();
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        values.push(item.id);
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.pagination.page = 1;
    this.loadWorkWaves();
  }

  addWorkWave() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        type: 1
      }
    };

    this.router.navigate(['workwave-template-rebuild'], navigationExtras);
  }

  addWorkWaveOnlineStore() {
    this.router.navigate(['workwave/online-store']);
  }

  refresh(){
    this.intermediaryService.presentLoading("Actualizando...");
    this.pagination.page = 1;
    const response = this.loadWorkWaves();
    this.intermediaryService.dismissLoading();
    return response;
  }

  reset(){
    this.filters = {
      warehouses: [],
      types: [],
      users: [],
      dates: []
    };
    this.order = {
      field: 'releaseDate',
      direction: 'DESC'
    };
    this.pagination = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 0;
    this.loadFilters();
    this.loadWorkWaves();
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {PickingNewProductsModel} from "../../../../../services/src/models/endpoints/PickingNewProducts";
import {DateTimeParserService} from "../../../../../services/src/lib/date-time-parser/date-time-parser.service";
import {environment} from "../../../../../services/src/environments/environment";

@Component({
  selector: 'regularization-product-template',
  templateUrl: './list-items.component.html',
  styleUrls: ['./list-items.component.scss']
})
export class RegularizationProductTemplateComponent implements OnInit {

  @Input() itemReceived: any;
  @Input() selectedFormControl: any;

  constructor(
    private dateTimeParserService: DateTimeParserService
  ) {}

  ngOnInit() {

  }

  processDate() {
    return this.dateTimeParserService.dateByMonthAndDate(this.itemReceived.createdAt);
  }

  getFamilyAndLifestyle(): string {
    let familyLifestyle: string[] = [];
    if (this.itemReceived.family) {
      familyLifestyle.push(this.itemReceived.family.name);
    }
    if (this.itemReceived.lifestyle) {
      familyLifestyle.push(this.itemReceived.lifestyle.name);
    }
    return familyLifestyle.join(' - ');
  }

}

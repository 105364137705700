import { Injectable } from '@angular/core';
import {AuthenticationService, environment} from "@suite/services";
import {HiringCorreosExpressModel} from "../../../models/endpoints/HiringCorreosExpress";
import SearchParameters = HiringCorreosExpressModel.SearchParameters;
import FilterOptionsResponse = HiringCorreosExpressModel.FilterOptionsResponse;
import {RequestsProvider} from "../../../providers/requests/requests.provider";
import {from, Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HiringCorreosExpressService {

  private apiMga = environment.apiBase;
  private apiCorreosExpress = environment.apiCorreosExpress;

  private getAllWarehousesUrl = this.apiMga + '/warehouses/getAllWarehouses';
  private getWarehouseCorreosExpressUrl = this.apiCorreosExpress + '/correosexpress-warehouse/';
  private updateWarehouseCorreosExpressUrl = this.apiCorreosExpress + '/correosexpress-warehouse/';
  private deleteWarehouseCorreosExpressUrl = this.apiCorreosExpress + '/correosexpress-warehouse/';
  private getFilterOptionsUrl = this.apiMga + '/warehouses/filter-options';
  private getFilterOptionsCorreosExpressUrl = this.apiCorreosExpress + '/correosexpress-warehouse/filter-options';

  constructor(
    private requestsProvider: RequestsProvider,
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getAllWarehouses(params: SearchParameters):Observable<any> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
      return this.http.post<any>(this.getAllWarehousesUrl, params, {headers}).pipe(map(response=>{
        return response.data;
      }));
    }));
  }

  getAllWarehousesCE(params: SearchParameters){
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
      return this.http.post<any>(this.getWarehouseCorreosExpressUrl, params, {headers}).pipe(map(response=>{
        return response.data;
      }));
    }));
  }

  updateWarehouseCE(data){
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
      return this.http.put<any>(this.updateWarehouseCorreosExpressUrl + data.warehouseReference, data, {headers}).pipe(map(response=>{
        return response.data;
      }));
    }));
  }

  deleteWarehouseCE(id:number){
    return this.http.delete<any>(this.deleteWarehouseCorreosExpressUrl + id)
      .pipe(map(response => {
        return response;
      }));
  }

  getFilterOptions(): Promise<FilterOptionsResponse> {
    return this.requestsProvider.get(this.getFilterOptionsUrl);
  }

  getFilterOptionsCE(): Promise<FilterOptionsResponse> {
    return this.requestsProvider.get(this.getFilterOptionsCorreosExpressUrl);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {OutScansIncidencesComponent} from "./out-scans-incidences.component";
import {
  MatListModule, MatOptionModule,
  MatPaginatorModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule
} from "@angular/material";
import {RouterModule, Routes} from "@angular/router";
import { FilterButtonModule } from "../components/filter-button/filter-button.module";
import {PaginatorComponentModule} from "../components/paginator/paginator.component.module";
import {OutScanIncidencesShowJsonModule} from "./modals/out-scan-incidences-show-json/out-scan-incidences-show-json.module";

const routes: Routes = [
  {
    path: '',
    component: OutScansIncidencesComponent
  }
];

@NgModule({
  declarations: [OutScansIncidencesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    MatTableModule,
    MatPaginatorModule,
    MatListModule,
    MatSortModule,
    RouterModule.forChild(routes),
    FormsModule,
    MatTooltipModule,
    MatSelectModule,
    MatOptionModule,
    FilterButtonModule,
    PaginatorComponentModule,
    OutScanIncidencesShowJsonModule
  ], entryComponents: [OutScansIncidencesComponent]
})
export class OutScansIncidencesModule { }

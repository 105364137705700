import { Component, OnInit, ViewChild } from '@angular/core';
import {AuthenticationService, LabelsService, TypeModel, TypesService} from '@suite/services';
import { MatSort, MatTableDataSource, Sort } from '@angular/material';
import { PrintLabelsModel } from '../../../services/src/models/endpoints/PrintLabels';
import { ToolbarProvider } from "../../../services/src/providers/toolbar/toolbar.provider";
import { IntermediaryService } from '@suite/services';
import { Router } from '@angular/router';
import {OpExpeditionType} from "./enums/OplExpeditionStatusEnums";
import {TagsInputOption} from "../components/tags-input/models/tags-input-option.model";
import {PaginatorComponent} from "../components/paginator/paginator.component";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {TransportManifestModule} from "./transport-manifest.module";

@Component({
  selector: 'transport-manifest',
  templateUrl: './transport-manifest.component.html',
  styleUrls: ['./transport-manifest.component.scss'],
})
export class TransportManifestComponent implements OnInit {
  displayedColumns: string[]=['id','date','barcode','scanner'];
  dataSource
  init:boolean =false;

  pagerValues = [20, 50, 100];
  pauseListenFormChange = false;
  requestTimeout;

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  filtersOpen: boolean;

  /**Filters */
  ids: Array<TagsInputOption> = [];
  dates: Array<TagsInputOption> = [];
  deliveryRequestExternalIds: Array<TagsInputOption> = [];
  groups: Array<TagsInputOption> = [];

  form: FormGroup = this.formBuilder.group({
    ids: [],
    dates: [],
    deliveryRequestExternalIds: [],
    pagination: this.formBuilder.group({
      page: 1,
      limit: undefined
    }),
    orderby: this.formBuilder.group({
      type: 1,
      order: "desc"
    })
  });

  incidenceStatusToHighlight: number[] = [ OpExpeditionType.RELABELLING ];

  constructor(
    private toolbarProvider: ToolbarProvider,
    private intermediaryService: IntermediaryService,
    private labelsService: LabelsService,
    private router: Router,
    private typesService: TypesService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) { }

  ionViewWillEnter(){
    if(this.init == false){
      this.getListAlerts();
    }
  }

  ngOnInit() {
    this.init = true;
    this.filtersOpen = false;
    this.toolbarProvider.currentPage.next("Incidencias")
    this.initForm();

    this.loadToolbarActions();
  }

  ngAfterViewInit() {
    this.form.value.pagination.limit = this.paginator.finalPagerValues[0];
    this.listenChanges();
    this.getListAlerts();
  }

  private loadToolbarActions() {
    let actionsToolbar = [];

    actionsToolbar = [
      {
        icon: 'funnel',
        label: 'Filtros',
        action: () => this.filtersOpen = !this.filtersOpen
      }];

    this.toolbarProvider.optionsActions.next(actionsToolbar);
  }

  initForm() {
    this.form.patchValue({
      ids: [],
      dates: [],
      deliveryRequestExternalIds: [],
      orderby: this.formBuilder.group({
        type: 1,
        order: "desc"
      })
    });
    this.ids = [];
    this.dates = [];
    this.deliveryRequestExternalIds = [];
    this.getFilters();
  }


  getFilters(): void {

    this.labelsService.getFilters().subscribe((filters) => {
      this.ids = filters.ids;
      this.dates = filters.dates;
      this.deliveryRequestExternalIds = filters.deliveryRequestExternalIds;
      this.applyFilters();
    }, (err) => {
      console.log(err);
    });
  }

  async getListAlerts(){
    await this.intermediaryService.presentLoading("Cargando incidencias");
    this.labelsService.getListAlertsExpedition(this.form.value).subscribe((res: any) => {
        if(res.results && res.results.length > 0){
          this.dataSource = new MatTableDataSource<PrintLabelsModel.AlertsTable>(res.results);
        } else {
          this.dataSource = new MatTableDataSource<PrintLabelsModel.AlertsTable>([]);
        }
        const paginator = res.pagination;
        this.groups = res.filters;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;
        this.paginator.cantSelect = paginator.limit;
        this.intermediaryService.dismissLoading();
    },
    async (err) => {
      await this.intermediaryService.dismissLoading();
    });
  }

  listenChanges() {
    let previousPageSize = this.form.value.pagination.limit;
    /**detect changes in the paginator */
    this.paginator.page.subscribe(page => {
      /**true if only change the number of results */
      let flag = previousPageSize === page.pageSize;
      previousPageSize = page.pageSize;
      this.form.value.pagination = {
        limit: page.pageSize,
        page: flag ? page.pageIndex : 1
      };
      this.getListAlerts();
    });
  }

  applyFilters() {
    if (this.pauseListenFormChange) return;
    clearTimeout(this.requestTimeout);
    this.requestTimeout = setTimeout(async () => {
      this.form.value.pagination.page = 1;
      this.form.value.pagination.limit = this.paginator.cantSelect;
      await this.getListAlerts();
    }, 100);
  }

  clearFilters() {
    this.initForm();
  }

  goScanner(row){
    if (row.status == OpExpeditionType.LABEL_ERROR_RESOLVED && row.id) {
      this.toolbarProvider.optionsActions.next([]);
      this.authenticationService.isStoreUser().then(async isStoreUser => {
        if(isStoreUser){
          this.ngOnDestroy();
          await this.router.navigate([`/list-alerts/scan/${row.id}/${row.deliveryRequestExternalId}`, {camera: true}]);
        }else{
          this.ngOnDestroy();
          await this.router.navigateByUrl(`/list-alerts/scan/${row.id}/${row.deliveryRequestExternalId}`);
        }
      });
    }else{
      if (row.status == OpExpeditionType.RELABELLING && row.id) {
        this.toolbarProvider.optionsActions.next([]);
        this.ngOnDestroy();
        this.router.navigateByUrl(`/list-alerts/change/${row.id}/${row.deliveryRequestExternalId}`);
      }
    }
  }

  refresh(){
    this.form.value.pagination.page = 1;
    this.getListAlerts();
  }

  ngOnDestroy(){
    this.dataSource = null;
    this.init=false;
  }

  isStatusErrorGenerated(alert){
    return alert && alert.status == OpExpeditionType.LABEL_GENERATION_ERROR;
  }

  isStatusErrorResolved(alert){
    return alert && alert.status == OpExpeditionType.LABEL_ERROR_RESOLVED;
  }

  isAddressChange(alert){
    return alert && alert.status == OpExpeditionType.RELABELLING;
  }

  public highlightIncidence(alert): boolean {
    return alert && this.incidenceStatusToHighlight.includes(alert.status);
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ExpeditionManualService } from '../../../../services/src/lib/endpoint/expedition-manual/expedition-manual.service';
import { IntermediaryService } from '../../../../services/src/lib/endpoint/intermediary/intermediary.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment, LabelsService, WarehouseModel, WarehouseService} from "@suite/services";
import { saveAs } from "file-saver";
import {LabelModel} from "../../../../services/src/models/endpoints/Label";
import {DownloaderService} from "../../../../services/src/lib/downloader/downloader.service";
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'suite-logistic-operator',
  templateUrl: './manage-incidence.component.html',
  styleUrls: ['./manage-incidence.component.scss']
})
export class ManageIncidenceComponent implements OnInit {

  id;
  deliveryRequestExternalId: string = '';
  data = {
    name: "",
    lastname: "",
    dni: "",
    phone: "",
    email: "",
    direction: "",
    province: "",
    city: "",
    country: "",
    postalcode: "",
    packages: "",
    incidence: true
  };

  initialData;
  private SHIPPING_MODE_STORE = 3;

  form: FormGroup;
  operators;
  selected;
  warehouses: any[];

  isWarehousePhone = false;
  isWarehouseEmail = false;

  public dniEnabled: boolean = false;
  public incidenceInfo: any = null;

  private regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private expeditionManualService: ExpeditionManualService,
    private intermediaryServiceL: IntermediaryService,
    private navParams: NavParams,
    private labelsService: LabelsService,
    private downloaderService: DownloaderService,
    private warehouseService: WarehouseService
  ) {}

  async ngOnInit() {
    this.loadWarehouses();
    this.id = this.navParams.get('id');
    this.deliveryRequestExternalId = this.navParams.get('deliveryRequestExternalId');
    this.getTransports();
    this.form = new FormGroup({
      expeditionId: new FormControl(this.id),
      name: new FormControl('', [Validators.required]),
      lastname: new FormControl(''),
      dni: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl('', [Validators.pattern(this.regexEmail)]),
      direction: new FormControl('', [Validators.required]),
      province: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      postalcode: new FormControl('', [Validators.required]),
      packages: new FormControl('', [Validators.required]),
      incidence: new FormControl(true)
    });
    await this.getIncidence(this.id);
  }

  async getIncidence(id) {
    this.expeditionManualService.getExpedition(id).subscribe(async data => {
      if(data) {
        this.incidenceInfo = data;
        this.initialData = {...data};
        this.form.get('name').setValue(data.transport ? data.transport.shippingAddressee1 : '');
        this.form.get('lastname').setValue(data.transport ? data.transport.shippingAddressee2 : '');
        this.form.get('dni').setValue(data.transport ? data.transport.shippingCompanyName : '');
        this.form.get('phone').setValue(data.transport ? data.transport.shippingPhone : '');
        if (data.transport && data.transport.shippingEmail) {
          this.form.get('email').setValue(data.transport.shippingEmail);
        } else {
          this.form.get('email').setValue(data.package && data.package[0] ? data.package[0].email : '');
        }
        if (data.transport.shippingAddress2 && data.transport.shippingAddress2 !== '') {
          this.form.get('direction').setValue(`${data.transport.shippingAddress1}, ${data.transport.shippingAddress2}`);
        } else {
          this.form.get('direction').setValue(data.transport.shippingAddress1);
        }
        this.form.get('province').setValue(data.transport ? data.transport.shippingProvince : '');
        this.form.get('city').setValue(data.transport ? data.transport.shippingCity : '');
        this.form.get('country').setValue(data.transport ? data.transport.shippingCountry : '');
        this.form.get('postalcode').setValue(data.transport ? data.transport.shippingZipCode : '');
        this.form.get('packages').setValue(data.countPackage);

        let phoneValue = this.form.get('phone').value.trim();
        let emailValue = this.form.get('email').value.trim();

        let phoneValueNoSpaces = phoneValue.replace(/\s/g,'');
        let warehousePhoneValueNoSpaces = this.incidenceInfo.warehouse.phone.replace(/\s/g,'');

        this.isWarehousePhone = (phoneValueNoSpaces == warehousePhoneValueNoSpaces);
        this.isWarehouseEmail = (emailValue == this.incidenceInfo.warehouse.eMail);

      } else {
        await this.clean();
      }
    });
  }

  onWarehouseChange(event: CustomEvent): void {
    const selectedWarehouseId = event.detail.value;
    const warehouseSelected = this.warehouses.find(warehouse => warehouse.id === selectedWarehouseId);
    this.incidenceInfo.shopId = selectedWarehouseId;
    if (this.initialData.package[0].shippingServiceAux && this.initialData.package[0].shippingServiceAux != "" && String(parseInt(warehouseSelected.reference)) == String(parseInt(this.initialData.package[0].shippingServiceAux))) {
      this.restoreInitialData();
    } else {
      this.updateFormData(warehouseSelected);
    }
  }

  private updateFormData(warehouse): void {
    if(warehouse){
      this.form.get('direction').setValue(warehouse.address2 ? `${warehouse.address1}, ${warehouse.address2}` : warehouse.address1);
      this.form.get('city').setValue(warehouse.city);
      this.form.get('province').setValue(warehouse.province);
      this.form.get('country').setValue(warehouse.countryId);
      this.form.get('postalcode').setValue(warehouse.zipCode);
    }
  }

  private restoreInitialData(): void {
    this.form.get('name').setValue(this.initialData.transport ? this.initialData.transport.shippingAddressee1 : '');
    this.form.get('lastname').setValue(this.initialData.transport ? this.initialData.transport.shippingAddressee2 : '');
    this.form.get('dni').setValue(this.initialData.transport ? this.initialData.transport.shippingCompanyName : '');
    this.form.get('phone').setValue(this.initialData.transport ? this.initialData.transport.shippingPhone : '');
    if (this.initialData.transport && this.initialData.transport.shippingEmail) {
      this.form.get('email').setValue(this.initialData.transport.shippingEmail);
    } else {
      this.form.get('email').setValue(this.initialData.package && this.initialData.package[0] ? this.initialData.package[0].email : '');
    }
    if (this.initialData.transport.shippingAddress2 && this.initialData.transport.shippingAddress2 !== '') {
      this.form.get('direction').setValue(`${this.initialData.transport.shippingAddress1}, ${this.initialData.transport.shippingAddress2}`);
    } else {
      this.form.get('direction').setValue(this.initialData.transport.shippingAddress1);
    }
    this.form.get('province').setValue(this.initialData.transport ? this.initialData.transport.shippingProvince : '');
    this.form.get('city').setValue(this.initialData.transport ? this.initialData.transport.shippingCity : '');
    this.form.get('country').setValue(this.initialData.transport ? this.initialData.transport.shippingCountry : '');
    this.form.get('postalcode').setValue(this.initialData.transport ? this.initialData.transport.shippingZipCode : '');
    this.form.get('packages').setValue(this.initialData.countPackage)
  }

  private async loadWarehouses(): Promise<void> {
    const warehouses$ = await this.warehouseService.getFullIndex();
    warehouses$.subscribe((response: HttpResponse<any>) => {
      this.warehouses = response.body.data;
    });
  }

  getTransports() {
    this.expeditionManualService.getTrasnport().subscribe(data => {
      this.operators = data;
    });
  }

  async save() {
    if (!this.form.valid) {
      this.intermediaryServiceL.presentToastError('Los datos introducidos no son correctos. Compruébelos para poder continuar.');
    } else {
      await this.intermediaryServiceL.presentLoading();

      const initialDestiny = (this.initialData.package[0].shippingServiceAux && this.initialData.package[0].shippingServiceAux != "");
      const isShippingModeStore = (this.incidenceInfo.package[0].shippingMode == this.SHIPPING_MODE_STORE);
      let initialDestinyWarehouse = null;

      if (!this.incidenceInfo.shopId) {
        if (isShippingModeStore) {
          if (initialDestiny) {
            initialDestinyWarehouse = this.warehouses.find(warehouse => String(parseInt(warehouse.reference)) == String(parseInt(this.initialData.package[0].shippingServiceAux)));
            this.incidenceInfo.shopId = initialDestinyWarehouse.id;
          } else {
            this.intermediaryServiceL.dismissLoading();
            this.intermediaryServiceL.presentToastError('Es necesario seleccionar un destino');
            return;
          }
        } else {
          this.incidenceInfo.shopId = null;
        }
      } else {
        if (initialDestiny) {
          initialDestinyWarehouse = this.warehouses.find(warehouse => String(parseInt(warehouse.reference)) == String(parseInt(this.initialData.package[0].shippingServiceAux)));
        }
      }

      const destinyId = isShippingModeStore ? this.incidenceInfo.shopId : null;
      let destinyChanged = false;
      if (initialDestinyWarehouse) {
        destinyChanged = (isShippingModeStore) ? (destinyId != initialDestinyWarehouse.id) : false;
      } else {
        destinyChanged = isShippingModeStore;
      }

      this.expeditionManualService
        .postManageIncidence(this.form.value, destinyId, destinyChanged)
        .subscribe(data => {
          this.intermediaryServiceL.dismissLoading();
          let setDocAsImpress: boolean = false;
          if (data.success) {
            this.intermediaryServiceL.presentToastSuccess(`Incidencia gestionada y generada la expedición con ${data.operator}.`);
          } else {
            this.intermediaryServiceL.presentToastError('No se ha podido contratar una expedición para los datos gestionados.');
            setDocAsImpress = true;
          }
          this.sendServicePrintPack(data.expeditionId, setDocAsImpress);
          this.close(true);
        }, error => {
          this.intermediaryServiceL.dismissLoading();
          this.intermediaryServiceL.presentToastError('Ha ocurrido un error al intentar gestionar los datos de la incidencia.');
        });
    }
  }

  close(isUpdate: boolean = false) {
    this.modalController.dismiss({update: isUpdate});
    this.clean();
  }

  async clean() {
    this.form.get('name').setValue("");
    this.form.get('lastname').setValue("");
    this.form.get('dni').setValue("");
    this.form.get('phone').setValue("");
    this.form.get('email').setValue("");
    this.form.get('direction').setValue("");
    this.form.get('province').setValue("");
    this.form.get('city').setValue("");
    this.form.get('country').setValue("");
    this.form.get('postalcode').setValue("");
    this.form.get('packages').setValue("");
  }

  selectChange(e) {}

  formInputIsRequired(formInput: string) {
    if (this.form.controls[formInput]) {
      if (this.form.controls[formInput].hasError('required')) {
        return true;
      }
    }
    return false;
  }

  async sendServicePrintPack(expeditionId: number, setAsImpress: boolean) {
    await this.intermediaryServiceL.presentLoadingNew('Descargando comprobante PDF...');

    let body = { expeditionId, setAsImpress };
    await this.labelsService
      .postServicePrintPack(body)
      .subscribe((result: LabelModel.IExpeditionDocToPrint[]) => {
          this.intermediaryServiceL.dismissLoadingNew();
          for (const iExpeditionDoc in result) {
            const expeditionDoc = result[iExpeditionDoc];
            const urlDownload = environment.downloadPdf + '/' + expeditionDoc.externalPath;
            const fileName = this.downloaderService.getExpeditionDownloadedFileName(expeditionDoc.extension, expeditionDoc.expeditionDeliveryRequest);

            if (parseInt(iExpeditionDoc) == (result.length - 1)) {
              saveAs(urlDownload, fileName)
            } else {
              saveAs(urlDownload, fileName)
            }
          }
        }, async (err) => {
          await this.intermediaryServiceL.dismissLoadingNew();
          await this.intermediaryServiceL.presentToastError("Error al intentar descargar el archivo PDF generado.");
        });
  }

  public getIncidenceMessage(): string {
    if (this.incidenceInfo) {
      if (this.incidenceInfo.incidence) {
        if (this.incidenceInfo.incidence.errorMessage) {
          return this.incidenceInfo.incidence.errorMessage;
        }
      }
    }
    return 'No se ha registrado en el sistema un mensaje de error para la incidencia consultada.';
  }

  findSelectedWarehouse(packageWarehouse, warehouseReference) {
    if (this.incidenceInfo.package[0].shippingServiceAux && this.incidenceInfo.package[0].shippingServiceAux != "") {
      return (String(parseInt(packageWarehouse)) == String(parseInt(warehouseReference)));
    }
    return false;
  }
}

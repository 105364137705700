import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {HttpRequestModel} from "../../../models/endpoints/HttpRequest";
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {

  private postGetDeviceInfoUrl: string = environment.apiBase + "/device-info/";
  private postGetFiltersDeviceInfoUrl: string = environment.apiBase + "/device-info/filters";

  constructor(private http: HttpClient) {
  }

  getDeviceInfo(body): Observable<any> {
    return this.http.post<HttpRequestModel.Response>(this.postGetDeviceInfoUrl, body).pipe(
      map(resp => resp.data)
    )
  }

  getFiltersDeviceInfo(): Observable<any> {
    return this.http.get<HttpRequestModel.Response>(this.postGetFiltersDeviceInfoUrl).pipe(
      map(resp => resp.data)
    )
  }

}

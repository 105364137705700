import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatListModule } from '@angular/material';
import { RouterModule, Routes } from "@angular/router";
import { BreadcrumbModule } from '../components/breadcrumb/breadcrumb.module';
import { TagsInputModule } from '../components/tags-input/tags-input.module';
import { PaginatorComponentModule } from '../components/paginator/paginator.component.module';
import { FilterButtonModule } from "../components/filter-button/filter-button.module";
import { MatTooltipModule } from "@angular/material";
import { TransportPackageCollectedComponent } from './transport-package-collected.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule, MatGridListModule} from "@angular/material";
import {
  MatCheckboxModule,
  MatPaginatorModule,
  MatRippleModule,
  MatSortModule,
  MatTableModule
} from '@angular/material';
import { NgxFileDropModule } from  'ngx-file-drop' ;
import {PackagesComponent} from "../transport-package-collected/packages/packages.component";
import {CdkTableModule} from "@angular/cdk/table";
import {OrderToPickUpComponent} from "./list-items/list-items.component";
import {Downloader} from "@ionic-native/downloader/ngx";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {PackagesWarningCancelledAlComponent} from "./packages-warning-cancelled-al/packages-warning-cancelled-al.component";

const routes: Routes = [
  {
    path: '',
    component: TransportPackageCollectedComponent
  }
];

@NgModule({
  declarations: [TransportPackageCollectedComponent,PackagesComponent,OrderToPickUpComponent, PackagesWarningCancelledAlComponent],
  entryComponents: [PackagesComponent,OrderToPickUpComponent, PackagesWarningCancelledAlComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    BreadcrumbModule,
    TagsInputModule,
    PaginatorComponentModule,
    FilterButtonModule,
    MatTooltipModule,
    FormsModule,
    MatCheckboxModule,
    MatRippleModule,
    MatSortModule,
    NgxFileDropModule,
    MatTabsModule,
    MatExpansionModule,
    MatGridListModule,
    RouterModule.forChild(routes),
    CdkTableModule,
    MatIconModule,
    MatButtonModule
  ],
  providers: [ Downloader ]
})
export class TransportPackageCollectedModule { }

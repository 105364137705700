import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MarketplacesService} from "../../../../services/src/lib/endpoint/marketplaces/marketplaces.service";
import {MatTableDataSource, Sort} from "@angular/material";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {PageEventPaginator, PaginatorComponent} from "../../components/paginator/paginator.component";
import {IntermediaryService, InventoryService} from "@suite/services";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import * as Filesave from 'file-saver';

@Component({
  selector: 'suite-market-refund',
  templateUrl: './market-refund.component.html',
  styleUrls: ['./market-refund.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MarketRefundComponent implements OnInit, AfterViewInit{

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  searchKey: string;
  orderColumns = ["avelonId", "client", "city", "units", "price", "status", "date", "change-tracking"];
  orderLineColumns = ["model", "size", "price", "status", "actions"];
  paginatorOptions = {
    page : 1,
    limit : 15,
  };
  marketColumnTitle : string;
  expandedElement: any | null;
  marketAllowChangeTracking = false;

  dataSource = new MatTableDataSource([]);
  orderLines;
  market: any;
  finalStates : string[];

  dataRefunds;
  orderType;
  typeOrder;

  constructor(private route : ActivatedRoute,
              private marketPlacesSrv : MarketplacesService,
              private intermediaryService : IntermediaryService,
              private inventoryServices: InventoryService,) { }

  ngOnInit() {
    this.route.params.subscribe(params=> {
      this.marketColumnTitle = params.name;
      this.market = this.marketPlacesSrv.getCachedMarketById(params.id);
    });
    this.loadPaginatorOptions();
  }

  loadPaginatorOptions(): void {
    this.paginator.useCustomPagerValues = true;
  }

  generateRequestBody(){
    return {
      platformIdentifier:this.market.externalId,
      pagination: {
        page : this.paginatorOptions.page,
        limit : this.paginatorOptions.limit
      },
      orderInfo: {
        field : this.orderType,
        dir : this.typeOrder != undefined ? this.typeOrder.toUpperCase() : this.typeOrder,
      },
      searchQuery: this.searchKey
    }
  }

  async ngAfterViewInit() {
    await this.searchMarketOrders();
  }

  async search(){
    this.paginatorOptions.page = 1;
    this.paginator.pageIndex = 1;
    await this.searchMarketOrders();
  }

  async searchMarketOrders(){

    await this.intermediaryService.presentLoadingNew('Cargando pedidos...');
    const body = this.generateRequestBody();
    this.marketPlacesSrv.postSearchPlatformOrders(body).subscribe(async response =>{

      if(response && response.orders.length){
        this.loadOrders(response.orders);
        for(let i = 0; i < response.orders.length; i++){
          response.orders[i].customerName = response.orders[i].customer.firstName+' '+response.orders[i].customer.surname;
          for(let x = 0; x < response.orders[i].expedition.length; x++){
            response.orders[i].trackingCode = response.orders[i].expedition[x].trackingCode;
          }
          for(let x = 0; x < response.orders[i].state.length; x++){
            response.orders[i].stateOrder = response.orders[i].state[x].state;
          }
          for(let x = 0; x < response.orders[i].lines.length; x++){
            response.orders[i].lines[x].stateOrderLine = response.orders[i].lines[x].states.slice(-1)[0].state;
          }

          let date = new Date( Date.parse(response.orders[i].createdAt) );
          let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
          let month = (date.getMonth() + 1) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
          let year = date.getFullYear();
          let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
          let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
          response.orders[i].createdAt = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
        }
        this.finalStates = response.finalStates;
        this.dataRefunds = response.orders;
        this.updateTotalOrders(response.totalCount);
      }
      await this.intermediaryService.dismissLoadingNew()
    }, async _ => await this.intermediaryService.dismissLoadingNew())
  }

  private loadOrders(ordersInfo : any[]){
    if(ordersInfo && ordersInfo.length){
      this.dataSource = new MatTableDataSource(ordersInfo);
    }
  }

  private updateTotalOrders(newTotal){
    this.paginator.length = newTotal;
    this.paginator.lastPage = Math.ceil(this.paginator.length / this.paginatorOptions.limit);
  }

  getAvelonRemoteMarketOrderId(orderMappings : any[]){
    return orderMappings.find(mapping => mapping.scopeIdentifier != this.market.externalId);
  }

  orderSelected(element: any) {
    this.orderLines = new MatTableDataSource(element.lines);
  }

  async sortData(event: Sort) {
    switch (event.active) {
      case 'order':
          this.orderType = 'mapping.remoteEntityIdentifier';
        this.typeOrder = event.direction !== '' ? event.direction : 'ASC';
        break;
      case 'avelonId':
        this.orderType = 'order.prefixedUniqueOriginReference';
        this.typeOrder = event.direction !== '' ? event.direction : 'ASC';
        break;
      case 'client':
        this.orderType = 'customer.firstName';
        this.typeOrder = event.direction !== '' ? event.direction : 'ASC';
        break;
      case 'city':
        this.orderType = 'address.city';
        this.typeOrder = event.direction !== '' ? event.direction : 'ASC';
        break;
      case 'price':
        this.orderType = 'order.orderTotalAmountWithVat';
        this.typeOrder = event.direction !== '' ? event.direction : 'ASC';
        break;
      case 'date':
        this.orderType = 'order.createdAt';
        this.typeOrder = event.direction !== '' ? event.direction : 'ASC';
        break;
      default:
        this.orderType = undefined;
        this.typeOrder = undefined;
        break;
    }
    await this.searchMarketOrders();
  }

  async changePage(event: PageEventPaginator) {
    this.paginatorOptions.page = event.pageIndex;
    if(event.pageSize != this.paginatorOptions.limit){
      this.paginatorOptions.page = 1;
      this.paginator.pageIndex = 1;
    }
    this.paginatorOptions.limit = event.pageSize;
    await this.searchMarketOrders();
  }

  async updateTracking(orderId) {
    await this.intermediaryService.presentInputConfirm('Actualizar tracking',
      [
      {
        name: 'inputTracking',
        type: 'text',
        id: 'input-tracking-id',
        // value: 'valor-input',
        placeholder: 'Tracking'
      }
    ],
      (inputData)=>{
        let body = { tracking: inputData.inputTracking };
        this.marketPlacesSrv.postUpdateTrackingOrder(orderId, body).subscribe(async response => {
          await this.intermediaryService.presentToastSuccess("El tracking se ha actualizado con éxito");
        }, async error => {
          await this.intermediaryService.presentToastError("Error al actualizar el tracking");
        });
    });
  }

  isActionButtonDisabled(orderLine){
    return this.finalStates.includes(orderLine.stateOrderLine);
  }

  async marksAsReturned(orderLine) {
    let body = { "lineState": ["return.completed_full"] };
    await this.intermediaryService.presentConfirm("¿Está seguro que quiere marcar como devuelta la línea de este pedido?",() => {
      this.marketPlacesSrv.patchOrderLine(orderLine.id, body).subscribe(async response => {
        if (response){
          await this.intermediaryService.presentToastSuccess("Se ha marcado como devuelta la línea del pedido");
          orderLine.stateOrderLine = this.finalStates[1]
        } else {
          await this.intermediaryService.presentToastError("No se ha podido marcar como devuelta la línea del pedido.Inténtelo más tarde.");
        }
      }, async error => {
        await this.intermediaryService.presentToastError("Error al marcar como devuelta la línea del pedido");
      });
    });
  }

  async cancelOrderLine(orderLine) {
    let body = { "lineState": ["placement.cancelled"] };
    await this.intermediaryService.presentConfirm("¿Está seguro que quiere marcar como cancelada la línea de este pedido?",async () => {
      this.marketPlacesSrv.patchOrderLine(orderLine.id, body).subscribe(async response => {
        if (response){
          await this.intermediaryService.presentToastSuccess("Se ha marcado como cancelada la línea del pedido");
          orderLine.stateOrderLine = this.finalStates[0]
        } else {
          await this.intermediaryService.presentToastError("No se ha podido marcar como cancelada la línea del pedido.Inténtelo más tarde.");
        }
      }, async error => {
        await this.intermediaryService.presentToastError("Error al marcar como cancelada la línea del pedido");
      });
    });
  }

  async exportToExcel() {
    await this.intermediaryService.presentLoadingNew('Descargando Archivo Excel');
    const formToExcel = this.generateRequestBody();
    if (formToExcel.pagination) {
      formToExcel.pagination.page = 1;
      formToExcel.pagination.limit = 0;
    }
    this.inventoryServices.getFileExcellZalando(this.sanitize(formToExcel)).pipe(
      catchError(error => of(error)),
      // map(file => file.error.text)
    ).subscribe(async (data) => {
        console.log(data);
        const blob = new Blob([data], { type: 'application/octet-stream' });
        Filesave.saveAs(blob, `${Date.now()}.xlsx`);
        await this.intermediaryService.dismissLoadingNew();
        await this.intermediaryService.presentToastSuccess('Archivo descargado')
    }, error => console.log(error));
  }

  sanitize(object) {
    /**mejorable */
    object = JSON.parse(JSON.stringify(object));
    if (!object.orderInfo.dir) delete object.orderInfo.dir;
    Object.keys(object).forEach(key => {
      if (object[key] instanceof Array) {
        if (object[key][0] instanceof Array) {
          object[key] = object[key][0];
        } else {
          for (let i = 0; i < object[key].length; i++) {
            if (object[key][i] === null || object[key][i] === "") {
              object[key].splice(i, 1);
            }
          }
        }
      }
      if (object[key] === null || object[key] === "") {
        delete object[key];
      }
    });
    return object;
  }

  resetFilters() {
    this.searchKey = '';
  }

}

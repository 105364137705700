export enum OpExpeditionType {
	GENERATED_IN_AC = 1,
	LOCKED = 2,
	UNLOCKED = 3,
	GENERATED_TAG = 4,
	LABEL_GENERATION_ERROR = 5,
	LABEL_ERROR_RESOLVED = 6,
  PICKEDUP = 7,
  SENT = 8,
  GENERATED_TAG_LOGISTIC_INTERNAL = 9,
  PICKEDUP_INTERN = 10,
  TO_PICK_IN_WAREHOUSE_ATTEND = 11,
  RELABELLING = 12
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CdkTableModule } from '@angular/cdk/table';
import { PrintRelabelPackingRoutingModule } from "./print-relabel-packing-routing.module";
import { PrintRelabelPackingComponent } from "./print-relabel-packing.component";
import { CommonUiCrudModule } from '@suite/common/ui/crud';
import {ListPackingRelabelTemplateComponent} from "./list-packing/list-packing.component";
import {PackingRelabelTemplateComponent} from "./list-packing/list-items/list-items.component";
import {MatTooltipModule} from '@angular/material/tooltip';
import {
  MatTableModule,
  MatCheckboxModule,
  MatGridListModule,
  MatRadioModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatSelectModule,
  MatDatepickerModule,
  MatInputModule
} from '@angular/material';
import {PaginatorComponentModule} from "../components/paginator/paginator.component.module";
import {TagsInputModule} from "../components/tags-input/tags-input.module";

@NgModule({
  declarations: [
    PrintRelabelPackingComponent,
    ListPackingRelabelTemplateComponent,
    PackingRelabelTemplateComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    PrintRelabelPackingRoutingModule,
    CommonUiCrudModule,
    CdkTableModule,
    FormsModule,
    MatGridListModule,
    MatTableModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    PaginatorComponentModule,
    TagsInputModule,
  ],
  entryComponents: [
    ListPackingRelabelTemplateComponent,
    PackingRelabelTemplateComponent
  ]
})
export class PrintRelabelPackingModule {}

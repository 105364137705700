import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegistryDetailsComponent} from './registry-details.component';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule, MatTooltipModule } from '@angular/material';
import {ButtonChangeStateModule} from '../button-change-state/button-change-state.module';
import {DetailsRegisterModule} from "../../../defect-handler/details-register/details-register.module";

@NgModule({
  declarations: [RegistryDetailsComponent],
  entryComponents: [RegistryDetailsComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    MatTooltipModule,
    MatExpansionModule,
    ButtonChangeStateModule,
    DetailsRegisterModule,
    ReactiveFormsModule,
  ],
  exports: [RegistryDetailsComponent]
})
export class RegistryDetailsModule { }

import { Injectable } from '@angular/core';
import {AuthenticationService, environment} from "@suite/services";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {from, Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RefundsService {

  private apiCrm = environment.apiCrm;
  private getRefundsOkUrl = this.apiCrm + '/refunds/refunds-ok/';
  private getRefundsKoUrl = this.apiCrm + '/refunds/refunds-ko/';
  private updateRefundsUrl = this.apiCrm + '/refunds/';

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) {}

  getRefundsOk():Observable<any> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
      return this.http.get<any>(this.getRefundsOkUrl, {headers}).pipe(map(response=>{
        return response.data;
      }));
    }));
  }

  getRefundsKo():Observable<any> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
      return this.http.get<any>(this.getRefundsKoUrl, {headers}).pipe(map(response=>{
        return response.data;
      }));
    }));
  }

  updateRefunds(data){
    return from(this.auth.getCurrentToken()).pipe(switchMap(token=>{
      let headers:HttpHeaders = new HttpHeaders({Authorization:token});
        return this.http.put<any>(this.updateRefundsUrl + data.id, data, {headers}).pipe(map(response=>{
          return response.data;
        }));
    }));
  }
}

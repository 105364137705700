import {Injectable} from "@angular/core";
import {ColorSorterModel} from "../../models/endpoints/ColorSorter";
import {TemplateSorterModel} from "../../models/endpoints/TemplateSorter";
import {OutputSorterModel} from "../../models/endpoints/OutputSorter";
import {MatrixSorterModel} from "../../models/endpoints/MatrixSorter";

@Injectable({
  providedIn: 'root'
})
export class SorterProvider {

  private _colorSelected: ColorSorterModel.ColorSorter;
  get colorSelected(): ColorSorterModel.ColorSorter {
    return this._colorSelected;
  }
  set colorSelected(value: ColorSorterModel.ColorSorter) {
    this._colorSelected = value;
  }

  private _idZoneSelected: number;
  get idZoneSelected(): number {
    return this._idZoneSelected;
  }
  set idZoneSelected(value: number) {
    this._idZoneSelected = value;
  }

  private _idTemplateSelected: number;
  get idTemplateSelected(): number {
    return this._idTemplateSelected;
  }
  set idTemplateSelected(value: number) {
    this._idTemplateSelected = value;
  }

  private _colorActiveForUser: string;
  get colorActiveForUser(): string {
    return this._colorActiveForUser;
  }
  set colorActiveForUser(value: string) {
    this._colorActiveForUser = value;
  }

  private _processActiveForUser: number;
  get processActiveForUser(): number {
    return this._processActiveForUser;
  }
  set processActiveForUser(value: number) {
    this._processActiveForUser = value;
  }

  private _templateToEditSelected: TemplateSorterModel.Template;
  get templateToEditSelected(): TemplateSorterModel.Template {
    return this._templateToEditSelected;
  }
  set templateToEditSelected(value: TemplateSorterModel.Template) {
    this._templateToEditSelected = value;
  }

  private _infoSorterOutputOperation: OutputSorterModel.OutputSorter;
  get infoSorterOutputOperation(): OutputSorterModel.OutputSorter {
    return this._infoSorterOutputOperation;
  }
  set infoSorterOutputOperation(value: OutputSorterModel.OutputSorter) {
    this._infoSorterOutputOperation = value;
  }

  private idWareHouse: number;
  /**
   * @description Return a Id of wareHouse
   */
  get id_wareHouse():number{
    return this.idWareHouse;
  }
  /**
   * @description Set id WareHouse
   * @param id Number
   */
  set id_wareHouse(id:number){
    this.idWareHouse = id;
  }

  /*
  * Check if the matrix loaded for sorter need select a zone to work.
  * This occurs when the matrix have more that 1 zone created.
  * */
  public matrixNeedsZoneSelection(matrix: MatrixSorterModel.MatrixTemplateSorter[]): boolean {
    let zonesList = [];

    // Get zones in matrix
    matrix.forEach(h => {
      h.columns.forEach(c => {
        const zone = c.way && c.way.templateZone && c.way.templateZone.zones ? c.way.templateZone.zones.id : null;
        zonesList.push(zone);
      });
    });

    // Remove duplicate zones
    zonesList = zonesList.filter((z, i, s) => i === s.indexOf(z));

    // Remove null values
    zonesList = zonesList.filter(z => typeof z != 'undefined' && z != null);

    return zonesList.length > 1;
  }
}

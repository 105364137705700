import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransportOrderPackageComponent } from './transport-order-package.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTabsModule, MatCheckboxModule, MatListModule, MatTableModule, MatPaginatorModule, MatExpansionModule } from '@angular/material';
import { FilterButtonModule } from '../components/filter-button/filter-button.module';
import { PaginatorComponentModule } from '../components/paginator/paginator.component.module';
import { RouterModule, Routes } from "@angular/router";
import {
  MatRippleModule,
  MatSortModule,
} from '@angular/material';
import {TagsInputModule} from "../components/tags-input/tags-input.module";
import {BreadcrumbModule} from "../components/breadcrumb/breadcrumb.module";
import {PackagesComponent} from "../transport-order-package/packages/packages.component";
import {Downloader} from "@ionic-native/downloader/ngx";
import {FabExtendedModule} from "../components/button/fab/fab-extended/fab-extended.module";

const routes: Routes = [
  {
    path: '',
    component: TransportOrderPackageComponent
  }
];

@NgModule({
  declarations: [TransportOrderPackageComponent,PackagesComponent],
  entryComponents: [PackagesComponent],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatCheckboxModule,
    FilterButtonModule,
    PaginatorComponentModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatRippleModule,
    MatSortModule,
    FormsModule,
    TagsInputModule,
    BreadcrumbModule,
    RouterModule.forChild(routes),
    FabExtendedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ Downloader ]
})
export class TransportOrderPackageModule { }

import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { PackingInventoryService } from 'libs/services/src/lib/endpoint/packing-inventory/packing-inventory.service';
import { PackingInventoryModel } from 'libs/services/src/models/endpoints/PackingInventory';
import {PaginatorComponent} from "../../components/paginator/paginator.component";
import {MatTableDataSource} from "@angular/material/table";
import { IntermediaryService } from 'libs/services/src/lib/endpoint/intermediary/intermediary.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import * as Filesave from 'file-saver';

@Component({
  selector: 'suite-list-products-modal',
  templateUrl: './list-products-modal.component.html',
  styleUrls: ['./list-products-modal.component.scss']
})
export class ListProductsModalComponent implements OnInit {

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  public dataSource = null;
  public displayedColumns: string[] = ['typeContent','model','size','product','package','order'];
  products: any[];
  filters: any = {
    models: [],
    products: [],
    packages: [],
    orders: [],
    sizes: [],
    types:[]
  };
  filterOptions: any = {
    models: [],
    products: [],
    packages: [],
    orders: [],
    sizes: [],
    types:[]
  };
  order: any = {
    field: 'models',
    direction: 'DESC'
  };
  pagination: any = {
    limit: undefined,
    page: 1
  };

  packingId:number;
  jail;
  private scrollDepthTriggered = false;

  constructor(
    private modalController: ModalController,
    private packingInventoryService: PackingInventoryService,
    private navParams: NavParams,
    private intermediaryService: IntermediaryService,

  ) {
    this.jail = this.navParams.get("jail");
    this.packingId = this.jail.id;
    console.log('Dato Recibido', this.packingId);

   }

  ngOnInit() {
    this.loadFilters();
    this.paginator.page.subscribe(paginator => {
      if (this.pagination.limit != paginator.pageSize) {
        this.pagination.page = 1;
      } else {
        this.pagination.page = paginator.pageIndex;
      }
      this.pagination.limit = paginator.pageSize;
      this.loadData();
    });
  }

  ngAfterViewInit(){
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadData();
  }

  close() {
    this.modalController.dismiss()
  }

  loadFilters(){
    this.packingInventoryService.getFilters(this.packingId).subscribe((response: PackingInventoryModel.ResponseSearchFilters) => {
      console.log('RESPONSE: ',response)
      if(response.code == 200){
        this.filterOptions = {
          models: response.data.filters.models.map(data => {
            return {
              id: data.id,
              value: data.reference,
              checked: false,
              hide: false
            }
          }),
          products: response.data.filters.products.map(data => {
            return {
              id: data.id,
              value: data.reference,
              checked: false,
              hide: false
            }
          }),
          packages: response.data.filters.packages.map(data => {
            return {
              id: data.id,
              value: data.reference,
              checked: false,
              hide: false
            }
          }),
          orders: response.data.filters.orders.map(data => {
            return {
              id: data.id,
              value: data.reference,
              checked: false,
              hide: false
            }
          }),
          sizes: response.data.filters.sizes.map(data => {
            return {
              id: data.id,
              value: data.reference,
              checked: false,
              hide: false
            }
          }),
          types: response.data.filters.types.map(data => {
            return {
              id: data.id,
              value: data.reference,
              checked: false,
              hide: false
            }
          })
        }
        console.log('This.filterOptions', this.filterOptions)
      }else{
        console.error(response);
      }
    });
  }

  loadData(){
    const parameters: PackingInventoryModel.SearchParameters = {
      packingId:this.packingId,
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };
    this.packingInventoryService.searchProductsByPackingId(parameters).subscribe((response: PackingInventoryModel.ResponseSearchFilters) => {
      if(response.code == 200){
        console.log('DATOS: ',response);
        this.dataSource = new MatTableDataSource<PackingInventoryModel.SearchProducts>(response.data.results);
        console.log(this.dataSource);
        this.products = response.data.results;
        const paginator = response.data.pagination;
        this.paginator.cantSelect = paginator.limit;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;
      }else{
        console.error(response);
      }
    });
  }

  orderBy(column: string){
    if(this.order.field == column){
      this.order.direction == 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.loadData();
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        values.push(item.id);
      }
    }
    this.filters[column] = values.length <= this.filterOptions[column].length ? values : [];
    this.pagination.page = 1;
    this.loadData();
  }

  reset(){
    this.filters = {
      models: [],
      products: [],
      packages: [],
      orders: [],
      sizes: [],
      types:[]
    };
    this.order = {
      field: 'models',
      direction: 'DESC'
    };
    this.pagination = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 1;
    this.loadFilters();
    this.loadData();
  }

  // /**
  //  * @description Eviar parametros y recibe un archivo excell
  //  */
  async fileExcell() {
    this.intermediaryService.presentLoading('Descargando Archivo Excel').then(()=>{
      const parameters: PackingInventoryModel.SearchParameters = {
        packingId:this.packingId,
        filters: this.filters,
        order: this.order,
        pagination: this.pagination
      };
      this.packingInventoryService.getFileExcell(parameters).pipe(
        catchError(error => of(error)),
        // map(file => file.error.text)
      ).subscribe((data) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        Filesave.saveAs(blob, `${Date.now()}.xlsx`);
        this.intermediaryService.dismissLoading();
        this.intermediaryService.presentToastSuccess('Archivo descargado')
      }, error => console.log(error));
    });
  }

}

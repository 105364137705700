import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {OplTransportsModel} from "@suite/services";

@Component({
  selector: 'suite-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {

  packages: OplTransportsModel.PackagesOrdersWithManifests[];

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss()
  }
}

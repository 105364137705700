import { Component, OnInit } from '@angular/core';
import { COLLECTIONS } from 'config/base';

@Component({
  selector: 'suite-workwaves-history',
  templateUrl: './workwaves-history.component.html',
  styleUrls: ['./workwaves-history.component.scss']
})
export class WorkwavesHistoryComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}

import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IncidenceCodeScanComponent} from "./incidence-code-scan.component";
import {ScannerManualModule} from "../../components/scanner-manual/scanner-manual.module";

@NgModule({
  declarations: [IncidenceCodeScanComponent],
  entryComponents: [IncidenceCodeScanComponent],
  imports: [
    CommonModule,
    IonicModule,
    ScannerManualModule
  ],
  exports: [IncidenceCodeScanComponent]
})
export class IncidenceCodeScanModule {}

import { Component, OnInit, ViewChild  } from '@angular/core';
import {Location} from "@angular/common";
import {PickingModel} from "../../../services/src/models/endpoints/Picking";
import {WorkwavesService} from "../../../services/src/lib/endpoint/workwaves/workwaves.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PickingService} from "../../../services/src/lib/endpoint/picking/picking.service";
import {AlertController, LoadingController } from "@ionic/angular";
import { IntermediaryService, UserTimeModel, UserTimeService } from '@suite/services';
import { TimesToastType } from '../../../services/src/models/timesToastType';
import {MatPaginator} from "@angular/material";
import FilterOptionsResponse = PickingModel.FilterOptionsResponse;
import Pagination = PickingModel.Pagination;
import Filters = PickingModel.Filters;
import FilterOptions = PickingModel.FilterOptions;
import Order = PickingModel.Order;
import Picking = PickingModel.Picking;
import SearchParameters = PickingModel.SearchParameters;
import SearchResponse = PickingModel.SearchResponse;
import {DateTimeParserService} from "../../../services/src/lib/date-time-parser/date-time-parser.service";
import {ListPickingRebuildModule} from "./list-picking-rebuild.module";
import {PaginatorComponent} from "../components/paginator/paginator.component";
import SearchResponseRebuild = PickingModel.SearchResponseRebuild;


@Component({
  selector: 'list-picking-rebuild',
  templateUrl: './list-picking-rebuild.component.html',
  styleUrls: ['./list-picking-rebuild.component.scss']
})
export class ListPickingRebuildComponent implements OnInit {

  public STATUS_PICKING_INITIATED: number = 2;

  private idWorkwave: number = null;

  //public listPickings: Array<PickingModel.PendingPickingsSelected> = new Array<PickingModel.PendingPickingsSelected>();
  public listPickings: Array<any> = new Array<any>();
  public isLoadingPickings: boolean = false;
  public previousPage: string = '';
  public usersNoSelectedToChangeUser: boolean = true;
  public usersNoSelectedToDelete: boolean = true;
  public disablePickingReset: boolean = true;
  private listIdsPickingsSelected: Array<number> = new Array<number>();
  private quantityPickingsSelectedAndInitiated: number = 0;
  private loading: HTMLIonLoadingElement = null;
  private listEmployeesToChange: UserTimeModel.ListUsersRegisterTimeActiveInactive = { usersActive: [], usersInactive: [] };

  public deleteOptionEnabled: boolean = true;

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  public displayedColumns: string[] = ['ids', 'dates', 'origins', 'destinies', 'users', 'status'];

  filters: Filters = {
    ids: [],
    dates: [],
    origins: [],
    destinies: [],
    users: [],
    status: [],
  };
  filterOptions: FilterOptions = {
    ids: [],
    dates: [],
    origins: [],
    destinies: [],
    users: [],
    status: [],
  };
  order: Order = {
    field: 'date',
    direction: 'DESC'
  };
  pagination: Pagination = {
    limit: undefined,
    page: 1
  };

  constructor(
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private intermediaryService: IntermediaryService,
    private workwavesService: WorkwavesService,
    private pickingService: PickingService,
    private userTimeService: UserTimeService
  ) {}

  ngOnInit() {
    this.idWorkwave = this.activatedRoute.snapshot.paramMap.get('id') ? parseInt(this.activatedRoute.snapshot.paramMap.get('id')) : null;

    if (this.idWorkwave && this.workwavesService.lastWorkwaveRebuildEdited) {
      this.previousPage = 'Olas de Trabajo';
    } else if (this.idWorkwave && this.workwavesService.lastWorkwaveHistoryQueried) {
      this.previousPage = 'Historial';
    } else {
      this.previousPage = null;
    }
    this.loadFilters();
    this.loadEmployees();
    this.paginator.page.subscribe(paginator => {
      this.pagination.limit = paginator.pageSize;
      this.pagination.page = paginator.pageIndex;
      this.loadPickingsList();
      this.intermediaryService.dismissLoading();
    });
  }

  ngAfterViewInit(){
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadPickingsList();
  }

  async resetPicking(){
    const alert = await this.alertController.create({
      backdropDismiss: false,
      message: 'Se finalizarán los pickings seleccionados con los productos escaneados y se liberarán las órdenes y productos para poder lanzarlos en un picking posterior.  ¿Continuar con su reseteo?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.intermediaryService.presentLoadingNew('Reseteando picking(s)...').then(()=>{
              this.pickingService.postResetPickings({pickingIds: this.listIdsPickingsSelected}).then(async response => {
                await this.intermediaryService.dismissLoadingNew();
                if(response.code == 200){
                  await this.intermediaryService.presentToastSuccess('Picking(s) reseteados con éxito.');
                  this.reset();
                }else{
                  console.error(response);
                  await this.intermediaryService.presentToastError('Ha ocurrido un error durante el reseteo de los pickings.', 'bottom');
                }
              }, async error => {
                await this.intermediaryService.dismissLoadingNew();
                console.error(error);
                await this.intermediaryService.presentToastError('Ha ocurrido un error durante el reseteo de los pickings.', 'bottom');
              }).catch(async error => {
                await this.intermediaryService.dismissLoadingNew();
                console.error(error);
                await this.intermediaryService.presentToastError('Ha ocurrido un error durante el reseteo de los pickings.', 'bottom');
              });
            });
          }
        }
      ]
    });
    await alert.present();
  }

  public loadPickingsList() {
    this.isLoadingPickings = true;

    let subscribeResponseListPickings = ((response: SearchResponseRebuild | any) => {
      if(response.code == 200) {
        if(response.data.result){
          this.listPickings = response.data.result;
          this.paginator.length = response.data.pagination.totalResults;
          this.paginator.lastPage = response.data.pagination.lastPage;
          this.paginator.pageIndex = response.data.pagination.selectPage;
        } else {
          this.listPickings = response.data;
          this.paginator.length = response.data.length;
          this.paginator.lastPage = 1;
          this.paginator.pageIndex = 1;
        }
        this.isLoadingPickings = false;
        this.listIdsPickingsSelected = [];
      }
    });
    let subscribeErrorListPickings = (error) => {
      if(error.status === 404){
        this.router.navigateByUrl('workwaves-scheduled');
        this.isLoadingPickings = false;
        return;
      }else{
        console.error('Error::Subscribe:pickingService::getListAllPendingPicking::', error);
        this.listPickings = new Array<PickingModel.PendingPickingsSelected>();
        this.isLoadingPickings = false;
      }
    };

    const parameters: SearchParameters = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };

    if (this.idWorkwave) {
      this.pickingService
        .getListPendingPickingByWorkwave(this.idWorkwave, parameters)
        .then(subscribeResponseListPickings, subscribeErrorListPickings);
    } else {
      this.pickingService
        .getListAllPendingPicking(parameters)
        .then(subscribeResponseListPickings, subscribeErrorListPickings);
    }
  }

  loadFilters(){
    if (this.idWorkwave) {
      this.pickingService.getFilterOptionsByWorkwave(this.idWorkwave).then((response: FilterOptionsResponse) => {
        if(response.code == 200){
          this.filterOptions = response.data;
        }else{
          console.error(response);
        }
      }).catch(console.error);
    }else{
      this.pickingService.getFilterOptions().then((response: FilterOptionsResponse) => {
        if(response.code == 200){
          this.filterOptions = response.data;
        }else{
          console.error(response);
        }
      }).catch(console.error);
    }
  }

  private loadEmployees() {
    this.userTimeService
      .getListUsersRegister()
      .subscribe((res: UserTimeModel.ListUsersRegisterTimeActiveInactive) => {
        this.listEmployeesToChange = res;
      }, (error) => {
        console.error('Error::Subscribe:userTimeService::getListUsersRegister::', error);
        this.listEmployeesToChange = { usersActive: [], usersInactive: [] };
      });
  }

  orderBy(column: string){
    if(this.order.field == column){
      this.order.direction == 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.loadPickingsList();
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        values.push(item.id);
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.loadPickingsList();
  }

  goPreviousPage() {
    this.location.back();
  }

  async changeUserSelected() {
    if (this.listEmployeesToChange.usersActive.length < 1 && this.listEmployeesToChange.usersInactive.length < 1) {
      this.intermediaryService.presentToastWarning('No hay usuarios para cambiar.');
      return;
    }

    let allUsers = [];
    allUsers = allUsers.concat(this.listEmployeesToChange.usersActive);
    allUsers = allUsers.concat(this.listEmployeesToChange.usersInactive.map(user => {user.start_time = null; return user;}));

    let inputs: any[] = allUsers.map(user => {
      return {
        name: user.id,
        type: 'radio',
        label: `${user.name} // ${user.start_time ? 'Activo' : 'Inactivo'}`,
        value: user.id
      }
    });

    let alert = await this.alertController.create({
      header: 'Nuevo usuario',
      backdropDismiss: false,
      cssClass: 'custom-alert-change-user',
      inputs: inputs,
      buttons: [
        'Cancelar',
        {
          text: 'Cambiar',
          handler: (data) => {
            if (typeof data === 'undefined' || !data) {
              return false;
            }
            this.showLoading('Cambiando usuarios...').then(() => {
              let listUsersPickings = this.listIdsPickingsSelected.map(id => {
                return { user: { id: data }, id: id };
              });
              this.changeUser(listUsersPickings);
            });
          }
        }
      ]
    });

    await alert.present();
  }

  private changeUser(listUsersPickings: any[]) {
    let subscribeResponseChangeEmployees = (res) => {
      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }
      this.intermediaryService.presentToastSuccess('Usuarios actualizados correctamente.', TimesToastType.DURATION_SUCCESS_TOAST_3750);
      this.listIdsPickingsSelected = new Array<number>();
      this.loadPickingsList();
    };
    let subscribeErrorChangeEmployees = (error) => {
      console.error('Error::Subscribe:pickingService::putUpdate::', error);
      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }
      this.intermediaryService.presentToastError('Ha ocurrido un error al intentar actualizar los usuarios de los picking.');
    };

    if (this.idWorkwave) {
      this.pickingService
        .putUpdate(this.idWorkwave, listUsersPickings)
        .subscribe(subscribeResponseChangeEmployees, subscribeErrorChangeEmployees);
    } else {
      this.pickingService
        .putUpdateByPickings(listUsersPickings)
        .subscribe(subscribeResponseChangeEmployees, subscribeErrorChangeEmployees);
    }
  }

  pickingSelected(data) {
    if (data.value) {
      this.listIdsPickingsSelected.push(data.id);

      this.usersNoSelectedToChangeUser = false;
      this.disablePickingReset = false;
      this.usersNoSelectedToDelete = this.listIdsPickingsSelected.length === 1 && !data.delete;
      if (!data.delete) {
        this.quantityPickingsSelectedAndInitiated++;
      }
    } else {
      this.listIdsPickingsSelected = this.listIdsPickingsSelected.filter((id) => id !== data.id);
      if (this.listIdsPickingsSelected.length < 1) {
        this.usersNoSelectedToChangeUser = true;
        this.disablePickingReset = true;
        this.usersNoSelectedToDelete = true;
      }
      if (!data.delete) {
        this.quantityPickingsSelectedAndInitiated--;
      }
    }
  }

  selectAllPickings() {
    if (this.listIdsPickingsSelected.length === this.listPickings.length) {
      this.usersNoSelectedToChangeUser = true;
      this.usersNoSelectedToDelete = true;
      this.disablePickingReset = true;
      this.quantityPickingsSelectedAndInitiated = 0;

      for (let picking of this.listPickings) {
        picking.selected = false;
      }

      this.listIdsPickingsSelected = new Array<number>();
    } else {
      this.usersNoSelectedToChangeUser = false;
      this.usersNoSelectedToDelete = false;
      this.disablePickingReset = false;
      this.quantityPickingsSelectedAndInitiated = 0;

      this.listIdsPickingsSelected = new Array<number>();
      for (let picking of this.listPickings) {
        picking.selected = true;
        this.listIdsPickingsSelected.push(picking.id);

        if (picking.status === this.STATUS_PICKING_INITIATED) {
          this.quantityPickingsSelectedAndInitiated++;
        }
      }
    }
  }

  deletePickingsSelected() {
    if (this.quantityPickingsSelectedAndInitiated > 0) {
      this.presentAlertBeforeDelete();
    } else {
      this.presentAlertConfirmDelete();
    }
  }

  private deletePickings() {
    this.workwavesService
      .postDeletePickings({ pickingsIds: this.listIdsPickingsSelected })
      .subscribe((res: {type:number,ids:number[]}) => {
        if(this.listIdsPickingsSelected.length === res.ids.length){
          let lista = 0;
          this.listIdsPickingsSelected.forEach(x =>{
            let p = res.ids.find(es => es === x);
            if(p){
              lista ++;
            }
          })
          if(lista === this.listPickings.length){
            this.router.navigateByUrl('workwaves-scheduled');
            if (this.loading) {
              this.loading.dismiss();
              this.loading = null;
            }
            return;
          }
        }

        if (this.loading) {
          this.loading.dismiss();
          this.loading = null;
        }
        this.intermediaryService.presentToastSuccess('Tareas de picking eliminadas correctamente.', TimesToastType.DURATION_SUCCESS_TOAST_3750);
        this.usersNoSelectedToDelete = true;



        this.loadPickingsList();
        this.loadEmployees();

      }, (error) => {
        console.error('Error::Subscribe:workwavesService::deleteDeletePickings::', error);
        if (this.loading) {
          this.loading.dismiss();
          this.loading = null;
        }
        this.intermediaryService.presentToastError('Ha ocurrido un error al intentar eliminar los picking seleccionados.');
      });
  }

  async presentAlertBeforeDelete() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      message: 'Ha seleccionado al menos un picking que ya ha sido iniciado y que no puede ser eliminado. Desmárquelo para poder continuar con la eliminación.',
      buttons: ['Cerrar']
    });

    await alert.present();
  }

  async presentAlertConfirmDelete() {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      message: 'Se eliminará los pickings seleccionados. ¿Continuar con su eliminación?',
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Aceptar',
          handler: () => {
            this.showLoading('Eliminando picking...').then(() => this.deletePickings());
          }
        }
      ]
    });

    await alert.present();
  }

  async showLoading(message: string) {
    this.loading = await this.loadingController.create({
      message: message,
      translucent: true,
    });
    return await this.loading.present();
  }

  reset(){
    this.filters = {
      ids: [],
      dates: [],
      origins: [],
      destinies: [],
      users: [],
      status: [],
    };
    this.order = {
      field: 'releaseDate',
      direction: 'DESC'
    };
    this.pagination = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 0;
    this.loadFilters();
    this.loadPickingsList();
  }

}

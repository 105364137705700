import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatTooltipModule } from '@angular/material';
import { InScansIncidencesShowJsonComponent } from "./in-scans-incidences-show-json.component";



@NgModule({
  declarations: [InScansIncidencesShowJsonComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    MatTooltipModule
  ],
  entryComponents: [
    InScansIncidencesShowJsonComponent
  ]
})
export class InScansIncidencesShowJsonModule {}

import {Component, OnInit, ViewChild} from '@angular/core';
import {COLLECTIONS} from 'config/base';
import {ModalController, AlertController, LoadingController} from '@ionic/angular';
import {CarrierModel} from 'libs/services/src/models/endpoints/carrier.model';
import {
  CarrierService,
  WarehouseModel,
  IntermediaryService,
  UsersService,
  FiltersService,
  TypesService, WarehousesService, ProductsService
} from '@suite/services';
import {MatTableDataSource} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {WarehouseService} from 'libs/services/src/lib/endpoint/warehouse/warehouse.service';
import {PrinterService} from 'libs/services/src/lib/printer/printer.service';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {UpdateComponent} from './update/update.component';
import {StoreComponent} from './store/store.component';
import {SendPackingComponent} from './send-packing/send-packing.component';
import {ShowDestinationsComponent} from './show-destionations/show-destinations.component';
import {AddDestinyComponent} from './add-destiny/add-destiny.component';
import {MultipleDestinationsComponent} from './multiple-destinations/multiple-destinations.component';
import {HistoryModalComponent} from './history-modal/history-modal.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {of} from 'rxjs';
import * as Filesave from 'file-saver';
import {TagsInputOption} from '../components/tags-input/models/tags-input-option.model';
import {PaginatorComponent} from '../components/paginator/paginator.component';
import {FilterButtonComponent} from "../components/filter-button/filter-button.component";
import {catchError} from 'rxjs/operators';
import {PermissionsService} from '../../../services/src/lib/endpoint/permissions/permissions.service';
import { DateTimeParserService } from '../../../services/src/lib/date-time-parser/date-time-parser.service';
import * as _ from 'lodash';
import { ListProductsModalComponent } from './list-products-modal/list-products-modal.component';
import {SelectableListComponent} from "./selectable-list/selectable-list.component";
import packingSealData = CarrierModel.packingSealData;

export interface CallToService {
  pagination: any,
  orderby: any,
  filters: any
}

@Component({
  selector: 'app-jail',
  templateUrl: './jail.component.html',
  styleUrls: ['./jail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class JailComponent implements OnInit {

  /**timeout for send request */
  requestTimeout;

  /**previous reference to detect changes */
  previousProductReferencePattern = '';
  pauseListenFormChange = false;
  permision: boolean;

  /**List of SearchInContainer */
  results: Array<CarrierModel.SearchInContainer> = [];

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @ViewChild('filterButtonReferences') filterButtonReferences: FilterButtonComponent;
  @ViewChild('filterButtonTypes') filterButtonTypes: FilterButtonComponent;
  @ViewChild('filterButtonProcessTypes') filterButtonProcessTypes: FilterButtonComponent;
  @ViewChild('filterButtonOrigins') filterButtonOrigins: FilterButtonComponent;
  @ViewChild('filterButtonDestinies') filterButtonDestinies: FilterButtonComponent;
  @ViewChild('filterButtonLastMovements') filterButtonLastMovements: FilterButtonComponent;

  form: FormGroup = this.formBuilder.group({
    references: [],
    types: [],
    processTypes: [],
    origins: [],
    destinies: [],
    lastMovements: [],
    packingReferencePattern: [],
    pagination: this.formBuilder.group({
      page: 1,
      limit: undefined
    }),
    orderby: this.formBuilder.group({
      type: null,
      order: "ASC"
    })
  });

  /**Filters */
  references: Array<TagsInputOption> = [];
  types: Array<TagsInputOption> = [];
  processTypes: Array<TagsInputOption> = [];
  origins: Array<TagsInputOption> = [];
  destinies: Array<TagsInputOption> = [];
  lastMovements: Array<TagsInputOption> = [];

  /** Filters save **/
  referencesSelected: Array<any> = [];
  typesSelected: Array<any> = [];
  processTypesSelected: Array<any> = [];
  originsSelected: Array<any> = [];
  packingReferencePatternSelected: Array<any> = [];
  orderbySelected: Array<any> = [];
  destiniesSelected: Array<any> = [];
  lastMovementsSelected: Array<any> = [];
  itemsIdSelected: Array<CarrierModel.SearchInContainer> = [];

  hasDeleteProduct = false;

  //For filter popovers
  isFilteringReferences: number = 0;
  isFilteringTypes: number = 0;
  isFilteringProcessTypes: number = 0;
  isFilteringOrigins: number = 0;
  isFilteringDestinies: number = 0;
  isFilteringLastMovements: number = 0;

  lastUsedFilter: string = '';
  isMobileApp: boolean = false;

  //For sorting
  lastOrder = [true, true, true, true, true];

  public title = 'Jaulas';
  public columns: any[] = [{name: 'ID', value: 'id'}, {name: 'Referencia', value: 'reference'}];
  public apiEndpoint = COLLECTIONS.find(collection => collection.name === 'Carriers').name;
  public routePath = '/jails';
  public jails: any[];

  displayedColumns = ['select', 'reference', 'packing', 'process', 'warehouse', 'destiny', 'products-status', 'sealed', 'isSend', "update", 'open-modal', 'buttons-print',];
  dataSource: any;
  listAllCarriers: any;
  expandedElement: CarrierModel.Carrier;

  carriers: packingSealData[] = [];
  warehouses: Array<WarehouseModel.Warehouse> = [];

  toDelete: FormGroup = this.formBuilder.group({
    jails: this.formBuilder.array([])
  });
  canEditPackaging: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private carrierService: CarrierService,
    private warehouseService: WarehouseService,
    private intermediaryService: IntermediaryService,
    private alertControler: AlertController,
    private printerService: PrinterService,
    private loadController: LoadingController,
    private historyModalComponent: HistoryModalComponent,
    private router: Router,
    private warehousesService: WarehousesService,
    private typeService: TypesService,
    private alertController: AlertController,
    private filterServices: FiltersService,
    private productsService: ProductsService,
    private modalController: ModalController,
    private usersService: UsersService,
    private permisionService: PermissionsService,
    private dateTimeParserService: DateTimeParserService
  ) {
    this.isMobileApp = typeof (<any>window).cordova !== "undefined";
  }

  ngOnInit() {
    this.getTypePacking();
    this.getTypeProcess();
    this.getWarehouses();
    this.usersService.hasDeleteProductPermission().then((observable) => {
      observable.subscribe((response) => {
        this.hasDeleteProduct = response.body.data;
      })
    });
    this.usersService.hasEditPackagingPermission().then((observable) => {
      observable.subscribe((response) => {
        this.canEditPackaging = response.body.data;
      })
    });
    this.getPermisionUser();
  }

  async ngAfterViewInit() {
    this.form = this.formBuilder.group({
      references: [],
      types: [],
      processTypes: [],
      origins: [],
      destinies: [],
      lastMovements: [],
      packingReferencePattern: [],
      pagination: this.formBuilder.group({
        page: 1,
        limit: this.paginator.finalPagerValues[0]
      }),
      orderby: this.formBuilder.group({
        type: null,
        order: "ASC"
      })
    });
    await this.getAllInfo();
  }

  async getAllInfo() {
    let body: CallToService = {
      pagination: {
        page: 1,
        limit: this.paginator.finalPagerValues[0]
      },
      orderby: null,
      filters: null
    };
    this.intermediaryService.presentLoading();
    await this.carrierService.getFilters().subscribe(sql_filters_result => {
      this.listAllCarriers = sql_filters_result.data.filters;
    });

    await this.carrierService.searchInContainer(body).subscribe(sql_result => {
      this.results = sql_result.data.results;
      this.initSelectForm();
      this.dataSource = new MatTableDataSource<CarrierModel.SearchInContainer>(this.results);
      let paginator: any = sql_result.data.pagination;
      this.paginator.length = paginator.totalResults;
      this.paginator.pageIndex = paginator.selectPage;
      this.paginator.lastPage = paginator.lastPage;
      this.getFilters(true);
      this.listenChanges();

      //Reduce all filters
      if (this.lastUsedFilter != 'references') {
        for (let index in this.references) {
          this.references[index].hide = false;
          this.references[index].checked = true;
        }
        this.filterButtonReferences.listItems = this.references;
      }
      if (this.lastUsedFilter != 'types') {
        for (let index in this.types) {
          this.types[index].hide = false;
          this.types[index].checked = true;
        }

        this.filterButtonTypes.listItems = this.types;
      }
      if (this.lastUsedFilter != 'processTypes') {
        for (let index in this.processTypes) {
          this.processTypes[index].hide = false;
          this.processTypes[index].checked = true;
        }

        this.filterButtonProcessTypes.listItems = this.processTypes;
      }
      if (this.lastUsedFilter != 'origins') {
        for (let index in this.origins) {
          this.origins[index].hide = false;
          this.origins[index].checked = true;
          this.origins[index].value = '' + this.origins[index].reference + ' - ' + this.origins[index].name + '';
        }
        this.filterButtonOrigins.listItems = this.origins;
      }
      if (this.lastUsedFilter != 'destinies') {
        for (let index in this.destinies) {
          this.destinies[index].hide = false;
          this.destinies[index].checked = true;
          this.destinies[index].value = '' + this.destinies[index].reference + ' - ' + this.destinies[index].name + '';
        }
        this.filterButtonDestinies.listItems = this.destinies;
      }
      if (this.lastUsedFilter != 'lastMovements') {

        for (let index in this.lastMovements) {
          this.lastMovements[index].hide = false;
          this.lastMovements[index].checked = true;
          this.lastMovements[index].value = this.getFormattedDate(this.lastMovements[index].name);
        }
        this.lastMovements =  _.uniqBy(this.lastMovements, 'value');
        if(this.filterButtonLastMovements) this.filterButtonLastMovements.listItems = this.lastMovements
      }
    });
    this.intermediaryService.dismissLoading();
  }

  sort(column: string) {
    for (let i = 0; i < document.getElementsByClassName('title').length; i++) {
      let iColumn = document.getElementsByClassName('title')[i] as HTMLElement;
      if (iColumn.innerHTML.includes('🡇') || iColumn.innerHTML.includes('🡅')) {
        iColumn.innerHTML = iColumn.innerHTML.slice(0, -2);
      }
    }

    switch (column) {
      case 'reference': {
        if (this.lastOrder[0]) {
          this.form.value.orderby = {order: "DESC", type: 1};
          JailComponent.showArrow(0, false);
        } else {
          this.form.value.orderby = {order: "ASC", type: 1};
          JailComponent.showArrow(0, true);
        }
        this.lastOrder[0] = !this.lastOrder[0];
        break;
      }
      case 'type': {
        if (this.lastOrder[1]) {
          this.form.value.orderby = {order: "DESC", type: 2};
          JailComponent.showArrow(1, false);
        } else {
          this.form.value.orderby = {order: "ASC", type: 2};
          JailComponent.showArrow(1, true);
        }
        this.lastOrder[1] = !this.lastOrder[1];
        break;
      }
      case 'processType': {
        if (this.lastOrder[2]) {
          this.form.value.orderby = {order: "DESC", type: 3};
          JailComponent.showArrow(2, false);
        } else {
          this.form.value.orderby = {order: "ASC", type: 3};
          JailComponent.showArrow(2, true);
        }
        this.lastOrder[2] = !this.lastOrder[2];
        break;
      }
      case 'origin': {
        if (this.lastOrder[3]) {
          this.form.value.orderby = {order: "DESC", type: 4};
          JailComponent.showArrow(3, false);
        } else {
          this.form.value.orderby = {order: "ASC", type: 4};
          JailComponent.showArrow(3, true);
        }
        this.lastOrder[3] = !this.lastOrder[3];
        break;
      }
      case 'destiny': {
        if (this.lastOrder[4]) {
          this.form.value.orderby = {order: "DESC", type: 5};
          JailComponent.showArrow(4, false);
        } else {
          this.form.value.orderby = {order: "ASC", type: 5};
          JailComponent.showArrow(4, true);
        }
        this.lastOrder[4] = !this.lastOrder[4];
        break;
      }
      case 'product': {
        if (this.lastOrder[5]) {
          this.form.value.orderby = {order: "DESC", type: 6};
          JailComponent.showArrow(5, false);
        } else {
          this.form.value.orderby = {order: "ASC", type: 6};
          JailComponent.showArrow(5, true);
        }
        this.lastOrder[5] = !this.lastOrder[5];
        break;
      }
      case 'lastMovements': {
        if (this.lastOrder[6]) {
          this.form.value.orderby = {order: "DESC", type: 7};
          JailComponent.showArrow(6, false);
        } else {
          this.form.value.orderby = {order: "ASC", type: 7};
          JailComponent.showArrow(6, true);
        }
        this.lastOrder[6] = !this.lastOrder[6];
        break;
      }
    }
    this.searchInContainer(this.sanitize(this.getFormValueCopy()));
  }

  static showArrow(colNumber, dirDown) {
    let htmlColumn = document.getElementsByClassName('title')[colNumber] as HTMLElement;
    if (dirDown) htmlColumn.innerHTML += ' 🡇';
    else htmlColumn.innerHTML += ' 🡅';
  }

  applyFilters(filtersResult, filterType) {
    const filters = filtersResult.filters;
    switch (filterType) {
      case 'references':
        let referencesFiltered: string[] = [];
        for (let reference of filters) {
          if (reference.checked) referencesFiltered.push(reference);
        }
        if (referencesFiltered.length >= this.references.length) {
          if (referencesFiltered.length > this.references.length) {
            this.form.value.references = this.references;
            this.isFilteringReferences = this.references.length;
          } else {
            this.form.value.references = referencesFiltered;
            this.isFilteringReferences = referencesFiltered.length;
          }
        } else {
          if (referencesFiltered.length > 0) {
            this.form.value.references = referencesFiltered;
            this.isFilteringReferences = referencesFiltered.length;
          } else {
            this.form.value.references = null;
            this.isFilteringReferences = 0;
          }
        }
        break;
      case 'types':
        let typesFiltered: string[] = [];
        for (let type of filters) {
          if (type.checked) typesFiltered.push(type);
        }
        if (typesFiltered.length >= this.types.length) {
          if (typesFiltered.length > this.types.length) {
            this.form.value.types = this.types;
            this.isFilteringTypes = this.types.length;
          } else {
            this.form.value.types = typesFiltered;
            this.isFilteringTypes = typesFiltered.length;
          }
        } else {
          if (typesFiltered.length > 0) {
            this.form.value.types = typesFiltered;
            this.isFilteringTypes = typesFiltered.length;
          } else {
            this.form.value.types = null;
            this.isFilteringTypes = 0;
          }
        }
        break;
      case 'processTypes':
        let processTypesFiltered: string[] = [];
        for (let processType of filters) {
          if (processType.checked) processTypesFiltered.push(processType);
        }
        if (processTypesFiltered.length >= this.processTypes.length) {
          if (processTypesFiltered.length > this.processTypes.length) {
            this.form.value.processTypes = this.processTypes;
            this.isFilteringProcessTypes = this.processTypes.length;
          } else {
            this.form.value.processTypes = processTypesFiltered;
            this.isFilteringProcessTypes = processTypesFiltered.length;
          }
        } else {
          if (processTypesFiltered.length > 0) {
            this.form.value.processTypes = processTypesFiltered;
            this.isFilteringProcessTypes = processTypesFiltered.length;
          } else {
            this.form.value.processTypes = null;
            this.isFilteringProcessTypes = 0;
          }
        }
        break;
      case 'origins':
        let originsFiltered: number[] = [];
        for (let origin of filters) {
          if (origin.checked) originsFiltered.push(origin);
        }
        if (originsFiltered.length >= this.origins.length) {
          if (originsFiltered.length > this.origins.length) {
            this.form.value.origins = this.origins;
            this.isFilteringOrigins = this.origins.length;
          } else {
            this.form.value.origins = originsFiltered;
            this.isFilteringOrigins = originsFiltered.length;
          }
        } else {
          if (originsFiltered.length > 0) {
            this.form.value.origins = originsFiltered;
            this.isFilteringOrigins = originsFiltered.length;
          } else {
            this.form.value.origins = null;
            this.isFilteringOrigins = 0;
          }
        }
        break;
      case 'destinies':
        let destiniesFiltered: number[] = [];
        for (let destiny of filters) {
          if (destiny.checked) destiniesFiltered.push(destiny);
        }
        if (destiniesFiltered.length >= this.destinies.length) {
          if (destiniesFiltered.length > this.destinies.length) {
            this.form.value.destinies = this.destinies;
            this.isFilteringDestinies = this.destinies.length;
          } else {
            this.form.value.destinies = destiniesFiltered;
            this.isFilteringDestinies = destiniesFiltered.length;
          }
        } else {
          if (destiniesFiltered.length > 0) {
            this.form.value.destinies = destiniesFiltered;
            this.isFilteringDestinies = destiniesFiltered.length;
          } else {
            this.form.value.destinies = null;
            this.isFilteringDestinies = 0;
          }
        }
        break;
      case 'lastMovements':
        let lastMovementsFiltered: number[] = [];
        for (let l of filters) {
          if (l.checked) lastMovementsFiltered.push(l);
        }
        if (lastMovementsFiltered.length >= this.lastMovements.length) {
          if (lastMovementsFiltered.length > this.lastMovements.length) {
            this.form.value.lastMovements = this.lastMovements;
            this.isFilteringLastMovements = this.lastMovements.length;
          } else {
            this.form.value.lastMovements = lastMovementsFiltered;
            this.isFilteringLastMovements = lastMovementsFiltered.length;
          }
        } else {
          if (lastMovementsFiltered.length > 0) {
            this.form.value.lastMovements = lastMovementsFiltered;
            this.isFilteringLastMovements = lastMovementsFiltered.length;
          } else {
            this.form.value.lastMovements = null;
            this.isFilteringLastMovements = 0;
          }
        }
        break;
    }
    this.lastUsedFilter = filterType;
    let flagApply = true;
    this.searchInContainer(this.sanitize(this.getFormValueCopy()), flagApply);
  }

  /**
   * clear empty values of objecto to sanitize it
   * @param object Object to sanitize
   * @return the sanitized object
   */
  sanitize(object) {
    /**mejorable */
    object = JSON.parse(JSON.stringify(object));
    if (!object.orderby.type) {
      delete object.orderby.type;
    } else {
      object.orderby.type = parseInt(object.orderby.type);
    }
    if (!object.orderby.order) delete object.orderby.order;
    Object.keys(object).forEach(key => {
      if (object[key] instanceof Array) {
        if (object[key][0] instanceof Array) {
          object[key] = object[key][0];
        } else {
          for (let i = 0; i < object[key].length; i++) {
            if (object[key][i] === null || object[key][i] === "") {
              object[key].splice(i, 1);
            }
          }
        }
      }
    });
    return object;
  }

  /**
   * Select or unselect all visible products
   * @param event to check the status
   */
  selectAll(event): void {
    let value = event.detail.checked;
    this.toDelete['controls'].jails['controls'].forEach(control => {
      control['controls'].selected.setValue(value);
    });

    if (value) {
      this.itemsIdSelected = this.results;
    } else {
      this.itemsIdSelected = [];
    }
  }

  /**
   * Listen changes in form to resend the request for search
   */
  listenChanges(): void {
    let previousPageSize = this.form.value.pagination.limit;
    /**detect changes in the paginator */
    this.paginator.page.subscribe(page => {
      this.saveFilters();
      /**true if only change the number of results */
      let flag = previousPageSize == page.pageSize;
      previousPageSize = page.pageSize;
      this.form.get("pagination").patchValue({
        limit: page.pageSize,
        page: flag ? page.pageIndex : 1
      });
      this.recoverFilters();
    });

    /**detect changes in the form */
    this.form.statusChanges.subscribe(change => {
      if (this.pauseListenFormChange) return;
      ///**format the reference */
      /**cant send a request in every keypress of reference, then cancel the previous request */
      clearTimeout(this.requestTimeout);
      /**it the change of the form is in reference launch new timeout with request in it */
      if (this.form.value.productReferencePattern != this.previousProductReferencePattern) {
        /**Just need check the vality if the change happens in the reference */
        if (this.form.valid)
          this.requestTimeout = setTimeout(() => {
            this.searchInContainer(this.sanitize(this.getFormValueCopy()));
          }, 1000);
      } else {
        /**reset the paginator to the 0 page */
        this.searchInContainer(this.sanitize(this.getFormValueCopy()));
      }
      /**assign the current reference to the previous reference */
      this.previousProductReferencePattern = this.form.value.productReferencePattern;
    });
  }

  private saveFilters() {
    this.referencesSelected = this.form.value.references;
    this.typesSelected = this.form.value.types;
    this.processTypesSelected = this.form.value.processTypes;
    this.originsSelected = this.form.value.origins;
    this.destiniesSelected = this.form.value.destinies;
    this.lastMovementsSelected = this.form.value.lastMovements;
    this.packingReferencePatternSelected = this.form.value.packingReferencePattern;
    this.orderbySelected = this.form.value.orderby;
  }

  private recoverFilters() {
    this.form.get("references").patchValue(this.referencesSelected, {emitEvent: false});
    this.form.get("types").patchValue(this.typesSelected, {emitEvent: false});
    this.form.get("processTypes").patchValue(this.processTypesSelected, {emitEvent: false});
    this.form.get("origins").patchValue(this.originsSelected, {emitEvent: false});
    this.form.get("destinies").patchValue(this.destiniesSelected, {emitEvent: false});
    this.form.get("lastMovements").patchValue(this.lastMovementsSelected, {emitEvent: false});
    this.form.get("packingReferencePattern").patchValue(this.packingReferencePatternSelected, {emitEvent: false});
    this.form.get("orderby").patchValue(this.orderbySelected, {emitEvent: false});
  }

  private getFormValueCopy() {
    return JSON.parse(JSON.stringify(this.form.value || {}));
  }

  /**
   * init selectForm controls
   */
  initSelectForm(): void {
    this.toDelete.removeControl("jails");
    this.toDelete.addControl("jails", this.formBuilder.array(this.results.map(carrier => {
      return this.formBuilder.group({
        id: carrier.id,
        reference: carrier.reference,
        selected: false
      });
    })));
  }

  /**
   * search carriers in container by criteria
   * @param parameters - parameters to search
   * @param applyFilter - parameters to search
   */
  searchInContainer(parameters, applyFilter: boolean = false): void {
    if (applyFilter) {
      parameters.pagination.page = 1;
    }

    let body: CallToService = {
      pagination: parameters.pagination,
      orderby: parameters.orderby,
      filters: {
        references: parameters.references,
        types: parameters.types,
        processTypes: parameters.processTypes,
        origins: parameters.origins,
        destinies: parameters.destinies,
        lastMovements: parameters.lastMovements
      }
    };

    if (this.isFilteringReferences == this.references.length) {
      body.filters.references = null;
    }
    if (this.isFilteringTypes == this.types.length) {
      body.filters.types = null;
    }
    if (this.isFilteringProcessTypes == this.processTypes.length) {
      body.filters.processTypes = null;
    }
    if (this.isFilteringOrigins == this.origins.length) {
      body.filters.origins = null;
    }
    if (this.isFilteringDestinies == this.destinies.length) {
      body.filters.destinies = null;
    }
    if (this.isFilteringLastMovements == this.lastMovements.length) {
      body.filters.lastMovements = null;
    }

    this.intermediaryService.presentLoading();
    this.carrierService.searchInContainer(body).subscribe(sql_result => {
      this.intermediaryService.dismissLoading();
      this.results = sql_result.data.results;
      this.dataSource = new MatTableDataSource<CarrierModel.SearchInContainer>(this.results);
      this.initSelectForm();
      let paginator: any = sql_result.data.pagination;

      this.paginator.length = paginator.totalResults;
      this.paginator.pageIndex = paginator.selectPage;
      this.paginator.lastPage = paginator.lastPage;

      this.toDelete.removeControl("jails");
      this.toDelete.addControl("jails", this.formBuilder.array(this.results.map(carrier => {
        return this.formBuilder.group({
          id: carrier.id,
          reference: carrier.reference,
          selected: false
        });
      })));

      if (applyFilter) {
        this.saveFilters();
        this.form.get("pagination").patchValue({
          limit: this.form.value.pagination.limit,
          page: 1
        }, {emitEvent: false});
        this.recoverFilters();
      }
    }, () => {
      this.intermediaryService.dismissLoading();
    });
  }

  /**
   * @description Eviar parametros y recibe un archivo excell
   */
  async fileExcell() {
    this.intermediaryService.presentLoading('Descargando Archivo Excel');
    const formToExcel = this.getFormValueCopy();
    if (formToExcel.pagination) {
      formToExcel.pagination.page = 1;
      formToExcel.pagination.limit = 0;
    }
    this.carrierService.getFileExcell(this.sanitize(formToExcel)).pipe(
      catchError(error => of(error)),
    ).subscribe((data) => {

      const blob = new Blob([data], {type: 'application/octet-stream'});
      Filesave.saveAs(blob, `${Date.now()}.xlsx`);
      this.intermediaryService.dismissLoading();
      this.intermediaryService.presentToastSuccess('Archivo descargado')
    }, error => console.log(error));
  }

  /**
   * get all filters to fill the selects
   */
  getFilters(stable: boolean = false) {
    this.updateFilterSourceReferences(this.listAllCarriers.references, stable);
    this.updateFilterSourceTypes(this.listAllCarriers.types, stable);
    this.updateFilterSourceProcessTypes(this.listAllCarriers.processTypes, stable);
    this.updateFilterSourceOrigins(this.listAllCarriers.origins, stable);
    this.updateFilterSourceDestinies(this.listAllCarriers.destinies, stable);
    this.updateFilterSourceLastMovements(this.listAllCarriers.lastMovements, stable)

    this.isFilteringReferences = this.references.length;
    this.isFilteringTypes = this.types.length;
    this.isFilteringProcessTypes = this.processTypes.length;
    this.isFilteringOrigins = this.origins.length;
    this.isFilteringDestinies = this.destinies.length;
    this.isFilteringLastMovements = this.lastMovements.length;
  }

  private getPermisionUser() {
    this.permisionService.getGestionPermision().then(obs => {
      obs.subscribe(permision => {
        this.permision = permision.body.data;
      })
    });
  }

  private updateFilterSourceReferences(references: any, stable: boolean) {
    this.pauseListenFormChange = true;
    let referencesList: any[] = [];
    references.forEach(key => {
      if (!referencesList.find(f => f.value == key['reference'])) {
        referencesList.push({value: key['reference']});
      }
    });
    if (stable == true) {
      this.references = referencesList;
      this.form.get("references").patchValue(this.references, {emitEvent: false});
    }

    setTimeout(() => {
      this.pauseListenFormChange = false;
    }, 0);
  }

  private updateFilterSourceTypes(types: any, stable: boolean) {
    this.pauseListenFormChange = true;
    let typesList: any[] = [];
    types.forEach(key => {
      if (!typesList.find(f => f.value == key['name'])) {
        typesList.push({value: key['name']});
      }
    });
    if (stable == true) {
      this.types = typesList;
      this.form.get("types").patchValue(this.types, {emitEvent: false});
    }

    setTimeout(() => {
      this.pauseListenFormChange = false;
    }, 0);
  }

  private updateFilterSourceProcessTypes(processTypes: any, stable: boolean) {
    this.pauseListenFormChange = true;
    let processTypesList: any[] = [];
    processTypes.forEach(key => {
      if(key['name']!= null) {
        if (!processTypesList.find(f => f.value == key['name'])) {
          processTypesList.push({value: key['name']});
        }
      }
    });
    if (stable == true) {
      this.processTypes = processTypesList;
      this.form.get("processTypes").patchValue(this.processTypes, {emitEvent: false});
    }

    setTimeout(() => {
      this.pauseListenFormChange = false;
    }, 0);
  }

  private updateFilterSourceOrigins(origins: any, stable: boolean) {
    this.pauseListenFormChange = true;
    let originsList: any[] = [];
    origins.forEach(key => {
      if (!originsList.find(f => f.reference == key['reference'])) {
        originsList.push({reference: key['reference'], name: key['name']});
      }
    });
    if (stable == true) {
      this.origins = originsList;
      this.form.get("origins").patchValue(this.origins, {emitEvent: false});
    }
    setTimeout(() => {
      this.pauseListenFormChange = false;
    }, 0);
  }

  private updateFilterSourceDestinies(destinies: any, stable: boolean) {
    this.pauseListenFormChange = true;
    let destiniesList: any[] = [];
    destinies.forEach(key => {
      if (!destiniesList.find(f => f.reference == key['reference'])) {
        destiniesList.push({reference: key['reference'], name: key['name']});
      }
    });
    if (stable == true) {
      this.destinies = destiniesList;
      this.form.get("destinies").patchValue(this.destinies, {emitEvent: false});
    }
    setTimeout(() => {
      this.pauseListenFormChange = false;
    }, 0);
  }

  private updateFilterSourceLastMovements(lastMovements: any, stable: boolean) {
    this.pauseListenFormChange = true;
    let lastMovementsList: any[] = [];
    lastMovements.forEach(key => {
      if (!lastMovementsList.find(f => f.reference == key['reference'])) {
        lastMovementsList.push({reference: key['reference'], name: key['name']});
      }
    });
    if (stable == true) {
      this.lastMovements = lastMovementsList;
      this.form.get("lastMovements").patchValue(this.lastMovements, {emitEvent: false});
    }
    setTimeout(() => {
      this.pauseListenFormChange = false;
    }, 0);
  }

  /**
   * Return a type
   * @param id - the id of type
   */
  typeById(id: number) {
    if (this.types) {
      return this.types.find(type => type.id == id);
    }
    return {name: ''};
  }
  processTypeListById(id: number) {
    if (this.processTypes) {
      return this.processTypes.find(processType => processType.id == id);
    }
    return {name: ''};
  }

  prevent(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Open modal to edit jail
   * @param event - to cancel it
   * @param jail - jail to be updated
   */
  async toUpdate(event, jail: CarrierModel.SearchInContainer) {
    event.stopPropagation();
    event.preventDefault();

    let modal = (await this.modalCtrl.create({
      component: UpdateComponent,
      componentProps: {
        jail: jail
      }
    }));

    modal.onDidDismiss().then(() => {
      this.getCarriers();
    });

    modal.present();
  }

  /**
   * Open modal to edit jail
   * @param event - to cancel it
   * @param jail - jail to be updated
   */
  async toSend(event, jail: CarrierModel.SearchInContainer) {
    event.stopPropagation();
    event.preventDefault();

    let modal = (await this.modalCtrl.create({
      component: SendPackingComponent,
      componentProps: {
        jail: jail
      }
    }));

    modal.onDidDismiss().then(() => {
      this.getCarriers();
    });

    modal.present();
  }

  /**
   * Open modal to store jail
   */
  async toStore() {
    let modal = (await this.modalCtrl.create({
      component: StoreComponent
    }));

    modal.onDidDismiss().then(() => {
      this.getCarriers();
    });

    modal.present();
  }

  getTypePacking() {
    this.intermediaryService.presentLoading();
    this.carrierService.getPackingTypes().subscribe(types => {
      this.types = types;
      this.intermediaryService.dismissLoading();
    })
  }

  getTypeProcess() {
    this.intermediaryService.presentLoading();
    this.carrierService.getPackingProcessTypes().subscribe(processTypes => {
      this.processTypes = processTypes;
      this.intermediaryService.dismissLoading();
    })
  }

  loadCarriers(): void {
    let parameters = this.sanitize(this.getFormValueCopy());
    if (!parameters.orderby.type) {
      parameters.orderby.type = null;
    }
    parameters.pagination.page = 1;
    let body: CallToService = {
      pagination: parameters.pagination,
      orderby: parameters.orderby,
      filters: {
        references: parameters.references ? parameters.references : [],
        types: parameters.types ? parameters.types : [],
        processTypes: parameters.processTypes,
        origins: parameters.origins ? parameters.origins : [],
        destinies: parameters.destinies ? parameters.destinies : [],
        lastMovements: parameters.lastMovements ? parameters.lastMovements : [],
      }
    };

    if (this.isFilteringReferences == this.references.length) {
      body.filters.references = null;
    }
    if (this.isFilteringTypes == this.types.length) {
      body.filters.types = null;
    }
    if (this.isFilteringProcessTypes == this.processTypes.length) {
      body.filters.processTypes = null;
    }
    if (this.isFilteringOrigins == this.origins.length) {
      body.filters.origins = null;
    }
    if (this.isFilteringDestinies == this.destinies.length) {
      body.filters.destinies = null;
    }
    if (this.isFilteringLastMovements == this.lastMovements.length) {
      body.filters.lastMovements = null;
    }

    this.intermediaryService.presentLoading("Actualizando...");
    this.carrierService.searchInContainer(body).subscribe(sql_result => {
      this.results = sql_result.data.results;
      this.dataSource = new MatTableDataSource<CarrierModel.SearchInContainer>(this.results);
      this.initSelectForm();
      let paginator: any = sql_result.data.pagination;

      this.paginator.length = paginator.totalResults;
      this.paginator.pageIndex = paginator.selectPage;
      this.paginator.lastPage = paginator.lastPage;

      this.toDelete.removeControl("jails");
      this.toDelete.addControl("jails", this.formBuilder.array(this.results.map(carrier => {
        return this.formBuilder.group({
          id: carrier.id,
          reference: carrier.reference,
          selected: false
        });
      })));
      this.intermediaryService.dismissLoading();
    });
  }

  async delete() {
    const carriersToEmpty: {id: number, reference: string, selected: boolean}[] = this.toDelete.value.jails.filter(jail => jail.selected);
    let message = 'Está a punto de ELIMINAR los siguientes embalajes, ¿Está seguro de que desea continuar?<ul>';
    for(let carrier of carriersToEmpty){
      message += '<li>'+carrier.reference+'</li>';
    }
    message += '</ul>';

    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        }, {
          text: 'Eliminar',
          handler: async () => {
            let observable = new Observable(observer => observer.next());
            this.toDelete.value.jails.forEach(jail => {
              if (jail.selected)
                observable = observable.pipe(switchMap(resonse => {
                  return this.carrierService.delete(jail.id)
                }))
            });
            this.intermediaryService.presentLoading();
            observable.subscribe(
              () => {
                this.intermediaryService.dismissLoading();
                this.intermediaryService.presentToastSuccess(
                  'Jaula eliminada con exito'
                );
                this.getCarriers();
              },
              () => {
                this.intermediaryService.dismissLoading();
                this.intermediaryService.presentToastError('Error eliminando jaula');
              }
            );
          }
        }
      ]
    });

    await alert.present();
  }

  getCarriers(): void {
    this.getAllInfo();
  }

  isAvailableSend(carrier: CarrierModel.SearchInContainer) {
    let isAvailable = parseInt(carrier.product) > 0 ? false : true;
    if (isAvailable) {
      return carrier.destiny.length == 0 || carrier.destiny.length == 1;
    }
    return false;
  }

  /**
   * check if have items to delete
   */
  hasToDelete(): boolean {
    return !!this.toDelete.value.jails.find(jail => jail.selected);
  }

  /**
   * copied function to show modal when user tap on print button
   * @param event
   * @param row
   */
  async print(event, row?: CarrierModel.SearchInContainer) {
    event.stopPropagation();
    let listReferences: Array<string> = null;
    if (row && row.reference) {
      listReferences = [row.reference];
    } else if (!row) {
      listReferences = this.toDelete.value.jails.filter(jail => jail.selected).map(jail => jail.reference);
    }

    if (listReferences && listReferences.length > 0) {
      this.printReferencesList(listReferences);
    }
  }

  async seal() {
    await this.intermediaryService.presentLoadingNew('Cargando...');
    const selectedPackingIds: number[] = this.toDelete.value.jails.filter(jail => jail.selected).map(x => x.id);
    this.carrierService.postGetPackingsByIds(selectedPackingIds).subscribe(async (carriers: packingSealData[]) => {
      this.carriers = carriers;
      await this.processLista();
      await this.intermediaryService.dismissLoadingNew();
    }, async error => {
      console.log(error);
      await this.intermediaryService.dismissLoadingNew();
    });
  }

  async processLista() {
    let listaCarrier: packingSealData[] = this.carriers;
    let listaSend: packingSealData[] = [];

    if (listaCarrier.length > 0) {
      listaSend = listaCarrier.filter(x => {
        if (x.packingInventorys.length > 0 && x.status !== 4 && x.carrierWarehousesDestiny.length === 1) {
          return x;
        }
      });
      if (listaSend.length > 0) {
        listaSend.forEach(y => {
          listaCarrier = listaCarrier.filter(x => x.id !== y.id)
        })
      }
      await this.presentAlert(listaCarrier, listaSend)
    }
  }

  async presentAlert(lista: packingSealData[], listaPresentada: packingSealData[]) {
    const listWithDestiny: {
      id: number,
      idWarehouse: number,
      idWarehouseName: string,
      reference: string,
      destiny: number,
      status: number,
      products: number
    }[] = [];
    const listWithNoDestiny: {
      id: number,
      idWarehouse: number,
      reference: string,
      destiny: number,
      products: number,
      status: number
    }[] = [];
    listaPresentada.forEach(item => {
      //Lista llenada toda
      listWithDestiny.push({
        id: item.id,
        idWarehouse: item.warehouse.id,
        idWarehouseName: item.carrierWarehousesDestiny[0].warehouse.name,
        reference: item.reference,
        destiny: item.carrierWarehousesDestiny.length,
        status: item.status,
        products: item.packingInventorys.length
      });
    });
    lista.forEach(item => {
      //Lista no llenada toda
      listWithNoDestiny.push({
        id: item.id,
        idWarehouse: item.warehouse.id,
        reference: item.reference,
        destiny: item.carrierWarehousesDestiny.length,
        products: item.packingInventorys.length,
        status: item.status
      });
    });

    await this.setMultipleDestinations(listWithDestiny, listWithNoDestiny);
  }

  private async printReferencesList(listReferences: Array<string>) {
    if ((<any>window).cordova) {
      this.printerService.print({text: listReferences, type: 0});
    } else {
      return await this.printerService.printBarcodesOnBrowser(listReferences);
    }
  }

  /**
   * Open modal to edit jail
   * @param event - to cancel it
   * @param jail - jail to be updated
   */
  async send(event, jail: CarrierModel.SearchInContainer) {
    event.stopPropagation();
    event.preventDefault();

    let modal = (await this.modalCtrl.create({
      component: AddDestinyComponent,
      componentProps: {
        jail: jail
      },
      cssClass: 'modalStyles'
    }));

    modal.onDidDismiss().then(() => {
      this.getCarriers();
    });

    modal.present();
  }

  async viewCarrier(event,element: CarrierModel.SearchInContainer) {
    event.stopPropagation();
    event.preventDefault();

    let modal = (await this.modalCtrl.create({
      component: HistoryModalComponent,
      componentProps: {packingReference: element.reference}
    }));

    modal.present()
  }

  async callToHistory() {
    this.router.navigate(['jails/history/']);
  }

  async showDestinations(event, jail: CarrierModel.SearchInContainer) {
    event.stopPropagation();
    event.preventDefault();

    let modal = (await this.modalCtrl.create({
      component: ShowDestinationsComponent,
      componentProps: {
        jail: jail
      }
    }));

    modal.onDidDismiss().then(async response => {
      if(response && response.data && response.data.clean){
        let message = 'Está a punto de quitar los destinos del embalaje '+jail.reference+', ¿Está seguro de que desea continuar?';

        const alert = await this.alertController.create({
          header: '¡Atención!',
          message: message,
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {}
            }, {
              text: 'Quitar',
              handler: async () => {
                this.carrierService.postRemoveCarrierDestinies(jail.id).subscribe(response => {
                  if(response.code != 200){
                    console.error(response);
                  }
                }, error => {
                  console.error(error);
                }, ()=>{
                  this.loadCarriers();
                });
              }
            }
          ]
        });

        await alert.present();
      }
    });

    modal.present();
  }

  getWarehouses() {
    this.intermediaryService.presentLoading();
    this.warehouseService.getIndex().then(observable => {
      observable.subscribe(response => {
        this.warehouses = (<any>response.body).data;
        this.intermediaryService.dismissLoading();
      });
    })
  }

  async setMultipleDestinations(listWithDestiny: {
    id: number,
    idWarehouse: number,
    idWarehouseName: string,
    reference: string,
    destiny: number,
    status: number,
    products: number
  }[], listWithNoDestiny: {
    id: number,
    idWarehouse: number,
    reference: string,
    destiny: number,
    products: number,
    status: number
  }[]) {
    event.stopPropagation();
    event.preventDefault();

    const componentProps: {
      listWithNoDestiny: {
          id: number,
          idWarehouse: number,
          reference: string,
          destiny: number,
          products: number,
          status: number
        }[],
      listWithDestiny: {
        id: number,
        idWarehouse: number,
        idWarehouseName: string,
        reference: string,
        destiny: number,
        status: number,
        products: number
      }[]
    } = {listWithNoDestiny, listWithDestiny};

    const modal = await this.modalCtrl.create({
      component: MultipleDestinationsComponent,
      componentProps: componentProps
    });

    modal.onDidDismiss().then( (response: {data?: any, role?: string, id?: string}) => {
      if (response && response.data) {
        this.getCarriers();
      }
    });

    await modal.present();
  }

  getFormattedDate(value: string): string {
    if (value && value != '') {
      return this.dateTimeParserService.dateMonthYear(value);
    } else {
      return '';
    }
  }

  /**
   * @description Eviar referencias de jaulas y recibe un archivo excell
   */
  async exportToExcel(event, row?: CarrierModel.SearchInContainer) {
    event.stopPropagation();
    let listReferences: Array<string> = null;
    if (row && row.reference) {
      listReferences = [row.reference];
    } else if (!row) {
      listReferences = this.toDelete.value.jails.filter(jail => jail.selected).map(jail => jail.reference);
    }

    if (listReferences && listReferences.length > 0) {
      this.intermediaryService.presentLoading('Descargando Archivo Excel');

      this.carrierService.getFileExcellCarrierByReferences({references:listReferences}).pipe(
        catchError(error => of(error)),
      ).subscribe((data) => {

        const blob = new Blob([data], { type: 'application/octet-stream' });
        Filesave.saveAs(blob, `${Date.now()}.xlsx`);
        this.intermediaryService.dismissLoading();
        this.intermediaryService.presentToastSuccess('Archivo descargado')
      }, error => console.log(error));

    }
  }

  async openProducts(event, jail: CarrierModel.SearchInContainer){
    console.log('OpenProducts:',jail);
    event.stopPropagation();
    event.preventDefault();

    let modal = (await this.modalCtrl.create({
      component: ListProductsModalComponent,
      componentProps: {
        jail: jail
      },
      cssClass: "modal-80"
    }));

    modal.onDidDismiss().then(() => {

    });

    modal.present();
  }

  async empty(){
    const carriersToEmpty: {id: number, reference: string, selected: boolean}[] = this.toDelete.value.jails.filter(jail => jail.selected);
    let message = 'Está a punto de vaciar los siguientes embalajes, ¿Está seguro de que desea continuar?<ul>';
    for(let carrier of carriersToEmpty){
      message += '<li>'+carrier.reference+'</li>';
    }
    message += '</ul>';

    const alert = await this.alertController.create({
      header: '¡Atención!',
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        }, {
          text: 'Vaciar',
          handler: async () => {
            await this.intermediaryService.presentLoadingNew('Vaciando embalajes');
            this.carrierService.postEmptyCarriers(carriersToEmpty.map(c => c.id), 'jail-list').subscribe(async response => {
              await this.intermediaryService.dismissLoadingNew();
              if(response.code != 200){
                await this.intermediaryService.presentToastError('Ha ocurrido un error al vaciar los embalajes')
                console.error(response);
              } else {
                await this.intermediaryService.presentToastSuccess('Emabalajes vaciados con éxito')
              }
            }, async error => {
              await this.intermediaryService.dismissLoadingNew();
              await this.intermediaryService.presentToastError('Ha ocurrido un error al vaciar los embalajes')
              console.error(error);
            }, async ()=>{
              await this.intermediaryService.dismissLoadingNew();
              this.loadCarriers();
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async openOriginSelect(event, carrierId: number, originId: number, carrierReference: string) {
    event.stopPropagation();
    event.preventDefault();

    const origins = this.warehouses.map(warehouse => { return {id: warehouse.id, value: `${warehouse.reference} - ${warehouse.name}`} });
    const currentOrigin = origins.filter(origin => origin.id == originId)[0];

    const modal = await this.modalController.create({
      component: SelectableListComponent,
      componentProps: { origins: origins, currentOrigin: currentOrigin, carrier: carrierReference }
    });

    modal.onDidDismiss().then(async result => {
      if (result && result.data != null) {
        const message = 'Está a punto de cambiar el almacén de origen del embalaje '+carrierReference+', ¿Está seguro de que desea continuar?';

        const alert = await this.alertController.create({
          header: '¡Atención!',
          message: message,
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {}
            }, {
              text: 'Cambiar',
              handler: async () => {
                const newOrigin = this.warehouses.find(v => v.id == result.data);
                this.carrierService.postUpdateCarrierOriginWarehouse(carrierId, newOrigin.id).subscribe(response => {
                  if (response.code != 200) {
                    console.error(response);
                  }
                }, error => {
                  console.error(error);
                }, () => {
                  this.loadCarriers();
                });
              }
            }
          ]
        });

        await alert.present();
      }
    });

    await modal.present();
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams} from "@ionic/angular";
import { InScansIncidence } from 'libs/services/src/models/endpoints/InScansIncience';

@Component({
  selector: 'suite-in-scan-incidences-show-json',
  templateUrl: './in-scans-incidences-show-json.component.html',
  styleUrls: ['./in-scans-incidences-show-json.component.scss']
})
export class InScansIncidencesShowJsonComponent implements OnInit {

  incidence: InScansIncidence;
  formatedError: string

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
  ) {
  }

  ngOnInit() {
    this.incidence = this.navParams.get("incidence");
    this.formatedError = JSON.stringify(JSON.parse(this.incidence.error), null, 4);
  }

  close() {
    this.modalController.dismiss();
  }

  downloadRequestJSON() {
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(this.incidence.error);
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", `${this.incidence.scannedBarcode}-${this.incidence.receiptDateTime}.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WarehouseService } from "../../../../services/src/lib/endpoint/warehouse/warehouse.service";
import {
  AuthenticationService,
  InventoryModel,
  InventoryService,
  WarehouseModel,
  IntermediaryService,
  GlobalVariableModel,
  GlobalVariableService
} from "@suite/services";
import { AlertController, ModalController } from "@ionic/angular";
import { ItemReferencesProvider } from "../../../../services/src/providers/item-references/item-references.provider";
import { environment as al_environment } from "../../../../../apps/al/src/environments/environment";
import { AudioProvider } from "../../../../services/src/providers/audio-provider/audio-provider.provider";
import { KeyboardService } from "../../../../services/src/lib/keyboard/keyboard.service";
import { TimesToastType } from '../../../../services/src/models/timesToastType';
import { PositionsToast } from '../../../../services/src/models/positionsToast.type';
import { CarrierService } from '../../../../services/src/lib/endpoint/carrier/carrier.service';
import { ListProductsCarrierComponent } from '../../components/list-products-carrier/list-products-carrier.component';
import { LoadingMessageComponent } from "../../components/loading-message/loading-message.component";
import {ToolbarProvider} from "../../../../services/src/providers/toolbar/toolbar.provider";
import { Router } from '@angular/router';
import {Events} from "@ionic/angular";
import {PositioningManualProvider} from "../../../../services/src/providers/positioning-manual/positioning-manual.provider";
import {LocalStorageProvider} from "../../../../services/src/providers/local-storage/local-storage.provider";
import {ReturnService} from "../../../../services/src/lib/endpoint/return/return.service";
import {ReturnModel} from "../../../../services/src/models/endpoints/Return";
import ReturnPackingsResponse = ReturnModel.ReturnPackingsResponse;
import EmptyPackingResponse = ReturnModel.EmptyPackingResponse;

@Component({
  selector: 'suite-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {

  @ViewChild(LoadingMessageComponent) loadingMessageComponent: LoadingMessageComponent;
  @ViewChild('inputToFocus') inputToFocus: ElementRef;

  dataToWrite: string = 'UBICACIÓN/EMBALAJE';
  containerReference: string = null;
  packingReference: string = null;
  inputPositioning: string = null;
  errorMessage: string = null;
  processInitiated: boolean;
  processActived: boolean;
  lastCodeScanned: string = 'start';
  numProducts: number = 0;
  totalProducts: number = 0;
  products: {
    reference: string;
    status: string;
    positionReference: string;
  }[] = [];
  colors = ['blanco', 'verde', 'rojo'];
  intervalCleanLastCodeScanned = null;
  timeLastCodeScanned: number = 0;
  container: any;
  alertList: any[] = [];
  alertActived: boolean = false;

  private countLoadOfVariables: number = 0;
  listVariables: Array<GlobalVariableModel.GlobalVariable> = new Array<GlobalVariableModel.GlobalVariable>();
  private listTypesFromDb: Array<{ id: number, name: string, workwave: boolean, type: string, tooltip: string }> = [];
  private listVariablesFromDb: Array<GlobalVariableModel.GlobalVariable> = new Array<GlobalVariableModel.GlobalVariable>();

  private isStoreUser: boolean = false;
  private storeUserObj: WarehouseModel.Warehouse = null;

  private timeoutStarted = null;
  private readonly timeMillisToResetScannedCode: number = 1000;
  private readonly SECONDS_OF_INACTIVITY_TO_REQUEST_NEW_LOCATION: number = 13;

  public isScannerBlocked: boolean = false;

  constructor(
    private alertController: AlertController,
    private warehouseService: WarehouseService,
    private inventoryService: InventoryService,
    private authenticationService: AuthenticationService,
    private intermediaryService: IntermediaryService,
    private itemReferencesProvider: ItemReferencesProvider,
    private audioProvider: AudioProvider,
    private keyboardService: KeyboardService,
    private carrierService: CarrierService,
    private modalCtrl: ModalController,
    private toolbarProvider: ToolbarProvider,
    private router: Router,
    private returnService: ReturnService,
    private globalVariableService: GlobalVariableService,
    public  events: Events,
    private positioningManualProvider: PositioningManualProvider,
    private localStorageProvider: LocalStorageProvider,
  ) {
    this.timeMillisToResetScannedCode = al_environment.time_millis_reset_scanned_code;
    this.focusToInput();
    this.events.subscribe('load_of_variables', () => {
      this.countLoadOfVariables++;
      if (this.countLoadOfVariables === 2) {
        this.generateVariablesList();
        this.countLoadOfVariables = 0;
      }
    });
  }

  async ngOnInit() {
    this.isStoreUser = await this.authenticationService.isStoreUser();
    if (this.isStoreUser) {
      this.storeUserObj = await this.authenticationService.getStoreCurrentUser();
    }

    this.processInitiated = false;

    if (this.isStoreUser) {
      this.dataToWrite = 'PRODUCTO';
    }
    this.getTypes();
    this.getGlobalVariables();
    this.localStorageProvider.get('processActived').then(processActived=>{
      let actived = JSON.parse(String(processActived));
      if(actived==true){
        let warehouseId = this.isStoreUser ? this.storeUserObj.id : this.warehouseService.idWarehouseMain;
        this.processActived = true;
        this.dataToWrite = 'PRODUCTO/UBICACIÓN/EMBALAJE';
        this.inputPositioning = null;
        this.errorMessage = null;
        this.positioningManualProvider.isActived.next(true);
        this.localStorageProvider.get('containerReference').then(containerReference=> {
          this.containerReference = String(containerReference)!='null' ? String(containerReference) : null;
        });
        this.localStorageProvider.get('packingReference').then(packingReference=> {
          this.packingReference = String(packingReference)!='null' ? String(packingReference) : null;
        });
        this.localStorageProvider.get('totalProducts').then(totalProducts=> {
          let dataParsed3 = JSON.parse(String(totalProducts));
          this.totalProducts = dataParsed3;
        });
        this.localStorageProvider.get('container').then(container=> {
          let conta: InventoryModel.Container;
          if (container) {
            const dataParsed = JSON.parse(String(container));
            if (dataParsed) {
              conta = dataParsed;
              this.container = conta;
            }
          }
        });
        this.localStorageProvider.get('products').then(products=> {
          let productsLocal: {reference: string, status: string; positionReference: string;}[];
          if (products) {
            const dataParsed = JSON.parse(String(products));
            if (dataParsed) {
              productsLocal = dataParsed;
              this.products = productsLocal;
              let pendingProducts = this.products.filter(p => p.status == this.colors[0] || p.status == this.colors[2]);
              if(pendingProducts.length>0) {
                pendingProducts.forEach(p => {
                  let params: any = {
                    productReference: p.reference,
                    warehouseId: warehouseId,
                    force: false
                  };

                  if (!this.isStoreUser && this.containerReference) {
                    params.containerReference = this.containerReference;
                  } else if (!this.isStoreUser && this.packingReference) {
                    params.packingReference = this.packingReference;
                  }
                  this.storeProductInContainer(params);
                });
              }
            }
          }
        });
      }
    });
  }

  private async modalList(jaula: string, products?: any[], returnPacking?: boolean) {
    let modal = await this.modalCtrl.create({
      component: ListProductsCarrierComponent,
      componentProps: {
        carrierReference: jaula,
        packingInventorys: products,
        returnPacking: returnPacking,
        process: 'positioning',
        isSorterProcess: false
      }
    });

    modal.onDidDismiss().then((data) => {
      if(data.data && data.data == 'emptyPacking'){
        this.intermediaryService.presentLoadingNew('Vaciando embalaje...');
        this.returnService.postEmptyPacking(jaula).subscribe(async (responseEmpty: EmptyPackingResponse) => {
          if (responseEmpty.code === 200) {
            this.carrierService.postPackingEmpty(jaula, 'positioning').then(async (res) => {
              if (res.code == 200) {
                this.audioProvider.playDefaultOk();
                await this.intermediaryService.dismissLoadingNew();
                await this.intermediaryService.presentToastSuccess(`El embalaje ${jaula} se ha vaciado correctamente.`);
                this.isScannerBlocked = false;
                this.focusToInput();
                this.inputPositioning = jaula;
                this.processInitiated = false;
                this.lastCodeScanned = 'start';
                await this.keyUpInput(KeyboardEvent['KeyCode'] = 13, true);
                return;
              } else {
                console.error(res);
                this.audioProvider.playDefaultError();
                await this.intermediaryService.dismissLoadingNew();
                await this.intermediaryService.presentToastError(res.errors);
              }
            }, async error => {
              console.error(error);
              this.audioProvider.playDefaultError();
              await this.intermediaryService.dismissLoadingNew();
              await this.intermediaryService.presentToastError(error.errors);
            }).catch(async (error) => {
              console.error(error);
              this.audioProvider.playDefaultError();
              await this.intermediaryService.dismissLoadingNew();
              await this.intermediaryService.presentToastError(error.errors);
            });
          } else {
            console.error(responseEmpty);
            this.audioProvider.playDefaultError();
            await this.intermediaryService.dismissLoadingNew();
            await this.intermediaryService.presentToastError(responseEmpty.errors);
          }
        }, async error => {
          console.error(error);
          this.audioProvider.playDefaultError();
          await this.intermediaryService.dismissLoadingNew();
          await this.intermediaryService.presentToastError(error.errors);
        });
        this.isScannerBlocked = false;
        this.focusToInput();
        return;
      }

      if (data.data === undefined && data.role === undefined) {
        this.isScannerBlocked = false;
        this.focusToInput();
        return;
      }

      if (data.data && data.role === undefined) {
        if (this.itemReferencesProvider.checkCodeValue(data.data) === this.itemReferencesProvider.codeValue.PACKING) {
          this.isScannerBlocked = false;
          this.focusToInput();
          this.inputPositioning = data.data;
          this.processInitiated = false;
          this.lastCodeScanned = 'start';
          this.keyUpInput(KeyboardEvent['KeyCode'] = 13, true);
          return;
        } else if (data.role === 'navigate') {
          this.isScannerBlocked = false;
          this.focusToInput();
        }
      }
    });

    modal.present();
  }

  private focusToInput(playSound: boolean = false, typeSound: 'ok' | 'error' = 'ok') {
    setTimeout(() => {
      this.inputToFocus.nativeElement.focus();
      if (playSound) {
        if (typeSound == 'ok') {
          this.audioProvider.playDefaultOk();
        } else {
          this.audioProvider.playDefaultError();
        }
      }
    }, 10);
  }

  async keyUpInput(event?, prova: boolean = false) {
    let warehouseId = this.isStoreUser ? this.storeUserObj.id : this.warehouseService.idWarehouseMain;
    let dataWrited = (this.inputPositioning || "").trim();

    if ((event.keyCode === 13 || prova && dataWrited && !this.processInitiated) && !this.isScannerBlocked) {
      this.isScannerBlocked = true;
      document.getElementById('input-ta').blur();

      if (dataWrited === this.lastCodeScanned) {
        this.inputPositioning = null;
        this.isScannerBlocked = false;
        this.focusToInput();
        return;
      }
      this.lastCodeScanned = dataWrited;

      if (this.timeoutStarted) {
        clearTimeout(this.timeoutStarted);
      }
      this.timeoutStarted = setTimeout(() => this.lastCodeScanned = 'start', this.timeMillisToResetScannedCode);

      this.processInitiated = true;
      if (!this.isStoreUser && (this.itemReferencesProvider.checkCodeValue(dataWrited) === this.itemReferencesProvider.codeValue.CONTAINER || this.itemReferencesProvider.checkCodeValue(dataWrited) === this.itemReferencesProvider.codeValue.CONTAINER_OLD)) {
        await this.loadingMessageComponent.show(true, `Cargando`);
        let globalVar = 60;
        let globalFound = this.listVariables.find( global => {
          return global.type === this.SECONDS_OF_INACTIVITY_TO_REQUEST_NEW_LOCATION;
        });
        if(globalFound && globalFound.value) {
          globalVar = parseInt(globalFound.value);
        }
        this.timeLastCodeScanned = new Date().getTime();
        this.intervalCleanLastCodeScanned = setInterval(() => {
          if (Math.abs((new Date().getTime() - this.timeLastCodeScanned) / 1000) > globalVar) {
            this.stopInterval();
          }
        }, 1000);
        this.carrierService.getContainerAndProducts(dataWrited, warehouseId).subscribe(async data => {
          this.container = data.container;
          this.containerReference = data.container.reference;
          this.localStorageProvider.set('container', JSON.stringify(this.container));
          this.numProducts = data.numProducts;
          this.totalProducts = this.numProducts;
          this.localStorageProvider.set("totalProducts",this.totalProducts);
          await this.loadingMessageComponent.show(false);
          await this.focusToInput();
          this.processInitiated = false;
          if(this.packingReference || this.containerReference){
            this.intermediaryService.presentToastSuccess(`Ubicación cambiada a ${dataWrited}`, TimesToastType.DURATION_SUCCESS_TOAST_2000, PositionsToast.BOTTOM).then(() => {
              this.isScannerBlocked = false;
              this.focusToInput(true, 'ok');
            });
          }else {
            this.intermediaryService.presentToastSuccess(`Inicio de ubicación en la posición ${dataWrited}`, TimesToastType.DURATION_SUCCESS_TOAST_2000, PositionsToast.BOTTOM).then(() => {
              this.isScannerBlocked = false;
              this.focusToInput(true, 'ok');
            });
          }
          this.packingReference = null;
          this.dataToWrite = 'PRODUCTO/UBICACIÓN/EMBALAJE';
          this.inputPositioning = null;
          this.errorMessage = null;
        }, async err => {
          await this.loadingMessageComponent.show(false);
          this.processInitiated = false;
          this.isScannerBlocked = false;
          this.inputPositioning = null;
          this.dataToWrite = 'UBICACIÓN/EMBALAJE';
          const errorMsg = err && err.error && err.error.errors ? err.error.errors : 'Error desconocido';
          this.intermediaryService.presentToastError(errorMsg, PositionsToast.BOTTOM, TimesToastType.DURATION_SUCCESS_TOAST_3750).then(async() => {
            await this.focusToInput(true, 'error');
          });
        });
      } else if (
        this.itemReferencesProvider.checkCodeValue(dataWrited) === this.itemReferencesProvider.codeValue.PRODUCT ||
        this.itemReferencesProvider.checkSpecificCodeValue(dataWrited, this.itemReferencesProvider.codeValue.PACKAGE)
      ) {
        this.positioningManualProvider.isActived.next(true);
        if(this.packingReference==null && this.containerReference == null){
          this.intermediaryService.presentToastError('Se debe escanear un embalaje.', PositionsToast.BOTTOM);
          this.inputPositioning = null;
          this.isScannerBlocked = false;
          this.focusToInput(true, 'error');
        }else {
          this.timeLastCodeScanned = new Date().getTime();
          this.localStorageProvider.set("containerReference",this.containerReference);
          this.localStorageProvider.set("packingReference",this.packingReference);
          let repeat = this.products.filter(p => p.reference == dataWrited);
          let product = {reference: dataWrited, status: this.colors[0], positionReference: this.containerReference || this.packingReference};
          this.products.unshift(product);
          this.localStorageProvider.set('products', JSON.stringify(this.products));
          if(repeat.length>0){
            this.totalProducts = this.numProducts;
          }else {
            this.numProducts += 1;
            this.totalProducts = this.numProducts;
          }
          this.localStorageProvider.set("totalProducts",this.totalProducts);
          this.processActived = true;
          this.positioningManualProvider.isActived.next(true);
          this.localStorageProvider.set('processActived', this.processActived);
          let params: any = {
              productReference: dataWrited,
              warehouseId: warehouseId,
              force: false
            };
          let pendingProducts = this.products.filter(p => p.status == this.colors[0] || p.status == this.colors[2]);
          if (pendingProducts.length > 0) {
            this.processActived = true;
            this.positioningManualProvider.isActived.next(true);
            this.localStorageProvider.set('processActived', this.processActived);
          } else {
            this.processActived = false;
            this.positioningManualProvider.isActived.next(false);
            this.localStorageProvider.set('processActived', this.processActived);
          }

          if (!this.isStoreUser && this.containerReference) {
            params.containerReference = this.containerReference;
          } else if (!this.isStoreUser && this.packingReference) {
            params.packingReference = this.packingReference;
          }
          this.storeProductInContainer(params);

          this.errorMessage = null;
        }
      } else if (!this.isStoreUser && this.itemReferencesProvider.checkCodeValue(dataWrited) === this.itemReferencesProvider.codeValue.PACKING) {
        await this.loadingMessageComponent.show(true, `Cargando`);
        let globalVar = 60;
        let globalFound = this.listVariables.find( global => {
          return global.type === this.SECONDS_OF_INACTIVITY_TO_REQUEST_NEW_LOCATION;
        });
        if(globalFound && globalFound.value) {
          globalVar = parseInt(globalFound.value);
        }
        this.timeLastCodeScanned = new Date().getTime();
        this.intervalCleanLastCodeScanned = setInterval(() => {
          if (Math.abs((new Date().getTime() - this.timeLastCodeScanned) / 1000) > globalVar) {
            this.stopInterval();
          }
        }, 1000);
        this.carrierService.getSingleAvailable(dataWrited).subscribe(async data => {
          if (data) {
            this.numProducts = data.packingInventorys.length;
            this.totalProducts = this.numProducts;
            this.localStorageProvider.set("totalProducts",this.totalProducts);
            this.returnService.getReturnsOfPacking(data.reference).subscribe(async (response: ReturnPackingsResponse) => {
              if (response.code == 200) {
                if (response.data && response.data.returnIds && response.data.returnIds.length > 0) {
                  this.isScannerBlocked = false;
                  await this.loadingMessageComponent.show(false);
                  await this.modalList(data.reference, response.data.returnProducts, true);
                } else {
                  if (data.packingInventorys.length > 0 && !prova) {
                    this.isScannerBlocked = false;
                    await this.loadingMessageComponent.show(false);
                    this.modalList(dataWrited);
                  } else {
                    this.processInitiated = false;
                    if(this.packingReference || this.containerReference){
                      this.intermediaryService.presentToastSuccess(`Ubicación cambiada a ${dataWrited}`, TimesToastType.DURATION_SUCCESS_TOAST_2000, PositionsToast.BOTTOM).then(async () => {
                        this.isScannerBlocked = false;
                        await this.loadingMessageComponent.show(false);
                        await this.focusToInput(true, 'ok');
                      });
                    }else {
                      this.intermediaryService.presentToastSuccess(`Inicio de ubicación en el embalaje ${dataWrited}`, TimesToastType.DURATION_SUCCESS_TOAST_2000, PositionsToast.BOTTOM).then(async () => {
                        this.isScannerBlocked = false;
                        await this.loadingMessageComponent.show(false);
                        await this.focusToInput(true, 'ok');
                      });
                    }
                    this.containerReference = null;
                    this.packingReference = dataWrited;
                    this.dataToWrite = 'PRODUCTO/UBICACIÓN/EMBALAJE';
                    this.inputPositioning = null;
                    this.errorMessage = null;
                  }
                }
              } else {
                console.error(response);
                this.inputPositioning = null;
                this.errorMessage = response.errors;
                this.processInitiated = false;
                this.isScannerBlocked = false;
                await this.loadingMessageComponent.show(false);
                this.focusToInput(true, 'error');
              }
            }, async error => {
              console.error(error);
              this.inputPositioning = null;
              this.errorMessage = error.errors;
              this.processInitiated = false;
              this.isScannerBlocked = false;
              await this.loadingMessageComponent.show(false);
              this.focusToInput(true, 'error');
            });
          } else {
            this.inputPositioning = null;
            this.errorMessage = '¡Referencia del contenedor/embalaje errónea!';
            this.processInitiated = false;
            this.isScannerBlocked = false;
            await this.loadingMessageComponent.show(false);
            this.focusToInput(true, 'error');
          }
        }, error => {
          if(error.status === 405) {
            this.inputPositioning = null;
            this.errorMessage = '¡EL embalaje está precintado y no se puede utilizar!';
            this.processInitiated = false;
            this.isScannerBlocked = false;
            this.loadingMessageComponent.show(false);
            this.focusToInput(true, 'error');
          }
        })
      } else if (!this.isStoreUser && !this.containerReference && !this.packingReference) {
        this.inputPositioning = null;
        this.errorMessage = '¡Referencia del contenedor/embalaje errónea!';
        this.processInitiated = false;
        this.isScannerBlocked = false;
        this.focusToInput(true, 'error');
      } else {
        this.inputPositioning = null;
        if (this.isStoreUser) {
          this.errorMessage = '¡Referencia del producto errónea!';
        } else {
          this.errorMessage = '¡Referencia del producto/contenedor/embalaje errónea!';
        }
        this.processInitiated = false;
        this.isScannerBlocked = false;
        this.focusToInput(true, 'error');
      }
    } else if (event.keyCode === 13 && this.isScannerBlocked) {
      this.inputPositioning = null;
      this.focusToInput();
    }
  }

  private reset(){
    this.lastCodeScanned = 'start';
    this.products = [];
    this.dataToWrite = 'UBICACIÓN/EMBALAJE';
    this.containerReference = null;
    this.packingReference = null;
    this.inputPositioning = null;
    this.errorMessage = null;
    this.processInitiated = false;
    this.processActived = false;
    this.positioningManualProvider.isActived.next(false);
    this.localStorageProvider.set('processActived', this.processActived);
    this.lastCodeScanned = 'start';
    this.numProducts = 0;
    this.totalProducts = 0;
    this.localStorageProvider.set("totalProducts",this.totalProducts);
    this.timeLastCodeScanned = 0;
    this.isScannerBlocked = false;
    this.focusToInput();
  }

  private stopInterval() {
    if (this.intervalCleanLastCodeScanned) {
      clearInterval(this.intervalCleanLastCodeScanned);
      this.intervalCleanLastCodeScanned = null;
      this.reset();
    }
  }

  private storeProductInContainer(params) {
    this.loadingMessageComponent.show(true, `Ubicando ${params.productReference || ''}`);
    this.inventoryService.postStoreObservable(params)
      .subscribe( async (res: InventoryModel.ResponseGlobal) => {
          this.products.forEach(p => {
            if (p.reference == params.productReference) {
              p.status = this.colors[1];
            }
          });
          this.localStorageProvider.set('products', JSON.stringify(this.products));
          let processed = this.products.filter(p => p.status==this.colors[1]);
          if(processed.length > 20){
            this.products.pop();
            this.localStorageProvider.set('products', JSON.stringify(this.products));
          }
          let pendingProducts = this.products.filter(p => p.status==this.colors[0]||p.status==this.colors[2]);
          if(pendingProducts.length > 0){
            this.processActived = true;
            this.positioningManualProvider.isActived.next(true);
            this.localStorageProvider.set('processActived', this.processActived);
          }else{
            this.processActived = false;
            this.positioningManualProvider.isActived.next(false);
            this.localStorageProvider.set('processActived', this.processActived);
          }
          this.isScannerBlocked = false
          this.inputPositioning = null
          this.processInitiated = false;
          this.loadingMessageComponent.show(false);
          this.focusToInput(true)
      }, async (error) => {
        if (error.error.code === 428) {
          let cont = 0;
          this.products.forEach(p => {
            if (p.reference == params.productReference) {
              p.status = this.colors[2];
              this.products.splice(cont, 1);
              this.products.unshift(p);
            }
            cont += 1;
          });
          this.localStorageProvider.set('products', JSON.stringify(this.products));
          this.loadingMessageComponent.show(false);
          this.audioProvider.playDefaultError();
          this.showWarningToForce(params);
        } else if (error.error.code === 401) {
          let cont = 0;
          this.products.forEach(p => {
            if (p.reference == params.productReference) {
              p.status = this.colors[2];
              this.products.splice(cont, 1);
              this.products.unshift(p);
            }
            cont += 1;
          });
          this.localStorageProvider.set('products', JSON.stringify(this.products));
          this.loadingMessageComponent.show(false);
          if (error.error.message === 'UserConfirmationRequiredException') {
            this.warningToForce(params, error.error.errors, false, 'Continuar');
          } else if (error.error.message === 'UserConfirmationRequiredToForceException') {
            this.warningToForce(params, error.error.errors);
          } else {
            /** Comprobando si tienes permisos para el forzado */
            const permission = await this.inventoryService.checkUserPermissions();
            /** Forzado de empaquetado */
            if (permission.data) {
              this.warningToForce(params, error.error.errors);
            } else {
              this.presentAlert(error.error.errors);
              this.processInitiated = false;
            }
          }
        } else {
          this.products.forEach((p, i) => {
            if (p.reference == params.productReference) {
              this.products.splice(i, 1);
            }
          });
          let errorMessage = error.error.message;
          if (error.error.errors) {
            if (typeof error.error.errors === 'string') {
              errorMessage = error.error.errors;
            } else {
              if (error.error.errors.productReference && error.error.errors.productReference.message) {
                errorMessage = error.error.errors.productReference.message;
              }
            }
          }
          this.intermediaryService.presentToastError(errorMessage, PositionsToast.BOTTOM).then(() => {
            this.isScannerBlocked = false;
            this.inputPositioning = null
            this.loadingMessageComponent.show(false);
            this.focusToInput(true, 'error');
          });
          this.processInitiated = false;

          this.localStorageProvider.set('products', JSON.stringify(this.products));
          let processed = this.products.filter(p => p.status==this.colors[1]);
          if(processed.length > 20){
            this.products.pop();
            this.localStorageProvider.set('products', JSON.stringify(this.products));
          }
          let pendingProducts = this.products.filter(p => p.status==this.colors[0]||p.status==this.colors[2]);
          if(pendingProducts.length > 0){
            this.processActived = true;
            this.positioningManualProvider.isActived.next(true);
            this.localStorageProvider.set('processActived', this.processActived);
          }else{
            this.processActived = false;
            this.positioningManualProvider.isActived.next(false);
            this.localStorageProvider.set('processActived', this.processActived);
          }
          this.processInitiated = false;

        }
      });
  }

  private async warningToForce(params, subHeader, checkPermissionToForce: boolean = true, btnOkMessage: string = 'Forzar') {
    const alertWarning = await this.alertController.create({
      header: 'Atención',
      subHeader,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            this.alertActived=false;
            this.intermediaryService.presentToastError('El producto no se ha ubicado.', PositionsToast.BOTTOM).then(() => {
              this.products.shift();
              this.localStorageProvider.set('products', JSON.stringify(this.products));
              this.numProducts = this.numProducts -1;
              this.totalProducts = this.numProducts;
              this.localStorageProvider.set("totalProducts",this.totalProducts);
              let pendingProducts = this.products.filter(p => p.status==this.colors[0]||p.status==this.colors[2]);
              if(pendingProducts.length > 0){
                this.processActived = true;
                this.positioningManualProvider.isActived.next(true);
                this.localStorageProvider.set('processActived', this.processActived);
              }else{
                this.processActived = false;
                this.positioningManualProvider.isActived.next(false);
                this.localStorageProvider.set('processActived', this.processActived);
              }
              this.isScannerBlocked = false;
              this.timeLastCodeScanned = new Date().getTime();
              this.inputPositioning = null
              this.focusToInput(true, 'error');
            });

            this.loadingMessageComponent.show(false);
            this.processInitiated = false;
          }
        },
        {
          text: btnOkMessage,
          handler: async () => {
            this.alertActived=false;
            if (checkPermissionToForce) {
              // Consultando si el usuario tiene permisos para forzar
              const permissions = await this.inventoryService.checkUserPermissions();
              if (permissions.data) {
                this.products.forEach(p => {
                  if (p.reference == params.productReference) {
                    p.status = this.colors[0];
                  }
                });
                this.localStorageProvider.set('products', JSON.stringify(this.products));
                params.force = true;
                params.avoidAvelonMovement = false;
                this.timeLastCodeScanned = new Date().getTime();
                this.storeProductInContainer(params);
                this.processInitiated = false;
              } else {
                this.alertController.dismiss();
                this.presentAlert('Su usuario no tiene los permisos suficientes para realizar este forzado de ubicación.');
                this.processInitiated = false;
              }
            } else {
              this.products.forEach(p => {
                if (p.reference == params.productReference) {
                  p.status = this.colors[0];
                }
              });
              this.localStorageProvider.set('products', JSON.stringify(this.products));
              params.force = true;
              this.timeLastCodeScanned = new Date().getTime();
              this.storeProductInContainer(params);
              this.processInitiated = false;
            }
          }
        }]
    });
    this.alertList.push(params.productReference);
    if(this.alertActived==false){
      this.alertActived=true;
      this.alertList.shift();
      return await alertWarning.present();
    }else{
      let warningInterval = setInterval(async () => {
        if (this.alertActived==false && this.alertList[0]==params.productReference) {
          clearInterval(warningInterval);
          warningInterval = null;
          this.alertList.shift();
          if(this.alertList.length>0){
            this.alertActived=true;
          }
          return await alertWarning.present();
        }
      }, 500);
    }
  }

  async presentAlert(subHeader) {
    const alert = await this.alertController.create({
      header: 'Atención',
      subHeader,
      buttons: ['OK']
    });

    alert.onDidDismiss().then(() => {
      this.processInitiated = false;
      this.loadingMessageComponent.show(false);
      this.isScannerBlocked = false;
      this.focusToInput(true, 'error');
    });

    await alert.present();
  }

  private async showWarningToForce(params) {
    const alertWarning = await this.alertController.create({
      header: 'Atención',
      subHeader: 'No se esperaba la entrada del producto que acaba de escanear. ¿Desea forzar la entrada del producto igualmente?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            this.products.shift();
            this.localStorageProvider.set('products', JSON.stringify(this.products));
            this.numProducts = this.numProducts -1;
            this.totalProducts = this.numProducts;
            this.localStorageProvider.set("totalProducts",this.totalProducts);
            let pendingProducts = this.products.filter(p => p.status==this.colors[0]||p.status==this.colors[2]);
            if(pendingProducts.length > 0){
              this.processActived = true;
              this.positioningManualProvider.isActived.next(true);
              this.localStorageProvider.set('processActived', this.processActived);
            }else{
              this.processActived = false;
              this.positioningManualProvider.isActived.next(false);
              this.localStorageProvider.set('processActived', this.processActived);
            }
            this.processInitiated = false;
            this.loadingMessageComponent.show(false);
            this.timeLastCodeScanned = new Date().getTime();
          }
        },
        {
          text: 'Forzar',
          handler: () => {
            this.products.forEach(p => {
              if (p.reference == params.productReference) {
                p.status = this.colors[0];
              }
            });
            this.localStorageProvider.set('products', JSON.stringify(this.products));
            params.force = true;
            this.timeLastCodeScanned = new Date().getTime();
            this.storeProductInContainer(params);
            this.processInitiated = false;
          }
        }]
    });

    return await alertWarning.present();
  }

  public onFocus(event) {
    if (event && event.target && event.target.id) {
      this.keyboardService.setInputFocused(event.target.id);
    }
  }

  public undo() {
    if(this.products.length > 0) {
      this.loadingMessageComponent.show(true, `Deshaciendo última lactura`);
      this.inventoryService.undo_inventoryProcess().subscribe(async result => {
        if (result) {
          this.timeLastCodeScanned = new Date().getTime();
          this.products.shift();
          this.localStorageProvider.set('products', JSON.stringify(this.products));
          this.numProducts = this.numProducts -1;
          this.totalProducts = this.numProducts;
          this.localStorageProvider.set("totalProducts",this.totalProducts);
          await this.loadingMessageComponent.show(false);
        }
      }, async error => {
        await this.loadingMessageComponent.show(false);
        this.intermediaryService.presentToastError('Ha ocurrido un error el cargar los datos del sevidor').then(() => {
          this.focusToInput(true, 'error');
        });
      });
    }else {
      this.intermediaryService.presentToastError('No hay ninguna lectura que deshacer.', PositionsToast.BOTTOM).then(() => {
        this.focusToInput(true, 'error');
      });
    }
  }

  toLocate(){
    this.reset();
    this.router.navigateByUrl('/locate');
    this.toolbarProvider.currentPage.next("Localizar");
  }

  toProducts(){
    this.reset();
    this.router.navigate(['/products']);
    this.toolbarProvider.currentPage.next("Productos");
  }


  getGlobalVariables() {
    this.globalVariableService.getAll().subscribe((globalVariables) => {
      this.listVariablesFromDb = globalVariables;
      this.events.publish('load_of_variables');
    });
  }

  generateVariablesList() {
    if (this.listVariablesFromDb.length !== this.listTypesFromDb.length) {
      this.listVariables = this.listVariablesFromDb;
      for (let iType in this.listTypesFromDb) {
        let type = this.listTypesFromDb[iType];
        let isTypeCreated: boolean = false;
        for (let iVariable in this.listVariablesFromDb) {
          let variable = this.listVariablesFromDb[iVariable];
          if (variable.type === type.id) {
            isTypeCreated = true;
            break;
          }
        }
        if (!isTypeCreated) {
          this.listVariables.push({ type: type.id, value: null, tooltip: null });
        }
      }
    } else {
      this.listVariables = this.listVariablesFromDb;
    }
    this.listVariables.sort((a, b) => {
      return a.type < b.type ? -1 : 1;
    })
  }

  getTypes() {
    this.globalVariableService.getTypes().subscribe((types) => {
        this.listTypesFromDb = types;
        this.events.publish('load_of_variables');
      });
  }

  oldReference(inventory: any) {
    let alphabet = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
    return 'P' + inventory.rack.hall.toString().padStart(2, '0')
      + alphabet[inventory.row - 1]
      + inventory.column.toString().padStart(2, '0');
  }

}

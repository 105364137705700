import {Component, Input, OnInit} from '@angular/core';
import {DateTimeParserService} from "../../../../services/src/lib/date-time-parser/date-time-parser.service";
import {PackagesComponent} from "../packages/packages.component";
import {ModalController} from '@ionic/angular';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'order-to-pick-up',
  templateUrl: './list-items.component.html',
  styleUrls: ['./list-items.component.scss']
})
export class OrderToPickUpComponent implements OnInit {

  @Input() element: any;
  @Input() selectedFormControl: FormControl;

  constructor(
    private dateTimeParserService: DateTimeParserService,
    private modalController: ModalController
  ) {}

  ngOnInit() {}

  public preparationDateParsed(element) : string {
    return this.dateTimeParserService.dateTimeSort(element.trackingPackage.createdAt);
  }

  public async presentModal(packages) {
    const modal = await this.modalController.create({
      component: PackagesComponent,
      componentProps: { packages }
    });

    await modal.present();
  }

  public selectItem() {
    this.selectedFormControl.setValue(!this.selectedFormControl.value);
  }
}

import {Component, OnInit} from '@angular/core';
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {DropFilesService} from '../../../services/src/lib/endpoint/drop-files/drop-files.service';
import {ModalController} from '@ionic/angular';
import {IntermediaryService} from '../../../services/src/';
import {TimesToastType} from "../../../services/src/models/timesToastType";
import {PositionsToast} from "../../../services/src/models/positionsToast.type";
import {environment, app, UploadFilesService} from "@suite/services";
import {ModalReviewComponent} from "../components/modal-defective/ModalReview/modal-review.component";
declare var require: any
const FileSaver = require('file-saver');


@Component({
  selector: 'suite-drop-files',
  templateUrl: './drop-files.component.html',
  styleUrls: ['./drop-files.component.scss']
})

export class DropFilesComponent implements OnInit{

  private baseUrlPhoto = environment.apiBasePhoto;
  type: string;
  sectionOrigin: string;
  images: any[];
  public files: NgxFileDropEntry[] = [];
  public showSaveButton: boolean = false;
  title: string;
  private isSGA;

  constructor(
    private dropFileSrv: DropFilesService,
    private modalController: ModalController,
    private intermediary: IntermediaryService,
    private uploadService: UploadFilesService,
    ) {
  }

  ngOnInit(): void {
    this.loadTittle();
    this.isSGA = (app.name == 'sga');
  }

  public async dropped(files: NgxFileDropEntry[]) {
    await this.intermediary.presentLoadingNew('Guardando archivo...');

    this.files = files;
    for (const droppedFile of files) {
      
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          
          var fileNameReplaced = droppedFile.relativePath.replace(/[ &\/\\#,+()$~%'":*?<>{}]/g, '_');
          const renamedFile = new File([file], droppedFile.fileEntry.name);    
          // You could upload it like this:
          const formData = new FormData();
          formData.append('file', renamedFile, fileNameReplaced);

          if (this.type == 'archive') {
            this.dropFileSrv.uploadReturnArchive(formData).subscribe(
              resp => {
                this.dropFileSrv.setImage(resp.data);
                this.intermediary.presentToastSuccess('Archivo subido y guardado correctamente en el repositorio.', TimesToastType.DURATION_SUCCESS_TOAST_3750);
                if(this.sectionOrigin == 'defects'){
                  this.close();
                }
              },
              async err => {
                const errorMessage = err && err.error && err.error.error ? err.error.error : 'Ha ocurrido un error al intentar subir el archivo al repositorio';
                await this.intermediary.presentToastError(errorMessage, PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST);
                await this.intermediary.dismissLoadingNew();
              }, async () => {
                await this.intermediary.dismissLoadingNew();
              });
          } else {
            if (this.type == 'delivery_note') {
              this.dropFileSrv.uploadReturnDeliveryNote(formData).subscribe(
                resp => {
                  this.dropFileSrv.setImage(resp.data);
                  this.intermediary.presentToastSuccess('Archivo subido y guardado correctamente en el repositorio.', TimesToastType.DURATION_SUCCESS_TOAST_3750);
                  if(this.sectionOrigin == 'defects'){
                    this.close();
                  }
                },
                async err => {
                  const errorMessage = err && err.error && err.error.error ? err.error.error : 'Ha ocurrido un error al intentar subir el archivo al repositorio';
                  await this.intermediary.presentToastError(errorMessage, PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST);
                  await this.intermediary.dismissLoadingNew();
                }, async () => await this.intermediary.dismissLoadingNew());
            } else {
              this.dropFileSrv.uploadFile(formData).subscribe(
                resp => {
                  this.dropFileSrv.setImage(resp.data);
                  this.intermediary.presentToastSuccess('Archivo subido y guardado correctamente en el repositorio.', TimesToastType.DURATION_SUCCESS_TOAST_3750);
                  if(this.sectionOrigin == 'defects'){
                    this.close();
                  }
                },
                async err => {
                  const errorMessage = err && err.error && err.error.error ? err.error.error : 'Ha ocurrido un error al intentar subir el archivo al repositorio';
                  await this.intermediary.presentToastError(errorMessage, PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST);
                  await this.intermediary.dismissLoadingNew()
                }, async () => await this.intermediary.dismissLoadingNew());
            }
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  deleteImage(item, index, arr) {
    this.intermediary.presentLoading()
    this.uploadService.deleteFile(item.id).subscribe(
      resp => {
        this.intermediary.presentToastSuccess('Archivo borrado exitosamente')
        arr.splice(index, 1);
        //this.signature = false;
      },
      err => {
        this.intermediary.presentToastError('Ocurrio un error al borrar el archivo')
        this.intermediary.dismissLoading()
      },
      () => {
        this.intermediary.dismissLoading()
      }
    )
  }

  async openReviewImage(item) {
    const modal = await this.modalController.create({
      component: ModalReviewComponent,
      componentProps: {
        data: item
      }
    });
    await modal.present();
  }

  public fileOver(event){
    console.log(event);
  }

  public fileLeave(event){
    console.log(event);
  }

  public close(){
    this.modalController.dismiss();
  }

  public saveItems() {
    this.modalController.dismiss({save: true});
  }

  loadTittle() {
    switch (this.type) {
      case 'archive':
        this.title = 'Subir Archivos';
        break;
      case 'delivery_note':
        this.title = 'Subir Albaranes';
        break;
      case 'photo':
      default:
        this.title =  'Subir Fotos';
    }
  }

  downloadFile(file) {
    const urlDownload = environment.downloadPdf + file.pathOriginal;
    const fileName = this.getFileName(file.fileName);
    FileSaver.saveAs(urlDownload, fileName);
  }

  isImage(extension) {
    return (extension == '.png' || extension == '.jpg' || extension == '.jpeg');
  }

  public getFileName(fileName: string) {
    const fileNameFound = fileName.match(/^file-\d*-(.*)/);
    if (fileNameFound && fileNameFound.length > 1) {
      return fileNameFound[1];
    }

    return fileName;
  }

}

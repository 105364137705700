import {Component, OnInit, ViewChild} from '@angular/core';
import {ReturnService} from "../../../services/src/lib/endpoint/return/return.service";
import {ReturnModel} from "../../../services/src/models/endpoints/Return";
import Return = ReturnModel.Return;
import SaveResponse = ReturnModel.SaveResponse;
import {ActivatedRoute, Router} from "@angular/router";
import LoadResponse = ReturnModel.LoadResponse;
import {BrandModel} from "../../../services/src/models/endpoints/Brand";
import Brand = BrandModel.Brand;
import {
  AuthenticationService, environment,
  IntermediaryService,
  UploadFilesService,
  UserModel,
  WarehouseModel
} from "@suite/services";
import Warehouse = WarehouseModel.Warehouse;
import OptionsResponse = ReturnModel.OptionsResponse;
import {ReturnTypeModel} from "../../../services/src/models/endpoints/ReturnType";
import ReturnType = ReturnTypeModel.ReturnType;
import {ProviderModel} from "../../../services/src/models/endpoints/Provider";
import Provider = ProviderModel.Provider;
import User = UserModel.User;
import {AlertController, Events, ModalController} from "@ionic/angular";
import {SelectConditionComponent} from "./select-condition/select-condition.component";
import {SupplierConditionModel} from "../../../services/src/models/endpoints/SupplierCondition";
import SupplierCondition = SupplierConditionModel.SupplierCondition;
import {SelectableListComponent} from "./modals/selectable-list/selectable-list.component";
import {DropFilesComponent} from "../drop-files/drop-files.component";
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {DropFilesService} from "../../../services/src/lib/endpoint/drop-files/drop-files.service";
import { ModalReviewComponent } from '../components/modal-defective/ModalReview/modal-review.component';
import {DateTimeParserService} from "../../../services/src/lib/date-time-parser/date-time-parser.service";
import JsPdf from "jspdf";
import 'jspdf-autotable';
import {TimesToastType} from "../../../services/src/models/timesToastType";
import {ProductsComponent} from "../new-return-unities/products/products.component";
import {DefectiveProductsComponent} from "../new-return-unities/defective-products/defective-products.component";
import {PositionsToast} from "../../../services/src/models/positionsToast.type";
import {AvailableItemsGroupedComponent} from "./modals/avaiable-items-grouped/available-items-grouped.component";
import Condition = ReturnModel.Condition;

@Component({
  selector: 'suite-new-return',
  templateUrl: './new-return.component.html',
  styleUrls: ['./new-return.component.scss']
})
export class NewReturnComponent implements OnInit {

  private baseUrlPhoto = environment.apiBasePhoto;
  return: Return;
  returnBeforeChanges: Return;
  types: ReturnType[];
  warehouses: Warehouse[];
  providers: Provider[];
  archives: Array<any> = [];
  delivery_notes: Array<any> = [];
  incidenceForm: FormGroup;
  archiveList: boolean = false;
  delivery_noteList: boolean = false;
  signatureList: boolean = false;
  displayArchiveList: boolean = false;
  displayDeliveryNoteList: boolean = false;
  isHistoric;
  isDefect: boolean = false;
  includePhotos: boolean;
  conditions: Condition[];

  private listItemsSelected: any[] = [];
  private itemForList: string = null;

  public ReturnStatus = ReturnModel.Status;
  private ReturnStatusNames = ReturnModel.StatusNames;
  public listStatusAvailable = [];

  public requiredFields: any = {
    type: true,
    provider: true,
    warehouse: true,
    brand: true,
    returnBeforeDate: true,
    quantityPacking: false,
    shipper: false,
    pickupDate: false,
    deliveryNote: false
  };

  productsByBrand;

  public availableUnities: ReturnModel.AvailableProductsGrouped[] = null;
  public assignedUnities: ReturnModel.AssignedProductsGrouped[] = null;
  public assignedItems: boolean = false;

  private MAX_PRODUCTS_WITH_PHOTOS_PER_PAGE = 3;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private returnService: ReturnService,
    private authenticationService: AuthenticationService,
    private modalController: ModalController,
    private intermediary: IntermediaryService,
    private dropFilesService: DropFilesService,
    private uploadService: UploadFilesService,
    private fb: FormBuilder,
    private alertController: AlertController,
    private dateTimeParserService: DateTimeParserService,
    private events: Events,
  ) {}

  async ngOnInit() {
    this.archiveList = false;
    this.delivery_noteList = false;
    this.archives = [];
    this.delivery_notes = [];
    this.conditions = [];

    this.getOptions();

    const returnId: number = parseInt(this.route.snapshot.paramMap.get('id'));
    if(this.route.snapshot.paramMap.get('isHistoric')){
      this.isHistoric = this.route.snapshot.paramMap.get('isHistoric');
    }

    if (returnId || (this.return && this.return.id)) {
      this.load(returnId ? returnId : this.return.id);
      this.archiveList = true;
      this.delivery_noteList = true;
    } else {
      this.return = {
        amountPackages: 0,
        brands: [],
        dateLastStatus: String(new Date()),
        dateLimit: "",
        datePickup: "",
        datePredictedPickup: "",
        dateReturnBefore: "",
        email: "",
        packings: [],
        packingsHistory: [],
        history: false,
        id: 0,
        lastStatus: 1,
        observations: "",
        operatorObservations: "",
        printTagPackages: false,
        provider: null,
        shipper: "",
        status: 1,
        type: null,
        unitsPrepared: 0,
        unitsSelected: 0,
        unitsLaunched: 0,
        user: await this.getCurrentUser(),
        userLastStatus: await this.getCurrentUser(),
        warehouse: null,
        archives: [],
        delivery_notes: [],
        products: []
      };

      this.listStatusAvailable = this.ReturnStatusNames.filter(r => r.id != this.ReturnStatus.UNKNOWN);

      this.archives = this.return.archives;
      this.delivery_notes = this.return.delivery_notes;
      this.displayArchiveList = false;
      this.displayDeliveryNoteList = false;
      this.initForm();

      this.archiveList = true;
      this.delivery_noteList = true;
    }

    this.dropFilesService.getImage().subscribe(resp => {
      if (resp) {
        if(resp.type=='archive') {
          this.archives.push(resp.file);
        }else{
          if(resp.type=='delivery_note') {
            this.delivery_notes.push(resp.file);
          }
        }
        if(this.archives.length > 0){
          this.displayArchiveList = true;
        }
        if(this.delivery_notes.length > 0){
          this.displayDeliveryNoteList = true;
        }
      }
    });

    this.events.subscribe('units_assigned', async () => await this.reload());
  }

  async reload(){
    this.archiveList = false;
    this.delivery_noteList = false;
    this.archives = [];
    this.delivery_notes = [];

    this.getOptions();

    const returnId: number = parseInt(this.route.snapshot.paramMap.get('id'));
    if(this.route.snapshot.paramMap.get('isHistoric')){
      this.isHistoric = this.route.snapshot.paramMap.get('isHistoric');
    }

    if (returnId || (this.return && this.return.id)) {
      await this.load(returnId ? returnId : this.return.id);
      this.archiveList = true;
      this.delivery_noteList = true;
    } else {
      this.return = {
        amountPackages: 0,
        brands: [],
        dateLastStatus: String(new Date()),
        dateLimit: "",
        datePickup: "",
        datePredictedPickup: "",
        dateReturnBefore: "",
        email: "",
        packings: [],
        packingsHistory: [],
        history: false,
        id: 0,
        lastStatus: 1,
        observations: "",
        operatorObservations: "",
        printTagPackages: false,
        provider: null,
        shipper: "",
        status: 1,
        type: null,
        unitsPrepared: 0,
        unitsSelected: 0,
        unitsLaunched: 0,
        user: await this.getCurrentUser(),
        userLastStatus: await this.getCurrentUser(),
        warehouse: null,
        archives: [],
        delivery_notes: [],
        products: []
      };

      this.listStatusAvailable = this.ReturnStatusNames.filter(r => r.id != this.ReturnStatus.UNKNOWN);

      this.archives = this.return.archives;
      this.delivery_notes = this.return.delivery_notes;
      this.displayArchiveList = false;
      this.displayDeliveryNoteList = false;
      this.initForm();

      this.archiveList = true;
      this.delivery_noteList = true;
    }
  }

  ngOnDestroy(){
    this.events.unsubscribe('units_assigned');
  }

  initForm() {
    this.incidenceForm = this.fb.group({
        amountPackages: this.return && this.return.amountPackages ? [this.return.amountPackages] : [0],
        brands: [this.return.brands],
        dateLastStatus: [this.return.dateLastStatus],
        dateLimit: [this.return.dateLimit],
        datePickup: [this.return.datePickup],
        datePredictedPickup: [this.return.datePredictedPickup],
        dateReturnBefore: [this.return.dateReturnBefore],
        email: [this.return.email],
        history: [this.return.history],
        id: [this.return.id],
        lastStatus: [this.return.lastStatus],
        observations: [this.return.observations],
        operatorObservations: [this.return.operatorObservations],
        packings: [this.return.packings],
        printTagPackages: [this.return.printTagPackages],
        provider: [this.return.provider],
        shipper: [this.return.shipper],
        status: [this.return.status],
        type: [this.return.type],
        unitsPrepared: [this.return.unitsPrepared],
        unitsSelected: [this.return.unitsSelected],
        user: [this.return.user],
        userLastStatus: [this.return.userLastStatus],
        warehouse: [this.return.warehouse],
        products: [this.return.products]
    });
  }

  async getCurrentUser() {
    const user: User = await this.authenticationService.getCurrentUser();
    delete user.permits;
    return user;
  }

  openArchiveList() {
    this.archiveList = !this.archiveList;
  }

  openDeliveryNoteList() {
    this.archiveList = !this.archiveList;
  }

  showArchiveList() {
    this.displayArchiveList = !this.displayArchiveList;
  }

  showDeliveryNoteList() {
    this.displayDeliveryNoteList = !this.displayDeliveryNoteList;
  }

  async openReviewImage(item) {
    const modal = await this.modalController.create({
      component: ModalReviewComponent,
      componentProps: {
        data: item
      }
    });
    await modal.present();
  }

  deleteImage(item, index, arr) {
    this.intermediary.presentLoading()
    this.uploadService.deleteFile(item.id).subscribe(
      resp => {
        this.intermediary.presentToastSuccess('Archivo borrado exitosamente')
        arr.splice(index, 1);
        if (this.archives.length === 0) {
          this.openArchiveList()
        }
        if (this.delivery_notes.length === 0) {
          this.openDeliveryNoteList()
        }
        //this.signature = false;
      },
      err => {
        this.intermediary.presentToastError('Ocurrio un error al borrar el archivo')
        this.intermediary.dismissLoading()
      },
      () => {
        this.intermediary.dismissLoading()
      }
    )
  }

  async save(customLoadingMsg: string, showToast: boolean = true) {
    await this.intermediary.presentLoadingNew(customLoadingMsg || 'Guardando devolución...');

    if (this.archives.length > 0) {
      let archives = [];
      this.archives.forEach(elem => {
        archives.push({ id: elem.id });
      });
      this.incidenceForm.addControl('archivesFileIds', new FormControl(archives));
    }
    if (this.delivery_notes.length > 0) {
      let delivery_notes = [];
      this.delivery_notes.forEach(elem => {
        delivery_notes.push({ id: elem.id });
      });
      this.incidenceForm.addControl('delivery_notesFileIds', new FormControl(delivery_notes));
    }

    this.return.archives = this.incidenceForm.value.archivesFileIds ? this.incidenceForm.value.archivesFileIds : [];
    this.return.delivery_notes = this.incidenceForm.value.delivery_notesFileIds ? this.incidenceForm.value.delivery_notesFileIds : [];

    if(this.return.brands){
      for (let brand of this.return.brands) {
        if(brand.name) delete brand.name;
        if(brand.condition) delete brand.condition;
      }
    }
    if(this.return.provider && this.return.provider.brands){
      for (let pbrand of this.return.provider.brands) {
        if(pbrand.name) delete pbrand.name;
        if(pbrand.condition) delete pbrand.condition;
      }
    }

    this.returnService.postSave(this.return).then(async (response: SaveResponse) => {
      this.intermediary.dismissLoadingNew();
      if (response.code == 200) {
        if (showToast) {
          this.intermediary.presentToastSuccess('Devolución guardada', TimesToastType.DURATION_SUCCESS_TOAST_3750);
          if(this.return && response.data && this.return.id == response.data.id){
            await this.getOptions();
            await this.load(response.data.id);
          } else {
            await this.router.navigate(['/new-return', {id: response.data.id}]);
          }
        }
      } else {
        const txtError = response.errors ? response.errors : 'Error al guardar la devolución';
        this.intermediary.presentToastError(txtError, "top", TimesToastType.DURATION_SUCCESS_TOAST_3750);
        console.error(response);
      }
    }).catch((e) => {
      console.error(e);
      this.intermediary.dismissLoadingNew();
    });
  }

  async load(returnId: number){
    this.returnService.postLoad({returnId: returnId}).then(async (response: LoadResponse) => {
      if (response.code == 200) {
        this.return = response.data;
        this.isDefect = this.return.type.defective;
        this.return.provider.brands = this.providers.filter(provider => provider.id == this.return.provider.id)[0].brands;
        this.return.brands = this.return.provider.brands.filter(brand => this.return.brands.map(b=>b.id).includes(brand.id));
        this.returnBeforeChanges = JSON.parse(JSON.stringify(this.return));

        if(this.return && this.return.provider){
          await this.getConditionsBySupplier(this.return.provider.id);
        }

        this.archives = this.return.archives;
        this.delivery_notes = this.return.delivery_notes;
        if(this.archives.length > 0){
          this.displayArchiveList = true;
        }
        if(this.delivery_notes.length > 0){
          this.displayDeliveryNoteList = true;
        }

        switch (this.return.status) {
          case this.ReturnStatus.IN_PROCESS:
          case this.ReturnStatus.PREPARED:
          case this.ReturnStatus.PENDING_PICKUP:
          case this.ReturnStatus.PICKED_UP:
          case this.ReturnStatus.BILLED:
            this.listStatusAvailable = this.ReturnStatusNames.filter(r => r.id > this.return.status && r.id != this.ReturnStatus.UNKNOWN);
            break;
          default:
            this.listStatusAvailable = this.ReturnStatusNames.filter(r => r.id != this.ReturnStatus.UNKNOWN);
        }

        this.initForm();

        this.returnService
          .getGetAssignedProductsGrouped(this.return.id)
          .subscribe(async res => {
            if (res.code == 200 && res.data.items && res.data.items.length > 0) {
              this.assignedUnities = res.data.items;
              this.assignedItems = true;
            } else {
              this.availableUnities = [];
              this.assignedItems = false;
            }
          });
        this.preloadOfAvailabilities();

      } else {
        console.error(response);
      }
    }).catch((e) => {
      console.error(e);
    });
  }

  getOptions(){
    this.returnService.getOptions().then((response: OptionsResponse) => {
      if(response.code == 200){
        this.types = response.data.types;
        this.warehouses = response.data.warehouses;
        this.providers = response.data.providers;
      }else{
        console.error(response);
      }
    }).catch(console.error);
  }

  getStatusName(status: number): string {
    const returnItem = this.ReturnStatusNames.find(r => r.id == status);
    if (returnItem) {
      return returnItem.name;
    }

    return 'Desconocido';
  }

  getBrandNameList(brands: Brand[]): string{
    return brands ? brands.filter(b => b && b.name).map(brand => brand.name.trim()).join(' // ') : '';
  }

  async changeStatus(status: number) {
    this.return.status = status;
    this.return.userLastStatus = await this.authenticationService.getCurrentUser();
    this.return.dateLastStatus = String(new Date());

    switch (this.return.status) {
      case this.ReturnStatus.PREPARED:
        this.requiredFields.quantityPacking = true;
        this.requiredFields.pickupDate = false;
        this.requiredFields.shipper = false;
        this.requiredFields.deliveryNote = false;
        break;
      case this.ReturnStatus.PENDING_PICKUP:
        this.requiredFields.quantityPacking = true;
        this.requiredFields.pickupDate = true;
        this.requiredFields.shipper = true;
        this.requiredFields.deliveryNote = false;
        break;
      case this.ReturnStatus.PICKED_UP:
        this.requiredFields.quantityPacking = true;
        this.requiredFields.pickupDate = true;
        this.requiredFields.shipper = true;
        this.requiredFields.deliveryNote = true;
        break;
    }
  }

  allFieldsFilled(): boolean{
    if (this.return) {
      switch (this.return.status) {
        case this.ReturnStatus.PREPARED:
          return !!(this.return.type && this.return.warehouse && this.return.provider && this.return.brands && this.return.brands.length > 0 && this.return.dateReturnBefore && this.return.amountPackages);
        case this.ReturnStatus.PENDING_PICKUP:
          return !!(this.return.type && this.return.warehouse && this.return.provider && this.return.brands && this.return.brands.length > 0 && this.return.dateReturnBefore && this.return.amountPackages && this.return.datePredictedPickup && this.return.shipper);
        case this.ReturnStatus.PICKED_UP:
          return !!(this.return.type && this.return.warehouse && this.return.provider && this.return.brands && this.return.brands.length > 0 && this.return.dateReturnBefore && this.return.amountPackages && this.return.datePredictedPickup && this.return.shipper && this.delivery_notes.length > 0);
        default:
          return !!(this.return.type && this.return.warehouse && this.return.provider && this.return.brands && this.return.brands.length > 0 && this.return.dateReturnBefore);
      }
    } else {
      return false;
    }

  }

  onlyPermittedChanges(): boolean{
    if(this.return && this.return.unitsPrepared > 0){
      if(
        this.return.type.id != this.returnBeforeChanges.type.id ||
        this.return.warehouse.id != this.returnBeforeChanges.warehouse.id ||
        this.return.provider.id != this.returnBeforeChanges.provider.id
      ){
        return false;
      }else{
        for(let brand of this.return.brands){
          if(!this.returnBeforeChanges.brands.map(b=>b.id).includes(brand.id)){
            return false;
          }
        }
        return true;
      }
    }else{
      return true;
    }
  }

  thereAreConditions(): boolean{
    if(this.conditions && this.conditions.length > 0){
      return true;
    }else{
      return false;
    }
  }

  async selectCondition() {
    const modal = await this.modalController.create({
      component: SelectConditionComponent,
      componentProps: {
        provider: this.return.provider,
        conditions: this.conditions
      }
    });

    modal.onDidDismiss().then(response => {
      if (response.data) {
        const condition: SupplierCondition = response.data;
        this.return.email = condition.contact;
        this.return.observations = condition.observations;
      }
    });

    await modal.present();
  }

  public async openShowSelectableList(type: number) {
    switch (type) {
      case 1:
        this.listItemsSelected = this.types.map(v => {
          return {id: v.id, value: v.name}
        });
        this.itemForList = 'Tipo';
        break;
      case 2:
        this.listItemsSelected = this.warehouses.map(v => {
          return {id: v.id, value: `${v.reference} - ${v.name}`}
        });
        this.itemForList = 'Almacén';
        break;
      case 3:
        this.listItemsSelected = this.providers.map(v => {
          return {id: v.id, value: v.name}
        });
        this.itemForList = 'Proveedor';
        break;
    }

    const modal = await this.modalController.create({
      component: SelectableListComponent,
      componentProps: { listItemsSelected: this.listItemsSelected, itemForList: this.itemForList }
    });
    modal.onDidDismiss().then(result => {
      if (result && result.data != null) {
        switch (type) {
          case 1:
            this.return.type = this.types.find(v => v.id == result.data);
            this.isDefect = this.return.type.defective;
            this.return.provider = null;
            this.return.brands = [];
            this.conditions = null;
            this.preloadOfAvailabilities();
            break;
          case 2:
            this.return.warehouse = this.warehouses.find(v => v.id == result.data);
            this.preloadOfAvailabilities();
            break;
          case 3:
            this.return.provider = this.providers.find(v => v.id == result.data);
            this.return.brands = [];
            break;
        }
      }
    });
    await modal.present();
  }

  public selectUnitiesItems() {
    let isDefective = false;
    if (this.return.type && this.return.type.defective) {
      isDefective = true;
    }

    const warehouseId = this.return.warehouse.id;
    const providerId = this.return.provider && this.return.provider.id;
    const brandIds = this.return.brands.map(b => b.id).join(',');

    this.returnService
      .postCheckProductsToAssignReturn({
        returnId: this.return.id,
        provider: providerId,
        warehouse: warehouseId,
        brands: this.return.brands.map(b => b.id),
        defective: isDefective
      })
      .subscribe(async (res) => {
        if (res.code == 200 && res.data.available_products) {
          await this.save(null, false);
          this.router.navigate(['new-return', 'unities', this.return.id], { queryParams: {defective: isDefective, warehouse: warehouseId, provider: providerId, brands: brandIds} });
        } else {
          this.intermediary.presentWarning('No hay unidades disponibles para asignar a la devolución.', null);
        }
      }, (e) => {
        this.intermediary.presentToastError('Ha ocurrido un error al intentar comprobar si hay unidades disponibles que asignar.', PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST);
      });

  }

  async searchArchive() {
    const modal = await this.modalController.create({
      component: DropFilesComponent,
      componentProps: {type: 'archive', images: this.archives }
    });
    await modal.present();
  }

  async searchDelivery_note() {
    const modal = await this.modalController.create({
      component: DropFilesComponent,
      componentProps: {type: 'delivery_note', images: this.delivery_notes, showSaveButton: true }
    });

    modal.onDidDismiss().then(data => {

    });

    await modal.present();
  }

  public formatDate(date: string) {
    return this.dateTimeParserService.dateMonthYear(date);
  }

  public getCountProductsScanned(productsList: any[]) {
    return productsList.filter(p => p.status == 2).length;
  }

  public getPackingNames(returnManufacturer: Return): string {
    if (returnManufacturer.packingsHistory && returnManufacturer.packingsHistory.length > 0) {
      return returnManufacturer.packingsHistory.map(returnPackingHistory => returnPackingHistory.packingReference).join(', ');
    } else if (returnManufacturer.packings && returnManufacturer.packings.length > 0) {
      return returnManufacturer.packings.map(returnPacking => returnPacking.packing.reference).join(', ');
    }
    return '';
  }

  private preloadOfAvailabilities() {
    if (this.return) {
      if (this.return.type && this.return.warehouse) {
        this.intermediary.presentLoading();
        this.returnService
          .postGetAvailableProductsGrouped({
            warehouse: this.return.warehouse.id,
            defective: this.return.type.defective
          })
          .subscribe(res => {
            if (res.code == 200 && res.data.available_products && res.data.available_products.length > 0) {
              this.availableUnities = res.data.available_products;
            } else {
              this.availableUnities = null;
            }
            this.intermediary.dismissLoading();
          }, error => this.availableUnities = null);
      } else {
        this.availableUnities = null;
      }
    }
  }

  public async showAvailableUnities(showUnities?: boolean) {
    let modal;
    if(showUnities){
      modal = await this.modalController.create({
        component: AvailableItemsGroupedComponent,
        componentProps: {listAssignedProductsGrouped: this.assignedUnities, listUnitiesGrouped: this.availableUnities, assigned: this.assignedItems, showUnities: true, isDefect: this.isDefect, return: this.return}
      });
    }else{
      modal = await this.modalController.create({
        component: AvailableItemsGroupedComponent,
        componentProps: {listUnitiesGrouped: this.availableUnities, assigned: this.assignedItems, isDefect: this.isDefect, return: this.return}
      });
    }

    modal.onDidDismiss().then(async response => {
      if (response.data) {
        if (response.data.length > 1) {
          const id = response.data[0].providerId;
          let flag = true;
          response.data.forEach(item => {
            if(item.providerId != id){
              flag = false;
            }
          });
          if(!flag){
            this.intermediary.presentToastError("Debes seleccionar marcas de un mismo proveedor.");
            return;
          }else{
            this.return.provider = {id: response.data[0].providerId, name: response.data[0].provider};
            let brands = [];
            response.data.forEach(elem => {
              brands.push({id: elem.brandId, name: elem.brand});
            });
            this.return.brands = brands;
            this.return.provider.brands = brands;
          }
        }else{
          this.return.provider = {id: response.data[0].providerId, name: response.data[0].provider};
          let brands = [];
          response.data.forEach(elem => {
            brands.push({id: elem.brandId, name: elem.brand});
          });
          this.return.brands = brands;
          this.return.provider.brands = brands;
        }
        if(this.return && this.return.provider) await this.getConditionsBySupplier(this.return.provider.id);
      }
    });
    await modal.present();
  }

  async deliveryNote(){
    const alert = await this.alertController.create({
      header: 'Incluir Fotos',
      message: '¿Desea incluir las fotos?',
      buttons: [
        {
          text: 'Sí',
          handler: () => {
            this.includePhotos = true;
            this.pureJsPdf();
          }
        }, {
          text: 'No',
          handler: () => {
            this.includePhotos = false;
            this.pureJsPdf();
          }
        }
      ]
    });
    await alert.present();
  }

  async pureJsPdf() {
    let doc = new JsPdf();
    let currentHeight: number = 20;
    let currentWidth: number = 15;
    let imageRows: boolean[] = [];

    this.returnService.getReturnToDeliveryNote(this.return.id, this.includePhotos).then(async (response: LoadResponse) => {
      if (response.code == 200) {
        let returnObj: Return = response.data;

        //Title
        doc.setFontSize(13);
        doc.setFontStyle('bold');
        const title: string[] = doc.splitTextToSize(`Albarán Devolución ${returnObj.id} - ${returnObj.provider.name}`, 180);
        doc.text(title, currentWidth, currentHeight);
        currentHeight += 7*title.length;
        let datePickupFormatted = returnObj.datePickup ? this.formatDate(returnObj.datePickup) : '';
        doc.text(`Almacén: ${returnObj.warehouse.reference} ${returnObj.warehouse.name}`, currentWidth, currentHeight);
        currentHeight += 7;
        doc.text(`Transportista: ${returnObj.shipper}`, currentWidth, currentHeight);
        currentHeight += 7;
        doc.text(`Bultos: ${returnObj.amountPackages}`, currentWidth, currentHeight);
        currentHeight += 7;
        doc.text(`Fecha recogida: ${datePickupFormatted}`, currentWidth, currentHeight);
        currentHeight += 7;

        //Table
        let head: string[][];
        let body: string[][];
        let lastPage: number = 1;

        const productsWithPhotos = [];
        const productsWithoutPhotos = [];
        const productsWithPhotosGroups = [];
        const linesHeight = [];

        if (returnObj.type.defective) {
          if (this.includePhotos) {

            for (let product of returnObj.products) {
              if (product.defect.photos.length) {
                productsWithPhotos.push(product);
              } else {
                productsWithoutPhotos.push(product);
              }
            }

            if (productsWithPhotos.length <= this.MAX_PRODUCTS_WITH_PHOTOS_PER_PAGE) {
              const allProducts = productsWithPhotos.concat(productsWithoutPhotos);
              head = [['Artículo', 'Modelo', 'Talla', 'Unidades', 'Detalle\nArtículo', 'Detalle\nColor', 'Motivo\nDefecto']];
              body = (() => {
                let result: string[][] = [];
                for (let product of allProducts) {
                  const defects: string[] = [];
                  if (product.defect.defectTypeParent && product.defect.defectTypeParent.includeInDeliveryNote) {
                    defects.push(product.defect.defectTypeParent.name);
                  }
                  if (product.defect.defectTypeChild && product.defect.defectTypeChild.includeInDeliveryNote) {
                    defects.push(product.defect.defectTypeChild.name);
                  }
                  if (product.defect.defectZoneParent && product.defect.defectZoneParent.includeInDeliveryNote) {
                    defects.push(product.defect.defectZoneParent.name);
                  }
                  if (product.defect.defectZoneChild && product.defect.defectZoneChild.includeInDeliveryNote) {
                    defects.push(product.defect.defectZoneChild.name);
                  }

                  let color = (product.model as any).detailColor;
                  let modelName = '';
                  let colorName = '';
                  let maxLines = 2;

                  if (product.model.name.length > 16) {
                    let slashModelNameArray = product.model.name.split("/");
                    if (slashModelNameArray.length > 1) {
                      if (maxLines < slashModelNameArray.length) {
                        maxLines = slashModelNameArray.length;
                      }
                      modelName = slashModelNameArray.join("/\n");
                    } else {
                      let spaceModelNameArray = product.model.name.split(" ");
                      if (spaceModelNameArray.length > 1) {
                        if (maxLines < spaceModelNameArray.length) {
                          maxLines = spaceModelNameArray.length;
                        }
                        modelName = spaceModelNameArray.join("\n");
                      } else {
                        modelName = product.model.name;
                      }
                    }
                  } else {
                    modelName = product.model.name;
                  }

                  if (color.length > 8) {
                    let slashColorNameArray = color.split("/");
                    if (slashColorNameArray.length > 1) {
                      if (maxLines < slashColorNameArray.length) {
                        maxLines = slashColorNameArray.length;
                      }
                      colorName = slashColorNameArray.join("/\n");
                    } else {
                      let spaceColorNameArray = color.split(" ");
                      if (spaceColorNameArray.length > 1) {
                        if (maxLines < spaceColorNameArray.length) {
                          maxLines = spaceColorNameArray.length;
                        }
                        colorName = spaceColorNameArray.join("\n");
                      } else {
                        colorName = color;
                      }
                    }
                  } else {
                    colorName = color;
                  }

                  if (defects.length > maxLines) {
                    maxLines = defects.length;
                  }

                  let data = [
                    product.model.reference,
                    modelName,
                    product.size.name,
                    '1',
                    product.model.supplierReference,
                    colorName,
                    defects.join('/\n')
                  ];

                  result.push(data);
                  if (product.defect.photos.length > 0) {
                    result.push(['', '', '', '']);
                    result.push(['', '', '', '']);
                    result.push(['', '', '', '']);
                    result.push(['', '', '', '']);
                    result.push(['', '', '', '']);
                    result.push(['', '', '', '']);
                    imageRows.push(true);
                    linesHeight.push(maxLines);
                  } else {
                    imageRows.push(false);
                  }
                }
                return result;
              })();

              doc.autoTable({startY: currentHeight, head: head, body: body, styles: {halign: 'center', fontSize: 10}, columnStyles: {
                  0: {cellWidth: 20},
                  1: {cellWidth: 42},
                  2: {cellWidth: 15},
                  3: {cellWidth: 25},
                  4: {cellWidth: 25},
                  5: {cellWidth: 20},
                  6: {cellWidth: 35}
                }});
              currentHeight += 10;
            } else {
              for (let i = 0, j = 0; i < productsWithPhotos.length; i++) {
                if (i >= this.MAX_PRODUCTS_WITH_PHOTOS_PER_PAGE && i % this.MAX_PRODUCTS_WITH_PHOTOS_PER_PAGE === 0) {
                  j++;
                }
                productsWithPhotosGroups[j] = productsWithPhotosGroups[j] || [];
                productsWithPhotosGroups[j].push(productsWithPhotos[i])
              }

              for (let i = 0; i <  productsWithPhotosGroups.length; i++) {
                if (i !== 0) {
                  doc.addPage("a4", "portrait");
                  currentHeight = 20;
                }
                head = [['Artículo', 'Modelo', 'Talla', 'Unidades', 'Detalle\nArtículo', 'Detalle\nColor', 'Motivo\nDefecto']];
                if (i !== productsWithPhotosGroups.length - 1) {
                  body = (() => {
                    let result: string[][] = [];
                    for (let product of productsWithPhotosGroups[i]) {
                      const defects: string[] = [];
                      if (product.defect.defectTypeParent && product.defect.defectTypeParent.includeInDeliveryNote) {
                        defects.push(product.defect.defectTypeParent.name);
                      }
                      if (product.defect.defectTypeChild && product.defect.defectTypeChild.includeInDeliveryNote) {
                        defects.push(product.defect.defectTypeChild.name);
                      }
                      if (product.defect.defectZoneParent && product.defect.defectZoneParent.includeInDeliveryNote) {
                        defects.push(product.defect.defectZoneParent.name);
                      }
                      if (product.defect.defectZoneChild && product.defect.defectZoneChild.includeInDeliveryNote) {
                        defects.push(product.defect.defectZoneChild.name);
                      }

                      let color = (product.model as any).detailColor;
                      let modelName = '';
                      let colorName = '';
                      let maxLines = 2;

                      if (product.model.name.length > 16) {
                        let slashModelNameArray = product.model.name.split("/");
                        if (slashModelNameArray.length > 1) {
                          if (maxLines < slashModelNameArray.length) {
                            maxLines = slashModelNameArray.length;
                          }
                          modelName = slashModelNameArray.join("/\n");
                        } else {
                          let spaceModelNameArray = product.model.name.split(" ");
                          if (spaceModelNameArray.length > 1) {
                            if (maxLines < spaceModelNameArray.length) {
                              maxLines = spaceModelNameArray.length;
                            }
                            modelName = spaceModelNameArray.join("\n");
                          } else {
                            modelName = product.model.name;
                          }
                        }
                      } else {
                        modelName = product.model.name;
                      }

                      if (color.length > 8) {
                        let slashColorNameArray = color.split("/");
                        if (slashColorNameArray.length > 1) {
                          if (maxLines < slashColorNameArray.length) {
                            maxLines = slashColorNameArray.length;
                          }
                          colorName = slashColorNameArray.join("/\n");
                        } else {
                          let spaceColorNameArray = color.split(" ");
                          if (spaceColorNameArray.length > 1) {
                            if (maxLines < spaceColorNameArray.length) {
                              maxLines = spaceColorNameArray.length;
                            }
                            colorName = spaceColorNameArray.join("\n");
                          } else {
                            colorName = color;
                          }
                        }
                      } else {
                        colorName = color;
                      }

                      if (defects.length > maxLines) {
                        maxLines = defects.length;
                      }

                      let data = [
                        product.model.reference,
                        modelName,
                        product.size.name,
                        '1',
                        product.model.supplierReference,
                        colorName,
                        defects.join('/\n')
                      ];

                      result.push(data);
                      result.push(['', '', '', '']);
                      result.push(['', '', '', '']);
                      result.push(['', '', '', '']);
                      result.push(['', '', '', '']);
                      result.push(['', '', '', '']);
                      result.push(['', '', '', '']);
                      linesHeight.push(maxLines);
                    }
                    return result;
                  })();
                } else {
                  const restOfTheProducts = productsWithPhotosGroups[i].concat(productsWithoutPhotos);
                  body = (() => {
                    let result: string[][] = [];
                    for (let product of restOfTheProducts) {
                      const defects: string[] = [];
                      if (product.defect.defectTypeParent && product.defect.defectTypeParent.includeInDeliveryNote) {
                        defects.push(product.defect.defectTypeParent.name);
                      }
                      if (product.defect.defectTypeChild && product.defect.defectTypeChild.includeInDeliveryNote) {
                        defects.push(product.defect.defectTypeChild.name);
                      }
                      if (product.defect.defectZoneParent && product.defect.defectZoneParent.includeInDeliveryNote) {
                        defects.push(product.defect.defectZoneParent.name);
                      }
                      if (product.defect.defectZoneChild && product.defect.defectZoneChild.includeInDeliveryNote) {
                        defects.push(product.defect.defectZoneChild.name);
                      }

                      let color = (product.model as any).detailColor;
                      let modelName = '';
                      let colorName = '';
                      let maxLines = 2;

                      if (product.model.name.length > 16) {
                        let slashModelNameArray = product.model.name.split("/");
                        if (slashModelNameArray.length > 1) {
                          if (maxLines < slashModelNameArray.length) {
                            maxLines = slashModelNameArray.length;
                          }
                          modelName = slashModelNameArray.join("/\n");
                        } else {
                          let spaceModelNameArray = product.model.name.split(" ");
                          if (spaceModelNameArray.length > 1) {
                            if (maxLines < spaceModelNameArray.length) {
                              maxLines = spaceModelNameArray.length;
                            }
                            modelName = spaceModelNameArray.join("\n");
                          } else {
                            modelName = product.model.name;
                          }
                        }
                      } else {
                        modelName = product.model.name;
                      }

                      if (color.length > 8) {
                        let slashColorNameArray = color.split("/");
                        if (slashColorNameArray.length > 1) {
                          if (maxLines < slashColorNameArray.length) {
                            maxLines = slashColorNameArray.length;
                          }
                          colorName = slashColorNameArray.join("/\n");
                        } else {
                          let spaceColorNameArray = color.split(" ");
                          if (spaceColorNameArray.length > 1) {
                            if (maxLines < spaceColorNameArray.length) {
                              maxLines = spaceColorNameArray.length;
                            }
                            colorName = spaceColorNameArray.join("\n");
                          } else {
                            colorName = color;
                          }
                        }
                      } else {
                        colorName = color;
                      }

                      if (defects.length > maxLines) {
                        maxLines = defects.length;
                      }

                      let data = [
                        product.model.reference,
                        modelName,
                        product.size.name,
                        '1',
                        product.model.supplierReference,
                        colorName,
                        defects.join('/\n')
                      ];

                      result.push(data);
                      if (product.defect.photos.length > 0) {
                        result.push(['', '', '', '']);
                        result.push(['', '', '', '']);
                        result.push(['', '', '', '']);
                        result.push(['', '', '', '']);
                        result.push(['', '', '', '']);
                        result.push(['', '', '', '']);
                        linesHeight.push(maxLines);
                      }
                    }
                    return result;
                  })();
                }

                doc.autoTable({startY: currentHeight, head: head, body: body, styles: {halign: 'center', fontSize: 10}, columnStyles: {
                    0: {cellWidth: 20},
                    1: {cellWidth: 42},
                    2: {cellWidth: 15},
                    3: {cellWidth: 25},
                    4: {cellWidth: 25},
                    5: {cellWidth: 20},
                    6: {cellWidth: 35}
                  }});
                currentHeight += 10;
              }

            }
          } else {
            head = [['Artículo', 'Modelo', 'Talla', 'Unidades', 'Detalle\nArtículo', 'Detalle\nColor', 'Motivo\nDefecto']];
            body = (() => {
              let result: string[][] = [];
              for (let product of returnObj.products) {
                const defects: string[] = [];
                if (product.defect.defectTypeParent && product.defect.defectTypeParent.includeInDeliveryNote) {
                  defects.push(product.defect.defectTypeParent.name);
                }
                if (product.defect.defectTypeChild && product.defect.defectTypeChild.includeInDeliveryNote) {
                  defects.push(product.defect.defectTypeChild.name);
                }
                if (product.defect.defectZoneParent && product.defect.defectZoneParent.includeInDeliveryNote) {
                  defects.push(product.defect.defectZoneParent.name);
                }
                if (product.defect.defectZoneChild && product.defect.defectZoneChild.includeInDeliveryNote) {
                  defects.push(product.defect.defectZoneChild.name);
                }
                let data = [
                  product.model.reference,
                  product.model.name,
                  product.size.name,
                  '1',
                  product.model.supplierReference,
                  (product.model as any).detailColor,
                  defects.join('/\n')
                ];
                result.push(data);
                imageRows.push(false);
              }
              return result;
            })();
            doc.autoTable({startY: currentHeight, head: head, body: body, styles: {halign: 'center', fontSize: 10}, columnStyles: {
                0: {cellWidth: 20},
                1: {cellWidth: 42},
                2: {cellWidth: 15},
                3: {cellWidth: 25},
                4: {cellWidth: 25},
                5: {cellWidth: 20},
                6: {cellWidth: 35}
              }});
            currentHeight += 10;
          }
        } else {
          head = [['Artículo', 'Modelo', 'Talla', 'Unidades', 'Detalle\nArtículo', 'Detalle\nColor']];
          body = (() => {
            let result: string[][] = [];
            const modelIds = returnObj.products.map(prod => prod.model.id).filter((elem, index, self) => {
              return index === self.indexOf(elem);
            });
            for (let modelId of modelIds) {
              const sizeIds = returnObj.products.filter(prod => prod.model.id == modelId).map(prod => prod.size.id).filter((elem, index, self) => {
                return index === self.indexOf(elem);
              });
              for (let sizeId of sizeIds) {
                const products = returnObj.products.filter(prod => prod.model.id == modelId && prod.size.id == sizeId);
                let data = [
                  products[0].model.reference,
                  products[0].model.name,
                  products[0].size.name,
                  products.length.toString(),
                  products[0].model.supplierReference,
                  ((products[0].model) as any).detailColor
                ];
                result.push(data);
                imageRows.push(false);
              }
            }
            return result;
          })();

          doc.autoTable({startY: currentHeight, head: head, body: body, styles: {halign: 'center', fontSize: 10}, columnStyles: {
              0: {cellWidth: 25},
              1: {cellWidth: 52},
              2: {cellWidth: 20},
              3: {cellWidth: 30},
              4: {cellWidth: 30},
              5: {cellWidth: 25}
            }});
          currentHeight += 10;
        }

        //Table images
        if (this.includePhotos) {
          if (productsWithPhotos.length <= this.MAX_PRODUCTS_WITH_PHOTOS_PER_PAGE) {
            doc.setPage(1);
            for (let row = 0; row < imageRows.length; row++) {
              if (linesHeight[row] && linesHeight[row] > 2) {
                currentHeight += 14 + (4.06 * (linesHeight[row] - 2));
              } else {
                currentHeight += 14;
              }
              if (imageRows[row]) {
                currentHeight -= .71;
                let counter: number = 0;
                for (let photo of returnObj.products[row].defect.photos) {
                  if(photo.extension == '.png' || photo.extension == '.jpg'){
                    if (counter == 3) {
                      break;
                    }
                    await this.getMeta(this.baseUrlPhoto + photo.pathMedium).then(image => {
                      let img: any = image;
                      let dimensions: { width: number, height: number } = NewReturnComponent.checkImageAndResize(img.naturalWidth, img.naturalHeight);
                      doc.addImage(img, "PNG", currentWidth - .85 + (61 * counter), currentHeight, (dimensions.width*1.5), (dimensions.height*1.5));
                      counter++;
                    }, () => {
                      console.error('Error while trying to load the image.');
                    });
                  }
                }
                currentHeight += 43.86;
              }
            }
            currentHeight += 5;
          } else {
            let imageCount = -1;
            for (let i = 0; i < productsWithPhotosGroups.length; i++) {
              doc.setPage(i + 1);
              lastPage = i + 1;
              if (i !== 0) {
                currentHeight = 30;
              } else {
                currentHeight = 65;
              }

              for (let product of  productsWithPhotosGroups[i]) {
                imageCount++;
                if (linesHeight[imageCount] && linesHeight[imageCount] > 2) {
                  currentHeight += 14 + (4.06 * (linesHeight[imageCount] - 2));
                } else {
                  currentHeight += 14;
                }
                currentHeight -= .71;
                let counter: number = 0;
                for (let photo of product.defect.photos) {
                  if(photo.extension == '.png' || photo.extension == '.jpg'){
                    if (counter == 3) {
                      break;
                    }
                    await this.getMeta(this.baseUrlPhoto + photo.pathMedium).then(image => {
                      let img: any = image;
                      let dimensions: { width: number, height: number } = NewReturnComponent.checkImageAndResize(img.naturalWidth, img.naturalHeight);
                      doc.addImage(img, "PNG", currentWidth - .85 + (61 * counter), currentHeight, (dimensions.width*1.5), (dimensions.height*1.5));
                      counter++;
                    }, () => {
                      console.error('Error while trying to load the image.');
                    });
                  }
                }
                currentHeight += 43.86;
              }
              currentHeight += 5;
            }
          }
        } else {
          currentHeight += (10 * body.length) + 5;
        }

        //Images
        if (this.includePhotos && returnObj.archives && returnObj.archives.length > 0) {
          let heightPage = doc.internal.pageSize.getHeight();
          if(doc.internal.getNumberOfPages() > 1) {
            let currentHeightPage = currentHeight > 0 && heightPage > 0 ? currentHeight % heightPage : 0;
            currentHeight = currentHeightPage + 40;
            doc.setPage(doc.internal.getNumberOfPages());
          }
          if(currentHeight + 60 > heightPage){
            doc.addPage("a4", "portrait");
            currentHeight = 20;
            doc.setPage(doc.internal.getNumberOfPages());
          }
          doc.setFontSize(16);
          doc.text(`Fotos`, currentWidth, currentHeight);
          currentHeight += 5;
          let counter: number = 0;
          for (let photo of returnObj.archives) {
            if(photo.extension == '.png' || photo.extension == '.jpg'){
              if (counter == 3) {
                counter = 0;
                currentHeight += 50;
                if(currentHeight + 60 > heightPage){
                  doc.addPage("a4", "portrait");
                  currentHeight = 20;
                  doc.setPage(doc.internal.getNumberOfPages());
                }
              }
              await this.getMeta(this.baseUrlPhoto + photo.pathMedium).then(image => {
                let img: any = image;
                let dimensions: { width: number, height: number } = NewReturnComponent.checkImageAndResize(img.naturalWidth, img.naturalHeight);
                doc.addImage(img, "PNG", currentWidth - .85 + (61 * counter), currentHeight, (dimensions.width*1.5), (dimensions.height*1.5));
                counter++;
              }, () => {
                console.error('Error while trying to load the image.');
              });
            }
          }
        }

        doc.save(`Albarán Devolución ${returnObj.id}.pdf`);

      } else {
        console.error(response);
        this.intermediary.presentToastError('Ha ocurrido un error al obtener los datos de la devolución para generar el albarán')
      }
    }).catch((e)=>{
      console.error(e);
      this.intermediary.presentToastError('Ha ocurrido un error al obtener los datos de la devolución para generar el albarán')
    });

  }

  getMeta(url) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }

  static checkImageAndResize(width: number, height: number): {width: number, height: number}{
    let ratio: number;
    if( (width/height) == 2 ){
      ratio = 58/width;
    }else{
      if( (width/height) > 2 ){
        ratio = 58/width;
      }else{
        ratio = 29/height;
      }
    }
    return {width: width*ratio, height: height*ratio};
  }

  private async getConditionsBySupplier(supplierId: number){
    this.returnService.getConditionsBySupplier(supplierId).subscribe(async (res: ReturnModel.SupplierConditionsResponse) => {
      if (res.code == 200 && res.data && res.data.length > 0) {
        this.conditions = res.data;
      } else {
        this.conditions = [];
      }
    });
  }
}

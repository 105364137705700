import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { AuthenticationService } from '../authentication/authentication.service';
import { PATH } from "../../../../../../config/base";
import { from, Observable } from "rxjs";
import { InventoryModel } from "../../../models/endpoints/Inventory";
import { environment } from '../../../environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { HttpRequestModel } from "../../../models/endpoints/HttpRequest";
import { RequestsProvider } from "../../../providers/requests/requests.provider";
import { ExcellModell } from 'libs/services/src/models/endpoints/Excell';
import { app } from '../../../../src/environments/environment';


const PATH_POST_STORE: string = PATH('Inventory Process', 'Store');
const PATH_GET_PRODUCTS_CONTAINER: string = PATH('Inventory', 'List by Container').slice(0, -1);
const PATH_GET_PRODUCTS_HISTORY_CONTAINER: string = PATH('Inventory Process', 'List by container').slice(0, -1);

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  /**Urls for the inventory service */
  private postStoreUrl: string = environment.apiBase + "/processes/positioner-main";
  private postStoreDefectiveUrl: string = environment.apiBase + "/processes/positioner-main/defective";
  private postStoreOnlineUrl: string = environment.apiBase + "/processes/positioner-main/online";
  private getProductsByContainerUrl: string = environment.apiBase + "/inventory/container/{{id}}";
  private getProductsHistoryByContainerUrl: string = environment.apiBase + "/inventory/processes/container/{{id}}";


  private postGlobalUrl: string = environment.apiBase + "/processes/positioner-main/global";
  private postPickingDirectUrl: string = environment.apiBase + "/processes/picking-main/direct";
  private postPickingReturnUrl: string = environment.apiBase + "/processes/picking-main/return";
  private postPendingSealUrl: string = environment.apiBase + "/processes/picking-main/pending-seal";
  private postPickingConsolidatedUrl: string = environment.apiBase + '/processes/picking-main/consolidated';
  private postPickingOnlineStoreUrl: string = environment.apiBase + '/processes/picking-main/ot';

  private sendexcell = environment.apiBase + "/inventory/export-to-excel-products";
  private searchInContainerUrl = environment.apiBase + "/inventory/search";
  private searchFiltersUrl = environment.apiBase + "/inventory/searchFilters";
  private userPermissionUrl = `${environment.apiBase}/gestion-permissions/users/has-force-permission`;
  private deleteProductsUrl = `${environment.apiBase}/inventory/delete-products`;
  private undoInvnetoryUrl = `${environment.apiBase}/inventory/undo`;
  private userPrimsiondeleteProducts = `${environment.apiBase}/gestion-permissions/users/has-delete-product-permission`;
  private inventoryByModelUrl = `${environment.apiBase}/inventory/{{warehouseId}}/model/{{modelId}}`;
  private sendExcellZalando = environment.apiCrm + "/inventory/export-to-excel";
  private getCsvDateTimeUrl = `${environment.apiBase}/inventory/get-csv-all-products-created-time`;
  private downloadCSVUrl = `${environment.apiBase}/inventory/download-csv-all-products`;
  private generateCSVUrl = `${environment.apiBase}/inventory/generate-csv-all-products`;

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private requestsProvider: RequestsProvider
  ) { }

  /**
   * Seach products in the inventory filtereds by params
   * @param parameters filters
   */
  searchInContainer(parameters): Observable<InventoryModel.ResponseSearchInContainer> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.post<InventoryModel.ResponseSearchInContainer>(this.searchInContainerUrl, parameters, { headers });
    }));
  }

  /**
   * Seach filters in the inventory
   * @param parameters filters
   */
  searchFilters(parameters): Observable<InventoryModel.ResponseFilters> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      // let headers = new HttpHeaders().set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').set('Authorization', token);
      // return this.http.post<InventoryModel.ResponseFilters>(this.searchFiltersUrl,parameters, {headers});
      return this.http.post<InventoryModel.ResponseFilters>(this.searchFiltersUrl, parameters, { headers });
    }));
  }


  /**
   * @author Gaetano Sabino
   * @param parameters
   */
  getFileExcell(parameters: ExcellModell.fileExcell) {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      // let headers:HttpHeaders = new HttpHeaders({Authorization:token});


      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });

      return this.http.post(this.sendexcell, parameters, { headers, responseType: 'blob' });
    }));
  }

  /**
   * @author Gaetano Sabino
   * @param ids
   */
  delete_Products(ids: number[]) {
    let body = { ids };

    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.post(this.deleteProductsUrl, body, { headers })
    }))
  }

  undo_inventoryProcess() {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.post(this.undoInvnetoryUrl, { headers })
    }))
  }

  permisis_user() {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.get(this.userPrimsiondeleteProducts);
    }))
  }

  postStore(params: InventoryModel.Inventory): Promise<HttpRequestModel.Response> {
    params.app = app.name
    return this.requestsProvider.post(this.postStoreUrl, params);
  }

  postStoreObservable(params: InventoryModel.Inventory): Observable<any> {
    params.app = app.name
    return this.http.post(this.postStoreUrl ,params).pipe(map((response:any)=>{
      return response.data
    }));
  }

  postStoreDefective(parameters): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postStoreDefectiveUrl, parameters);
  }

  postStoreOnline(params: InventoryModel.Inventory): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postStoreOnlineUrl, params);
  }

  checkUserPermissions(): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.get(this.userPermissionUrl);
  }


  async productsByContainer(
    containerId: number
  ): Promise<Observable<HttpResponse<InventoryModel.ResponseProductsContainer>>> {
    const currentToken = await this.auth.getCurrentToken();
    const headers = new HttpHeaders({ Authorization: currentToken });
    return this.http.get<InventoryModel.ResponseProductsContainer>(this.getProductsByContainerUrl.replace("{{id}}", String(containerId)), {
      headers: headers,
      observe: 'response'
    });
  }

  async productsHistoryByContainer(
    containerId: number
  ): Promise<Observable<HttpResponse<InventoryModel.ResponseProductsContainer>>> {
    const currentToken = await this.auth.getCurrentToken();
    const headers = new HttpHeaders({ Authorization: currentToken });
    return this.http.get<InventoryModel.ResponseProductsContainer>(
      this.getProductsHistoryByContainerUrl.replace("{{id}}", String(containerId)), {
      headers: headers,
      observe: 'response'
    });
  }

  postGlobal(containersToMoveProducts): Observable<InventoryModel.ResponseGlobal> {
    return this.http.post<InventoryModel.ResponseGlobal>(this.postGlobalUrl, containersToMoveProducts);
  }

  postPickingDirect(picking: InventoryModel.Picking): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postPickingDirectUrl, picking);
  }

  postPickingReturn(picking: InventoryModel.Picking): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postPickingReturnUrl, picking);
  }

  postPickingConsolidated(picking: InventoryModel.Picking): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postPickingConsolidatedUrl, picking);
  }

  postPickingOnlineStore(picking: InventoryModel.Picking): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postPickingOnlineStoreUrl, picking);
  }

  getPendingSeal(pickingId: number): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postPendingSealUrl, { pickingId: pickingId });
  }

  getContainersByModel(modelId: number, warehouseId: number): Observable<InventoryModel.Inventories[]> {
    let url = this.inventoryByModelUrl.replace("{{warehouseId}}", String(warehouseId));
    return this.http.get<InventoryModel.ResponseByModel>(url.replace("{{modelId}}", String(modelId))).pipe(map(response => {
      return response.data;
    }));
  }

  getFileExcellZalando(parameters: ExcellModell.fileExcell) {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.post(this.sendExcellZalando, parameters, { headers, responseType: 'blob' });
    }));
  }

  getCSV() {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.get(this.downloadCSVUrl, { headers, responseType: 'blob' });
    }));
  }

  generateCSV() {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.post(this.generateCSVUrl, {},{ headers });
    }));
  }
}

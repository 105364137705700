import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IntermediaryService } from '../../../../services/src/lib/endpoint/intermediary/intermediary.service';
import { OplTransportsService } from '../../../../services/src/lib/endpoint/opl-transports/opl-transports.service';
import {OplTransportsModel} from '../../../../services/src/models/endpoints/opl-transports.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Sort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { PaginatorComponent } from '../../components/paginator/paginator.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PackagesComponent } from '../packages/packages.component';
import { TagsInputOption } from '../../components/tags-input/models/tags-input-option.model';
import { saveAs } from "file-saver";
import * as moment from 'moment';
import { WarehousesService, WarehouseModel } from '@suite/services';

@Component({
  selector: 'suite-order-package',
  templateUrl: './order-package.component.html',
  styleUrls: ['./order-package.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrderPackageComponent implements OnInit {

  @Input('id') id: number;

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  filters: OplTransportsModel.OrderExpeditionFilters;

  displayedColumns: string[] = [
    'id',
    'warehouse',
    'date',
    'print',
    'package',
  ];
  pagerValues = [50, 100, 200];
  pauseListenFormChange: boolean;
  selection = new SelectionModel<any>(true, []);
  form: FormGroup = this.formBuilder.group({
    expeditions: [[]],
    orders: [[]],
    date: [[]],
    warehouses: [[]],
    transports: [[]],
    orderby: this.formBuilder.group({
      type: 1,
      order: "desc"
    }),
    pagination: this.formBuilder.group({
      page: 1,
      limit: this.pagerValues[0]
    })
  });

  orderByOrden: boolean;
  orderByWarehouse: boolean;
  orderByDate: boolean;

  columns: any;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  expandedElement: any;
  isFilteringOrder: number = 0;
  isFilteringWarehouse: number = 0;
  isFilteringDate: number = 0;
  lastUsedFilter: string;
  isApplyFilter:boolean = false;
  isApplyPagination:boolean = false;
  lastOrder = [true, true, true];

  orders: Array<TagsInputOption> = [];
  warehouses: Array<TagsInputOption> = [];
  dates: Array<TagsInputOption> = [];
  dataSource: OplTransportsModel.ResultsLoadOrdersWithManifestsAndPackage[];
  listwarehouses: Array<TagsInputOption> = [];
  filter : any [] = null;
  filterType: string;
  executed :boolean = false;
  listPackages: any = [];

  constructor(
    private intermediaryService: IntermediaryService,
    private oplTransportsService: OplTransportsService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private warehousesService: WarehousesService
  ) {}

  ngOnInit() {
    this.form.patchValue({
      transports: [this.id]
    })
    
    this.getFilters();
    this.getList(this.form.value);
    this.listenChanges();
  }

  getFilters() {
    this.oplTransportsService.getFilters().subscribe((resp: OplTransportsModel.OrderExpeditionFilters) => {
      this.filters = resp
    })
    this.warehousesService.getIndex().then(observable => {
      observable.subscribe(response => {
        this.listwarehouses = (<any>response.body).data;
        this.warehouses = this.listwarehouses;
      });
    });
  }
 
  async getList(body: OplTransportsModel.OrderExpeditionFilterRequest) {
    await this.intermediaryService.presentLoadingNew();
    this.oplTransportsService
      .postLoadOrdersWithManifestsAndPackages(body)
      .subscribe(async resp => {
        this.dataSource = resp.results;

        if (this.isApplyFilter == false) {
          this.orders = this.dataSource.map(elem => {
            return {
              id: elem.id,
              name: elem.id.toString()
            }
          });
          this.updateFilterOrders(this.orders);

          let whsTemp = this.warehouses.map(elem => {
            return {
              id: elem.id,
              name: `${elem.reference} - ${elem.name}`
            }
          });

          this.warehouses = await this.uniqueArray(whsTemp);
          this.updateFilterSourceWarehouses(this.warehouses);

          let datesTemp = this.dataSource.map(elem => {
            return {
              name: elem.dateGeneration
            }
          });
          this.dates = this.uniqueDatesArray(datesTemp);
          this.updateFilterDates(this.dates);
        }
        this.isApplyFilter = false;
        if (this.isApplyPagination == false) {
          const pagination = resp.pagination;
          this.paginator.length = pagination.totalResults;
          this.paginator.lastPage = pagination.lastPage;
        }
        this.isApplyPagination = false;
      }, () => this.intermediaryService.presentToastError('Ocurrio un error al cargar el listado'), () => this.intermediaryService.dismissLoadingNew());
  }

  clearFilters() {
    this.form = this.formBuilder.group({
      expeditions: [[]],
      orders: [[]],
      date: [[]],
      warehouses: [[]],
      transports: [[]],
      orderby: this.formBuilder.group({
        type: 1,
        order: "desc"
      }),
      pagination: this.formBuilder.group({
        page: 1,
        limit: this.pagerValues[0]
      })
    })
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.forEach(row => this.selection.select(row));
  }

  async sortData(event: Sort) {

    let type;
    if(event.active === "id"){
      type = 5;
    }else if(event.active === "warehouse"){
      type = 3;
    }else if(event.active === "date"){
      type = 1;
    }

    this.form.value.orderby.order = event.direction !== '' ? event.direction : 'desc';
    this.form.value.orderby.type = event.direction !== '' ? type : 1;
    this.warehouses = this.listwarehouses;
    this.getList(this.form.value);
  }

  listenChanges() {
    let previousPageSize = this.form.value.pagination.limit;
    /**detect changes in the paginator */
    this.paginator.page.subscribe(async page => {
      /**true if only change the number of results */
      let flag = previousPageSize === page.pageSize;
      previousPageSize = page.pageSize;
      this.form.value.pagination = {
        limit: page.pageSize,
        page: flag ? page.pageIndex : 1
      };
      this.isApplyPagination = true;
      this.getList(this.form.value)
      if(this.filter != null){
        await this.applyFilters(this.filter, this.filterType);
      }
      this.warehouses = this.listwarehouses;
    });  
  }

  async changePageIndex(){
        if (!this.executed) {
            this.executed = true;
            this.paginator.pageIndex = 1;
        }
  }

  async presentModal(packages) {
    
    let expeditionIds = [];
    packages.forEach(p => {
      expeditionIds.push(p.expeditionId);
    });
    let body = { expeditionIds: expeditionIds };
    await this.intermediaryService.presentLoadingNew();
    this.oplTransportsService
      .postLoadOrderManifestWithPackages(body)
      .subscribe(async resp => {
        this.listPackages = resp;

        let itemPackages = [];
        packages.forEach(p => {
          this.listPackages.forEach(item => {
            if(p.containerId == item.containerId){
              itemPackages.push(item);
            }
          });
        });

        packages = itemPackages;
        const modal = await this.modalController.create({
          component: PackagesComponent,
          componentProps: { packages }
        });

        await modal.present();
      }, () => {
        this.intermediaryService.presentToastError('Ocurrio un error al cargar el listado');
        this.intermediaryService.dismissLoadingNew();
      }, () => {
        this.intermediaryService.dismissLoadingNew();
      });
  }

  private updateFilterSourceWarehouses(warehouses: Array<any>) {
    this.pauseListenFormChange = true;
    let value = this.form.get("warehouses").value;
    this.warehouses = warehouses ? warehouses.map(warehouse => {
      warehouse.id = warehouse.id
      warehouse.name = warehouse.name;
      warehouse.value = warehouse.name;
      warehouse.checked = true;
      warehouse.hide = false;
      return warehouse;
    }): [];
    if (value && value.length) {
      this.form.get("warehouses").patchValue(value, { emitEvent: false });
    }
    this.form.get("pagination").patchValue({ page: 1}, { emitEvent: false });
    setTimeout(() => { this.pauseListenFormChange = false; }, 0);
  }
  private updateFilterOrders(orders: Array<any>) {

    this.pauseListenFormChange = true;
    let value = this.form.get("orders").value;
    this.orders = <any>orders.map(order => {
      order.id = order.id
      order.name = order.name;
      order.value = order.id;
      order.checked = true;
      order.hide = false;
      return order;
    });

    if (value && value.length) {
      this.form.get("orders").patchValue(value, { emitEvent: false });
    }
    setTimeout(() => { this.pauseListenFormChange = false; }, 0);
  }
  formatDate(data: string): string {
    const date = new Date(data);
    const day = date.getDate()
    let month: any = date.getMonth() + 1
    if (month < 10) {
      month = `0${month}`
    }
    const year = date.getFullYear()
    const formatedDate = `${day}-${month}-${year}`
    return formatedDate
  }
  private updateFilterDates(dates: Array<any>) {
    this.pauseListenFormChange = true;
    let value = this.form.get("date").value;
    this.dates = <any>dates.map(date => {
      date.id = date.name
      date.name = this.formatDate(date.name)
      date.value = date.name;
      date.checked = true;
      date.hide = false;
      return date;
    });

    if (value && value.length) {
      this.form.get("orders").patchValue(value, { emitEvent: false });
    }
    setTimeout(() => { this.pauseListenFormChange = false; }, 0);
  }

  async applyFilters(filtersResult, filterType) {
    this.filter = filtersResult;
    this.filterType = filterType;
    await this.changePageIndex();

    if(filtersResult){
      const filters = filtersResult.filters;
      switch (filterType) {
        case 'orders':
          let orderFiltered: number[] = [];
          for (let orders of filters) {
            if (orders.checked) orderFiltered.push(orders.id);
          }
          if (orderFiltered.length >= this.orders.length) {
            this.form.value.orders = [];
            this.isFilteringOrder = this.orders.length;
          } else {
            if (orderFiltered.length > 0) {
              this.form.value.orders = orderFiltered;
              this.isFilteringOrder = orderFiltered.length;
            } else {
              this.form.value.orders = [99999];
              this.isFilteringOrder = this.orders.length;
            }
          }
          break;
        case 'warehouses':
          let warehousesFiltered: number[] = [];
          for (let warehouse of filters) {
            if (warehouse.checked) warehousesFiltered.push(warehouse.id);
          }
          if (warehousesFiltered.length >= this.warehouses.length) {
            this.form.value.warehouses = [];
            this.isFilteringWarehouse = this.warehouses.length;
          } else {
            if (warehousesFiltered.length > 0) {
              this.form.value.warehouses = warehousesFiltered;
              this.isFilteringWarehouse = warehousesFiltered.length;
            } else {
              this.form.value.warehouses = [99999];
              this.isFilteringWarehouse = this.warehouses.length;
            }
          }
          break;
        case 'dates':
          let datesFiltered: string[] = [];

          for (let date of filters) {
          let data = moment(date.id).format('YYYY-MM-DD');
            if (date.checked) datesFiltered.push(data);
          }
          if (datesFiltered.length >= this.dates.length) {
            this.form.value.date = [];
            this.isFilteringDate = this.dates.length;
          } else {
            if (datesFiltered.length > 0) {
              this.form.value.date = datesFiltered;
              this.isFilteringDate = datesFiltered.length;
            } else {
              this.form.value.date = ["2090-11-03"];
              this.isFilteringDate = this.dates.length;
            }
          }
          break;
      }
    
    this.lastUsedFilter = filterType;
    this.isApplyFilter = true;
    this.getList(this.form.value)
    }
    else{
      this.isApplyFilter = false;
      this.getList(this.form.value)
    }
  }

  async print(id) {
    await this.intermediaryService.presentLoadingNew('Comprobando información del manifiesto...');

    this.oplTransportsService
      .print(id)
      .subscribe(async _ => {
        await this.intermediaryService.dismissLoadingNew();
        await this.intermediaryService.presentLoadingNew('Reimprimiendo manifiesto...');

        this.oplTransportsService
          .downloadPdfTransortOrders(id)
          .subscribe(async (resp) => {
            await this.intermediaryService.dismissLoadingNew();
            const blob = new Blob([resp], {type: 'application/pdf'});
            saveAs(blob, 'documento.pdf')
          }, async _ => {
            await this.intermediaryService.dismissLoadingNew();
            await this.intermediaryService.presentToastError('Ha ocurrido un error en la comprobación de los datos del manifiesto y no se ha podido reimprimir.');
          }, async () => await this.intermediaryService.dismissLoadingNew());
      }, async _ => {
        await this.intermediaryService.dismissLoadingNew();
        await this.intermediaryService.presentToastError('Ha ocurrido un error en la comprobación de los datos del manifiesto y no se ha podido reimprimir.');
      });
  }

  refresh(transportId: number) {
    this.clearFilters()
    this.form.patchValue({
      transports: [transportId]
    })
    this.warehouses = this.listwarehouses;
    this.getList(this.form.value)
    this.filter = null
    this.filterType = '';
    this.executed = false;
    this.paginator.pageIndex = 1;
  }

  async uniqueArray(listArray: Array<any>) {
    let uniquesArray = [];
    let counting = 0;
    let found = false;

    for (let i = 0; i < listArray.length; i++) {
      for (let y = 0; y < uniquesArray.length; y++) {
        if (listArray[i].id == uniquesArray[y].id) {
          found = true;
        }
      }
      counting++;
      if (counting == 1 && found == false) {
        uniquesArray.push(listArray[i]);
      }
      found = false;
      counting = 0;
    }
    return uniquesArray;
  }

  uniqueDatesArray(listArray: Array<any>) {
    let uniquesArray = [];
    let counting = 0;
    let found = false;

    for (let i = 0; i < listArray.length; i++) {
      for (let y = 0; y < uniquesArray.length; y++) {
        if (listArray[i].name == uniquesArray[y].name) {
          found = true;
        }
      }
      counting++;
      if (counting == 1 && found == false) {
          uniquesArray.push(listArray[i]);
      }
      found = false;
      counting = 0;
    }
    return uniquesArray;
  }
}

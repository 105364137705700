import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ModalController, NavParams} from "@ionic/angular";
import { IntermediaryService } from '../../../../services/src/lib/endpoint/intermediary/intermediary.service';
import {HiringCorreosExpressService} from "../../../../services/src/lib/endpoint/hiring-correosexpress/hiring-correosexpress.service";
import { UtilsComponent } from '../../components/utils/utils.component';

@Component({
  selector: 'suite-update-hiring-correosexpress',
  templateUrl: './update-hiring-correosexpress.component.html',
  styleUrls: ['./update-hiring-correosexpress.component.scss']
})
export class UpdateHiringCorreosExpressComponent implements OnInit {
  title = 'Editar Contratación de Correos Express';
  redirectTo = '/update-hiring-correosexpress';
  elementHiring;
  referenceWarehouseSelected;
  nameWarehouseSelected;
  userSelected;
  passwordSelected;
  solicitanteSelected;
  codRteSelected;

  @ViewChild(UtilsComponent) utils: UtilsComponent;
  updateForm: FormGroup = this.formBuilder.group({
    reference: [''],
    name: [''],
    user: [''],
    password: [''],
    solicitante: [''],
    codRte: [''],
  });

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private intermediaryService: IntermediaryService,
    private formBuilder: FormBuilder,
    private hiringCorreosExpressService: HiringCorreosExpressService,
  ) {}

  ngOnInit() {
    this.elementHiring = this.navParams.get('element');
    this.referenceWarehouseSelected = this.elementHiring.warehouseReference;
    this.nameWarehouseSelected = this.elementHiring.nameWarehouse;
    this.userSelected = this.elementHiring.user;
    this.passwordSelected = this.elementHiring.password;
    this.solicitanteSelected = this.elementHiring.solicitante;
    this.codRteSelected = this.elementHiring.codRte;
  }

  updateWarehouseCE(){
    let dataBody = {
      "id": this.elementHiring.idWarehouseCE,
      "warehouseReference": this.elementHiring.warehouseReference,
      "user": this.userSelected,
      "password": this.passwordSelected,
      "solicitante": this.solicitanteSelected,
      "codRte": this.codRteSelected,
    };

    if(dataBody.user == "" && dataBody.password == "" && dataBody.solicitante == "" && dataBody.codRte == ""){
      this.intermediaryService.presentConfirm("¿Está seguro que quiere eliminar los datos?",()=>{
        this.hiringCorreosExpressService.deleteWarehouseCE(dataBody.id).subscribe(() => {
          this.intermediaryService.presentToastSuccess("Datos eliminados con éxito");
          this.modalController.dismiss(null);
        }, error => {
          this.intermediaryService.presentToastError("Error al eliminar los datos");
          this.modalController.dismiss(null);
        });
      });
    }else{
      this.hiringCorreosExpressService.updateWarehouseCE(dataBody).subscribe(() => {
        this.intermediaryService.presentToastSuccess("Datos guardados con éxito");
        this.modalController.dismiss(null);
      }, error => {
        this.intermediaryService.presentToastError("Error al guardar los datos");
        this.modalController.dismiss(null);
      });
    }
  }

  close():void{
    this.modalController.dismiss();
  }

  cleanInputs(){
    this.userSelected = '';
    this.passwordSelected = '';
    this.solicitanteSelected = '';
    this.codRteSelected = '';
  }
}

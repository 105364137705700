import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'workwave-list-workwaves-history',
  templateUrl: './list-workwave.component.html',
  styleUrls: ['./list-workwave.component.scss']
})
export class WorkwaveListWorkwavesHistoryComponent implements OnInit {

  @Input() workwaveHistory: any;
  checked: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {
  }

  processTitleWorkwave() : string {
    if(this.workwaveHistory.warehouses.length > 0){
      return this.workwaveHistory.warehouses.map((warehouse) => {
        return warehouse.warehouse.reference+' '+warehouse.warehouse.name;
      }).join(', ');
    } else {
      return 'Online/Peticiones'
    }
  }

  dateCreatedParsed() : string {
    moment.locale('es');
    return moment(this.workwaveHistory.executedDate || this.workwaveHistory.releaseDate).format('ddd, DD/MM/YYYY');
  }

  timeCreatedParsed() : string {
    moment.locale('es');
    return moment(this.workwaveHistory.executedDate || this.workwaveHistory.releaseDate).format('LT');
  }

  getUser() : string {
    return this.workwaveHistory.user.name;
  }

  getTypeShippingOrderString(line: number) : string {
    let type = this.workwaveHistory.typeShippingOrder;
    if(!type){
      return "";
    } else if(type == 6){
      return "";
    }
    if (line == 1) {
      if (type == 1) {
        return "Reposición";
      } else if (type == 2) {
        return "Distribución";
      } else {
        return "Reposición";
      }
    } else {
      if (type == 1 || type == 2) {
        return "";
      } else {
        return "Distribución";
      }
    }
  }

  showPickings() {
    this.router.navigate(['workwaves-history/detail', this.workwaveHistory.id]);
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'suite-store-priority',
  templateUrl: './store-priority.component.html',
  styleUrls: ['./store-priority.component.scss']
})
export class StorePriorityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

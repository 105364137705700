import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService, environment } from '@suite/services';
import { RackModel } from '../../../models/endpoints/rack.model';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {RequestsProvider} from "../../../providers/requests/requests.provider"
import FilterOptionsResponse = RackModel.FilterOptionsResponse;
import SearchParameters = RackModel.SearchParameters;
import SearchResponse = RackModel.SearchResponse;

@Injectable({
  providedIn: 'root'
})
export class RackService {
  private rackUrl:string = environment.apiSorter+"/sorter/racks";
  private rackFilteredUrl:string = environment.apiSorter+"/sorter/racks/filtered";
  private getFilterOptionsUrl:string = environment.apiSorter+"/sorter/racks/filters";
  constructor(
    private http:HttpClient,
    private auth: AuthenticationService,
    private requestsProvider: RequestsProvider
  ) { }

  form: FormGroup = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required, Validators.minLength(5)]),
    reference: new FormControl('', [Validators.required, Validators.pattern('^E[0-9]{4}')]),
    warehouseReference: new FormControl('', Validators.required)
  });

  initializeFormGroup() {
    this.form.setValue({
      id: '',
      name: '',
      reference: '',
      warehouseReference: ''
    });
  }

  populateForm(rack: RackModel.Rack) {
    this.form.setValue({
      id: rack.sorterRack_id,
      name: rack.sorterRack_name,
      reference: rack.sorterRack_reference,
      warehouseReference: rack.warehouse_reference
    });
  }

  async getIndex(): Promise<Observable<HttpResponse<RackModel.ResponseIndex>>> {
    const currentToken = await this.auth.getCurrentToken();
    const headers = new HttpHeaders({ Authorization: currentToken });

    return this.http.get<RackModel.ResponseIndex>(this.rackUrl, {
      headers,
      observe: 'response'
    });
  }

  async getIndexWithFilters(params: SearchParameters): Promise<SearchResponse> {
    return this.requestsProvider.post(this.rackFilteredUrl, params);
  }

  async getFilterOptions(): Promise<FilterOptionsResponse> {
    return this.requestsProvider.get(this.getFilterOptionsUrl);
  }

  delete(id: number):Observable<any>{
    return this.http.delete(`${this.rackUrl}/${id}`);
  }

  store(rack: RackModel.Rack):Observable<RackModel.Rack> {
    return this.http.post<RackModel.SingleRackResponse>(this.rackUrl,rack).pipe(map(response=>{
      return response.data;
    }));
  }

  update(id: number, rack: RackModel.Rack) {
    return this.http.put<RackModel.SingleRackResponse>(`${this.rackUrl}/${id}`, rack).pipe(map((response) =>{
      return response.data;
    }));
  }
}

import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BarcodeScannerService } from 'libs/services/src/lib/barcode-scanner/barcode-scanner.service';

//CA_PIKCER STORES COMPONENT
@Component({
  selector: 'suite-picking-stores',
  templateUrl: './picking-stores.component.html',
  styleUrls: ['./picking-stores.component.scss']
})
export class PickingStoresComponent implements OnInit {
  constructor(
    private barcodeScannerService: BarcodeScannerService,
  ) { }

  //The scan resutl is an object result={cancelled, format, text} so in the text is the reference ID
  async scanBarcode(action) {
    var scanResult = await (await this.barcodeScannerService.useScanner(action));
    //console.log('var scanResult-->', scanResult);
    //this.getExtraProductInfo(scanResult);
  }

  ngOnInit() {
  }



}

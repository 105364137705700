import {Component, OnInit, ViewChild} from '@angular/core';
import {PaginatorComponent} from "../components/paginator/paginator.component";
import {HiringSeurModel} from "../../../services/src/models/endpoints/HiringSeur";
import Filters = HiringSeurModel.Filters;
import FilterOptions = HiringSeurModel.FilterOptions;
import FilterOptionsResponse = HiringSeurModel.FilterOptionsResponse;
import Order = HiringSeurModel.Order;
import Pagination = HiringSeurModel.Pagination;
import SearchParameters = HiringSeurModel.SearchParameters;
import {NavigationEnd, Router} from "@angular/router";
import {HiringSeurService} from "../../../services/src/lib/endpoint/hiring-seur/hiring-seur.service";
import { IntermediaryService } from '../../../services/src/lib/endpoint/intermediary/intermediary.service';
import {DateTimeParserService} from "../../../services/src/lib/date-time-parser/date-time-parser.service";
import {MatTableDataSource} from "@angular/material/table";
import {ModalController} from "@ionic/angular";
import {UpdateHiringSeurComponent} from "./update-hiring-seur/update-hiring-seur.component";

@Component({
  selector: 'suite-hiring-seur',
  templateUrl: './hiring-seur.component.html',
  styleUrls: ['./hiring-seur.component.scss']
})
export class HiringSeurComponent implements OnInit {

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  dataSource;
  public displayedColumns: string[] = ['warehouseReference', 'nameWarehouse', 'userSeur', 'passSeur', 'dni', 'cit', 'ccc', 'cccDPD', 'ccn', 'franquicia'];

  dataTable = [];
  hiring;
  filters: Filters = {
    warehouseReference: [],
    nameWarehouse: [],
    userSeur: [],
    passSeur: [],
    dni: [],
    cit: [],
    ccc: [],
    cccDPD: [],
    ccn: [],
    franquicia: []
  };

  filterOptions: FilterOptions  = {
    warehouseReference: [],
    nameWarehouse: [],
    userSeur: [],
    passSeur: [],
    dni: [],
    cit: [],
    ccc: [],
    cccDPD: [],
    ccn: [],
    franquicia: []
  };
  order: Order = {
    field: 'warehouseReference',
    direction: 'ASC'
  };
  pagination: Pagination = {
    limit: undefined,
    page: 1
  };

  constructor(
    public router: Router,
    private hiringSeurService: HiringSeurService,
    private intermediaryService: IntermediaryService,
    private dateTimeParserService: DateTimeParserService,
    private modalCtrl: ModalController,
  ) {
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd && val && val.url == '/hiring-seur'){
        if(typeof this.hiring !== 'undefined'){
          this.reset();
        }
      }
    });
  }

  ngOnInit() {
    this.loadFilters();
    this.dataSource = new MatTableDataSource();
    this.paginator.page.subscribe(paginator => {
      if (this.pagination.limit != paginator.pageSize) {
        this.pagination.page = 1;
      } else {
        this.pagination.page = paginator.pageIndex;
      }
      this.pagination.limit = paginator.pageSize;
      this.loadHiring();
    });
  }

  ngAfterViewInit(){
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadHiring();
  }

  loadFilters(){
    this.hiringSeurService.getFilterOptions().then((response: FilterOptionsResponse) => {
      this.hiringSeurService.getFilterOptionsSeur().then((responseSeur: FilterOptionsResponse) => {
        if(response.code == 200){
          this.filterOptions = {
            warehouseReference: response.data.warehouseReference.map((data, index) => {
              const warehouseReference1 = JSON.stringify(data);
              const warehouseReference = JSON.parse(warehouseReference1);
              return {
                id: warehouseReference.id,
                value: warehouseReference.warehouseReference,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            nameWarehouse: response.data.nameWarehouse.map((data, index) => {
              const nameWarehouse1 = JSON.stringify(data);
              const nameWarehouse = JSON.parse(nameWarehouse1);
              return {
                id: nameWarehouse.id,
                value: nameWarehouse.nameWarehouse,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            userSeur: responseSeur.data.userSeur.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            passSeur: responseSeur.data.passSeur.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            dni: responseSeur.data.dni.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            cit: responseSeur.data.cit.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            ccc: responseSeur.data.ccc.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            cccDPD: responseSeur.data.cccDPD.map((data, index) => {
                return {
                  id: '',
                  value: data,
                  checked: true,
                  hide: index > 20 ? true : false
                }
            }),
            ccn: responseSeur.data.ccn.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            franquicia: responseSeur.data.franquicia.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            })
          };
        }else{
          console.error(response);
        }
      }).catch(console.error);
    }).catch(console.error);
  }

  async loadHiring(){
    const parameters: SearchParameters = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };

    this.dataTable = [];
    await this.hiringSeurService.getAllWarehouses(parameters).subscribe(async data => {
      let dataWarehouses = data.result;
      await this.hiringSeurService.getAllWarehousesSeur(parameters).subscribe(async data => {
        let dataSeur = data.result;

        if(this.filters.userSeur.length > 0 || this.filters.passSeur.length > 0 || this.filters.dni.length > 0 ||
          this.filters.cit.length > 0 || this.filters.ccc.length > 0 || this.filters.cccDPD.length > 0|| this.filters.ccn.length > 0 || this.filters.franquicia.length > 0){
          dataWarehouses.forEach(async (dataWarehouse) => {
            dataSeur.forEach(async (dataS) => {
              if (parseInt(dataWarehouse.reference) == dataS.warehouseReference) {
                this.dataTable.push({
                  warehouseReference: parseInt(dataWarehouse.reference),
                  nameWarehouse: dataWarehouse.name,
                  idWarehouseSeur: dataS.id,
                  userSeur: dataS.seur_sws_user,
                  passSeur: dataS.seur_sws_pass,
                  dni: dataS.seur_sws_dni,
                  cit: dataS.seur_sws_cit,
                  ccc: dataS.seur_sws_ccc,
                  cccDPD: dataS.seur_sws_ccc_DPD,
                  ccn: dataS.seur_sws_ccn,
                  franquicia: dataS.seur_sws_franquicia
                });
              }
            });
          });
        }else{
          dataWarehouses.forEach(async (dataWarehouse) => {
            this.dataTable.push({
              warehouseReference: parseInt(dataWarehouse.reference),
              nameWarehouse: dataWarehouse.name,
              userSeur: null,
              passSeur: null,
              dni: null,
              cit: null,
              ccc: null,
              cccDPD: null,
              ccn: null,
              franquicia: null
            });
            dataSeur.forEach(async (dataS) => {
              if(parseInt(dataWarehouse.reference) == dataS.warehouseReference){
                for(let i = 0; i < this.dataTable.length; i++){
                  if(parseInt(dataWarehouse.reference) == this.dataTable[i].warehouseReference && dataS.warehouseReference == this.dataTable[i].warehouseReference){
                    this.dataTable.splice(i, 1, {
                      warehouseReference: parseInt(dataWarehouse.reference),
                      nameWarehouse: dataWarehouse.name,
                      idWarehouseSeur: dataS.id,
                      userSeur: dataS.seur_sws_user,
                      passSeur: dataS.seur_sws_pass,
                      dni: dataS.seur_sws_dni,
                      cit: dataS.seur_sws_cit,
                      ccc: dataS.seur_sws_ccc,
                      cccDPD: dataS.seur_sws_ccc_DPD,
                      ccn: dataS.seur_sws_ccn,
                      franquicia: dataS.seur_sws_franquicia
                    });
                  }
                }
              }
            });
          });
        }

        if(this.order.field == 'userSeur' || this.order.field == 'passSeur' || this.order.field == 'dni' || this.order.field == 'cit' ||
          this.order.field == 'ccc' ||  this.order.field == 'cccDPD' || this.order.field == 'ccn' || this.order.field == 'franquicia'){
          this.dataSource = new MatTableDataSource(this.orderItemsSeur(this.dataTable, this.order.field, this.order.direction));
        }else{
          this.dataSource = new MatTableDataSource(this.dataTable);
        }
      });
      const paginator = data.pagination;
      this.paginator.cantSelect = paginator.limit;
      this.paginator.length = paginator.totalResults;
      this.paginator.pageIndex = paginator.selectPage;
      this.paginator.lastPage = paginator.lastPage;
    });
  }

  async openModalWarehouse(event, element) {
    event.stopPropagation();
    event.preventDefault();
    let modal = (await this.modalCtrl.create({
      component: UpdateHiringSeurComponent,
      componentProps: {
        element
      }
    }));
    modal.onDidDismiss().then((data) => {
      this.loadHiring();
    });
    modal.present();
  }


  reset(){
    this.filters = {
      warehouseReference: [],
      nameWarehouse: [],
      userSeur: [],
      passSeur: [],
      dni: [],
      cit: [],
      ccc: [],
      cccDPD: [],
      ccn: [],
      franquicia: []
    };
    this.order = {
      field: 'warehouseReference',
      direction: 'ASC'
    };
    this.pagination = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 1;
    this.loadFilters();
    this.loadHiring();
  }

  orderBy(column: string){
    if(this.order.field == column){
      this.order.direction == 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.loadHiring();
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        if(column == 'warehouseReference'){
          values.push(item.id);
        }else{
          values.push(item.value);
        }
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.pagination.page = 1;
    this.loadHiring();
  }

  orderItemsSeur(items, field, direction){
    let data;
    switch(field){
      case 'userSeur':
        data = data = items.sort(function (a, b) {
          if (a.userSeur > b.userSeur) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.userSeur < b.userSeur) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
      case 'passSeur':
        data = items.sort(function (a, b) {
          if (a.passSeur > b.passSeur) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.passSeur < b.passSeur) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
      case 'dni':
        data = items.sort(function (a, b) {
          if (a.dni > b.dni) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.dni < b.dni) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
      case 'cit':
        data = items.sort(function (a, b) {
          if (a.cit > b.cit) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.cit < b.cit) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
      case 'ccc':
        data = items.sort(function (a, b) {
          if (a.ccc > b.ccc) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.ccc < b.ccc) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
      case 'cccDPD':
          data = items.sort(function (a, b) {
            if (a.cccDPD > b.cccDPD) {
              return direction == 'ASC' ?  1 :  -1;
            }
            if (a.cccDPD < b.cccDPD) {
              return direction == 'ASC' ?  -1 :  1;
            }
            return 0;
          });
          break;
      case 'ccn':
        data = items.sort(function (a, b) {
          if (a.ccn > b.ccn) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.ccn < b.ccn) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
      case 'franquicia':
        data = items.sort(function (a, b) {
          if (a.franquicia > b.franquicia) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.franquicia < b.franquicia) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
    }
    return data;
  }

}



import {Component, Input, OnInit} from '@angular/core';
import {DateTimeParserService} from "../../../../services/src/lib/date-time-parser/date-time-parser.service";
import {PackagesComponent} from "../packages/packages.component";
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'received-product-template',
  templateUrl: './list-items.component.html',
  styleUrls: ['./list-items.component.scss']
})
export class ReceivedProductTemplateComponent implements OnInit {

  @Input() element: any;
  @Input() selectedFormControl: any;

  constructor(
    private dateTimeParserService: DateTimeParserService,
    private modalController: ModalController,
  ) {}

  ngOnInit() {

  }

  preparationDateParsed(element) : string {
    return this.dateTimeParserService.dateTimeSort(element.trackingPackage.createdAt);
  }

  async presentModal(packages) {

    const modal = await this.modalController.create({
      component: PackagesComponent,
      componentProps: { packages }
    });

    await modal.present();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule, Routes } from "@angular/router";
import { HiringSeurComponent } from './hiring-seur.component';
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {FabExtendedModule} from "../components/button/fab/fab-extended/fab-extended.module";
import {PaginatorComponentModule} from "../components/paginator/paginator.component.module";
import { FilterButtonModule } from "../components/filter-button/filter-button.module";
import { MatPaginatorModule, MatTooltipModule, MatTableModule } from "@angular/material";
import {UpdateHiringSeurComponent} from "./update-hiring-seur/update-hiring-seur.component";

const routes: Routes = [
  {
    path: '',
    component: HiringSeurComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    FormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FabExtendedModule,
    PaginatorComponentModule,
    FilterButtonModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatTableModule,
  ],
  declarations: [HiringSeurComponent, UpdateHiringSeurComponent],
  entryComponents: [
    UpdateHiringSeurComponent
  ]
})

export class HiringSeurModule { }

import {Component, EventEmitter, OnInit} from '@angular/core';
import {IncidencesService} from "../../../services/src/lib/endpoint/incidences/incidences.service";
import {PopoverController} from "@ionic/angular";
import {IncidenceModel} from "../../../services/src/models/endpoints/Incidence";
import TypeFilters = IncidenceModel.TypeFilters;
import Incidence = IncidenceModel.Incidence;

@Component({
  selector: 'suite-ui-crud-incidences-popover',
  templateUrl: './incidences-popover.component.html',
  styleUrls: ['./incidences-popover.component.scss']
})
export class IncidencesPopoverComponent implements OnInit {

  incidentsEmitter : EventEmitter<Incidence[]>;
  incidences : Incidence[] = [];

  constructor(
    private popoverCtrl: PopoverController
  ) {}

  ngOnInit() {
    this.incidentsEmitter.subscribe(data =>{
      this.incidences = data;
    });
  }

  seeMoreIncidences() {
    this.popoverCtrl.dismiss({showMore: true});
  }

}

import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {PrinterServiceModel} from "../../../models/endpoints/PrinterService";
import {SettingsService} from "../../storage/settings/settings.service";
import {conditionallyCreateMapObjectLiteral} from "@angular/compiler/src/render3/view/util";

@Injectable({
  providedIn: 'root'
})
export class PrinterServiceService {

  private printServiceAddress: string = null;

  private postPrintUrl = '/api/print';

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {}

  public async getPrinterServiceAddress() {
    const address = await this.settingsService.getDeviceSetting(SettingsService.STORAGE_KEY_PRINTER_SERVICE_ADDRESS);
    this.printServiceAddress = address || SettingsService.DEFAULT_PRINTER_SERVICE_ADDRESS;
  }

  postPrintBase64(parameters: PrinterServiceModel.ParamsPrintBase64): Observable<any> {
    console.debug('PrinterService::Print base64 document: ', JSON.stringify({url: this.postPrintUrl, body: parameters}));
    return this.http.post(this.getCompleteUrl(this.postPrintUrl), parameters);
  }

  postPrint(parameters: PrinterServiceModel.ParamsPrint): Observable<any> {
    console.debug('PrinterService::Print document: ', JSON.stringify({url: this.postPrintUrl, body: parameters}));
    return this.http.post(this.getCompleteUrl(this.postPrintUrl), parameters);
  }

  printTest(ip: string, parameters: PrinterServiceModel.ParamsPrint): Observable<any> {
    const url = 'http://' + ip + this.postPrintUrl;
    console.debug('PrinterService::Print document: ', JSON.stringify({url: this.postPrintUrl, body: parameters}));
    return this.http.post(url, parameters);
  }

  private getCompleteUrl(partialUrl: string) {
    return 'http://' + this.printServiceAddress + partialUrl;
  }
}

import {Events, ModalController} from '@ionic/angular';
import {Observable, Subscription} from 'rxjs';
import {
  ReceptionsAvelonService,
  ReceptionAvelonModel,
  IntermediaryService,
  ProductsService,
  environment,
  ProductModel
} from '@suite/services';
import {Component, OnInit, OnDestroy, ViewChild, AfterContentInit, ChangeDetectorRef, ElementRef, AfterViewInit} from '@angular/core';
import { Type } from './enums/type.enum';
import { VirtualKeyboardService } from '../components/virtual-keyboard/virtual-keyboard.service';
import { Reception } from './classes/reception.class';
import { ListsComponent } from './components/lists/lists.component';
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {InfoModalComponent} from "./info-modal/info-modal.component";
import {PositionsToast} from "../../../services/src/models/positionsToast.type";
import {ScreenResult} from "./enums/screen_result.enum";
import {FormHeaderReceptionComponent} from "./components/form-header-reception/form-header-reception.component";
import {InfoHeaderReceptionComponent} from "./components/info-header-reception/info-header-reception.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {WebsocketService} from '../../../services/src/lib/endpoint/web-socket/websocket.service';
import {type} from '../../../services/src/lib/endpoint/web-socket/enums/typeData';
import {StateExpeditionAvelonService} from "../../../services/src/lib/endpoint/state-expedition-avelon/state-expedition-avelon.service";
import {StatesExpeditionAvelonProvider} from "../../../services/src/providers/states-expetion-avelon/states-expedition-avelon.provider";
import {TypeModelVisualization} from "./enums/model_visualization.enum";
import {PrinterService} from "../../../services/src/lib/printer/printer.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ReceptionOcrModel} from "../../../services/src/models/endpoints/ReceptionOcr";
import ReceptionOcr = ReceptionOcrModel.ReceptionOcr;
import OcrPositionConfirmation = ReceptionOcrModel.OcrPositionConfirmation;
import OcrRecognitionConfirmation = ReceptionOcrModel.OcrRecognitionConfirmation;
import OcrReceptionEnd = ReceptionOcrModel.OcrReceptionEnd;
import {AlertButton, AlertService} from "../../../services/src/lib/endpoint/alert/alert.service";
import OcrSnapshot = ReceptionOcrModel.OcrSnapshot;
import {CookieService} from "ngx-cookie-service";
import { environment as sga_environment } from '../../../../apps/sga/src/environments/environment';
import * as qz from 'qz-tray';
import { KJUR, KEYUTIL, stob64, hextorstr } from 'jsrsasign';
import OcrRecognitionLearningResponse = ReceptionOcrModel.OcrRecognitionLearningResponse;
import ResponseInfoReception = ProductModel.ResponseInfoReception;
import ResponseCheckEan = ProductModel.ResponseCheckEan;
import {BarcodeScannerComponent} from "./components/barcode-scanner/barcode-scanner.component";
import StoreOCR = ReceptionOcrModel.StoreOCR;
import SnapshotOCR = ReceptionOcrModel.SnapshotOCR;
import Data = ReceptionAvelonModel.Data;
import {SelectableListReceptionsComponent} from "./modals/selectable-list-receptions/selectable-list-receptions.component";

@Component({
  selector: 'suite-receptions-avelon',
  templateUrl: './receptions-avelon.component.html',
  styleUrls: ['./receptions-avelon.component.scss'],
  animations: [
    trigger('fadein', [
      state('out', style({ opacity: 0, display: 'none' })),
      transition('in => out', [
        style({ opacity: 1, display: 'flex' }),
        animate('300ms ease-out', style({ opacity: 0, display: 'none' }))
      ]),
      transition('out => in', [
        style({ opacity: 0, display: 'none' }),
        animate('1ms ease-out', style({ opacity: 1, display: 'flex' }))
      ])
    ])
  ]
})
export class ReceptionsAvelonComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {

  @ViewChild(ListsComponent) listsComponent:ListsComponent;
  @ViewChild('provider') providerInput: ElementRef;
  @ViewChild('expedition') expeditionInput: ElementRef;
  @ViewChild('ean') eanInput: ElementRef;
  @ViewChild(FormHeaderReceptionComponent) formHeaderReceptionComponent: FormHeaderReceptionComponent;
  @ViewChild(InfoHeaderReceptionComponent) infoHeaderReceptionComponent: InfoHeaderReceptionComponent;
  @ViewChild('brandList') brandList: ListsComponent;
  @ViewChild('modelList') modelList: ListsComponent;
  @ViewChild('colorList') colorList: ListsComponent;
  @ViewChild('barcodeScanner') barcodeScanner: BarcodeScannerComponent;

  REFERENCE_PRODUCT_REGEX_VALID = /^([0]{2})([\d]{6})([\d]{2})([\d]{3})([\d]{5})$/;
  EAN_13_VALID = /^([\d]{13})$/;
  UPC_A_VALID = /^([\d]{12})$/;

  public stateAnimationForm: string = 'in';
  public stateAnimationInfo: string = 'out';

  public expedit:string="";
  expeditionLines: {
    id: number,
    state: number,
    brandId: number,
    modelId: number,
    colorId: number
  }[];
  response: ReceptionAvelonModel.Reception;
  oldBrands: ReceptionAvelonModel.Data[] = [];
  subscriptions: Subscription[] = [];
  providers: Array<any>;
  isReceptionStarted: boolean;
  expedition: string;
  providerId: number;
  deliveryNote: string;
  interval: any;
  option: any;
  typeScreen: number;
  referencesToPrint: string[];
  filter;
  objectType = Type;
  filterData: ReceptionAvelonModel.Reception;
  result: ReceptionAvelonModel.Print = {
    reference: undefined,
    brandId: undefined,
    colorId: undefined,
    sizeId: undefined,
    modelId: undefined,
    providerId: undefined,
    expedition: '',
    ean: ''
  };
  oldEan = '';
  providersAux;
  value;
  myControl = new FormControl();
  filteredProviders: Observable<any[]>;
  showCheck: boolean = true;
  modelSelected: any = null;
  ocrPhoto: string = '../assets/img/placeholder-product.jpg';

  public listSizes: ReceptionAvelonModel.LoadSizesList[] = [];
  private expeditionStarted = null;

  isReceptionWithoutOrder: boolean = false;
  ocrReceived: boolean = false;
  lastReception: ReceptionOcr;
  internalModelChangeGoingOn: boolean = false;
  socketSubscription: boolean = false;
  positionId: number;
  learnedBrandIds: number[] = [];
  cameraBarcodes: {
    type: string,
    code: string
  }[] = [];
  snapshot: string = '';
  lastOCRReceptionFinished: boolean = true;

  public TypesModel = TypeModelVisualization;
  public typeModelVisualization: number = TypeModelVisualization.MODEL_NAME;
  public lastTypeModelVisualization: number = TypeModelVisualization.MODEL_NAME;

  constructor(
    private events: Events,
    private activatedRoute: ActivatedRoute,
    private reception: ReceptionsAvelonService,
    private productsService: ProductsService,
    private intermediaryService: IntermediaryService,
    private alertService: AlertService,
    private virtualKeyboardService: VirtualKeyboardService,
    private modalController: ModalController,
    private cd: ChangeDetectorRef,
    private websocketService : WebsocketService,
    private stateExpeditionAvelonService: StateExpeditionAvelonService,
    private stateExpeditionAvelonProvider: StatesExpeditionAvelonProvider,
    private cookieService: CookieService,
    private printerService: PrinterService
  ) {}

  ngOnInit() {
    this.typeModelVisualization = TypeModelVisualization.MODEL_NAME;
    this.isReceptionWithoutOrder = !!(this.activatedRoute.snapshot && this.activatedRoute.snapshot.routeConfig && this.activatedRoute.snapshot.routeConfig.path && this.activatedRoute.snapshot.routeConfig.path == 'free');

    this.intermediaryService.presentLoading('Cargando', () => {
      this.response = new Reception();
      this.filterData = new Reception();
      this.typeScreen = undefined;
      this.referencesToPrint = [];
      this.isReceptionStarted = false;
      this.deliveryNote = null;
      this.expeditionStarted = null;

      let subscriptionLoadData = this.reception.getAllProviders()
        .subscribe((data: Array<ReceptionAvelonModel.Providers>) => {
            this.providers = data;
            this.providersAux = data;
          },
          e => {
            this.intermediaryService.dismissLoading();
          },
          () => {
            this.filteredProviders = this.myControl.valueChanges
              .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this._filter(name) : this.providers.slice())
              );
            this.intermediaryService.dismissLoading();

            if (subscriptionLoadData) {
              subscriptionLoadData.unsubscribe();
              subscriptionLoadData = null;
            }
          }
        );
      this.subscriptions.push(subscriptionLoadData);
    });

    let subscriptionLoadStates = this.stateExpeditionAvelonService.getIndex().subscribe(response=>{
      this.stateExpeditionAvelonProvider.states = response;
      if (subscriptionLoadStates) {
        subscriptionLoadStates.unsubscribe();
        subscriptionLoadStates = null;
      }
    });
    this.subscriptions.push(subscriptionLoadStates);

    if(!this.socketSubscription){
      const subscription = this.websocketService.getEmitData().subscribe(async (data: any) => {
        console.log('The websocket has emitted data to this user:', data);
        if(this.lastOCRReceptionFinished){
          this.lastOCRReceptionFinished = false;
          if (this.typeScreen == undefined) {
            await this.resetAllAlternate();
          } else {
            await this.screenExit(true);
          }
          if (this.isReceptionStarted && data && data.receive && data.receive.queue && data.receive.queue == 'OCR_RECEPTION') {
            this.lastReception = data.receive.receptionOcr;
            if (this.lastReception.model && this.lastReception.model.multipleField) {
              if (this.lastReception.model.multipleField == "name") {
                if (this.typeModelVisualization != TypeModelVisualization.MODEL_NAME) {
                  this.internalModelChangeGoingOn = true;
                  this.typeModelVisualization = TypeModelVisualization.MODEL_NAME;
                  await this.intermediaryService.presentLoadingNew('Cargando modelos...');
                  await this.reception.postLoadModelTable({typeVisualization: this.typeModelVisualization, providerId: this.providerId}).then(async response => {
                    await this.intermediaryService.dismissLoadingNew();
                    this.filterData.models = response.data;
                    await this.resetAllAlternate();
                    this.lastTypeModelVisualization = this.typeModelVisualization;
                    this.internalModelChangeGoingOn = false;
                  });
                }
              }else{
                if (this.typeModelVisualization != TypeModelVisualization.PROVIDER_REFERENCE) {
                  this.internalModelChangeGoingOn = true;
                  this.typeModelVisualization = TypeModelVisualization.PROVIDER_REFERENCE;
                  await this.intermediaryService.presentLoadingNew('Cargando modelos...');
                  await this.reception.postLoadModelTable({typeVisualization: this.typeModelVisualization, providerId: this.providerId}).then(async response => {
                    await this.intermediaryService.dismissLoadingNew();
                    this.filterData.models = response.data;
                    await this.resetAllAlternate();
                    this.lastTypeModelVisualization = this.typeModelVisualization;
                    this.internalModelChangeGoingOn = false;
                  });
                }
              }
            }
            if (this.lastReception.photo && this.lastReception.photo != '') {
              this.ocrPhoto = this.lastReception.photo;
            }
            if (this.lastReception.ean && this.lastReception.ean != '') {
              this.result.ean = this.lastReception.ean;
              this.eanInput.nativeElement.value = this.lastReception.ean;
              this.ocrReceived = true;
              this.printProductsLoading();
            } else {
              let brandFound: boolean = false;
              if (this.lastReception.brand && this.result.brandId != this.lastReception.brand.id) {
                for (let brand of this.brandList.data) {
                  if (brand.id == this.lastReception.brand.id) {
                    brandFound = true;
                    this.brandList.fastSelect(brand);
                    break;
                  }
                }
              }
              if(!brandFound && this.lastReception.receivedBrand){
                for (let brand of this.brandList.data) {
                  if (brand.name.trim().toLowerCase() == this.lastReception.receivedBrand.trim().toLowerCase()) {
                    this.brandList.fastSelect(brand);
                    break;
                  }
                }
              }
              let modelFound: boolean = false;
              if (this.lastReception.model && this.result.modelId != this.lastReception.model.id && !this.lastReception.model.multipleField) {
                for (let model of this.modelList.data) {
                  if (model.available_ids.includes(this.lastReception.model.id)) {
                    modelFound = true;
                    this.modelList.fastSelect(model);
                    break;
                  }
                }
              } else {
                if (this.lastReception.model && this.lastReception.model.multipleField) {
                  if (this.lastReception.model.multipleField == "name") {
                    for (let model of this.modelList.data) {
                      if (model.name.toLowerCase().trim() == this.lastReception.model.name.toLowerCase().trim()) {
                        modelFound = true;
                        this.modelList.fastSelect(model);
                        break;
                      }
                    }
                  } else {
                    for (let model of this.modelList.data) {
                      if (model.name.toLowerCase().trim() == this.lastReception.model.supplierReference.toLowerCase().trim()) {
                        modelFound = true;
                        this.modelList.fastSelect(model);
                        break;
                      }
                    }
                  }
                }
              }
              if(!modelFound && this.lastReception.receivedModel){
                for (let model of this.modelList.data) {
                  if (model.name.trim().toLowerCase() == this.lastReception.receivedModel.trim().toLowerCase()) {
                    this.modelList.fastSelect(model);
                    break;
                  }
                }
              }
              let colorFound: boolean = false;
              if (this.lastReception.color && this.result.colorId != this.lastReception.color.id) {
                for (let color of this.colorList.data) {
                  if (color.id == this.lastReception.color.id) {
                    colorFound = true;
                    this.colorList.fastSelect(color);
                    break;
                  }
                }
              }
              if(!colorFound && this.lastReception.receivedColor){
                for (let color of this.colorList.data) {
                  if (color.name.trim().toLowerCase() == this.lastReception.receivedColor.trim().toLowerCase()) {
                    this.colorList.fastSelect(color);
                    break;
                  }
                }
              }
              setTimeout(()=>{
                let sizeFound: boolean = false;
                if (this.lastReception.size) {
                  for (let index in this.listSizes) {
                    if (this.listSizes[index].id == this.lastReception.size.id) {
                      sizeFound = true;
                      this.listSizes[index].quantity = 1;
                    } else {
                      this.listSizes[index].quantity = 0;
                    }
                  }
                }
                if(!sizeFound && this.lastReception.receivedSize){
                  for (let index in this.listSizes) {
                    if (this.listSizes[index].name.trim().toLowerCase() == this.lastReception.receivedSize.trim().toLowerCase()) {
                      this.listSizes[index].quantity = 1;
                    }
                  }
                }
                this.ocrReceived = true;
                if(!this.lastReception.corrected){
                  this.printProductsLoading();
                }else{
                  this.lastOCRReceptionFinished = true;
                }
              },1000);
            }
          }else{
            this.lastOCRReceptionFinished = true;
          }
        }
      });
      this.subscriptions.push(subscription);
      this.socketSubscription = true;
    }

  }

  sendOCRPositionConfirmation(){
    this.reception.getBrandsList().subscribe(async brands => {
      const cookiePositionId: string = this.cookieService.get('sga-ocr-positionId');
      if(cookiePositionId && cookiePositionId != ''){
        this.positionId = parseInt(cookiePositionId);
      }else{
        this.positionId = 1;
      }
      const ocrPositionConfirmation: OcrPositionConfirmation = {
        positionId: this.positionId,
        supplier: {
          id: this.infoHeaderReceptionComponent.provider.avelonId,
          name: this.infoHeaderReceptionComponent.provider.name,
          brands: brands.map(b=>{return {id:b.avelonId,name:b.name}})
        },
      };
      await this.reception.postOCRPositionConfirmation(ocrPositionConfirmation)
    }).unsubscribe();
  }

  receiveOCRRecognitionLearningBrands(avelonProviderId: number) {
    const subNext = (response: OcrRecognitionLearningResponse) => {
      if (response.code == 200) {
        this.learnedBrandIds = response.data && response.data.brands ? response.data.brands.map(b => b.id) : [];
        for (let brand of this.brandList.data) {
          brand.learned = this.learnedBrandIds.includes(brand.avelonId);
        }
      } else {
        console.error(response);
      }
    };
    const subError = (error) => {
      console.error(error);
    };
    const subComplete = () => {
      if (subscription){
        subscription.unsubscribe()
      }
    };

    const subscription = this.reception.postOCRRecognitionLearning({avelonProviderId: avelonProviderId}).subscribe(subNext, subError, subComplete);

    this.subscriptions.push(subscription);
  }

  async sendOCRRecognitionConfirmation(){
    if(this.lastReception.recognitionId){
      const ocrRecognitionConfirmation: OcrRecognitionConfirmation = {
        recognitionId: this.lastReception.recognitionId,
        ean: this.result.ean,
        brand: {
          id: this.result.brandId,
          name: this.result.brandId && this.brandList.data.filter(b=>b.id==this.result.brandId).length > 0 ? this.brandList.data.filter(b=>b.id==this.result.brandId)[0].name : null
        },
        model: {
          id: this.result.modelId,
          name: this.result.modelId && this.modelList.data.filter(m=>m.id==this.result.modelId).length > 0 ? this.modelList.data.filter(m=>m.id==this.result.modelId)[0].name : null
        },
        color:{
          id: this.result.colorId,
          name: this.result.colorId && this.colorList.data.filter(c=>c.id==this.result.colorId).length > 0 ? this.colorList.data.filter(c=>c.id==this.result.colorId)[0].name : null
        },
        size: {
          id: this.listSizes.filter(s=>s.quantity==1).length > 0 ? this.listSizes.filter(s=>s.quantity==1)[0].id : null,
          name: this.listSizes.filter(s=>s.quantity==1).length ? this.listSizes.filter(s=>s.quantity==1)[0].name : null
        }
      };
      await this.reception.postOCRRecognitionConfirmation(ocrRecognitionConfirmation);
      this.ocrReceived = false;
      this.lastReception.corrected = false;
    }
  }

  async sendOCRReceptionEnd(){
    const ocrReceptionEnd: OcrReceptionEnd = {
      positionId: this.positionId
    };
    await this.reception.postOCRReceptionEnd(ocrReceptionEnd)
  }

  async ngOnDestroy() {
    if (this.socketSubscription) {
      await this.sendOCRReceptionEnd();
    }
    for (let subscription of this.subscriptions) {
      try {
        if (subscription) {
          subscription.unsubscribe();
        }
      } catch (error) {
        console.error('Error to try unsubscribe of some observable');
      }
    }
    clearInterval(this.interval);
  }

  ngAfterContentInit() {
    this.cd.detectChanges()
  }

  ngAfterViewInit() {
    this.listSelected();
    this.clickSizeSelected();
  }

  async resetReceptionProcess() {
    this.eanInput.nativeElement.value = "";
    this.result.ean = null;
    this.listSizes = [];
    this.ocrPhoto = '../assets/img/placeholder-product.jpg';
    this.lastReception = null;

    this.stateAnimationForm = 'in';
    this.stateAnimationInfo = 'out';
    this.isReceptionStarted = false;
    if (this.socketSubscription) {
      await this.sendOCRReceptionEnd();
    }
    this.formHeaderReceptionComponent.resetProcess();
    this.infoHeaderReceptionComponent.loadInfoExpedition({packingsPallets: null, provider: null, expeditionReference: null, date: null, shipper: null, states: null});
    this.deliveryNote = null;
    this.expeditionStarted = null;

    this.typeModelVisualization = TypeModelVisualization.MODEL_NAME;
    this.lastTypeModelVisualization = this.typeModelVisualization;

    this.ngOnInit();
  }

  public changeDeliveryNote(deliveryNote) {
    this.deliveryNote = deliveryNote;
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.providers.filter(provider => provider.name.toLowerCase().indexOf(filterValue) === 0);
  }

  openVirtualKeyboard(list?: Array<ReceptionAvelonModel.Data>, type?: Type) {
    if (!this.virtualKeyboardService.aKeyboardIsOpen) {
      const dataList = [];

      if(list) {
        list.forEach(item => {
          dataList.push({id: item.id, value: item.name, color: item.color});
        });
      }

      let keyboardEventEmitterSubscribe = this.virtualKeyboardService.eventEmitter.subscribe(
        data => {
          let dato;
          if (data.selected) {
            switch (data.selected.type) {
              case Type.BRAND:
                dato = this.response.brands.find(elem => elem.id === data.selected.id);
                this.updateList(dato);
                break;
              case Type.COLOR:
                dato = this.response.colors.find(elem => elem.id === data.selected.id);
                this.updateList(dato);
                break;
              case Type.MODEL:
                dato = this.response.models.find(elem => elem.id === data.selected.id);
                this.updateList(dato);
                break;
              case Type.PROVIDER:
                this.providerInput.nativeElement.value = data.selected.id;
                break;
              case Type.EXPEDITION_NUMBER:
                this.expeditionInput.nativeElement.value = data.selected.id;
                break;
              case Type.EAN_CODE:
                this.eanInput.nativeElement.value = data.selected.id;
                this.result.ean = this.eanInput.nativeElement.value;
                this.sizeSelectedInSelector(null);
                break;
              case undefined:
                this.expedition = data.selected.id;
                break;
            }
          }
        }
      );
      this.subscriptions.push(keyboardEventEmitterSubscribe);
      const callbackDismissKeyboard = () => {
        if (keyboardEventEmitterSubscribe) {
          keyboardEventEmitterSubscribe.unsubscribe();
          keyboardEventEmitterSubscribe = null;
        }
      };
      this.virtualKeyboardService.openVirtualKeyboard({dataList, type}, callbackDismissKeyboard);
    }
  }

  async checkProvider(data: ReceptionAvelonModel.CheckProvider) {
    this.expedition = data.expedition;
    this.providerId = data.providerId;

    await this.intermediaryService.presentLoading('Cargando');
    let subscription = this.reception.getReceptions(data.providerId, this.typeModelVisualization).subscribe((info: ReceptionAvelonModel.Reception) => {
      this.response = info;
      this.expeditionLines = info.lines;
      this.response.brands = this.clearSelected(this.response.brands);
      this.response.models = this.clearSelected(this.response.models);
      this.response.colors = this.clearSelected(this.response.colors);
      this.filterData.brands = this.clearSelected(info.brands);
      this.filterData.models = this.clearSelected(info.models);
      this.filterData.colors = this.clearSelected(info.colors);

      this.reset();
      this.expedition = data.expedition;

    },error => {}, async () => {
      await this.intermediaryService.dismissLoading();
      if (subscription) {
        subscription.unsubscribe();
        subscription = null;
      }
      this.sendOCRPositionConfirmation();
      setTimeout(() => {
        this.receiveOCRRecognitionLearningBrands(data.providerAvelonId);
      }, 0);
    });
    this.subscriptions.push(subscription);
  }

  async alertMessage(message: string, callback?) {
    const alert = await this.alertService.createAlert({
      header: '¡Atención!',
      message: message,
      buttons: [{
        text: 'Aceptar',
        handler: callback
      }],
      cssClass: 'red-background'
    });
    await alert.present();
  }

  clickSizeSelected() {
    let subscription = this.reception.getEmitSizes().subscribe((e: any) => {
      if (e && e.dato) {
        if (e.dato.selected) {
          this.result.sizeId = e.dato.id;
        } else {
          this.result.sizeId = undefined;
        }
      } else {
        this.result.sizeId = undefined;
      }
    }, () => {}, () => {
      if (subscription) {
        subscription.unsubscribe();
        subscription = null;
      }
    });
    this.subscriptions.push(subscription);
  }

  updateList(dato) {
    let model = [];
    let brand = [];
    let size = [];
    let color = [];

    if (dato.belongsModels) {
      dato.belongsModels.forEach(modelId => {
        const modelsFilter = this.response.models.filter(model => !!model.available_ids.find(id => id == modelId));
        modelsFilter.forEach(m => {
          const modelFind = model.find(elem => elem.id == m.id);
          if(modelFind === undefined) {
            model.push(m)
          }
        });
        /***************************brands******************************/
        const brandsFilter = this.response.brands.filter(elem => {
          if(elem.belongsModels.find(elem => elem === modelId)){
            return elem
          }
        });
        brandsFilter.forEach(elem => {
          if(brand.find(data => data.id === elem.id) === undefined) {
            brand.push(elem)
          }
        });

        /*****************************color****************************/
        const colorFilter = this.response.colors.filter(elem => {
          if(elem.belongsModels.find(elem => elem === modelId)){
            return elem
          }
        });
        colorFilter.forEach(elem => {
          if(color.find(data => data.id === elem.id) === undefined) {
            color.push(elem)
          }
        })
      })
    } else {
      const modelsFilter = this.response.models.filter(model => model.id === dato.id);
      modelsFilter.forEach(m => {
        const modelFind = model.find(elem => elem.id == m.id);
        if (modelFind === undefined) {
          model.push(m)
        }
      });

      /***************************brands******************************/
      const brandsFilter = this.response.brands.filter(elem => !!elem.belongsModels.find(model => !!dato.available_ids.find(id => id == model)));
      brandsFilter.forEach(elem => {
        if (brand.find(data => data.id === elem.id) === undefined) {
          brand.push(elem)
        }
      });

      /*****************************color****************************/
      const colorFilter = this.response.colors.filter(elem => !!elem.belongsModels.find(model => !!dato.available_ids.find(id => id == model)));
      colorFilter.forEach(elem => {
        if (color.find(data => data.id === elem.id) === undefined) {
          color.push(elem)
        }
      })
    }

    if (model.length > 0) {
      this.response.models = model;
      this.reception.setModelsList(model);
      if (this.response.models.length == 1) {
        this.result.modelId = this.response.models[0].id;
        this.response.models[0].selected = true;
      }
    }
    if (brand.length > 0) {
      this.response.brands = brand;
      this.reception.setBrandsList(brand);
      if (this.response.brands.length == 1) {
        this.result.brandId = this.response.brands[0].id;
        this.response.brands[0].selected = true;
      }
    }
    if (color.length > 0) {
      this.response.colors = color;
      this.reception.setColorsList(color);
      if (this.response.colors.length == 1) {
        this.result.colorId = this.response.colors[0].id;
        this.response.colors[0].selected = true;

        if (this.response.models.length == 1) {
          let modelIdForColor = this.response.colors[0].belongsModels.find(m => !!this.response.models[0].available_ids.find(id => id == m));
          this.result.modelId = modelIdForColor || this.response.models[0].id;
        }
      }
    }

    if (this.result.modelId && this.result.colorId) {
      this.checkDuplicates();
    }
  }

  reset(dato?: ReceptionAvelonModel.Data) {
    this.response.models = this.filterData.models;
    this.response.colors = this.filterData.colors;
    this.response.brands = this.filterData.brands;
    this.reception.setModelsList(this.response.models);
    this.reception.setBrandsList(this.response.brands);
    this.reception.setColorsList(this.response.colors);
  }

  async resetAllAlternate() {
    await this.intermediaryService.presentLoadingNew('Recargando');

    if(this.filterData){
      this.response.models = this.filterData.models;
      this.response.models.map(elem => elem.selected = false);
      this.response.colors = this.filterData.colors;
      this.response.colors.map(elem => elem.selected = false);
      this.response.brands = this.filterData.brands;
      this.response.brands.map(elem => elem.selected = false);
    }

    this.result.modelId = undefined;
    this.result.brandId = undefined;
    this.result.sizeId = undefined;
    this.result.colorId = undefined;
    this.modelSelected = null;

    if(this.response){
      this.reception.setModelsList(this.response.models);
      this.reception.setBrandsList(this.response.brands);
      this.reception.setColorsList(this.response.colors);
    }

    this.expedit = this.expedition;
    this.result.ean = "";
    this.ocrPhoto = '../assets/img/placeholder-product.jpg';
    this.listSizes = [];

    await this.intermediaryService.dismissLoadingNew();
  }

  resetSizes(){
    for(let size of this.listSizes){
      size.quantity = 0;
    }
  }

  goBack(type: string) {
    switch (type) {
      case 'brands':
        if(this.oldBrands.length > 0) {
          this.response.brands = JSON.parse(JSON.stringify(this.oldBrands));
        }else{
          this.response.brands = this.filterData.brands;
        }
        for (let brand of this.response.brands) {
          brand.selected = false;
        }
        this.reception.setBrandsList(this.response.brands);
        break;
      case 'models':
        const models = this.filterData.models;
        if(this.result.colorId){
          this.response.models = [];
          const color = this.filterData.colors.find(c => c.id == this.result.colorId);
          for(let model of models){
            if(color.belongsModels.includes(model.id)){
              model.selected = false;
              this.response.models.push(model);
            }else{
              for(let modelId of model.available_ids){
                if(color.belongsModels.includes(modelId)){
                  model.selected = false;
                  this.response.models.push(model);
                  break;
                }
              }
            }
          }
        }else{
          for(let model of models) {
            model.selected = false;
          }
          this.response.models = models;
        }
        this.reception.setModelsList(this.response.models);
        break;
      case 'colors':
        const colors = this.filterData.colors;
        if(this.result.modelId){
          this.response.colors = [];
          const model = this.filterData.models.find(m => !!m.available_ids.find(id => id == this.result.modelId));
          for(let color of colors){
            if(color.belongsModels.includes(model.id)){
              color.selected = false;
              this.response.colors.push(color);
            }else{
              for(let modelId of model.available_ids){
                if(color.belongsModels.includes(modelId)){
                  color.selected = false;
                  this.response.colors.push(color);
                  break;
                }
              }
            }
          }
        }else{
          for(let color of colors) {
            color.selected = false;
          }
          this.response.colors = colors;
        }
        this.reception.setColorsList(this.response.colors);
    }

  }

  listSelected() {
    let subscription = this.reception.getEmitList().subscribe((event: any) => {
      switch (event.type) {
        case 'brands':
          if (event.dato.selected) {
            this.oldBrands = JSON.parse(JSON.stringify(this.response.brands));
            this.result.brandId = event.dato.id;
            setTimeout(() => {
              this.updateList(event.dato);
            }, 0);
          } else {
            this.result.brandId = undefined;
            this.goBack('brands');
            if(!this.result.modelId){
              this.goBack('models');
            }
            if(!this.result.colorId){
              this.goBack('colors');
            }
          }
          this.getModelAndColorColors(this.result.brandId);
          break;
        case 'models':
          if (event.dato.selected) {
            this.result.modelId = event.dato.id;
            this.modelSelected = event.dato;
            setTimeout(() => {
              this.updateList(event.dato);
            }, 0);
          } else {
            this.result.modelId = undefined;
            this.modelSelected = null;
            this.goBack('models');
            if (!this.result.brandId) {
              this.goBack('brands');
            }
            if (!this.result.colorId) {
              this.goBack('colors');
            }
          }
          this.getColorColors(this.result.modelId);
          break;
        case 'colors':
          if (event.dato.selected) {
            this.result.colorId = event.dato.id;
            if (this.modelSelected) {
              this.result.modelId = this.modelSelected.available_ids.find(id => event.dato.belongsModels.find(model => model == id));
            }
            setTimeout(() => {
              this.updateList(event.dato);
            }, 0);
          } else {
            this.result.colorId = undefined;
            this.goBack('colors');
            if (!this.result.brandId) {
              this.goBack('brands');
            }
            if (!this.result.modelId) {
              this.goBack('models');
            }

            if(this.result.brandId){
              if(this.result.modelId){
                this.getColorColors(this.result.modelId);
              }else{
                this.getModelAndColorColors(this.result.brandId);
              }
            }
          }
          break;
      }
    }, () => {}, () => {
      if (subscription) {
        subscription.unsubscribe();
        subscription = null;
      }
    });
    this.subscriptions.push(subscription);
  }

  getModelAndColorColors(brandId: number){
    let greenModels: number[] = [];
    let orangeModels: number[] = [];
    let greenColors: number[] = [];
    let orangeColors: number[] = [];
    for(let line of this.expeditionLines){
      if(!brandId || line.brandId == brandId){
        if(line.state == 2){
          if(!greenModels.includes(line.modelId)){
            greenModels.push(line.modelId);
          }
          if(!greenColors.includes(line.colorId)){
            greenColors.push(line.colorId);
          }
        }else{
          if(!orangeModels.includes(line.modelId)){
            orangeModels.push(line.modelId);
          }
          if(!orangeColors.includes(line.colorId)){
            orangeColors.push(line.colorId);
          }
        }
      }
    }
    orangeModels = orangeModels.filter(model => {return !greenModels.includes(model)});
    orangeColors = orangeColors.filter(color => {return !greenColors.includes(color)});
    for(let model of this.response.models){
      if(greenModels.includes(model.id)){
        model.color = 'green';
      }else{
        if(orangeModels.includes(model.id)){
          model.color = 'orange';
        }else{
          model.color = 'red';
        }
      }
    }
    for(let color of this.response.colors){
      if(greenColors.includes(color.id)){
        color.color = 'green';
      }else{
        if(orangeColors.includes(color.id)){
          color.color = 'orange';
        }else{
          color.color = 'red';
        }
      }
    }
    this.reception.setModelsList(this.response.models);
    this.reception.setColorsList(this.response.colors);
  }

  getColorColors(modelId: number){
    let sameNameModelIds: number[] = [];
    for(let model of this.response.models){
      if(model.available_ids.includes(modelId)){
        sameNameModelIds = model.available_ids;
        break;
      }
    }
    let greenColors: number[] = [];
    let orangeColors: number[] = [];
    for(let line of this.expeditionLines){
      if(!modelId || sameNameModelIds.includes(line.modelId)){
        if(line.state == 2 && !greenColors.includes(line.colorId)){
          greenColors.push(line.colorId);
        }else{
          if(line.state != 2 && !orangeColors.includes(line.colorId)){
            orangeColors.push(line.colorId);
          }
        }
      }
    }
    orangeColors = orangeColors.filter(color => {return !greenColors.includes(color)});
    for(let color of this.response.colors){
      if(greenColors.includes(color.id)){
        color.color = 'green';
      }else{
        if(orangeColors.includes(color.id)){
          color.color = 'orange';
        }else{
          color.color = 'red';
        }
      }
    }
    this.reception.setColorsList(this.response.colors);
  }

  public sizeSelectedInSelector(item: ReceptionAvelonModel.LoadSizesList) {
    for (let size of this.listSizes) {
      if ((item && size.id != item.id) || !item) {
        size.quantity = 0;
      }
    }
  }

  public removeEanCode(event) {
    event.preventDefault();
    event.stopPropagation();
    this.result.ean = null;
  }

  public printProductsLoading() {
    this.intermediaryService.presentLoading('Enviando').then(() => this.printProducts());
  }

  private async printProducts() {
    await this.intermediaryService.dismissLoading();
    if (!this.providerId || !this.expedition) {
      this.intermediaryService.presentToastError('No se detectan el proveedor y la expedición asignados. Pruebe a reiniciar el proceso. Si persiste el error contacte con su responsable.');
      this.lastOCRReceptionFinished = true;
    } else {
      const eanSet = this.eanInput.nativeElement.value;
      if (eanSet && eanSet != this.oldEan) {
        this.checkEanAndPrint(eanSet);
      } else {
        if (this.checkIfSelectMandatoryFields()) {
          this.notifyReceptionAndPrint();
        } else {
          this.intermediaryService.dismissLoading();
          this.lastOCRReceptionFinished = true;
        }
      }
    }
  }

  clearSelected(array: Array<ReceptionAvelonModel.Data>) {
    array.map(element => {
      element.state = 0;
      if (element.selected) {
        element.selected = false;
      }
    });
    return array;
  }

  async screenExit(e) {
    this.typeScreen = undefined;
    this.referencesToPrint = [];
    this.lastReception = null;
    await this.resetAllAlternate();
    this.infoHeaderReceptionComponent.loadInfoExpedition(
      {
        expeditionReference: this.expeditionStarted.reference,
        provider: {name: this.expeditionStarted.providerName, id: this.expeditionStarted.providerId, avelonId: this.expeditionStarted.providerAvelonId},
        states: this.expeditionStarted.receptionStates,
        shipper: this.expeditionStarted.shipper,
        date: this.expeditionStarted.deliveryDate,
        packingsPallets: {packings: this.expeditionStarted.receptionPackings, pallets: this.expeditionStarted.receptionPallets}
      },
      this.deliveryNote
    );
  }

  async load(e, item) {
    this.expedition = this.expeditionInput && this.expeditionInput.nativeElement && this.expeditionInput.nativeElement.value ? this.expeditionInput.nativeElement.value : null;
    this.value = item.name;
    this.filter = false;
    this.providerId = item.id;
    const data: ReceptionAvelonModel.CheckProvider = {
      expedition: this.expedition,
      providerId: this.providerId,
      providerAvelonId: this.expeditionStarted.providerAvelonId,
    };

    if (data.expedition === undefined || data.expedition.length === 0) {
      await this.intermediaryService.presentWarning('Introduzca un número de expedición para poder iniciar la recepción.', null);
    } else {
      let subscription = this.reception.checkExpeditionsByNumberAndProvider({
        expeditionNumber: data.expedition,
        providerId: data.providerId
      }).subscribe(async (response) => {
        if(response.data.expedition_available) {
          const modal = await this.modalController.create({
            component: InfoModalComponent,
            componentProps: {
              expedition: response.data.expedition,
              anotherExpeditions: response.data.another_expeditions
            }
          });

          modal.onDidDismiss().then(async response => {
            if (response.data && response.data.reception) {
              this.showCheck = false;
              this.isReceptionStarted = true;
              await this.checkProvider(data);
            }
          });

          modal.present();
        }else{
          this.isReceptionStarted = false;
          await this.intermediaryService.presentWarning('No se ha encontrado esa expedición', null);
        }
      }, () => {}, () => {
        if (subscription) {
          subscription.unsubscribe();
          subscription = null;
        }
      });
      this.subscriptions.push(subscription);
    }
  }

  async checkDuplicates() {
    if (this.typeModelVisualization == TypeModelVisualization.MODEL_REFERENCE) {
      this.loadSizes();
    } else {
      const modelSelected = this.response.models[0];
      const availableIds: number[] = modelSelected.available_ids.filter(id => id != modelSelected.id);
      let duplicatedModelIds: number[] = [];
      if (modelSelected.color == 'red') {
        const brandModelIds: number[] = this.response.brands[0].belongsModels;
        const colorModelIds: number[] = this.response.colors[0].belongsModels;
        duplicatedModelIds = availableIds.filter(id => brandModelIds.includes(id) && colorModelIds.includes(id));
      } else {
        const lineModelIds: number[] = this.expeditionLines.map(line => {
          if (line.brandId == this.result.brandId && line.colorId == this.result.colorId) {
            return line.modelId;
          }
        });
        duplicatedModelIds = availableIds.filter(id => lineModelIds.includes(id));
      }
      duplicatedModelIds = duplicatedModelIds.filter(id => id != this.result.modelId);
      if (duplicatedModelIds.length > 0) {
        duplicatedModelIds.push(this.result.modelId);
        const modelsWithReferences: {id: number, reference: string}[] = (await this.reception.postGetModelsReferences(duplicatedModelIds)).data;
        await this.openShowSelectableList(modelsWithReferences);
      } else {
        this.loadSizes();
      }
    }
  }

  public async openShowSelectableList(modelsWithReferences: {id: number, reference: string}[]) {
    const listItemsSelected = modelsWithReferences.map(m => {
      return {id: m.id, value: m.reference}
    });

    const modal = await this.modalController.create({
      component: SelectableListReceptionsComponent,
      componentProps: { listItemsSelected: listItemsSelected, itemForList: 'Seleccione un artículo' }
    });

    modal.onDidDismiss().then(async result => {
      if (result && result.data != null) {
        this.result.modelId = result.data;
        this.loadSizes();
      } else {
        await this.resetAllAlternate();
      }
    });

    await this.intermediaryService.presentWarning('La combinación seleccionada ha encontrado los siguientes artículos diferentes. Por favor, seleccione el artículo que está recepcionando.', await modal.present());
  }

  private loadSizes() {
    let subscription = this.reception
      .postLoadSizesList({modelId: this.result.modelId, colorId: this.result.colorId})
      .subscribe((res: ReceptionAvelonModel.ResponseLoadSizesList) => {
        if (res.code == 200) {
          this.listSizes = res.data;
          setTimeout(() => {
            let sizeFound: boolean = false;
            if (this.lastReception && this.lastReception.size) {
              for (let index in this.listSizes) {
                if (this.listSizes[index].id == this.lastReception.size.id) {
                  sizeFound = true;
                  this.listSizes[index].quantity = 1;
                } else {
                  this.listSizes[index].quantity = 0;
                }
              }
            }
            if(!sizeFound && this.lastReception && this.lastReception.receivedSize){
              for (let index in this.listSizes) {
                if (this.listSizes[index].name.trim().toLowerCase() == this.lastReception.receivedSize.trim().toLowerCase()) {
                  this.listSizes[index].quantity = 1;
                }
              }
            }
          }, 1000);
        } else {
          this.intermediaryService.presentToastError('Ha ocurrido un error al intentar cargar las tallas correspondientes.', PositionsToast.BOTTOM);
        }
      }, (error) => {
        this.intermediaryService.presentToastError('Ha ocurrido un error al intentar cargar las tallas correspondientes.', PositionsToast.BOTTOM);
      }, () => {
        if (subscription) {
          subscription.unsubscribe();
          subscription = null;
        }
      });
    this.subscriptions.push(subscription);
  }

  private checkEanAndPrint(ean) {
    if(this.EAN_13_VALID.test(ean) ||this.UPC_A_VALID.test(ean)){
      let subscription = this.reception
        .eanProductPrint(ean, this.expedition, this.providerId, this.isReceptionWithoutOrder, this.deliveryNote)
        .subscribe(async (resultCheck) => {
          this.intermediaryService.dismissLoading();
          if (resultCheck.resultToPrint && resultCheck.resultToPrint.length > 0) {
            const resultEan = resultCheck.resultToPrint[0];
            this.typeScreen = resultEan.type;
            this.referencesToPrint = [resultEan.reference];
            this.printerService.printTagBarcodeAndData(this.referencesToPrint)
              .then((resPrint) => {

                if (qz) {
                  qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
                  qz.security.setSignaturePromise(function(toSign) {
                    return function(resolve, reject) {
                      try {
                        const pk = KEYUTIL.getKey(sga_environment.qzTrayPrivateKey);
                        const sig = new KJUR.crypto.Signature({"alg": "SHA512withRSA"});  // Use "SHA1withRSA" for QZ Tray 2.0 and older
                        sig.init(pk);
                        sig.updateString(toSign);
                        const hex = sig.sign();
                        console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
                        resolve(stob64(hextorstr(hex)));
                      } catch (err) {
                        console.error(err);
                        reject(err);
                      }
                    };
                  });
                  qz.security.setCertificatePromise((resolve, _) => {
                    resolve(sga_environment.printLicense);
                  });

                  qz.websocket.connect().then(() => {
                    // Pass the printer name into the next Promise
                    return qz.printers.find(sga_environment.printer);
                  }).then(printer => {
                    console.log('QZ Print::device', printer);
                    const config = qz.configs.create(printer, {
                      altPrinting: true
                    });
                    return qz.print(config, resPrint);
                  }).then(() => {
                    return qz.websocket.disconnect();
                  }).catch(e => {
                    qz.websocket.disconnect();
                    console.error(e);
                  });
                } else {
                  this.intermediaryService.presentToastError('QZ Tray no instalado');
                  console.log("QZ Tray not installed")
                }
              }, (error) => {
                console.error('Some error success to print reference of reception', error);
              });
            if(this.ocrReceived){
              await this.sendOCRRecognitionConfirmation();
            }
          } else {
            if (resultCheck.productsWithError && resultCheck.productsWithError.length > 0) {
              await this.alertMessage('EAN ' + ean + ': '+ resultCheck.productsWithError[0].reason);
            }else{
              this.intermediaryService.presentToastError('Ha ocurrido un error al intentar comprobar el EAN '+ean+' introducido.');
            }
          }
        }, e => {
          if (e.error.code == 400 && e.error.message == 'InvalidEanException') {
            if (this.checkIfSelectMandatoryFields(ean)) {
              if (this.checkOnlyOneSizeAndOneQuantity()) {
                this.notifyReceptionAndPrint(ean);
              } else {
                this.intermediaryService.dismissLoading();
              }
            } else {
              this.intermediaryService.dismissLoading();
            }
          } else {
            this.intermediaryService.dismissLoading();
            let errorMessage = 'Ha ocurrido un error al intentar comprobar el EAN introducido.';
            if (e.error.errors) {
              errorMessage = e.error.errors;
            }
            this.intermediaryService.presentToastError(errorMessage);
          }
        }, () => {
          if (subscription) {
            subscription.unsubscribe();
            subscription = null;
          }
          this.lastOCRReceptionFinished = true;
        });
      this.subscriptions.push(subscription);
    } else {
      this.intermediaryService.presentToastError('Error al comprobar el formato del EAN ' + ean + '. Formato incorrecto por simbolo, letra o cantidad de caracteres inesperada.');
    }
  }

  // check if all fields mandatory are selected to receive the product (brand, model, color and at least one size)
  private checkIfSelectMandatoryFields(ean?: string): boolean {
    const errorsMessages: string[] = [];
    const sizesToPrint = this.listSizes.filter(s => {
      return s.quantity > 0;
    });

    if(ean && (!this.result.brandId || !this.result.modelId || !this.result.colorId || sizesToPrint.length <= 0)){
      this.alertMessage('Código EAN '+ean+ ' no registrado en el sistema. <br><br>Por favor seleccione Marca, Modelo, Color y una talla para imprimir la etiqueta y registrar el EAN en el sistema');
      return false;
    }

    if (!this.result.brandId) {
      errorsMessages.push('Una marca.');
    }
    if (!this.result.modelId) {
      errorsMessages.push('Un modelo.');
    }
    if (!this.result.colorId) {
      errorsMessages.push('Un color.');
    }
    if (sizesToPrint.length <= 0) {
      errorsMessages.push('Al menos una talla.');
    }

    if (errorsMessages.length > 0) {
      if(this.lastReception && this.lastReception.productReference){
        const message: string = 'Se ha recibido el código '+this.lastReception.productReference+', pero el artículo no está incluido como artículo del proveedor '+this.infoHeaderReceptionComponent.provider.name+'.<br>' +
          'A partir del código los datos del artículo son:<br>' +
          '• Marca: '+(this.lastReception.brand?this.lastReception.brand.name:'')+'<br>' +
          '• Modelo: '+(this.lastReception.model?this.lastReception.model.name:'')+'<br>' +
          '• Color: '+(this.lastReception.color?this.lastReception.color.name:'')+'<br>' +
          '• Talla: '+(this.lastReception.size?this.lastReception.size.name:'');
        const callback = () => this.typeScreen = undefined;
        this.alertMessage(message, callback());
        return false;
      }else{
        const messageAsList = errorsMessages.map(m => `<li>${m}</li>`);
        let message: string = 'Se ha detectado que faltan campos por seleccionar para poder realizar la recepción del producto y la impresión de su código único. Para poder continuar compruebe que ha seleccionado: <ul>' + messageAsList.join('') + '</ul>';
        const callback = () => this.typeScreen = undefined;
        if(this.lastReception){
          message += 'Se han recibido los siguientes datos mediante OCR: <ul>' +
            `<li>Marca: ${this.lastReception.receivedBrand ? this.lastReception.receivedBrand : ''}</li>` +
            `<li>Modelo: ${this.lastReception.receivedModel ? this.lastReception.receivedModel : ''}</li>` +
            `<li>Color: ${this.lastReception.receivedColor ? this.lastReception.receivedColor : ''}</li>` +
            `<li>Talla: ${this.lastReception.receivedSize ? this.lastReception.receivedSize : ''}</li>` +
            '</ul>';
          if(this.ocrPhoto != '../assets/img/placeholder-product.jpg'){
            message += `<img src="${this.ocrPhoto}" alt="Captura de la cámara" width="560" height="315">`
          }
        }
        this.alertMessage(message, callback());
        return false;
      }
    } else {
      return true;
    }
  }

  // check that user only has selected one size and one unity for that size to associate to EAN code
  private checkOnlyOneSizeAndOneQuantity(): boolean {
    const sizesToPrint = this.listSizes.filter(s => {
      return s.quantity > 0;
    });
    if (sizesToPrint.length > 1) {
      this.alertMessage('Seleccione solo una talla para asociar al EAN escaneado. Asegúrese de que solo ha indicado 1 Ud. de la talla que desea asociar al código.');
      return false;
    } else if (sizesToPrint[0].quantity > 1) {
      this.alertMessage('Solo puede asociar a este EAN 1 Ud. de la talla indicada.');
      return false;
    }

    return true;
  }

  // notify as received all products selected (with multiple sizes) and redirect user to location page (sorter or warehouse)
  private notifyReceptionAndPrint(eanToAssociate: string = null) {
    const paramsRequest = [];

    const sizesToPrint = this.listSizes.filter(s => {
      return s.quantity > 0;
    });
    const sizesMapped = sizesToPrint.map(s => {
      const sizeMapped: any = {
        providerId: this.providerId,
        expedition: this.expedition,
        brandId: this.result.brandId,
        colorId: this.result.colorId,
        sizeId: s.id,
        modelId: this.result.modelId,
        quantity: s.quantity
      };
      if (eanToAssociate) {
        sizeMapped.ean = eanToAssociate;
      }
      return sizeMapped;
    });
    for (let size of sizesMapped) {
      for (let q = 0; q < size.quantity; q++) {
        paramsRequest.push(size);
      }
    }

    let params: ReceptionAvelonModel.ParamsToPrint = {
      to_print: paramsRequest,
      model_field: this.typeModelVisualization == this.TypesModel.MODEL_NAME ? 'name' : (this.typeModelVisualization == this.TypesModel.MODEL_REFERENCE ? 'reference' : 'supplierReference')
    };
    if (this.deliveryNote) {
      params.delivery_note = this.deliveryNote;
    }

    let subscription = null;

    const subscribeResponseOk = async (res) => {
      let subscriptionInner = this.reception
        .getReceptions(this.providerId, this.typeModelVisualization)
        .subscribe((info: ReceptionAvelonModel.Reception) => {
          this.response = info;
          this.response.brands = this.clearSelected(this.response.brands);
          this.response.models = this.clearSelected(this.response.models);
          this.response.colors = this.clearSelected(this.response.colors);
        }, () => {}, () => {
          if (subscriptionInner) {
            subscriptionInner.unsubscribe();
            subscriptionInner = null;
          }
        });
      this.subscriptions.push(subscriptionInner);

      if (res.resultToPrint && res.resultToPrint.length > 0) {
        const someProductToSorter = !!res.resultToPrint.find(r => r.type == ScreenResult.SORTER_VENTILATION);

        if (someProductToSorter) {
          this.typeScreen = ScreenResult.SORTER_VENTILATION;
        } else {
          this.typeScreen = ScreenResult.WAREHOUSE_LOCATION;
        }

        this.referencesToPrint = res.resultToPrint.map(r => r.reference);
      }

      if (this.referencesToPrint && this.referencesToPrint.length > 0) {
        this.printerService.printTagBarcodeAndData(this.referencesToPrint)
          .then((resPrint) => {

            if (qz) {
              qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
              qz.security.setSignaturePromise(function(toSign) {
                return function(resolve, reject) {
                  try {
                    const pk = KEYUTIL.getKey(sga_environment.qzTrayPrivateKey);
                    const sig = new KJUR.crypto.Signature({"alg": "SHA512withRSA"});  // Use "SHA1withRSA" for QZ Tray 2.0 and older
                    sig.init(pk);
                    sig.updateString(toSign);
                    const hex = sig.sign();
                    console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
                    resolve(stob64(hextorstr(hex)));
                  } catch (err) {
                    console.error(err);
                    reject(err);
                  }
                };
              });
              qz.security.setCertificatePromise((resolve, _) => {
                resolve(sga_environment.printLicense);
              });

              qz.websocket.connect().then(() => {
                // Pass the printer name into the next Promise
                return qz.printers.find(sga_environment.printer);
              }).then(printer => {
                console.log('QZ Print::device', printer);
                const config = qz.configs.create(printer, {
                  altPrinting: true
                });
                return qz.print(config, resPrint);
              }).then(() => {
                return qz.websocket.disconnect();
              }).catch(e => {
                qz.websocket.disconnect();
                console.error(e);
              });
            } else {
              this.intermediaryService.presentToastError('QZ Tray no instalado');
              console.log("QZ Tray not installed")
            }
          }, (error) => {
            console.error('Some error success to print reference of reception', error);
          });
      }

      if (res.productsWithError && res.productsWithError.length > 0) {
        let errorMessage = `No se han podido generar e imprimir alguna de las etiquetas necesarias (${res.productsWithError.length}). <br/>Se detalla la incidencia a continuación: <ul>`;
        for (let error of res.productsWithError) {
          errorMessage += `<li>${error.reason}</li>`
        }
        errorMessage += '</ul>';
        await this.alertMessage(errorMessage);
        this.intermediaryService.presentToastError('Ha ocurrido un error inesperado al intentar imprimir algunas de las etiquetas necesarias.');
      }
      if(this.ocrReceived && sizesToPrint.length == 1 && sizesToPrint[0].quantity == 1){
        await this.sendOCRRecognitionConfirmation();
      }
      this.intermediaryService.dismissLoading();
    };
    const subscribeResponseError = (error) => {
      this.intermediaryService.dismissLoading();
      this.intermediaryService.presentToastError('Ha ocurrido un error al intentar imprimir las etiquetas necesarias.');
    };
    const subscribeResponseComplete = () => {
      if (subscription) {
        subscription.unsubscribe();
        subscription = null;
      }
      this.lastOCRReceptionFinished = true;
    };

    if (this.isReceptionWithoutOrder) {
      subscription = this.reception.makeReceptionFree(params).subscribe(subscribeResponseOk, subscribeResponseError, subscribeResponseComplete);
    } else {
      subscription = this.reception.printReceptionLabel(params).subscribe(subscribeResponseOk, subscribeResponseError, subscribeResponseComplete);
    }
    this.subscriptions.push(subscription);
  }

  public getPhotoUrl(modelId): string {
    if (modelId && this.modelSelected && this.modelSelected.photos_models && this.modelSelected.photos_models[modelId]) {
      return environment.urlBase + this.modelSelected.photos_models[modelId];
    } else {
      return '../assets/img/placeholder-product.jpg';
    }
  }

  public resumeExpedition(expeditionReference: string) {
    this.formHeaderReceptionComponent.checkingResumeExpedition(true);
    let subscription = this.reception
      .checkExpeditionByReference(expeditionReference)
      .subscribe(async res => {
        if (res.code == 200) {
          if (res.data.expedition_available && res.data.expedition) {
            const modal = await this.modalController.create({
              component: InfoModalComponent,
              componentProps: {
                expedition: res.data.expedition,
                anotherExpeditions: res.data.another_expeditions
              }
            });
            modal.onDidDismiss().then(response => {
              if (response.data && response.data.reception && response.data.expedition) {
                const expedition: ReceptionAvelonModel.Expedition = response.data.expedition;
                this.startReception(expedition);
              }
            });
            modal.present();

            this.formHeaderReceptionComponent.checkingResumeExpedition(false);
          } else {
            this.intermediaryService.presentWarning(`No hay ninguna expedición con referencia ${res.data.expedition_reference_queried} pendiente de recepción.`, null);
            this.formHeaderReceptionComponent.checkingResumeExpedition(false);
          }
        } else {
          let errorMessage = 'Ha ocurrido un error al intentar continuar con la expedición indicada.';
          if (res.error && res.error.errors) {
            errorMessage = res.error.errors;
          }
          this.intermediaryService.presentToastError(errorMessage);
          this.formHeaderReceptionComponent.checkingResumeExpedition(false);
        }
      }, (e) => {
        let errorMessage = 'Ha ocurrido un error al intentar continuar con la expedición indicada.';
        if (e.error && e.error.errors) {
          errorMessage = e.error.errors;
        }
        this.intermediaryService.presentToastError(errorMessage);
        this.formHeaderReceptionComponent.checkingResumeExpedition(false);
      }, () => {
        if (subscription) {
          subscription.unsubscribe();
          subscription = null;
        }
      });
    this.subscriptions.push(subscription);
  }

  // check if the expedition selected by reference is available and get her data
  public checkExpedition(data) {
    this.formHeaderReceptionComponent.checkingExpedition(true);
    let subscription = this.reception
      .checkExpeditionByReference(data)
      .subscribe(async (res) => {
        if (res.code == 200) {
          if (res.data.expedition_available) {
            const modal = await this.modalController.create({
              component: InfoModalComponent,
              componentProps: {
                expedition: res.data.expedition,
                anotherExpeditions: res.data.another_expeditions
              }
            });
            modal.onDidDismiss().then(response => {
              if (response.data && response.data.reception && response.data.expedition) {
                const expedition: ReceptionAvelonModel.Expedition = response.data.expedition;
                this.startReception(expedition);
              }
            });
            modal.present();

            this.formHeaderReceptionComponent.checkingExpedition(false);
          } else {
            this.isReceptionStarted = false;
            await this.intermediaryService.presentWarning(`No hay ninguna expedición con referencia ${res.data.expedition_reference_queried} pendiente de recepción.`, null);
            this.formHeaderReceptionComponent.checkingExpedition(false);
          }
        } else {
          this.stateAnimationForm = 'in';
          this.stateAnimationInfo = 'out';
          this.isReceptionStarted = false;
          let errorMessage = 'Ha ocurrido un error al intentar consultar la expedición indicada.';
          if (res.error && res.error.errors) {
            errorMessage = res.error.errors;
          }
          this.intermediaryService.presentToastError(errorMessage);
          this.formHeaderReceptionComponent.checkingExpedition(false);
        }
      }, (e) => {
        this.stateAnimationForm = 'in';
        this.stateAnimationInfo = 'out';
        this.isReceptionStarted = false;
        let errorMessage = 'Ha ocurrido un error al intentar consultar la expedición indicada.';
        if (e.error && e.error.errors) {
          errorMessage = e.error.errors;
        }
        this.intermediaryService.presentToastError(errorMessage);
        this.formHeaderReceptionComponent.checkingExpedition(false);
      }, () => {
        if (subscription) {
          subscription.unsubscribe();
          subscription = null;
        }
      });
    this.subscriptions.push(subscription);
  }

  // check if the provider selected have expeditions to receive
  public listByProvider(data) {
    this.formHeaderReceptionComponent.checkingProvider(true);
    let subscription = this.reception
      .checkExpeditionsByProvider(data)
      .subscribe(async (res) => {
        if (res.code == 200) {
          if (res.data.has_expeditions) {
            const modal = await this.modalController.create({
              component: InfoModalComponent,
              componentProps: {
                anotherExpeditions: res.data.expeditions,
                title: `Expediciones para ${res.data.provider_queried}`,
                titleAnotherExpeditions: 'Listado de expediciones'
              }
            });
            modal.onDidDismiss().then(response => {
              if (response.data && response.data.reception && response.data.expedition) {
                const expedition: ReceptionAvelonModel.Expedition = response.data.expedition;
                this.startReception(expedition);
              }
            });
            modal.present();

            this.formHeaderReceptionComponent.checkingProvider(false);
          } else {
            this.stateAnimationForm = 'in';
            this.stateAnimationInfo = 'out';
            this.isReceptionStarted = false;
            await this.intermediaryService.presentWarning(`No hay expediciones pendientes para el proveedor ${res.data.provider_queried}.`, null);
            this.formHeaderReceptionComponent.checkingProvider(false);
          }
        } else {
          this.stateAnimationForm = 'in';
          this.stateAnimationInfo = 'out';
          this.isReceptionStarted = false;
          let errorMessage = 'Ha ocurrido un error al intentar consultar la expedición indicada.';
          if (res.error && res.error.errors) {
            errorMessage = res.error.errors;
          }
          this.intermediaryService.presentToastError(errorMessage);
          this.formHeaderReceptionComponent.checkingProvider(false);
        }
      }, (e) => {
        this.stateAnimationForm = 'in';
        this.stateAnimationInfo = 'out';
        this.isReceptionStarted = false;
        let errorMessage = 'Ha ocurrido un error al intentar consultar las expediciones del proveedor.';
        if (e.error && e.error.errors) {
          errorMessage = e.error.errors;
        }
        this.intermediaryService.presentToastError(errorMessage);
        this.formHeaderReceptionComponent.checkingProvider(false);
      }, () => {
        if (subscription) {
          subscription.unsubscribe();
          subscription = null;
        }
      });
    this.subscriptions.push(subscription);
  }

  //region Selection of data visualization for model
  public changeValue() {
    if(!this.internalModelChangeGoingOn){
      let subscription = this.reception
        .postReloadModelsList({typeVisualization: this.typeModelVisualization, providerId: this.providerId})
        .subscribe(async (res) => {
          this.filterData.models = res.data;
          await this.resetAllAlternate();
          this.lastTypeModelVisualization = this.typeModelVisualization;
        }, error => {
          console.error('Error reloading models: ', error);
          this.typeModelVisualization = this.lastTypeModelVisualization;
          this.intermediaryService.presentToastError('Ha ocurrido un error al intentar cargar los modelos por el valor seleccionado.');
        }, () => {
          if (subscription) {
            subscription.unsubscribe();
            subscription = null;
          }
        });
      this.subscriptions.push(subscription);
    }
  }
  //endregion

  private startReception(expedition) {
    const fieldsToLoadData: ReceptionAvelonModel.CheckProvider = {
      expedition: expedition.reference,
      providerId: expedition.providerId,
      providerAvelonId: expedition.providerAvelonId,
    };
    this.formHeaderReceptionComponent.saveLastExpeditionStarted({reference: fieldsToLoadData.expedition, providerId: fieldsToLoadData.providerId, providerAvelonId: fieldsToLoadData.providerAvelonId});
    this.checkProvider(fieldsToLoadData);

    this.stateAnimationForm = 'out';
    this.stateAnimationInfo = 'in';
    this.isReceptionStarted = true;

    this.expeditionStarted = expedition;
    this.infoHeaderReceptionComponent.loadInfoExpedition(
      {
        expeditionReference: expedition.reference,
        provider: {name: expedition.providerName, id: expedition.providerId.toString(), avelonId: expedition.providerAvelonId},
        packingsPallets: {packings: expedition.receptionPackings, pallets: expedition.receptionPallets},
        date: expedition.deliveryDate,
        shipper: expedition.shipper,
        states: expedition.receptionStates
      });
  }

  async timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getCorrectedMessage(){
    if(this.lastReception.corrected){
      return 'Se ha cargado la información modificada previamente por el usuario en un escaneo similar:';
    }else{
      if(this.lastReception.brandCorrected){
        return 'Se ha corregido automáticamente la marca escaneada en base al proveedor y modelo, los datos recibidos son:';
      }else{
        return 'Se han recibido los siguientes datos mediante OCR:';
      }
    }
  }

  async takeSnapshot(){
    await this.intermediaryService.presentLoadingNew('Procesando captura...');

    const newBarcodes = await this.barcodeScanner.takeSnapshot();
    this.cameraBarcodes = newBarcodes.barcodes;
    this.snapshot = newBarcodes.snapshot;

    let codeFound: boolean = false;

    for(let barcode of this.cameraBarcodes){
      if(barcode.type == 'CODE_128' && this.REFERENCE_PRODUCT_REGEX_VALID.test(barcode.code)){
        codeFound = true;
        const snapshotOCR: SnapshotOCR = {
          positionId: this.positionId,
          barcode: barcode.code,
          barcodeType: barcode.type,
          image: this.snapshot
        };
        await this.reception.postStoreSnapshotOCR(snapshotOCR);
        const response: ResponseInfoReception = await this.productsService.getProductDataForReceptionWithBarcode(barcode.code);
        if(response.code == 200 && response.data && response.data.brand && response.data.model && response.data.color && response.data.size){
          const storeOCR: StoreOCR = {
            positionId: this.positionId,
          };
          await this.reception.postStoreOCR(storeOCR);
        }else{
          const ocrSnapshot: OcrSnapshot = {
            positionId: this.positionId,
            image: this.snapshot
          };
          await this.reception.postOCRSnapshot(ocrSnapshot);
        }
        break;
      }
    }

    if(!codeFound){
      for(let barcode of this.cameraBarcodes){
        if((barcode.type == 'EAN_13' || barcode.type == 'UPC_A') && (this.EAN_13_VALID.test(barcode.code) || this.UPC_A_VALID.test(barcode.code))){
          codeFound = true;
          const snapshotOCR: SnapshotOCR = {
            positionId: this.positionId,
            barcode: barcode.code,
            barcodeType: barcode.type,
            image: this.snapshot
          };
          await this.reception.postStoreSnapshotOCR(snapshotOCR);
          const response: ResponseCheckEan = await this.productsService.getCheckEanExists(barcode.code);
          if(response.code == 200 && response.data && response.data.codeExists){
            const storeOCR: StoreOCR = {
              positionId: this.positionId,
            };
            await this.reception.postStoreOCR(storeOCR);
          }else{
            const ocrSnapshot: OcrSnapshot = {
              positionId: this.positionId,
              image: this.snapshot
            };
            await this.reception.postOCRSnapshot(ocrSnapshot);
          }
          break;
        }
      }
    }

    if(!codeFound){
      const snapshotOCR: SnapshotOCR = {
        positionId: this.positionId,
        image: this.snapshot
      };
      await this.reception.postStoreSnapshotOCR(snapshotOCR);
      const ocrSnapshot: OcrSnapshot = {
        positionId: this.positionId,
        image: this.snapshot
      };
      await this.reception.postOCRSnapshot(ocrSnapshot);
    }

    await this.intermediaryService.dismissLoadingNew();
  }

  learningNewTab(){
    window.open(environment.ocrModelLearningSystem, '_blank');
  }

}

import { Injectable } from '@angular/core';
import { RequestsProvider } from "../../../providers/requests/requests.provider";
import { environment } from "@suite/services";
import { InScansIncidence } from 'libs/services/src/models/endpoints/InScansIncience';

@Injectable({
    providedIn: 'root'
})
export class InScansIncidenceService {

    private solvePath = `${environment.apiBase}/in-scans-incidences/solve`
    private filterPath = `${environment.apiBase}/in-scans-incidences/filter`
    private filterOptionsPath = `${environment.apiBase}/in-scans-incidences/filter-options`
    private markAsSolvedPath = `${environment.apiBase}/in-scans-incidences/mark-as-solved`
    private getCount = `${environment.apiBase}/in-scans-incidences/count-pending`

    constructor(
        private requestsProvider: RequestsProvider
    ) { }

    solve(params: { incidences: InScansIncidence[], userId: number }) {
        return this.requestsProvider.post(this.solvePath, params);
    }

    filter(params) {
        return this.requestsProvider.post(this.filterPath, params);
    }

    filterOptions() {
        return this.requestsProvider.get(this.filterOptionsPath);
    }

    markAsSolved(params: { incidences: InScansIncidence[], userId: number }) {
        return this.requestsProvider.post(this.markAsSolvedPath, params);
    }

    checkForInScansIncidences() {
        return this.requestsProvider.get(this.getCount);
    }
}

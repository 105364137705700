
export * from './scanner-configuration/scanner-configuration.component';
export * from './scanner-configuration/scanner-configuration.module';

/**util component */
export * from './components/components.module';
export * from './components/utils/utils.component';

/**util clasess */
export * from './utils/validators';

/**user component and subcomponents */
export * from './users/users.component';
export * from './users/users.module';

export * from './locations/locations.component';
export * from './locations/locations.module';

export * from './jail/jail.component';
export * from './jail/jail.module';

export * from './state-expedition-avelon/state-expedition-avelon.component';
export * from './state-expedition-avelon/state-expedition-avelon.module';

export * from './commercial-fields/commercial-fields.component';
export * from './commercial-fields/commercial-fields.module';

export * from './brands-enabled-reception/brands-enabled-reception.component';
export * from './brands-enabled-reception/brands-enabled-reception.module';

export * from './pallets/pallets.component';
export * from './pallets/pallets.module';

export * from './group-to-warehouse/group-to-warehouse.component';
export * from './group-to-warehouse/group-to-warehouse.module';

export * from './groups/groups.component';
export * from './groups/groups.module';

export * from './products/products.component';
export * from './products/products.module';

export * from './ventilation-no-sorter/ventilation-no-sorter.component';
export * from './ventilation-no-sorter/ventilation-no-sorter.module';

export * from './products-al/products-al.component';
export * from './products-al/products-al.module';

export * from './locate/locate.component';
export * from './locate/locate.module';

export * from './audits/audits.component';
export * from './audits/audits.module';

export * from './audits-mobile/audits-mobile.component';
export * from './audits-mobile/audits-mobile.module';

export * from './audits-mobile/add-audits/add-audits.component';


// Incidences Button
export * from './incidences-button/incidences-button.component';
export * from './incidences-button/incidences-button.module';

// Incidences List
export * from './incidences-list/incidences-list.component';
export * from './incidences-list/incidences-list.module';

// Out Scans Incidences
export * from './out-scans-incidences/out-scans-incidences.component';
export * from './out-scans-incidences/out-scans-incidences.module';

// In Scans Incidences
export * from './in-scans-incidences/in-scans-incidences.component';
export * from './in-scans-incidences/in-scans-incidences.module';

// Incidences Popover
export * from './incidences-popover/incidences-popover.component';
export * from './incidences-popover/incidences-popover.module';

// Incidences Reception List
export * from './incidences-reception-list/incidences-reception-list.component';
export * from './incidences-reception-list/incidences-reception-list.module';

// Avelon Incidences Reception List
export * from './avelon-incidences-reception/avelon-incidences-reception.component';
export * from './avelon-incidences-reception/avelon-incidences-reception.module';

// Picking Scan Packing
export * from './picking-scan-packing/picking-scan-packing.component';
export * from './picking-scan-packing/picking-scan-packing.module';

// Simple Incidence
export * from './incidence-simple/incidence-simple.component';
export * from './incidence-simple/incidence-simple.module';

// Complex Incidence
export * from './incidence-complex/incidence-complex.component';
export * from './incidence-complex/incidence-complex.module';

// Halls (racks)
export * from './halls/halls.component';
export * from './halls/halls.module';

// Warehouses
export * from './warehouses/warehouses.component';
export * from './warehouses/warehouses.module';

// Workwaves Scheduled
export * from './workwaves-schedule/workwaves-schedule.component';
export * from './workwaves-schedule/workwaves-schedule.module';

// Workwave Template Rebuild
export * from './workwave-template-rebuild/workwave-template-rebuild.component';
export * from './workwave-template-rebuild/workwave-template-rebuild.module';

// Reception Hide Alert
export * from './reception-hide-alert/reception-hide-alert.component';
export * from './reception-hide-alert/reception-hide-alert.module';

// Workwaves History
export * from './workwaves-history/workwaves-history.component';
export * from './workwaves-history/workwaves-history.module';

export * from './user-manager/user-manager.component';
export * from './user-manager/user-manager.module';

export * from './role-assignment/role-assignment.component';
export * from './role-assignment/role-assignment.module';

export * from './damaged-shoes/damaged-shoes.component';
export * from './damaged-shoes/damaged-shoes.module';

export * from './defective-management/defective-management.component';
export * from './defective-management/defective-management.module';

export * from './defective-zones/defective-zones.component';
export * from './defective-zones/defective-zones.module';

export * from './defective-registry/defective-registry.component';
export * from './defective-registry/defective-registry.module';

export * from './defective-historic/defective-historic.component';
export * from './defective-historic/defective-historic.module';

/**supplier-conditions */
export * from './supplier-conditions/supplier-conditions.component';
export * from './supplier-conditions/supplier-conditions.module';

/**return-types */
export * from './return-types/return-types.component';
export * from './return-types/return-types.module';

export * from './return-tracking-list/return-tracking-list.component';
export * from './return-tracking-list/return-tracking-list.module';

/** regularization **/
export * from './regularization-form/regularization-form.component';
export * from './regularization-form/regularization-form.module';

export * from './regularization-route/regularization-route.component';
export * from './regularization-route/regularization-route.module';

/**returns-historic */
export * from './returns-historic/returns-historic.component';
export * from './returns-historic/returns-historic.module';

export * from './return-pending-list/return-pending-list.component';
export * from './return-pending-list/return-pending-list.module';

export * from './new-return/new-return.component';
export * from './new-return/new-return.module';

export * from './view-return/view-return.component';
export * from './view-return/view-return.module';

/**returns-list */
export * from './returns-list/returns-list.component';
export * from './returns-list/returns-list.module';

/**returns-list-products */
export * from './returns-list-products/returns-list-products.component';
export * from './returns-list-products/returns-list-products.module';

/**breadcrumb */
export * from './components/breadcrumb/breadcrumb.component';
export * from './components/breadcrumb/breadcrumb.module';

// Picking User Assignment
export * from './user-assignment-picking/user-assignment-picking.component';
export * from './user-assignment-picking/user-assignment-picking.module';

// Picking Tasks
export * from './picking-tasks/picking-tasks.component';
export * from './picking-tasks/picking-tasks.module';

// Picking Tasks Stores
export * from './picking-tasks-stores/picking-tasks-stores.component';
export * from './picking-tasks-stores/picking-tasks-stores.module';

/**Permissions */
export * from './permissions/permissions.module';
export * from './permissions/permissions.page';

/**Home */
export * from './home/home.module';
export * from './home/home.page';

/**Login */
export * from './login/login.module';
export * from './login/login.page';

/**Roles */
export * from './roles/roles-routing.module';
export * from './roles/roles.module';
export * from './roles/roles.component';

/**Assign */
export * from './assign/assign.module';
export * from './assign/assign-routing.module';

/**Labels */
export * from './labels/labels.module';
export * from './labels/labels.component';

/**Settings */
export * from './settings/settings.component';
export * from './settings/settings.module';

/**Settings */
export * from './section-settings-print-service/section-settings-print-service.component';
export * from './section-settings-print-service/section-settings-print-service.module';

/**Menu */
export * from './components/menu/menu.component';
export * from './components/menu/menu.module';

/**Manual Positioning */
export * from './positioning-manual/positioning-manual.component';
export * from './positioning-manual/positioning-manual.module';

/**Manual Positioning Online */
export * from './positioning-manual-online/positioning-manual-online.component';
export * from './positioning-manual-online/positioning-manual-online.module';

/**Manual Picking */
export * from './picking-manual/picking-manual.component';
export * from './picking-manual/picking-manual.module';

/**Return Picking */
export * from './picking-return/picking-return.component';
export * from './picking-return/picking-return.module';

/**List Pickings with Products */
export * from './list-picking/list-picking.component';
export * from './list-picking/list-picking.module';

/**Tariff */
export * from './tariff/tariff.component';
export * from './tariff/tariff.module';

/**TariffSGA */
export * from './tariffSGA/tariffSGA.component';
export * from './tariffSGA/tariffSGA.module';
/**Prices */
export * from './prices/prices.component';
export * from './prices/prices.module';

/**New Products */
export * from './new-products/new-products.component';
export * from './new-products/new-products.module';

/**Free Expired Reserves */
export * from './free-expired-reserves/free-expired-reserves.component';
export * from './free-expired-reserves/free-expired-reserves.module';

/**New Products */
export * from './list-new-products/list-new-products.component';
export * from './list-new-products/list-new-products.module';

/**Tags-input */
export * from './components/tags-input/tags-input.component';
export * from './components/tags-input/tags-input.module';

/**Building */
export * from './building/building.component';
export * from './building/building.module';

/**Sorter */
export * from './sorter/sorter.component';
export * from './sorter/sorter.module';

/**Sorter Create*/
export * from './sorter-create/sorter-create.component';
export * from './sorter-create/sorter-create.module';

/**Calendar picking */
export * from './calendar-picking/calendar-picking.component';
export * from './calendar-picking/calendar-picking.module';

/**Calendar sga */
export * from './calendar-sga/calendar-sga.component';
export * from './calendar-sga/calendar-sga.module';

/**Manual Print Prices*/
export * from './print-prices-manual/print-prices-manual.component';
export * from './print-prices-manual/print-prices-manual.module';

/**Relabel Packings*/
export * from './print-relabel-packing/print-relabel-packing.component';
export * from './print-relabel-packing/print-relabel-packing.module';

/**Relabel Packings Manual*/
export * from './print-relabel-packing-manual/print-relabel-packing-manual.component';
export * from './print-relabel-packing-manual/print-relabel-packing-manual.module';

/**List Product Carrier*/
export * from './page-list-products-carrier/page-list-products-carrier.component';
export * from './page-list-products-carrier/page-list-products-carrier.module';

/**Group picking */
export * from './group-warehouse-picking/group-warehouse-picking.component';
export * from './group-warehouse-picking/group-warehouse-picking.module';

/**Seal Packing Manual*/
export * from './seal-packing-manual/seal-packing-manual.component';
export * from './seal-packing-manual/seal-packing-manual.module';

/**Relabel Products Manual*/
export * from './print-relabel-product-manual/print-relabel-product-manual.component';
export * from './print-relabel-product-manual/print-relabel-product-manual.module';

/**User time */
export * from './user-time/user-time.component'
export * from './user-time/user-time.module'

/**Print Products Received*/
export * from './print-received-product/print-received-product.component';
export * from './print-received-product/print-received-product.module';

/**Unfit Online Products*/
export * from './unfit-online-products/unfit-online-products.component';
export * from './unfit-online-products/unfit-online-products.module';

/**Agency */
export * from './agency/agency.component';
export * from './agency/agency.module';

/**Global variables */
export * from './global-variables/global-variables.component';
export * from './global-variables/global-variables.module';

/**Workwave config menu */
export * from './workwave-config-menu/workwave-config-menu.component';
export * from './workwave-config-menu/workwave-config-menu.module';

/** List Pickings with Products Rebuild */
export * from './list-picking-rebuild/list-picking-rebuild.component';
export * from './list-picking-rebuild/list-picking-rebuild.module';

/** Button to open popover filters */
export * from './components/filter-button/filter-button.component';
export * from './components/filter-button/filter-button.module';

/** Popover for filters */
export * from './components/filter-popover/filter-popover.component';
export * from './components/filter-popover/filter-popover.module';

/** Transfer Packing */
export * from './transfer-packing/transfer-packing.component';
export * from './transfer-packing/transfer-packing.module';

/** Transfer Ventilation */
export * from './transfer-ventilation/transfer-ventilation.component';
export * from './transfer-ventilation/transfer-ventilation.module';

/** Toolbar AL */
export * from './components/toolbar-al/toolbar-al.component';
export * from './components/toolbar-al/toolbar-al.module';

/** Popover Menu Toolbar */
export * from './components/popover-menu-toolbar/popover-menu-toolbar.component';
export * from './components/popover-menu-toolbar/popover-menu-toolbar.module';

// Workwave Template Online-Store Requests
export * from './workwave-template-online-store/workwave-template-online-store.component';
export * from './workwave-template-online-store/workwave-template-online-store.module';

// Products verification for online/store requests pickings
export * from './picking-online-store-verify/picking-online-store-verify.component';
export * from './picking-online-store-verify/picking-online-store-verify.module';

// Orders preparations
export * from './order-preparation/order-preparation.component';
export * from './order-preparation/order-preparation.module';


// Transport manifest
export * from './transport-manifest/transport-manifest.component';
export * from './transport-manifest/transport-manifest.module';

// Labels Manual
export * from './labels-manual/labels-manual.component';
export * from './labels-manual/labels-manual.module';

// Scanner item for manual input or laser scanners
export * from './components/scanner-manual/scanner-manual.component';
export * from './components/scanner-manual/scanner-manual.module';

// /**Regions */
export  * from './regions/regions.component';
export  * from './regions/regions.module';

// // /**Receptions avelon */
export  * from './receptions-avelon/receptions-avelon.component'
export  * from './receptions-avelon/receptions-avelon.module';

/**Reception Final */
export  * from './reception-final/reception-final.component'
export  * from './reception-final/reception-final.module';

// // /**Predistributions */
export  * from './predistributions/predistributions.component'
export  * from './predistributions/predistributions.module';

// // /**Receptions empty packing */
export  * from './reception-empty-packing/reception-empty-packing.component'
export  * from './reception-empty-packing/reception-empty-packing.module';

export  * from './send-empty-packing/send-empty-packing.component'
export  * from './send-empty-packing/send-empty-packing.module';

// // /**Receptionss avelon */
export  * from './receptionss-avelon/receptionss-avelon.component'
export  * from './receptionss-avelon/receptionss-avelon.module';

// // /**Receptionss avelon */
export  * from './products-avelon/products-avelon.component'
export  * from './products-avelon/products-avelon.module';

export  * from './receptions-avelon-app/receptions-avelon-app.component'
export  * from './receptions-avelon-app/receptions-avelon-app.module';

export  * from './expeditions-pending-app/expeditions-pending-app.component'
export  * from './expeditions-pending-app/expeditions-pending-app.module';

// incidents
export * from './incidents/incidents.component';
export * from './incidents/incidents.module'

// defect handler list
export * from './defect-handler/defect-handler.component'
export * from './defect-handler/defect-handler.module'

// signature
export * from './signature/signature.component'
export * from './signature/signature.module'

//seasons enabled reception
export  * from './seasons-enabled/seasons-enabled.component'
export  * from './seasons-enabled/seasons-enabled.module';


//Drop Files
export * from './drop-files/drop-files.component'
export * from './drop-files/drop-files.module'

//Drop Files
export * from './expedition-manual/expedition-manual.component'
export * from './expedition-manual/expedition-manual.module'

//Drop Files
export * from './hiring-seur/hiring-seur.component'
export * from './hiring-seur/hiring-seur.module'

//Drop Files
export * from './hiring-correosexpress/hiring-correosexpress.component'
export * from './hiring-correosexpress/hiring-correosexpress.module'

//Drop Files
export * from './hiring-dhl/hiring-dhl.component'
export * from './hiring-dhl/hiring-dhl.module'

//Drop Files
export * from './expedition-collected/expedition-collected.component'
export * from './expedition-collected/expedition-collected.module'

//Drop Files
export * from './expedition-collected/package-collected/package-collected.component'
export * from './expedition-collected/package-collected/package-collected.module'
//requested-products
export * from './requested-products/requested-products.component'
export * from './requested-products/requested-products.module'

//Unlock Expeditions
export * from './unlock-expeditions/unlock-expeditions.component'
export * from './unlock-expeditions/unlock-expeditions.module'

export * from './transports-orders/transports-orders.component'
export * from './transports-orders/transports-orders.module'

export * from './expedition-cancelled/expedition-cancelled.component'
export * from './expedition-cancelled/expedition-cancelled.module'

export * from './transport-order-package/transport-order-package.component'
export * from './transport-order-package/transport-order-package.module'

export * from './transport-package-collected/transport-package-collected.component'
export * from './transport-package-collected/transport-package-collected.module'

export * from './transport-package-cancelled/transport-package-cancelled.component'
export * from './transport-package-cancelled/transport-package-cancelled.module'

export * from './transports-select/transports-select.component'
export * from './transports-select/transports-select.module'

/**Transportes */
export * from './transports-expeditions/transports-expeditions.component'
export * from './transports-expeditions/transports-expeditions.module'

/** Aplicaciones instaladas */
export * from './installed-app-versions/installed-app-versions.component'
export * from './installed-app-versions/installed-app-versions.module'

/**Órdenes no procesadas */
export * from './order-no-processed/order-no-processed.component'
export * from './order-no-processed/order-no-processed.module'

/**Transportes */
export * from './expeditions-inside/expedition-inside.component'
export * from './expeditions-inside/expedition-inside.module'


/**Transportes */
export * from './package-received/package-received.component'
export * from './package-received/package-received.module'

/**Transportes */
export * from './package-history/package-history.component'
export * from './package-history/package-history.module'

//// *marketplaces

export  * from './marketplaces/marketplaces.component';
export  * from './marketplaces/marketplaces.module';

export  * from './marketplaces/create-mapping/create-mapping.component';
export  * from './marketplaces/create-mapping/create-mapping.module';

export  * from './customers/customers.component';
export  * from './customers/customers.module';

export * from './expedition-incidences/expedition-incidences.component';
export * from './expedition-incidences/expedition-incidences.module';

export  * from './refunds/refunds.component';
export  * from './refunds/refunds.module';

//region Online Orders
export * from './orders/online/change-address/change-address.component';
export * from './orders/online/change-address/change-address.module';
//endregion

export * from './create-picking-orders-petitions/create-picking-orders-petitions.component';
export * from './create-picking-orders-petitions/create-picking-orders-petitions.module';

//PHONEGAP EXPORT===========================================================
//br - ExtendedInfoCapture
export * from './br/br.component';
export * from './br/br.module';

//Release Reservation
export * from './release-reservations/release-reservations.component';
export * from './release-reservations/release-reservations.module';

//Locate Defective capture
export * from './locate-defective/locate-defective.component';
export * from './locate-defective/locate-defective.module';

//Size Regularization
export * from './size-regularization/size-regularization.component';
export * from './size-regularization/size-regularization.module';

//Picking Stores
export * from './picking-stores/picking-stores.component';
export * from './picking-stores/picking-stores.module';

//Print Tags
export * from './print-tags/print-tags.component';
export * from './print-tags/print-tags.module';

//Reception Peer To Peer
export * from './reception-pair-by-pair/reception-pair-by-pair.component';
export * from './reception-pair-by-pair/reception-pair-by-pair.module';
//===================================================================

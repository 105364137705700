import { HttpRequestModel } from 'libs/services/src/models/endpoints/HttpRequest';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {ExpeditionIncidenceModel} from "../../../models/endpoints/ExpeditionIncidence";

@Injectable({
  providedIn: 'root'
})
export class ExpeditionIncidencesService {

  private readonly baseUrl: string;
  private postGetAllIncidencesUrl: string;
  private getGetIncidencesFiltersUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiBase;
    this.postGetAllIncidencesUrl = `${this.baseUrl}/opl/incidences`;
    this.getGetIncidencesFiltersUrl = `${this.baseUrl}/opl/incidences/filters`;
  }

  public postGetAllIncidences(body): Observable<ExpeditionIncidenceModel.ResponseGetAllIncidences> {
    return this.http.post<HttpRequestModel.Response>(this.postGetAllIncidencesUrl, body).pipe(
      map(resp => resp.data)
    )
  }

  public getGetIncidencesFilters(): Observable<ExpeditionIncidenceModel.ResponseGetIncidencesFilters> {
    return this.http.get<HttpRequestModel.Response>(this.getGetIncidencesFiltersUrl).pipe(
      map(resp => resp.data)
    )
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {RouterModule, Routes} from "@angular/router";
import {MatTooltipModule} from "@angular/material";
import {TransportsSelectComponent} from './transports-select.component';
import {MatRippleModule} from '@angular/material';

const routes: Routes = [
  {
    path: '',
    component: TransportsSelectComponent
  }
];

@NgModule({
  declarations: [TransportsSelectComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forChild(routes),
    MatTooltipModule,
    MatRippleModule
  ]
})
export class TransportsSelectModule {}

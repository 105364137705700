import { Component, OnInit, ViewChild } from '@angular/core';
import { WarehouseService } from "../../../../services/src/lib/endpoint/warehouse/warehouse.service";
import { AuthenticationService, InventoryModel, InventoryService, WarehouseModel, IntermediaryService } from "@suite/services";
import { AlertController} from "@ionic/angular";
import { ItemReferencesProvider } from "../../../../services/src/providers/item-references/item-references.provider";
import { environment as al_environment } from "../../../../../apps/al/src/environments/environment";
import { AudioProvider } from "../../../../services/src/providers/audio-provider/audio-provider.provider";
import { KeyboardService } from "../../../../services/src/lib/keyboard/keyboard.service";
import { CarrierService } from '../../../../services/src/lib/endpoint/carrier/carrier.service';
import { LoadingMessageComponent } from "../../components/loading-message/loading-message.component";
import {Observable} from "rxjs";
import { HttpResponse} from "@angular/common/http";
import { ProductModel, ProductsService} from "@suite/services";

@Component({
  selector: 'suite-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {

  @ViewChild(LoadingMessageComponent) loadingMessageComponent: LoadingMessageComponent;

  dataToWrite: string = 'PRODUCTO';
  containerReference: string = null;
  packingReference: string = null;
  inputPositioning: string = null;
  errorMessage: string = null;
  processInitiated: boolean;
  processActived: boolean;
  lastCodeScanned: string = 'start';
  numProducts: number = 0;
  totalProducts: number = 0;
  product: ProductModel.Product|ProductModel.SizesAndModel;
  intervalCleanLastCodeScanned = null;
  timeLastCodeScanned: number = 0;
  locations: InventoryModel.Inventories[] = [];
  actualLocation: number = null;
  container: any;

  private isStoreUser: boolean = false;
  private storeUserObj: WarehouseModel.Warehouse = null;

  private timeoutStarted = null;
  private readonly timeMillisToResetScannedCode: number = 1000;

  public isScannerBlocked: boolean = false;

  constructor(
    private alertController: AlertController,
    private warehouseService: WarehouseService,
    private inventoryService: InventoryService,
    private authenticationService: AuthenticationService,
    private intermediaryService: IntermediaryService,
    private itemReferencesProvider: ItemReferencesProvider,
    private audioProvider: AudioProvider,
    private keyboardService: KeyboardService,
    private carrierService: CarrierService,
    private productsService: ProductsService,
  ) {
    this.timeMillisToResetScannedCode = al_environment.time_millis_reset_scanned_code;
    this.focusToInput();
  }

  async ngOnInit() {
    this.isStoreUser = await this.authenticationService.isStoreUser();
    if (this.isStoreUser) {
      this.storeUserObj = await this.authenticationService.getStoreCurrentUser();
    }

    this.processInitiated = false;

    if (this.isStoreUser) {
      this.dataToWrite = 'PRODUCTO';
    }
  }

  private focusToInput(playSound: boolean = false, typeSound: 'ok' | 'error' = 'ok') {
    setTimeout(() => {
      if(document.getElementById('input-ta-locate')) {
        document.getElementById('input-ta-locate').focus();
      }
      if (playSound) {
        if (typeSound == 'ok') {
          this.audioProvider.playDefaultOk();
        } else {
          this.audioProvider.playDefaultError();
        }
      }
    }, 500);
  }

  async keyUpInput(event?, prova: boolean = false) {
    let warehouseId = this.isStoreUser ? this.storeUserObj.id : this.warehouseService.idWarehouseMain;
    let dataWrited = (this.inputPositioning || "").trim();

    if ((event.keyCode === 13 || prova && dataWrited && !this.processInitiated) && !this.isScannerBlocked) {
      this.isScannerBlocked = true;
      document.getElementById('input-ta-locate').blur();

      if (dataWrited === this.lastCodeScanned) {
        this.inputPositioning = null;
        this.isScannerBlocked = false;
        this.focusToInput();
        return;
      }
      this.lastCodeScanned = dataWrited;

      if (this.timeoutStarted) {
        clearTimeout(this.timeoutStarted);
      }
      this.timeoutStarted = setTimeout(() => this.lastCodeScanned = 'start', this.timeMillisToResetScannedCode);

      this.containerReference = null;
      this.packingReference = null;
      this.processInitiated = true;
      if (
        this.itemReferencesProvider.checkCodeValue(dataWrited) === this.itemReferencesProvider.codeValue.PRODUCT ||
        this.itemReferencesProvider.checkSpecificCodeValue(dataWrited, this.itemReferencesProvider.codeValue.PACKAGE)
      ) {
        await this.loadingMessageComponent.show(true, `Cargando`);
          this.productsService.getInfo(dataWrited).then(async (res: ProductModel.ResponseInfo) => {
            if (res.code === 200) {
              if(res.data.location.container){
                this.product = res.data;
                await this.carrierService.getContainerAndProductsModel(this.product.location.container.reference, warehouseId, this.product.model.id).subscribe(async data => {
                  this.container = data.container;
                  this.packingReference = data.container.reference;
                  this.containerReference = data.container.reference;
                  this.numProducts = data.numProducts;
                  this.totalProducts = this.numProducts;
                  await this.getInventory();
                  this.processActived = true;
                  this.inputPositioning = null;
                  this.isScannerBlocked = false;

                  this.errorMessage = null;
                });
              }else{
                if(res.data.location.packingId) {
                  await this.carrierService.getSingle(res.data.location.packingId).subscribe(async data => {
                    let packing = data.reference;
                    this.inputPositioning = null;
                    this.processInitiated = false;
                    this.isScannerBlocked = false;
                    await this.intermediaryService.presentToastError('El producto no está ubicado en almacén. Se encuentra en la jaula '+ packing +'.', "bottom");
                    await this.loadingMessageComponent.show(false);
                    await this.focusToInput(true, 'error');
                  });
                }else {
                  this.inputPositioning = null;
                  this.processInitiated = false;
                  this.isScannerBlocked = false;
                  await this.intermediaryService.presentToastError('El producto no está ubicado en almacén.', "bottom");
                  await this.loadingMessageComponent.show(false);
                  await this.focusToInput(true, 'error');
                }
              }
            }
          });
      } else {
        this.inputPositioning = null;
        this.errorMessage = '¡Referencia del producto errónea!';
        this.processInitiated = false;
        this.isScannerBlocked = false;
        this.focusToInput(true, 'error');
      }
    } else if (event.keyCode === 13 && this.isScannerBlocked) {
      this.inputPositioning = null;
      this.focusToInput();
    }
  }

  async getInventory(){
    await this.inventoryService.getContainersByModel(this.product.model.id, this.product.location.warehouse.id).subscribe(async data => {
      if (data) {
        this.locations = data;
        let cont = 0;
        this.locations.forEach(l => {
          if(this.containerReference){
            if(l.container && (l.container.id == this.product.location.container.id)){
              this.actualLocation = cont;
            }
          }
          cont++;
        });
      }
      await this.loadingMessageComponent.show(false);
      await this.focusToInput();
    });
  }

  async previousLocation(){
    await this.loadingMessageComponent.show(true, `Cargando`);
    this.actualLocation = this.actualLocation - 1;
    await this.carrierService.getContainerAndProductsModel(this.locations[this.actualLocation].container.reference, this.locations[this.actualLocation].warehouse.id, this.product.model.id).subscribe( async data => {
        this.container = data.container;
        this.packingReference = data.container.reference;
        this.containerReference = data.container.reference;
        this.numProducts = data.numProducts;
        this.totalProducts = this.numProducts;
        await this.loadingMessageComponent.show(false);
        await this.focusToInput();
    });
  }

  async nextLocation(){
    await this.loadingMessageComponent.show(true, `Cargando`);
    this.actualLocation = this.actualLocation + 1;
    await this.carrierService.getContainerAndProductsModel(this.locations[this.actualLocation].container.reference, this.locations[this.actualLocation].warehouse.id, this.product.model.id).subscribe( async data => {
      this.container = data.container;
      this.packingReference = data.container.reference;
      this.containerReference = data.container.reference;
      this.numProducts = data.numProducts;
      this.totalProducts = this.numProducts;
      await this.loadingMessageComponent.show(false);
      await this.focusToInput();
    });
  }

  public onFocus(event) {
    if (event && event.target && event.target.id) {
      this.keyboardService.setInputFocused(event.target.id);
    }
  }

  oldReference(inventory: any) {
    let alphabet = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
    return 'P' + inventory.rack.hall.toString().padStart(2, '0')
      + alphabet[inventory.row - 1]
      + inventory.column.toString().padStart(2, '0');
  }

}

import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";

@Component({
  selector: 'list-destinies',
  templateUrl: './modal-destinies.component.html',
  styleUrls: ['./modal-destinies.component.scss']
})
export class ListDestiniesComponent implements OnInit {

  public title = 'Destinos';
  private picking;
  destinies;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    this.picking = this.navParams.get('picking');
    this.destinies = this.picking.destinyWarehouse;
  }

  goToList() {
    this.modalController.dismiss();
  }

}

import { IntermediaryService } from './../../../services/src/lib/endpoint/intermediary/intermediary.service';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatSort, MatTableDataSource, Sort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { PredistributionsService } from '../../../services/src/lib/endpoint/predistributions/predistributions.service';
import { PredistributionModel } from '../../../services/src/models/endpoints/Predistribution';
import Predistribution = PredistributionModel.Predistribution;
import { FilterButtonComponent } from '../components/filter-button/filter-button.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TagsInputOption } from '../components/tags-input/models/tags-input-option.model';
import { FiltersModel } from '../../../services/src/models/endpoints/filters';
import {PaginatorComponent} from '../components/paginator/paginator.component';
import * as _ from 'lodash';
import {Subscription} from "rxjs";

@Component({
  selector: 'suite-predistributions',
  templateUrl: './predistributions.component.html',
  styleUrls: ['./predistributions.component.scss'],
})
export class PredistributionsComponent implements OnInit, OnDestroy {

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['avelonOrder','references','warehouses','date_service','brands','providers','models','colors','category','family','lifestyle', 'reserved', 'block'];
  dataSourceOriginal;
  dataSource;
  selectionPredistribution = new SelectionModel<Predistribution>(true, []);
  selectionReserved = new SelectionModel<Predistribution>(true, []);

  columns = {};

  @ViewChild('filterButtonAvelonOrder') filterButtonAvelonOrder: FilterButtonComponent;
  @ViewChild('filterButtonReferences') filterButtonReferences: FilterButtonComponent;
  @ViewChild('filterButtonWarehouses') filterButtonWarehouses: FilterButtonComponent;
  @ViewChild('filterButtonDateServices') filterButtonDateServices: FilterButtonComponent;
  @ViewChild('filterButtonBrands') filterButtonBrands: FilterButtonComponent;
  @ViewChild('filterButtonProviders') filterButtonProviders: FilterButtonComponent;
  @ViewChild('filterButtonModels') filterButtonModels: FilterButtonComponent;
  @ViewChild('filterButtonColors') filterButtonColors: FilterButtonComponent;
  @ViewChild('filterButtonCategory') filterButtonCategory: FilterButtonComponent;
  @ViewChild('filterButtonFamily') filterButtonFamily: FilterButtonComponent;
  @ViewChild('filterButtonLifestyle') filterButtonLifestyle: FilterButtonComponent;
  @ViewChild('filterButtonReserved') filterButtonReserved: FilterButtonComponent;
  @ViewChild('filterButtonBlock') filterButtonBlock: FilterButtonComponent;


  isFilteringAvelonOrder: number = 0;
  isFilteringReferences: number = 0;
  isFilteringWarehouses: number = 0;
  isFilteringDateServices: number = 0;
  isFilteringBrands: number = 0;
  isFilteringProviders: number = 0;
  isFilteringModels: number = 0;
  isFilteringColors: number = 0;
  isFilteringCategory: number = 0;
  isFilteringFamily: number = 0;
  isFilteringLifestyle: number = 0;
  isFilteringReserved: number = 0;
  isFilteringBlock: number = 0;

  /**Filters */
  avelonOrder: Array<TagsInputOption> = [];
  references: Array<TagsInputOption> = [];
  warehouses: Array<TagsInputOption> = [];
  date_service: Array<TagsInputOption> = [];
  brands: Array<TagsInputOption> = [];
  providers: Array<TagsInputOption> = [];
  models: Array<TagsInputOption> = [];
  colors: Array<TagsInputOption> = [];
  category: Array<TagsInputOption> = [];
  family: Array<TagsInputOption> = [];
  lifestyle: Array<TagsInputOption> = [];
  reserved: Array<TagsInputOption> = [];
  block: Array<TagsInputOption> = [];

  groups: Array<TagsInputOption> = [];
  entities;
  pauseListenFormChange: boolean;
  lastUsedFilter: string;

  form: FormGroup = this.formBuilder.group({
    avelonOrder: [],
    references: [],
    warehouses: [],
    date_service: [],
    brands: [],
    providers:[],
    models: [],
    colors: [],
    category: [],
    family: [],
    lifestyle: [],
    reserved: [],
    block: [],
    pagination: this.formBuilder.group({
      page: 1,
      limit: undefined
    }),
    orderby: this.formBuilder.group({
      type: 4,
      order: "asc"
    })
  });
  length: any;

  private paginatorObservable: Subscription = null;

  constructor(
    private predistributionsService: PredistributionsService,
    private formBuilder: FormBuilder,
    private intermediaryService:IntermediaryService
  ) {}

  ngOnInit(): void {
    this.initEntity();
    this.initForm();
    this.getFilters();
  }

  ngAfterViewInit(){
    this.form.patchValue({pagination:{ limit: this.paginator.finalPagerValues[0]}});
    this.getColumns(this.form);
    this.getList(this.form);
    this.listenChanges();
  }

  ngOnDestroy(): void {
    this.paginatorObservable.unsubscribe();
  }

  listenChanges() {
    if (!this.paginatorObservable) {
      let previousPageSize = this.form.value.pagination.limit;
      /**detect changes in the paginator */
      this.paginatorObservable = this.paginator.page.subscribe(page => {
        /**true if only change the number of results */
        let flag = previousPageSize === page.pageSize;
        previousPageSize = page.pageSize;
        this.form.value.pagination = {
          limit: page.pageSize,
          page: flag ? page.pageIndex : 1
        };
        this.getList(this.form)
      });
    }
  }
  initEntity() {
    this.entities = {
      avelonOrder: [],
      references: [],
      warehouses: [],
      date_service: [],
      brands: [],
      providers:[],
      models: [],
      colors: [],
      category: [],
      family: [],
      lifestyle: [],
      reserved: [],
      block: [],
    }
  }
  initForm() {
    this.form.patchValue({
      avelonOrder: [],
      references: [],
      warehouses: [],
      date_service: [],
      brands: [],
      providers:[],
      models: [],
      colors: [],
      category: [],
      family: [],
      lifestyle: [],
      reserved: [],
      block: [],
      pagination: this.formBuilder.group({
        page: 1,
        limit: undefined
      }),
    })
  }

  isAllSelectedPredistribution() {
    if (this.dataSource) {
      const numSelected = this.selectionPredistribution.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
    return false
  }

  isAllSelectedReserved() {
    if (this.dataSource) {
      const numSelected = this.selectionReserved.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
    return false
  }

  predistributionToggle() {
    if (this.isAllSelectedPredistribution()) {
      this.dataSource.data.forEach(row => {
        row.block = false;
      });

      this.selectionPredistribution.clear()
    } else {
      this.dataSource.data.forEach(row => {
        row.block = true;
        this.selectionPredistribution.select(row);
      });
    }
  }

  reservedToggle() {
    if (this.isAllSelectedReserved()) {
      this.dataSource.data.forEach(row => {
        row.reserved = false;
      });
      this.selectionReserved.clear();
    } else {
      this.dataSource.data.forEach(row => {
        row.reserved = true;
        this.selectionReserved.select(row);
      });
    }
  }

  checkboxLabelPredistribution(row?): string {
    if (!row) {
      return `${this.isAllSelectedPredistribution() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionPredistribution.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  checkboxLabelReserved(row?): string {
    if (!row) {
      return `${this.isAllSelectedReserved() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionReserved.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  changePredistribution(row, position: number) {
    this.dataSource.data[position].block = !this.dataSource.data[position].block;
  }

  changeReserved(row, position: number) {
    this.dataSource.data[position].reserved = !this.dataSource.data[position].reserved;
  }

  async savePredistributions() {
    let list = [];
    this.dataSource.data.forEach((dataRow, index) => {
      if (this.dataSourceOriginal.data[index].block !== dataRow.block || this.dataSourceOriginal.data[index].reserved !== dataRow.reserved) {
        list.push({
          block: !!dataRow.block,
          reserved: !!dataRow.reserved,
          modelId: dataRow.model.id,
          warehouseId: dataRow.warehouse.id,
          avelonOrderId: dataRow.avelonOrder,
        })
      }
    });

    if (list.length > 0) {
      await this.intermediaryService.presentLoading('Actualizando predistribuciones...', async () => {
        this.predistributionsService.updateBlockReserved(list).subscribe((data) => {
          this.intermediaryService.presentToastSuccess("Se han actualizado las predistribuciones correctamente.");
          this.getList(this.form);
        }, (error) => {
          this.intermediaryService.presentToastError("Ha ocurrido un error al intentar actualizar las predistribuciones.");
          this.intermediaryService.dismissLoading();
        }, () => {
          this.intermediaryService.dismissLoading();
        });
      });
    }
  }

  getFilters() {
    this.predistributionsService.entities().subscribe(entities => {
      this.avelonOrder = this.updateFilterSource(entities.avelonOrder, 'avelonOrder');
      this.references = this.updateFilterSource(entities.references, 'references');
      this.warehouses = this.updateFilterSource(entities.warehouses, 'warehouses');
      this.date_service = this.updateFilterSource(entities.date_service, 'date_service');
      this.brands = this.updateFilterSource(entities.brands, 'brands');
      this.providers = this.updateFilterSource(entities.providers, 'providers');
      this.models = this.updateFilterSource(entities.models, 'models');
      this.colors = this.updateFilterSource(entities.colors, 'colors');
      this.category = this.updateFilterSource(entities.category, 'category');
      this.family = this.updateFilterSource(entities.family, 'family');
      this.lifestyle = this.updateFilterSource(entities.lifestyle, 'lifestyle');
      this.reserved = this.updateFilterSourceBoolean(entities.reserved, 'reserved');
      this.block = this.updateFilterSourceBoolean(entities.block, 'block');

      this.reduceFilters(entities);
      setTimeout(() => {
        this.pauseListenFormChange = false;
        this.pauseListenFormChange = true;
      }, 0);
    })

  }
  async getList(form?: FormGroup){
    this.predistributionsService.index(form.value).subscribe(
      (resp:any) => {
        this.dataSource = new MatTableDataSource<PredistributionModel.Predistribution>(resp.results);
        const paginator = resp.pagination;
        this.paginator.cantSelect = paginator.limit;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;

        this.selectionPredistribution.clear();
        this.selectionReserved.clear();
        this.dataSource.data.forEach(row => {
          if (row.block) {
            this.selectionPredistribution.select(row);
          }
          if (row.reserved) {
             this.selectionReserved.select(row);
          }
          this.dataSourceOriginal = _.cloneDeep(this.dataSource)
        });
      },
      async err => {
        await this.intermediaryService.dismissLoading()
      },
      async () => {
        await this.intermediaryService.dismissLoading()
      }
    )
  }

  async applyFilters(filtersResult, filterType) {
    await this.intermediaryService.presentLoading();
    const filters = filtersResult.filters;
    switch (filterType) {
      case 'avelonOrder':
        let avelonOrderFiltered: string[] = [];
        for (let avelonOrder of filters) {

          if (avelonOrder.checked) avelonOrderFiltered.push(avelonOrder.id);
        }

        if (avelonOrderFiltered.length >= this.avelonOrder.length) {
          this.form.value.avelonOrder = [];
          this.isFilteringAvelonOrder = this.avelonOrder.length;
        } else {
          if (avelonOrderFiltered.length > 0) {
            this.form.value.avelonOrder = avelonOrderFiltered;
            this.isFilteringAvelonOrder = avelonOrderFiltered.length;
          } else {
            this.form.value.avelonOrder = ['99999'];
            this.isFilteringAvelonOrder = this.avelonOrder.length;
          }
        }
        break;
        case 'references':
        let referencesFiltered: string[] = [];
        for (let reference of filters) {

          if (reference.checked) referencesFiltered.push(reference.id);
        }

        if (referencesFiltered.length >= this.references.length) {
          this.form.value.references = [];
          this.isFilteringReferences = this.references.length;
        } else {
          if (referencesFiltered.length > 0) {
            this.form.value.references = referencesFiltered;
            this.isFilteringReferences = referencesFiltered.length;
          } else {
            this.form.value.references = ['99999'];
            this.isFilteringReferences = this.references.length;
          }
        }
        break;
      case 'category':
        let categoryFiltered: number[] = [];
        for (let category of filters) {
          if (category.checked) categoryFiltered.push(category.id);
        }
        if (categoryFiltered.length >= this.category.length) {
          this.form.value.category = [];
          this.isFilteringCategory = this.category.length;
        } else {
          if (categoryFiltered.length > 0) {
            this.form.value.category = categoryFiltered;
            this.isFilteringCategory = categoryFiltered.length;
          } else {
            this.form.value.category = ["99999"];
            this.isFilteringCategory = this.category.length;
          }
        }
        break;
      case 'family':
        let familyFiltered: number[] = [];
        for (let family of filters) {
          if (family.checked) familyFiltered.push(family.id);
        }
        if (familyFiltered.length >= this.family.length) {
          this.form.value.family = [];
          this.isFilteringFamily = this.family.length;
        } else {
          if (familyFiltered.length > 0) {
            this.form.value.family = familyFiltered;
            this.isFilteringFamily = familyFiltered.length;
          } else {
            this.form.value.family = ["99999"];
            this.isFilteringFamily = this.family.length;
          }
        }
        break;
      case 'lifestyle':
        let lifestyleFiltered: number[] = [];
        for (let lifestyle of filters) {
          if (lifestyle.checked) lifestyleFiltered.push(lifestyle.id);
        }
        if (lifestyleFiltered.length >= this.lifestyle.length) {
          this.form.value.lifestyle = [];
          this.isFilteringLifestyle = this.lifestyle.length;
        } else {
          if (lifestyleFiltered.length > 0) {
            this.form.value.lifestyle = lifestyleFiltered;
            this.isFilteringLifestyle = lifestyleFiltered.length;
          } else {
            this.form.value.lifestyle = ["99999"];
            this.isFilteringLifestyle = this.lifestyle.length;
          }
        }
        break;
      case 'models':
        let modelsFiltered: string[] = [];
        for (let model of filters) {

          if (model.checked) modelsFiltered.push(model.id);
        }

        if (modelsFiltered.length >= this.models.length) {
          this.form.value.models = [];
          this.isFilteringModels = this.models.length;
        } else {
          if (modelsFiltered.length > 0) {
            this.form.value.models = modelsFiltered;
            this.isFilteringModels = modelsFiltered.length;
          } else {
            this.form.value.models = [99999];
            this.isFilteringModels = this.models.length;
          }
        }

        break;
      case 'colors':
        let colorsFiltered: number[] = [];
        for (let color of filters) {
          if (color.checked) colorsFiltered.push(color.id);
        }
        if (colorsFiltered.length >= this.colors.length) {
          this.form.value.colors = [];
          this.isFilteringColors = this.colors.length;
        } else {
          if (colorsFiltered.length > 0) {
            this.form.value.colors = colorsFiltered;
            this.isFilteringColors = colorsFiltered.length;
          } else {
            this.form.value.colors = [99999];
            this.isFilteringColors = this.colors.length;
          }
        }
        break;
      case 'warehouses':
        let warehousesFiltered: number[] = [];
        for (let warehouse of filters) {
          if (warehouse.checked) warehousesFiltered.push(warehouse.id);
        }

        if (warehousesFiltered.length >= this.warehouses.length) {
          this.form.value.warehouses = [];
          this.isFilteringWarehouses = this.warehouses.length;
        } else {
          if (warehousesFiltered.length > 0) {
            this.form.value.warehouses = warehousesFiltered;
            this.isFilteringWarehouses = warehousesFiltered.length;
          } else {
            this.form.value.warehouses = [99999];
            this.isFilteringWarehouses = this.warehouses.length;
          }
        }
        break;
      case 'date_service':
        let dateServicesFiltered: number[] = [];
        for (let date_service of filters) {
          if (date_service.checked) dateServicesFiltered.push(date_service.id);
        }

        if (dateServicesFiltered.length >= this.date_service.length) {
          this.form.value.date_service = [];
          this.isFilteringDateServices = this.date_service.length;
        } else {
          if (dateServicesFiltered.length > 0) {
            this.form.value.date_service = dateServicesFiltered;
            this.isFilteringDateServices = dateServicesFiltered.length;
          } else {
            this.form.value.date_service = ['99999'];
            this.isFilteringDateServices = this.date_service.length;
          }
        }
        break;
      case 'providers':
        let providersFiltered: number[] = [];
        for (let provider of filters) {
          if (provider.checked) providersFiltered.push(provider.id);
        }
        if (providersFiltered.length >= this.providers.length) {
          this.form.value.providers = [];
          this.isFilteringProviders = this.providers.length;
        } else {
          if (providersFiltered.length > 0) {
            this.form.value.providers = providersFiltered;
            this.isFilteringProviders = providersFiltered.length;
          } else {
            this.form.value.providers = [99999];
            this.isFilteringProviders = this.providers.length;
          }
        }
        break;
      case 'brands':
        let brandsFiltered: number[] = [];
        for (let brand of filters) {
          if (brand.checked) brandsFiltered.push(brand.id);
        }
        if (brandsFiltered.length >= this.brands.length) {
          this.form.value.brands = [];
          this.isFilteringBrands = this.brands.length;
        } else {
          if (brandsFiltered.length > 0) {
            this.form.value.brands = brandsFiltered;
            this.isFilteringBrands = brandsFiltered.length;
          } else {
            this.form.value.brands = [99999];
            this.isFilteringBrands = this.brands.length;
          }
        }
        break;
      case 'reserved':
        let reservedFiltered: number[] = [];
        for (let reserved of filters) {
          if (reserved.checked) reservedFiltered.push(reserved.id);
        }
        if (reservedFiltered.length >= this.reserved.length) {
          this.form.value.reserved = [];
          this.isFilteringReserved = this.reserved.length;
        } else {
          if (reservedFiltered.length > 0) {
            this.form.value.reserved = reservedFiltered;
            this.isFilteringReserved = reservedFiltered.length;
          } else {
            this.form.value.reserved = ["99999"];
            this.isFilteringReserved = this.reserved.length;
          }
        }
        break;
      case 'block':
        let blockFiltered: number[] = [];
        for (let block of filters) {
          if (block.checked) blockFiltered.push(block.id);
        }
        if (blockFiltered.length >= this.block.length) {
          this.form.value.block = [];
          this.isFilteringBlock = this.block.length;
        } else {
          if (blockFiltered.length > 0) {
            this.form.value.block = blockFiltered;
            this.isFilteringBlock = blockFiltered.length;
          } else {
            this.form.value.block = ["99999"];
            this.isFilteringBlock = this.block.length;
          }
        }
        break;
    }
    this.lastUsedFilter = filterType;
    this.form.value.pagination.page = 1;
    this.getList(this.form);
  }

  private reduceFilters(entities){
    this.filterButtonAvelonOrder.listItems = this.reduceFilterEntities(this.avelonOrder, entities,'avelonOrder');
    this.filterButtonReferences.listItems = this.reduceFilterEntities(this.references, entities,'references');
    this.filterButtonWarehouses.listItems = this.reduceFilterEntities(this.warehouses, entities,'warehouses');
    this.filterButtonDateServices.listItems = this.reduceFilterEntities(this.date_service, entities,'date_service');
    this.filterButtonBrands.listItems = this.reduceFilterEntities(this.brands, entities,'brands');
    this.filterButtonProviders.listItems = this.reduceFilterEntities(this.providers, entities,'providers');
    this.filterButtonModels.listItems = this.reduceFilterEntities(this.models, entities,'models');
    this.filterButtonColors.listItems = this.reduceFilterEntities(this.colors, entities,'colors');
    this.filterButtonCategory.listItems = this.reduceFilterEntities(this.category, entities,'category');
    this.filterButtonFamily.listItems = this.reduceFilterEntities(this.family, entities,'family');
    this.filterButtonLifestyle.listItems = this.reduceFilterEntities(this.lifestyle, entities,'lifestyle');
    this.filterButtonReserved.listItems = this.reduceFilterEntities(this.reserved, entities,'reserved');
    this.filterButtonBlock.listItems = this.reduceFilterEntities(this.block, entities,'block');
  }

  private reduceFilterEntities(arrayEntity: any[], entities: any, entityName: string) {
    if (this.lastUsedFilter !== entityName) {
      let filteredEntity = entities[entityName] as unknown as string[];

      arrayEntity.forEach((item) => {
        item.hide = filteredEntity.includes(item.value);
      });
    }

    return arrayEntity;
  }

  private updateFilterSource(dataEntity: FiltersModel.Default[], entityName: string) {
    let resultEntity;

    this.pauseListenFormChange = true;
    let dataValue = this.form.get(entityName).value;

    resultEntity = dataEntity.map(entity => {
      entity.id = <number>(<unknown>entity.id);
      entity.name = entity.name;
      entity.value = entity.name;
      entity.checked = true;
      entity.hide = false;
      return entity;
    });

    if (dataValue && dataValue.length) {
      this.form.get(entityName).patchValue(dataValue, { emitEvent: false });
    }

    setTimeout(() => { this.pauseListenFormChange = false; }, 0);

    return resultEntity;
  }

  private updateFilterSourceBoolean(dataEntity: FiltersModel.Default[], entityName: string) {
    let resultEntity;
    this.pauseListenFormChange = true;
    let dataValue = this.form.get(entityName).value;

    resultEntity = dataEntity.map(entity => {
      entity.id = <number>(<unknown>entity.id);
      entity.name = entity.id == 0 ? 'No' : 'Sí';
      entity.value = entity.name;
      entity.checked = true;
      entity.hide = false;
      return entity;
    });

    if (dataValue && dataValue.length) {
      this.form.get(entityName).patchValue(dataValue, { emitEvent: false });
    }

    setTimeout(() => { this.pauseListenFormChange = false; }, 0);

    return resultEntity;
  }

  async getColumns(form?: FormGroup){
    // await this.intermediaryService.presentLoading();
    this.predistributionsService.index(form.value).subscribe(
      (resp:any) => {
        resp.filters.forEach(element => {
          this.columns[element.name] = element.id;
        });
      },
      async err => {
        await this.intermediaryService.dismissLoading()
      },
      async () => {
        await this.intermediaryService.dismissLoading()
      }
    )
  }

  refreshPredistributions() {
    this.paginatorObservable.unsubscribe();
    this.paginatorObservable = null;
    this.initEntity();
    this.initForm();
    this.getFilters();
    this.getList(this.form);
    this.listenChanges();
  }

  async sortData(event: Sort) {
    this.form.value.orderby.type = this.columns[event.active];
    this.form.value.orderby.order = event.direction;

    await this.intermediaryService.presentLoading('Cargando Filtros...', () => {
      this.getList(this.form);
    });
  }
}

import { Component, OnInit } from '@angular/core';

import { COLLECTIONS } from 'config/base';

@Component({
  selector: 'suite-workwaves-schedule',
  templateUrl: './workwaves-schedule.component.html',
  styleUrls: ['./workwaves-schedule.component.scss']
})
export class WorkwavesScheduleComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}

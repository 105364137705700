import {Component, OnInit, ViewChild} from '@angular/core';
import { IntermediaryService } from '../../../../services/src/lib/endpoint/intermediary/intermediary.service';
import {NavigationEnd, Router} from "@angular/router";
import {PageEventPaginator, PaginatorComponent} from "../../components/paginator/paginator.component";
import {MatTableDataSource} from "@angular/material/table";
import {MarketplacesMgaService} from "../../../../services/src/lib/endpoint/marketplaces-mga/marketplaces-mga.service";
import {SelectionModel} from "@angular/cdk/collections";
import {ModalController} from "@ionic/angular";
import {UpdateParameterizationIvaComponent} from "./update-parameterization-iva/update-parameterization-iva.component";
import {CreateParameterizationIvaComponent} from "./create-parameterization-iva/create-parameterization-iva.component";

@Component({
  selector: 'suite-parameterization-iva',
  templateUrl: './parameterization-iva.component.html',
  styleUrls: ['./parameterization-iva.component.scss']
})
export class ParameterizationIvaComponent implements OnInit {

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  dataSource;
  public displayedColumns: string[] = ['select', 'country', 'isoCode', 'iva', 'nameShippingCost'];
  dataTable = [];
  parameterizationIva;
  selection;
  paginationOptions = {
    limit: undefined,
    page: 1
  };

  constructor(
    public router: Router,
    private intermediaryService: IntermediaryService,
    private marketplacesMgaService: MarketplacesMgaService,
    private modalController: ModalController,
  ) {
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd && val && val.url == '/parameterization-iva'){
        if(typeof this.parameterizationIva !== 'undefined'){
          this.reset();
        }
      }
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.selection = new SelectionModel(true, []);
    this.paginator.page.subscribe(paginator => {
      if (this.paginationOptions.limit != paginator.pageSize) {
        this.paginationOptions.page = 1;
      } else {
        this.paginationOptions.page = paginator.pageIndex;
      }
      this.paginationOptions.limit = paginator.pageSize;
      this.loadParameterizationIva();
    });
  }

  ngAfterViewInit(){
    this.paginationOptions.limit = this.paginator.finalPagerValues[0];
    this.loadParameterizationIva();
  }

  async loadParameterizationIva(){
    const parameters = {
      pagination: this.paginationOptions
    };

    this.dataTable = [];
    await this.marketplacesMgaService.loadParameterizationIva(parameters).subscribe(data => {
      let dataIva = data.result;
      for(let i = 0; i < dataIva.length; i++){
        this.dataTable.push({
          id: dataIva[i].id,
          country: dataIva[i].name,
          isoCode: dataIva[i].code,
          iva: dataIva[i].percentage,
          nameShippingCost: dataIva[i].default == true ? 'ENVIO' : 'ENVIO_' + dataIva[i].code,
          deleted: dataIva[i].deleted,
          default: dataIva[i].default
        });
      }

      this.dataSource = new MatTableDataSource(this.dataTable);
      const paginator = data.pagination;
      this.paginator.cantSelect = paginator.limit;
      this.paginator.length = paginator.totalResults;
      this.paginator.pageIndex = paginator.selectPage;
      this.paginator.lastPage = paginator.lastPage;
    });
  }



  reset(){
    this.paginationOptions = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 1;
    this.loadParameterizationIva();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  async deleteRules(dataRule){
    await this.marketplacesMgaService.deleteParameterizationIva(dataRule).subscribe(count => {
      this.loadParameterizationIva();
      this.intermediaryService.presentToastSuccess("Reglas eliminadas con éxito");
    }, error => {
      this.loadParameterizationIva();
      this.intermediaryService.presentToastError("Error al eliminar las reglas");
    });
  }

  private index: number = 0;
  async confirmDeletion(){
    let dataRule = {};
    let itemsDelete = this.selection.selected;

    if (itemsDelete.length > 0 && this.index < itemsDelete.length) {
      if (itemsDelete[this.index].default == false) {
        await this.intermediaryService.presentConfirm('¿Está seguro que quiere eliminar la regla de: ' + itemsDelete[this.index].country + '?', async () => {
            try {
              if (itemsDelete.length > 0 && this.index < itemsDelete.length) {
                if (itemsDelete[this.index].default == false) {
                  dataRule = {
                    deleted: true,
                    id: itemsDelete[this.index].id
                  };
                  this.deleteRules(dataRule);
                  this.index++;
                  await this.confirmDeletion();
                }
              }
            }catch(e){
              console.error('ERROR: ',e);
            }
          }, async () => {
            try {
              if (itemsDelete.length > 0 && this.index < itemsDelete.length) {
                if (itemsDelete[this.index].default == false) {
                  this.index++;
                  await this.confirmDeletion();
                }
              }
            }catch(e){
              console.error('ERROR: ',e);
            }
          }
        );
      }else{
        await this.intermediaryService.presentWarning('La regla "Por defecto" no puede eliminarse.', async () => {
          this.index++;
          await this.confirmDeletion();
        });
      }
    }else{
      if(this.index >= itemsDelete.length){
        this.index = 0;
        this.selection.clear();
      }
    }
  }

  async openModalUpdateRule(event, element){
    event.stopPropagation();
    event.preventDefault();

    let modal = await this.modalController.create({
      component: UpdateParameterizationIvaComponent,
      componentProps: {
        element,
        mode: 'update'
      }
    });

    modal.onDidDismiss().then((data) => {
      this.loadParameterizationIva()
    });
    modal.present();
  }

  async openModalCreateRule(element){
    let modal = await this.modalController.create({
      component: CreateParameterizationIvaComponent,
      componentProps: {
        element,
        mode: 'create'
      }
    });

    modal.onDidDismiss().then((data) => {
      this.loadParameterizationIva()
    });
    modal.present();
  }

}

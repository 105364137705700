import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatTableModule } from '@angular/material';
import { MatListModule } from '@angular/material';
import { ProductsComponent } from './products.component';
import { RouterModule, Routes } from "@angular/router";
import { MatPaginatorModule } from '@angular/material';
import { ProductDetailsModule } from './modals/product-details/product-details.module';
import { GenerateExcelModule } from './modals/generate-excel/generate-excel.module';
import { BreadcrumbModule } from '../components/breadcrumb/breadcrumb.module';
import { TagsInputModule } from '../components/tags-input/tags-input.module';
import { PaginatorComponentModule } from '../components/paginator/paginator.component.module';
import { ProductRelocationModule } from './modals/product-relocation/product-relocation.module';
import { FilterButtonModule } from "../components/filter-button/filter-button.module";
import { MatTooltipModule } from "@angular/material";


const routes: Routes = [
  {
    path: '',
    component: ProductsComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    BreadcrumbModule,
    RouterModule.forChild(routes),
    ProductDetailsModule,
    GenerateExcelModule,
    ProductRelocationModule,
    TagsInputModule,
    PaginatorComponentModule,
    FilterButtonModule,
    MatTooltipModule
  ],
  declarations: [ProductsComponent]
})
export class ProductsModule { }

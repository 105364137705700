import { Component, OnInit, ViewChild  } from '@angular/core';
import {WorkwavesService} from "../../../../services/src/lib/endpoint/workwaves/workwaves.service";
import {Observable} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {
  WorkwaveModel
} from "../../../../services/src/models/endpoints/Workwaves";
import {Router} from "@angular/router";
import {PickingService} from "../../../../services/src/lib/endpoint/picking/picking.service";
import {PickingModel} from "../../../../services/src/models/endpoints/Picking";
import {PickingProvider} from "../../../../services/src/providers/picking/picking.provider";
import {IntermediaryService} from "@suite/services";
import {MatPaginator} from "@angular/material";
import FilterOptionsHistoryResponse = WorkwaveModel.FilterOptionsHistoryResponse;
import Pagination = WorkwaveModel.Pagination;
import Filters = WorkwaveModel.FiltersHistory;
import FilterOptions = WorkwaveModel.FilterOptionsHistory;
import Order = WorkwaveModel.Order;
import Workwave = WorkwaveModel.Workwave;
import SearchParametersHistory = WorkwaveModel.SearchParametersHistory;
import SearchResponse = WorkwaveModel.SearchResponse;
import {DateTimeParserService} from "../../../../services/src/lib/date-time-parser/date-time-parser.service";
import {PaginatorComponent} from "../../components/paginator/paginator.component";

@Component({
  selector: 'list-workwaves-history',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListWorkwavesHistoryComponent implements OnInit {

  public workwavesHistory: any[] = [];
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  public displayedColumns: string[] = ['check', 'warehouse', 'user', 'date', 'description'];

  filters: Filters = {
    warehouses: [],
    types: [],
    users: [],
    dates: [],
  };
  filterOptions: FilterOptions = {
    warehouses: [],
    types: [],
    users: [],
    dates: [],
  };
  order: Order = {
    field: 'date',
    direction: 'DESC'
  };
  pagination: Pagination = {
    limit: undefined,
    page: 1
  };

  constructor(
    private router: Router,
    private workwavesService: WorkwavesService,
    private pickingService: PickingService,
    private pickingProvider: PickingProvider,
    private intermediaryService: IntermediaryService,
    private dateTimeParserService: DateTimeParserService
  ) {}

  ngOnInit() {
    this.loadFilters();
    this.paginator.page.subscribe(paginator => {
      let flag = false;
      if(paginator.pageSize != this.pagination.limit){
        flag = true;
      }
      this.pagination.limit = paginator.pageSize;
      this.pagination.page = flag ? 1 : paginator.pageIndex;
      this.loadWorkwavesTemplates();
      this.intermediaryService.dismissLoading();
    });
  }

  ngAfterViewInit(){
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadWorkwavesTemplates();
  }

  loadFilters(){
    this.workwavesService.getFilterOptionsHistory().then((response: FilterOptionsHistoryResponse) => {
      if(response.code == 200){
        this.filterOptions = response.data;
      }else{
        console.error(response.message);
      }
    }).catch(console.error);
  }

  loadWorkwavesTemplates() {
    const parameters: SearchParametersHistory = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };
    this.workwavesService.getListExecuted(parameters).then((response: SearchResponse) => {
      if(response.code == 200){
        this.workwavesHistory = response.data.result;
        let paginator: any = response.data.pagination;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;
      }
      this.intermediaryService.dismissLoading();
    });
  }


  orderBy(column: string){
    if(this.order.field == column){
      this.order.direction == 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.loadWorkwavesTemplates();
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        values.push(item.id);
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.pagination.page = 1;
    this.loadWorkwavesTemplates();
  }

  showWorkwave(workwave) {
    this.workwavesService.lastWorkwaveEdited = null;
    this.workwavesService.lastWorkwaveHistoryQueried = workwave;
    this.pickingProvider.listPickingsHistory = null;
    this.router.navigate(['workwaves-history/detail/'+workwave.id]);    
  }

  loadWorkWave(){
    this.intermediaryService.presentLoading("Actualizando...");
    this.pagination.page = 1;
    const response = this.loadWorkwavesTemplates();
    this.intermediaryService.dismissLoading();
    return response;
  }

  reset(){
    this.filters = {
      warehouses: [],
      types: [],
      users: [],
      dates: []
    };
    this.order = {
      field: 'releaseDate',
      direction: 'DESC'
    };
    this.pagination = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 0;
    this.loadFilters();
    this.loadWorkwavesTemplates();
  }

}

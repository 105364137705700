import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AlertController, ModalController} from '@ionic/angular';
import { MatSort} from '@angular/material/sort';
import {NewRuleComponent} from './new-rule/new-rule.component';
import {MarketplacesService} from '../../../../services/src/lib/endpoint/marketplaces/marketplaces.service';
import {MatTableDataSource} from '@angular/material';
import { IntermediaryService} from '@suite/services';
@Component({
  selector: 'suite-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})

export class RulesComponent implements OnInit {

  dataSourceCategories;
  dataSourceRulesCategories;
  displayedCategoriesColumns;

  dataSourceEnabling;
  dataSourceRulesEnabling;
  displayedEnablingColumns;

  market;
  marketExternalId;
  marketName;

  constructor(
    private route: ActivatedRoute,
    private modalController: ModalController,
    private marketplacesService: MarketplacesService,
    private alertController : AlertController,
    private intermediaryService : IntermediaryService,
  ) {
  }
  
  async ngOnInit() {

    this.dataSourceCategories = [];
    this.dataSourceRulesCategories = new MatTableDataSource(this.dataSourceCategories);
    this.displayedCategoriesColumns = ['id', 'name', 'categories', 'products', 'edit','copy', 'delete'];
   
    this.dataSourceEnabling = [];
    this.dataSourceRulesEnabling = new MatTableDataSource(this.dataSourceEnabling);
    this.displayedEnablingColumns = ['id', 'name', 'categories', 'products', 'edit','copy', 'delete'];
    
    this.market = '';
    this.marketExternalId = '';

    await this.route.params.subscribe(params=>{
      const marketPlaceInfo = this.marketplacesService.getCachedMarketById(params.id)
      if(marketPlaceInfo) {
        this.marketName = marketPlaceInfo.name;
        this.market = marketPlaceInfo.reId;
        this.marketExternalId = marketPlaceInfo.externalId;
      }
    });
    this.getValues();
  }

  @ViewChild('categoriesTableSort') public categoriesTableSort: MatSort;
  @ViewChild('minimumTableSort') public minimumTableSort: MatSort;

  getValues() {
    this.dataSourceEnabling = [];
    this.dataSourceCategories = [];
    this.marketplacesService.getRulesConfigurations(this.market).subscribe((data: any) => {
      if (data && data.length) {
        for (let ruleConfiguration of data) {
          let type = "";
          let categories = [];
          switch (ruleConfiguration.ruleType) {
            case 0:
              break;
            case 1:
              type = "enabling";
              break;
            case 2:
              type = "categories";
              categories = ruleConfiguration.categories ? ruleConfiguration.categories : [];
              break;
          }
        
          let rule = {
            id: ruleConfiguration.id,
            idFront: ruleConfiguration.id.substring(0,3), //id for front
            name: ruleConfiguration.name,
            filterType: type,
            categoriesFilter: [],
            minPriceFilter: null,
            maxPriceFilter: null,
            products: ruleConfiguration.products,
            referencesExceptions: [],
            description: ruleConfiguration.description,
            categories: categories
          };

          if (ruleConfiguration.rulesFilters) {
            for (let ruleFilter of ruleConfiguration.rulesFilters) {
              if (ruleFilter.ruleFilterType != 6) {
                let category = {
                  id: ruleFilter.id,
                  name: ruleFilter.name,
                  externalId: ruleFilter.externalId,
                  type: ruleFilter.ruleFilterType,
                  group: 0
                };
                switch (ruleFilter.ruleFilterType) {
                  case 2:
                    category.group = ruleFilter.dataGroup;
                    break;
                  case 3:
                    category.group = 16;
                    break;
                  case 4:
                    category.group = 17;
                    break;
                  case 5:
                    category.group = 15;
                    break;
                }
                rule.categoriesFilter.push(category);
              } else {
                rule.minPriceFilter = ruleFilter.minPrice;
                rule.maxPriceFilter = ruleFilter.maxPrice;
              }
            }
          }

          if (ruleConfiguration.referenceExceptions) {
            for (let exception of ruleConfiguration.referenceExceptions) {
              rule.referencesExceptions.push(
                {
                  reference: exception.reference,
                  type: exception.exceptionType ? "include" : "exclude"
                }
              );
            }
          }

          switch (rule.filterType) {
            case "enabling":
              this.dataSourceEnabling.push(rule);
              break;

            case "categories":
              this.dataSourceCategories.push(rule);
              break;
          }
        }
    
        this.dataSourceRulesEnabling = new MatTableDataSource(this.dataSourceEnabling);
        this.dataSourceRulesCategories = new MatTableDataSource(this.dataSourceCategories);

        setTimeout(() => this.dataSourceRulesCategories.sort = this.categoriesTableSort);
        setTimeout(() => this.dataSourceRulesEnabling.sort = this.minimumTableSort);
    }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceRulesCategories.filter = filterValue;
    this.dataSourceRulesCategories.filterPredicate = function(data, filter: string): boolean { 
      return data.name.toLowerCase().includes(filter) || data.idFront.toLowerCase().includes(filter);
      };
  }

  async openModalNewRule(ruleFilterType): Promise<void> {
    let modal = await this.modalController.create({
      component: NewRuleComponent,
      componentProps: {
        ruleFilterType,
        mode: 'create',
        market: this.market,
        marketExternalId: this.marketExternalId
      }
    });

    let updateData = {
      marketReId:this.market
    };

    modal.onDidDismiss().then((data) => {
      if (data && data.data) {

        let minPrice = data.data.minPriceFilter !== null ? data.data.minPriceFilter : null;
        let maxPrice = data.data.maxPriceFilter !== null ? data.data.maxPriceFilter : null;

        let ruleConfiguration = {
          name: data.data.name,
          description: data.data.description,
          ruleType: 0,
          status: 1,
          rulesFilterIds: [],
          marketsIds: [
            this.market
          ],
          referenceExceptions: {},
          ruleDataValidactionAttributes: [],
          categories: data.data.destinationCategories
        };

        switch (data.data.filterType) {
          case "enabling":
            ruleConfiguration.ruleType = 1;
            break;

          case "categories":
            ruleConfiguration.ruleType = 2;
            break;
        }

        for (let category of data.data.categoriesFilter) {
          switch (category.type) {
            case 2:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  dataGroup: category.group,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
            case 3:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  dataGroup: category.group,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
            case 4:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  dataGroup: category.group,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
            case 5:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  dataGroup: category.group,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
          }
        }

        if (minPrice && maxPrice) {
          ruleConfiguration.rulesFilterIds.push(
            {
              id: 0,
              name: null,
              ruleFilterType: 6,
              externalId: null,
              dataGroup: null,
              minPrice,
              maxPrice,
              status: 1
            }
          );
        }

        let exceptions = {};
        data.data.referencesExceptions.forEach(item => {
          if (item.type == 'include') {
            exceptions[item.reference] = 1;
          } else {
            exceptions[item.reference] = 0;
          }
        });

        ruleConfiguration.referenceExceptions = exceptions;

        this.marketplacesService.postRulesConfigurations(ruleConfiguration).subscribe(data => {
          this.getValues();
          this.marketplacesService.updateCategoriesMinPrices(updateData).subscribe(result => {});
        });
      }
    });

    modal.present();
  }

  async deleteRuleAlert(ruleType, rule, index): Promise<void> {
    
    const alert = await this.alertController.create(
      {
        header: `Atención`,
        message: `Va a eliminar la siguiente regla: <strong>${rule.name}</strong><br><br>
        ¿Está seguro de que desea continuar?`,
        buttons: [
          'Cancelar',
          {
            text : 'Aceptar',
            handler: async () => {
              await this.deleteRule(ruleType, rule, index);
            }
          }
        ]
      }
    );

    await alert.present();
  }

  async deleteRule(ruleType, rule, index) {
    
    let deleteData = {
      marketReId: this.market
     }
    
    await this.intermediaryService.presentLoadingNew('Eliminando regla...')
    this.marketplacesService.deleteRule(rule.id).subscribe(response => {
      if (ruleType == 'categories'){
          this.dataSourceCategories.splice(index, 1)
          this.dataSourceRulesCategories = new MatTableDataSource(this.dataSourceCategories)
        } else {
          this.dataSourceEnabling.splice(index, 1)
          this.dataSourceRulesEnabling = new MatTableDataSource(this.dataSourceEnabling)
      }
      this.marketplacesService.updateCategoriesMinPrices(deleteData).subscribe(forceUpdateResponse=>{});
    });
    await this.intermediaryService.dismissLoadingNew();
    this.getValues();
  }

  async editRule(ruleToEdit): Promise<void> {
    let rule = JSON.parse(JSON.stringify(ruleToEdit));
    let modal = await this.modalController.create({
      component: NewRuleComponent,
      componentProps: {
        ruleFilterType: rule.filterType,
        ruleName: rule.name,
        selectedCategories: rule.categoriesFilter,
        minPriceFilter: rule.minPriceFilter !== null ? parseFloat(rule.minPriceFilter).toFixed(2) : null,
        maxPriceFilter: rule.maxPriceFilter !== null ? parseFloat(rule.maxPriceFilter).toFixed(2) : null,
        selectedDestinationCategories: rule.categories,
        referencesExceptions: rule.referencesExceptions,
        id: rule.id,
        mode: 'edit',
        market: this.market,
        marketExternalId: this.marketExternalId
      }
    });

    let updateData = {
      marketReId: this.market
    };

    modal.onDidDismiss().then((data) => {
      if (data && data.data) {

        let minPrice = data.data.minPriceFilter !== null ? data.data.minPriceFilter : null;
        let maxPrice = data.data.maxPriceFilter !== null ? data.data.maxPriceFilter : null;

        let ruleConfiguration = {
          id: ruleToEdit.id,
          name: data.data.name,
          description: data.data.description,
          ruleType: 0,
          status: 1,
          rulesFilterIds: [],
          marketsIds: [
            this.market
          ],
          referenceExceptions: {},
          ruleDataValidactionAttributes: [
          ],
          categories: data.data.destinationCategories,
          products: data.data.products
        };

        switch (data.data.filterType) {
          case "enabling":
            ruleConfiguration.ruleType = 1;
            break;

          case "categories":
            ruleConfiguration.ruleType = 2;
            break;
        }

        for (let category of data.data.categoriesFilter) {
          switch (category.type) {
            case 2:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  dataGroup: category.group,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
            case 3:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
            case 4:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
            case 5:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
          }
        }

        if (minPrice && maxPrice) {
          ruleConfiguration.rulesFilterIds.push(
            {
              id: 0,
              name: null,
              ruleFilterType: 6,
              externalId: null,
              dataGroup: null,
              minPrice,
              maxPrice,
              status: 1
            }
          );
        }

        let exceptions = {};
        data.data.referencesExceptions.forEach(item => {
          if (item.type == 'include') {
            exceptions[item.reference] = 1;
          } else {
            exceptions[item.reference] = 0;
          }
        });

        ruleConfiguration.referenceExceptions = exceptions;

        this.marketplacesService.updateRulesConfigurations(ruleToEdit.id, ruleConfiguration).subscribe(data => {
          this.getValues();
          this.marketplacesService.updateCategoriesMinPrices(updateData).subscribe(result => {});
        });
      }
    });
    modal.present();
  }

  async cloneRule(ruleToClone): Promise<void> {
    let rule = JSON.parse(JSON.stringify(ruleToClone));
    let modal = await this.modalController.create({
      component: NewRuleComponent,
      componentProps: {
        ruleFilterType: rule.filterType,
        ruleName: rule.name + ' (Copia)',
        selectedCategories: rule.categoriesFilter,
        minPriceFilter: rule.minPriceFilter !== null ? parseFloat(rule.minPriceFilter).toFixed(2) : null,
        maxPriceFilter: rule.maxPriceFilter !== null ? parseFloat(rule.maxPriceFilter).toFixed(2) : null,
        selectedDestinationCategories: rule.categories,
        referencesExceptions: rule.referencesExceptions,
        id: rule.id,
        mode: 'clone',
        market: this.market,
        marketExternalId: this.marketExternalId
      }
    });

    let updateData = {
      marketReId: this.market
    };

    modal.onDidDismiss().then((data) => {
      if (data && data.data) {

        let minPrice = data.data.minPriceFilter !== null ? data.data.minPriceFilter : null;
        let maxPrice = data.data.maxPriceFilter !== null ? data.data.maxPriceFilter : null;

        let ruleConfiguration = {
          name: data.data.name,
          description: data.data.description,
          ruleType: 0,
          status: 1,
          rulesFilterIds: [],
          marketsIds: [
            this.market
          ],
          referenceExceptions: {},
          ruleDataValidactionAttributes: [],
          categories: data.data.destinationCategories
        };

        switch (data.data.filterType) {
          case "enabling":
            ruleConfiguration.ruleType = 1;
            break;

          case "categories":
            ruleConfiguration.ruleType = 2;
            break;
        }

        for (let category of data.data.categoriesFilter) {
          switch (category.type) {
            case 2:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  dataGroup: category.group,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
            case 3:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  dataGroup: category.group,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
            case 4:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  dataGroup: category.group,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
            case 5:
              ruleConfiguration.rulesFilterIds.push(
                {
                  id: category.id,
                  name: category.name,
                  ruleFilterType: category.type,
                  externalId: category.externalId,
                  dataGroup: category.group,
                  minPrice: null,
                  maxPrice: null,
                  status: 1
                }
              );
              break;
          }
        }

        if (minPrice && maxPrice) {
          ruleConfiguration.rulesFilterIds.push(
            {
              id: 0,
              name: null,
              ruleFilterType: 6,
              externalId: null,
              dataGroup: null,
              minPrice,
              maxPrice,
              status: 1
            }
          );
        }

        let exceptions = {};
        data.data.referencesExceptions.forEach(item => {
          if (item.type == 'include') {
            exceptions[item.reference] = 1;
          } else {
            exceptions[item.reference] = 0;
          }
        });

        ruleConfiguration.referenceExceptions = exceptions;

        this.marketplacesService.postRulesConfigurations(ruleConfiguration).subscribe(data => {
          this.getValues();
          this.marketplacesService.updateCategoriesMinPrices(updateData).subscribe(result => {});
        });
      }
    });
    
    modal.present();
  }
}
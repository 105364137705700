import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './paginator.component';
import { MatListModule } from '@angular/material';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {ResponsiveLayoutModule} from "../responsive-layout/responsive-layout.module";

@NgModule({
  declarations: [PaginatorComponent],
  imports: [
    CommonModule,
    IonicModule,
    MatListModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ResponsiveLayoutModule
  ],
  exports:[PaginatorComponent]
})
export class PaginatorComponentModule { }

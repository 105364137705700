import { Component, OnInit } from '@angular/core';
import { BarcodeScannerService } from 'libs/services/src/lib/barcode-scanner/barcode-scanner.service';

@Component({
  selector: 'suite-locate-defective',
  templateUrl: './locate-defective.component.html',
  styleUrls: ['./locate-defective.component.scss']
})
export class LocateDefectiveComponent implements OnInit {

  constructor(private barcodeScannerService: BarcodeScannerService) { }

  async scanBarcode(action) {
    var scanResult = await (await this.barcodeScannerService.useScanner(action));
  }

  ngOnInit() {
  }

}

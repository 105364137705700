import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AuditsComponent } from './audits.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatListModule, MatTableModule, MatPaginatorModule, MatSlideToggleModule, MatExpansionModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatDialogModule, MatCardModule, MatCheckboxModule, MatSortModule } from '@angular/material';
import { BreadcrumbModule } from '../components/breadcrumb/breadcrumb.module';
import { ModalsModule } from '../sorter/modals/modals.module';
import { ProductsByAuditComponent } from './modals/products-by-audit/products-by-audit.component';
import {PaginatorComponentModule} from "../components/paginator/paginator.component.module";
import { FilterButtonModule } from '../components/filter-button/filter-button.module';
import { OverlayModule,OverlayContainer } from '@angular/cdk/overlay';

const routes: Routes = [
  {
    path: '',
    component: AuditsComponent
  }
];

export class CdkOverlayContainer extends OverlayContainer {
  
  protected _createContainer(): void {
    const container = this._document.createElement('div');

    container.classList.add('cdk-overlay-container', 'test');
    this._document.body.appendChild(container);
    this._containerElement = container;
  }
}

@NgModule({
  declarations: [AuditsComponent,ProductsByAuditComponent],
  entryComponents: [ProductsByAuditComponent],
  imports: [
    CommonModule,
    IonicModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    BreadcrumbModule,
    ModalsModule, 
    MatExpansionModule,
    MatSlideToggleModule,
    RouterModule.forChild(routes),
    PaginatorComponentModule,
    FilterButtonModule,
    MatSortModule
  ],
  providers:[{ provide: OverlayContainer, useClass: CdkOverlayContainer },]
})
export class AuditsModule { }

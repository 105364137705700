import {Injectable} from '@angular/core';
import {ScanditProvider} from "../../../providers/scandit/scandit.provider";
import {ScanditModel} from "../../../models/scandit/Scandit";
import {Router} from "@angular/router";
import {AuditsService, environment} from "@suite/services";
import {AuditsModel} from "../../../models/endpoints/Audits";
import {ItemReferencesProvider} from "../../../providers/item-references/item-references.provider";

declare let ScanditMatrixSimple;

@Injectable({
  providedIn: 'root'
})
export class AuditMultipleScanditService {

  private NOTICE_BUBBLE_ACTION: number = 1;
  private NOTICE_BUBBLE_ERROR: number = 2;

  private CUSTOM_SOUND_OK: number = 1;
  private CUSTOM_SOUND_ERROR: number = 2;

  private packingReference: string;
  private packingAuditsCreated: {} = {};

  private supportPackingReference;

  constructor(
    private router: Router,
    private auditsService: AuditsService,
    private scanditProvider: ScanditProvider,
    private itemReferencesProvider: ItemReferencesProvider
  ) {}

  public init() {
    this.packingReference = null;
    this.packingAuditsCreated = {};
    ScanditMatrixSimple.initAuditMultiple((response: ScanditModel.ResponseAuthMultiple) => {
      if (response && response.result && response.actionIonic) {
        this.executeAction(response.actionIonic, response.params);
      }
      if (response.result && response.barcode) {
        let codeScanned = response.barcode.data;
        if (this.itemReferencesProvider.checkCodeValue(codeScanned) == this.itemReferencesProvider.codeValue.PRODUCT) {
          if (this.packingReference) {
            this.checkProductInPacking(this.packingReference, codeScanned);
          } else {
            ScanditMatrixSimple.sound(this.CUSTOM_SOUND_ERROR);
            this.changeNotice("Escanea un embalaje para comenzar la verificación", this.NOTICE_BUBBLE_ERROR);
            ScanditMatrixSimple.setTimeout("scanPacking", 2 * 1000);
          }
        } else if (this.itemReferencesProvider.checkCodeValue(codeScanned) == this.itemReferencesProvider.codeValue.PACKING) {
          if (!this.packingAuditsCreated[codeScanned]) {
            this.createAudit(codeScanned);
          } else {
            this.packingOkScanProducts(codeScanned);
          }
        }
      } else {
        if (response.exit) {
          if (response.manual) {
            this.router.navigate(['audits/add']);
          } else {
            this.router.navigate(['audits']);
          }
        }

        if (response.action) {
          switch (response.action) {
            case 'request':
              let responseData = null;

              if (response.data) {
                responseData = JSON.parse(response.data);
              }

              switch (response.requestType) {
                case "postCheckProductInPacking":
                  if (response.code == 201) {
                    ScanditMatrixSimple.sound(this.CUSTOM_SOUND_OK);
                  } else {
                    this.productKO();
                  }
                  break;

                case "postCreateAudit":
                  if (response.code == 201) {
                    ScanditMatrixSimple.sound(this.CUSTOM_SOUND_OK);
                    this.packingAuditsCreated[this.supportPackingReference] = true;
                    this.packingOkScanProducts(this.supportPackingReference);
                  } else if (response.code == 422) {
                    ScanditMatrixSimple.sound(this.CUSTOM_SOUND_ERROR);
                    this.changeNotice(responseData.errors, this.NOTICE_BUBBLE_ERROR);

                    setTimeout(() => {
                      this.changeNotice("Escanea el embalaje a revisar", this.NOTICE_BUBBLE_ACTION);
                    }, 2 * 1000);
                  } else {
                    let msgError = `Ha ocurrido un error al intentar iniciar una auditoría para ${this.supportPackingReference}.`;
                    if (responseData.errors) {
                      msgError = responseData.errors;
                    } else if (responseData.message) {
                      msgError = responseData.message;
                    }
                    ScanditMatrixSimple.sound(this.CUSTOM_SOUND_ERROR);
                    this.changeNotice(msgError, this.NOTICE_BUBBLE_ERROR);

                    setTimeout(() => {
                      this.changeNotice("Escanea el embalaje a revisar", this.NOTICE_BUBBLE_ACTION);
                    }, 2 * 1000);
                  }
                  break;
              }
              break;
          }
        }
      }
    }, 'Auditoría', this.scanditProvider.colorsHeader.background.color, this.scanditProvider.colorsHeader.color.color);
  }

  private changeNotice(message: string, type: number) {
    ScanditMatrixSimple.changeNoticeAuditMultiple(message, type);
  }

  private packingOkScanProducts(packingReference: string) {
    this.packingReference = packingReference;
    ScanditMatrixSimple.sound(this.CUSTOM_SOUND_OK);
    this.changeNotice("Escanea los productos del embalaje", this.NOTICE_BUBBLE_ACTION);
  }

  private productKO() {
    ScanditMatrixSimple.sound(this.CUSTOM_SOUND_ERROR);
    ScanditMatrixSimple.wrongCodeAuditMultiple();
  }

  //region API connection
  private createAudit(packingReference: string) {
    this.supportPackingReference = packingReference;
    ScanditMatrixSimple.request("POST", environment.apiBase + '/sorter/audit', {
      packingReference,
      status: 1,
      type: 2
    }, localStorage.getItem("access_token"), "postCreateAudit");
  }

  private checkProductInPacking(packingReference: string, productReference: string) {
    ScanditMatrixSimple.request("POST", environment.apiBase + '/sorter/audit/packing/product', {
      packingReference,
      productReference
    }, localStorage.getItem("access_token"), "postCheckProductInPacking");
  }

  private executeAction(action: string, paramsString: string){
    let params = [];
    try{
      params = JSON.parse(paramsString);
    } catch (e) {

    }
    switch (action){
      case 'scanPacking':
        this.changeNotice("Escanea el embalaje a revisar", this.NOTICE_BUBBLE_ACTION);
        break;
    }
  }

  //endregion
}

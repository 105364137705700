import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditsService, IntermediaryService } from '@suite/services';
import {ToolbarProvider} from "../../../services/src/providers/toolbar/toolbar.provider";
import {Router, ActivatedRoute} from '@angular/router';
import { Subject } from 'rxjs';
import {AuditMultipleScanditService} from "../../../services/src/lib/scandit/audit-multiple/audit-multiple.service";
import { TimesToastType } from '../../../services/src/models/timesToastType';
import {TagsInputOption} from "../components/tags-input/models/tags-input-option.model";
import {PaginatorComponent} from "../components/paginator/paginator.component";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'suite-audits-mobile',
  templateUrl: './audits-mobile.component.html',
  styleUrls: ['./audits-mobile.component.scss']
})
export class AuditsMobileComponent implements OnInit {

  public Auditories : any = [];

  public static returned: Subject<any> = new Subject();

  pagerValues = [20, 50, 100];
  pauseListenFormChange = false;
  requestTimeout;

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  filtersOpen: boolean;

  /**Filters */
  packings: Array<TagsInputOption> = [];

  form: FormGroup = this.formBuilder.group({
    packings: [],
    pagination: this.formBuilder.group({
      page: 1,
      limit: this.pagerValues[0]
    }),
  });

  constructor(
    private audit : AuditsService,
    private intermediaryService : IntermediaryService,
    private toolbarProvider: ToolbarProvider,
    private router : Router,
    private activeRoute: ActivatedRoute,
    private auditMultipleScanditService: AuditMultipleScanditService,
    private formBuilder: FormBuilder,
  ) {
    AuditsMobileComponent.returned.subscribe(res => {
      this.getAllAudits();
    });
  }

  ngOnInit() {
    this.toolbarProvider.currentPage.next('Control de embalajes');
    this.filtersOpen = false;
    this.initForm();
    this.loadToolbarActions();
    this.listenChanges();
    this.getAllAudits();
  }

  private loadToolbarActions() {
    let actionsToolbar = [];

    actionsToolbar = [
      {
        icon: 'funnel',
        label: 'Filtros',
        action: () => this.filtersOpen = !this.filtersOpen
      }];

    this.toolbarProvider.optionsActions.next(actionsToolbar);
  }

  initForm() {
    this.form.patchValue({
      packings: [],
    });
    this.packings = [];
    this.getFilters();
  }


  getFilters(): void {
    this.audit.getFilters().subscribe((filters) => {
      this.packings = filters.packings;
      this.applyFilters();
    }, (err) => {
      console.log(err);
    });
  }

  getAllAudits(){
    this.audit.getAll(this.form.value).subscribe(res =>{
      this.Auditories = res.data.results;
      const paginator = res.data.pagination;
      this.paginator.length = paginator.totalResults;
      this.paginator.pageIndex = paginator.selectPage;
      this.paginator.lastPage = paginator.lastPage;
    },err =>{
      this.intermediaryService.presentToastError(err.error.result.reason);
    })
  }

  listenChanges() {
    let previousPageSize = this.form.value.pagination.limit;
    /**detect changes in the paginator */
    this.paginator.page.subscribe(page => {
      /**true if only change the number of results */
      let flag = previousPageSize === page.pageSize;
      previousPageSize = page.pageSize;
      this.form.value.pagination = {
        limit: page.pageSize,
        page: flag ? page.pageIndex : 1
      };
      this.getAllAudits()
    });
  }

  applyFilters() {
    if (this.pauseListenFormChange) return;
    clearTimeout(this.requestTimeout);
    this.requestTimeout = setTimeout(async () => {

      await this.getAllAudits();
    }, 100);
  }

  clearFilters() {
    this.initForm();
  }

  closeAuditoria(data){
    this.audit.create({packingReference:data,status:2}).subscribe(res =>{
      this.intermediaryService.presentToastSuccess('Control de embalaje cerrado!!',TimesToastType.DURATION_SUCCESS_TOAST_3750);
      this.getAllAudits();
    })
  }

  SeeProducts(data){
    this.router.navigateByUrl('/audits/list-products/'+data.id+'/'+data.packing.reference+'/false');
  }

  restartAudit(audit) {
    this.router.navigateByUrl('/audits/scanner-product/'+audit.id+'/'+audit.packing.reference+'/'+this.activeRoute.snapshot.routeConfig.path);
  }

  initAuditMultipleScandit(){
    this.auditMultipleScanditService.init();
  }

  getMessageVerifiedProducts(productsQuantity: number) : string {
    if (productsQuantity === 0 || productsQuantity > 1) {
      return `${productsQuantity} productos verificados`;
    } else {
      return `${productsQuantity} producto verificado`;
    }
  }
}

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {AlertController, ModalController} from '@ionic/angular';
import {IntermediaryService} from '@suite/services';
import {PaginatorComponent} from '../components/paginator/paginator.component';
import {
  AvelonIncidencesReceptionService
} from "../../../services/src/lib/endpoint/avelon-incidences-reception/avelon-incidences-reception.service";
import {AvelonIncidencesReception} from '../../../services/src/models/endpoints/AvelonIncidencesReception'
import {
  AvelonIncidencesReceptionShowJsonComponent
} from './modals/avelon-incidences-reception-show-json/avelon-incidences-reception-show-json.component';
import {
  AvelonIncidencesReceptionShowRequestJsonComponent
} from "./modals/avelon-incidences-reception-show-request-json/avelon-incidences-reception-show-request-json.component";

@Component({
  selector: 'suite-avelon-incidences-reception',
  templateUrl: './avelon-incidences-reception.component.html',
  styleUrls: ['./avelon-incidences-reception.component.scss']
})
export class AvelonIncidencesReceptionComponent implements OnInit, AfterViewInit {

  public static alert: boolean = false;

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  pagerValues = [50, 100, 1000];
  dataSource: MatTableDataSource<AvelonIncidencesReception> = null;
  displayedColumns: string[] = ['select', 'createdAt', 'id', 'method', 'itemId', 'retryTimes', 'request', 'response', 'retry', 'solve'];
  incidencesSelected: AvelonIncidencesReception[] = [];
  isAllSelected = false

  avelonIncidencesReception: AvelonIncidencesReception[];
  filters = {
    createdAtTimes: [],
    logIds: [],
    methods: [],
    itemIds: [],
    numOfRetryTimes: [],
    requests: [],
    responses: [],
  };
  filterOptions = {
    createdAtTimes: [],
    logIds: [],
    methods: [],
    itemIds: [],
    numOfRetryTimes: [],
      requests: [],
    responses: [],
  };
  order = {
    field: 'createdAt',
    direction: 'DESC'
  };
  pagination = {
    limit: undefined,
    page: 1
  };
  currentUserId: number

  constructor(
    private avelonIncidencesReceptionService: AvelonIncidencesReceptionService,
    private modalController: ModalController,
    private intermediaryService: IntermediaryService,
    private alertController: AlertController
  ) {
  }

  ngOnInit() {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    this.currentUserId = sessionUser.id;

    this.loadFilters();
    this.paginator.page.subscribe(paginator => {
      if (this.pagination.limit !== paginator.pageSize) {
        this.pagination.page = 1;
      } else {
        this.pagination.page = paginator.pageIndex;
      }
      this.pagination.limit = paginator.pageSize;
      this.loadAvelonIncidencesReceptions();
    });
  }

  ngAfterViewInit() {
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadAvelonIncidencesReceptions();
  }

  async loadFilters() {
    try {
      const response = await this.avelonIncidencesReceptionService.filterOptions()
      if (response.code === 200) {
        this.filterOptions = {
          createdAtTimes: response.data.createdAtTimes
            .filter((value, index, self) => self.indexOf(value) === index)
            .map(data => {
            return {
              id: data,
              value: new Date(data).toLocaleString(),
              checked: false,
              hide: false
            }
          }),
          logIds: response.data.logIds.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
          methods: response.data.methods.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
          itemIds: response.data.itemIds.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
          numOfRetryTimes: response.data.numOfRetryTimes.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
          responses: response.data.responses.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
          requests: response.data.requests.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
        }
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error)
    }
  }

  async loadAvelonIncidencesReceptions() {
    const parameters = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };
    try {
      const response = await this.avelonIncidencesReceptionService.filter(parameters)
      if (response.code === 200) {
        let result;
        try {
          result = response.data.result.map(data => {
            data.retryTimes = data.retryTimes ? data.retryTimes : 0;
            if (data.retryTimes > 0) {
              let json = JSON.parse(data.retries[0].error);


              if (json.hasOwnProperty('Message')) {
                data.error = json['Message'];
              } else if (json.hasOwnProperty('message')) {
                data.error = json['message'];
              } else {
                data.error = JSON.stringify(json);
              }

            } else {
              if (data.method === 'get') {
                console.log(data.response);
              }
              let index = data.response.indexOf("{");
              try {
                let json = JSON.parse(data.response.substring(index));
                if (json.hasOwnProperty('Message')) {
                  data.error = json['Message'];
                } else if (json.hasOwnProperty('errno')) {
                  data.error = json['errno'];
                } else if (json.hasOwnProperty('abort')) {
                  data.error = json['abort'];
                } else {
                  data.error = data.response;
                }
              } catch (e) {
                data.error = data.response;
              }
            }
            return data;
          });
        } catch (error) {
          result = response.data.result.map(data => {
            data.error = data.response;
            return data;
          });
        }
        this.dataSource = new MatTableDataSource<AvelonIncidencesReception>(result);
        this.avelonIncidencesReception = result;
        const paginator = response.data.pagination;
        this.paginator.cantSelect = paginator.limit;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  orderBy(column: string) {
    if (this.order.field === column) {
      this.order.direction === 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    } else {
      this.order.field = column;
    }
    this.loadAvelonIncidencesReceptions();
  }

  applyFilters(event, column: string) {
    const values = [];
    for (let item of event.filters) {
      if (item.checked) {
        values.push(item.id);
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.pagination.page = 1;
    this.incidencesSelected = []
    this.isAllSelected = false
    this.loadAvelonIncidencesReceptions();
  }

  reset() {
    this.filters = {
      createdAtTimes: [],
      logIds: [],
      methods: [],
      itemIds: [],
      numOfRetryTimes: [],
      requests: [],
      responses: []
    };
    this.pagination = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 1;
    this.loadFilters();
    this.loadAvelonIncidencesReceptions();
    this.isAllSelected = false
    this.incidencesSelected = []
  }

  async markAsSolvedConfirm(incidence: AvelonIncidencesReception) {
    const alert = await this.alertController.create({
      header: 'Resolver manualmente',
      message: `<span>Se marcará como resuelta manualmente la incidencia de código <b>${incidence.id}</b>, método <b>${incidence.method}</b> y response: <b><br />${incidence.response}</b>.<br/><br/>¿Está seguro de querer continuar?</span>`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Sí',
          handler: () => {
            this.markAsSolved([incidence]);
          }
        }
      ]
    });
    await alert.present();
  }

  async markAsSolved(incidences: AvelonIncidencesReception[]) {
    let params = {
      incidences,
      userId: this.currentUserId
    };
    await this.intermediaryService.presentLoadingNew("Marcando incidencia como resuelta")
    try {
      const response = await this.avelonIncidencesReceptionService.markAsSolved(params)
      if (response.code === 200) {
        await this.intermediaryService.presentToastSuccess('Incidencia marcada como resuelta.');
        this.reset();
      } else {
        await this.intermediaryService.presentToastError('Ha ocurrido un error. La incidencia no se ha podido marcar como resuelta.');
      }
    } catch (error) {
      console.error(error);
      await this.intermediaryService.presentToastError('Ha ocurrido un error. La incidencia no se ha podido marcar como resuelta.');
    } finally {
      await this.intermediaryService.dismissLoadingNew()
    }
  }

  async showError(incidence: AvelonIncidencesReception) {
    const modal = await this.modalController.create({
      component: AvelonIncidencesReceptionShowJsonComponent,
      componentProps: {
        incidence
      }
    });
    await modal.present();
  }

  async showRequest(incidence: AvelonIncidencesReception) {
    const modal = await this.modalController.create({
      component: AvelonIncidencesReceptionShowRequestJsonComponent,
      componentProps: {
        incidence
      }
    });
    await modal.present();
  }

  async solve(incidences: AvelonIncidencesReception[]) {
    let params = {
      incidences,
      userId: this.currentUserId
    };
    await this.intermediaryService.presentLoadingNew("Resolviendo incidencia")
    try {
      const response = await this.avelonIncidencesReceptionService.solve(params)
      if (response.code === 200) {
        await this.intermediaryService.presentToastSuccess('Incidencia marcada como resuelta.');
        this.reset();
      } else {
        await this.intermediaryService.presentToastError('Ha ocurrido un error. La incidencia no se ha podido resolver.');
      }
    } catch (error) {
      console.error(error);
      await this.intermediaryService.presentToastError('Ha ocurrido un error. La incidencia no se ha podido resolver.');
    } finally {
      await this.intermediaryService.dismissLoadingNew()
      await this.loadAvelonIncidencesReceptions()
    }
  }

  selectIncidence(incidence: AvelonIncidencesReception) {
    const exist = this.incidencesSelected.some(incidenceSelected => incidenceSelected.id === incidence.id)
    if (!exist) {
      this.incidencesSelected.push(incidence)
    } else {
      const index = this.incidencesSelected.findIndex(incidenceSelected => incidenceSelected.id === incidence.id)
      this.incidencesSelected.splice(index, 1)
    }
    this.isAllSelected = this.avelonIncidencesReception.length === this.incidencesSelected.length;
  }

  isSelected(incidence: AvelonIncidencesReception) {
    return this.incidencesSelected.some(incidenceSelected => incidenceSelected.id === incidence.id)
  }

  selectAll() {
    if (this.isAllSelected) {
      this.incidencesSelected = []
    } else {
      this.incidencesSelected = [].concat(this.avelonIncidencesReception)
    }
    this.isAllSelected = !this.isAllSelected
  }

  async markAsSolveAllConfirm() {
    const alert = await this.alertController.create({
      header: 'Resolver manualmente',
      message: `<span>Se marcarán como resueltas las incidencias seleccionadas ¿Está seguro de querer continuar?</span>`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Sí',
          handler: () => {
            this.markAsSolved(this.incidencesSelected);
          }
        }
      ]
    });
    await alert.present();
  }

  async solveConfirm(incidences: AvelonIncidencesReception[]) {
    const alert = await this.alertController.create({
      header: 'Reenviar petición',
      message:
        incidences.length > 1 ?
          `<span>Se intentarán resolver las incidencias seleccionadas ¿Está seguro de querer continuar?</span>`
          :
          `<span>Se intentará resolver la incidencia <b>${incidences[0].id}</b>, método <b>${incidences[0].method}</b> y response <br /><b>${incidences[0].response}</b>.<br/><br/>¿Está seguro de querer continuar?</span>`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Sí',
          handler: () => {
            this.solve(incidences);
          }
        }
      ]
    });
    await alert.present();
  }

  convertKeysToCamelCase(obj: any) {
    let output = {}
    for (const i of Object.keys(obj)) {
      let current = obj[i]
      if (this.isObject(current) || this.isArray(current)) {
        output[i.toLowerCase()] = this.convertKeysToCamelCase(current);
      } else {
        output[i.toLowerCase()] = current;
      }
    }
    return output;
  };

  isObject(obj: any) {
    return typeof obj === 'object'
  }

  isArray(arr: any) {
    return Array.isArray(arr)
  }

}

import {map} from 'rxjs/operators';
import {ReceptionAvelonModel} from '@suite/services';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpRequestModel} from '../../../models/endpoints/HttpRequest';
import {Observable, BehaviorSubject} from "rxjs";
import {RequestsProvider} from "../../../providers/requests/requests.provider";
import {ReceptionOcrModel} from "../../../models/endpoints/ReceptionOcr";
import OcrPositionConfirmation = ReceptionOcrModel.OcrPositionConfirmation;
import OcrRecognitionConfirmation = ReceptionOcrModel.OcrRecognitionConfirmation;
import OcrReceptionEnd = ReceptionOcrModel.OcrReceptionEnd;
import OcrSnapshot = ReceptionOcrModel.OcrSnapshot;
import OcrRecognitionLearning = ReceptionOcrModel.OcrRecognitionLearning;
import OcrRecognitionLearningResponse = ReceptionOcrModel.OcrRecognitionLearningResponse;
import StoreOCR = ReceptionOcrModel.StoreOCR;
import SnapshotOCR = ReceptionOcrModel.SnapshotOCR;

@Injectable({
  providedIn: 'root'
})
export class ReceptionsAvelonService {

  receptionsUrl: string = `${environment.apiBase}/reception`;
  getAllProvidersUrl: string = `${environment.apiBase}/avelonProviders/all`;
  postMarkAsPrintedUrl: string = `${environment.apiBase}/reception/mark-as-printed`;
  postIsProviderAvailableUrl: string = `${environment.apiBase}/avelonProviders`;
  urlReception: string = `${environment.apiBase}/reception/expedition/lines-destiny-impress/blocked`;
  checkExpeditionsByNumberAndProviderUrl: string = `${environment.apiBase}/avelonProviders/check/expedition/provider`;
  private checkExpeditionByReferenceUrl: string = `${environment.apiBase}/avelonProviders/check/expedition/`;
  private checkExpeditionsByProviderUrl: string = `${environment.apiBase}/avelonProviders/check/provider/`;
  postLoadSizesUrl: string = `${environment.apiBase}/reception/sizes/list`;
  private postReloadModelsListUrl: string = `${environment.apiBase}/reception/models/list`;
  private postReloadColorsListUrl: string = `${environment.apiBase}/reception/colors/list`;
  makeReceptionFreeUrl: string = `${this.receptionsUrl}/free`;
  postCreateIncidenceForNotPrintsUrl: string = `${this.receptionsUrl}/no-printed/incidence`;
  ocrPositionConfirmationUrl: string = `${this.receptionsUrl}/ocr/position-confirmation`;
  ocrRecognitionConfirmationUrl: string = `${this.receptionsUrl}/ocr/recognition-confirmation`;
  ocrReceptionEndUrl: string = `${this.receptionsUrl}/ocr/reception-end`;
  ocrSnapshotUrl: string = `${this.receptionsUrl}/ocr/snapshot`;
  ocrRecognitionLearning: string = `${this.receptionsUrl}/ocr/recognition-learning`;
  getBrandsByProvidersUrl: string = `${this.receptionsUrl}/suppliers/brands`;
  postStoreOCRUrl: string = `${this.receptionsUrl}/ocr/store-ocr`;
  postStoreSnpashotOCRUrl: string = `${this.receptionsUrl}/ocr/store-snapshot-ocr`;
  postGetModelsReferencesUrl: string = `${environment.apiBase}/public-models/references`;

  private models = new BehaviorSubject([]);
  private models$ = this.models.asObservable()
  private brands = new BehaviorSubject([]);
  private brands$ = this.brands.asObservable()
  private sizes = new BehaviorSubject([]);
  private sizes$ = this.sizes.asObservable()
  private colors = new BehaviorSubject([]);
  private colors$ = this.colors.asObservable()
  private emit = new BehaviorSubject({});
  private emit$ = this.emit.asObservable()
  private emitSize = new BehaviorSubject({});
  private emitSize$ = this.emitSize.asObservable();

  constructor(
    private http: HttpClient,
    private requestsProvider: RequestsProvider
  ) {}

  //region API Requests

  postOCRRecognitionLearning(ocrRecognitionLearning: OcrRecognitionLearning): Observable<OcrRecognitionLearningResponse> {
    return this.http.post<OcrRecognitionLearningResponse>(this.ocrRecognitionLearning, ocrRecognitionLearning);
  }

  postStoreOCR(storeOCR: StoreOCR): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postStoreOCRUrl, storeOCR);
  }

  postGetModelsReferences(modelIds: number[]): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postGetModelsReferencesUrl, {modelIds: modelIds});
  }

  postStoreSnapshotOCR(snapshotOCR: SnapshotOCR): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postStoreSnpashotOCRUrl, snapshotOCR);
  }

  postOCRPositionConfirmation(ocrPositionConfirmation: OcrPositionConfirmation): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.ocrPositionConfirmationUrl, ocrPositionConfirmation);
  }

  postOCRRecognitionConfirmation(ocrRecognitionConfirmation: OcrRecognitionConfirmation): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.ocrRecognitionConfirmationUrl, ocrRecognitionConfirmation);
  }

  postOCRReceptionEnd(ocrReceptionEnd: OcrReceptionEnd): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.ocrReceptionEndUrl, ocrReceptionEnd);
  }

  postOCRSnapshot(ocrSnapshot: OcrSnapshot): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.ocrSnapshotUrl, ocrSnapshot);
  }

  getReceptions(providerId: number, typeModelVisualization: number, typeColorVisualization?: number) {
    const body: ReceptionAvelonModel.GetAllByProvider = {
      providerId,
      typeModelVisualization,
      typeColorVisualization: typeColorVisualization ? typeColorVisualization : 1
    };
    return this.http.post<HttpRequestModel.Response>(`${this.receptionsUrl}/all`, body).pipe(map(resp => resp.data));
  }

  getReceptionsNotifiedProviders(providerId: number, typeVisualization: number) {
    const body: ReceptionAvelonModel.GetProvider = {
      providerId,
      typeVisualization
    };
    return this.http.post<HttpRequestModel.Response>(`${this.receptionsUrl}/all-with-notified`, body).pipe(map(resp => resp.data));
  }

  getAllProviders() {
    return this.http.get<HttpRequestModel.Response>(this.getAllProvidersUrl).pipe(map(resp => resp.data));
  }

  printReceptionLabel(body: ReceptionAvelonModel.ParamsToPrint) {
    return this.http.post<HttpRequestModel.Response>(`${this.receptionsUrl}/print-reception-label`, body).pipe(map(resp => resp.data));
  }

  markAsPrinted(parameters): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postMarkAsPrintedUrl, parameters);
  }

  makeReceptionFree(body: ReceptionAvelonModel.ParamsToPrint) {
    return this.http.post<HttpRequestModel.Response>(this.makeReceptionFreeUrl, body).pipe(map(resp => resp.data));
  }

  eanProductPrint(ean: string, expedition: string, providerId: number, isReceptionWithoutOrder: boolean, delivery_note: string) {
    return this.http.post<HttpRequestModel.Response>(`${this.receptionsUrl}/ean-product/print-reception-label`, {ean, expedition, providerId, isReceptionWithoutOrder, delivery_note}).pipe(map(resp => resp.data));
  }

  checkExpeditionsByNumberAndProvider(params: ReceptionAvelonModel.ParamsCheckExpeditionsByNumberAndProvider): Observable<ReceptionAvelonModel.ResponseCheckExpeditionsByNumberAndProvider> {
    return this.http.post<ReceptionAvelonModel.ResponseCheckExpeditionsByNumberAndProvider>(this.checkExpeditionsByNumberAndProviderUrl, params);
  }

  checkExpeditionByReference(expeditionReference: string): Observable<ReceptionAvelonModel.ResponseCheckExpeditionByReference> {
    return this.http.get<ReceptionAvelonModel.ResponseCheckExpeditionByReference>(this.checkExpeditionByReferenceUrl + expeditionReference);
  }

  checkExpeditionsByProvider(providerId: number): Observable<ReceptionAvelonModel.ResponseCheckExpeditionsByProvider> {
    return this.http.get<ReceptionAvelonModel.ResponseCheckExpeditionsByProvider>(this.checkExpeditionsByProviderUrl + providerId);
  }

  postLoadSizesList(params: ReceptionAvelonModel.ParamsLoadSizesList): Observable<ReceptionAvelonModel.ResponseLoadSizesList> {
    return this.http.post<ReceptionAvelonModel.ResponseLoadSizesList>(this.postLoadSizesUrl, params);
  }

  postReloadModelsList(params: ReceptionAvelonModel.ParamsReloadModelsList): Observable<ReceptionAvelonModel.ResponseReloadModelsList> {
    return this.http.post<ReceptionAvelonModel.ResponseReloadModelsList>(this.postReloadModelsListUrl, params);
  }

  postReloadColorsList(params: ReceptionAvelonModel.ParamsReloadColorsList): Observable<ReceptionAvelonModel.ResponseReloadColorsList> {
    return this.http.post<ReceptionAvelonModel.ResponseReloadColorsList>(this.postReloadColorsListUrl, params);
  }

  postLoadModelTable(params: ReceptionAvelonModel.ParamsReloadModelsList): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postReloadModelsListUrl, params);
  }

  postCreateIncidenceForNotPrints(params: {references: string[]}): Observable<any> {
    return this.http.post<any>(this.postCreateIncidenceForNotPrintsUrl, params);
  }

  getBrandsBySupplier(supplierId: number): Observable<any> {
    return this.http.post(this.getBrandsByProvidersUrl, {id: supplierId}).pipe(map((response: any) => {
      return response.data;
    }));
  }
  //endregion

  //region Data getter and setter
  setModelsList(data: Array<any>){
    this.models.next(data)
  }
  getModelsList(){
    return this.models$
  }

  setBrandsList(data: Array<any>){
    this.brands.next(data)
  }
  getBrandsList(){
    return this.brands$
  }
  setColorsList(data: Array<any>){
    this.colors.next(data)
  }
  getColorsList(){
    return this.colors$
  }
  setSizesList(data: Array<any>){
    this.sizes.next(data)
  }
  getSizesList(){
    return this.sizes$
  }
  setEmitList(data: any){
    this.emit.next(data)
  }
  getEmitList(){
    return this.emit$
  }

  setEmitSizes(data: any){
    this.emitSize.next(data)
  }
  getEmitSizes(){
    return this.emitSize$
  }
  //endregion
}

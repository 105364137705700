import { Component, OnInit, ViewChild, AfterViewInit, Query, Input,Output,EventEmitter, SimpleChange } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { parse } from 'querystring';
import { ModalController, NavController } from '@ionic/angular';
import { IntermediaryService, OplTransportsService } from '../../../services/src';
import { PaginatorComponent } from '../components/paginator/paginator.component';
import { SelectionModel } from '@angular/cdk/collections';
import { TagsInputOption } from '../components/tags-input/models/tags-input-option.model';
import { DefectiveRegistryService } from '../../../services/src/lib/endpoint/defective-registry/defective-registry.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { FiltersModel } from '../../../services/src/models/endpoints/filters';
import { ExpeditionCollectedService } from '../../../services/src/lib/endpoint/expedition-collected/expedition-collected.service';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from "file-saver";
import {DateTimeParserService} from "../../../services/src/lib/date-time-parser/date-time-parser.service";
import {ToolbarProvider} from "../../../services/src/providers/toolbar/toolbar.provider";
import {PackagesComponent} from "../transport-package-cancelled/packages/packages.component";
import {validators} from "../utils/validators";

@Component({
  selector: 'suite-transport-package-cancelled',
  templateUrl: './transport-package-cancelled.component.html',
  styleUrls: ['./transport-package-cancelled.component.scss']
})

export class TransportPackageCancelledComponent {
  constructor(
    private defectiveRegistryService: DefectiveRegistryService,
    private formBuilder: FormBuilder,
    private intermediaryService: IntermediaryService,
    private modalController: ModalController,
    private intermediary: IntermediaryService,
    private expeditionCollectedService: ExpeditionCollectedService,
    private navCtrl: NavController,
    private activateRoute: ActivatedRoute,
    private oplTransportsService: OplTransportsService,
    private dateTimeParserService: DateTimeParserService,
    private toolbarProvider: ToolbarProvider,
  ) {
  }
  @Input() id: any;
  @Input() sendEvent:boolean;
  @Output()buttonState = new EventEmitter<boolean>();
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  /**Filters */
  uniquecodes: Array<TagsInputOption> = [];
  containers: Array<TagsInputOption> =[];
  shops: Array<TagsInputOption> = [];
  orders: Array<TagsInputOption> = [];
  dates: Array<TagsInputOption> = [];
  groups:Array<TagsInputOption> = [];

  displayedColumns: string[] = ['select', 'orders', 'uniquecodes', 'containers', 'dates', 'products'];
  dataSource;
  packages;
  button:boolean = false;
  selection = new SelectionModel<any>(true, []);
  columns=['uniquecodes','shops', 'containers','orders','dates'];
  public showFiltersMobileVersion: boolean = false;

  toDelete: FormGroup = this.formBuilder.group({
    jails: this.formBuilder.array([])
  });

  pauseListenFormChange: boolean;
  lastUsedFilter: string;
  pagerValues = [20, 50, 100];

  form: FormGroup = this.formBuilder.group({
    id: [[]],
    uniquecodes:[[]],
    containers:[[]],
    shops:[[]],
    orders:[[]],
    dates:[[]],
    groups:[[]],
    idTransport: new FormControl(''),
    pagination: this.formBuilder.group({
      page: 1,
      limit: undefined
    }),
    orderby: this.formBuilder.group({
      type: 5,
      order: "desc"
    }),
    isAl: true,
  });
  length: any;

  selectedForm: FormGroup = this.formBuilder.group({
    selector: false
  },{
    validators: validators.haveItems("toSelect")
  });


  ngOnInit() {
    let transportId = parseInt(this.activateRoute.snapshot.paramMap.get('id'));
    this.id = transportId;
    this.initForm();
    this.getFilters(this.id);
    this.form.value.idTransport = this.id;
  }

  async ngAfterViewInit() {
    this.form.value.pagination.limit = this.paginator.finalPagerValues[0];
    this.getList(this.form.value);
    this.listenChanges();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }){
    let change: SimpleChange = changes['sendEvent'];
      if(this.stateUpdate() == true){
        let packages = this.selectedForm.value.toSelect
          .map((selected, i) => {
            return selected ? this.packages[i] : false;
          })
          .filter(packages => packages);
        this.update(packages);
    }
  }

  openFiltersMobile() {
    this.showFiltersMobileVersion = !this.showFiltersMobileVersion;
  }

  ionViewWillEnter() {
    this.toolbarProvider.optionsActions.next([
      {
        icon: 'funnel',
        label: 'Filtros',
        action: () => this.showFiltersMobileVersion = !this.showFiltersMobileVersion
      }
    ]);
  }

  private getFormValueCopy() {
    return JSON.parse(JSON.stringify(this.form.value || {}));
  }

  sanitize(object){
    /**mejorable */
    object = JSON.parse(JSON.stringify(object));
    if(!object.orderby.type){
      delete object.orderby.type;
    }else{
      object.orderby.type = parseInt(object.orderby.type);
    }
    if(!object.orderby.order)
      delete object.orderby.order;
    Object.keys(object).forEach(key=>{
      if(object[key] instanceof Array){
        if(object[key][0] instanceof Array){
          object[key] = object[key][0];
        } else {
          for(let i = 0;i<object[key].length;i++) {
            if(object[key][i] === null || object[key][i] === "") {
              object[key].splice(i,1);
            }
          }
        }
      }
      if (object[key] === null || object[key] === "") {
        delete object[key];
      }
    });
    return object;
  }

  applyFilters() {
    this.openFiltersMobile();
    this.form.value.pagination.page = 1;
    this.form.value.pagination.limit = this.paginator.cantSelect;
    this.form.value.idTransport = this.id;
    this.getList(this.sanitize(this.getFormValueCopy()));
  }

  clearFilters() {
    this.form = this.formBuilder.group({
      id: [[]],
      uniquecodes:[[]],
      containers:[[]],
      shops:[[]],
      orders:[[]],
      dates:[[]],
      groups: [[]],
      idTransport: this.id,
      pagination: this.formBuilder.group({
        page: 1,
        limit: this.paginator.cantSelect
      }),
      orderby: this.formBuilder.group({
        type: 5,
        order: "desc"
      }),
      isAl: true,
    });
  }

  initForm() {
    this.form.patchValue({
      id: [],
      uniquecodes:[],
      containers:[],
      shops:[],
      orders:[],
      dates:[],
      isAl: true,
    })
  }

  listenChanges() {
    let previousPageSize = this.form.value.pagination.limit;
    /**detect changes in the paginator */
    this.paginator.page.subscribe(page => {
      /**true if only change the number of results */
      let flag = previousPageSize === page.pageSize;
      previousPageSize = page.pageSize;
      this.form.value.pagination = {
        limit: page.pageSize,
        page: flag ? page.pageIndex : 1
      };
      this.getList(this.form.value)
    });

  }

  getFilters(id) {
    this.expeditionCollectedService.getFiltersPackage(id, true).subscribe((entities) => {
      this.uniquecodes = this.updateFilterSource(entities.uniquecodes, 'uniquecodes');
      this.containers = this.updateFilterExpedition(entities.containers,'containers');
      this.shops = this.updateFilterSource(entities.shops,'shops');
      this.orders = this.updateFilterSource(entities.orders,'orders');
      this.dates = this.updateFilterSource(entities.dates,'dates');
      this.groups = this.updateFilterSource(entities.groups,'groups');

      setTimeout(() => {
        this.pauseListenFormChange = false;
        this.pauseListenFormChange = true;
      }, 0);
    },(error)=>{
      console.log(error);
    })
  }

  private updateFilterSource(dataEntity: FiltersModel.Default[], entityName: string) {
    let resultEntity;

    this.pauseListenFormChange = true;
    let dataValue = this.form.get(entityName).value;

    resultEntity = dataEntity ? dataEntity.map(entity => {
        if (entity.name == "uniquecode") {
          entity.name = 'Etiqueta de envío';
        } else if (entity.name == "container") {
          entity.name = 'ID Contenedor';
        } else if (entity.name == "order") {
          entity.name = 'ID Pedido';
        } else if (entity.name == "date_preparation") {
          entity.name = 'Fecha preparación';
        }
        entity.id = <number>(<unknown>entity.id);
        entity.name = entity.name;
        entity.value = entity.name;
        entity.checked = true;
        entity.hide = false;
        return entity;
    }) : [];

    resultEntity.splice(2,1);

    if (dataValue && dataValue.length) {
      this.form.get(entityName).patchValue(dataValue, { emitEvent: false });
    }

    setTimeout(() => { this.pauseListenFormChange = false; }, 0);

    return resultEntity;
  }

  private updateFilterExpedition(dataEntity: FiltersModel.Default[], entityName: string) {
    let resultEntity;

    this.pauseListenFormChange = true;
    let dataValue = this.form.get(entityName).value;

    resultEntity = dataEntity ? dataEntity.map(entity => {
      entity.id = <number>(<unknown>entity.id);
      entity.name = entity.id+"";
      entity.value = entity.id+"";
      entity.checked = true;
      entity.hide = false;
      return entity;
    }) : [];

    if (dataValue && dataValue.length) {
      this.form.get(entityName).patchValue(dataValue, { emitEvent: false });
    }

    setTimeout(() => { this.pauseListenFormChange = false; }, 0);
    return resultEntity;
  }

  async getList(form) {
    this.intermediaryService.presentLoading("Cargando paquetes cancelados..");
    await this.expeditionCollectedService.getPackagesCancelled(form).subscribe((resp: any) => {
      this.intermediaryService.dismissLoading();
      if (resp.results) {
        this.dataSource = new MatTableDataSource<any>(resp.results);
        this.packages = resp.results;
        const paginator = resp.pagination;

        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;
        this.initSelectedForm();
      }
    },
      async err => {
        await this.intermediaryService.dismissLoading()
      },
      async () => {
        await this.intermediaryService.dismissLoading()
      })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  async update(packages) {
    const expeditionPackageIds: number[] = packages.map(s => s.package.id);
    this.expeditionCollectedService.postCheckOrderNotCancelled({expeditionPackageIds: expeditionPackageIds}).then(async response => {
      if (response.code == 200) {
        const cancelledPackageIds: number[] = response.data;
        const data: any = packages.filter(s => !cancelledPackageIds.includes(s.package.id)).map(function (obj) {
          var rObj = {};
          rObj['warehouse'] = obj.warehouse.id;
          rObj['expedition'] = obj.expedition;
          rObj['transport'] = obj.transport.id;
          rObj['package'] = obj.package.id;
          return rObj;
        });
        await this.intermediaryService.presentLoading();
        const transport = data[0].expedition;
        this.expeditionCollectedService.updatePackage(data).subscribe(data => {

            this.selection.clear();
            this.ngOnInit();
            this.oplTransportsService.downloadPdfTransortOrders(data.order.id).subscribe(
              resp => {
                const blob = new Blob([resp], {type: 'application/pdf'});
                saveAs(blob, 'documento.pdf')
              },
              e => {
                console.log(e.error.text);
              },
              () => this.intermediaryService.dismissLoading()
            )
            this.intermediaryService.presentToastSuccess('Los paquetes seleccionados fueron actualizados con exito');

          }, error => {
            this.selection.clear();
            this.intermediaryService.presentToastError(error.error.errors);
            this.intermediaryService.dismissLoading();
          },
          () => {
            this.refresh();
          }
        );
      } else {
        console.error(response);
      }
    }, console.error).catch(console.error);
  }

  refresh() {
    this.getList(this.form.value);
  }

  private initSelectedForm() {
    this.selectedForm.removeControl("toSelect");
    this.selectedForm.addControl("toSelect", this.formBuilder.array(this.packages.map(element => new FormControl(false))));
  }

  stateUpdate() {
    let packages = this.selectedForm.value.toSelect
      .map((selected, i) => {
        return selected ? this.packages[i] : false;
      })
      .filter(packages => packages);
    if (packages.length > 0) {
      this.buttonState.emit(true);
      return true
    }else{
      this.buttonState.emit(false);
    }
    return false;
  }

  preparationDateParsed(element) : string {
    return this.dateTimeParserService.dateTime(element.trackingPackage.createdAt);
  }

  public async send(){
    if(this.stateUpdate() == true){
      let packages = this.selectedForm.value.toSelect
        .map((selected, i) => {
          return selected ? this.packages[i] : false;
        })
        .filter(packages => packages);
      this.update(packages);
      this.initSelectedForm();
    }
  }

  async presentModal(packages) {

    const modal = await this.modalController.create({
      component: PackagesComponent,
      componentProps: { packages }
    });

    await modal.present();

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from "@ionic/angular";
import {PaginatorComponentModule} from "../../components/paginator/paginator.component.module";
import {MatTableModule} from "@angular/material/table";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UpdateParameterizationIvaComponent } from './update-parameterization-iva/update-parameterization-iva.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { CreateParameterizationIvaComponent } from './create-parameterization-iva/create-parameterization-iva.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import { FilterButtonModule } from '../../components/filter-button/filter-button.module';

@NgModule({
  declarations: [UpdateParameterizationIvaComponent, CreateParameterizationIvaComponent],
  imports: [
    CommonModule,
    IonicModule,
    PaginatorComponentModule,
    FilterButtonModule,
    MatTableModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
  ],
  entryComponents: [UpdateParameterizationIvaComponent, CreateParameterizationIvaComponent]
})
export class ParameterizationIvaModule { }

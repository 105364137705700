import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {MatTableModule, MatCheckboxModule, MatGridListModule, MatRadioModule} from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import { WorkwavesHistoryRoutingModule } from "./workwaves-history-routing.module";
import { WorkwavesHistoryComponent } from "./workwaves-history.component";
import { CommonUiCrudModule } from '@suite/common/ui/crud';
import {ListWorkwavesHistoryComponent} from "./list/list.component";
import {TitleListWorkwavesHistoryComponent} from "./list/list-title/list-title.component";
import {WorkwaveListWorkwavesHistoryComponent} from "./list/list-workwave/list-workwave.component";
import {ListDetailHistoryComponent} from "./list-detail/list-detail.component";
import {TitleDetailHistoryComponent} from "./list-detail/title/title.component";
import {DetailHistoryComponent} from "./list-detail/detail/detail.component";
import {BreadcrumbModule} from '../components/breadcrumb/breadcrumb.module';
import {ListPickingModule} from "../list-picking/list-picking.module";
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatPaginatorModule } from "@angular/material";
import {FilterButtonModule} from "../components/filter-button/filter-button.module";
import {PaginatorComponentModule} from "../components/paginator/paginator.component.module";
import { ListProductsComponent } from './list-detail/modal-products/modal-products.component';
import { ListDestiniesComponent } from './list-detail/modal-destinies/modal-destinies.component';

@NgModule({
  declarations: [ListDestiniesComponent,ListProductsComponent,WorkwavesHistoryComponent, ListWorkwavesHistoryComponent, TitleListWorkwavesHistoryComponent, WorkwaveListWorkwavesHistoryComponent, ListDetailHistoryComponent, TitleDetailHistoryComponent, DetailHistoryComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    MatTableModule,
    MatCheckboxModule,
    WorkwavesHistoryRoutingModule,
    CommonUiCrudModule,
    CdkTableModule,
    MatGridListModule,
    FormsModule,
    MatRadioModule,
    BreadcrumbModule,
    ListPickingModule,
    MatTooltipModule,
    MatPaginatorModule,
    FilterButtonModule,
    PaginatorComponentModule
  ],
  entryComponents: [ListDestiniesComponent,ListProductsComponent,ListWorkwavesHistoryComponent, TitleListWorkwavesHistoryComponent, WorkwaveListWorkwavesHistoryComponent, ListDetailHistoryComponent, TitleDetailHistoryComponent, DetailHistoryComponent]
})
export class WorkwavesHistoryModule {}

import {Component, OnInit, ViewChild} from '@angular/core';
import {PaginatorComponent} from "../components/paginator/paginator.component";
import {HiringCorreosExpressModel} from "../../../services/src/models/endpoints/HiringCorreosExpress";
import Filters = HiringCorreosExpressModel.Filters;
import FilterOptions = HiringCorreosExpressModel.FilterOptions;
import FilterOptionsResponse = HiringCorreosExpressModel.FilterOptionsResponse;
import Order = HiringCorreosExpressModel.Order;
import Pagination = HiringCorreosExpressModel.Pagination;
import SearchParameters = HiringCorreosExpressModel.SearchParameters;
import {NavigationEnd, Router} from "@angular/router";
import {HiringCorreosExpressService} from "../../../services/src/lib/endpoint/hiring-correosexpress/hiring-correosexpress.service";
import { IntermediaryService } from '../../../services/src/lib/endpoint/intermediary/intermediary.service';
import {DateTimeParserService} from "../../../services/src/lib/date-time-parser/date-time-parser.service";
import {MatTableDataSource} from "@angular/material/table";
import {ModalController} from "@ionic/angular";
import {UpdateHiringCorreosExpressComponent} from "./update-hiring-correosexpress/update-hiring-correosexpress.component";

@Component({
  selector: 'suite-hiring-correosexpress',
  templateUrl: './hiring-correosexpress.component.html',
  styleUrls: ['./hiring-correosexpress.component.scss']
})
export class HiringCorreosExpressComponent implements OnInit {

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  dataSource;
  public displayedColumns: string[] = ['warehouseReference', 'nameWarehouse', 'user', 'password', 'solicitante', 'codRte'];

  dataTable = [];
  hiring;
  filters: Filters = {
    warehouseReference: [],
    nameWarehouse: [],
    user: [],
    password: [],
    solicitante: [],
    codRte: []
  };

  filterOptions: FilterOptions  = {
    warehouseReference: [],
    nameWarehouse: [],
    user: [],
    password: [],
    solicitante: [],
    codRte: []
  };
  order: Order = {
    field: 'warehouseReference',
    direction: 'ASC'
  };
  pagination: Pagination = {
    limit: undefined,
    page: 1
  };

  constructor(
    public router: Router,
    private hiringCEService: HiringCorreosExpressService,
    private intermediaryService: IntermediaryService,
    private dateTimeParserService: DateTimeParserService,
    private modalCtrl: ModalController,
  ) {
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd && val && val.url == '/hiring-correosexpress'){
        if(typeof this.hiring !== 'undefined'){
          this.reset();
        }
      }
    });
  }

  ngOnInit() {
    this.loadFilters();
    this.dataSource = new MatTableDataSource();
    this.paginator.page.subscribe(paginator => {
      if (this.pagination.limit != paginator.pageSize) {
        this.pagination.page = 1;
      } else {
        this.pagination.page = paginator.pageIndex;
      }
      this.pagination.limit = paginator.pageSize;
      this.loadHiring();
    });
  }

  ngAfterViewInit(){
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadHiring();
  }

  loadFilters(){
    this.hiringCEService.getFilterOptions().then((response: FilterOptionsResponse) => {
      this.hiringCEService.getFilterOptionsCE().then((responseCE: FilterOptionsResponse) => {
        if(response.code == 200){
          this.filterOptions = {
            warehouseReference: response.data.warehouseReference.map((data, index) => {
              const warehouseReference1 = JSON.stringify(data);
              const warehouseReference = JSON.parse(warehouseReference1);
              return {
                id: warehouseReference.id,
                value: warehouseReference.warehouseReference,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            nameWarehouse: response.data.nameWarehouse.map((data, index) => {
              const nameWarehouse1 = JSON.stringify(data);
              const nameWarehouse = JSON.parse(nameWarehouse1);
              return {
                id: nameWarehouse.id,
                value: nameWarehouse.nameWarehouse,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            user: responseCE.data.user.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            password: responseCE.data.password.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            solicitante: responseCE.data.solicitante.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            }),
            codRte: responseCE.data.codRte.map((data, index) => {
              return {
                id: '',
                value: data,
                checked: true,
                hide: index > 20 ? true : false
              }
            })
          };
        }else{
          console.error(response);
        }
      }).catch(console.error);
    }).catch(console.error);
  }

  async loadHiring(){
    const parameters: SearchParameters = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };

    this.dataTable = [];
    await this.hiringCEService.getAllWarehouses(parameters).subscribe(async data => {
      let dataWarehouses = data.result;
      await this.hiringCEService.getAllWarehousesCE(parameters).subscribe(async data => {
        let dataCE = data.result;

        if(this.filters.user.length > 0 || this.filters.password.length > 0 || this.filters.solicitante.length > 0 || this.filters.codRte.length > 0){
          dataWarehouses.forEach(async (dataWarehouse) => {
            dataCE.forEach(async (dataCE) => {
              if (parseInt(dataWarehouse.reference) == dataCE.warehouseReference) {
                this.dataTable.push({
                  warehouseReference: parseInt(dataWarehouse.reference),
                  nameWarehouse: dataWarehouse.name,
                  idWarehouseCE: dataCE.id,
                  user: dataCE.user,
                  password: dataCE.password,
                  solicitante: dataCE.solicitante,
                  codRte: dataCE.codRte
                });
              }
            });
          });
        }else{
          dataWarehouses.forEach(async (dataWarehouse) => {
            this.dataTable.push({
              warehouseReference: parseInt(dataWarehouse.reference),
              nameWarehouse: dataWarehouse.name,
              user: null,
              password: null,
              solicitante: null,
              codRte: null
            });
            dataCE.forEach(async (dataCE) => {
              if(parseInt(dataWarehouse.reference) == dataCE.warehouseReference){
                for(let i = 0; i < this.dataTable.length; i++){
                  if(parseInt(dataWarehouse.reference) == this.dataTable[i].warehouseReference && dataCE.warehouseReference == this.dataTable[i].warehouseReference){
                    this.dataTable.splice(i, 1, {
                      warehouseReference: parseInt(dataWarehouse.reference),
                      nameWarehouse: dataWarehouse.name,
                      idWarehouseCE: dataCE.id,
                      user: dataCE.user,
                      password: dataCE.password,
                      solicitante: dataCE.solicitante,
                      codRte: dataCE.codRte
                    });
                  }
                }
              }
            });
          });
        }

        if(this.order.field == 'user' || this.order.field == 'password' || this.order.field == 'solicitante' || this.order.field == 'codRte'){
          this.dataSource = new MatTableDataSource(this.orderItemsCE(this.dataTable, this.order.field, this.order.direction));
        }else{
          this.dataSource = new MatTableDataSource(this.dataTable);
        }
      });
      const paginator = data.pagination;
      this.paginator.cantSelect = paginator.limit;
      this.paginator.length = paginator.totalResults;
      this.paginator.pageIndex = paginator.selectPage;
      this.paginator.lastPage = paginator.lastPage;
    });
  }

  async openModalWarehouse(event, element) {
    event.stopPropagation();
    event.preventDefault();
    let modal = (await this.modalCtrl.create({
      component: UpdateHiringCorreosExpressComponent,
      componentProps: {
        element
      }
    }));
    modal.onDidDismiss().then((data) => {
      this.loadHiring();
    });
    modal.present();
  }


  reset(){
    this.filters = {
      warehouseReference: [],
      nameWarehouse: [],
      user: [],
      password: [],
      solicitante: [],
      codRte: []
    };
    this.order = {
      field: 'warehouseReference',
      direction: 'ASC'
    };
    this.pagination = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 1;
    this.loadFilters();
    this.loadHiring();
  }

  orderBy(column: string){
    if(this.order.field == column){
      this.order.direction == 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    }else{
      this.order.field = column;
    }
    this.loadHiring();
  }

  applyFilters(event, column: string){
    const values = [];
    for(let item of event.filters){
      if(item.checked){
        if(column == 'warehouseReference'){
          values.push(item.id);
        }else{
          values.push(item.value);
        }
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.pagination.page = 1;
    this.loadHiring();
  }

  orderItemsCE(items, field, direction){
    let data;
    switch(field){
      case 'user':
        data = data = items.sort(function (a, b) {
          if (a.user > b.user) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.user < b.user) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
      case 'password':
        data = items.sort(function (a, b) {
          if (a.password > b.password) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.password < b.password) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
      case 'solicitante':
        data = items.sort(function (a, b) {
          if (a.solicitante > b.solicitante) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.solicitante < b.solicitante) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
      case 'codRte':
        data = items.sort(function (a, b) {
          if (a.codRte > b.codRte) {
            return direction == 'ASC' ?  1 :  -1;
          }
          if (a.codRte < b.codRte) {
            return direction == 'ASC' ?  -1 :  1;
          }
          return 0;
        });
        break;
    }
    return data;
  }

}

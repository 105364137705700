import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {InputSorterModel} from "../../../models/endpoints/InputSorter";
import {HttpRequestModel} from "../../../models/endpoints/HttpRequest";
import {RequestsProvider} from "../../../providers/requests/requests.provider";
import {CarrierModel} from "../../../models/endpoints/carrier.model";
import {PickingStoreModel} from "../../../models/endpoints/PickingStore";

@Injectable({
  providedIn: 'root'
})
export class SorterInputService {

  private postProductScanUrl: string = environment.apiSorter + "/sorters/scanProduct";
  private postPackingFullUrl: string = environment.apiSorter + "/sorters/packing-full";
  private postCheckProductInWayUrl: string = environment.apiSorter + "/sorter/execution/way/product/check";
  private postRackScanUrl: string = environment.apiSorter + "/sorter/process/product/rack";
  private postCheckWayIsFreeUrl: string = environment.apiSorter + "/sorter/execution/way/check-free";
  private getSingleCarrierUrl: string = environment.apiSorter + "/sorter/packing/{{id}}";
  private getProductByReferenceUrl = environment.apiSorter + "/sorter/product/{{reference}}";
  private postVentilateUrl = environment.apiSorter + "/sorter/processes/picking-store/ventilate";
  private postCheckPackingAvailabilityUrl = environment.apiSorter + '/sorter/packing/availability/check';

  constructor(
    private http: HttpClient,
    private requestsProvider: RequestsProvider
  ) { }

  postProductScan(params: InputSorterModel.ParamsProductScan): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postProductScanUrl, params);
  }

  postCheckProductInWay(params: InputSorterModel.ParamsCheckProductInWay): Observable<InputSorterModel.CheckProductInWay> {
    return this.http.post<InputSorterModel.ResponseCheckProductInWay>(this.postCheckProductInWayUrl, params).pipe(map(response => {
      return response.data;
    }));
  }

  postRackScan(params: InputSorterModel.ParamsRackScan): Observable<InputSorterModel.RackScan> {
    return this.http.post<InputSorterModel.ResponseRackScan>(this.postRackScanUrl, params).pipe(map(response => {
      return response.data;
    }));
  }

  postCheckWayIsFree(params: InputSorterModel.ParamsCheckWayIsFree): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postCheckWayIsFreeUrl, params);
  }

  getSingleCarrier(id: any): Observable<any> {
    return this.http.get(this.getSingleCarrierUrl.replace("{{id}}", String(id)));
  }

  postCheckPackingAvailability(params: CarrierModel.ParamsCheckPackingAvailability): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postCheckPackingAvailabilityUrl, params);
  }

  postVentilate(params: PickingStoreModel.ParamsVentilate) : Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postVentilateUrl, params);
  }

  getProductByReference(reference: any): Observable<any> {
    return this.http.get(this.getProductByReferenceUrl.replace("{{reference}}", String(reference)));
  }

  postPackingFull(params: InputSorterModel.ParamsPackingFull): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postPackingFullUrl, params);
  }
}

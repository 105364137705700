export const config = {
  version: '1.0.22',
  downloads: {
    directoryBase: 'Download',
    directoryAL: 'AL',
    directoryExpeditions: 'expediciones',
    directoryManifests: 'manifiestos',
    directoryReturns: 'devoluciones'
  },
  enabledScandit: false
};

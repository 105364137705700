import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-input-error',
  templateUrl: './form-input-error.component.html',
  styleUrls: ['./form-input-error.component.scss']
})
export class FormInputErrorComponent {
  @Input() form: FormGroup;
  @Input() formInput: string;

  get formInputError() {
    if (this.form.controls[this.formInput]) {
      const errors = this.form.controls[this.formInput].errors;
      for (const errorName in errors) {
        if (errors[errorName]) {
          let fieldFor = '';
          if (this.formInput == 'direction' || this.formInput == 'address') {
            fieldFor = ' para la dirección';
          } else if (this.formInput == 'name' || this.formInput == 'firstName') {
            fieldFor = ' para el nombre';
          }

          switch (errorName) {
            case 'required':
              return null;
            case 'minlength':
              return `Mínimo ${this.form.controls[this.formInput].errors.minlength.requiredLength} caracteres${fieldFor}.`;
            case 'maxlength':
              return `Máximo ${this.form.controls[this.formInput].errors.maxlength.requiredLength} caracteres${fieldFor}.`;
            case 'email':
              return 'Introduce una dirección de email correcta.';
            default:
              return this.form.controls[this.formInput].errors[errorName];
          }
        }
      }
      return null;
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatTooltipModule } from '@angular/material';
import {OutScanIncidencesShowJsonComponent} from "./out-scan-incidences-show-json.component";



@NgModule({
  declarations: [OutScanIncidencesShowJsonComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    MatTooltipModule
  ],
  entryComponents: [
    OutScanIncidencesShowJsonComponent
  ]
})
export class OutScanIncidencesShowJsonModule {}

import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MarketplacesService} from '../../../../services/src/lib/endpoint/marketplaces/marketplaces.service';
import { group, arrayCategories} from '../../../../services/src/environments/environment';
import {
  MatTableDataSource,
  MatPaginator,
  MatPaginatorIntl,
  MatAccordion,
  MatExpansionPanel,
  MatSelectChange
} from '@angular/material';
import {forkJoin, Observable} from 'rxjs'
import {PaginatorLanguageComponent} from "../../components/paginator-language/paginator-language.component";
import {IntermediaryService} from "@suite/services";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {RequestQueryBuilder} from "@nestjsx/crud-request";

interface IMappingInfo{
  name : string;
  id : number;
  "group_data" : {[id : string] : string}
  expanded : boolean;
  data : any[];
  table : ITableInfo;
  paginator : MatPaginator;
}

interface ITableInfo{
  datasource : MatTableDataSource<any>;
  input : any;
  options : any[];
}

enum MappingProcess{
  BRANDS = 5,
  COLORS = 3,
  SIZES = 4,
  FEATURES = 2,
  SEASONS = 30,
  SIZEDOMAINS = 31,
}

@Component({
  selector: 'suite-mappings',
  templateUrl: './mappings.component.html',
  styleUrls: ['./mappings.component.scss'],
  providers: [
    {provide: MatPaginatorIntl, useValue: PaginatorLanguageComponent()}
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class MappingsComponent implements OnInit, AfterViewInit {

  private static readonly DEFAULT_GROUP_DATA : {[id : string] : string}= {
    "17" : "Temporada",
    "18" : "Plantilla",
    "19" : "Tipo de tacón",
    "inner material" : "Mat. Interior",
    "outer material" : "Mat. Exterior,",
    "heel" : 'Medidas',
    "genreId" : "genre",
    "product type" : 'Tipo de producto'
  };

  private originObservableProcess : Observable<any>[] = [];
  private originCallbackProcess : {(data : any) : void}[] = [];
  private destinyObservableProcess : Observable<any>[] = [];
  private destinyCallbackProcess : {(data : any) : void}[] = [];

  @ViewChildren(MatPaginator) paginators: QueryList<MatPaginator>;
  username;
  dataDBsave = [];
  displayedColumns = ['blank', 'avelonData', 'marketData'];
  domainSizeColumns = ['blank','sizeGroup', 'family', 'brand', 'sizes'];
  market;
  marketExternalId;
  marketName;
  mappingData : IMappingInfo[];
  expandedElement: any | null = null;
  sizesDatasource = new MatTableDataSource();
  sizeDomainReferenceFilter;
  sizeDomainFamilyFilter = "-1";
  sizeDomainBrandFilter = "-1";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private marketplacesService: MarketplacesService,
    private intermediaryService : IntermediaryService
  ) {
  }

  async ngOnInit() {
    this.username = localStorage.getItem("username");

    await this.route.params.subscribe(params=>{
      const marketPlaceInfo = this.marketplacesService.getCachedMarketById(params.id);
      if(marketPlaceInfo){
        this.market = marketPlaceInfo.reId;
        this.marketName = marketPlaceInfo.name;
        this.marketExternalId = marketPlaceInfo.externalId;
        let mappingList = [];
        mappingList = marketPlaceInfo.configurationData &&
        marketPlaceInfo.configurationData.catalog &&
        marketPlaceInfo.configurationData.catalog.mappings
          ? marketPlaceInfo.configurationData.catalog.mappings : [];
        this.mappingData = mappingList.map(mappingInfo => {
          mappingInfo['expanded'] = false;
          if(!mappingInfo.group_data){
            mappingInfo['group_data'] = MappingsComponent.DEFAULT_GROUP_DATA;
          }
          return mappingInfo;
        });
      }
    });
  }

  async ngAfterViewInit() {
    await this.intermediaryService.presentLoadingNew("Cargando mapeos...");
    await this.loadMappingProcesses();
  }

  private async loadMappingProcesses(){
    for(const mappingProcess of this.mappingData){
      await this.loadMappingProcess(mappingProcess);
    }
    this.getMappingValue();
  }

  private async loadMappingProcess(mappingProcess : IMappingInfo){
    switch (mappingProcess.id){
      case MappingProcess.BRANDS:
        this.loadBrandsMapping(mappingProcess);
        break;
      case MappingProcess.SIZES:
        this.loadSizesMapping(mappingProcess);
        break;
      case MappingProcess.COLORS:
        this.loadColorsMapping(mappingProcess);
        break;
      case MappingProcess.FEATURES:
        this.loadFeaturesMapping(mappingProcess);
        break;
      case MappingProcess.SEASONS:
        this.loadSeasonsMapping(mappingProcess);
        break;
      case MappingProcess.SIZEDOMAINS:
        this.loadSizeDomainMapping(mappingProcess);
        break;
    }
  }

  private loadBrandsMapping(mappingProcess: IMappingInfo){
    this.originObservableProcess.push(this.marketplacesService.getBrands());
    this.originCallbackProcess.push(data=>{
      mappingProcess.data = [];
      if (data && data.length) {
        data.forEach(brand => {
          if (brand.market_filters.find(marketId => (marketId.marketId == this.market))) {
            mappingProcess.data.push({
              avelonData: {id: brand.externalId, name: brand.name.trim()},
              marketData: {id: -1, name: null}
            });
          }
        });
        mappingProcess.data.sort((a, b) => (a.avelonData.name.toLowerCase() > b.avelonData.name.toLowerCase()) ? 1 : ((b.avelonData.name.toLowerCase() > a.avelonData.name.toLowerCase()) ? -1 : 0));
        mappingProcess.table = {datasource : new MatTableDataSource<any>(mappingProcess.data), input : "", options : []};
      }
    });

    this.destinyObservableProcess.push(this.marketplacesService.getMarketBrands(this.marketExternalId));
    this.destinyCallbackProcess.push(data=>{
      let brands = [];
      for (let brand of data) {
        brands.push({id: brand.externalId, name: brand.name});
      }
      brands.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
      mappingProcess.table.options = brands
    })
  }
  private loadSizesMapping(mappingProcess: IMappingInfo){
    this.originObservableProcess.push(this.marketplacesService.getSizes());
    this.originCallbackProcess.push(data=> {
      if (!mappingProcess.data) {
        mappingProcess.data = [];
      }
      if (data && data.length) {
        data.forEach(size => {
          if (size.market_filters.find(marketId => (marketId.marketId == this.market))) {
            if (mappingProcess.data.length) {
              if (!mappingProcess.data.find(searchSize => {
                return searchSize.avelonData.name.trim() === size.name.trim();
              })) {
                mappingProcess.data.push({
                  avelonData: {id: size.name.trim(), name: size.name.trim()},
                  marketData: {id: -1, name: null}
                });
              }
            } else {
              mappingProcess.data.push({
                avelonData: {id: size.name.trim(), name: size.name.trim()},
                marketData: {id: -1, name: null}
              });
            }
          }
        });
        let sizeTransformed = [];
        for (let sizeValue of mappingProcess.data.slice()) {
          let numbers = '';
          let letters = '';
          for (let i = 0; i < sizeValue.avelonData.name.length; i++) {
            if (isNaN(sizeValue.avelonData.name[i])) {
              letters = sizeValue.avelonData.name.substr(i, sizeValue.avelonData.name.length);
              break;
            } else {
              numbers += sizeValue.avelonData.name[i];
            }
          }
          if (numbers == '') {
            numbers = '999999';
          }
          sizeTransformed.push({sizeValue, numbers, letters});
        }
        sizeTransformed.sort((a, b) => (parseInt(a.numbers) > parseInt(b.numbers)) ? 1 : ((parseInt(b.numbers) > parseInt(a.numbers)) ? -1 : (a.letters.toLowerCase() > b.letters.toLowerCase()) ? 1 : ((b.letters.toLowerCase() > a.letters.toLowerCase()) ? -1 : 0)));
        let sortedSizes = [];
        for (let size of sizeTransformed) {
          sortedSizes.push(size.sizeValue);
        }
        mappingProcess.data = sortedSizes.slice();
        mappingProcess.table = {datasource: new MatTableDataSource<any>(mappingProcess.data), input: "", options: []};
      }
    });

    this.destinyObservableProcess.push(this.marketplacesService.getMarketSizes(this.marketExternalId));
    this.destinyCallbackProcess.push(data=>{
      let sizes = [];
      for (let size of data) {
        sizes.push({id: size.externalId, name: size.name});
      }
      for (let size of sizes) {
        if (mappingProcess.table.options.length) {
          if (!mappingProcess.table.options.find(searchSize => {
            return searchSize.name.trim() === size.name.trim();
          })) {
            mappingProcess.table.options.push({id: size.name, name: size.name});
          }
        } else {
          mappingProcess.table.options.push({id: size.name, name: size.name});
        }
      }
      mappingProcess.table.options.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
    });
  }

  private loadColorsMapping(mappingProcess: IMappingInfo){
    this.originObservableProcess.push(this.marketplacesService.getColors());
    this.originCallbackProcess.push(data => {
      mappingProcess.data = [];
      if (data && data.length) {
        data.forEach(color => {
          if (color.market_filters.find(marketId => (marketId.marketId == this.market))) {
            mappingProcess.data.push({
              avelonData: {id: color.externalId, name: color.name.trim()},
              marketData: {id: -1, name: null}
            });
          }
        });
        mappingProcess.data.sort((a, b) => (a.avelonData.name.toLowerCase() > b.avelonData.name.toLowerCase()) ? 1 : ((b.avelonData.name.toLowerCase() > a.avelonData.name.toLowerCase()) ? -1 : 0));
        mappingProcess.table = {datasource : new MatTableDataSource<any>(mappingProcess.data), input : "", options : []};
      }
    });

    this.destinyObservableProcess.push(this.marketplacesService.getMarketColors(this.marketExternalId));
    this.destinyCallbackProcess.push(data=>{
      let colors = [];
      for (let color of data) {
        colors.push({id: color.externalId, name: color.name});
      }
      colors.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
      mappingProcess.table.options = colors;
    })
  }
  private loadFeaturesMapping(mappingProcess: IMappingInfo){
    this.originObservableProcess.push(this.marketplacesService.getFeatures());
    this.originCallbackProcess.push(data=>{
      mappingProcess.data = [];
      if (data && data.length) {
        data.forEach(feature => {
          if (feature.market_filters.find(marketId => (marketId.marketId == this.market))) {
            if (arrayCategories.includes(Number(feature.dataGroup))) {
            const groupId = group.some(c => c.id == feature.dataGroup);
            if (groupId) {
              mappingProcess.data.push({
                avelonData: {id: feature.externalId, name: group.find(c => c.id == feature.dataGroup).name +': ' + feature.name.trim(), group: feature.dataGroup},
                marketData: {id: -1, name: null}
              });
            }
          }
        }
        });
        mappingProcess.data.sort((a, b) => (parseInt(a.avelonData.group) > parseInt(b.avelonData.group)) ? 1 : ((parseInt(b.avelonData.group) > parseInt(a.avelonData.group)) ? -1 : ((a.avelonData.name.toLowerCase() > b.avelonData.name.toLowerCase()) ? 1 : ((b.avelonData.name.toLowerCase() > a.avelonData.name.toLowerCase()) ? -1 : 0))));
        mappingProcess.table = {datasource : new MatTableDataSource<any>(mappingProcess.data), input : "", options : []};
      }
    });

    this.destinyObservableProcess.push(this.marketplacesService.getMarketFeatures(this.marketExternalId));
    this.destinyCallbackProcess.push(data=>{
      let features = [];
      for (let feature of data) {
        if (feature.additionalGrouping) {
          let groupName = 'Característica';
          if (mappingProcess.group_data[feature.additionalGrouping]){
            groupName = mappingProcess.group_data[feature.additionalGrouping];
          }
          features.push({id: feature.externalId, name: `${groupName}: ${feature.name}`, group: feature.additionalGrouping});
        }
      }
      features.sort((a, b) => (parseInt(a.group) > parseInt(b.group)) ? 1 : ((parseInt(b.group) > parseInt(a.group)) ? -1 : ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))));
      mappingProcess.table.options = features;
    });
  }

  private loadSeasonsMapping(mappingProcess: IMappingInfo){
    this.originObservableProcess.push(this.marketplacesService.getSeasons());
    this.originCallbackProcess.push(data=>{
      mappingProcess.data = [];
      if (data && data.length) {
        data.forEach(color => {
          if (color.market_filters.find(marketId => (marketId.marketId == this.market))) {
            mappingProcess.data.push({
              avelonData: {id: color.externalId, name: color.name.trim()},
              marketData: {id: -1, name: null}
            });
          }
        });
        mappingProcess.data.sort((a, b) => (a.avelonData.name.toLowerCase() > b.avelonData.name.toLowerCase()) ? 1 : ((b.avelonData.name.toLowerCase() > a.avelonData.name.toLowerCase()) ? -1 : 0));
        mappingProcess.table = {datasource: new MatTableDataSource<any>(mappingProcess.data), input: "", options: []};
      }
    });

    this.destinyObservableProcess.push(this.marketplacesService.getMarketSeasons(this.marketExternalId));
    this.destinyCallbackProcess.push(data=>{
      let seasons = [];
      for (let season of data) {
        seasons.push({id: season.externalId, name: season.name});
      }
      seasons.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
      mappingProcess.table.options = seasons
    });
  }

  private loadSizeDomainMapping(mappingProcess){
    mappingProcess.data = [];
    mappingProcess.table = {
      datasource : new MatTableDataSource<any>(mappingProcess.data),
      input : {brands : [], family : []},
      options : []
    };
    mappingProcess['expandedRow'] = -1;
    mappingProcess['customPaginator'] = {
      page : 1,
      count : 0,
      pageCount : 0,
      reset : false
    };
  }

  getMappingValue() {
    forkJoin(
      this.originObservableProcess
    ).subscribe(results => {
        let index = 0;
        this.originCallbackProcess.forEach(callback=>{
          callback(results[index]);
          index++;
        });

      this.getDestinyValues();
    }, async()=> await this.intermediaryService.dismissLoadingNew());
  }

  getDestinyValues() {
    forkJoin(this.destinyObservableProcess).subscribe(async results => {
      let index = 0;
      this.destinyCallbackProcess.forEach(callback=>{
        callback(results[index]);
        index++;
      });
      this.paginators.forEach((paginator, paginatorIndex)=>{
        if(this.mappingData.length){
          this.mappingData[paginatorIndex].paginator = paginator;
          this.mappingData[paginatorIndex].table.datasource.paginator = paginator;
        }
      });
      await this.updateDataSaved();
    }, async()=> await this.intermediaryService.dismissLoadingNew());
  }

  async updateDataSaved() {
    this.marketplacesService.getMapDataRules().subscribe(async data => {
      if (data) {
        this.dataDBsave = data;
      } else {
        this.dataDBsave = [];
      }

      this.dataDBsave.forEach(item => {
        if (item.marketId == this.market) {
          const mappingProcess: IMappingInfo = this.getMappingProcessById(item.typeMapped);
          if (mappingProcess) {
            let newOption = {id: -1, name: null};

            mappingProcess.table.options.forEach(option => {
              if (option.id == item.marketDataId) {
                newOption = option;
              }
            });

            mappingProcess.data.forEach(data => {
              if (item.typeMapped == MappingProcess.FEATURES) {
                if (data.avelonData.id == item.originDataId && data.avelonData.group == item.aditionalMapInfo) {
                  data.marketData = {
                    id: newOption.id,
                    name: newOption.name
                  }
                }
              } else {
                if (data.avelonData.id == item.originDataId) {
                  data.marketData = {
                    id: newOption.id,
                    name: newOption.name
                  }
                }
              }
            });

            mappingProcess.table.datasource.data = mappingProcess.data;
            if (!!mappingProcess.table.input) {
              this.searchOnMappingList(mappingProcess);
            }
          }
        }
      });
      await this.intermediaryService.dismissLoadingNew();
    });
  }

  private getMappingProcessById(id) : IMappingInfo{
    return this.mappingData.find(mapping=> mapping.id == id);
  }

  changeOptionSelect(e, element, mappingProcess : IMappingInfo) {
    let marketData = null;

    mappingProcess.table.options.forEach(item => {
      if (item.id == e.value) {
        marketData = item;
      }
    });

    const marketDataId = marketData ? marketData.id : -1;

    let dataSend = {
      originDataId: element.avelonData.id,
      marketDataId,
      typeMapped: mappingProcess.id,
      marketId: this.market,
      aditionalMapInfo: mappingProcess.id == MappingProcess.FEATURES ? element.avelonData.group :"",
      creationUsr: "",
      modificationUsr: ""
    };

    let dataToUpdate = {
      externalId: dataSend.originDataId,
      type:mappingProcess.id,
      marketReId:this.market
    };

    let update: boolean = false;
    let idToUpdate: number = 0;

    this.dataDBsave.forEach(item => {
      if (mappingProcess.id == MappingProcess.FEATURES) {
        if (item.originDataId == element.avelonData.id && item.typeMapped == mappingProcess.id && item.aditionalMapInfo == element.avelonData.group  && item.marketId == this.market) {
          update = true;
          idToUpdate = item.id;
        }
      } else {
        if (item.originDataId == element.avelonData.id && item.typeMapped == mappingProcess.id && item.marketId == this.market) {
          update = true;
          idToUpdate = item.id;
        }
      }
    });

    if(marketDataId == -1) {
      this.marketplacesService.deleteMapDataRules(idToUpdate).subscribe(data => {
        this.updateDataSaved();
        this.marketplacesService.update_data(dataToUpdate).subscribe(result => {});
      });
    } else {
      if (update) {
        dataSend.modificationUsr = this.username;
        this.marketplacesService.updateMapDataRules(idToUpdate, dataSend).subscribe(data => {
          this.updateDataSaved();
          this.marketplacesService.update_data(dataToUpdate).subscribe(result => {});
        });
      } else {
        dataSend.creationUsr = this.username;
        this.marketplacesService.postMapDataRules(dataSend).subscribe(data => {
          this.updateDataSaved();
          this.marketplacesService.update_data(dataToUpdate).subscribe(result => {});
        });
      }
    }
  }

  searchOnMappingList(mappingProcess : IMappingInfo) {
    if (!!mappingProcess.table.input) {
      let filteredData = [];
      for (let data of mappingProcess.data) {
        if (data.avelonData.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").search(mappingProcess.table.input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
          !== -1) {
          filteredData.push(data);
        }
      }
      mappingProcess.table.datasource.data = filteredData;
    } else {
      mappingProcess.table.datasource.data = mappingProcess.data.slice();
    }
    mappingProcess.paginator.firstPage();
  }

  async expandPanel(mappingProcess) {
    this.resetMappingParams(mappingProcess);
    mappingProcess.expanded = !mappingProcess.expanded;
    if (mappingProcess.id === MappingProcess.SIZEDOMAINS) {
      if(mappingProcess.expanded){
        mappingProcess.customPaginator.page = 1;
        this.loadSizeDomainFilters(mappingProcess);
        await this.searchSizeDomains(mappingProcess);
      }
    }
    mappingProcess.table.datasource.data = mappingProcess.data.slice();
    if(mappingProcess.paginator){
      mappingProcess.paginator.firstPage();
    }
  }

  private resetMappingParams(mappingProcess){
    this.expandedElement = null;
    this.mappingData.forEach(mapping =>{
      if(mapping.id != mappingProcess.id){
        mapping.expanded = false;
      }
    });
    this.sizeDomainReferenceFilter = "";
    this.sizeDomainFamilyFilter= "-1";
    this.sizeDomainBrandFilter= "-1";
  }

  private async loadSizeDomainFilters(mappingProcess){
    await this.loadSizeDomainBrandsFilters(mappingProcess)
    await this.loadSizeDomainFamilyFilters(mappingProcess);
    await this.loadSizeDomainAvelonSizes(mappingProcess);
  }

  private async loadSizeDomainBrandsFilters(mappingProcess){
    const brandsMappingProcess = this.getMappingById(MappingProcess.BRANDS);
    if (!brandsMappingProcess && !mappingProcess.table.input.brands.length) {
      await this.marketplacesService.getMarketBrands(this.marketExternalId).subscribe(data => {
        let brands = [];
        for (let brand of data) {
          brands.push({id: brand.externalId, name: brand.name});
        }
        brands.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
        mappingProcess.table.input.brands = brands;
      });
    } else {
      mappingProcess.table.input.brands = brandsMappingProcess.table.options;
    }
  }

  private async loadSizeDomainFamilyFilters(mappingProcess){
    if (!mappingProcess.table.input.family.length) {
      const query = '?' + RequestQueryBuilder.create({
        search: {
          "$and": [
            {marketId : this.market.id},
            {type : "feature"},
            {additionalGrouping : "age_gender"}
          ]
        },
        sort: {
          field: "name", order: "ASC"
        }
      }).query(false);
      await this.marketplacesService.getMarketProductProperty(query).subscribe(response => {
        mappingProcess.table.input.family = response.data;
      });
    }
  }

  private async loadSizeDomainAvelonSizes(mappingProcess: IMappingInfo) {
    const sizeMappingProcess = this.getMappingById(MappingProcess.SIZES);
    if (!sizeMappingProcess && !mappingProcess.table.options.length) {
      await this.marketplacesService.getMarketSizes(this.marketExternalId).subscribe(data => {
        let sizes = [];
        for (let size of data) {
          sizes.push({id: size.externalId, name: size.name});
        }
        for (let size of sizes) {
          if (mappingProcess.table.options.length) {
            if (!mappingProcess.table.options.find(searchSize => {
              return searchSize.name.trim() === size.name.trim();
            })) {
              mappingProcess.table.options.push({id: size.name, name: size.name});
            }
          } else {
            mappingProcess.table.options.push({id: size.name, name: size.name});
          }
        }
      });
    } else {
      mappingProcess.table.options = sizeMappingProcess.table.options;
    }
  }

  async expandRow(mappingProcess, item: any, rowIndex) {
    this.sizesDatasource.data = [];
    if(this.expandedElement == null || this.expandedElement != item){
      if (mappingProcess.expandedRow != -1) {
        mappingProcess.table.datasource.data[mappingProcess.expandedRow].actionText = 'Ver';
      }
      item.actionText = 'Cerrar';
      mappingProcess.expandedRow = rowIndex;
      await this.loadSizes(item);
    } else if(this.expandedElement == item){
        mappingProcess.table.datasource.data[mappingProcess.expandedRow].actionText = 'Ver';
    }
  }

  private async loadSizes(item){
    await this.intermediaryService.presentLoadingNew();
    const parsedSizes = this.getParsedDomainSizes(item.id, item.sizes);
    this.sizesDatasource.data = parsedSizes;
    await this.intermediaryService.dismissLoadingNew()
  }

  private getMappingById(mappingId){
    return this.mappingData.find(mappingProcess=> mappingProcess.id == mappingId);
  }

  private getParsedDomainSizes(id, sizes) {
    const parsedSizes = [];
    Object.keys(sizes).map(key => {
      parsedSizes.push(
        {
          id: id,
          value: key,
          euValue: !!sizes[key] ? sizes[key] : '-1'
        });
    });
    return parsedSizes;
  }

  async syncChanges() {
    await this.intermediaryService.presentLoadingNew("Sincronizando cambios");
    this.marketplacesService.postUpdateZalandoSizeMapWithApi().subscribe(async ()=>{
      await this.intermediaryService.dismissLoadingNew()
    }, async () => await this.intermediaryService.dismissLoadingNew());
  }

  private async searchSizeDomains(mappingProcess) {
    this.expandedElement = null;
    await this.intermediaryService.presentLoadingNew("Cargando tallas")
    const query = this.createQuery(mappingProcess);
    await this.marketplacesService.getZalandoSizeMap(query).subscribe(async response=>{
      this.processSizeDomainResponse(mappingProcess, response);
      mappingProcess.table.datasource.data = mappingProcess.data;
      await this.intermediaryService.dismissLoadingNew();
    }, async ()=> await this.intermediaryService.dismissLoadingNew());
  }

  private processSizeDomainResponse(mappingProcess, response) {
    mappingProcess.data = [];
    mappingProcess.customPaginator.count = response.count;
    mappingProcess.customPaginator.pageCount = response.pageCount;
    mappingProcess.data = [];
    if (response && response.data && response.data.length) {
      response.data.forEach(item => {
        const [firstSize, ...lastSize]  = Object.keys(item.sizes);
        mappingProcess.data.push({
          id: item.id,
          name: `${item.label} - ${item.ageGender}, ${item.brandName}, ${item.zone} [${firstSize}-${lastSize[0]}]`,
          family: item.ageGender,
          brand: item.brandName,
          sizes: item.sizes,
          actionText : 'Ver'
        })
      });
    }
  }

  private createQuery(mappingProcess) : string {
    if(mappingProcess.customPaginator.reset){
      mappingProcess.customPaginator.reset = false;
      mappingProcess.customPaginator.page = 1;
    }
    const query = '?' + RequestQueryBuilder.create({
      limit: 10,
      page: mappingProcess.customPaginator.page,
      search: {
        "$and": this.getQueryFilters()
      },
      sort: {
        field: "label", order: "DESC"
      }
    }).query(false);
    return query;
  }

  private getQueryFilters() {
    const filters = [];
    if(!!this.sizeDomainReferenceFilter){
      filters.push(
        {
          label : {$cont : this.sizeDomainReferenceFilter}
        }
      )
    }
    if(!!this.sizeDomainBrandFilter && this.sizeDomainBrandFilter != '-1'){
      filters.push(
        {
          brandCode : {$eq : this.sizeDomainBrandFilter}
        }
      )
    }
    if(!!this.sizeDomainFamilyFilter && this.sizeDomainFamilyFilter != '-1'){
      filters.push(
        {
          ageGender : {$eq : this.sizeDomainFamilyFilter}
        }
      )
    }
    return filters;
  }

  nextPage(mappingProcess) {
    mappingProcess.customPaginator.page++;
    mappingProcess.customPaginator.page = Math.min(mappingProcess.customPaginator.page, mappingProcess.customPaginator.pageCount)
    this.searchSizeDomains(mappingProcess);
  }

  previousPage(mappingProcess) {
    mappingProcess.customPaginator.page--;
    mappingProcess.customPaginator.page = Math.max(1, mappingProcess.customPaginator.page)
    this.searchSizeDomains(mappingProcess);
  }

  resetPage(mappingProcess) {
    mappingProcess.customPaginator.reset = true;
  }

  updateSizeDomain(event: MatSelectChange, element: any, mappingProcess) {
    this.marketplacesService.postUpdateZalandoSizeMap(element.id,
      {value : element.value, euValue : event.value}).subscribe(()=>{
        this.updateSize(mappingProcess, element.value, event.value)
    });
  }

  updateSize(mappingProcess, value, euValue){
      mappingProcess.table.datasource.data[mappingProcess.expandedRow].sizes[value] = euValue;
  }
}

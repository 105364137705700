import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ExpeditionService,
  IntermediaryService,
  LabelsService,
  OplTransportsService,
  TypesService
} from '@suite/services';
import { PackageHistoryService } from '@suite/services';
import { AlertController, LoadingController, ModalController, NavParams } from "@ionic/angular";
import { Observable } from "rxjs";
import * as moment from 'moment';
import { HttpResponse } from "@angular/common/http";
import { InternOrderPackageStatus } from '../enums/status.enum';
import {MatAccordion} from '@angular/material/expansion';
import {switchMap} from 'rxjs/operators';
import {HttpRequestModel} from "../../../../services/src/models/endpoints/HttpRequest";
import { saveAs } from "file-saver";
import {environment} from "../../../../services/src/environments/environment";
import {LabelModel} from "../../../../services/src/models/endpoints/Label";
import {DownloaderService} from "../../../../services/src/lib/downloader/downloader.service";

@Component({
  selector: 'suite-history-details',
  templateUrl: './history-details.component.html',
  styleUrls: ['./history-details.component.scss']
})
export class HistoryDetailsComponent implements OnInit {

  data;
  order;
  location;
  package;
  orderHistorical;
  orderHistoricalLast;
  list;
  delivery;
  section = 'information';
 @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    private typeService: TypesService,
    private packageService: PackageHistoryService,
    private modalController: ModalController,
    private navParams: NavParams,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private intermediaryService: IntermediaryService,
    private labelsService: LabelsService,
    private downloaderService: DownloaderService
  ) {
    this.data = this.navParams.get("data");
    this.order = this.navParams.get("order");
    this.package = this.navParams.get("package");
    this.delivery = this.navParams.get("delivery");
  }

  ngOnInit() {
    this.getProductHistorical();
    this.getProductHistoricalLast();
    this.getList();
  }

  /**
   * Get historical of products
   */
  getProductHistorical(): void {
    this.packageService.getHistorical(this.order).subscribe(historical => {
      this.orderHistorical = historical;
    });
  }

  getProductHistoricalLast(): void {
    this.packageService.getHistoricalLast(this.package).subscribe(historical => {
      this.orderHistoricalLast = historical;
    });
  }

  getList(): void {
    this.packageService.getList(this.order).subscribe(historical => {
      this.list = historical;
    });
  }

  formattedDate(date) {
    return moment(date).format("DD/MM/YYYY")
  }

  formattedDateTime(date) {
    return moment(date).format("DD/MM/YYYY HH:mm")
  }

  close() {
    this.modalController.dismiss();
  }


  getStatus(status){
    switch (status) {
      case InternOrderPackageStatus.PENDING_COLLECTED:
        return 'Etiqueta generada';
        break;
      case InternOrderPackageStatus.COLLECTED:
        return 'Recogido';
        break;
      case InternOrderPackageStatus.SORTER_IN:
        return 'Entrada Sorter';
        break;
      case InternOrderPackageStatus.SORTER_OUT:
        return 'Salida Sorter';
        break;
      case InternOrderPackageStatus.JAIL_IN:
        return 'Ubicado embalaje';
        break;
      case InternOrderPackageStatus.SORTER_RACK_IN :
        return 'Estantería anexa Sorter';
        break;
      case InternOrderPackageStatus.RECEIVED:
        return 'Recepcionado';
        break;
      case InternOrderPackageStatus.WAREHOUSE_OUTPUT:
        return 'Salida Almacén';
        break;

      default:
        break;
    }

  }

  async printOrderTag() {
    let body = {expeditionId: this.data.package.order.expedition.id, reprint: true };
    await this.labelsService.postServicePrintPack(body).subscribe((result: LabelModel.IExpeditionDocToPrint[]) =>{
        for (const iExpeditionDoc in result) {
          const expeditionDoc = result[iExpeditionDoc];
          const urlDownload = environment.downloadPdf + '/' + expeditionDoc.externalPath;
          const fileName = this.downloaderService.getExpeditionDownloadedFileName(expeditionDoc.extension, expeditionDoc.expeditionDeliveryRequest);

          saveAs(urlDownload, fileName);
        }
      },
      async (err) => {
        if(err.error.code == 404){
          console.log(err);
          this.intermediaryService.presentToastError("No tiene archivos para descargar");
        }else {
          console.log(err);
          this.intermediaryService.presentToastError("No se pudo descargar el archivo");
        }
      });
  }

  download_file(fileURL, fileName) {
      var save = document.createElement('a');
      save.href = fileURL;
      save.target = '_blank';
      var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
      save.download = fileName || filename;
      var evt = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': false
      });
      save.dispatchEvent(evt);
      (window.URL).revokeObjectURL(save.href);
  }

}

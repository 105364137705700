import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {ModalController} from "@ionic/angular";
import {CategorizeProductsComponent} from "./modals/categorize-products/categorize-products.component";
import {MatTableDataSource} from "@angular/material";
import {MarketplacesService} from "../../../../services/src/lib/endpoint/marketplaces/marketplaces.service";
import * as XLSX from "xlsx";
import { IntermediaryService } from '@suite/services';

@Component({
  selector: 'suite-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit, OnDestroy {

  @ViewChild('catalogTable') table: ElementRef;
  innerStatus = [
    {id: 'invalid', name: 'Invalido'},
    {id: 'valid', name: 'Valido'},
    {id: 'validWithIssues', name: 'Valido con errores'},
    {id: 'uploaded', name:'Subido'},
    {id: 'rejected', name: 'Rechazado'},
    {id: 'failed', name: 'Error'},
  ];
  marketStatus = [
    {id: 'unknown', name: 'Desconocido'},
    {id: 'uploaded', name: 'Subido'},
    {id: 'uploadedStock0', name: 'Subido - stock 0'},
    {id: 'uploadedWithErrors', name: 'Subido con errores'},
  ];
  catalogData;
  allCatalogData;
  catalogTableData;
  catalogTableHeader;
  selectedProducts;
  market;
  marketExternalId;
  marketMW;
  unfilteredCatalogData;
  searchReference;
  searchModel;
  searchSize;
  searchBrand;
  searchColor;
  searchFamily;
  searchDescription;
  searchPrice;
  searchStock;
  refresher;
  count: number = 0;
  page: number = 1;
  pageCount: number = 0;
  total: number = 0;
  marketName: string;
  private loading = null;

  constructor(
    private route: ActivatedRoute,
    private modalController: ModalController,
    private marketplacesService: MarketplacesService,
    private intermediaryService : IntermediaryService
  ) {
  }

  async ngOnInit() {
    this.searchReference = "";
    this.searchModel = "";
    this.searchBrand = "";
    this.searchSize = "";
    this.searchColor = "";
    this.searchFamily = "";
    this.searchDescription = "";
    this.searchPrice = "";
    this.searchStock = "";
    this.catalogData = [];
    this.catalogTableHeader = ['select', 'ref', 'model', 'size', 'brand', 'color', 'family', 'description', 'pvpac', 'units', 'internalStatus', 'marketStatus','updatedStatus' ];
    this.selectedProducts = [];
    this.market = '';
    this.marketExternalId = '';

    await this.route.params.subscribe(params=>{
      const marketPlaceInfo = this.marketplacesService.getCachedMarketById(params.id);
      if(marketPlaceInfo){
        this.market = marketPlaceInfo.id;
        this.marketName = marketPlaceInfo.name;
        this.marketMW = marketPlaceInfo.id;
      }
    });
    this.getProductsData();
    this.startInterval();
  }

  ngOnDestroy() {
    this.stopInterval()
  }

  async getProductsData() {
    this.catalogData = [];
    this.unfilteredCatalogData = [];

    this.marketplacesService.searchProductWithVariationsCatalog(this.buildFilter({limit : 15, page : this.page})).subscribe(data => {
      for (let product of data.data) {
        for (let productVariation of product.productsMarkets[0].variations) {
          if (product.productsMarkets[0].marketId == this.market) {
            this.catalogData.push({
              reference: product.reference,
              model: product.model,
              brand: product.brand,
              productsMarkets : product.productsMarkets,
              size: productVariation.idSize,
              color: product.color ? product.color : '-',
              family: product.family ? product.family : '-',
              description: product.description ? product.description : '-',
              pvpac: productVariation.activePrice ? productVariation.activePrice : '-',
              //pvp: productVariation.originalPrice ? productVariation.originalPrice : '-',
              units: productVariation.stock,
              statusRead: productVariation.statusRead,
              statusUpload: productVariation.statusUpload,
              statusSync: productVariation.statusSync,
              // active: (productInMarket.status == 'done'),
            });
          }
        }
      }
      this.unfilteredCatalogData = this.catalogData.slice();
      this.count = data.count;
      this.total = data.total;
      this.pageCount = data.pageCount;
      this.catalogTableData = new MatTableDataSource(this.catalogData);
    });

  }

async getAllProductsData() {
    let jsonExport = [];
    let exportPageCount = 0;

    this.allCatalogData = [];
    let dataJson = 0;
    this.marketplacesService.getAllProductCatalog(this.buildFilter({limit : 140, page : 1})).subscribe(element => {
    exportPageCount = element.pageCount;

      for (let i = 1; i <= exportPageCount; i++) {
        this.marketplacesService.getAllProductCatalog(this.buildFilter({limit : 140, page : i})).subscribe(async data => {

         dataJson = dataJson + element.count;
          for (let product of data.data) {
                 let productInMarket = product.productsMarkets.find(productMarket => productMarket.marketId == this.marketMW);
                 for (let productVariation of product.productVariations) {
                   if (productVariation.externalId === this.market) {
                     this.allCatalogData.push({
                       ref: product.reference,
                       model: product.model,
                       brand: product.brand,
                       size: productVariation.idSize,
                       color: product.color ? product.color : '-',
                       family: product.family ? product.family : '-',
                       description: product.description ? product.description : '-',
                       pvpac: productVariation.discountedPrice ? productVariation.discountedPrice : '-',
                       //pvp: productVariation.originalPrice ? productVariation.originalPrice : '-',
                       units: productVariation.stock,
                       active: (productInMarket.status === 'validAndSynced'),
                       statusRead: productVariation.statusRead,
                       statusUpload: productVariation.statusUpload,
                       statusSync: productVariation.statusSync
                     });
                   }
                 }
               }
                jsonExport=this.allCatalogData;
                  if (dataJson >= element.total && this.selectedProducts.length <= 0) {
                    this.exportSelectedExcel(jsonExport);
                    await this.intermediaryService.dismissLoadingNew();
                  } else{
                    this.exportSelectedExcel(this.selectedProducts);
                    await this.intermediaryService.dismissLoadingNew();
                  }
       });
     }
    });
  }

  getStatus(productMarketStatus, typeOfStatus){
    return this[typeOfStatus].find(element => element.id === productMarketStatus).name;
  }

  private buildFilter(options ?: {limit: number, page :number }) : any {
    const filters =  {
      limit : options ? options.limit : 15,
      page : options ? options.page : this.page,
      orderBy : 'reference',
      filter : this.getQueryFilters()
    };

    return filters;
  }

  private getQueryFilters() : any{
    let filters = {markets : [{id: this.marketMW}],  variation : {}};

    this.addFilterToList(filters, "reference", this.searchReference)
    this.addFilterToList(filters, "model", this.searchModel)
    this.addFilterToList(filters.variation, "discountedPrice", this.searchPrice)
    this.addFilterToList(filters, "description", this.searchDescription)
    this.addFilterToList(filters, "brand", this.searchBrand)
    this.addFilterToList(filters, 'color', this.searchColor)
    this.addFilterToList(filters, 'family', this.searchFamily)
    this.addFilterToList(filters.variation, 'idSize', this.searchSize)
    this.addFilterToList(filters.variation, 'stock', this.searchStock)

    return filters;
  }

  private addFilterToList(filters, field : string, value){
    if(!!value){
      filters[field] =  value;
    }
  }

  exportSelectedExcel(json){

    let datajson = ['Ref', 'Modelo', 'Marca', 'Talla', 'Color', 'Familia', 'Descripción', 'PVP AC', 'Stock disp.', 'Estado Actualizado', 'Estado Market', 'Estado Lectura'];
    let marketName = this.marketName.replace(' ', '-');

    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(json);
    console.log(json);
    console.log('Exportado');
    XLSX.utils.sheet_add_json (ws, [], {header : datajson, origin : "A1" });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Searched Data Export');

    /* save to file */
    XLSX.writeFile(wb, marketName.toLowerCase()+'ExportAllData.xlsx');
  }

  selectProductRow(product) {
    if (product.isSelected){
      this.selectedProducts.push(product);
    } else {
      const index = this.selectedProducts.map(p => p.reference).indexOf(product.reference);
      this.selectedProducts.splice(index, 1);
    }
  }

  changeProductActive(product) {
    product.active = !product.active;
  }

  async openCategoryModal() {
    let modal = await this.modalController.create({
      component: CategorizeProductsComponent,
      componentProps: { selectedProducts: this.selectedProducts }
    });
    return modal.present();
  }

  startInterval() {
    this.refresher = setInterval(async () => {
      await this.getProductsData();
    }, 60000);
  }

  stopInterval() {
    if (this.refresher) {
      clearInterval(this.refresher);
    }
  }

  refreshProductList() {
    this.stopInterval();
    this.getProductsData();
    this.startInterval();
  }

  searchProducts() {
    this.page = 1;
    this.refreshProductList();
  }

  nextPage() {
    this.page++;
    this.page = Math.min(this.page, this.pageCount)
    this.refreshProductList();
  }

  previousPage() {
    this.page--;
    this.page = Math.max(1, this.page)
    this.refreshProductList();
  }

  resetFilters() {
    this.searchModel = "";
    this.searchBrand = "";
    this.searchReference = "";
    this.searchPrice = "";
    this.searchSize = "";
    this.searchColor = "";
    this.searchDescription = "";
    this.searchStock = "";
    this.refreshProductList();
  }

  async searchProductsToExport() {
    await this.intermediaryService.presentLoadingNew('Exportando Excel...');
    await this.refreshProductListToExport();
  }

  async refreshProductListToExport() {
    this.stopInterval();
    await this.getAllProductsData();
    this.startInterval();
  }

}

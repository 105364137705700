import { Injectable } from '@angular/core';
import { environment } from '@suite/services';

declare let mlkitMatrixSimple: any;

//BARCODESCANNER SERVICE PHONEGAP CORDOVA
interface BarcodeScannerOptions {
  preferFrontCamera?: boolean;
  showFlipCameraButton?: boolean;
  showTorchButton?: boolean;
  disableAnimations?: boolean;
  disableSuccessBeep?: boolean;
  prompt?: string;
  formats?: string;
  orientation?: string;
  torchOn?: boolean;
  saveHistory?: boolean;
  resultDisplayDuration?: number;
  action?: string;
  method?: string;
  url?: string;
  data?: string;
  token?: string;
  requestType?: string;
  screen?: string;
}
@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerService {

  barcodeScanRseult: any;

  constructor() { }

  useScanner(action: string): any {

    let options: BarcodeScannerOptions = {
      preferFrontCamera: false,
      showFlipCameraButton: true,
      showTorchButton: true,
      torchOn: false,
      disableAnimations: false,
      disableSuccessBeep: false,
      prompt: 'Posicione el código dentro del recuadro de escaneo',
      resultDisplayDuration: 2000,
      action: action,
    };

    options = this.requestInfo(options);

    console.log('options', options);
    console.log('options', JSON.stringify(options));
    console.log('action', options.action);

    return mlkitMatrixSimple.scan(options)

  }

  //This method sets the values we are gonna use for the calls to the database
  requestInfo(options: BarcodeScannerOptions): BarcodeScannerOptions {

    switch (options.action) {

      case ('extendedProductInfo'): {
        options = {
          ...options,
          method: 'GET',
          url: environment.apiBase + '/products/info/extended/',
          data: '',
          token: localStorage.getItem("access_token"),
          requestType: 'getExtendedInfo',
        }
        break;
      }

      //TODO
      case ('locateDefective'): {
        options = {
          ...options,
          method: 'GET',
          url: environment.apiBase + '/products/info/extended/',
          data: '',
          token: localStorage.getItem("access_token"),
          requestType: 'getExtendedInfo',
        }
        break;
      }

      //TODO
      case ('releaseReservations'): {
        options = {
          ...options,
          method: 'GET',
          url: environment.apiBase + '/products/info/extended/',
          data: '',
          token: localStorage.getItem("access_token"),
          requestType: 'getExtendedInfo',
        }
        break;
      }

      //TODO
      case ('requestList'): {
        options = {
          ...options,
          method: 'GET',
          url: environment.apiBase + '/products/info/extended/',
          data: '',
          token: localStorage.getItem("access_token"),
          requestType: 'getExtendedInfo',
        }
        break;
      }

      case ('sizeRegularization'): {
        options = {
          ...options,
          method: 'POST',
          url: environment.apiBase,
          data: '',
          token: localStorage.getItem("access_token"),
          requestType: 'sizeRegularization',
        }
        break;
      }

      case ('receptionPairByPair'): {
        options = {
          ...options,
          method: 'GET',
          url: environment.apiBase,
          data: '',
          token: localStorage.getItem("access_token"),
          requestType: 'receptionPairByPair',
        }
        break;
      }
    }
    return options;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SettingsService } from "../../../services/src/lib/storage/settings/settings.service";
import { IntermediaryService } from '@suite/services';
import {PrinterServiceService} from "../../../services/src/lib/endpoint/printer-service/printer-service.service";
import {PrinterServiceModel} from "../../../services/src/models/endpoints/PrinterService";
import {PositionsToast} from "../../../services/src/models/positionsToast.type";
import {TimesToastType} from "../../../services/src/models/timesToastType";
import {AlertController} from "@ionic/angular";

@Component({
  selector: 'settings-print-service',
  templateUrl: './section-settings-print-service.component.html',
  styleUrls: ['./section-settings-print-service.component.scss']
})

export class SectionSettingsPrintServiceComponent implements OnInit {

  form: FormGroup = this.formBuilder.group({
    printerServiceAddress: ['', [Validators.pattern('(\\d{1,3}).(\\d{1,3}).(\\d{1,3}).(\\d{1,3}):(\\d{1,4})')]],
  });

  private zplTestTag: string = '+^XA\n' +
    '+\n' +
    '+^FX ==== LABEL INFO ====\n' +
    '+^FX points width = 832 height = 1198\n' +
    '+^FX mm width = 104 height = 150\n' +
    '+^FX scale = 7.99\n' +
    '+\n' +
    '+^FX set label encoding to UTF-8\n' +
    '+^FX prefix text commands with circumflex-FH and escape characters with _xx_xx sequences from\n' +
    '+^FX https://www.utf8-chartable.de/\n' +
    '+^FX example: "_FH_FD Expedici_C3_B3n" (_ are circumflexes)\n' +
    '+^CI28\n' +
    '+\n' +
    '+\n' +
    '+^FXREGION label preprinted blocks\n' +
    '+^FXENDREGION label preprinted blocks\n' +
    '+\n' +
    '+\n' +
    '+^FXREGION label body\n' +
    '+^FO80,160,0 ^FB672,1,0,C,0 ^A0N,72,72 ^FH^FDPRUEBA^FS\n' +
    '+\n' +
    '+^FO300,300,0 ^GD176,176,40,B,L ^FS\n' +
    '+^FO300,300,0 ^GD176,176,40,B,R ^FS\n' +
    '+^FX vertical lines have a top and bottom sharp edge, and since they can\'t be rotated 90º, those visual\n' +
    '+^FX lines looked very ugly, so I put some white squares here overlapping the X edges and creating\n' +
    '+^FX a perfect cross\n' +
    '+^FO300,300,0 ^GB1,20,20,W,0 ^FS\n' +
    '+^FO300,456,0 ^GB1,20,20,W,0 ^FS\n' +
    '+^FO496,300,0 ^GB1,20,20,W,0 ^FS\n' +
    '+^FO496,456,0 ^GB1,20,20,W,0 ^FS\n' +
    '+\n' +
    '+^FO80,560,0 ^FB672,2,0,C,0 ^A0N,64,64 ^FH^FDEtiqueta de prueba^FS\n' +
    '+\n' +
    '+^FO104,800,0 ^BY3 ^BCN,160,N,N,N,N ^FDTEST_GALVINTEC^FS\n' +
    '+^FO136,976,0 ^FB560,1,0,C,0 ^A0N,48,48 ^FDTEST_GALVINTEC^FS\n' +
    '+^FXENDREGION label body\n' +
    '+\n' +
    '+^XZ';
  private typeTestTag: string = 'incidence_geolabel_formatted_zpl';

  constructor(
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private intermediaryService: IntermediaryService,
    private printerServiceService: PrinterServiceService
  ) {}

  ngOnInit() {
    this.settingsService.getDeviceSettings()
      .then((incomingDataObservable) => incomingDataObservable.subscribe(value => {
        if (!value.printerServiceAddress) {
          value.printerServiceAddress = SettingsService.DEFAULT_PRINTER_SERVICE_ADDRESS;
        }

        this.form.patchValue(value);
      }));
  }

  async submit() {
    await this.intermediaryService.presentLoadingNew("Guardando configuración...");
    await this.settingsService.saveDeviceSetting(SettingsService.STORAGE_KEY_PRINTER_SERVICE_ADDRESS, this.form.value.printerServiceAddress)
      .then(() =>{
        this.intermediaryService.dismissLoadingNew();
        this.intermediaryService.presentToastSuccess('Configuración guardada', TimesToastType.DURATION_SUCCESS_TOAST_2000, PositionsToast.BOTTOM);
      });
  }

  async testPrinterService() {
    const bodyPrint: PrinterServiceModel.ParamsPrint = {
      document: this.zplTestTag,
      documentType: this.typeTestTag
    };

    await this.intermediaryService.presentLoadingNew("Lanzando prueba a " + this.form.value.printerServiceAddress + " ...");
    this.printerServiceService.printTest(this.form.value.printerServiceAddress, bodyPrint).subscribe(res => {
      console.debug('PrinterService::Item printed correctly: ', typeof res != 'string' ? JSON.stringify(res) : res);
      this.intermediaryService.dismissLoadingNew();
      this.intermediaryService.presentToastSuccess("Comprobada y confirmada la conexión con el servicio de impresión. Compruebe que se haya impreso la etiqueta de pruebas en la impresora ZPL.", TimesToastType.DURATION_SUCCESS_TOAST_2000, PositionsToast.BOTTOM);
    }, error => {
      console.error('PrinterService::ERROR to print item: ', JSON.stringify(error));
      this.intermediaryService.dismissLoadingNew();
      this.intermediaryService.presentToastError("Ha ocurrido un error al intentar conectar con el servicio de impresión. Compruebe que la dirección configurada es la correcta.", PositionsToast.BOTTOM, TimesToastType.DURATION_ERROR_TOAST_LONG);
    });
  }
}

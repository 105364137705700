import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'suite-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {
  @Input() set parentId(_parent){
    if(_parent) {
      if (!_parent.isParent) {
        this.isParent = false;
        this.form.addControl('avelonId', new FormControl(''));
      }
      this.form.get("defectTypeParent").patchValue(_parent, { emitEvent: false });
    }
  };
  form:FormGroup = this.formBuilder.group({
    id:'',
    name:['',[Validators.required]],
    includeInDeliveryNote:[false],
    includeInIncidenceTicket:[false],
    defectTypeParent:['']
  });

  @Input() set group(_group){
    if(_group) {
      if (!_group.isParent) {
        this.isParent = false;
        this.form.addControl('avelonId', new FormControl(''));
      }
      this.form.patchValue(_group);

    }

  }

  isParent = true;
  constructor(private formBuilder:FormBuilder) { }

  ngOnInit() {
  }

  /**
   * Sanitize the nul values of data
   * @param object object to be cleaned
   */
  sanitize(object){
    let keys:Array<string> = Object.keys(object);
    keys.forEach(key=>{
      if(!object[key]){
        delete object[key];
      }
    });
    return object;
  }

  /**
   * Obtain the value of form
   */
  getValue(){
    return this.sanitize(this.form.value);
  }

}

import { Component, OnInit , ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { AuditsService, IntermediaryService } from '@suite/services';
import { MatSort, MatDialog, Sort } from '@angular/material';
import { ProductsByAuditComponent } from './modals/products-by-audit/products-by-audit.component';
import {AuditsModel} from "../../../services/src/models/endpoints/Audits";
import AuditPacking = AuditsModel.AuditPacking;
import {DateTimeParserService} from "../../../services/src/lib/date-time-parser/date-time-parser.service";
import {FormBuilder, FormGroup} from '@angular/forms';
import { TagsInputOption } from '../components/tags-input/models/tags-input-option.model';
import {FiltersModel} from '../../../services/src/models/endpoints/filters';
import {FilterButtonComponent} from '../components/filter-button/filter-button.component';
import {PaginatorComponent} from '../components/paginator/paginator.component';
import { AlertController, ModalController } from '@ionic/angular';
export interface Audits {
  user: string;
  status: number;
  packing: {
    reference: string
  };
  products: any;
}

const ELEMENT_DATA: Audits[] = [];

@Component({
  selector: 'suite-audits',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.scss']
})
export class AuditsComponent implements OnInit {

  displayedColumns: string[] = ['user', 'status', 'packing', 'date-start', 'products','options'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('filterButtonPacking') filterButtonPacking: FilterButtonComponent;
  @ViewChild('filterButtonStatus') filterButtonStatus: FilterButtonComponent;
  @ViewChild('filterButtonUser') filterButtonUser: FilterButtonComponent;
  @ViewChild('filterButtonCountProducts') filterButtonCountProducts: FilterButtonComponent;
  @ViewChild('filterButtonDates') filterButtonDates: FilterButtonComponent;

  entities;
  pagerValues;
  lastUsedFilter: string;
  form: FormGroup;
  packing: Array<TagsInputOption> = [];
  status: Array<TagsInputOption> = [];
  user: Array<TagsInputOption> = [];
  date: Array<TagsInputOption> = [];
  countProducts: Array<TagsInputOption> = [];
  pauseListenFormChange: boolean;

  isFilteringStatus: number = 0;
  isFilteringPacking: number = 0;
  isFilteringUser: number = 0;
  isFilteringCountProducts: number = 0;
  isFilteringDates: number = 0;

  columns = {};
  lastLimit: number;
  constructor(
    private audit : AuditsService,
    private intermediaryService : IntermediaryService,
    public dialog: MatDialog,
    private dateTimeParserService: DateTimeParserService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    this.initEntity();
    this.initForm();
    this.getFilters();
    //this.getColumns(this.form)
    this.listenChanges();
  }

  initEntity() {
    this.entities = {
      user: [],
      packing: [],
      status: [],
      countProducts: [],
      datesFilter:[],
      orderBy: this.formBuilder.group({
        type: 1,
        order: "asc"
      })
    }
  }

  initForm() {
    this.pagerValues = this.paginator.fixedSGApagerValues;
    console.log(this.pagerValues);
    this.form = this.formBuilder.group({
      user: [],
      packing: [],
      status: [],
      countProducts: [],
      datesFilter:[],
      pagination: this.formBuilder.group({
        page: 1,
        limit: this.pagerValues[0]
      }),
      orderBy: this.formBuilder.group({
        type: 1,
        order: "asc"
      })
    })
    this.getList(this.form);
  }

  getFilters() {
    this.audit.getAllFilters().subscribe((entities) => {
      this.packing = this.updateFilterSource(entities.packing, 'packing');     
      this.status = this.updateFilterSource(entities.status, 'status');
      this.user = this.updateFilterSource(entities.user,'user');
      this.countProducts = this.updateFilterSource(entities.countProducts,'countProducts');
      this.date = this.updateFilterSource(entities.datesFilter,'datesFilter');
      this.reduceFilters(entities);
      setTimeout(() => {
        this.pauseListenFormChange = false;
        this.pauseListenFormChange = true;
      }, 0);
    })
  }

  private updateFilterSource(dataEntity: FiltersModel.Default[], entityName: string) {
    let resultEntity;

    this.pauseListenFormChange = true;
    let dataValue = this.form.get(entityName).value;
    resultEntity = dataEntity.map(entity => {
      entity.id = <number>(<unknown>entity.id);
      if(entity.name.toString()=='false'){
        entity.name = 'No';
        entity.value = 'No';
      }else{
        if(entity.name.toString()=='true'){
          entity.name = 'Sí';
          entity.value = 'Sí';
        }else{
          entity.name = entity.name;
          entity.value = entity.name;
        }
      }
      entity.checked = true;
      entity.hide = false;
      return entity;
    });

    if (dataValue && dataValue.length) {
      this.form.get(entityName).patchValue(dataValue, { emitEvent: false });
    }

    setTimeout(() => { this.pauseListenFormChange = false; }, 0);

    return resultEntity;
  }

  private reduceFilters(entities){
    this.filterButtonPacking.listItems = this.reduceFilterEntities(this.packing, entities,'packing');   
    this.filterButtonStatus.listItems = this.reduceFilterEntities(this.status, entities,'status'); 
    this.filterButtonUser.listItems = this.reduceFilterEntities(this.user,entities,'user');
    this.filterButtonCountProducts.listItems = this.reduceFilterEntities(this.countProducts,entities,'countProducts'); 
    this.filterButtonDates.listItems = this.reduceFilterEntities(this.date,entities,'datesFilter'); 
  }

  private reduceFilterEntities(arrayEntity: any[], entities: any, entityName: string) {
    if (this.lastUsedFilter !== entityName) {
      let filteredEntity = entities[entityName] as unknown as string[];

      arrayEntity.forEach((item) => {
        item.hide = filteredEntity.includes(item.value);
      });

      return arrayEntity;
    }
  }  

  async openProducts(data: AuditPacking): Promise<void> {   

    let modal = await this.modalController.create({
      component: ProductsByAuditComponent,
      componentProps: data
    });
    modal.onDidDismiss().then(async () => {
     
    });
    await modal.present();
    
  }

  showProducts(item) {
    this.audit.getById(item.id).subscribe(res => {
      let responseFormatted: AuditPacking = res.data;
      if (responseFormatted.countProducts === 0) {
        this.intermediaryService.presentToastWarning(`No hay productos escaneados para la auditoría del embalaje ${responseFormatted.packing.reference}`);
      } else {
        this.openProducts(responseFormatted);
      }
    })
  }

  applyFilter(event: any) {
    const filteredValue = event.target.value;
    this.dataSource.filter = filteredValue.trim().toLowerCase();
  }

  applyFilters(filtersResult, filterType) {
    const filters = filtersResult.filters;
    switch (filterType) {     
      case 'packing':
        let packingFiltered: string[] = [];
        for (let packing of filters) {

          if (packing.checked) packingFiltered.push(packing.id);
        }

        if (packingFiltered.length >= this.packing.length) {
          this.form.value.packing = [];
          this.isFilteringPacking = this.packing.length;
        } else {
          if (packingFiltered.length > 0) {
            this.form.value.packing = packingFiltered;
            this.isFilteringPacking = packingFiltered.length;
          } else {
            this.form.value.packing = [];
            this.isFilteringPacking = this.packing.length;
          }
        }
        break;
    
      case 'status':
        let statusFiltered: string[] = [];
        for (let status of filters) {

          if (status.checked) statusFiltered.push(status.id);
        }

        if (statusFiltered.length >= this.status.length) {
          this.form.value.status = [];
          this.isFilteringStatus = this.status.length;
        } else {
          if (statusFiltered.length > 0) {
            this.form.value.status = statusFiltered;
            this.isFilteringStatus = statusFiltered.length;
          } else {
            this.form.value.status = [];
            this.isFilteringStatus = this.status.length;
          }
        }
        break;

      case 'user':
        let userFiltered: string[] = [];
        for (let user of filters) {

          if (user.checked) userFiltered.push(user.id);
        }

        if (userFiltered.length >= this.user.length) {
          this.form.value.user = [];
          this.isFilteringUser = this.user.length;
        } else {
          if (userFiltered.length > 0) {
            this.form.value.user = userFiltered;
            this.isFilteringUser = userFiltered.length;
          } else {
            this.form.value.user = [];
            this.isFilteringUser = this.user.length;
          }
        }
        break;

      case 'countProducts':
        let countProductsFiltered: string[] = [];
        for (let countProducts of filters) {

          if (countProducts.checked) countProductsFiltered.push(countProducts.id);
        }

        if (countProductsFiltered.length >= this.countProducts.length) {
          this.form.value.countProducts = [];
          this.isFilteringCountProducts = this.countProducts.length;
        } else {
          if (countProductsFiltered.length > 0) {
            this.form.value.countProducts = countProductsFiltered;
            this.isFilteringCountProducts = countProductsFiltered.length;
          } else {
            this.form.value.countProducts = [];
            this.isFilteringCountProducts = this.countProducts.length;
          }
        }
        break;

      case 'datesFilter':
        let datesFilterFiltered: string[] = [];
        for (let datesFilter of filters) {

          if (datesFilter.checked) datesFilterFiltered.push(datesFilter.id);
        }

        if (datesFilterFiltered.length >= this.date.length) {
          this.form.value.datesFilter = [];
          this.isFilteringDates = this.date.length;
        } else {
          if (datesFilterFiltered.length > 0) {
            this.form.value.datesFilter = datesFilterFiltered;
            this.isFilteringDates = datesFilterFiltered.length;
          } else {
            this.form.value.datesFilter = [];
            this.isFilteringDates = this.date.length;
          }
        }
        break;
        
    }

    this.lastUsedFilter = filterType;
   
    this.form.value.pagination.page = 1;
  
    this.getList(this.form);
  }

  getDateFormatted(originalDate) : string {
    return this.dateTimeParserService.dateMonthYear(originalDate);
  }

  async getList(form?: FormGroup){
    this.audit.getAllFiltered(form.value).subscribe((resp:any) => {
        if (resp.results) {
          this.dataSource = new MatTableDataSource(resp.results);
          const paginator = resp.pagination;

          this.paginator.length = paginator.totalResults;
          this.paginator.pageIndex = paginator.selectPage;
          this.paginator.lastPage = paginator.lastPage;
          // this.paginator.page = paginator.selectPage;
          resp.filters.forEach(element => {
            this.columns[element.name] = element.id;
          });
          console.log('resp.filters: ',resp.filters)
          console.log('this.columns: ',this.columns)
        }
      },
      async err => {
        await this.intermediaryService.dismissLoading()
      },
      async () => {
        await this.intermediaryService.dismissLoading()
      })
  }

  async sortData(event: Sort) {
    debugger
    this.form.value.orderBy.type = this.columns[event.active];
    this.form.value.orderBy.order = event.direction !== '' ? event.direction : 'asc';

    this.intermediaryService.presentLoading('Cargando Filtros...').then(() => {
      this.getList(this.form);
    });
  }

  listenChanges() {
    this.lastLimit = this.form.value.pagination.limit;

    this.paginator.page.subscribe(page => {
      /**true if only change the number of results */
      let flag = this.lastLimit === page.pageSize;
      this.lastLimit = page.pageSize;
      this.form.value.pagination = {
        limit: page.pageSize,
        page: flag ? page.pageIndex : 1
      };
      this.getList(this.form)
    });

  }

  async refresh(){
    this.intermediaryService.presentLoading("Actualizando...");
    this.form.value.pagination = {
      limit: this.lastLimit || this.paginator.fixedSGApagerValues[0],
      page: 1
    };
    await this.getList(this.form);
    this.intermediaryService.dismissLoading();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailableItemsGroupedComponent } from './available-items-grouped.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import { ScrollingModule as ExperimentalScrollingModule} from "@angular/cdk-experimental/scrolling";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MatButtonModule, MatInputModule, MatPaginatorModule, MatTableModule, MatTooltipModule} from "@angular/material";
import {FilterButtonModule} from "../../../components/filter-button/filter-button.module";

@NgModule({
  declarations: [AvailableItemsGroupedComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    CommonModule,
    MatListModule,
    MatIconModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    MatTooltipModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    FilterButtonModule,
    MatTableModule
  ],
  entryComponents: [AvailableItemsGroupedComponent]
})
export class AvailableItemsGroupedModule { }

import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams} from "@ionic/angular";
import {AvelonIncidencesReception} from "../../../../../services/src/models/endpoints/AvelonIncidencesReception";

@Component({
  selector: 'suite-in-scan-incidences-show-json',
  templateUrl: './avelon-incidences-reception-show-request-json.component.html',
  styleUrls: ['./avelon-incidences-reception-show-request-json.component.scss']
})

export class AvelonIncidencesReceptionShowRequestJsonComponent implements OnInit {

  incidence: AvelonIncidencesReception;
  request: string;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
  ) {
    this.request = '';
  }

  ngOnInit() {
    this.incidence = this.navParams.get("incidence");
    this.request = JSON.stringify(JSON.parse(this.incidence.request), null, 4);
  }

  close() {
    this.modalController.dismiss();
  }

  downloadRequestJSON(error) {
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(error.request);
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", `${this.incidence.id}-${error.createdAt}-request.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

}

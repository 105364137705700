import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {FormBuilder, FormGroup} from '@angular/forms';
import {IntermediaryService} from '@suite/services';
import {TableEmitter} from '../../../services/src/models/tableEmitterType';
import {TableFiltersComponent} from "../components/table-filters/table-filters.component";
import {DeviceInfoService} from "../../../services/src/lib/endpoint/device-info/device-info.service";

@Component({
  selector: 'installed-app-versions',
  templateUrl: './installed-app-versions.component.html',
  styleUrls: ['./installed-app-versions.component.scss'],
})
export class InstalledAppVersionsComponent implements OnInit {

  @ViewChild('suiteTableFilters') suiteTableFilters: TableFiltersComponent;

  columnsData = [
    {
      name: 'updatedAt',
      title: 'Fecha',
      field: ['updatedAt'],
      filters: true,
      type: 'text'
    },
    {
      name: 'alVersion',
      title: 'Versión',
      field: ['alVersion'],
      filters: true,
      type: 'text'
    },
    {
      name: 'model',
      title: 'Dispositivo',
      field: ['model'],
      filters: true,
      type: 'text'
    },
    {
      name: 'system',
      title: 'Sistema Operativo',
      field: ['system'],
      filters: true,
      type: 'text'
    },
    {
      name: 'uuid',
      title: 'ID',
      field: ['uuid'],
      filters: true,
      type: 'text'
    }
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  pauseListenFormChange: boolean;
  pagination;
  filtersData;
  form: FormGroup = this.formBuilder.group({
    updatedAt: [],
    alVersion: [],
    model: [],
    system: [],
    uuid: [],
    pagination: this.formBuilder.group({
      page: 1,
      limit: undefined
    }),
    orderby: this.formBuilder.group({
      type: 1,
      order: "asc"
    })
  });

  constructor(
    private deviceInfoService: DeviceInfoService,
    private formBuilder: FormBuilder,
    private intermediaryService: IntermediaryService
  ) {
  }

  ngOnInit() {
    this.getFilters();
  }

  async ngAfterViewInit() {
    this.form.patchValue({pagination: {limit: this.suiteTableFilters.paginator.finalPagerValues[0]}});
    await this.getList();
  }

  async getList() {
    await this.intermediaryService.presentLoading("Cargando aplicaciones instaladas...");
    await this.deviceInfoService.getDeviceInfo(this.form.value).subscribe((resp: any) => {
        this.intermediaryService.dismissLoading()
        this.dataSource = new MatTableDataSource<any>(resp);
        this.pagination = resp.pagination;
      },
      async () => {
        await this.intermediaryService.dismissLoading()
      },
      async () => {
        await this.intermediaryService.dismissLoading()
      })
  }

  getFilters() {
    this.deviceInfoService.getFiltersDeviceInfo().subscribe((entities) => {
      this.filtersData = entities;
      setTimeout(() => {
        this.pauseListenFormChange = false;
        this.pauseListenFormChange = true;
      }, 0);
    }, (error) => {
      console.log(error);
    })
  }

  async emitMain(e) {
    switch (e.event) {
      case TableEmitter.BtnRefresh:
        this.form.value.pagination.page = 1;
        await this.getList();
        this.getFilters();
        break;
      case TableEmitter.Filters:
        this.form.get(e.value.entityName).patchValue(e.value.filters);
        await this.getList();
        break;
      case TableEmitter.Pagination:
        this.form.value.pagination = e.value;
        await this.getList();
        break;
      case TableEmitter.Sorter:
        this.form.value.orderby = e.value;
        await this.getList();
        break;
    }
  }

}

import {Component, OnInit, OnDestroy, ElementRef} from '@angular/core';
import DBR from "dynamsoft-javascript-barcode";
import Webcam from 'webcamjs';

DBR.BarcodeReader.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@8.1.3/dist/";
DBR.BarcodeReader.productKeys = "100264834-100264860";
// DBR.BarcodeReader._bUseFullFeature = true;

@Component({
  selector: 'suite-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss']
})
export class BarcodeScannerComponent implements OnInit, OnDestroy {

  bDestroyed = false;
  scanner = null;
  barcodes: {
    type: string,
    code: string
  }[] = [];
  snapshot: string = '';

  constructor(
    private elementRef: ElementRef
  ) {}

  async ngOnInit() {
    try{
      this.scanner = this.scanner || await DBR.BarcodeScanner.createInstance();

      await this.scanner.updateRuntimeSettings('balance');

      if(this.bDestroyed){
        this.scanner.destroy();
        return;
      }

      this.scanner.setUIElement(this.elementRef.nativeElement);

      // this.scanner.onFrameRead = results => {};

      // this.scanner.onUnduplicatedRead = (txt, result) => {};

      await this.scanner.open();

      Webcam.set({
        dest_width: 2880,
        dest_height: 1620,
        width: 480,
        height: 270,
        image_format: 'jpeg',
        jpeg_quality: 90,
        constraints: {
          width: { ideal: 3840 },
          height: { ideal: 2160 }
        }
      });
      Webcam.attach('#my_camera');

    }catch(ex){
      console.error(ex);
    }
  }

  ngOnDestroy(){
    this.bDestroyed = true;
    if(this.scanner){
      this.scanner.destroy();
    }
    Webcam.reset();
  }

  async takeSnapshot(): Promise<{barcodes: {type: string, code: string}[], snapshot: string}> {
    Webcam.snap(base64img => this.snapshot = base64img);
    const results = await this.scanner.decodeBase64String(this.snapshot);
    this.barcodes = results.map(r => {return {type: r.barcodeFormatString, code: r.barcodeText}});
    return {barcodes: this.barcodes, snapshot: this.snapshot};
  }

}

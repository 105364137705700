import { Component, OnInit, ViewChild } from '@angular/core';
import {GlobalVariableService, GlobalVariableModel, IntermediaryService, JailModel} from '@suite/services';
import { FormBuilder } from '@angular/forms';
import {Events, ModalController} from "@ionic/angular";
import {UsersReplenishmentGlobalVarComponent} from "../global-variables/users-replenishment-global-var/users-replenishment-global-var.component";
import {EmployeeService} from "../../../services/src/lib/endpoint/employee/employee.service";
import EmployeeReplenishment = EmployeeModel.EmployeeReplenishment;
import {EmployeeModel} from "../../../services/src/models/endpoints/Employee";
import {MatPaginator} from "@angular/material";
import {PrinterService} from "../../../services/src/lib/printer/printer.service";
import {PickingStoreService} from "../../../services/src/lib/endpoint/picking-store/picking-store.service";
import {PickingStoreModel} from "../../../services/src/models/endpoints/PickingStore";
import { environment as sga_environment } from '../../../../apps/sga/src/environments/environment';
import * as qz from 'qz-tray';
import { KJUR, KEYUTIL, stob64, hextorstr } from 'jsrsasign';

@Component({
  selector: 'suite-global-variables',
  templateUrl: './global-variables.component.html',
  styleUrls: ['./global-variables.component.scss']
})
export class GlobalVariablesComponent implements OnInit {

  listVariables: Array<GlobalVariableModel.GlobalVariable> = new Array<GlobalVariableModel.GlobalVariable>();
  private listTypesFromDb: Array<{ id: number, name: string, workwave: boolean, type: string, tooltip: string }> = [];
  private listVariablesFromDb: Array<GlobalVariableModel.GlobalVariable> = new Array<GlobalVariableModel.GlobalVariable>();
  private countLoadOfVariables: number = 0;
  private listReasons;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  employees: EmployeeReplenishment[];
  filters: {
    name: string,
    replenishment: string,
    order: string
  };
  employeesToShow: any;

  constructor(
    private events: Events,
    private globalVariableService: GlobalVariableService,
    private pickingStoreService: PickingStoreService,
    private formBuilder: FormBuilder,
    private intermediaryService: IntermediaryService,
    private modalController: ModalController,
    private employeeService: EmployeeService,
    private printerService: PrinterService,
  ) { }

  ngOnInit() {
    this.intermediaryService.presentLoading();

    this.getTypes();
    this.getReasonVariable();
    this.getGlobalVariables();
    this.filters = {
      name: '',
      replenishment: 'yes',
      order: 'ASC'
    };
    this.events.subscribe('load_of_variables', () => {
      this.countLoadOfVariables++;
      if (this.countLoadOfVariables == 2) {
        this.generateVariablesList();
        this.search();
        this.countLoadOfVariables = 0;
      }
    });
  }

  generateVariablesList() {
    this.intermediaryService.dismissLoading();
    if (this.listVariablesFromDb.length != this.listTypesFromDb.length) {
      this.listVariables = this.listVariablesFromDb;
      for (let iType in this.listTypesFromDb) {
        let type = this.listTypesFromDb[iType];
        let isTypeCreated: boolean = false;
        for (let iVariable in this.listVariablesFromDb) {
          let variable = this.listVariablesFromDb[iVariable];
          if (variable.type == type.id) {
            isTypeCreated = true;
            break;
          }
        }
        if (!isTypeCreated) {
          this.listVariables.push({ type: type.id, typeObject: type, value: null, tooltip: null });
        }
      }
    } else {
      this.listVariables = this.listVariablesFromDb;
    }
    this.listVariables.sort((a,b) => {
      if ( a && a.typeObject && b && b.typeObject && a.typeObject.type < b.typeObject.type ){
        return -1;
      }
      if ( a && a.typeObject && b && b.typeObject && a.typeObject.type > b.typeObject.type ){
        return 1;
      }
      if ( a && a.typeObject && b && b.typeObject && a.typeObject.name < b.typeObject.name ){
        return -1;
      }
      if ( a && a.typeObject && b && b.typeObject && a.typeObject.name > b.typeObject.name ){
        return 1;
      }
      return 0;
    });
  }

  getGlobalVariables() {
    this.globalVariableService
      .getAll()
      .subscribe((globalVariables) => {
        this.listVariablesFromDb = globalVariables;
        this.events.publish('load_of_variables');
      });
  }

  getTypes() {
    this.globalVariableService
      .getTypes()
      .subscribe((types) => {
        this.listTypesFromDb = types;
        this.events.publish('load_of_variables');
      });
  }

  getReasonVariable() {
    this.pickingStoreService
      .getLoadRejectionReasons()
      .then((res: PickingStoreModel.ResponseLoadRejectionReasons) => {
        let resData: Array<PickingStoreModel.RejectionReasons> = res.data;
        this.listReasons = resData;
      }, (error) => {
        console.error('Error::Subscribe::pickingStoreService::getLoadRejectionReasons', error);
      })
      .catch((error) => {
        console.error('Error::Subscribe::pickingStoreService::getLoadRejectionReasons', error);
      });

  }

  getTypeById(id) : string {
    let type = this.listTypesFromDb.find((type) => {
      return type.id == id;
    });

    return type.name || '';
  }
  getGeneralTypeById(id) : string {
    let type = this.listTypesFromDb.find((type) => {
      return type.id == id;
    });

    return type.type || '';
  }
  getTooltipById(id) : string {
    let type = this.listTypesFromDb.find((type) => {
      return type.id == id;
    });

    return type.tooltip || '';
  }

  updateVariables() {
    let variablesToUpdate = this.listVariables.filter((variable, index) => {
      this.listVariables[index].error = !variable.value;
      return variable.value;
    });
    if (variablesToUpdate.length == this.listVariables.length) {
      this.intermediaryService.presentLoading('Actualizando las variables...').then(() => {
        this.globalVariableService.store(variablesToUpdate).subscribe((res) => {
          this.search();
          this.getTypes();
          this.getGlobalVariables();
          this.intermediaryService.dismissLoading();
          this.intermediaryService.presentToastSuccess("Las variables han sido actualizadas.")
        },() => {
          this.intermediaryService.dismissLoading();
          this.intermediaryService.presentToastError("Actualización de variables fallida.");
        });
      });
    } else {
      this.intermediaryService.presentToastError('Inicialice todas las variables del sistema.');
    }
  }

  async usersReplenishment(){
    const modal = await this.modalController.create({component: UsersReplenishmentGlobalVarComponent});

    modal.onDidDismiss().then(async response => {
      if (response.data) {
        await this.intermediaryService.presentLoading('Cargando...');
        this.employeeService.store(response.data).then(async response => {
          if(response.code == 200){
            this.search();
            await this.intermediaryService.dismissLoading();
            await this.intermediaryService.presentToastSuccess('Los cambios se han guardado correctamente.');
          }else{
            console.error(response);
            await this.intermediaryService.dismissLoading();
          }
        }, async error => {
          console.error(error);
          await this.intermediaryService.dismissLoading();
        }).catch(async error => {
          console.error(error);
          await this.intermediaryService.dismissLoading();
        });
      }
    });

    await modal.present();
  }

  search(){
      const searchParameters = {
        name: this.filters.name,
        replenishment: this.filters.replenishment,
        pagination: {
          page: 1,
          limit: 10,
          sortType: this.filters.order
        }
      };
      this.employeeService.search(searchParameters).then(async response => {
        if(response.code == 200){
          this.employees = response.data[0];
          this.employeesToShow = this.employees.map( employee => {
            return employee.name;
          }).join(', ');
          for(let variable of this.listVariables){
            if(this.getTypeById(variable.type) == 'Usuarios que generan reposiciones'){
              variable.value = this.employeesToShow;
            }
          }
        }
      });
  }

  async sendZebraCommands() {
    await this.intermediaryService.presentLoading(
      'Guardando configuración...',
      () => {
        console.log('QZ Print::sendZebraCommands');
        const commandsToSend =
          '! U1 setvar "power.inactivity_timeout" "0"\n' +
          '! U1 setvar "power.low_battery_timeout" "0""\n' +
          '! U1 setvar ""media.type"" ""label""\n' +
          '! U1 setvar ""media.sense_mode"" ""gap""\n' +
          '~jc^xa^jus^xz';
        if (qz) {
          qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
          qz.security.setSignaturePromise(function(toSign) {
            return function(resolve, reject) {
              try {
                const pk = KEYUTIL.getKey(sga_environment.qzTrayPrivateKey);
                const sig = new KJUR.crypto.Signature({"alg": "SHA512withRSA"});  // Use "SHA1withRSA" for QZ Tray 2.0 and older
                sig.init(pk);
                sig.updateString(toSign);
                const hex = sig.sign();
                console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
                resolve(stob64(hextorstr(hex)));
              } catch (err) {
                console.error(err);
                reject(err);
              }
            };
          });
          qz.security.setCertificatePromise((resolve, _) => {
            resolve(sga_environment.printLicense);
          });

          qz.websocket.connect().then(() => {
            // Pass the printer name into the next Promise
            return qz.printers.find(sga_environment.printer);
          }).then(printer => {
            // Create a default config for the found printer
            const config = qz.configs.create(printer, {
              altPrinting: true
            });
            return qz.print(config, commandsToSend);
          }).then(() => {
            return qz.websocket.disconnect();
          }).catch(e => {
            qz.websocket.disconnect();
            console.error(e);
          });
        } else {
          this.intermediaryService.dismissLoading();
          this.intermediaryService.presentToastError('QZ Tray Print no instalado');
          console.log('QZ Tray not installed');
        }
      }
    );
  }
}

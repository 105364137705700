import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'suite-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent {

  /**for send the data of the value */
  @Output() submit = new EventEmitter();

  @Input() set building(building){
    if(building)
      if(building.manageAgency){
        building.manageAgencyId = building.manageAgency.id;
      }
      this.form.patchValue(building);
  }
  
  /**form to export data */
  form:FormGroup = this.formBuilder.group({
    id:[''],
    name:['',Validators.required],
    phone:['',Validators.required],
    address:['',Validators.required],
  });

  constructor(
    private formBuilder:FormBuilder,
    private modalController:ModalController) { }


   /**
    * close the current instance of update modal
    */
    close():void{
      this.modalController.dismiss();
    }

  /**
   * Send the data to the parent component
   */
  submitData():void{
    this.submit.emit(this.form.value);
  }

}

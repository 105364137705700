import {Component, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ReceptionAvelonModel, ReceptionsAvelonService} from "@suite/services";
import Expedition = ReceptionAvelonModel.Expedition;
import {ReceptionOcrModel} from "../../../../../services/src/models/endpoints/ReceptionOcr";
import OcrRecognitionLearningResponse = ReceptionOcrModel.OcrRecognitionLearningResponse;

@Component({
  selector: 'info-expeditions',
  templateUrl: './info-expeditions.component.html',
  styleUrls: ['./info-expeditions.component.scss']
})
export class InfoExpeditionsComponent implements OnInit {

  title: string = 'Información de la expedición';
  titleAnotherExpeditions: string = 'Otras expediciones';
  expedition: Expedition = null;
  anotherExpeditions: Expedition[] = [];
  learnedBrands: {id: number, name: string}[] = [];
  unlearnedBrands: {id: number, name: string}[] = [];

  constructor(
    private modalController: ModalController,
    private receptionsAvelonService: ReceptionsAvelonService
  ) {}

  ngOnInit() {
    this.receiveOCRRecognitionLearningBrands();
  }

  async close() {
    await this.modalController.dismiss();
  }

  async receptionExpedition(expedition: Expedition) {
    await this.modalController.dismiss({reception: true, expedition});
  }

  receiveOCRRecognitionLearningBrands() {
    const providerId: number = this.expedition ? this.expedition.providerId : this.anotherExpeditions[0].providerId;

    const subNext = (response: OcrRecognitionLearningResponse) => {
      if (response.code == 200) {
        this.learnedBrands = response.data && response.data.brands ? response.data.brands : [];
      } else {
        console.error(response);
      }
    };
    const subError = (error) => {
      console.error(error);
    };
    const subComplete = () => {
      if (subscription){
        subscription.unsubscribe()
      }
      this.getUnlearnedBrands();
    };

    const subscription = this.receptionsAvelonService.postOCRRecognitionLearning({id: providerId}).subscribe(subNext, subError, subComplete);
  }

  getUnlearnedBrands(){
    const providerId: number = this.expedition ? this.expedition.providerId : this.anotherExpeditions[0].providerId;
    this.receptionsAvelonService.getBrandsBySupplier(providerId).subscribe((brands: {id: number, name: string, avelonId: number}[]) => {
      this.unlearnedBrands = brands.filter(brand => !this.learnedBrands.map(b=>b.id).includes(brand.avelonId));
    },error => console.error(error));
  }

  getLearnedBrandNames(): string {
    if(this.learnedBrands){
      return this.learnedBrands.map(b=>b.name).join(', ');
    }else{
      return '';
    }
  }

  getUnlearnedBrandNames(): string {
    if(this.unlearnedBrands){
      return this.unlearnedBrands.map(b=>b.name).join(', ');
    }else{
      return '';
    }
  }

}

import { Injectable } from '@angular/core';
import { CarrierModel } from 'libs/services/src/models/endpoints/carrier.model';
import { RequestsProvider } from "../../../providers/requests/requests.provider";
import { HttpRequestModel } from "../../../models/endpoints/HttpRequest";
import { environment } from '../../../environments/environment';
import {ExcellModell} from "../../../models/endpoints/Excell";
import {InventoryModel} from "../../../models/endpoints/Inventory";
import { AuthenticationService } from '../authentication/authentication.service';
import { PATH } from "../../../../../../config/base";
import { from, Observable } from "rxjs";
import { map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { response } from 'express';
import EmptyCarriersResponse = CarrierModel.EmptyCarriersResponse;
import UpdateOriginResponse = CarrierModel.UpdateOriginResponse;
import RemoveCarrierDestiniesResponse = CarrierModel.RemoveCarrierDestiniesResponse;
import postGetPackingsByIdsResponse = CarrierModel.postGetPackingsByIdsResponse;
import packingSealData = CarrierModel.packingSealData;

@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  /**Private urls for Carrier service */
  private carrierUrl: string = environment.apiBase + "/packing";
  private postGetPackingsByIdsUrl: string = environment.apiBase + "/packing/multiple";
  private carrierMeWarehouseUrl: string = environment.apiBase + '/packing/me-warehouses';
  private singleCarrierUrl: string = environment.apiBase + "/packing/{{id}}";
  private singleAvailableCarrierUrl = `${environment.apiBase}/packing/use/`
  private packingFullUrl: string = environment.apiBase + "/packing/full";
  private singleContainerAndProductsUrl: string = environment.apiBase + "/warehouses/containers/warehouse/reference/{{reference}}";
  private singleContainerAndProductsModelUrl: string = environment.apiBase + "/warehouses/containers/warehouse/products/reference/{{reference}}";
  private singleCarrierForPickingUrl: string = environment.apiBase + "/packing/picking/{{id}}";
  private warehouseDestination: string = environment.apiBase + "/packing/warehouse/{{id}}";
  private getReference: string = environment.apiBase + "/packing/{{reference}}";
  private setWarehouseDestination: string = environment.apiBase + "/packing/warehouse";
  private postRemoveCarrierDestiniesUrl: string = environment.apiBase + "/packing/remove-destinies";
  private setWarehouseDestinationMultiple: string = environment.apiBase + "/packing/destiny/warehouses";
  private packingUrl: string = environment.apiBase + "/types/packing";
  private packingProcessUrl: string = environment.apiBase + "/types/process/packing";
  private sendPackingToWarehouse = environment.apiBase + "/packing/destiny/{{id}}/warehouse/{{warehouseId}}";
  private getCarriesEmptyPackingUrl = `${environment.apiBase}/packing/carries-empty-packing`
  private getReceptionsUrl = `${environment.apiBase}/packing/reception`
  private sendPackingUrl = environment.apiBase + "/packing/send";
  private postSealsList = environment.apiBase + "/packing/seal-lista";
  private postSealAll = environment.apiBase + "/packing/seal/all"
  private getGetPackingDestinyUrl = environment.apiBase + '/packing/destiny/';
  private postCheckProductsDestinyUrl = environment.apiBase + '/packing/products/destiny/check';
  private postCheckPackingAvailabilityUrl = environment.apiBase + '/packing/availability/check';
  private postVaciarCalle = environment.apiBase + '/packing/empty';
  private postEmptyCarriersUrl = environment.apiBase + '/packing/empty-bulk';
  private postUpdateOriginUrl = environment.apiBase + '/packing/update-origin';
  private postVaciarCalleSorter = environment.apiSorter + '/sorter/packing/empty';
  private getCarrierHistoryURL = environment.apiBase + '/packing/history';
  private getAllWhsonCarries = environment.apiBase + '/packing/getWhs/getWhsOnCarrier';
  private movementHistory = environment.apiBase + '/packing/warehouse/movementHistory';
  private typeMovement = environment.apiBase + '/types/movement-history';
  private sendexcell = environment.apiBase + "/packing/export-to-excel";
  private searchInContainerUrl = environment.apiBase + "/packing/search";
  private getFiltersUrl = environment.apiBase + "/packing/filters";
  private exportexcell = environment.apiBase + "/packing/references/export-to-excel";

  constructor(
    private http: HttpClient,
    private requestsProvider: RequestsProvider,
    private auth: AuthenticationService
  ) {}

  postGetPackingsByIds(packingIds: number[]): Observable<packingSealData[]> {
    return this.http.post<postGetPackingsByIdsResponse>(this.postGetPackingsByIdsUrl, {packingIds: packingIds}).pipe(map(response => {
      return response.data;
    }));
  }

  /*------------------*/
  getAllWhs(): Observable<Array<CarrierModel.Carrier>> {
    return this.http.get<CarrierModel.CarrierResponse>(this.getAllWhsonCarries).pipe(map(response => {
      return response.data;
    }));
  }

  /**
   * Seach products in the inventory filtereds by params
   * @param parameters filters
   */
  searchInContainer(parameters): Observable<CarrierModel.ResponseSearchInContainer> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      if(parameters && parameters.filters && parameters.filters.lastMovements){
        delete parameters.filters.lastMovements;
      }
      return this.http.post<CarrierModel.ResponseSearchInContainer>(this.searchInContainerUrl, parameters, { headers });
    }));
  }

  getFilters(): Observable<CarrierModel.ResponseSearchInContainer> {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });
      return this.http.post<CarrierModel.ResponseSearchInContainer>(this.getFiltersUrl, { headers });
    }));
  }

  /**
   * @author Gaetano Sabino
   * @param parameters
   */
  getFileExcell(parameters: ExcellModell.fileExcell) {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      // let headers:HttpHeaders = new HttpHeaders({Authorization:token});


      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });

      return this.http.post(this.sendexcell, parameters, { headers, responseType: 'blob' });
    }));
  }

  postMovementsHistory(body: {}): Observable<Array<CarrierModel.Carrier>> {
    return this.http.post<CarrierModel.CarrierResponse>(this.movementHistory, body).pipe(map(response => {
      return response.data;
    }));
  }

  getMovementType(): Observable<Array<CarrierModel.Carrier>> {
    return this.http.get<CarrierModel.CarrierResponse>(this.typeMovement).pipe(map(response => {
      return response.data;
    }));
  }

  getCarrierMeWarehouse(): Observable<Array<CarrierModel.Carrier>> {
    return this.http.get<CarrierModel.CarrierResponse>(this.carrierMeWarehouseUrl).pipe(map(response => {
      return response.data;
    }));
  }

  postUpdateCarrierOriginWarehouse(carrierId: number, warehouseId: number): Observable<UpdateOriginResponse>{
    return this.http.post<UpdateOriginResponse>(this.postUpdateOriginUrl, {carrierId: carrierId, warehouseId: warehouseId});
  }

  postSealList(reference: string[]) {
    let body = { reference };
    return this.http.post(this.postSealsList, body)
  }
  postSeals(sealList) {
    return this.http.post(this.postSealAll, sealList);
  }

  postPackingEmpty(id: number | string, process: string, emptyPackingInReturn: boolean = false) {
    let body = { packingIdOrReference: id, process, emptyPackingInReturn };
    return this.requestsProvider.post(this.postVaciarCalle, body)
  }

  postEmptyCarriers(carrierIds: number[], process: string): Observable<EmptyCarriersResponse> {
    return this.http.post<EmptyCarriersResponse>(this.postEmptyCarriersUrl, {carrierIds: carrierIds, process: process});
  }

  postPackingEmptySorter(id: number | string, process: string, emptyPackingInReturn: boolean) {
    let body = { packingIdOrReference: id, process, emptyPackingInReturn };
    return this.requestsProvider.post(this.postVaciarCalleSorter, body)
  }

  getPackingTypes() {
    return this.http.get(this.packingUrl).pipe(map((response) => {
      return (<any>response).data;
    }))
  }

  getPackingProcessTypes() {
    return this.http.get(this.packingProcessUrl).pipe(map((response) => {
      return (<any>response).data;
    }))
  }

  updateDestination(id, destination) {
    return this.http.put(this.warehouseDestination.replace("{{id}}", String(id)), destination);
  }

  setDestination(carrierId, destination) {
    return this.http.put(this.setWarehouseDestination, { carrierId: carrierId, destinationWarehouseId: destination });
  }

  postRemoveCarrierDestinies(carrierId: number): Observable<RemoveCarrierDestiniesResponse> {
    return this.http.post<RemoveCarrierDestiniesResponse>(this.postRemoveCarrierDestiniesUrl, {carrierId: carrierId});
  }

  setDestinationMultiple(CarrierToDestiny) {
    return this.http.post(this.setWarehouseDestinationMultiple, CarrierToDestiny);
  }
  /**
   * Get a carrier by id
   * @param id - the id of carrier to get
   * @returns a carrier
   */
  getSingle(id: any): Observable<CarrierModel.Carrier> {
    return this.http.get<CarrierModel.SingleCarrierResponse>(this.singleCarrierUrl.replace("{{id}}", String(id))).pipe(map(response => {
      return response.data;
    }));
  }

  getSingleAvailable(reference: string): Observable<CarrierModel.Carrier> {
    return this.http.get<CarrierModel.SingleCarrierResponse>(`${this.singleAvailableCarrierUrl}${reference}`).pipe(map(response => {
      return response.data;
    }));
  }

  getContainerAndProducts(reference: any, warehouse: any): Observable<any> {
    let body = { reference: reference, warehouseId: warehouse };
    return this.http.post<CarrierModel.SingleContainerResponse>(this.singleContainerAndProductsUrl.replace("{{reference}}", String(reference)), body).pipe(map(response => {
      return response.data;
    }));
  }

  getContainerAndProductsModel(reference: any, warehouse: any, model: any): Observable<any> {
    let body = { reference: reference, warehouseId: warehouse, modelId: model };
    return this.http.post<CarrierModel.SingleContainerResponse>(this.singleContainerAndProductsModelUrl.replace("{{reference}}", String(reference)), body).pipe(map(response => {
      return response.data;
    }));
  }

  getSingleForPicking(id: any): Observable<CarrierModel.Carrier> {
    return this.http.get<CarrierModel.SingleCarrierResponse>(this.singleCarrierForPickingUrl.replace("{{id}}", String(id))).pipe(map(response => {
      return response.data;
    }));
  }

  getByReference(reference: string) {
    return this.http.get<CarrierModel.SingleCarrierResponse>(this.getReference.replace("{{reference}}", String(reference))).pipe(map(response => {
      return response.data;
    }));
  }

  /**
   * Delete a carrier by id
   * @param id - the id of the carrier
   */
  delete(id: number): Observable<any> {
    return this.http.delete(this.singleCarrierUrl.replace("{{id}}", String(id)));
  }

  /**
   * Store one carrier
   * @param carrier
   */
  store(carrier): Observable<CarrierModel.Carrier> {
    return this.http.post<CarrierModel.SingleCarrierResponse>(this.carrierUrl, carrier).pipe(map(response => {
      return response.data;
    }));
  }

  /**
   * Update an carrier
   * @param carrier
   */
  update(id, carrier) {
    return this.http.put<CarrierModel.SingleCarrierResponse>(this.singleCarrierUrl.replace("{{id}}", String(id)), carrier).pipe(map(response => {
      return response.data;
    }));
  }

  /**
  * Send Packing to Warehouse
  */
  sendPackingToWareouse(id: number, warehouseId: number): Observable<CarrierModel.CarrierWarehouseDestiny> {
    return this.http.post<CarrierModel.CarrierWarehouseDestinyResponse>(this.sendPackingToWarehouse.replace("{{id}}", String(id)).replace("{{warehouseId}}", String(warehouseId)), {}).pipe(map(response => {
      return response.data;
    }));
  }

  getCarriesEmptyPacking() {
    return this.http.get(this.getCarriesEmptyPackingUrl).pipe(map((resp: any) => resp.data));
  }

  getReceptions(body) {
    return this.http.post(this.getReceptionsUrl, body).pipe(map((resp: any) => resp.data));
  }
  sendPacking(packingReference: string, warehouseId: number) {
    let request = { packingReference: packingReference, warehouseId: warehouseId }
    return this.http.post<CarrierModel.SingleCarrierResponse>(this.sendPackingUrl, request).pipe(map(response => {
      return response.data;
    }));
  }

  getGetPackingDestiny(packingReference: string): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.get(this.getGetPackingDestinyUrl + packingReference);
  }

  postCheckProductsDestiny(params: CarrierModel.ParamsCheckProductsDestiny): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postCheckProductsDestinyUrl, params);
  }

  postCheckPackingAvailability(params: CarrierModel.ParamsCheckPackingAvailability): Promise<HttpRequestModel.Response> {
    return this.requestsProvider.post(this.postCheckPackingAvailabilityUrl, params);
  }

  carrierHistory(ref: string): Observable<CarrierModel.HistoryModal> {
    let body = JSON.parse(JSON.stringify({ ref }));
    return this.http.post<CarrierModel.HistoryModal>(this.getCarrierHistoryURL, { reference: ref })
      .pipe(
        map(elem => elem.data)
      );
  }

  postPackingFull(carrier: CarrierModel.Carrier) {
    return this.http.put<CarrierModel.SingleCarrierResponse>(this.packingFullUrl, carrier).pipe(map(response => {
      return response.data;
    }));
  }

  /**
   * @author Martin Wehren
   * @param parameters
   */
  getFileExcellCarrierByReferences(parameters: {references:string[]}) {
    return from(this.auth.getCurrentToken()).pipe(switchMap(token => {
      // let headers:HttpHeaders = new HttpHeaders({Authorization:token});


      let headers: HttpHeaders = new HttpHeaders({ Authorization: token });

      return this.http.post(this.exportexcell, parameters, { headers, responseType: 'blob' });
    }));
  }
}

import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  MatCheckboxModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
  MatRippleModule,
  MatListModule,
  MatPaginatorModule
} from '@angular/material';
import {RouterModule, Routes} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {BreadcrumbModule} from '../components/breadcrumb/breadcrumb.module';
import {TagsInputModule} from '../components/tags-input/tags-input.module';
import {FilterButtonModule} from "../components/filter-button/filter-button.module";
import {InstalledAppVersionsComponent} from './installed-app-versions.component';
import {PaginatorComponentModule} from '../components/paginator/paginator.component.module';
import {TableFiltersModule} from "../components/table-filters/table-filters.component.module";

const routes: Routes = [
  {
    path: '',
    component: InstalledAppVersionsComponent
  }
];

@NgModule({
  declarations: [InstalledAppVersionsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    BreadcrumbModule,
    RouterModule.forChild(routes),
    TagsInputModule,
    PaginatorComponentModule,
    FilterButtonModule,
    MatTooltipModule,
    MatSortModule,
    MatCheckboxModule,
    MatRippleModule,
    FormsModule,
    TableFiltersModule,
  ],
  entryComponents: [],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class InstalledAppVersionsModule {
}


export namespace PackageCancelledModel {

  export interface ResponseDataPackageCancelledInfo {
    packages: IPackageCancelledInfo[]
  }

  export interface IPackageCancelledInfo {
    deliveryRequestExternalId: string,
    packageId: number,
    containerId: number,
    uniqueCodeTracking: string,
    lines: IPackageCancelledLineInfo[],
    status: PackageInfoStatus
  }

  export interface IPackageCancelledLineInfo {
    deliveryRequestExternalLineId: string,
    deliveryRequestLineId: number,
    modelReference: string,
    sizeNumber: string
  }

  export enum PackageInfoStatus {
    CANCELLED_COMPLETELY = 1,
    RETURNED_COMPLETELY = 2,
    CANCELLED_PARTIALLY = 3,
    RETURNED_PARTIALLY = 4,
  }
}

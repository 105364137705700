import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MarketplacesComponent } from './marketplaces.component';
import { IonicModule } from '@ionic/angular';
import {MatIconModule, MatTooltipModule} from '@angular/material'

//style libraries
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule, MatSelectModule, MatSortModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';

//Components
import { CatalogComponent } from './catalog/catalog.component';
import { CatalogMarketplacesComponent } from './catalog-marketplaces/catalog-marketplaces.component';
import { StorePriorityComponent } from './store-priority/store-priority.component';
import { ReportMarketplacesComponent } from './reports/reports-marketplaces.component';
import { LogisticsOperators } from './logistics-operators/logistics-operators.component';
import { MappingsComponent } from './mappings/mappings.component';
import { RulesComponent } from './rules/rules.component';
import { RulesModule } from './rules/rules.module';
import { SecurityStocksComponent } from './security-stocks/security-stocks.component';
import {CategorizeProductsModule} from "./catalog/modals/categorize-products/categorize-products.module";
import {FormsModule} from "@angular/forms";
import {LogisticsOperatorsModule} from "./logistics-operators/logistics-operators.module";
import {PaginatorComponentModule} from "../components/paginator/paginator.component.module";
import {ParameterizationIvaComponent} from "./parameterization-iva/parameterization-iva.component";
import {ParameterizationIvaModule} from "./parameterization-iva/parameterization-iva.module";
import {MarketRefundComponent} from "./market-refund/market-refund.component";
import { FilterButtonModule } from '../components/filter-button/filter-button.module';

const routes: Routes = [
  {
    path: '',
    component: MarketplacesComponent
  },
  {
    path: 'catalogs-marketplaces',
    component: CatalogMarketplacesComponent,
    data: {
      name: 'Catálogo Marketplaces'
    }
  },
  {
    path: 'reports',
    component: ReportMarketplacesComponent,
    data: {
      name: 'Informes'
    }
  },
  {
    path: 'catalog/:id',
    component: CatalogComponent,
  },
  {
    path: 'mapping/:id',
    component: MappingsComponent,
  },
  {
    path: 'rules/:id',
    component: RulesComponent,
  },
  {
    path: 'refunds/:id',
    component: MarketRefundComponent,
  },
  //-----------
  {
    path: 'store-priority',
    component: StorePriorityComponent,
    data: {
      name: 'Prioridad de Tienda'
    }
  },

  {
    path: 'logistics-operators',
    component: LogisticsOperators,
    data: {
      name: 'Parametrización logística'
    }
  },
  {
    path: 'parameterization-iva',
    component: ParameterizationIvaComponent,
    data: {
      name: 'Destinos e impuestos'
    }
  }
];

@NgModule({
  declarations: [
    MarketplacesComponent,
    CatalogMarketplacesComponent,
    CatalogComponent,
    ReportMarketplacesComponent,
    MappingsComponent,
    RulesComponent,
    SecurityStocksComponent,
    StorePriorityComponent,
    LogisticsOperators,
    MarketRefundComponent,
    ParameterizationIvaComponent,
  ],
  entryComponents: [MarketplacesComponent],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    RouterModule.forChild(routes),
    MatExpansionModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSortModule,
    MatSelectModule,
    RulesModule,
    CategorizeProductsModule,
    LogisticsOperatorsModule,
    MatListModule,
    MatPaginatorModule,
    FormsModule,
    PaginatorComponentModule,
    ParameterizationIvaModule,
    FilterButtonModule,
    MatIconModule,
    MatTooltipModule
  ]
})
export class MarketplacesModule { }

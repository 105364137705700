import { Injectable } from '@angular/core';
import { RequestsProvider } from "../../../providers/requests/requests.provider";
import { environment } from "@suite/services";
import { AvelonIncidencesReception } from 'libs/services/src/models/endpoints/AvelonIncidencesReception';

@Injectable({
    providedIn: 'root'
})
export class AvelonIncidencesReceptionService {

    private solvePath = `${environment.apiBase}/avelon-incidences-reception/solve`
    private filterPath = `${environment.apiBase}/avelon-incidences-reception/filter`
    private filterOptionsPath = `${environment.apiBase}/avelon-incidences-reception/filter-options`
    private markAsSolvedPath = `${environment.apiBase}/avelon-incidences-reception/mark-as-solved`
    private getCount = `${environment.apiBase}/avelon-incidences-reception/count-pending`

    constructor(
        private requestsProvider: RequestsProvider
    ) { }

    solve(params: { incidences: AvelonIncidencesReception[], userId: number }) {
        return this.requestsProvider.post(this.solvePath, params);
    }

    filter(params) {
        return this.requestsProvider.post(this.filterPath, params);
    }

    filterOptions() {
        return this.requestsProvider.get(this.filterOptionsPath);
    }

    markAsSolved(params: { incidences: AvelonIncidencesReception[], userId: number }) {
        return this.requestsProvider.post(this.markAsSolvedPath, params);
    }

    checkForAvelonIncidencesReceptions() {
        return this.requestsProvider.get(this.getCount);
    }
}

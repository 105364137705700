import { IonicModule } from '@ionic/angular';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from '@angular/common';
import { ReceptionsAvelonComponent } from './receptions-avelon.component';
import { ListsComponent } from './components/lists/lists.component';
import { SizesComponent } from './components/sizes/sizes.component';
import { VirtualKeyboardModule } from '../components/virtual-keyboard/virtual-keyboard.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ScreenResultComponent } from './components/screen-result/screen-result.component';
import {
  MatAutocompleteModule, MatButtonModule,
  MatFormFieldModule, MatIconModule,
  MatInputModule,
  MatRippleModule,
  MatSelectModule,
  MatTooltipModule
} from "@angular/material";
import {InfoModalModule} from "./info-modal/info-modal.module";
import {SizeInputModule} from "../components/size-input/size-input.module";
import {FormHeaderReceptionComponent} from "./components/form-header-reception/form-header-reception.component";
import {BarcodeScannerComponent} from "./components/barcode-scanner/barcode-scanner.component";
import {LoadingButtonModule} from "../components/button/loading-button/loading-button.module";
import {InfoHeaderReceptionComponent} from "./components/info-header-reception/info-header-reception.component";
import {SizeSelectModule} from "../components/size-select/size-select.module";
import {ScrollingModule} from "@angular/cdk/scrolling";
import { CookieService } from 'ngx-cookie-service';
import {SelectableListReceptionsComponent} from "./modals/selectable-list-receptions/selectable-list-receptions.component";
import {SingleSelectListModule} from "../components/single-select-list/single-select-list.module";

const routes: Routes = [
  {
    path: '',
    component: ReceptionsAvelonComponent
  },
  {
    path: 'free',
    component: ReceptionsAvelonComponent
  }
];
@NgModule({
  declarations: [ReceptionsAvelonComponent, ListsComponent, SizesComponent, ScreenResultComponent, FormHeaderReceptionComponent, InfoHeaderReceptionComponent, BarcodeScannerComponent, SelectableListReceptionsComponent],
  entryComponents: [ReceptionsAvelonComponent, ListsComponent, SizesComponent, ScreenResultComponent, FormHeaderReceptionComponent, InfoHeaderReceptionComponent, BarcodeScannerComponent, SelectableListReceptionsComponent],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormsModule,
    RouterModule.forChild(routes),
    VirtualKeyboardModule,
    RouterModule.forChild(routes),
    MatFormFieldModule,
    InfoModalModule,
    MatAutocompleteModule,
    SingleSelectListModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    SizeInputModule,
    MatTooltipModule,
    LoadingButtonModule,
    MatRippleModule,
    SizeSelectModule,
    MatIconModule,
    MatButtonModule,
    ScrollingModule
  ],
  providers: [ CookieService ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReceptionsAvelonModule { }

import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ModalController} from "@ionic/angular";
import {MarketplacesMgaService} from "../../../../../services/src/lib/endpoint/marketplaces-mga/marketplaces-mga.service";
import { IntermediaryService } from '../../../../../services/src/lib/endpoint/intermediary/intermediary.service';

@Component({
  selector: 'suite-create-parameterization-iva',
  templateUrl: './create-parameterization-iva.component.html',
  styleUrls: ['./create-parameterization-iva.component.scss']
})
export class CreateParameterizationIvaComponent implements OnInit {
  title = 'Crear regla';
  redirectTo = '/create-parameterization-iva';
  id;
  selectedCountry;
  countriesListSelect = [];
  isoCode;
  iva;
  nameShippingCost;
  activateButton;

  updateForm: FormGroup = this.formBuilder.group({
    country: ['', Validators.required],
    isoCode: [''],
    iva: ['', [Validators.required, Validators.pattern('^[0-9]+([.][0-9]+)?$')]],
    nameShippingCost: ['']
  });

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private marketplacesMgaService: MarketplacesMgaService,
    private intermediaryService: IntermediaryService,
  ) {}

  async ngOnInit() {
    await this.marketplacesMgaService.getCountriesWithoutVatRule().subscribe(countries => {
      this.countriesListSelect = countries;
    });
  }

  createFixedValues(){
    this.isoCode = this.selectedCountry.isoCode;
    this.nameShippingCost = 'ENVIO_' + this.selectedCountry.isoCode;
  }

  async createRules(){
    let dataBody = {
      name: this.selectedCountry.name,
      code: this.selectedCountry.isoCode,
      percentage: this.iva,
      deleted: false,
      default: false
    };

    await this.intermediaryService.presentConfirm('Por favor, recuerda que previamente a crear esta regla, debes tener creado y mapeado al país correspondiente en Avelon el producto "ENVIO_' + this.selectedCountry.isoCode + '" el cual se asociará a los gastos de envío de ' + this.selectedCountry.name + '.', () => {
      this.marketplacesMgaService.createParameterizationIva(dataBody).subscribe(data => {
        this.intermediaryService.presentToastSuccess("Regla guardada con éxito");
        this.modalController.dismiss(null);
      }, error => {
        this.intermediaryService.presentToastError("Error al guardar la regla");
        this.modalController.dismiss(null);
      });
    });
  }

  close():void{
    this.modalController.dismiss();
  }
}

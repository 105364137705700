import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
  MatTableModule,
  MatCheckboxModule,
  MatGridListModule,
  MatRadioModule,
  MatTooltipModule
} from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import {TitlePickingComponent} from "./title/title.component";
import {PickingComponent} from "./picking/picking.component";
import {ListPickingRebuildComponent} from "./list-picking-rebuild.component";
import {ListProductsComponent} from "./modal-products/modal-products.component";
import {ListDestiniesComponent} from "./modal-destinies/modal-destinies.component";
import { MatPaginatorModule } from "@angular/material";
import {FilterButtonModule} from "../components/filter-button/filter-button.module";
import {PaginatorComponentModule} from "../components/paginator/paginator.component.module";
@NgModule({
  declarations: [ListPickingRebuildComponent, TitlePickingComponent, PickingComponent, ListProductsComponent, ListDestiniesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    MatTableModule,
    MatCheckboxModule,
    CdkTableModule,
    MatGridListModule,
    FormsModule,
    MatRadioModule,
    MatTooltipModule,
    MatPaginatorModule,
    FilterButtonModule,
    PaginatorComponentModule
  ],
  entryComponents: [ListPickingRebuildComponent, TitlePickingComponent, PickingComponent, ListProductsComponent, ListDestiniesComponent]
})
export class ListPickingRebuildModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatListModule } from '@angular/material';
import { RouterModule, Routes } from "@angular/router";
import { BreadcrumbModule } from '../components/breadcrumb/breadcrumb.module';
import { TagsInputModule } from '../components/tags-input/tags-input.module';
import { PaginatorComponentModule } from '../components/paginator/paginator.component.module';
import { FilterButtonModule } from "../components/filter-button/filter-button.module";
import { MatTooltipModule } from "@angular/material";
import { ExpeditionCancelledComponent } from './expedition-cancelled.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PackageCancelledModule } from './package-cancelled/package-cancelled.module';
import { MatCheckboxModule, MatPaginatorModule, MatRippleModule, MatSortModule, MatTableModule } from '@angular/material';
import { NgxFileDropModule } from  'ngx-file-drop' ;
import { PackageCancelledComponent } from './package-cancelled/package-cancelled.component';
import { PackagesComponent } from "../expedition-cancelled/packages/packages.component";

const routes: Routes = [
  {
    path: '',
    component: ExpeditionCancelledComponent
  }
];

@NgModule({
  entryComponents:[PackageCancelledComponent, PackagesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    BreadcrumbModule,
    RouterModule.forChild(routes),
    TagsInputModule,
    PaginatorComponentModule,
    FilterButtonModule,
    MatTooltipModule,
    FormsModule,
    MatCheckboxModule,
    MatRippleModule,
    MatSortModule,
    NgxFileDropModule,
    MatTabsModule,
    PackageCancelledModule
  ],
  declarations: [ExpeditionCancelledComponent, PackagesComponent]
})

export class ExpeditionCancelledModule {}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { AlertController, ModalController } from '@ionic/angular';
import { IntermediaryService } from '@suite/services';
import { PaginatorComponent } from '../components/paginator/paginator.component';
import { InScansIncidenceService } from "../../../services/src/lib/endpoint/in-scans-incidence/in-scans-incidence.service";
import { InScansIncidence } from '../../../services/src/models/endpoints/InScansIncience'
import { InScansIncidencesShowJsonComponent } from './modals/in-scans-incidences-show-json/in-scans-incidences-show-json.component';

@Component({
  selector: 'suite-in-scans-incidences',
  templateUrl: './in-scans-incidences.component.html',
  styleUrls: ['./in-scans-incidences.component.scss']
})
export class InScansIncidencesComponent implements OnInit, AfterViewInit {

  public static alert: boolean = false;

  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;

  dataSource: MatTableDataSource<InScansIncidence> = null;
  displayedColumns: string[] = ['select', 'receiptDateTime', 'receiptShopId', 'itemId', 'sizeIndex', 'userId', 'scannedBarcode', 'error', 'retry', 'solve'];
  incidencesSelected: InScansIncidence[] = [];
  isAllSelected = false

  inScansIncidences: InScansIncidence[];
  filters = {
    receiptDateTimes: [],
    receiptShopIds: [],
    itemIds: [],
    sizeIndexes: [],
    userIds: [],
    scannedBarcodes: []
  };
  filterOptions = {
    receiptDateTimes: [],
    receiptShopIds: [],
    itemIds: [],
    sizeIndexes: [],
    userIds: [],
    scannedBarcodes: []
  };
  order = {
    field: 'receiptDateTime',
    direction: 'DESC'
  };
  pagination = {
    limit: undefined,
    page: 1
  };
  currentUserId: number

  constructor(
    private inScansIncidenceService: InScansIncidenceService,
    private modalController: ModalController,
    private intermediaryService: IntermediaryService,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    const sessionUser = JSON.parse(localStorage.getItem("user"));
    this.currentUserId = sessionUser.id;

    this.loadFilters();
    this.paginator.page.subscribe(paginator => {
      if (this.pagination.limit !== paginator.pageSize) {
        this.pagination.page = 1;
      } else {
        this.pagination.page = paginator.pageIndex;
      }
      this.pagination.limit = paginator.pageSize;
      this.loadInScansIncidences();
    });
  }

  ngAfterViewInit() {
    this.pagination.limit = this.paginator.finalPagerValues[0];
    this.loadInScansIncidences();
  }

  async loadFilters() {
    try {
      const response = await this.inScansIncidenceService.filterOptions()
      if (response.code === 200) {
        this.filterOptions = {
          receiptDateTimes: response.data.receiptDateTimes.map(data => {
            return {
              id: data,
              value: new Date(data).toLocaleString(),
              checked: false,
              hide: false
            }
          }),
          receiptShopIds: response.data.receiptShopIds.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
          itemIds: response.data.itemIds.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
          sizeIndexes: response.data.sizeIndexes.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
          userIds: response.data.userIds.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          }),
          scannedBarcodes: response.data.scannedBarcodes.map(data => {
            return {
              id: data,
              value: data,
              checked: false,
              hide: false
            }
          })
        }
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error)
    }
  }

  async loadInScansIncidences() {
    const parameters = {
      filters: this.filters,
      order: this.order,
      pagination: this.pagination
    };
    try {
      const response = await this.inScansIncidenceService.filter(parameters)
      if (response.code === 200) {
        console.log(response.data.result)
        this.dataSource = new MatTableDataSource<InScansIncidence>(response.data.result);
        this.inScansIncidences = response.data.result;
        const paginator = response.data.pagination;
        this.paginator.cantSelect = paginator.limit;
        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error
    }
  }

  orderBy(column: string) {
    if (this.order.field === column) {
      this.order.direction === 'ASC' ? this.order.direction = 'DESC' : this.order.direction = 'ASC';
    } else {
      this.order.field = column;
    }
    this.loadInScansIncidences();
  }

  applyFilters(event, column: string) {
    const values = [];
    for (let item of event.filters) {
      if (item.checked) {
        values.push(item.id);
      }
    }
    this.filters[column] = values.length < this.filterOptions[column].length ? values : [];
    this.pagination.page = 1;
    this.incidencesSelected = []
    this.isAllSelected = false
    this.loadInScansIncidences();
  }

  reset() {
    this.filters = {
      receiptDateTimes: [],
      receiptShopIds: [],
      itemIds: [],
      sizeIndexes: [],
      userIds: [],
      scannedBarcodes: []
    };
    this.pagination = {
      limit: this.paginator.finalPagerValues[0],
      page: 1
    };
    this.paginator.cantSelect = this.paginator.finalPagerValues[0];
    this.paginator.pageIndex = 1;
    this.loadFilters();
    this.loadInScansIncidences();
    this.isAllSelected = false
    this.incidencesSelected = []
  }

  async markAsSolvedConfirm(incidence: InScansIncidence) {
    const alert = await this.alertController.create({
      header: 'Resolver manualmente',
      message: `<span>Se marcará como resuelta la incidencia del código <b>${incidence.scannedBarcode}</b>, almacén <b>${incidence.receiptShopId}</b> y modelo <b>${incidence.itemId}</b>.<br/><br/>¿Está seguro de querer continuar?</span>`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Sí',
          handler: () => {
            this.markAsSolved([incidence]);
          }
        }
      ]
    });
    await alert.present();
  }

  async markAsSolved(incidences: InScansIncidence[]) {
    let params = {
      incidences,
      userId: this.currentUserId
    };
    await this.intermediaryService.presentLoadingNew("Marcando incidencia como resuelta")
    try {
      const response = await this.inScansIncidenceService.markAsSolved(params)
      console.log(response)
      if (response.code === 200) {
        await this.intermediaryService.presentToastSuccess('Incidencia marcada como resuelta.');
        this.reset();
      } else {
        console.error(response);
        await this.intermediaryService.presentToastError('Ha ocurrido un error. La incidencia no se ha podido marcar como resuelta.');
      }
    } catch (error) {
      console.error(error);
      await this.intermediaryService.presentToastError('Ha ocurrido un error. La incidencia no se ha podido marcar como resuelta.');
    } finally {
      await this.intermediaryService.dismissLoadingNew()
    }
  }

  async showError(incidence: InScansIncidence) {
    const modal = await this.modalController.create({
      component: InScansIncidencesShowJsonComponent,
      componentProps: {
        incidence
      }
    });
    await modal.present();
  }

  async solve(incidences: InScansIncidence[]) {
    let params = {
      incidences,
      userId: this.currentUserId
    };
    await this.intermediaryService.presentLoadingNew("Resolviendo incidencia")
    try {
      const response = await this.inScansIncidenceService.solve(params)
      console.log(response)
      if (response.code === 200) {
        await this.intermediaryService.presentToastSuccess('Incidencia marcada como resuelta.');
        this.reset();
      } else {
        console.error(response);
        await this.intermediaryService.presentToastError('Ha ocurrido un error. La incidencia no se ha podido resolver.');
      }
    } catch (error) {
      console.error(error);
      await this.intermediaryService.presentToastError('Ha ocurrido un error. La incidencia no se ha podido resolver.');
    } finally {
      await this.intermediaryService.dismissLoadingNew()
      await this.loadInScansIncidences()
    }
  }

  selectIncidence(incidence: InScansIncidence) {
    const exist = this.incidencesSelected.some(incidenceSelected => incidenceSelected.id === incidence.id)
    if (!exist) {
      this.incidencesSelected.push(incidence)
    } else {
      const index = this.incidencesSelected.findIndex(incidenceSelected => incidenceSelected.id === incidence.id)
      this.incidencesSelected.splice(index, 1)
    }
    if (this.inScansIncidences.length === this.incidencesSelected.length) {
      this.isAllSelected = true
    } else {
      this.isAllSelected = false
    }
  }

  isSelected(incidence: InScansIncidence) {
    return this.incidencesSelected.some(incidenceSelected => incidenceSelected.id === incidence.id)
  }

  selectAll() {
    if (this.isAllSelected) {
      this.incidencesSelected = []
    } else {
      this.incidencesSelected = [].concat(this.inScansIncidences)
    }
    this.isAllSelected = !this.isAllSelected
  }

  async markAsSolveAllConfirm() {
    const alert = await this.alertController.create({
      header: 'Resolver manualmente',
      message: `<span>Se marcarán como resueltas las incidencias seleccionadas ¿Está seguro de querer continuar?</span>`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Sí',
          handler: () => {
            this.markAsSolved(this.incidencesSelected);
          }
        }
      ]
    });
    await alert.present();
  }

  async solveConfirm(incidences: InScansIncidence[]) {
    const alert = await this.alertController.create({
      header: 'Reenviar petición',
      message: 
        incidences.length > 1 ? 
        `<span>Se intentarán resolver las incidencias seleccionadas ¿Está seguro de querer continuar?</span>` 
        : 
        `<span>Se intentará resolver la incidencia <b>${incidences[0].scannedBarcode}</b>, almacén <b>${incidences[0].receiptShopId}</b> y modelo <b>${incidences[0].itemId}</b>.<br/><br/>¿Está seguro de querer continuar?</span>`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Sí',
          handler: () => {
            this.solve(incidences);
          }
        }
      ]
    });
    await alert.present();
  }
}

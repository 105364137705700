import {Component, OnInit, ViewChild} from '@angular/core';
import {IntermediaryService} from '../../../services/src/lib/endpoint/intermediary/intermediary.service';
import {OplTransportsService} from '../../../services/src/lib/endpoint/opl-transports/opl-transports.service';
import {MatTabsModule, MatTabChangeEvent} from '@angular/material/tabs';
import {OrderPackageComponent} from './order-package/order-package.component';
import {ExpeditionsProcessedModel} from "../../../services/src/models/endpoints/Expeditions/Processed";

@Component({
  selector: 'suite-transports-orders',
  templateUrl: './transports-orders.component.html',
  styleUrls: ['./transports-orders.component.scss']
})
export class TransportsOrdersComponent implements OnInit {

  @ViewChild('#tabGroup') private tabGroup: MatTabsModule;
  @ViewChild(OrderPackageComponent) orders: OrderPackageComponent;

  selectedIndex = 0;
  transports: ExpeditionsProcessedModel.TransportsWithManifests[] = [];
  indexTab: number = 0;

  constructor(
    private intermediaryService: IntermediaryService,
    private oplTransportsService: OplTransportsService
  ) {}

  ngOnInit() {
    this.getTransport()
  }

  getTransport() {
    this.oplTransportsService.getOperatorsWithManifests().subscribe(res => {
      this.transports = res
    })
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.indexTab = tabChangeEvent.index;
  }

  refresh() {
    const transportId = this.transports[this.indexTab].id;
    this.orders.refresh(transportId);
  }
}

import { Subscription } from 'rxjs';
import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  SimpleChange,
  OnChanges
} from '@angular/core';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { ModalController, NavController } from '@ionic/angular';
import { IntermediaryService, OplTransportsService } from '../../../../services/src';
import { PaginatorComponent } from '../../components/paginator/paginator.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterButtonComponent } from '../../components/filter-button/filter-button.component';
import { TagsInputOption } from '../../components/tags-input/models/tags-input-option.model';
import { DefectiveRegistryService } from '../../../../services/src/lib/endpoint/defective-registry/defective-registry.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { FiltersModel } from '../../../../services/src/models/endpoints/filters';
import { ExpeditionCollectedService } from '../../../../services/src/lib/endpoint/expedition-collected/expedition-collected.service';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from "file-saver";
import {DateTimeParserService} from "../../../../services/src/lib/date-time-parser/date-time-parser.service";
import {PackagesComponent} from "../../expedition-collected/packages/packages.component";
import ResponseDataPackageCancelledInfo = PackageCancelledModel.ResponseDataPackageCancelledInfo;
import {PackagesWarningCancelledComponent} from "../packages-warning-cancelled/packages-warning-cancelled.component";
import {PackageCancelledModel} from "../../../../services/src/models/endpoints/Expeditions/PackageCancelled";
import IPackageCancelledInfo = PackageCancelledModel.IPackageCancelledInfo;

@Component({
  selector: 'suite-package-collected',
  templateUrl: './package-collected.component.html',
  styleUrls: ['./package-collected.component.scss']
})
export class PackageCollectedComponent implements OnChanges {

  constructor(
    private defectiveRegistryService: DefectiveRegistryService,
    private formBuilder: FormBuilder,
    private intermediaryService: IntermediaryService,
    private modalController: ModalController,
    private intermediary: IntermediaryService,
    private expeditionCollectedService: ExpeditionCollectedService,
    private navCtrl: NavController,
    private activateRoute: ActivatedRoute,
    private oplTransportsService: OplTransportsService,
    private dateTimeParserService: DateTimeParserService
  ) {}

  @Input() id: any;
  @Input() sendEvent:boolean;
  @Output()buttonState = new EventEmitter<boolean>();
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filterButtonUniqueCode') filterButtonUniqueCode: FilterButtonComponent;
  @ViewChild('filterButtonContainers') filterButtonContainers: FilterButtonComponent;
  @ViewChild('filterButtonShops') filterButtonShops:FilterButtonComponent;
  @ViewChild('filterButtonOrders') filterButtonOrders:FilterButtonComponent;
  @ViewChild('filterButtonDates') filterButtonDates:FilterButtonComponent;

  /**Filters */
  uniquecodes: Array<TagsInputOption> = [];
  containers: Array<TagsInputOption> =[];
  shops: Array<TagsInputOption> = [];
  orders: Array<TagsInputOption> = [];
  dates: Array<TagsInputOption> = [];

  displayedColumns: string[] = ['select', 'orders', 'uniquecodes', 'shops', 'containers', 'dates', 'products'];
  dataSource;
  button:boolean = false;
  subscriptionRefresh: Subscription;
  buttonSendEmiter: Subscription;
  selection = new SelectionModel<any>(true, []);
  columns=['uniquecodes','shops', 'containers','orders','dates'];


  toDelete: FormGroup = this.formBuilder.group({
    jails: this.formBuilder.array([])
  });

  isFilteringUniqueCode: number = 0;
  isFilteringContainers: number = 0;
  isFilteringShops: number = 0;
  isFilteringOrders: number = 0;
  isFilteringDates: number = 0;



  entities;
  pauseListenFormChange: boolean;
  lastUsedFilter: string;
  pagerValues = [50, 100, 200];

  form: FormGroup = this.formBuilder.group({
    id: [[]],
    uniquecodes:[[]],
    containers:[[]],
    shops:[[]],
    orders:[[]],
    dates:[[]],
    idTransport: new FormControl(''),
    pagination: this.formBuilder.group({
      page: 1,
      limit: this.pagerValues[0]
    }),
    orderby: this.formBuilder.group({
      type: 5,
      order: "desc"
    })
  });
  length: any;

  async ngOnInit() {
    this.initEntity();
    this.initForm();
    this.getFilters(this.id);
    this.form.value.idTransport = this.id;
    await this.getList(this.form);
    this.initEventsEmmiter();
    await this.listenChanges();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    if (this.stateUpdate() == true) {
      this.update();
    }
  }

  initEventsEmmiter(){
    this.getRefreshStatus();
  }

  public async getRefreshStatus() {
    this.subscriptionRefresh = await this.expeditionCollectedService.getData().subscribe(async (id: any) => {
      if (this.id == id) {
        this.id = id;
        this.form.get('idTransport').patchValue(this.id);
        await this.refresh();
        this.getFilters(this.id);
      }
    }, (error) => {
      console.log(error);
    });
  }

  initEntity() {
    this.entities = {
      id: [],
      uniquecodes:[],
      containers:[],
      shops:[],
      orders:[],
      dates:[],
    }
  }

  initForm() {
    this.form.patchValue({
      id: [],
      uniquecodes:[],
      containers:[],
      shops:[],
      orders:[],
      dates:[],
    })
  }

  async listenChanges() {
    console.log("TEST::listenChanges");
    let previousPageSize = this.form.value.pagination.limit;
    /**detect changes in the paginator */
    this.paginator.page.subscribe(async page => {
      /**true if only change the number of results */
      let flag = previousPageSize === page.pageSize;
      previousPageSize = page.pageSize;
      this.form.value.pagination = {
        limit: page.pageSize,
        page: flag ? page.pageIndex : 1
      };
      await this.getList(this.form)
    });
  }

  getFilters(id) {
    this.expeditionCollectedService.getFiltersPackage(id, false).subscribe((entities) => {
      this.uniquecodes = this.updateFilterSource(entities.uniquecodes, 'uniquecodes');
      this.containers = this.updateFilterExpedition(entities.containers,'containers');
      this.shops = this.updateFilterSource(entities.shops,'shops');
      this.orders = this.updateFilterSource(entities.orders,'orders');
      this.dates = this.updateFilterSource(entities.dates,'dates');
      this.reduceFilters(entities);

      setTimeout(() => {
        this.pauseListenFormChange = false;
        this.pauseListenFormChange = true;
      }, 0);
    },(error)=>{
      console.log(error);
    })
  }

  private updateFilterSource(dataEntity: FiltersModel.Default[], entityName: string) {
    let resultEntity;

    this.pauseListenFormChange = true;
    let dataValue = this.form.get(entityName).value;

    resultEntity = dataEntity ? dataEntity.map(entity => {
      entity.id = <number>(<unknown>entity.id);
      entity.name = entity.name;
      entity.value = entity.name;
      entity.checked = true;
      entity.hide = false;
      return entity;
    }) : [];

    if (dataValue && dataValue.length) {
      this.form.get(entityName).patchValue(dataValue, { emitEvent: false });
    }

    setTimeout(() => { this.pauseListenFormChange = false; }, 0);

    return resultEntity;
  }

  private updateFilterExpedition(dataEntity: FiltersModel.Default[], entityName: string) {
    let resultEntity;

    this.pauseListenFormChange = true;
    let dataValue = this.form.get(entityName).value;

    resultEntity = dataEntity ? dataEntity.map(entity => {
      entity.id = <number>(<unknown>entity.id);
      entity.name = entity.id+"";
      entity.value = entity.id+"";
      entity.checked = true;
      entity.hide = false;
      return entity;
    }) : [];

    if (dataValue && dataValue.length) {
      this.form.get(entityName).patchValue(dataValue, { emitEvent: false });
    }

    setTimeout(() => { this.pauseListenFormChange = false; }, 0);
    return resultEntity;
  }

  private reduceFilters(entities) {
    this.filterButtonUniqueCode.listItems = this.reduceFilterEntities(this.uniquecodes, entities, 'uniquecodes');
    this.filterButtonContainers.listItems = this.reduceFilterEntities(this.containers, entities, 'containers');
    this.filterButtonShops.listItems = this.reduceFilterEntities(this.shops, entities, 'shops');
    this.filterButtonOrders.listItems = this.reduceFilterEntities(this.orders, entities, 'orders');
    this.filterButtonDates.listItems = this.reduceFilterEntities(this.dates, entities, 'dates');
  }

  private reduceFilterEntities(arrayEntity: any[], entities: any, entityName: string) {
    if (this.lastUsedFilter !== entityName) {
      let filteredEntity = entities[entityName] as unknown as string[];

      arrayEntity.forEach((item) => {
        item.hide = filteredEntity.includes(item.value);
      });

      return arrayEntity;
    }
  }

  async sortData($event: Sort) {
    if($event.active == "orders"){
      this.form.value.orderby.type = 1;
    }else if($event.active == "uniquecodes"){
      this.form.value.orderby.type = 2;
    }else if($event.active == "shops"){
      this.form.value.orderby.type = 3;
    }else if($event.active == "containers"){
      this.form.value.orderby.type = 4;
    }else if($event.active == "dates"){
      this.form.value.orderby.type = 5;
    }
    this.form.value.orderby.order = $event.direction !== '' ? $event.direction : 'asc';

    await this.getList(this.form);
  }

  async getList(form) {
    await this.intermediaryService.presentLoadingNew("Cargando paquetes recogidos..");
    await this.expeditionCollectedService.getPackages(form.value).subscribe(async (resp: any) => {
      await this.intermediaryService.dismissLoadingNew();
      if (resp.results) {
        this.dataSource = new MatTableDataSource<any>(resp.results);
        const paginator = resp.pagination;

        this.paginator.length = paginator.totalResults;
        this.paginator.pageIndex = paginator.selectPage;
        this.paginator.lastPage = paginator.lastPage;
      }
    },
      async err => {
        await this.intermediaryService.dismissLoadingNew()
      },
      async () => {
        await this.intermediaryService.dismissLoadingNew()
      })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    this.stateUpdate();
  }

  public actionToggle (row) {
    this.selection.toggle(row);
    this.stateUpdate();
  }

  async applyFilters(filtersResult, filterType) {
    const filters = filtersResult.filters;
    switch (filterType) {
      case 'uniquecodes':
        let uniquecodeFiltered: string[] = [];
        for (let uniquecodes of filters) {

          if (uniquecodes.checked) uniquecodeFiltered.push(uniquecodes.id);
        }
        if (uniquecodeFiltered.length >= this.uniquecodes.length) {
          this.form.value.uniquecodes = [];
          this.isFilteringUniqueCode = this.uniquecodes.length;
        } else {
          if (uniquecodeFiltered.length > 0) {
            this.form.value.uniquecodes = uniquecodeFiltered;
            this.isFilteringUniqueCode = uniquecodeFiltered.length;
          } else {
            this.form.value.uniquecodes = ["99999"];
            this.isFilteringUniqueCode = this.uniquecodes.length;
          }
        }
        break;
      case 'containers':
          let containersFiltered: string[] = [];
          for (let containers of filters) {
            if (containers.checked) containersFiltered.push(containers.id);
          }

          if (containersFiltered.length >= this.containers.length) {
            this.form.value.containers = [];
            this.isFilteringContainers = this.containers.length;
          } else {
            if (containersFiltered.length > 0) {
              this.form.value.containers = containersFiltered;
              this.isFilteringContainers = containersFiltered.length;
            } else {
              this.form.value.containers = ["99999"];
              this.isFilteringContainers = this.containers.length;
            }
          }
          break;
      case 'shops':
          let shopsFiltered: string[] = [];
          for (let shops of filters) {

            if (shops.checked) shopsFiltered.push(shops.id);
          }

          if (shopsFiltered.length >= this.shops.length) {
            this.form.value.shops = [];
            this.isFilteringShops = this.shops.length;
          } else {
            if (shopsFiltered.length > 0) {
              this.form.value.shops = shopsFiltered;
              this.isFilteringShops = shopsFiltered.length;
            } else {
              this.form.value.shops = ["99999"];
              this.isFilteringShops = this.shops.length;
            }
          }
          break;
      case 'orders':
        let ordersFiltered: string[] = [];
        for (let orders of filters) {
          if (orders.checked) ordersFiltered.push(orders.id);
        }

        if (ordersFiltered.length >= this.orders.length) {
          this.form.value.orders = [];
          this.isFilteringOrders = this.orders.length;
        } else {
          if (ordersFiltered.length > 0) {
            this.form.value.orders = ordersFiltered;
            this.isFilteringOrders = ordersFiltered.length;
          } else {
            this.form.value.orders = ["99999"];
            this.isFilteringOrders = this.orders.length;
          }
        }
        break;
      case 'dates':
        let datesFiltered: string[] = [];
        for (let dates of filters) {
          if (dates.checked) datesFiltered.push(dates.id);
        }

        if (datesFiltered.length >= this.dates.length) {
          this.form.value.dates = [];
          this.isFilteringDates = this.dates.length;
        } else {
          if (datesFiltered.length > 0) {
            this.form.value.dates = datesFiltered;
            this.isFilteringDates = datesFiltered.length;
          } else {
            this.form.value.dates = ["99999"];
            this.isFilteringDates = this.dates.length;
          }
        }
        break;
    }

    this.lastUsedFilter = filterType;
    this.form.value.pagination.page = 1;
    await this.getList(this.form);
  }

  async update() {
    await this.intermediaryService.presentLoadingNew();

    const expeditionPackageIds: number[] = this.selection.selected.map(s => s.package.id);
    const dataPackages: any = this.selection.selected.map(obj => {
      return {
        warehouse: obj.warehouse.id,
        expedition: obj.expedition,
        transport: obj.transport.id,
        package: obj.package.id
      };
    });
    this.expeditionCollectedService.postCheckOrderNotCancelled({expeditionPackageIds: expeditionPackageIds})
      .then(async response => {
        let responseData: ResponseDataPackageCancelledInfo;
        if(response && response.data) responseData = response.data;
        if (response.code == 200 && responseData && responseData.packages.length > 0 ) {
          await this.intermediaryService.dismissLoadingNew();
          await this.presentModalWarningCancelledOrReturned(responseData.packages, async () => {
            await this.downloadManifest(dataPackages);
          }, async () => {
            await this.refresh()
          });
        } else {
          await this.downloadManifest(dataPackages);
        }
      }, async (error) => {
        console.error(error);
        await this.intermediaryService.dismissLoadingNew();
      })
      .catch(async (error) => {
        console.error(error);
        await this.intermediaryService.dismissLoadingNew();
      });
  }

  async refresh() {
    this.selection.clear();
    await this.getList(this.form);
  }

  stateUpdate() {
    const throwEvent = this.selection.selected.length > 0;
    this.buttonState.emit(throwEvent);
    return throwEvent;
  }

  ngOnDestroy(){
    this.subscriptionRefresh.unsubscribe();
  }

  preparationDateParsed(element) : string {
    return this.dateTimeParserService.dateTime(element.trackingPackage.createdAt);
  }

  async presentModal(packages) {
    const modal = await this.modalController.create({
      component: PackagesComponent,
      componentProps: { packages }
    });

    await modal.present();
  }

  async presentModalWarningCancelledOrReturned(packages: IPackageCancelledInfo[], callbackOk: () => void, callbackCancel: () => void) {
    const modal = await this.modalController.create({
      component: PackagesWarningCancelledComponent,
      componentProps: {packages, callbackOk, callbackCancel}
    });
    await modal.present();
  }

  async downloadManifest(dataPackages){
    await this.intermediaryService.presentLoadingNew();
    await this.expeditionCollectedService.updatePackage(dataPackages).subscribe(async data => {
        this.selection.clear();
        await this.intermediaryService.dismissLoadingNew();
        await this.oplTransportsService.downloadPdfTransortOrders(data.order.id).subscribe(async resp => {
            const blob = new Blob([resp], {type: 'application/pdf'});
            saveAs(blob, 'documento.pdf')
          },
          async e => {
            console.log(e.error.text);
            await this.intermediaryService.dismissLoadingNew();
          },
          async () => await this.intermediaryService.dismissLoadingNew());
        await this.intermediaryService.presentToastSuccess('Se han marcado como enviados los paquetes seleccionados.');
      }, async error => {
        let errorMessage = 'Ha ocurrido un error al intentar marcar como enviados los paquetes seleccionados.';
        if (error && error.error && error.error.errors) {
          errorMessage = error.error.errors;
        }

        this.selection.clear();
        await this.intermediaryService.dismissLoadingNew();
        await this.intermediaryService.presentToastError(errorMessage);
      },
      async () => {
        await this.intermediaryService.dismissLoadingNew();
        await this.refresh();
      });
  }
}

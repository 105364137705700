import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {environment, IntermediaryService, LabelsService, OplTransportsModel} from "@suite/services";
import {LabelModel} from "../../../../services/src/models/endpoints/Label";
import {PrinterServiceModel} from "../../../../services/src/models/endpoints/PrinterService";
import {PositionsToast} from "../../../../services/src/models/positionsToast.type";
import {TimesToastType} from "../../../../services/src/models/timesToastType";
import {PrinterServiceService} from "../../../../services/src/lib/endpoint/printer-service/printer-service.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {validators} from "../../utils/validators";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Downloader, DownloadRequest, NotificationVisibility} from "@ionic-native/downloader/ngx";
import {config} from "../../../../services/src/config/config";
import {DownloaderService} from "../../../../services/src/lib/downloader/downloader.service";

@Component({
  selector: 'suite-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateY(0)' })),
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.17s ease-in-out')
      ]),
      transition(':leave', [
        animate('0.17s ease-in-out', style({ transform: 'translateY(100%)' }))
      ])
    ])
  ]
})
export class PackagesComponent implements OnInit {

  packages: OplTransportsModel.PackagesOrdersWithManifests[];
  selectedForm: FormGroup = this.formBuilder.group({
    selector: false
  }, {
    validators: validators.haveItems("toSelect")
  });

  constructor(
    private labelsService: LabelsService,
    private intermediaryService: IntermediaryService,
    private printerServiceService: PrinterServiceService,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private downloaderService: DownloaderService,
    private downloader: Downloader
  ) {}

  ngOnInit() {
    this.selectedForm.removeControl("toSelect");
    this.selectedForm.addControl("toSelect", this.formBuilder.array(this.packages.map(i => new FormControl(false))));
  }

  dismiss() {
    this.modalController.dismiss()
  }

  async reprint() {
    this.intermediaryService.presentLoadingNew('Imprimiendo expediciones....')
    const expeditionsToRePrint = this.getExpeditionsToRePrint();
    for (const itemToPrint of expeditionsToRePrint) {
      this.labelsService.postServicePrintPack(itemToPrint).subscribe(async (result: LabelModel.IExpeditionDocToPrint[]) =>{
        this.intermediaryService.dismissLoadingNew();
        if(result && result.length > 0){
          let isMobileApp = typeof (<any>window).cordova !== "undefined";
          if (isMobileApp == true) {
            await this.printerServiceService.getPrinterServiceAddress();
            for (const iExpeditionDoc in result) {
              const expeditionDoc = result[iExpeditionDoc];
              if (expeditionDoc.extension == 'pdf') {
                const bodyPrintBase64: PrinterServiceModel.ParamsPrintBase64 = {
                  documentBase64: expeditionDoc.document,
                  documentType: expeditionDoc.type
                };
                this.printerServiceService.postPrintBase64(bodyPrintBase64).subscribe(res => {
                  console.debug('PrinterService::Item printed correctly: ', typeof res != 'string' ? JSON.stringify(res) : res);
                }, async error => {
                  console.error('PrinterService::ERROR to print item: ', JSON.stringify(error));
                  await this.intermediaryService.presentToastError("Ha ocurrido un error al intentar conectar con el servicio de impresión. Compruebe que la dirección configurada en 'Configuración > Servicio de impresión' es la correcta.", PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST_LONG);
                });
              } else {
                const bodyPrint: PrinterServiceModel.ParamsPrint = {
                  document: expeditionDoc.document,
                  documentType: expeditionDoc.type
                };
                this.printerServiceService.postPrint(bodyPrint).subscribe(res => {
                  console.debug('PrinterService::Item printed correctly: ', typeof res != 'string' ? JSON.stringify(res) : res);
                }, async error => {
                  console.error('PrinterService::ERROR to print item: ', JSON.stringify(error));
                  await this.intermediaryService.presentToastError("Ha ocurrido un error al intentar conectar con el servicio de impresión. Compruebe que la dirección configurada en 'Configuración > Servicio de impresión' es la correcta.", PositionsToast.TOP, TimesToastType.DURATION_ERROR_TOAST_LONG);
                });
              }
              const urlDownload = environment.downloadPdf + '/' + expeditionDoc.externalPath;
              const fileName = this.downloaderService.getExpeditionDownloadedFileName(expeditionDoc.extension, expeditionDoc.expeditionDeliveryRequest);
              this.downloadUrl(urlDownload, fileName, parseInt(iExpeditionDoc) === (result.length - 1));
            }
          }
        } else {
          this.intermediaryService.dismissLoadingNew();
          await this.intermediaryService.presentToastError("No se pudo descargar el archivo");
        }
        this.selectedForm.reset();
      }, async (error) => {
        console.log(error);
        this.intermediaryService.dismissLoadingNew();
        await this.intermediaryService.presentToastError("No se pudo imprimir el archivo");
      });
    }
  }

  private getExpeditionsToRePrint() {
    const itemsToPrint = [];
    for (const iSelection in this.selectedForm.value.toSelect) {
      if (this.selectedForm.value.toSelect[iSelection]) {
        const expeditionPackage = this.packages[iSelection];
        if (!itemsToPrint.find(p => p.expeditionId == expeditionPackage.expeditionId)) {
          itemsToPrint.push({
            expeditionId: expeditionPackage.expeditionId,
            setAsImpress: false,
            reprint: true,
            incidence: false,
            reprintPackages: true
          });
        }
      }
    }
    return itemsToPrint;
  }

  private async downloadUrl(urlDownload, fileName, isFinish){
    let request = this.configNotificationDownload(urlDownload,fileName);
    console.log("DOWNLOAD::request", request);

    await this.downloader.download(request).then((location: string) =>{
        console.log("DOWNLOAD::SUCCESS location", location);
      if(isFinish){
        this.intermediaryService.dismissLoadingNew();
        this.intermediaryService.presentToastSuccess("¡Descarga finalizada!");
      }
    }).catch((error: any) => {
      if(isFinish){
        console.log("DOWNLOAD::ERROR", error);
        this.intermediaryService.dismissLoadingNew();
        this.intermediaryService.presentToastError("¡Ha ocurrido un error al descargar el archivo!");
      }
    });
  }

  private configNotificationDownload(url,name): DownloadRequest {
    const downloadsDirectory = `${config.downloads.directoryBase}/${config.downloads.directoryAL}/${config.downloads.directoryExpeditions}`;
    return {
      uri: url,
      title: 'Etiqueta de expedición',
      description: '',
      mimeType: '',
      visibleInDownloadsUi: true,
      notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
      destinationInExternalPublicDir: {
        dirType: downloadsDirectory,
        subPath: name
      }
    }
  }
}

import {Injectable} from '@angular/core';
import {ScanditProvider} from "../../../providers/scandit/scandit.provider";
import {ScanditModel} from "../../../models/scandit/Scandit";
import {PrinterService} from "../../printer/printer.service";
import {
  AuthenticationService,
  environment,
  PriceModel,
  PriceService,
  ProductModel,
  ProductsService,
  WarehouseModel
} from "@suite/services";
import {PackingInventoryService} from "../../endpoint/packing-inventory/packing-inventory.service";
import {PackingInventoryModel} from "../../../models/endpoints/PackingInventory";
import {PrintModel} from "../../../models/endpoints/Print";
import {environment as al_environment} from "../../../../../../apps/al/src/environments/environment";
import {ItemReferencesProvider} from "../../../providers/item-references/item-references.provider";

declare let Scandit;
declare let GScandit;
declare let ScanditMatrixSimple;

@Injectable({
  providedIn: 'root'
})
export class PrintTagsScanditService {

  /*
   * 1 - Reference Tag
   * 2 - Price Tag
   * 3 - Packing Tag
   */
  private typeTags: 1|2|3|4 = 1;
  private timeoutHideText;

  private listProductsPrices: any[];
  private productRelabel: ProductModel.SizesAndModel;
  private lastCodeScanned: string = 'start';
  private lastProductReferenceScanned: string = 'start';
  private scannedPaused: boolean = false;

  private productToPrintPvpLabel: PriceModel.PriceByModelTariff = null;

  private isStoreUser: boolean = false;
  private storeUserObj: WarehouseModel.Warehouse = null;

  private readonly timeMillisToResetScannedCode: number = 1000;

  constructor(
    private printerService: PrinterService,
    private priceService: PriceService,
    private packingInventorService: PackingInventoryService,
    private productsService: ProductsService,
    private authService: AuthenticationService,
    private scanditProvider: ScanditProvider,
    private itemReferencesProvider: ItemReferencesProvider
  ) {
    this.timeMillisToResetScannedCode = al_environment.time_millis_reset_scanned_code;
  }

  printTagsReferences() {
    this.typeTags = 1;
    this.initPrintTags();
  }

  printTagsPrices() {
    this.typeTags = 2;
    this.initPrintTags();
  }

  printTagsPackings() {
    this.typeTags = 3;
    this.initPrintTags();
  }

  printRelabelProducts() {
    this.typeTags = 4;
    this.initPrintTags();
  }

  private async initPrintTags() {
    this.isStoreUser = await this.authService.isStoreUser();
    if (this.isStoreUser) {
      this.storeUserObj = await this.authService.getStoreCurrentUser();
    }

    this.lastCodeScanned = 'start';
    let codeScanned: string = null;
    let timeoutStarted = null;

    ScanditMatrixSimple.initPrintTags(async (response: ScanditModel.ResponsePrintTags) => {
      if(response && response.result && response.actionIonic){
        this.executeAction(response.actionIonic, response.params);
      } else if (response && response.result) {
        if (response.barcode && response.barcode.data != this.lastCodeScanned && !this.scannedPaused) {

          codeScanned = response.barcode.data;
          this.lastCodeScanned = codeScanned;
          this.lastProductReferenceScanned = codeScanned;

          ScanditMatrixSimple.setTimeout("lastCodeScannedStart", this.timeMillisToResetScannedCode, "");

          switch (this.itemReferencesProvider.checkCodeValue(codeScanned)) {
            case this.itemReferencesProvider.codeValue.PRODUCT:
              switch (this.typeTags) {
                case 1:

                  ScanditMatrixSimple.request("POST", environment.apiBase + "/products/references", { references: [codeScanned] }, localStorage.getItem("access_token"), "getProductsByReference");
                  break;
                case 2:
                  ScanditMatrixSimple.showLoadingDialog('Consultando precio del producto...');
                  ScanditMatrixSimple.request("POST", environment.apiBase + "/tariffs/references-products", { references: [codeScanned] }, localStorage.getItem("access_token"), "postPricesByProductsReferences");
                  break;
                case 3:
                  // Check packing of product
                  this.getCarrierOfProductAndPrint(codeScanned);
                  break;
                case 4:
                    this.postRelabelProduct(this.lastProductReferenceScanned);
                  break;
                default:
                  ScanditMatrixSimple.setText(
                    'El código escaneado no es válido para la operación que se espera realizar.',
                    this.scanditProvider.colorsMessage.error.color,
                    this.scanditProvider.colorText.color,
                    16);
                  this.hideTextMessage(1500);
                  break;
              }
              break;
            case this.itemReferencesProvider.codeValue.PRODUCT_MODEL:
              switch (this.typeTags) {
                case 1:
                  ScanditMatrixSimple.setText(
                    'Escanea un código de caja para reimprimir la etiqueta de caja del producto.',
                    this.scanditProvider.colorsMessage.error.color,
                    this.scanditProvider.colorText.color,
                    16);
                  this.hideTextMessage(1500);
                  break;
                case 2:
                  // Query sizes_range for product model
                  ScanditMatrixSimple.showLoadingDialog('Consultando precio del producto...');
                  ScanditMatrixSimple.request("POST", environment.apiBase + "/tariffs/model", { reference: codeScanned, tariffId: null }, localStorage.getItem("access_token"), "postPricesByModel");
                  break;
                case 4:
                  this.getSizeListByReference(codeScanned);
                  break;
                default:
                  ScanditMatrixSimple.setText(
                    'El código escaneado no es válido para la operación que se espera realizar.',
                    this.scanditProvider.colorsMessage.error.color,
                    this.scanditProvider.colorText.color,
                    16);
                  this.hideTextMessage(1500);
                  break;
              }
              break;
            default:
              let msg = 'El código escaneado no es válido para la operación que se espera realizar.';
              if (this.typeTags == 1) {
                msg = 'El código escaneado es erróneo. Escanea un código de caja para poder imprimir la etiqueta de caja.';
              } else if (this.typeTags == 2) {
                msg = 'El código escaneado es erróneo. Escanea un código de caja o de exposición para poder imprimir la etiqueta de precio.';
              } else if (this.typeTags == 4) {
                msg = 'El código escaneado es erróneo. Escanea un código de caja o de exposición para poder reetiquetar el producto.';
              }
              ScanditMatrixSimple.setText(
                msg,
                this.scanditProvider.colorsMessage.error.color,
                this.scanditProvider.colorText.color,
                16);
              this.hideTextMessage(1500);
              break;
          }
        }
        if (response.action == 'change_tag_type') {
          this.typeTags = response.type_tags;
        } else if (response.action == 'select_size') {
          let sizeSelected: number = response.size_selected;
          if (this.typeTags == 2) {
            let price = this.listProductsPrices[sizeSelected];
            if (price.typeLabel == PrintModel.LabelTypes.LABEL_PRICE_WITHOUT_TARIF_OUTLET) {
              this.scannedPaused = true;
              this.productToPrintPvpLabel = price;
              ScanditMatrixSimple.showWarning(true, 'Este artículo no tiene tarifa outlet. ¿Desea imprimir su etiqueta de PVP?', 'print_pvp_label', 'Sí', 'No');
            } else {
              this.printerService.printTagPriceUsingPrice(price, ()=>{
                console.debug("PRINT::printNotify 1 [" + new Date().toJSON() + "]", [price.hash]);
                ScanditMatrixSimple.request("POST", environment.apiBase + "/tariffs/printReferences", { hashes: [price.hash] }, localStorage.getItem("access_token"), "printNotify");
              });
            }
          } else if (this.typeTags == 4) {
            let modelId = this.productRelabel.model.id;
            let sizeId = this.productRelabel.sizes[sizeSelected].id;
            this.postRelabelProduct(this.lastProductReferenceScanned, modelId, sizeId);
          }
        } else if (response.action == 'print_pvp_label') {
          this.scannedPaused = false;
          if (response.response) {
            this.productToPrintPvpLabel.typeLabel = PrintModel.LabelTypes.LABEL_PRICE_WITHOUT_TARIF;
            this.printerService.printTagPriceUsingPrice(this.productToPrintPvpLabel, ()=>{
              console.debug("PRINT::printNotify 1 [" + new Date().toJSON() + "]", [this.productToPrintPvpLabel.hash]);
              ScanditMatrixSimple.request("POST", environment.apiBase + "/tariffs/printReferences", { hashes: [this.productToPrintPvpLabel.hash] }, localStorage.getItem("access_token"), "printNotify");
            });
          } else {
            this.lastCodeScanned = 'start';
            this.productToPrintPvpLabel = null;
          }
        } else if (response.action === 'request') {
          let responseData = null;

          if (response.data) {
            responseData = JSON.parse(response.data);
          }

          switch (response.requestType) {
            case "postPricesByProductsReferences":
              if (response.code === 200 || response.code === 201) {
                let pricesData: PriceModel.PriceByModelTariff[] = responseData.data;
                ScanditMatrixSimple.hideLoadingDialog();
                let price = pricesData[0];
                if (price.typeLabel === PrintModel.LabelTypes.LABEL_PRICE_WITHOUT_TARIF_OUTLET) {
                  this.scannedPaused = true;
                  this.productToPrintPvpLabel = price;
                  ScanditMatrixSimple.showWarning(true, 'Este artículo no tiene tarifa outlet. ¿Desea imprimir su etiqueta de PVP?', 'print_pvp_label', 'Sí', 'No');
                } else {
                  this.printerService.printTagPriceUsingPrice(price, () => {
                    console.debug("PRINT::printNotify 1 [" + new Date().toJSON() + "]", [price.hash]);
                    ScanditMatrixSimple.request("POST", environment.apiBase + "/tariffs/printReferences", { hashes: [price.hash] }, localStorage.getItem("access_token"), "printNotify");
                  });
                }
              } else if (response.code === 0) {
                ScanditMatrixSimple.hideLoadingDialog();
                this.lastCodeScanned = 'start';
                ScanditMatrixSimple.setText(
                  'Ha ocurrido un problema al intentar conectarse con el servidor. Revise su conexión y pruebe de nuevo a realizar la operación.',
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  16);
                this.hideTextMessage(1500);
              } else {
                ScanditMatrixSimple.hideLoadingDialog();
                this.lastCodeScanned = 'start';
                ScanditMatrixSimple.setText(
                  'Ha ocurrido un error al consultar los precios del artículo escaneado.',
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  16);
                this.hideTextMessage(1500);
              }
              break;

            case "postPricesByModel":
              if (response.code === 200 || response.code === 201) {
                let respData = responseData.data;
                ScanditMatrixSimple.hideLoadingDialog();
                if (respData && respData.length === 1) {
                  let price = respData[0];
                  if (price.typeLabel === PrintModel.LabelTypes.LABEL_PRICE_WITHOUT_TARIF_OUTLET) {
                    this.scannedPaused = true;
                    this.productToPrintPvpLabel = price;
                    ScanditMatrixSimple.showWarning(true, 'Este artículo no tiene tarifa outlet. ¿Desea imprimir su etiqueta de PVP?', 'print_pvp_label', 'Sí', 'No');
                  } else {
                    this.printerService.printTagPriceUsingPrice(price, ()=>{
                      console.debug("PRINT::printNotify 1 [" + new Date().toJSON() + "]", [price.hash]);
                      ScanditMatrixSimple.request("POST", environment.apiBase + "/tariffs/printReferences", { hashes: [price.hash] }, localStorage.getItem("access_token"), "printNotify");
                    });
                  }
                } else if (respData && respData.length > 1) {
                  this.listProductsPrices = respData;
                  // Request user select size to print
                  ScanditMatrixSimple.showAlertSelectSizeToPrint('Selecciona talla a usar', respData);
                }
              } else if (response.code === 0) {
                ScanditMatrixSimple.hideLoadingDialog();
                this.lastCodeScanned = 'start';
                ScanditMatrixSimple.setText(
                  'Ha ocurrido un problema al intentar conectarse con el servidor. Revise su conexión y pruebe de nuevo a realizar la operación.',
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  16);
                this.hideTextMessage(1500);
              } else {
                ScanditMatrixSimple.hideLoadingDialog();
                this.lastCodeScanned = 'start';
                ScanditMatrixSimple.setText(
                  'Ha ocurrido un error al consultar los precios del artículo escaneado.',
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  16);
                this.hideTextMessage(1500);
              }
              break;

            case "postRelabel":
              ScanditMatrixSimple.hideLoadingDialog();
              if (response.code == 200) {
                // Do product print
                let data = responseData.data as any;
                if (data && data.missingData) {
                  let data = responseData.data as ProductModel.ResponseRelabelNotCompleted['data'];
                  let modelReference = data.model.reference;
                  let sizeNotFound = data.sizeNumberNotFound;
                  let newSizes = data.newSizes;
                  let listItems = newSizes.map((size) => {
                    return {
                      rangesNumbers: {
                        sizeRangeNumberMax: size.name,
                        sizeRangeNumberMin: size.name
                      }
                    }
                  });
                  ScanditMatrixSimple.showAlertSelectSizeToPrint(`Talla ${sizeNotFound} no encontrada para el modelo ${modelReference}. Seleccione talla:`, listItems);
                } else {
                  let data = responseData.data as ProductModel.Product;
                  this.printerService.printTagBarcodeUsingProduct(data);
                }
              } else if (response.code == 0) {
                ScanditMatrixSimple.setText(
                  'Ha ocurrido un problema al intentar conectarse con el servidor. Revise su conexión y pruebe de nuevo a realizar la operación.',
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  16);
                this.hideTextMessage(1500);
              } else {
                let errorMessage = 'Ha ocurrido un error al intentar consultar la información de la talla.';
                if (responseData && responseData.errors) {
                  errorMessage = responseData.errors;
                }

                ScanditMatrixSimple.setText(
                  errorMessage,
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  16);
                this.hideTextMessage(1500);
              }
              break;

            case "getInfoForRelabel":
              ScanditMatrixSimple.hideLoadingDialog();
              if (response.code == 200) {
                let responseSizeAndModel: ProductModel.SizesAndModel = <ProductModel.SizesAndModel>responseData.data;
                if (responseSizeAndModel.model && responseSizeAndModel.sizes) {
                  if (responseSizeAndModel.sizes.length == 1) {
                    this.postRelabelProduct(this.lastCodeScanned, responseSizeAndModel.model.id, responseSizeAndModel.sizes[0].id);
                  } else {
                    let responseSizeAndModel: ProductModel.SizesAndModel = <ProductModel.SizesAndModel>responseData.data;
                    this.productRelabel = responseSizeAndModel;

                    let listItems = responseSizeAndModel.sizes.map((size) => {
                      return {
                        rangesNumbers: {
                          sizeRangeNumberMax: size.name,
                          sizeRangeNumberMin: size.name
                        }
                      }
                    });
                    ScanditMatrixSimple.showAlertSelectSizeToPrint('Selecciona talla a usar', listItems);
                  }
                }
              } else if (response.code == 0) {
                ScanditMatrixSimple.setText(
                  'Ha ocurrido un problema al intentar conectarse con el servidor. Revise su conexión y pruebe de nuevo a realizar la operación.',
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  16);
                this.hideTextMessage(1500);
              } else {
                ScanditMatrixSimple.setText(
                  'No se ha podido consultar la información del producto escaneado.',
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  16);
                this.hideTextMessage(1500);
              }
              break;

            case "printNotify":
              break;

            case "getCarrierOfProduct":
              ScanditMatrixSimple.hideLoadingDialog();
              if (response.code == 200) {
                this.printerService.print({text: [responseData.data.reference], type: 0})
              } else {
                console.error('Error::Subscribe::GetCarrierOfProduct::', responseData);
                let msgError = `Ha ocurrido un error al intentar comprobar el embalaje del producto ${codeScanned}.`;
                if (responseData.errors && typeof responseData.errors == 'string') {
                  msgError = responseData.errors;
                } else if (responseData.message) {
                  msgError = responseData.message;
                }
                ScanditMatrixSimple.setText(
                  msgError,
                  this.scanditProvider.colorsMessage.error.color,
                  this.scanditProvider.colorText.color,
                  16);
                this.hideTextMessage(1500);
              }
              break;

            case "getProductsByReference":
              console.debug("PRINT::getProductsByReference 2 [" + new Date().toJSON() + "]", responseData);
              let products = responseData.data;

              let dataToPrint;
              dataToPrint = this.printerService.processProductToPrintTagBarcode(products);

              await this.printerService.toPrintFromString(dataToPrint);
              console.log('Printed product tag ... ');

              break;
          }

        }
      }
    }, 'Imprimir etiquetas', this.scanditProvider.colorsHeader.background.color, this.scanditProvider.colorsHeader.color.color, this.typeTags);
  }

  private getSizeListByReference(code: string) {
    ScanditMatrixSimple.showLoadingDialog('Consultando información del producto...');
    ScanditMatrixSimple.request("GET",  environment.apiBase + "/products/info/relabel/" + code, {}, localStorage.getItem("access_token"), "getInfoForRelabel");
  }

  private async postRelabelProduct(productReference: string, modelId?: number, sizeId?: number) {
    let paramsRelabel: ProductModel.ParamsRelabel = {
      productReference
    };

    if (this.isStoreUser) {
      paramsRelabel.warehouseId = this.storeUserObj.id;
    }

    if (modelId) {
      paramsRelabel.modelId = modelId;
    }

    if (sizeId) {
      paramsRelabel.sizeId = sizeId;
    }

    ScanditMatrixSimple.showLoadingDialog('Generando nueva etiqueta...');
    ScanditMatrixSimple.request("POST", environment.apiBase + "/products/relabel", paramsRelabel, localStorage.getItem("access_token"), "postRelabel");
  }

  private getCarrierOfProductAndPrint(codeScanned: string) {
    ScanditMatrixSimple.showLoadingDialog('Comprobando embalaje del producto...');
    ScanditMatrixSimple.request("GET",  environment.apiBase+"/packing/inventories/" + codeScanned + "/carrier", {}, localStorage.getItem("access_token"), "getCarrierOfProduct");
  }

  private hideTextMessage(delay: number){
    ScanditMatrixSimple.setTimeout("hideText", delay, "");
  }

  private executeAction(action: string, paramsString: string){
    let params = [];
    try{
      params = JSON.parse(paramsString);
    } catch (e) {

    }

    switch (action){
      case 'lastCodeScannedStart':
        this.lastCodeScanned = 'start';
        break;
      case 'hideText':
        ScanditMatrixSimple.showText(false);
        break;
    }
  }

}
